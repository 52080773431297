import head from 'lodash/head';
import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Paragraph } from '@savgroup-front-common/core/src/atoms/Paragraph';
import { AdditionalInformation } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { ActionRow } from '@savgroup-front-common/core/src/atoms/ActionRow';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import { AdaptedNeededInformation } from './AdditionalInformationExpect.types';
import { useAdditionalInformationExpectContent } from './AdditionalInformationExpectContent.hooks';
import messages from './messages';
import ProductAdditionalInformation from './ProductAdditionalInformation';

interface AdditionalInformationExpectContentProps {
  additionalInformations: Record<string, AdaptedNeededInformation[]>;
}

const AdditionalInformationExpectContent: FC<
  AdditionalInformationExpectContentProps
> = ({ additionalInformations }) => {
  const {
    formContext,
    handleAdditionalInformationNeededSubmit,
    singleProduct,
    headProduct,
    groupedProducts,
  } = useAdditionalInformationExpectContent({
    additionalInformations,
  });
  const {
    formState: { isSubmitting, errors, isSubmitSuccessful },
  } = formContext;

  return (
    <Card>
      <div>
        <Heading level={2}>
          <SafeFormattedMessageWithoutSpread message={messages.header} />
        </Heading>
        <Paragraph>
          <SafeFormattedMessageWithoutSpread message={messages.subHeader} />
        </Paragraph>
      </div>

      <FormProvider {...formContext}>
        <form onSubmit={handleAdditionalInformationNeededSubmit}>
          {singleProduct && headProduct && headProduct.neededInformation && (
            <AdditionalInformation
              ignoreUniquenessByProduct
              formContext={formContext}
              neededInformation={headProduct.neededInformation as any}
              isNewDesign
            />
          )}

          {!singleProduct &&
            groupedProducts.map((product) => {
              if (
                !product.neededInformation ||
                product.neededInformation.length === 0
              ) {
                return <></>;
              }

              return (
                <ProductAdditionalInformation
                  key={head(product.ownerProductIds)}
                  product={product}
                />
              );
            })}

          <ActionRow>
            <Button
              primary
              type={BUTTON_TYPES.SUBMIT}
              isLoading={isSubmitting || isSubmitSuccessful}
              isError={Object.values(errors).length > 0}
            >
              <SafeFormattedMessageWithoutSpread message={messages.submit} />
            </Button>
          </ActionRow>
        </form>
      </FormProvider>
    </Card>
  );
};

AdditionalInformationExpectContent.displayName =
  'AdditionalInformationExpectContent';

export default AdditionalInformationExpectContent;
