import { LOCATION_CHANGE } from 'redux-first-history';

import { CLAIM_STATE } from '@savgroup-front-common/constants';

import * as OrdersConst from '../Orders/actionTypes';

import { LOAD_ORDER_VIEW } from './actionTypes';

const initialState = {
  product: {
    productID: null,
    brandName: null,
    modelName: null,
    modelTypeId: null,
  },
  issueSummary: {
    issueId: null,
    issueName: null,
    reasonName: null,
    solutionName: null,
  },
  claimID: null,
  diagnosticQuestions: [],
  claimState: '',
  handling: {
    isLoaded: false,
  },
  orderView: false,
};

function onSelectProduct(state, { product, order }) {
  return {
    ...state,
    claimID: product.claimContextId,
    product: {
      productID: product.ownerProductId,
      brandName: product.brandName,
      modelName: product.modelName,
      modelTypeId: product.modelTypeId,
    },
    order,
    handling: initialState.handling,
  };
}

function onLocationChange(state, payload) {
  const { pathname } = payload.location;
  const path = pathname.split('/');

  const claimID =
    path.indexOf('claim') === -1 ? null : path[path.indexOf('claim') + 1];
  const isClaim = pathname.search('claim') !== -1;
  const isIssues = pathname.search('issues') !== -1;
  const isSymptoms = pathname.search('symptoms') !== -1;
  const isDiagnostic = pathname.search('diagnostic') !== -1;
  const isReasons = pathname.search('reasons') !== -1;
  const isReason = pathname.search('reason') !== -1;
  const isSolutions = pathname.search('solutions') !== -1;
  const isHandling = pathname.search('handling') !== -1;
  const isDepositHandling = pathname.search('deposit') !== -1;
  const isDeliveryHandling = pathname.search('delivery') !== -1;
  const isConfirmation = pathname.search('confirmation') !== -1;
  const isRMA = pathname.search('rma') !== -1;
  const isIAD = pathname.search('iad') !== -1;

  let claimState;

  if (isClaim) {
    if (isIssues) {
      claimState = CLAIM_STATE.CREATED;
    } else if (isSymptoms) {
      claimState = CLAIM_STATE.SYMPTOMS;
    } else if (isDiagnostic) {
      claimState = CLAIM_STATE.DIAGNOSTIC;
    } else if (isReasons) {
      claimState = CLAIM_STATE.REASONS;
    } else if (isReason) {
      claimState = CLAIM_STATE.DIAGNOSTIC_REASON;
    } else if (isSolutions) {
      claimState = CLAIM_STATE.SOLUTIONS;
    } else if (isHandling) {
      if (isDepositHandling) {claimState = CLAIM_STATE.HANDLING_DEPOSIT;}
      else if (isDeliveryHandling) {
        claimState = CLAIM_STATE.HANDLING_DELIVERY;
      } else if (isIAD) {
        claimState = CLAIM_STATE.HANDLING_IAD;
      }
    } else if (isConfirmation) {
      claimState = CLAIM_STATE.CONFIRMATION;
    } else if (isRMA) {
      claimState = 'ClosedForRma';
    }
  } else {
    claimState = null;
  }

  return {
    ...state,
    claimID: claimID || state.claimID,
    claimState: claimState || state.claimState,
  };
}

function onLoadOrderViewStarted(state) {
  return {
    ...state,
    orderView: false,
  };
}
function onLoadOrderViewEnded(state) {
  return {
    ...state,
    orderView: true,
  };
}

function navigationReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return onLocationChange(state, action.payload);

    case OrdersConst.MYORDERS_SELECT_PRODUCT:
      return onSelectProduct(state, action.payload);

    case LOAD_ORDER_VIEW.STARTED:
      return onLoadOrderViewStarted(state);
    case LOAD_ORDER_VIEW.END:
      return onLoadOrderViewEnded(state);

    default:
      return state;
  }
}

export default navigationReducer;
