import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { MessageType } from '@savgroup-front-common/types';

import { showMessageId } from '../helpers';

import { SafeFormattedMessageReturnValue } from './safeFormattedMessage';

// eslint-disable-next-line react/display-name
export const SafeFormattedMessageWithoutSpread = ({
  message,
  values,
  dataTestId,
}: {
  message: MessageDescriptor | MessageType | string | number | boolean;
  values?: Record<string, any>;
  dataTestId?: string;
}): SafeFormattedMessageReturnValue => {
  if (
    typeof message === 'string' ||
    typeof message === 'number' ||
    typeof message === 'boolean'
  ) {
    return React.createElement('span', { 'data-testid': dataTestId }, message);
  }

  const id = message?.id;
  const el = React.createElement(FormattedMessage, {
    ...message,
    values:
      (message as MessageType) && (message as MessageType)?.values
        ? (message as MessageType)?.values
        : values,
  });

  return React.createElement(
    'span',
    { title: showMessageId ? id : undefined, 'data-testid': dataTestId },
    el,
  );
};
