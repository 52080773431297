import startsWith from 'lodash/startsWith';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  CommonAttachmentService,
  CommonClaimService,
} from '@savgroup-front-common/core/src/api';

import { adaptAttachments } from '../adapters';

const getUploadedAttachments = async ({ products }) => {
  return Promise.all(
    products.map(async ({ claimIds, productId }) => {
      const response = await CommonClaimService.getClaimDocuments({
        claimId: claimIds[0],
      });

      return { documents: response, productId };
    }, {}),
  );
};

const useGetUploadedAttachmentsOnClaimGroup = ({ products, claimGroupId }) => {
  const [claimAttachmentsByProductId, setClaimAttachmentsByProductId] =
    useState(undefined);

  useDeepCompareEffect(() => {
    const call = async () => {
      if (Object.values(products).length === 0) {
        return;
      }

      const getUploadedAttachmentResponses = await getUploadedAttachments({
        products,
      });

      const responses = await Promise.all(
        getUploadedAttachmentResponses.map(async ({ documents, productId }) => {
          return {
            documents: await Promise.all(
              documents.map(async (document) => {
                const { url } = document;
                const isUrlReady =
                  startsWith(url, 'https://') ||
                  startsWith(url, '//localhost/');
                const formattedUrl = isUrlReady
                  ? url
                  : `${APIConfiguration.base}${url}`;

                const blob = await CommonAttachmentService.downloadAttachment({
                  endpoint: formattedUrl,
                });

                return {
                  ...document,
                  value: blob,
                };
              }),
            ),
            productId,
          };
        }),
      );

      const adaptedAttachments = adaptAttachments({
        uploadedAttachments: responses,
      });

      setClaimAttachmentsByProductId(
        adaptedAttachments.reduce((acc, adaptedAttachment) => {
          return {
            ...acc,
            [adaptedAttachment.productId]: adaptedAttachment.documents,
          };
        }, {}),
      );
    };

    call();
  }, [claimGroupId, products]);

  return { claimAttachmentsByProductId };
};

export default useGetUploadedAttachmentsOnClaimGroup;
