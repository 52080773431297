import { rem, rgba } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

interface $RouteSubTabProps {
  $isActive: boolean;
}
export const $RouteSubTab = styled.div<$RouteSubTabProps>`
  @media ${media.maxWidth.xs} {
    &&& {
      font-size: 1rem;
    }
  }

  display: flex;
  color: ${({ theme }) => theme.colors.mainTextColor}!important;
  font-size: ${rem(20.6)};
  font-weight: ${({ theme, $isActive }) =>
    $isActive ? theme.fonts.weight.bold : theme.fonts.weight.normal};

  line-height: ${rem(21)};
  position: relative;
  cursor: pointer;
  padding-bottom: 1.5rem;

  &:hover,
  &:focus {
    outline: 0;
    color: ${({ theme }) => theme.colors.primary}!important;
    text-decoration: unset;
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

interface $SubMenuLinkCustomProps {
  $isActive: boolean;
}

export const $SubMenuLinkCustom = styled(Link)<$SubMenuLinkCustomProps>`
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.primary : theme.colors.darkBackground};
  background-color: ${({ theme, $isActive }) =>
    $isActive ? rgba(theme.colors.primary, 0.05) : 'transparent'};
  width: 100%;
  height: ${rem(38)};
  font-size: ${({ theme }) => theme.fonts.size.small};
  border: 0;
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  outline: 0;
  white-space: nowrap;
  min-width: 14rem;
  cursor: pointer;
  border-radius: ${({ $isActive }) => ($isActive ? '8px' : null)};
  svg {
    width: ${rem(20)};
    height: ${rem(20)};
    margin-right: ${rem(10)};
  }

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => rgba(theme.colors.primary, 0.03)};
    border-radius: 8px;
  }
`;

export const $SubTabMessageWithIcon = styled.div`
  align-items: center;
  display: flex;
  svg {
    margin-right: 7px;
    fill: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const $SubTabMessage = styled.div`
  margin-top: 3px;
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
`;

export const $IconMargin = styled.div`
  margin-left: 5px;
  display: flex;

  svg:first-child {
    fill: ${({ theme }) => theme.colors.paragraphTextColor};
  }
`;
