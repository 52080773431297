import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { CLAIM_TYPES } from '@savgroup-front-common/constants/src/myaccount';
import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import { ChevronLeftIcon } from '@savgroup-front-common/core/src/protons/icons';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { AuthConfiguration } from 'myaccount/configuration';
import { ActionCreators as ClaimCreationActions } from 'myaccount/domains/Claim/ClaimCreation';
import { ROUTES } from '../../app/routes/Routes';
import ClaimContainer from 'myaccount/view/components/ClaimsContainer';

import { BackButton } from './ChooseIssuePage.styles';
import useChooseIssuePage from './hooks/useChooseIssuePage';
import { Issues } from './Issues';
import messages from './messages';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

const ChooseIssuePage = () => {
  const [issueIsSelected, setIssueIsSelected] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isGridFluidActive = useMedia(media.maxWidth.md);

  const { mergedIssues, issuesIsLoaded, isRunning, isLoaded } =
    useChooseIssuePage();
  const handleIssueSelection = useCallback(
    (payload) => {
      setIssueIsSelected(true);
      dispatch(ClaimCreationActions.chooseIssue(payload));
    },
    [dispatch],
  );

  const isLoading = !isLoaded || isRunning || mergedIssues.length === 0;

  return (
    <Grid fluid={isGridFluidActive}>
      <NeedHelp clientId={AuthConfiguration.clientId} />

      <BackButton
        onClick={() => history.push(ROUTES.PRODUCTS)}
        naked
        icon={<ChevronLeftIcon />}
        componentThemeName={SUPPORTED_COMPONENTS.BACK_BUTTON}
        type={BUTTON_TYPES.BUTTON}
      >
        <FormattedMessage {...messages.back} />
      </BackButton>
      <ClaimContainer
        claimType={CLAIM_TYPES.ISSUES}
        isRunning={!mergedIssues}
        centerTitle
      >
        {isLoading && (
          <Grid>
            <NeedHelp clientId={AuthConfiguration.clientId} />
            <BaseLoader dataTestId="chooseIssuePageLoader" />
          </Grid>
        )}

        <Issues
          issues={mergedIssues}
          onSelect={handleIssueSelection}
          isLoaded={issuesIsLoaded}
          issueIsSelected={issueIsSelected}
        />
      </ClaimContainer>
    </Grid>
  );
};

ChooseIssuePage.displayName = 'ChooseIssuePage';

export default ChooseIssuePage;
