import { all, put, takeEvery } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { ActionTypes as ActionDomainActionTypes } from 'myaccount/domains/actions';

import { ActionTypes as ClaimActionTypes } from '../../Claim';
import { ActionTypes as CustomerActionTypes } from '../../Customer';
import { ActionCreators as ErrorActionCreators } from '../../errors';
import * as MyOrderActionsTypes from '../../Orders/actionTypes';

function* onErrorWorker() {
  yield put(ErrorActionCreators.onServerErrorOccured());
}

function* onloadQuotesDataWorker({ statusCode }) {
  if (statusCode !== 400) {
    yield put(ErrorActionCreators.onServerErrorOccured());
  }
}

function* onLoadInvoiceErrorWorker({ statusCode }) {
  if (statusCode !== 404) {
    yield put(ErrorActionCreators.onServerErrorOccured());
  }
}

export function* ErrorWatcher() {
  yield takeEvery(
    CustomerActionTypes.GET_SHIPPING_LABEL.ERRORED,
    onErrorWorker,
  );
  yield takeEvery(
    CustomerActionTypes.LOAD_QUOTES_DATA.ERRORED,
    onloadQuotesDataWorker,
  );
  yield takeEvery(
    CustomerActionTypes.LOAD_INVOICE_DATA.ERRORED,
    onLoadInvoiceErrorWorker,
  );
  yield takeEvery(CustomerActionTypes.ACT_ON_QUOTE.ERRORED, onErrorWorker);
  yield takeEvery(
    ActionDomainActionTypes.SET_FILE_ACTION.ERRORED,
    onErrorWorker,
  );
  yield takeEvery(MyOrderActionsTypes.MYORDERS_ON_ERRORS, onErrorWorker);
  yield takeEvery(ClaimActionTypes.LOAD_CLAIM_SUMMARY.ERRORED, onErrorWorker);
}

export function* errorSaga() {
  try {
    yield all([ErrorWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
