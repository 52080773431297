import { CURRENCIES } from '@savgroup-front-common/constants';

import { ReverseMoneyNumberAmount } from '../ReverseMoney';

import { INVOICE_ITEM_KIND } from './InvoiceItemKind';

export interface InvoiceItem {
  kind: INVOICE_ITEM_KIND;
  referenceType: string;
  referenceId: string;
  label?: string;
  currencyCode: CURRENCIES;
  price: number;
  tax: number;
  priceWithTax: number;
}

export interface Invoice {
  id: string;
  state: INVOICE_STATE;
  displayValueAddedTax: boolean;
  item: InvoiceItem[];
  price: number;
  tax: number;
  priceWithTax: number;
  currencyTrigram: CURRENCIES;
  creationDate: string;
  lastStateDate: string;
  paymentId: string;
  alreadyPaidAmount: number;
  amountToBePaidByClient: ReverseMoneyNumberAmount;
  billingId: string;
  totalPriceWithTax: number;
}

export interface InvoiceDto {
  id: string;
  state: INVOICE_STATE;
  displayValueAddedTax: boolean;
  item: InvoiceItem[];
  price: number;
  tax: number;
  priceWithTax: number;
  currencyTrigram: CURRENCIES;
  creationDate: Date;
  lastStateDate: Date;
  paymentId: string;
}

export enum INVOICE_STATE {
  PAID = 'Paid',
  OPENED = 'Opened',
}

export enum ENTITY_TYPE {
  CLAIM_GROUP = 'ClaimGroup',
  QUOTE = 'Quote',
}

export enum PAYMENT_METHOD {
  NOT_SET = 'NotSet',
  CASH = 'Cash',
  CHEQUE = 'Cheque',
  CREDIT_CARD = 'CreditCard',
  CASH_REGISTER = 'CashRegister',
  INTEGRATED_PROVIDER = 'IntegratedProvider',
  EXTERNAL_WEBSITE = 'ExternalWebSite',
}
