import React from 'react';

import Icon from './Icon';

interface DepositIconProps {
  color?: string;
  size?: string;
}

const DepositIcon: React.FC<DepositIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M6.27681 14.3957L14.7131 18.1452L14.8047 18.3508L14.8447 18.4408H14.7461H14.2764V18.7217L5.99038 15.039L6.27681 14.3957ZM15.2157 30.4963V20.1672L15.4719 20.281L15.568 20.0652L15.6641 20.281L15.9203 20.1672V30.4963H15.2157ZM25.1457 15.039L16.8597 18.7217V18.4408H16.39H16.2913L16.3314 18.3508L16.4229 18.1452L24.8592 14.3957L25.1457 15.039Z"
        stroke={color}
        strokeOpacity="0.7"
        strokeWidth="0.939394"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5682 10.9135L6.40909 14.985V26.1243L15.5682 30.1957L24.7273 26.1243V24.7821C24.7273 24.393 25.0427 24.0776 25.4318 24.0776C25.8209 24.0776 26.1364 24.393 26.1364 24.7821V26.155C26.1363 26.4183 26.0599 26.6761 25.9165 26.8969C25.7731 27.1177 25.5687 27.2922 25.3282 27.3992L16.1221 31.4915C16.122 31.4916 16.1222 31.4915 16.1221 31.4915C15.9478 31.5691 15.7589 31.6093 15.5682 31.6093C15.3775 31.6093 15.1889 31.5692 15.0146 31.4917C15.0145 31.4916 15.0147 31.4917 15.0146 31.4917L5.80852 27.3994C5.56798 27.2923 5.3633 27.1177 5.21987 26.8969C5.07643 26.6761 5.00006 26.4184 5 26.1552V14.9543C5.00006 14.691 5.07643 14.4332 5.21987 14.2124C5.3633 13.9916 5.56765 13.8171 5.80819 13.71L15.0143 9.61772C15.0141 9.61777 15.0144 9.61767 15.0143 9.61772C15.1885 9.5402 15.3775 9.5 15.5682 9.5C15.7589 9.5 15.9475 9.54006 16.1218 9.61757C16.1217 9.61752 16.1219 9.61762 16.1218 9.61757L25.3278 13.7099C25.5684 13.817 25.7731 13.9916 25.9165 14.2124C26.0599 14.4332 26.1363 14.6908 26.1364 14.9541V18.6761C26.1364 19.0652 25.8209 19.3806 25.4318 19.3806C25.0427 19.3806 24.7273 19.0652 24.7273 18.6761V14.985L15.5682 10.9135ZM15.5491 30.2042C15.549 30.2043 15.5492 30.2042 15.5491 30.2042V30.2042Z"
        fill={color}
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99255 12.0489C10.1506 11.6934 10.5669 11.5332 10.9225 11.6913L20.396 15.902C20.6365 16.009 20.8412 16.1837 20.9847 16.4045C21.1281 16.6253 21.2045 16.8829 21.2045 17.1462V21.1028C21.2045 21.4919 20.8891 21.8073 20.5 21.8073C20.1109 21.8073 19.7955 21.4919 19.7955 21.1028V17.177L10.3502 12.9789C9.99464 12.8209 9.83451 12.4045 9.99255 12.0489Z"
        fill={color}
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.979 18.1775C32.2542 17.9024 32.7003 17.9024 32.9754 18.1775L35.7936 20.9957C36.0687 21.2709 36.0687 21.717 35.7936 21.9921L32.9754 24.8103C32.7003 25.0854 32.2542 25.0854 31.979 24.8103C31.7039 24.5351 31.7039 24.0891 31.979 23.8139L33.5945 22.1985H24.0227C23.6336 22.1985 23.3181 21.883 23.3181 21.4939C23.3181 21.1048 23.6336 20.7894 24.0227 20.7894H33.5945L31.979 19.1739C31.7039 18.8988 31.7039 18.4527 31.979 18.1775Z"
        fill={color}
        fillOpacity="0.7"
      />
    </Icon>
  );
};

DepositIcon.displayName = 'DepositIcon';

export default DepositIcon;
