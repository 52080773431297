import React, { FC } from 'react';

import { ActionLabel } from '../ActionLabel';

import { ActiveStatusTemplate } from './ActiveStatusTemplate';
import { useClientAction } from './ClientAction.hooks';
import ExpectsContainer from './ExpectsContainer/ExpectsContainer';
import useGetClientData from './hooks/useGetClientData';

const ClientAction: FC = () => {
  const {
    confirmationClaimGroupValue,
    loadingStatuses,
    workflowInfos,
    fileSummary,
    handlingSummary,
    claimConfirmationData,
    fileId,
    module,
    fileStatusName,
    invoiceId,
  } = useGetClientData();

  const { content1, adaptedFirstAction } = useClientAction({
    fileSummary,
    claimConfirmationData,
    workflowInfos,
    fileStatusName,
    invoiceId,
    module,
  });

  if (!workflowInfos?.file?.currentActions) {
    return <></>;
  }

  if (content1) {
    return (
      <ActiveStatusTemplate
        fileId={fileId}
        confirmationClaimGroupValue={confirmationClaimGroupValue}
        fileSummary={fileSummary}
        claimConfirmationData={claimConfirmationData}
        handlingSummary={handlingSummary}
        adaptedFirstAction={adaptedFirstAction}
        loadingStatuses={loadingStatuses}
      />
    );
  }

  return (
    <>
      <ActionLabel action={adaptedFirstAction} index={0} />
      <ExpectsContainer />
    </>
  );
};

ClientAction.displayName = 'ClientAction';

export default ClientAction;
