import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import PaginatedView from '@savgroup-front-common/core/src/components/PaginatedView/PaginatedView';

import messages from './messages';
import ProductIRSSummaryCard from './ProductIRSSummaryCard';

const ProductIRSSummaryCardsGroup = ({
  goToEditionPage,
  products,
  flatMode,
  claimGroupId,
}) => {
  const [activeProduct, changeActiveProduct] = useState(0);
  const claimId = head(products)?.claimId;

  return (
    <>
      {products.length > 1 && !flatMode ? (
        <PaginatedView
          products={products}
          activeIndex={activeProduct}
          onActiveProductChange={changeActiveProduct}
          title={<FormattedMessage {...messages.products} />}
        />
      ) : null}
      {map(sortBy(products, ['claimId']), (item, index) =>
        flatMode || activeProduct === index ? (
          <ProductIRSSummaryCard
            key={String(get(item, 'claimId'))}
            multiProduct={products.length > 1}
            flatMode={flatMode}
            goToEditionPage={goToEditionPage}
            product={item}
            claimId={claimId}
            claimGroupId={claimGroupId}
          />
        ) : null,
      )}
    </>
  );
};

ProductIRSSummaryCardsGroup.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ProductIRSSummaryCardsGroup.displayName = 'ProductIRSSummaryCardsGroup';

export default ProductIRSSummaryCardsGroup;
