enum COMMON_QUERY_PARAMS {
  ADAPTER_ID = ':adapterId',
  BILLING_ID = ':billingId',
  CLAIM_GROUP_ID = ':claimGroupId',
  CLAIM_ID = ':claimId',
  CONSOLE_ENTITY_DEFINITION = ':entityDefinition',
  CONSOLE_RULE_TYPE = ':ruleType',
  COUNTRY_CODE = ':countryCode',
  CULTURE_CODE = ':cultureCode',
  DOCUMENT_ID = ':documentId',
  DOCUMENT_TYPE = ':documentType',
  DRAFT_ID = ':draftId',
  EAN = ':EAN',
  EMAIL = ':email',
  ENTITY_ID = ':entityId',
  ENTITY_TYPE = ':entityType',
  FILE_ATTACHMENT_ID = ':fileAttachmentId',
  FILE_ID = ':fileId',
  FILE_NAME = ':fileName',
  ID = ':id',
  IMPORT_ID = ':importId',
  IMPORT_TYPE = ':importType',
  INVOICE_ID = ':invoiceId',
  ISSUE_ID = ':issueId',
  KIND = ':kind',
  LOAN_ID = ':loanId',
  MODEL_ID = ':modelId',
  MODULE_DEFINITION_ID = ':moduleDefinitionId',
  MODULE_INSTANCE_ID = ':moduleInstanceId',
  NODE_UNIQUE_NAME = ':nodeUniqueName',
  ORDER_ID = ':orderId',
  ORDER_REFERENCE = ':orderReference',
  OWNER_ID = ':ownerId',
  OWNER_PRODUCT_ID = ':ownerProductId',
  PAYMENT_ID = ':paymentId',
  PRODUCT_SUPPLIER_ID = ':productSupplierId',
  QUOTE_ID = ':quoteId',
  REFERENCE = ':reference',
  REQUEST_LINE_ID = ':requestLineId',
  RESPONSE_UNIQUE_NAME = ':responseUniqueName',
  SELLER_ID = ':sellerId',
  SERVICE = ':service',
  SERVICE_BUS_ID = ':serviceBusId',
  STATE = ':state',
  STOCK_ITEM_ID = ':stockItemId',
  TENANT_ID = ':tenantId',
  TOKEN = ':token',
  TOKEN_ID = ':tokenId',
  TREE_UNIQUE_NAME = ':treeUniqueName',
  USER_ID = ':userId',
  WARRANTY_ID = ':warrantyId',
  WARRANTY_TYPE_ID = ':warrantyTypeId',
  WORKFLOW_ID = ':workflowId',
  WORKFLOW_NAME = ':workflowName',
}

export default COMMON_QUERY_PARAMS;
