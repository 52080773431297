import React from 'react';

import Icon from '../Icon';

interface HomeLightIconProps {
  color?: string;
  size?: string;
}

const HomeLightIcon: React.FC<HomeLightIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M5 15.8333L20 6.66667L35 15.8333M31.6667 21.6667V32.3333C31.6667 32.5985 31.5613 32.8529 31.3738 33.0404C31.1862 33.228 30.9319 33.3333 30.6667 33.3333H9.33333C9.06812 33.3333 8.81376 33.228 8.62623 33.0404C8.43869 32.8529 8.33333 32.5985 8.33333 32.3333V21.6667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

HomeLightIcon.displayName = 'HomeLightIcon';

export default HomeLightIcon;
