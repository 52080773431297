import styled from 'styled-components';

import { rem } from '../../../helpers';
import { SimpleAnimationProps } from '../../../animations/simpleAnimation';
import {
  scaleInToScaleDown,
  StateAnimationProps,
} from '../../../animations/stateAnimation';

interface $LoaderWrapperProps extends SimpleAnimationProps {
  $hasMargin: boolean;
  $isOpen: boolean;
}

export const $Container = styled.div<$LoaderWrapperProps>`
  position: relative;
  padding-top: ${({ $hasMargin, $isOpen }) =>
    $hasMargin && $isOpen ? rem(128) : 0};
  padding-bottom: ${({ $hasMargin, $isOpen }) =>
    $hasMargin && $isOpen ? rem(128) : 0};
  flex: 1;
  height: ${({ $isOpen }) => ($isOpen ? '100%' : null)};
  min-height: ${({ $isOpen }) => ($isOpen ? '32px' : null)};
`;

interface $LoaderTextProps extends StateAnimationProps {
  $align: 'start' | 'center' | 'end';
  $hasMargin: boolean;
}

export const $LoaderText = styled.div<$LoaderTextProps>`
  position: absolute;
  width: 100%;
  top: ${({ $hasMargin }) => {
    return $hasMargin ? '50%' : 0;
  }};
  display: flex;
  align-items: center;
  justify-content: ${({ $align }) => {
    switch ($align) {
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';

      default:
        return $align;
    }
  }};
  > div {
    margin: 0 ${rem(5)};
  }
  span {
    font-size: ${({ theme }) => theme.fonts.size.small};
    color: ${({ theme }) => theme.colors.mainTextColor};
    white-space: nowrap;
  }
  ${scaleInToScaleDown};
`;
