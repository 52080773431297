import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { ExtendedFile } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../../../../formatters';
import { AttachmentIcon, CrossIcon } from '../../../../../protons/icons';
import { Button } from '../../../../button';
import { $Container, FieldMessage, Wrapper } from '../../../common';
import {
  FIELD_STATUS,
  FieldMessages,
} from '../../../common/helpers/getFinalFieldState.types';
import {
  AttachmentContent,
  AttachmentsListFileName,
  StyledProgressBar,
} from '../AttachmentsList.styles';

import useAttachmentItem from './AttachmentItem.hooks';

interface AttachmentItemProps {
  file: ExtendedFile;
  onRemove?: (item: ExtendedFile, index: number) => void;
  name: string;
  isLiveUpload?: boolean;
  errors: FieldMessages;
  index: number;
  dataTestId?: string;
}

const AttachmentItem: FunctionComponent<
  React.PropsWithChildren<AttachmentItemProps>
> = ({
  index,
  file,
  onRemove = undefined,
  name,
  isLiveUpload = true,
  errors,
  dataTestId,
}) => {
  const { status, message, progress, fileName } = useAttachmentItem({
    file,
    isLiveUpload,
    errors,
    name,
  });

  const theme = useTheme();

  return (
    <$Container>
      <Wrapper status={status} name={name}>
        <StyledProgressBar
          percent={progress}
          isError={status === FIELD_STATUS.ERROR}
          isSuccess={status === FIELD_STATUS.SUCCESS}
          hideOnFinish
        >
          <AttachmentContent>
            <AttachmentIcon size="18px" color={theme.colors.mainTextColor} />
            <AttachmentsListFileName>
              {SafeFormattedMessage(fileName)}
            </AttachmentsListFileName>
          </AttachmentContent>

          {onRemove && (
            <AttachmentContent $fixed>
              <Button
                onClick={() => onRemove(file, index)}
                type={BUTTON_TYPES.BUTTON}
                icon={<CrossIcon />}
                naked
                dataTestId="deleteAttachment"
              />
            </AttachmentContent>
          )}
        </StyledProgressBar>
      </Wrapper>
      <FieldMessage message={message} status={status} dataTestId={dataTestId} />
    </$Container>
  );
};

AttachmentItem.displayName = 'AttachmentItem';

export default AttachmentItem;
