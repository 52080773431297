import { fromJS } from 'immutable';

import { FINISH_CLAIM_CREATION, START_CLAIM } from './actionTypes';

export const getInitialState = () =>
  fromJS({
    claimCreationState: fromJS({
      claimCreationPending: false,
      issueId: null,
    }),
  });

function startClaimCreation(state) {
  return state.setIn(['claimCreationState', 'claimCreationPending'], true);
}

function finishClaimCreation() {
  return getInitialState();
}

export default function reducer(state = getInitialState(), action) {
  switch (action.type) {
    case START_CLAIM:
      return startClaimCreation(state);
    case FINISH_CLAIM_CREATION:
      return finishClaimCreation(state);
    default:
      return state;
  }
}
