import { defineMessages } from 'react-intl';

export default defineMessages({
  Unavailable: {
    id: 'sparePart.stockAvailability.Active',
    defaultMessage: 'Unavailable',
  },
  LocalStock: {
    id: 'sparePart.stockAvailability.LocalStock',
    defaultMessage: 'LocalStock',
  },
  SupplierStock: {
    id: 'sparePart.stockAvailability.SupplierStock',
    defaultMessage: 'SupplierStock',
  },
});
