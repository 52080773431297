import get from 'lodash/get';
import head from 'lodash/head';
import uniqBy from 'lodash/uniqBy';

export const CONFIRMATION_FIELDS = {
  MAIL: 'mail',
  PHONE: 'phone',
  STORE_ACTOR: 'storeActor',
};

const formatOption = ({ id, name, address }) => ({
  label: name,
  value: id,
  description: `${address.address} ${address.postalCode}`,
});

const transformStoreActorValueIntoOption = (storeActorOptions) => {
  const options = storeActorOptions.reduce((acc, { storesActor }) => {
    return [...acc, ...storesActor.map(formatOption)];
  }, []);

  return uniqBy(options, 'value');
};

export function useFormatStoreActorOptions({ storeActorOptions }) {
  const options = transformStoreActorValueIntoOption(storeActorOptions);

  const defaultValue = get(head(storeActorOptions), 'selectedStoreActorId');
  const defaultOption = options.find(({ value }) => value === defaultValue);

  return [options, defaultOption];
}
