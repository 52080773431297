import confirmPaymentIntent from './confirmPaymentIntent';
import createPaymentCommand from './createPaymentCommand';
import createPaymentIntent from './createPaymentIntent';
import getClaimGroupLastPaymentQuery from './getClaimGroupLastPaymentQuery';
import getFileLastPaymentQuery from './getFileLastPaymentQuery';
import getFilePaymentsQuery from './getFilePaymentsQuery';
import getOpenedInvoiceQuery from './getOpenedInvoiceQuery';
import issuePaymentCommand from './issuePaymentCommand';

export const PaymentService = {
  confirmPaymentIntent,
  createPaymentIntent,
  getFilePaymentsQuery,
  issuePaymentCommand,
  getClaimGroupLastPaymentQuery,
  getOpenedInvoiceQuery,
  createPaymentCommand,
  getFileLastPaymentQuery,
};
