import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { css } from 'styled-components';

import {
  mediaQuery,
  mediaQueryLandscape,
} from '@savgroup-front-common/core/src/grid/helpers';
import { Theme } from '@savgroup-front-common/types/src/Theme';

const elevationBasedOnProps = ({ theme }: { theme: Theme }) => {
  return theme.shadows.elevation__2;
};

export const $SelectContainerRow = styled(Row)`
  margin-top: -6px;
  z-index: 9;
  height: 100vh;
  background-color: #e5e3df;

  ${mediaQueryLandscape.xs`
    position: static;
    height: auto;
  `} ${mediaQuery.sm`
    z-index: 0;
    height: calc(100vh - 95px);
  `};
`;
export const $ColWithoutGutter = styled(Col)`
  background-color: #ffffff;
  ${mediaQuery.sm`
    padding-left: 0;
    padding-right: 0;
  `};
`;
export const $BackButtonContainer = styled($ColWithoutGutter)`
  position: relative;
  z-index: 0;
  background-color: #ffffff;
  padding: 4px 16px;
  ${(props) =>
    css`
      ${elevationBasedOnProps(props)};
    `} ${mediaQuery.lg`padding: 4px;`};
`;
export const $AddressContainer = styled.div`
  position: relative;
  background-color: #ffffff;
`;
