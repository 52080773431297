import { createSelector } from 'reselect';

import { wasActionLoadedSelector } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import { LOAD_ORDERS } from './actionTypes';

export const selectOrders = (state) => state.orders;

export const selectOrdersIsRunning = createSelector(
  [selectOrders],
  (orders) => orders.isRunning,
);
export const selectOrdersIsLoaded = createSelector([selectOrders], (orders) => {
  return orders.isLoaded;
});
const ordersSelector = ({ orders = {} }) => orders;

export const productsInfoSelector = createSelector(
  [ordersSelector],
  ({ products }) => products,
);
export const ordersSelectorItems = createSelector(
  [ordersSelector],
  ({ items = [] }) => items,
);

export const selectLoadOwnerOrdersWasLoaded = (state, { ownerId }) => {
  return wasActionLoadedSelector(state, LOAD_ORDERS, ownerId);
};
