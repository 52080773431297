import md5 from 'md5';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { Paragraph } from '@savgroup-front-common/core/src/atoms/Paragraph';
import { ChevronRightIcon } from '@savgroup-front-common/core/src/protons/icons';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { AuthConfiguration } from 'myaccount/configuration';

import {
  StyledCol,
  StyledImg,
  StyledRow,
  StyledTitle,
} from './CreateClaim.styles';
import messages from './messages';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';

const CreateClaim = ({ createClaim }) => {
  const ref = useRef(null);
  const onError = () => {
    if (ref.current) {
      ref.current.onerror = null;
      ref.current.src = `${process.env.REACT_APP_STATIC_HASH}/images/illu_empty.svg`;
    }
  };

  const hashedClientId = md5(AuthConfiguration.clientId);

  return (
    <StyledRow between="md" middle="md">
      <Col xs={5} sm={2}>
        <StyledImg
          alt="empty"
          src={`${APIConfiguration.uiStylesCDN}homepage-images/${hashedClientId}`}
          ref={ref}
          onError={onError}
        />
      </Col>
      <Col xs={7} sm={10}>
        <Row between="md">
          <Col xs={12} md={8}>
            <StyledTitle heading>
              <FormattedMessage {...messages.title} />
            </StyledTitle>
            <Paragraph>
              <FormattedMessage {...messages.message} />
            </Paragraph>
          </Col>
          <StyledCol xs={12} md={4}>
            <Button
              className="createClaim"
              onClick={() => createClaim()}
              icon={<ChevronRightIcon />}
              position={SUPPORTED_ICON_POSITIONS.RIGHT}
              primary
              fluid
              isLoading={false}
              componentThemeName={SUPPORTED_COMPONENTS.DECLARE_CLAIM_BUTTON}
              dataTestId="create-claim-button"
            >
              <FormattedMessage {...messages.action} />
            </Button>
          </StyledCol>
        </Row>
      </Col>
    </StyledRow>
  );
};

CreateClaim.propTypes = {
  createClaim: PropTypes.func.isRequired,
};

CreateClaim.displayName = 'CreateClaim';

export default CreateClaim;
