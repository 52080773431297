import PropTypes from 'prop-types';
import React from 'react';

import { Loader } from '@savgroup-front-common/core/src/atoms/loader';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';

import OrderProductInfo from '../../../../../components/OrderProductInfo/OrderProductInfo';
import { $LoaderWrapper } from '../ReasonSelectionForm/ReasonSelectionForm.styles';

const ReasonSolutionSelectionProductInfo = ({
  productInfo,
  orderDataLoaded,
}) => (
  <Card>
    {orderDataLoaded ? (
      <OrderProductInfo {...productInfo} />
    ) : (
      <$LoaderWrapper>
        <Loader />
      </$LoaderWrapper>
    )}
  </Card>
);

ReasonSolutionSelectionProductInfo.propTypes = {
  productInfo: PropTypes.shape({}),
  orderDataLoaded: PropTypes.bool,
};

ReasonSolutionSelectionProductInfo.defaultProps = {
  productInfo: null,
  orderDataLoaded: false,
};

export default ReasonSolutionSelectionProductInfo;
