import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import {
  HeaderH1,
  HeaderWrapperCol,
  PageHeaderContainer,
  SubHeaderH3,
} from './OldPageHeader.styles';

const OldPageHeader = ({
  type,
  values,
  customSubtitle,
  centerTitle,
  dataTestId,
}) => {
  const workingTitle = messages[`title_${type}`];
  const workingSubtitle = messages[`subtitle_${type}`];
  const workingDescription = messages[`description_${type}`]
    ? messages[`description_${type}`]
    : null;

  const subtitleJSX =
    workingSubtitle || customSubtitle
      ? customSubtitle || (
          <FormattedMessage {...workingSubtitle} values={values} />
        )
      : null;

  const descriptionJSX = workingDescription ? (
    <FormattedMessage {...workingDescription} values={values} />
  ) : null;

  return (
    <PageHeaderContainer centerTitle={centerTitle}>
      <HeaderWrapperCol xs={12} lg={12} type={type}>
        {workingTitle && (
          <HeaderH1 heading centerTitle={centerTitle} data-testid={dataTestId}>
            <FormattedMessage {...workingTitle} values={values} />
          </HeaderH1>
        )}
        {(workingSubtitle || workingDescription) &&
          (subtitleJSX || descriptionJSX) && (
            <SubHeaderH3 paragraph centerTitle={centerTitle}>
              {subtitleJSX || descriptionJSX}
            </SubHeaderH3>
          )}
      </HeaderWrapperCol>
    </PageHeaderContainer>
  );
};

OldPageHeader.propTypes = {
  values: PropTypes.shape({}),
  onContactUs: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
  customSubtitle: PropTypes.string,
  description: PropTypes.string,
  myClaims: PropTypes.bool,
  centerTitle: PropTypes.bool,
  dataTestId: PropTypes.string,
};

OldPageHeader.defaultProps = {
  values: {},
  onContactUs: null,
  type: '',
  title: undefined,
  customSubtitle: undefined,
  description: undefined,
  myClaims: false,
  centerTitle: false,
  dataTestId: undefined,
};

OldPageHeader.displayName = 'OldPageHeader';

export default OldPageHeader;
