import * as Yup from 'yup';

import { AdditionalInformationSchema } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';
import { RELATED_TO } from 'myaccount/types';

const claimGroupDocumentPageRelatedToClaimSchema = ({
  reasonSelected,
  isMultiProduct,
}: {
  reasonSelected: any;
  isMultiProduct: boolean;
}) => {
  const neededInformationFiltered = isMultiProduct
    ? reasonSelected?.neededInformation
        ?.filter((item: any) => item.type === ADDITIONAL_INFORMATION_TYPES.FILE)
        ?.filter((item: any) => item.relatedTo === RELATED_TO.CLAIM)
    : reasonSelected?.neededInformation?.filter(
        (item: any) => item.type === ADDITIONAL_INFORMATION_TYPES.FILE,
      );

  const reasonAdditionalInformationSchema = AdditionalInformationSchema({
    neededInformationList: neededInformationFiltered,
  });

  return Yup.object().shape({
    reasonAdditionalInformationDocumentRelatedToClaim:
      reasonAdditionalInformationSchema,
  });
};

export default claimGroupDocumentPageRelatedToClaimSchema;
