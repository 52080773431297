import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { NoSolutionReport } from '@savgroup-front-common/core/src/components/NoSolutionReport';
import { claimGroupsDeadEnd } from '@savgroup-front-common/core/src/domains/claims/claimsSelectors/claimGroupsDeadEnd';

import messages from '../ReasonSelectionForm/messages';

const NoSolutionForm = ({ activeProduct }) => {
  const intl = useIntl();

  const { claimGroupId, orderId, claimIds, outOfWarranty } = activeProduct;
  const deadEnd = useSelector((state) => claimGroupsDeadEnd(state, orderId));

  return (
    <>
      {outOfWarranty && (
        <div>
          <FormattedMessage
            {...messages.noIssues}
            values={{
              date: intl.formatDate(Date.now(), {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }),
            }}
          />
        </div>
      )}
      {!outOfWarranty && (
        <NoSolutionReport
          isReportSent={deadEnd.isReportSent}
          groupId={claimGroupId}
          orderId={orderId}
          claimIds={claimIds}
        />
      )}
    </>
  );
};

NoSolutionForm.propTypes = {
  activeProduct: PropTypes.shape({}).isRequired,
  deadEnd: PropTypes.shape({}),
};

NoSolutionForm.defaultProps = {
  deadEnd: {},
};

NoSolutionForm.displayName = 'NoSolutionForm';

export default NoSolutionForm;
