import { UserManager } from 'oidc-client';
import React from 'react';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import useInitApp from './App.hooks';
import Layout from './layout/Layout';
import NewLayout from './NewLayout/NewLayout';

interface AppRoutesProps {
  userManager: { current: UserManager };
}

const AppRoutes: React.FC<AppRoutesProps> = ({ userManager }) => {
  const { isNewDesign, isBtoBPortal, isLoading } = useInitApp();

  if (isLoading) {
    return <BaseLoader />;
  }

  return isNewDesign || isBtoBPortal ? (
    <NewLayout />
  ) : (
    <Layout userManager={userManager} />
  );
};

AppRoutes.displayName = 'AppRoutes';

export default AppRoutes;
