import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Grid } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import { ChevronLeftIcon } from '@savgroup-front-common/core/src/protons/icons';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { AuthConfiguration } from 'myaccount/configuration';
import { Selectors as ClaimCreationSelectors } from 'myaccount/domains/Claim/ClaimCreation';
import { fileProductsSelector } from 'myaccount/domains/Claim/claimGroupSelector';
import { ROUTES } from 'myaccount/view/app';

import OldPageHeader from '../../../components/OldPageHeader';

import { BackButton } from './ClaimGroupIRS.styles';
import messages from './messages';
import ReasonSolutionSelection from './ReasonSolutionSelection';
import useGetUploadedAttachmentsOnClaimGroup from './ReasonSolutionSelection/hooks/useGetUploadedAttachmentsOnClaimGroup';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

const ClaimGroupIRS = ({
  productsIsLoaded,
  currentReasonsLoaded,
  currentClaimsIsLoaded,
  history,
  currentSelectedIssue,
  currentClaimGroupId,
}) => {
  const allProductsInOrder =
    useSelector(ClaimCreationSelectors.currentProductsSelector) || {};
  const { eligibleProducts, notEligibleProducts } = allProductsInOrder;
  const allProducts = eligibleProducts.concat(notEligibleProducts);
  const productsCount = allProducts.reduce(
    (acc, product) => acc + product.ownerProductId.length,
    0,
  );
  const products = useSelector(fileProductsSelector);
  const isReasonSolutionSelectionDisplayed =
    productsIsLoaded &&
    currentReasonsLoaded &&
    currentClaimsIsLoaded &&
    products.length > 0;
  const isGridFluidActive = useMedia(media.maxWidth.md);

  const { claimAttachmentsByProductId } = useGetUploadedAttachmentsOnClaimGroup(
    {
      claimGroupId: currentClaimGroupId,
      products,
    },
  );

  return (
    <Grid fluid={isGridFluidActive}>
      <NeedHelp clientId={AuthConfiguration.clientId} />

      <BackButton
        type={BUTTON_TYPES.BUTTON}
        onClick={() => {
          if (productsCount > 1) {
            return history.push(
              `${ROUTES.CHOOSE_PRODUCTS.replace(
                ':issueId',
                currentSelectedIssue,
              )}?claimGroupId=${currentClaimGroupId}`,
            );
          }

          return history.push(
            `${ROUTES.ISSUES}?claimGroupId=${currentClaimGroupId}`,
          );
        }}
        naked
        icon={<ChevronLeftIcon />}
        componentThemeName={SUPPORTED_COMPONENTS.BACK_BUTTON}
      >
        <FormattedMessage {...messages.back} />
      </BackButton>

      <OldPageHeader type="claim_group_irs" />

      {(!isReasonSolutionSelectionDisplayed ||
        !claimAttachmentsByProductId) && <BaseLoader />}
      {isReasonSolutionSelectionDisplayed && claimAttachmentsByProductId && (
        <ReasonSolutionSelection
          claimAttachmentsByProductId={claimAttachmentsByProductId}
        />
      )}
    </Grid>
  );
};

ClaimGroupIRS.displayName = 'ClaimGroupIRS';

ClaimGroupIRS.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  currentReasonsLoaded: PropTypes.bool.isRequired,
  currentClaimsIsLoaded: PropTypes.bool.isRequired,
  productsIsLoaded: PropTypes.bool,
};

ClaimGroupIRS.defaultProps = {
  productsIsLoaded: false,
};

export default ClaimGroupIRS;
