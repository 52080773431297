import { groupBy, map } from 'lodash';
import moment from 'moment';
import { useQuery } from 'react-query';
import { v4 } from 'uuid';
import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { COUNTRY_CODES, STALE_TIME } from '@savgroup-front-common/constants';

import { CommonCarrierService } from '../api';

const GetPickupSchedule = 'getPickupSchedule';

const adaptPickupSchedule = (
  payload: any,
  meta: { countryCode: COUNTRY_CODES; carrier: string },
) => {
  const { countryCode, carrier } = meta;

  const pickupSchedule = payload.map((appointment: any) => {
    const startLocalTime =
      appointment.startTimeUtc || appointment.startTimeInLocalRecipientTimezone;
    const endLocalTime =
      appointment.endTimeUtc || appointment.endTimeInLocalRecipientTimezone;
    const date = moment(moment(startLocalTime).utc().format('YYYY-MM-DD'));

    return {
      internalId: appointment.internalId || v4(),
      date,
      startLocalTime,
      endLocalTime,
      carrier,
      countryCode,
    };
  });

  return map(groupBy(pickupSchedule, 'date'), (intervals: any, date: Date) => ({
    date,
    intervals,
  }));
};

const useGetHomePickupSchedule = ({
  carrier,
  startTimeInLocalRecipientTimezone,
  endTimeInLocalRecipientTimezone,
  countryCode,
  postalCode,
  fileId,
  isFileHandlingLoading,
}: {
  carrier: string;
  startTimeInLocalRecipientTimezone: string;
  endTimeInLocalRecipientTimezone?: string;
  countryCode: COUNTRY_CODES;
  postalCode?: string;
  fileId: string;
  isFileHandlingLoading?: boolean;
}): any => {
  const { data: detailedSchedule, isLoading } = useQuery(
    [
      GetPickupSchedule,
      { carrier, startTimeInLocalRecipientTimezone, postalCode },
    ],
    async () => {
      if (
        !carrier ||
        !startTimeInLocalRecipientTimezone ||
        !countryCode ||
        isFileHandlingLoading
      ) {
        return undefined;
      }

      const response = await CommonCarrierService.getHomePickupScheduleQuery({
        carrier,
        startTimeInLocalRecipientTimezone,
        endTimeInLocalRecipientTimezone,
        countryCode,
        postalCode,
        fileId,
      });

      if (response.failure) {
        logError(response.errors);

        return undefined;
      }

      return adaptPickupSchedule(response.value, {
        countryCode,
        carrier,
      });
    },
    {
      staleTime: STALE_TIME.MINUTES_10,
    },
  );

  return {
    detailedSchedule,
    isLoading,
  };
};

export default useGetHomePickupSchedule;
