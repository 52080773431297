import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { customerState } from 'myaccount/domains/Customer/selectors';
import { ActionLabel } from 'myaccount/view/components/ActionLabel';
import { CustomerCommentDisplay } from 'myaccount/view/components/CustomerCommentDisplay';

import messages from './messages';

const FileHistory: FC = () => {
  const customer = useSelector(customerState);
  const fileActions = customer?.availableFileActions || [];

  if (fileActions.length <= 1) {
    return null;
  }

  const sortedActions = fileActions.sort(
    (a, b) =>
      new Date(b.fileStatusDate).getTime() -
      new Date(a.fileStatusDate).getTime(),
  );

  return (
    <>
      <Heading level={2}>
        <SafeFormattedMessageWithoutSpread message={messages.history} />
      </Heading>

      {sortedActions.map((action, index) => {
        if (index === 0) {
          return null;
        }

        return (
          <>
            <ActionLabel
              key={`${action.fileStatus.name}-${String(index)}`}
              action={action}
              index={index}
            />
            <CustomerCommentDisplay action={action} />
          </>
        );
      })}
    </>
  );
};

FileHistory.displayName = 'FileHistory';

export default FileHistory;
