import { createSelector } from 'reselect';

import { CommonAppState } from '../CommonAppState';
import { getActionErrorsSelector } from '../sagaRequestMetadata/selectors';

import { types } from './actions';

export const selectLogin = (state: CommonAppState) => state.login;

export const selectFirstName = createSelector(
  [selectLogin],
  (login) => login.firstname,
);

export const permissionsArdLoaded = createSelector(
  [selectLogin],
  (login) => login.isLoaded,
);

export const selectLastName = createSelector(
  [selectLogin],
  (login) => login.lastname,
);

export const selectUserId = createSelector(
  [selectLogin],
  (login) => login.userId,
);

export const selectPermissions = createSelector([selectLogin], (login) => {
  return login.permissions;
});

export const selectLoginErrors = (state: CommonAppState) =>
  getActionErrorsSelector(state, types.LOGIN_SIGNED_ENHANCED);

export const selectAccesses = createSelector(
  [selectLogin],
  (login) => login.accesses,
);

export const selectRoles = createSelector(
  [selectLogin],
  (login) => login.roles,
);

export const selectProfiles = createSelector(
  [selectLogin],
  (login) => login.profiles,
);

export const selectAssignedStoreIds = createSelector(
  [selectLogin],
  (login) => login.assignedStoresIds,
);

export const selectEmail = createSelector([selectLogin], (login) => login.mail);

export const selectPreferences = createSelector(
  [selectLogin],
  (login) => login.preferences,
);

export const selectLastSelectedStore = createSelector(
  [selectPreferences],
  (preferences) => preferences.lastSelectedStore,
);

export const isLoggedInSelector = createSelector(
  [selectLogin],
  (login) => login.isLoggedIn,
);
