import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  HANDLING_GROUPS,
  HANDLING_MODES,
} from '@savgroup-front-common/constants';
import { MessageType } from '@savgroup-front-common/types';

import messages from './messages';

interface GetLabelArgs {
  handlingMode: HANDLING_MODES;
  pickupMode: HANDLING_GROUPS;
}

export const getLabel = ({
  handlingMode,
  pickupMode,
}: GetLabelArgs): MessageType | string => {
  const key = `${handlingMode.toLowerCase()}${pickupMode}`;

  switch (key) {
    case 'depositPickupPoint': {
      return messages.depositPickupPoint;
    }
    case 'deliveryPickupPoint': {
      return messages.deliveryPickupPoint;
    }
    case 'depositPickupStore': {
      return messages.depositPickupStore;
    }
    case 'deliveryPickupStore': {
      return messages.deliveryPickupStore;
    }
    case 'deliveryHome': {
      return messages.deliveryHome;
    }
    case 'depositHome': {
      return messages.depositHome;
    }
    case 'depositExternal': {
      return messages.depositExternal;
    }
    case 'depositHomeIntervention': {
      return messages.depositHomeIntervention;
    }
    case 'deliveryExternal': {
      return messages.deliveryExternal;
    }
    case 'depositImmediateDropAtStore': {
      return messages.depositImmediateDropAtStore;
    }
    case 'deliveryPickupStoreDelivery': {
      return messages.deliveryPickupStoreDelivery;
    }

    default:
      logVerbose(`handlingCard: missing trad`, { handlingMode, pickupMode });

      return key;
  }
};
