export enum ADDITIONAL_INFORMATION_TYPES {
  STRING = 'String',
  TEXT_AREA = 'TextArea',
  ENUM = 'Enum',
  DATE = 'Date',
  INTEGER = 'Integer',
  DECIMAL = 'Decimal',
  IMEI = 'Imei',
  FILE = 'File',
  NOTIFICATION_INFO = 'NotificationInfo',
  NOTIFICATION_ALERT = 'NotificationAlert',
  DIDACTICS = 'Didactics',
  ENUM_VALUE = 'EnumValue',
  SERIAL_NUMBER = 'SerialNumber',
  RMA_AGREEMENT_NUMBER = 'RMAAgreementNumber',
  DANGEROUS_ALERT = 'DangerousAlert',
  EXCHANGE_ORDER_REFERENCE = 'ExchangedOrderReference',

  IRIS_CONDITION_CODE = 'IrisConditionCode',
  IRIS_EXTENDED_CONDITION_CODE = 'IrisExtendedConditionCode',
  IRIS_SYMPTOM_CODE = 'IrisSymptomCode',
  IRIS_SECTION_CODE = 'IrisSectionCode',
  IRIS_DEFECT_CODE = 'IrisDefectCode',
  IRIS_REPAIR_CODE = 'IrisRepairCode',
}

export const TEXT_ADDITIONAL_INFORMATION_TYPES = [
  ADDITIONAL_INFORMATION_TYPES.STRING,
  ADDITIONAL_INFORMATION_TYPES.IMEI,
  ADDITIONAL_INFORMATION_TYPES.INTEGER,
  ADDITIONAL_INFORMATION_TYPES.TEXT_AREA,
];

export enum RELATED_TO {
  CLAIM = 'Claim',
  PRODUCT = 'Product',
}

export interface AdditionalInformationDto {
  id: string;
  key: string;
  name: string;
  isRequired: boolean;
  uniqueName: string;
  type: ADDITIONAL_INFORMATION_TYPES;
  uniquenessByProduct: boolean;
  description: string;
  displayOrder: number;
  possibleValues: AdditionalInformationPossibleValue[];
  validatingRegex?: string;
  importByQrCode: boolean;
  relatedTo: RELATED_TO;
}
export interface AdditionalInformationPossibleValue {
  id: string;
  name: string;
  key: string;
}
