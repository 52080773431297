import React, { FunctionComponent, ReactNode } from 'react';

import { MessageType } from '@savgroup-front-common/types';

import { Banner } from '../../atoms/Banners';
import { Heading } from '../../atoms/Heading';
import { SafeFormattedMessage } from '../../formatters';
import { BugIcon } from '../../protons/icons';

import { $ErrorContainer, $ErrorSpacer } from './ErrorBoundary.styles';
import messages from './messages';

interface BaseErrorBoundaryProps {
  titleMessage?: string | MessageType;
  bodyMessage?: string | MessageType;
  isFullScreen?: boolean;
  icon?: ReactNode;
}

const BaseErrorBoundary: FunctionComponent<
  React.PropsWithChildren<BaseErrorBoundaryProps>
> = ({
  titleMessage = messages.title,
  bodyMessage = messages.didactic,
  icon = <BugIcon color="#fff" size="80px" />,
  isFullScreen = true,
  children,
}) => {
  return (
    <$ErrorContainer $isFull={isFullScreen}>
      <$ErrorSpacer animationDuration={2000}>{icon}</$ErrorSpacer>
      <$ErrorSpacer animationDuration={3000}>
        <Heading level={1} color="white">
          {SafeFormattedMessage(titleMessage)}
        </Heading>
      </$ErrorSpacer>
      <$ErrorSpacer animationDuration={3000}>
        <Banner>{SafeFormattedMessage(bodyMessage)}</Banner>
      </$ErrorSpacer>
      <$ErrorSpacer animationDelay={400} animationDuration={4000}>
        {children}
      </$ErrorSpacer>
    </$ErrorContainer>
  );
};

BaseErrorBoundary.displayName = 'BaseErrorBoundary';

export default BaseErrorBoundary;
