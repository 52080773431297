import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';

import DEFAULT_CONFIG from './Stripe.config.DEFAULT';
import PROD_CONFIG from './Stripe.config.PROD';

const getCurrentStripeConfig = () => {
  switch (currentAppEnvironment) {
    case APP_ENVS.PROD:
      return PROD_CONFIG;
    default:
      return DEFAULT_CONFIG;
  }
};

export default getCurrentStripeConfig();
