import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

const SendIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color} viewBox="0 0 96 96">
    <g id="XMLID_2_">
      <path
        id="XMLID_4_"
        className="st0"
        d="M74.6,20.8c0.7,0.5,1,1.1,0.8,2l-7.9,47.1c-0.1,0.6-0.4,1.1-1,1.4c-0.3,0.2-0.6,0.2-1,0.2
          c-0.2,0-0.5-0.1-0.7-0.2L51,65.7l-7.4,9.1c-0.4,0.5-0.9,0.7-1.5,0.7c-0.3,0-0.5,0-0.7-0.1c-0.4-0.1-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.4-0.7-0.4-1.1V62.8l26.5-32.5L33.9,58.7l-12.1-5c-0.8-0.3-1.2-0.8-1.2-1.7c0-0.8,0.3-1.4,1-1.8l51.1-29.5
          c0.3-0.2,0.6-0.3,1-0.3C73.9,20.5,74.3,20.6,74.6,20.8z"
      />
    </g>
  </Icon>
);

SendIcon.displayName = 'SendIcon';

export default SendIcon;
