import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { useSelector } from 'react-redux';
import { compose } from 'redux';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import { mapStyles, markerShape } from './MapWithControlledZoom.constants';
import useMapWithControlledZoom from './MapWithControlledZoom.hooks';

const HOME_MARKER_URL = `${process.env.REACT_APP_STATIC_HASH}/images/home_marker.svg`;

const MapWithControlledZoom = ({
  onSelectPickupPoint,
  pickupPoints,
  searchAddress,
  selectedPickupPointId,
  bounds,
  center,
}) => {
  const [hasHumanZoom, setHasHumanZoom] = useState(false);
  const [zoom, setZoom] = useState(15);
  const mapRef = useRef(null);

  const sellerId = useSelector((state) => {
    const { items } = state.orders;

    return _.get(_.first(items), 'sellerId', null);
  });
  const { isLoading, adaptedPickupPoints } = useMapWithControlledZoom({
    pickupPoints,
    sellerId,
  });

  const onZoomChanged = () => {
    if (mapRef && mapRef.current) {
      setZoom(mapRef.current.getZoom());
      setHasHumanZoom(true);
    }
  };

  const handleSelectPickupPoint = (pickupPoint) => () => {
    const { latitude, longitude } = pickupPoint;

    if (mapRef && mapRef.current) {
      mapRef.current.panTo({ lat: latitude, lng: longitude });
      setZoom(17);
    }

    onSelectPickupPoint(pickupPoint);
  };

  const autoFocus = useCallback(() => {
    const lat = adaptedPickupPoints[0].latitude;
    const lng = adaptedPickupPoints[0].longitude;

    if (mapRef.current && lat && lng) {
      const x = mapRef.current.getBounds();

      if (x) {
        const northEast = x.getNorthEast();
        const southWest = x.getSouthWest();

        if (
          !(
            (lat < northEast.lat() && lat > southWest.lat()) ||
            (lng < northEast.lng() && lng > northEast.lng())
          )
        ) {
          setZoom(zoom - 1);
        }
      }
    }
  }, [adaptedPickupPoints, zoom]);

  useEffect(() => {
    if (!hasHumanZoom && adaptedPickupPoints.length > 0) {
      autoFocus();
    }
  }, [autoFocus, adaptedPickupPoints, zoom, hasHumanZoom]);

  if (isLoading) {
    return <BaseLoader />;
  }

  if (!bounds) {
    return null;
  }

  return (
    <GoogleMap
      defaultCenter={center}
      zoom={zoom}
      onZoomChanged={onZoomChanged}
      ref={mapRef}
      defaultOptions={{ styles: mapStyles }}
    >
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={500 / Math.max(zoom, 1)}
        zoomOnClick={false}
        maxZoom={15}
      >
        {adaptedPickupPoints?.map((adaptedPickupPoint) => {
          const isSelected = selectedPickupPointId === adaptedPickupPoint.id;
          const zIndex = isSelected ? 1000 : 1;

          return (
            <Marker
              key={adaptedPickupPoint.id}
              position={{
                lat: adaptedPickupPoint.latitude,
                lng: adaptedPickupPoint.longitude,
              }}
              icon={
                isSelected
                  ? adaptedPickupPoint.selectedIcon
                  : adaptedPickupPoint.icon
              }
              onClick={handleSelectPickupPoint(adaptedPickupPoint)}
              title={adaptedPickupPoint.name}
              zIndex={zIndex}
              clickableIcons
              shape={markerShape}
            />
          );
        })}
      </MarkerClusterer>
      <Marker
        position={{
          lat: searchAddress.location.lat,
          lng: searchAddress.location.lng,
        }}
        icon={HOME_MARKER_URL}
      />
    </GoogleMap>
  );
};

MapWithControlledZoom.propTypes = {
  searchAddress: PropTypes.shape().isRequired,
  bounds: PropTypes.shape().isRequired,
  onSelectPickupPoint: PropTypes.func.isRequired,
  pickupPoints: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

MapWithControlledZoom.displayName = 'MapWithControlledZoom';

export default compose(withScriptjs, withGoogleMap)(MapWithControlledZoom);
