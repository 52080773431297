import { fromJS } from 'immutable';
import get from 'lodash/get';

export function onAdditionalInfoSucceeded(state, { meta }) {
  const { claimId, informations } = meta;

  if (!claimId) {
    return state;
  }

  return state.updateIn(['claims', claimId, 'value'], (values) => ({
    ...values,
    additionalClaimInformation: informations.map(({ id, value }) => ({
      additionalInformationId: id,
      additionalInformationEnumValue: value,
      additionalInformationStringValue: value,
    })),
  }));
}

export function onAdditionalInfoFileSucceeded(state, { payload, meta }) {
  const { claimId } = meta;
  const value = get(payload, ['value']);

  if (!claimId) {
    return state;
  }

  return state.updateIn(['claims', claimId, 'value'], (values) => ({
    ...values,
    additionalClaimInformationFile: value,
  }));
}

export function onDeleteAdditionalInfoFileSucceeded(state, { meta }) {
  const { claimId } = meta;

  if (!claimId) {
    return state;
  }

  return state.updateIn(['claims', claimId, 'value'], (values) => ({
    ...values,
    additionalClaimInformationFile: null,
  }));
}

export function onClaimLoaded(state, { payload, meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);
  const claims = get(payload, ['value']);

  if (!claimGroupId) {
    return state;
  }

  let newState = state;

  claims.forEach((claim) => {
    newState = newState
      .setIn(
        ['claims', claim.claimId],
        fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], claim),
      )
      .setIn(
        ['isBypassingDateRules', claim.claimId],
        fromJS({ isLoaded: true, isDirty: false }).setIn(
          ['value'],
          claim.ignoreDateRulesChecking,
        ),
      );

    return undefined;
  });

  return newState;
}

export function onClaimConfirmationPending(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    return state;
  }

  return state.setIn(
    ['claimConfirmationStatus', claimId],
    fromJS({ isLoaded: false, isDirty: true }),
  );
}

export function onClaimConfirmationErrored(state, { errors, meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    return state;
  }

  return state.setIn(
    ['claimConfirmationStatus', claimId],
    fromJS({ isLoaded: true, isDirty: true, hasErrors: true }).setIn(
      ['errors'],
      errors,
    ),
  );
}

export function onClaimConfirmed(state, { payload, meta }) {
  const claimId = get(meta, ['claimId']);
  const claim = get(payload, ['value']);

  if (!claimId) {
    return state;
  }

  return state
    .setIn(
      ['claimConfirmationStatus', claimId],
      fromJS({ isLoaded: true, isDirty: false }),
    )
    .setIn(
      ['claims', claimId],
      fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], claim),
    );
}

export function onClaimGroupConfirmationPending(state, { meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  return state.setIn(
    ['claimConfirmationStatus', claimGroupId],
    fromJS({ isLoaded: false, isDirty: true }),
  );
}

export function onClaimGroupConfirmationErrored(state, { errors, meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  return state.setIn(
    ['claimConfirmationStatus', claimGroupId],
    fromJS({ isLoaded: true, isDirty: true, hasErrors: true }).setIn(
      ['errors'],
      errors,
    ),
  );
}

export function onClaimGroupConfirmed(state, { meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  return state.setIn(
    ['claimConfirmationStatus', claimGroupId],
    fromJS({ isLoaded: true, isDirty: false }),
  );
}

export function onClearClaimConfirmationErrors(state, { payload }) {
  const claimId = get(payload, ['claimId']);

  if (!claimId) {
    return state;
  }

  return state.setIn(
    ['claimConfirmationStatus', claimId],
    fromJS({ isLoaded: true, isDirty: true }),
  );
}

export function onClaimDeleted(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    return state;
  }

  return state.deleteIn(['claims', claimId]);
}
