import gsap from 'gsap';
import React, { FC, useEffect, useRef, useState } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

import { ANIMATION_STATE } from '@savgroup-front-common/constants';

import { $TruckLoaderContainer } from './TruckLoader.styles';

const TruckLoader: FC<{
  animationState: TransitionStatus;
  estimatedWaitingTimeInSeconds?: number;
}> = ({ animationState, estimatedWaitingTimeInSeconds = 3 }) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const truckRef = useRef<HTMLDivElement>(null);

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const container = buttonRef;
    const box = boxRef.current;
    const truck = truckRef.current;

    if (animationState === ANIMATION_STATE.ENTERING) {
      container?.current?.classList?.add('animation');
    } else if (
      buttonRef &&
      buttonRef.current &&
      animationState === ANIMATION_STATE.EXITING
    ) {
      gsap.to(buttonRef?.current, {
        '--progress': 1,
        duration: 0.1,
        ease: 'power2.in',
        onComplete: () => {
          container?.current?.classList.remove('animation');
        },
      });
    } else if (
      container &&
      container.current &&
      buttonRef &&
      buttonRef.current &&
      animationState === ANIMATION_STATE.ENTERED
    ) {
      container?.current?.classList?.add('animation');

      gsap.to(container.current, {
        '--box-s': 1,
        '--box-o': 1,
        duration: 0.3,
        delay: 0.5,
      });

      gsap.to(box, {
        x: 0,
        duration: 0.4,
        delay: 0.7,
      });

      gsap.to(container.current, {
        '--hx': -5,
        '--bx': 50,
        duration: 0.18,
        delay: 0.92,
      });

      gsap.to(box, {
        y: 0,
        duration: 0.1,
        delay: 1.15,
      });

      gsap.set(container.current, {
        '--truck-y': 0,
        '--truck-y-n': -26,
      });

      gsap.to(container.current, {
        '--truck-y': 1,
        '--truck-y-n': -25,
        duration: 0.2,
        delay: 1.25,
        onComplete() {
          gsap
            .timeline({
              onComplete() {
                setCounter(counter + 1);
              },
            })
            .to(truck, {
              x: 0,
              duration: 0.4,
            })
            .to(truck, {
              x: 40,
              duration: 0.6,
            })
            .to(truck, {
              x: 20,
              duration: 1,
            })
            .to(truck, {
              x: 96,
              duration: 0.4,
            });
          gsap.to(buttonRef.current, {
            '--progress': 1,
            duration: estimatedWaitingTimeInSeconds,
            ease: 'power2.in',
          });
        },
      });
    }
  }, [counter, estimatedWaitingTimeInSeconds, animationState]);

  return (
    <$TruckLoaderContainer ref={buttonRef}>
      <div ref={truckRef} className="truck">
        <div className="wheel" />
        <div className="back" />
        <div className="front" />
        <div ref={boxRef} className="box" />
      </div>
    </$TruckLoaderContainer>
  );
};

TruckLoader.displayName = 'TruckLoader';

export default TruckLoader;
