import styled from 'styled-components';

export const $QuotationLine = styled.tr<{
  $isActive: boolean;
  $isSelected: boolean;
}>`
  text-align: left;
  opacity: ${({ $isSelected }) => ($isSelected ? undefined : 0.4)};

  & td {
    padding: 1rem;
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.mainTextColor : theme.colors.paragraphTextColor};
  }
  & td:first-child {
    width: 80%;
  }
`;
