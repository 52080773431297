import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

import { Button } from '../../../../../atoms/button';

export const $ButtonGroup = styled.div`
  display: flex;

  @media ${media.maxWidth.sm} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  & > button input {
    display: none;
    visibility: hidden;
  }
  & > button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  & > button:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const $ConditionSelection = styled.div<{ $isFluid: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ $isFluid }) => ($isFluid ? '100%' : null)};

  & > div {
    width: ${({ $isFluid }) => ($isFluid ? '100%' : null)};
  }
`;

export const $UsedButton = styled(Button)`
  color: ${({ theme }) => theme.colors.success};
  & svg {
    fill: ${({ theme }) => theme.colors.success};
  }

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.success};
  }
  &:hover svg,
  &:focus svg,
  &:hover svg {
    fill: ${({ theme }) => theme.colors.success};
  }
`;

export const $HiddenBlock = styled.div`
  display: none;
`;
