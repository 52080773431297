import get from 'lodash/get';
import result from 'lodash/result';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { HANDLING_MODES } from '@savgroup-front-common/constants';
import { verifiedAddress } from '@savgroup-front-common/core/src/domains/carriers/verifiedAddress/selectors';
import { AddressInfo, AddressInfoDto } from '@savgroup-front-common/types';
import { selectGroupHandlingInfo } from 'myaccount/domains/Claim/claimGroupSelector';
import { formatVerifiedAddress } from 'myaccount/formatters/carrier';
import { useLayout } from 'myaccount/view/app/NewLayout/NewLayout.hooks';

import adaptAddressInfo from '../../../helpers/adaptAddressInfo';

interface UseGetHomePickupReturnValue {
  homePickupAddress?: AddressInfoDto;
  mail?: string;
}

const useGetHomePickup = (): UseGetHomePickupReturnValue => {
  const { login } = useLayout();

  const verifiedAddressValue = useSelector(verifiedAddress);
  const verifiedAddressToJS = result(verifiedAddressValue.get('value'), 'toJS');

  const handlingInfo = useSelector(selectGroupHandlingInfo) as any;
  const currentHandlingAddressDeposit = get(handlingInfo, [
    HANDLING_MODES.DEPOSIT,
    'address',
  ]);
  const handlingAddress = adaptAddressInfo({
    address: currentHandlingAddressDeposit,
  });

  const formattedAddress = formatVerifiedAddress(
    verifiedAddressToJS,
    handlingAddress,
  );
  const homePickupAddressDefault = formattedAddress?.address
    ? formattedAddress
    : handlingAddress;

  const [homePickupAddress, setHomePickupAddress] = useState<
    | (AddressInfoDto & {
        firstName?: AddressInfo['firstName'];
        lastName?: AddressInfo['lastName'];
      })
    | undefined
  >(undefined);

  useDeepCompareEffect(() => {
    if (homePickupAddressDefault?.address) {
      setHomePickupAddress(homePickupAddressDefault);
    } else {
      setHomePickupAddress(adaptAddressInfo({ address: login?.ownerAddress }));
    }
  }, [homePickupAddressDefault, login?.ownerAddress]);

  return {
    homePickupAddress,
    mail: login.email,
  };
};

export default useGetHomePickup;
