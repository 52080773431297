import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AdditionalInformation } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm';
import { Accordion } from '@savgroup-front-common/core/src/molecules/Accordion';

import { AdaptedNeededInformation } from './AdditionalInformationExpect.types';

interface ProductAdditionalInformationProps {
  product: {
    neededInformation: AdaptedNeededInformation[];
    modelName?: string;
  };
}

const ProductAdditionalInformation: FC<ProductAdditionalInformationProps> = ({
  product,
}) => {
  const formContext = useFormContext();
  const {
    formState: { errors },
  } = formContext;
  const errorsAddInformationIds = Object.keys(errors);

  const [isOpen, setIsOpen] = useState(true);
  const isErrored = product.neededInformation
    .map((item) => item.id)
    .some((id) => errorsAddInformationIds.includes(id));

  useEffect(() => {
    if (isErrored) {
      setIsOpen(true);
    }
  }, [isErrored]);

  return (
    <Accordion
      isOpen={isOpen}
      onClick={() => setIsOpen(!isOpen)}
      title={product.modelName || '-'}
      isErrored={isErrored}
      unmountOnExit={false}
    >
      <AdditionalInformation
        ignoreUniquenessByProduct
        formContext={formContext}
        neededInformation={product.neededInformation as any}
      />
    </Accordion>
  );
};

ProductAdditionalInformation.displayName = 'ProductAdditionalInformation';

export default ProductAdditionalInformation;
