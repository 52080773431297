import { defineMessages } from 'react-intl';

export default defineMessages({
  created: {
    id: 'view.card.quote.status.Created',
    defaultMessage: 'Created',
  },
  draft: {
    id: 'view.card.quote.status.Draft',
    defaultMessage: 'Draft',
  },
  paid: {
    id: 'view.card.quote.status.Paid',
    defaultMessage: 'Paid',
  },
  approved: {
    id: 'view.card.quote.status.Approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'view.card.quote.status.Rejected',
    defaultMessage: 'Rejected',
  },
  cancelled: {
    id: 'view.card.quote.status.Cancelled',
    defaultMessage: 'Cancelled',
  },
  pendingCustomerApproval: {
    id: 'view.card.quote.status.PendingCustomerApproval',
    defaultMessage: 'Pending Customer Approval',
  },
});
