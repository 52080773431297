import { defineMessages } from 'react-intl';

export default defineMessages({
  cancelClaim: {
    id: 'view.myaccount.claims.cancelClaim',
    defaultMessage: 'Annuler la déclaration',
  },
  orderNumber: {
    id: 'view.myaccount.claims.orderNumber',
    defaultMessage: 'Order number N°{orderReference}',
  },
  options: {
    id: 'components.common.loading.searchingOptions',
    defaultMessage:
      'We are searching for available options. Please wait a moment...',
  },
});
