import head from 'lodash/head';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ALLOWED_MIME_TYPES } from '@savgroup-front-common/constants';
import { MultiFileUploadHookForm } from '@savgroup-front-common/core/src/atoms/Form';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import useHandleDeleteAttachmentOnClaimGroup from './hooks/useHandleDeleteAttachmentOnClaimGroup';
import useHandleSetAttachmentOnClaimGroup from './hooks/useHandleSetAttachmentOnClaimGroup';

const ReasonFileUpload = ({ name, activeProduct }) => {
  const formContext = useFormContext();
  const {
    formState: { errors },
    control,
    trigger,
  } = formContext;
  const { claimGroupId, claimId, claimIds } = activeProduct;

  const { pushErrors } = useToasts();
  const handleDeleteAttachmentOnClaimGroup =
    useHandleDeleteAttachmentOnClaimGroup({
      claimGroupId,
    });
  const handleSetAttachementOnClaimGroup = useHandleSetAttachmentOnClaimGroup({
    claimGroupId,
    claimId,
    claimIds,
  });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <MultiFileUploadHookForm
            hollow
            isFullWidth
            name={field.name}
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            onSelect={async (files) => {
              const file = head(files);
              const fileIndex = field.value.length;

              const response = await handleSetAttachementOnClaimGroup({
                file,
                notify: ({ progress }) => {
                  file.progress = progress;
                  field.onChange([...(field.value || []), file]);
                },
              });

              if (response.failure) {
                file.errors = response.errors;
                field.onChange([...(field.value || []), file]);
                await trigger(`${name}.${fileIndex}`);
              }

              file.id = response.value.id;
              field.onChange([...(field.value || []), file]);
            }}
            files={field.value}
            onRemove={async (_, index) => {
              const fileToDelete = field.value.find(
                (item, itemIndex) => index === itemIndex,
              );

              if (!fileToDelete.id) {
                field.onChange(
                  field.value.filter((item, itemIndex) => index !== itemIndex),
                );
                await trigger();

                return;
              }

              const response = await handleDeleteAttachmentOnClaimGroup({
                documentId: fileToDelete.id,
              });

              if (response.failure) {
                pushErrors(response.errors);

                return;
              }

              field.onChange(
                field.value.filter((item, itemIndex) => index !== itemIndex),
              );
              await trigger();
            }}
            errors={errors}
            value={field.value}
            dataTestId="reasonFileUpload"
          />
        );
      }}
    />
  );
};

ReasonFileUpload.displayName = 'ReasonFileUpload';

export default ReasonFileUpload;
