import filter from 'lodash/filter';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import size from 'lodash/size';
import qs from 'qs';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';

import { Selectors as ClaimSelectors } from '@savgroup-front-common/core/src/domains/claims';
import {
  pathname,
  search,
} from '@savgroup-front-common/core/src/domains/selectors';

import { ROUTES } from '../../view/app/routes/Routes';

const GROUP_KEYS = {
  NON_ELIGIBLE_FOR_RETURN: 'NonEligibleForReturn',
  VIEW_FILE: 'ViewFile',
  FINALIZE_CLAIM_GROUP: 'FinalizeClaimGroup',
  FINALIZE_CLAIM: 'FinalizeClaim',
  OTHERS: 'Others',
};

export const currentOrderIdSelector = createSelector([pathname], (path) => {
  const options = matchPath(path, {
    path: ROUTES.ORDER_CREATE_CLAIM,
    exact: false,
    strict: false,
  });

  return get(options, 'params.orderId');
});

export const currentClaimGroupIdSelector = createSelector(
  [search],
  (searchString) => {
    const params = qs.parse(searchString, { ignoreQueryPrefix: true });

    return get(params, 'claimGroupId');
  },
);

const currentClaimGroup = createSelector(
  [currentClaimGroupIdSelector, ClaimSelectors.claimGroups],
  (claimGroupId, claimGroups) =>
    claimGroups.getIn([claimGroupId, 'value']) || [],
);

const ordersState = (state) => state.orders;

export const ordersSelector = createSelector(
  [ordersState, currentOrderIdSelector],
  (orders, currentOrderId) => {
    if (currentOrderId) {
      return filter(orders.items, (order) => order.orderId === currentOrderId);
    }

    return orders.items;
  },
);

export const ordersIsLoadedSelector = createSelector([ordersState], (orders) =>
  get(orders, 'isLoaded'),
);

const sortProductsByDateAndAvailability = (products = []) => {
  return orderBy(
    products,
    [
      (product) => new Date(get(product, 'purchaseDate')).getTime(),
      'notOpenForClaims',
    ],
    ['desc', 'asc'],
  );
};

export const openFilesSelector = (state) =>
  get(state, ['myReturn', 'historic', 'openFiles']);
export const closedFilesSelector = (state) =>
  get(state, ['myReturn', 'historic', 'closedFiles']);

export const openedFilesFromOrderId = createSelector(
  [openFilesSelector, (state, orderId) => orderId],
  (openedFils, orderId) =>
    openedFils.filter((file) => get(file, 'orderId', null) === orderId),
);

export const closedFilesFromOrderId = createSelector(
  [closedFilesSelector, (state, orderId) => orderId],
  (closedFiles, orderId) =>
    closedFiles.filter((file) => get(file, 'orderId', null) === orderId),
);

export const currentClaimProducts = createSelector(
  [currentClaimGroup, ClaimSelectors.products],
  (claimGroup, products) =>
    claimGroup.map((claimId) =>
      get(products.getIn([claimId, 'value']), 'productId'),
    ),
);

export const groupedProductSelector = createSelector(
  [
    ordersSelector,
    openFilesSelector,
    closedFilesSelector,
    currentClaimGroupIdSelector,
  ],
  (orders, openFiles, closedFiles, claimGroupId) => {
    const findFile = (files, ownerProductId) =>
      maxBy(
        filter(files, (file) => find(file.fileProducts, { ownerProductId })),
        'createdDate',
      );
    const products = flatMap(orders, (order) =>
      map(order.products, (p) => ({
        ...p,
        ...omit(order, 'products'),
        orderNumber: order.reference,
        salesChannel: order.marketPlace,
        ongoingFile: findFile(openFiles, p.ownerProductId),
        previousFile: findFile(closedFiles, p.ownerProductId),
        disabled: get(p, 'notOpenForClaims'),
      })),
    );

    const {
      ViewFile,
      FinalizeClaimGroup,
      FinalizeClaim,
      Others,
      NonEligibleForReturn,
    } = groupBy(products, (product) => {
      if (product.disabled) {
        return GROUP_KEYS.NON_ELIGIBLE_FOR_RETURN;
      }

      if (product.ongoingFile) {
        return GROUP_KEYS.VIEW_FILE;
      }
      if (product.claimGroupId === claimGroupId) {
        return GROUP_KEYS.OTHERS;
      }
      if (product.claimGroupId) {
        return GROUP_KEYS.FINALIZE_CLAIM_GROUP;
      }
      if (product.claimContextId) {
        return GROUP_KEYS.FINALIZE_CLAIM;
      }

      return GROUP_KEYS.OTHERS;
    });

    return {
      ViewFile: groupBy(ViewFile, (p) => p.ongoingFile.fileReference),
      FinalizeClaimGroup: groupBy(FinalizeClaimGroup, (p) => p.claimGroupId),
      FinalizeClaim: FinalizeClaim || [],
      Others: sortProductsByDateAndAvailability(Others || []),
      NonEligibleForReturn: sortProductsByDateAndAvailability(
        NonEligibleForReturn || [],
      ),
      productsCount: size(products),
    };
  },
);
