enum CLAIM_STATE {
  CREATED = 'Created',
  SYMPTOMS = 'WaitingForSymptom',
  DIAGNOSTIC = 'DiagnosticInProgress',
  DIAGNOSTIC_REASON = 'WaitingForDiagnosticConfirmation',
  REASONS = 'WaitingForReason',
  SOLUTIONS = 'WaitingForSolution',
  HANDLING_DEPOSIT = 'WaitingForHandlingDepositInfo',
  HANDLING_DELIVERY = 'WaitingForHandlingDeliveryInfo',
  HANDLING_ROUNDTRIP_DEPOSIT = 'WaitingForRoundTripHandlingDepositInfo',
  HANDLING_ROUNDTRIP_DELIVERY = 'WaitingForRoundTripHandlingDeliveryInfo',
  HANDLING_IAD = 'WaitingForOnSiteInterventionInfo',
  CONFIRMATION = 'Confirmation',
  RMA = 'ClosedForRma',
  CLOSED = 'Closed',
}

export default CLAIM_STATE;
