import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

const DeleteIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z" />
    </Icon>
  );
};

DeleteIcon.displayName = 'DeleteIcon';

export default DeleteIcon;
