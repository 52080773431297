import md5 from 'md5';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';

import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { Link } from '@savgroup-front-common/core/src/atoms/link';

import { AuthConfiguration } from '../../../../../configuration';
import { LogoContainer, LogoImg } from '../AppHeader.styles';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

const Logo = ({ url, logoUrl }) => {
  const imgRef = useRef(null);

  const hashedClientId = md5(AuthConfiguration.clientId);
  const logoFromUiStyles = `${APIConfiguration.uiStylesCDN}logos/${hashedClientId}`;
  const logoFallback = `${process.env.REACT_APP_STATIC_HASH}/images/logo-blank-rvl.svg`;

  const [listAlternatives, setListAlternatives] = useState(
    [logoUrl, logoFromUiStyles, logoFallback].filter((item) => item),
  );
  const [firstAlternative, ...rest] = listAlternatives;

  const onError = useCallback(() => {
    if (imgRef.current) {
      imgRef.current.onerror = rest.length > 0 ? onError : null;
      imgRef.current.src = firstAlternative;

      setListAlternatives(rest);
    }
  }, [firstAlternative, rest]);

  return (
    <Link {...url} dataTestId="headerlogo-image">
      <LogoContainer componentThemeName={SUPPORTED_COMPONENTS.LOGO}>
        <LogoImg
          ref={imgRef}
          alt="logo"
          src={firstAlternative}
          onError={onError}
        />
      </LogoContainer>
    </Link>
  );
};

Logo.propTypes = {
  logoUrl: PropTypes.string,
  companyPicture: PropTypes.string,
  url: PropTypes.shape({
    isExternal: PropTypes.bool.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired,
};
Logo.defaultProps = {
  logoUrl: undefined,
  companyPicture: undefined,
};

Logo.displayName = 'Logo';

export default Logo;
