import { useQuery } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { PaymentService } from 'myaccount/api';

import { useConfirmPaymentIntent } from '../../InvoicePaymentForm/hooks/useCOnfirmPaymentIntent';

const useCreatePaymentIntent = ({ invoiceId }) => {
  const { pushErrors } = useToasts();

  const { handleConfirmPaymentIntent } = useConfirmPaymentIntent({
    invoiceId,
  });

  const { data: paymentIntentClientSecret } = useQuery(
    ['CreatePaymentIntent', { invoiceId }],
    async () => {
      if (!invoiceId) {
        return undefined;
      }

      const response = await PaymentService.createPaymentIntent({ invoiceId });

      if (response.failure) {
        if (
          response.errors.some(
            (error) =>
              error.code === 'Reverse.Payment.Errors.Payment.CannotPaidTwice',
          )
        ) {
          try {
            await handleConfirmPaymentIntent();
          } catch (error) {
            pushErrors(error);

            return undefined;
          }
        }

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
    },
  );

  return paymentIntentClientSecret;
};

export default useCreatePaymentIntent;
