import { yupResolver } from '@hookform/resolvers/yup';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { useConfirmPaymentIntent } from '../../../../../components/InvoicePaymentForm/hooks/useCOnfirmPaymentIntent';

import checkoutFormSchema from './CheckoutForm.schema';
import { CheckoutFormValues } from './CheckoutForm.types';

interface UseCheckoutFormArgs {
  onAllSubmit: () => void;
  invoiceId: string;
}

const useCheckoutForm = ({ onAllSubmit, invoiceId }: UseCheckoutFormArgs) => {
  const [paymentLoading, setPaymentLoading] = useState(false);
  const { pushErrors } = useToasts();
  const stripe = useStripe();
  const elements = useElements();

  const formContext = useForm<CheckoutFormValues>({
    resolver: yupResolver(checkoutFormSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const { handleSubmit } = formContext;

  const { handleConfirmPaymentIntent } = useConfirmPaymentIntent({
    invoiceId,
  });

  const onSubmit = handleSubmit(async ({ name }) => {
    setPaymentLoading(true);
    if (!stripe || !elements) {
      return undefined;
    }

    const responseStripe = await stripe.confirmPayment({
      elements,
      redirect: 'if_required' as any,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name,
          },
        },
      },
    });

    if (responseStripe.error) {
      const errors = new Error(responseStripe.error.message);

      pushErrors([errors]);

      return undefined;
    }

    try {
      await handleConfirmPaymentIntent();
    } catch (error: any) {
      pushErrors(error);

      return undefined;
    }

    setPaymentLoading(false);
    onAllSubmit();

    return undefined;
  });

  return {
    formContext,
    onSubmit,
    paymentLoading,
  };
};

export default useCheckoutForm;
