import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import matches from 'lodash/matches';
import { replace } from 'redux-first-history';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { ActionCreators as ClaimActionCreators } from '@savgroup-front-common/core/src/domains/claims';
import * as ClaimActionTypes from '@savgroup-front-common/core/src/domains/claims/actionTypes';
import { LOAD_ISSUES_OPTIONS_FROM_ALL_ORDERS } from '@savgroup-front-common/core/src/domains/claims/actionTypes';

import { ROUTES } from '../../view/app/routes/Routes';
import {
  ActionTypes as ClaimCreationActionTypes,
  Selectors as ClaimCreationSelectors,
} from '../Claim/ClaimCreation';
import {
  chooseIssueEnd,
  chooseIssueSuccess,
  returnProducts,
} from '../Claim/ClaimCreation/actionCreators';
import { loadOrdersData } from '../Orders/actionCreators';
import { LOAD_ORDERS_DATA } from '../Orders/actionTypes';
import { loadOrdersDataWorker } from '../Orders/saga';

import { currentClaimGroupIdSelector, ordersSelector } from './groupedProduct';
import { mergedIssuesSelector } from './issues';

const ordersState = (state) => state.orders;

function* chooseIssueWorker({ payload: issueId }) {
  const currentClaimGroupId = yield select(currentClaimGroupIdSelector);
  const products = yield select(
    ClaimCreationSelectors.currentProductsSelector,
  ) || {};
  const { eligibleProducts, notEligibleProducts } = products;
  const allProducts = eligibleProducts.concat(notEligibleProducts);
  const productsCount = allProducts.reduce(
    (acc, product) => acc + product.ownerProductId.length,
    0,
  );

  yield put(ClaimCreationActionTypes.CHOOSE_ISSUE.start());

  if (currentClaimGroupId && productsCount > 1) {
    yield all([
      put(ClaimActionCreators.deleteClaimGroupById(currentClaimGroupId)),
      take(ClaimActionTypes.DELETE_CLAIM_GROUP_BY_ID.SUCCEEDED),
      put(loadOrdersData({ forceRefresh: true })),
      take(LOAD_ORDERS_DATA.SUCCEEDED),
    ]);
  }

  yield put(chooseIssueSuccess());
  if (productsCount > 1) {
    yield put(replace(ROUTES.CHOOSE_PRODUCTS.replace(':issueId', issueId)));
  } else {
    const ownerProductIds = head(allProducts).ownerProductId;

    yield put(returnProducts({ issueId, ownerProductIds }));
  }
  yield put(chooseIssueEnd());
}
function* chooseIssueWatcher() {
  yield takeEvery(
    ClaimCreationActionTypes.CHOOSE_ISSUE.BASE,
    chooseIssueWorker,
  );
}

function* loadIssuesWorker() {
  yield put(ClaimActionCreators.loadIssuesFromAllOrderStarted());

  const ordersIsLoaded = get(yield select(ordersState), 'isLoaded');

  if (!ordersIsLoaded) {
    yield call(loadOrdersDataWorker);
  }

  const orderIds = map(yield select(ordersSelector), (order) => order.orderId);

  for (const orderId of orderIds) {
    yield all([
      put(ClaimActionCreators.loadIssuesByOrder({ orderId })),
      take(
        matches({
          type: ClaimActionTypes.LOAD_ISSUES_OPTIONS_BY_ORDER.END,
          meta: { orderId },
        }),
      ),
    ]);
  }

  const mergedIssues = yield select(mergedIssuesSelector);

  if (mergedIssues.length === 0) {
    logCritical(`We got no issues for orderIds: ${JSON.stringify(orderIds)} !`);
  }

  yield put(ClaimActionCreators.loadIssuesFromAllOrderSucceeded());
}

function* loadIssuesWatcher() {
  yield takeEvery(LOAD_ISSUES_OPTIONS_FROM_ALL_ORDERS.BASE, loadIssuesWorker);
}

export default function* mainSaga() {
  yield all([chooseIssueWatcher(), loadIssuesWatcher()]);
}
