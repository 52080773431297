const { hostname } = window.location;

const authorityByClientId = {
  'preprod-selfcare-revers.io': 'https://preprod-selfcare-login.revers.io',
};

function setAuthority(authority = null) {
  const { clientId } = this;

  const defaultAuthority = authorityByClientId[clientId];

  this.authority = authority || defaultAuthority;

  return this;
}

const defaultClientId = 'preprod-selfcare-revers.io';

function computeClientId() {
  if (process.env.REACT_APP_CLIENTID) {
    return process.env.REACT_APP_CLIENTID;
  }
  if (hostname !== 'localhost') {
    return hostname;
  }

  return defaultClientId;
}

const clientId = computeClientId();

const AuthConfiguration = {
  clientId,
  authority: authorityByClientId[clientId],
  scope: 'openid email api',
  setAuthority,
};

export default AuthConfiguration;
