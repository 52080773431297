import React from 'react';

interface CrossIconProps {
  color?: string;
}

/* eslint-disable max-len */
const CrossIcon: React.FC<CrossIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2634 28.7383L20.0018 20M28.7401 11.2617L20.0001 20M20.0001 20L11.2634 11.2617M20.0018 20L28.7401 28.7383"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

CrossIcon.displayName = 'CrossIcon';

export default CrossIcon;
