import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { ActionRow } from '@savgroup-front-common/core/src/atoms/ActionRow';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { Field } from '@savgroup-front-common/core/src/molecules/Field';
import { ConfirmModal } from '@savgroup-front-common/core/src/molecules/ConfirmModal';
import { RescheduleTechnicalInterventionModal } from '@savgroup-front-common/core/src/components/InterventionAppointment';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BannersConsumer } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import {
  TechnicalInterventionStatus,
  TechnicalInterventionStatusMap,
} from '@savgroup-front-common/types';

import { useFileFollowupsContext } from '../../../pages/FileFollowupsPage/FileFollowups/FileFollowups.context';

import { useConfirmedTechnicalInterventionSchedule } from './ConfirmedTechnicalInterventionSchedule.hooks';
import { $Col } from './ConfirmedTechnicalInterventionSchedule.styles';
import messages from './messages';
import { MODAL_SIZES } from '@savgroup-front-common/core/src/atoms/modal';
import {
  FreeCancellation,
  UpdateIcon,
} from '@savgroup-front-common/core/src/protons/icons';

type ConfirmedTechnicalInterventionScheduleProps = React.PropsWithChildren<{
  fileId: string;
}>;

const ConfirmedTechnicalInterventionSchedule: FunctionComponent<
  ConfirmedTechnicalInterventionScheduleProps
> = ({ fileId }) => {
  const { fileSummary } = useFileFollowupsContext();
  const {
    setIsRescheduleModalOpen,
    isConfirmModalOpen,
    isRescheduleModalOpen,
    confirmedScheduleText,
    latestTechnicalIntervention,
    handleCancelTechnicalInterventionSchedule,
    isCancelTechnicalInterventionLoading,
    setIsConfirmModalOpen,
  } = useConfirmedTechnicalInterventionSchedule({ fileId });
  const isRescheduleEnabled = latestTechnicalIntervention?.isReSchedulable;

  if (!latestTechnicalIntervention) {
    return <></>;
  }

  const isAllowedToCancel =
    TechnicalInterventionStatusMap.Scheduled.includes(
      latestTechnicalIntervention.status,
    ) ||
    latestTechnicalIntervention.status === TechnicalInterventionStatus.NONE ||
    latestTechnicalIntervention.status === undefined;

  return (
    <Row>
      <Col xs={12} md={6} mdOffset={3}>
        <Card>
          <Heading level={4}>
            <SafeFormattedMessageWithoutSpread
              message={messages.confirmedTechnicalInterventionScheduleTitle}
            />
          </Heading>

          <BannersConsumer />

          <Row>
            <$Col xs={12} md={12}>
              <Field
                label={
                  messages.confirmedTechnicalInterventionScheduleDescriptionLabel
                }
                isEllipsisEnabled={false}
              >
                <SafeFormattedMessageWithoutSpread
                  message={
                    messages.confirmedTechnicalInterventionScheduleDescription
                  }
                />
              </Field>
            </$Col>
            {confirmedScheduleText && (
              <$Col xs={12} md={12}>
                <Field
                  label={messages.confirmedTechnicalInterventionScheduleLabel}
                  copy={confirmedScheduleText}
                  isEllipsisEnabled={false}
                >
                  {confirmedScheduleText}
                </Field>
              </$Col>
            )}
          </Row>

          <ConfirmModal
            isOpen={isConfirmModalOpen}
            size={MODAL_SIZES.SMALL}
            title={messages.confirmModalTitle}
            didactic={messages.confirmModalDidactic}
            cancelMessage={messages.cancel}
            confirmMessage={messages.confirm}
            onCancel={() => setIsConfirmModalOpen(false)}
            onConfirm={handleCancelTechnicalInterventionSchedule}
            isLoading={isCancelTechnicalInterventionLoading}
          >
            <SafeFormattedMessageWithoutSpread
              message={
                messages.technicalInterventionScheduleCancellationConfirmMessage
              }
              values={{
                confirmedScheduleText: confirmedScheduleText || '',
              }}
            />
          </ConfirmModal>

          {isRescheduleEnabled && (
            <RescheduleTechnicalInterventionModal
              fileId={fileId}
              sellerId={fileSummary?.sellerId || ''}
              isOpen={isRescheduleModalOpen}
              onRescheduleSuccess={() => {
                setIsRescheduleModalOpen(false);
              }}
              onCancel={() => setIsRescheduleModalOpen(false)}
            />
          )}

          <ActionRow>
            {isAllowedToCancel && (
              <Button
                type={BUTTON_TYPES.BUTTON}
                hollow={isRescheduleEnabled}
                primary
                icon={<FreeCancellation />}
                onClick={() => setIsConfirmModalOpen(true)}
                isLoading={isCancelTechnicalInterventionLoading}
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.cancelTechnicalInterventionSchedule}
                />
              </Button>
            )}

            {isRescheduleEnabled && (
              <Button
                type={BUTTON_TYPES.BUTTON}
                primary
                icon={<UpdateIcon />}
                onClick={() => setIsRescheduleModalOpen(true)}
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.rescheduleTechnicalInterventionSchedule}
                />
              </Button>
            )}
          </ActionRow>
        </Card>
      </Col>
    </Row>
  );
};

ConfirmedTechnicalInterventionSchedule.displayName =
  'ConfirmedTechnicalInterventionSchedule';

export default ConfirmedTechnicalInterventionSchedule;
