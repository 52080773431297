import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';
import { Timeslot } from '@savgroup-front-common/types';

import { ErrorFromBack } from '../../../../helpers';

import { INTERVENTION_APPOINTMENT_ACTION_TYPES } from './TakeInterventionAppointment.types';

export interface InterventionAppointmentState {
  hasErrors: boolean;
  errors: ErrorFromBack[] | Error[];
  selectedTimeslot?: Timeslot;
}

export const interventionAppointmentInit =
  (): InterventionAppointmentState => ({
    hasErrors: false,
    errors: [],
  });

export type InterventionAppointmentAction =
  | {
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.VALIDATE_TIMESLOT;
    }
  | {
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.SET_ERRORS;
      payload: { errors: ErrorFromBack[] | Error[] };
    }
  | {
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.SELECT_TIMESLOT;
      payload: { selectedTimeslot: Timeslot };
    };

export const takeInterventionAppointmentReducer = (
  state: InterventionAppointmentState,
  action: InterventionAppointmentAction,
): InterventionAppointmentState => {
  switch (action.type) {
    case INTERVENTION_APPOINTMENT_ACTION_TYPES.SET_ERRORS: {
      return {
        ...state,
        hasErrors: true,
        errors: action.payload.errors,
      };
    }
    case INTERVENTION_APPOINTMENT_ACTION_TYPES.VALIDATE_TIMESLOT: {
      return {
        ...state,
        hasErrors: false,
        errors: [],
      };
    }
    case INTERVENTION_APPOINTMENT_ACTION_TYPES.SELECT_TIMESLOT: {
      return {
        ...state,
        selectedTimeslot: action.payload.selectedTimeslot,
      };
    }

    default: {
      throw new ActionTypeIsNotSupportedError();
    }
  }
};
