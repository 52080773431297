import { useCallback, useRef, useState } from 'react';

interface Options {
  shouldPreventDefault?: boolean;
  delay?: number;
}

interface UseEnhancedLongPressArgs {
  onClick?: () => void;
  onLongPress?: () => void;
  options?: Options;
}

const isTouchEvent = (event: Event): event is TouchEvent => {
  return 'touches' in event;
};

const preventDefault: EventListener = (event) => {
  if (isTouchEvent(event) && event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

const useEnhancedLongPress = ({
  onLongPress,
  onClick,
  options: { shouldPreventDefault = true, delay = 300 } = {
    shouldPreventDefault: true,
    delay: 300,
  },
}: UseEnhancedLongPressArgs) => {
  const [isLongPressSuccess, setIsLongPressSuccess] = useState(false);
  const [isPressing, setIsPressing] = useState(false);
  const [isLongPressPending, setIsLongPressPending] = useState(false);
  const timeout = useRef<any | undefined>();
  const target = useRef<EventTarget>();

  const start = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      if (onLongPress) {
        setIsPressing(true);
        setIsLongPressPending(true);
      }
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
        });
        target.current = event.target;
      }
      timeout.current = onLongPress
        ? setTimeout(() => {
            setIsLongPressSuccess(true);
            setIsLongPressPending(false);
          }, delay)
        : undefined;
    },
    [onLongPress, delay, shouldPreventDefault],
  );

  const clear = useCallback(
    (event: React.MouseEvent | React.TouchEvent, shouldTriggerClick = true) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      if (shouldTriggerClick && !isLongPressSuccess && onClick) {
        onClick();
      }
      if (shouldTriggerClick && isLongPressSuccess && onLongPress) {
        onLongPress();
      }
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefault);
      }

      if (onLongPress) {
        setIsLongPressSuccess(false);
        setIsLongPressPending(false);
        setIsPressing(false);
      }
    },
    [onLongPress, isLongPressSuccess, onClick, shouldPreventDefault],
  );

  return {
    isPressing,
    isLongPressPending,
    isLongPressSuccess,
    onMouseDown: (e: React.MouseEvent) => start(e),
    onTouchStart: (e: React.TouchEvent) => start(e),
    onMouseUp: (e: React.MouseEvent) => clear(e),
    onMouseLeave: (e: React.MouseEvent) => clear(e, false),
    onTouchEnd: (e: React.TouchEvent) => clear(e),
  };
};

export default useEnhancedLongPress;
