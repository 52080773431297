import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.myaccount.claims.confirmation.reasonAndSolution',
    defaultMessage: 'Reason and solution',
  },
  reason: {
    id: 'view.card.claim.reasonName',
    defaultMessage: 'Reason',
  },
  solution: {
    id: 'components.myaccount.common.solution',
    defaultMessage: 'Solution',
  },
  comment: {
    id: 'components.myaccount.claims.reasons.choice.comment',
    defaultMessage: 'Comment',
  },
  noComment: {
    id: 'components.myaccount.claims.confirmation.noComment',
    defaultMessage: 'No comment',
  },
  documents: {
    id: 'components.myaccount.claims.confirmation.documents',
    defaultMessage: 'Document(s)',
  },
  noDocuments: {
    id: 'components.myaccount.claims.confirmation.noDocuments',
    defaultMessage: 'No documents uploaded',
  },
  free: {
    id: 'components.myaccount.claims.confirmation.free',
    defaultMessage: 'Free',
  },
  editReasonSolution: {
    id: 'components.myaccount.claims.confirmation.edit',
    defaultMessage: 'Edit',
  },
});
