import React from 'react';

interface HeartPulseIconProps {
  color?: string;
}

/* eslint-disable max-len */
const HeartPulseIcon: React.FC<HeartPulseIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3775 15.9792L12.2133 18.3075L9.98668 9.40083C9.94001 9.21583 9.77334 9.08583 9.58334 9.08583C9.39334 9.08583 9.22668 9.21583 9.18001 9.40083L7.59168 15.7525H1.66751V16.5858H7.91751C8.10751 16.5858 8.27418 16.4558 8.32084 16.2708L9.58418 11.2183L11.6808 19.6008C11.7225 19.77 11.8658 19.895 12.04 19.9133C12.0558 19.9158 12.0708 19.9158 12.0867 19.9158C12.2433 19.9158 12.3892 19.8275 12.4592 19.6867L14.0083 16.5825H18.3342V15.7492H13.7508C13.5917 15.7492 13.4483 15.8375 13.3783 15.9783L13.3775 15.9792Z"
        fill={color}
      />
      <path
        d="M3.375 12.1642C2.2425 10.4583 1.66667 8.72166 1.66667 7C1.66667 4.9325 3.34917 3.24999 5.41667 3.24999C6.40083 3.24999 7.34917 3.63249 8.08583 4.32583C8.77333 4.97166 9.16667 5.79416 9.16667 6.58333H10.8333C10.8333 5.79416 11.2267 4.97166 11.9142 4.32583C12.6508 3.63333 13.5992 3.24999 14.5833 3.24999C16.6508 3.24999 18.3333 4.9325 18.3333 7C18.3333 8.72166 17.7575 10.4583 16.625 12.1642L18.0133 13.0858C19.3308 11.1042 20 9.0575 20 7C20 4.01333 17.57 1.58333 14.5833 1.58333C13.1717 1.58333 11.8175 2.12499 10.7708 3.11166C10.4767 3.39 10.2183 3.69249 10 4.0125C9.78083 3.69249 9.52333 3.39 9.22917 3.11166C8.1825 2.12499 6.82833 1.58333 5.41667 1.58333C2.43 1.58333 0 4.01333 0 7C0 9.0575 0.669167 11.1042 1.98667 13.0858L3.375 12.1642Z"
        fill={color}
      />
    </svg>
  );
};

HeartPulseIcon.displayName = 'HeartPulseIcon';

export default HeartPulseIcon;
