import { get } from 'lodash';

export interface BackError {
  subject: string;
  code: string;
  message: string;
  developerMessage: string;
  moreInfo: string;
  field: string;
  index: number;
  isWarning: boolean;
  extraData: string;
}

export interface BaseBackResponsePagination {
  total: number;
  page: number;
  pageSize: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface BaseBackResponse {
  pagination?: BaseBackResponsePagination;
  errors: BackError[];
  failure: boolean;
  isHandledError?: boolean;
  hasWarnings?: boolean;
  isNotFound?: boolean;
  allowHttpCache?: boolean;
  statusCode?: number;
  success?: boolean;
}
export interface BackResponse<T> extends BaseBackResponse {
  value: T;
  failure: false;
}
export interface BackResponseFailure extends BaseBackResponse {
  failure: true;
}

export function isBackResponseFailure(
  objectToDetermine: any,
): objectToDetermine is BackResponseFailure {
  return (
    objectToDetermine &&
    get(objectToDetermine, 'failure') === true &&
    get(objectToDetermine, 'errors') &&
    get(objectToDetermine, 'errors', []).length > 0
  );
}
