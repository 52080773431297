import React from 'react';
import { Controller } from 'react-hook-form';
import { Col, Row } from 'react-styled-flexboxgrid';

import {
  EmailAutocomplete,
  FormGroup,
  Input,
} from '@savgroup-front-common/core/src/atoms/Form';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { PhoneNumberInput } from '@savgroup-front-common/core/src/helpers';

import { CONFIRMATION_FIELDS } from '../Confirmation.helpers';

import messages from './messages';

export default function ConfirmationUserFields({ countryCode, formContext }) {
  const {
    control,
    formState: { errors },
  } = formContext;

  return (
    <Card>
      <Row>
        <Col xs={12} md={6}>
          <FormGroup>
            <Controller
              name={CONFIRMATION_FIELDS.PHONE}
              control={control}
              render={({ field }) => {
                return (
                  <PhoneNumberInput
                    value={field.value}
                    onChange={field.onChange}
                    countryCode={countryCode}
                  >
                    {(_, innerOnChange, onBlur) => {
                      return (
                        <Input
                          label={messages.phoneNumber}
                          errors={errors}
                          name={field.name}
                          value={field.value}
                          onChange={innerOnChange}
                          onBlur={onBlur}
                          isRequired
                        />
                      );
                    }}
                  </PhoneNumberInput>
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Controller
              name={CONFIRMATION_FIELDS.MAIL}
              control={control}
              render={({ field }) => (
                <EmailAutocomplete
                  name={field.name}
                  errors={errors}
                  label={messages.email}
                  dataTestId="data-emailconfirmation-input"
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                  isRequired
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
    </Card>
  );
}

ConfirmationUserFields.displayName = 'ConfirmationUserFields';
