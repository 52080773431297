import React, { FC } from 'react';
import { Transition } from 'react-transition-group';

import { TruckLoader } from '../../../atoms/loader';

import { $Container, $LoaderText } from './TruckBaseLoader.styles';

const TruckBaseLoader: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  return (
    <$Container $hasMargin $isOpen={isOpen}>
      <Transition in={isOpen} timeout={300} unmountOnExit>
        {(animationState) => (
          <$LoaderText
            animationDuration={300}
            animationState={animationState}
            $align="center"
            $hasMargin
          >
            <TruckLoader
              animationState={animationState}
              estimatedWaitingTimeInSeconds={8}
            />
          </$LoaderText>
        )}
      </Transition>
    </$Container>
  );
};

TruckBaseLoader.displayName = 'TruckBaseLoader';

export default TruckBaseLoader;
