import React, { FC, PropsWithChildren } from 'react';

import { $QuotationLine } from './QuotationLine.styles';

interface QuotationLineHeadProps {
  isActive?: boolean;
  isSelected?: boolean;
}

const QuotationLine: FC<PropsWithChildren<QuotationLineHeadProps>> = ({
  children,
  isActive = false,
  isSelected = true,
}) => {
  return (
    <$QuotationLine $isActive={isActive} $isSelected={isSelected}>
      {children}
    </$QuotationLine>
  );
};

QuotationLine.displayName = 'QuotationLineHead';

export default QuotationLine;
