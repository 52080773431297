import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';

import { SafeFormattedMessage } from '../../../../formatters';
import { getFromDataByKey } from '../../../../helpers';
import { AttachmentIcon, CrossIcon } from '../../../../protons/icons';
import { Button } from '../../../button';
import { $Container, FieldMessage, Wrapper } from '../../common';
import { FIELD_STATUS } from '../../common/helpers/getFinalFieldState.types';

import {
  AttachmentContent,
  AttachmentsListFileName,
  StyledProgressBar,
} from './AttachmentsList.styles';
import messages from './messages';

interface Attachment {
  progress: number;
  name: string;
  isDeleting: boolean;
  hasErrors: boolean;
}

interface AttachmentsListProps {
  item: Attachment;
  onRemove?: (item: Attachment) => void;
  name: string;
  dataTestId?: string;
}

const AttachmentItem: FunctionComponent<
  React.PropsWithChildren<AttachmentsListProps>
> = ({ item, onRemove = undefined, name, dataTestId }) => {
  const progress = getFromDataByKey(item, 'progress', 0);
  const fileName = getFromDataByKey(item, 'name', '');
  const isSuccess =
    getFromDataByKey(item, 'isLoaded', false) || progress === 100;
  const isDeleting = getFromDataByKey(item, 'isDeleting', false);
  const isError = getFromDataByKey(item, 'hasErrors', false);
  let status;
  let message;

  if (isSuccess) {
    status = FIELD_STATUS.SUCCESS;
    message = messages.uploadSucceeded;
  }

  if (isError) {
    message = messages.uploadFailed;
    status = FIELD_STATUS.ERROR;
  }

  const theme = useTheme();

  return (
    <$Container>
      <Wrapper name={name} status={status}>
        <StyledProgressBar
          percent={progress}
          isError={isError}
          isSuccess={isSuccess}
          hideOnFinish
        >
          <AttachmentContent>
            <AttachmentIcon size="18px" color={theme.colors.mainTextColor} />
            <AttachmentsListFileName>
              {SafeFormattedMessage(fileName)}
            </AttachmentsListFileName>
          </AttachmentContent>

          {onRemove && (
            <AttachmentContent $fixed>
              <Button
                isLoading={isDeleting}
                onClick={() => onRemove(item)}
                type={BUTTON_TYPES.BUTTON}
                icon={<CrossIcon />}
                naked
                dataTestId="deleteAttachment"
              />
            </AttachmentContent>
          )}
        </StyledProgressBar>
      </Wrapper>
      <FieldMessage message={message} status={status} dataTestId={dataTestId} />
    </$Container>
  );
};

AttachmentItem.displayName = 'AttachmentItem';

export default AttachmentItem;
