import styled from 'styled-components';

import { Card } from '../../atoms/Cards';
import Paragraph from '../../atoms/Paragraph/Paragraph';
import { mediaQuery } from '../../grid/helpers';
import { rem } from '../../helpers';

export const $CardWrapper = styled(Card)`
  min-height: ${rem(170)};

  ${mediaQuery.sm`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${rem(320)};
  `};
`;

export const $ImgContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  height: ${rem(65)};

  ${mediaQuery.sm`justify-content: center;`}
  img, svg {
    max-width: ${rem(50)};
    width: 100%;
    height: auto;
  }
`;

export const $NameContainer = styled.h2`
  padding: 0;
  font-size: ${rem(24)};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  margin: 0;

  color: ${({ theme }) => theme.colors.mainTextColor};
  text-align: left;
  overflow: hidden;
  ${mediaQuery.sm`
    text-align: center!important;
  `};
`;

export const $SubtiltesContainer = styled(Paragraph)`
  padding: 0;
  height: ${rem(55)};
  overflow: hidden;
  margin-top: ${rem(6)};
  ${mediaQuery.sm`
    text-align: center;
  `};
`;
