import { createAction } from '@reduxjs/toolkit';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';

import {
  LOAD_ORDERS,
  LOAD_ORDERS_DATA,
  MYORDERS_FOUND,
  MYORDERS_LOADED,
  MYORDERS_NOT_FOUND,
  MYORDERS_ON_ERRORS,
  MYORDERS_ON_SEARCH_ERRORS,
  MYORDERS_RUNNING,
  MYORDERS_SEARCHING,
  OWNER_PRODUCT_INFO_LOADED,
} from './actionTypes';

export const ordersLoading = createAction(MYORDERS_RUNNING.BASE);
export const loadOrdersSuccess = createAction(MYORDERS_RUNNING.SUCCEEDED);
export const ordersLoaded = createAction(MYORDERS_LOADED.BASE);
export const loadOrdersData = createAction(LOAD_ORDERS_DATA.BASE);
export const loadOrders = createAction(LOAD_ORDERS.BASE);

function onOrderSearching() {
  return {
    type: MYORDERS_SEARCHING,
  };
}

export function onErrors(errors) {
  return {
    type: MYORDERS_ON_ERRORS,
    payload: errors,
  };
}

function onOrderFound(payload) {
  return {
    type: MYORDERS_FOUND,
    payload: payload.value,
  };
}

function onOwnerProductInfoLoaded(payload) {
  return {
    type: OWNER_PRODUCT_INFO_LOADED,
    payload: payload.value,
  };
}

function onOrderNotFound() {
  return {
    type: MYORDERS_NOT_FOUND,
  };
}

function onSearchError(errors) {
  return {
    type: MYORDERS_ON_SEARCH_ERRORS,
    payload: errors,
  };
}

export function loadOwnerProductInfo(ownerProductId) {
  return (dispatch, getState) => {
    const state = getState();
    const ownerId = state.login.id;

    return apiCall(
      `${APIConfiguration.owner}owners/${ownerId}/products/${ownerProductId}/full`,
      {
        method: SUPPORTED_METHODS.GET,
      },
      dispatch,
    ).then((result) => {
      dispatch(onOwnerProductInfoLoaded(result));
    });
  };
}

export function searchOrder(orderReference, query) {
  return (dispatch) => {
    dispatch(onOrderSearching());

    const url = buildUrl(
      `${APIConfiguration.owner}owners/orders/${orderReference}`,
      query,
    );

    return apiCall(url, { method: SUPPORTED_METHODS.GET }, dispatch)
      .then((result) => {
        dispatch(onOrderFound(result));
      })
      .catch((result) => {
        if (result.statusCode === 404) {
          dispatch(onOrderNotFound());
        } else {
          dispatch(onSearchError(result));
        }
      });
  };
}
