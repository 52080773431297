import { DOCUMENT_TYPES } from '@savgroup-front-common/constants';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import getDocumentNameTranslated, {
  DocumentData,
} from '@savgroup-front-common/core/src/formatters/getDocumentNameTranslated';

import messages from '../messages';

interface GetDocumentNameArgs {
  document: DocumentData;
}

const getDocumentName = ({ document }: GetDocumentNameArgs) => {
  if (document.type === DOCUMENT_TYPES.SHIPPING_LABEL) {
    return safeFormattedIntlString(messages.shippingLabel);
  }
  const documentName = getDocumentNameTranslated({ documentData: document });

  return documentName;
};

export default getDocumentName;
