export const CARRIER_PRODUCT_TYPE = {
  PICKUP: 'Pickup',
  PICKUP_POST_OFFICE: 'PickupPostOffice',
  PICKUP_COUNTER: 'PickupCounter',
  HOME_DELIVERY: 'HomeDelivery',
  DROP_AT_STORE: 'DropAtStore',
  SELLER_PROVIDER_CARRIER: 'SellerProvidedCarrier',
  IMMEDIATE_DROP_AT_STORE: 'ImmediateDropAtStore',
  PICKUP_STORE_DELIVERY: 'PickUpStoreDelivery',
  HOME_INTERVENTION: 'HomeIntervention',
};
