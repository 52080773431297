import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from 'react-styled-flexboxgrid';

import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import {
  regroupOtherProducts,
  regroupProducts,
} from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { ActionCreators as ClaimActionCreators } from '@savgroup-front-common/core/src/domains/claims';
import { selectUserId } from '@savgroup-front-common/core/src/domains/selectors';
import { AuthConfiguration } from 'myaccount/configuration';
import {
  selectLoadCloseFilesWasLoaded,
  selectLoadOpenFilesWasLoaded,
} from 'myaccount/domains/MyReturn/selectors';
import { selectOrderViewIsLoaded } from 'myaccount/domains/Navigation/selectors';
import {
  groupedProductSelector,
  ordersSelector,
} from 'myaccount/domains/Views/groupedProduct';
import { mergedIssuesSelector } from 'myaccount/domains/Views/issues';
import { ROUTES } from 'myaccount/view/app';

import OrderProductList from './OrderProductList/OrderProductList';

const OrdersPage = ({
  orders,
  hasError,
  deleteClaimGroupById,
  mappedOrders,
  ownerId,
  sellerId,
}) => {
  const history = useHistory();

  const orderViewLoaded = useSelector(selectOrderViewIsLoaded);
  const isGetOpenFilesLoaded = useSelector((state) =>
    selectLoadOpenFilesWasLoaded(state, { ownerId }),
  );
  const isGetClosedFilesLoaded = useSelector((state) =>
    selectLoadCloseFilesWasLoaded(state, { ownerId }),
  );

  const isLoading =
    !orderViewLoaded ||
    ((!orders.isLoaded || orders.isRunning) && !hasError) ||
    !isGetOpenFilesLoaded ||
    !isGetClosedFilesLoaded;

  return (
    <div>
      {isLoading && (
        <Grid>
          <NeedHelp clientId={AuthConfiguration.clientId} />
          <BaseLoader dataTestId="ordersPageLoader" />
        </Grid>
      )}
      {!isLoading && (
        <>
          <NeedHelp clientId={AuthConfiguration.clientId} />
          {orders.items && orders.items.length > 0 && (
            <OrderProductList
              sellerId={sellerId}
              ownerId={ownerId}
              orders={mappedOrders}
              deleteClaimGroupById={deleteClaimGroupById}
              startClaim={() => history.push(ROUTES.ISSUES)}
              regroupProducts={regroupProducts}
              regroupOtherProducts={regroupOtherProducts}
            />
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { orders } = state;
  const { items } = orders;
  const sellerId = _.get(_.first(items), 'sellerId', null);

  return {
    orders: {
      ...orders,
      items: ordersSelector(state),
    },
    mappedOrders: groupedProductSelector(state),
    ownerId: selectUserId(state),
    mergedIssues: mergedIssuesSelector(state),
    sellerId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteClaimGroupById: (...data) =>
    dispatch(ClaimActionCreators.deleteClaimGroupById(...data)),
});

OrdersPage.propTypes = {
  ownerId: PropTypes.string.isRequired,
  sellerId: PropTypes.string,
  orders: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    isRunning: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isErrored: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
  hasError: PropTypes.bool,
  mappedOrders: PropTypes.shape({}).isRequired,
  deleteClaimGroupById: PropTypes.func.isRequired,
};

OrdersPage.defaultProps = {
  hasError: false,
  sellerId: undefined,
};

OrdersPage.displayName = 'OrdersPage';

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage);
