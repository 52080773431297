import React, {
  ChangeEvent,
  FunctionComponent,
  ReactNode,
  Ref,
  useRef,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import {
  HTMLFileInputElement,
  MessageType,
} from '@savgroup-front-common/types';

import {
  SafeFormattedMessage,
  SafeFormattedMessageWithoutSpread,
} from '../../../formatters';
import { ImageConverter } from '../../../helpers/ImageConverter';
import { useCombinedRefs } from '../../../hooks';
import { AttachmentIcon, PlusIcon } from '../../../protons/icons';
import { QrCodeIcon } from '../../../protons/icons/QrCode.icon';
import { Button } from '../../button';
import ButtonGroup from '../../button/ButtonGroup';
import { FieldMessage } from '../common';
import { getFinalFieldState } from '../common/helpers/getFinalFieldState';
import {
  FIELD_STATUS,
  FieldMessages,
} from '../common/helpers/getFinalFieldState.types';
import { Label } from '../common/Label/Label';

import AttachmentItem from './AttachmentItem';
import {
  $Col,
  $Dropzone,
  $FileAttachmentCol,
  $FileInput,
  $IconContainer,
} from './FileUploadHookForm.styles';
import messages from './messages';

interface FileUploadHookFormProps {
  label?: MessageType;
  dropZoneLabel?: MessageType;
  postLabel?: ReactNode;
  isRequired?: boolean;
  dropzone?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  isSuccess?: boolean;
  disabled?: boolean;
  isDisabled?: boolean;
  forwardedRef?: Ref<HTMLInputElement> | (() => void);
  allowedMimeTypes?: string[];
  errors?: FieldMessages;
  warnings?: FieldMessages;
  successes?: FieldMessages;
  isLoading?: boolean;
  file?: { value: File; progress?: number };
  hollow?: boolean;
  onRemove?: (file: { value: File; progress?: number }) => void;
  name: string;
  dataTestId?: string;
  onSelect: (files?: File[]) => void;
  isLiveUpload?: boolean;
  isFullWidth?: boolean;
  onImportByQrCodeClick?: () => void;
  withImagePreview?: boolean;
}
interface FileUploadHookFormPropsRef extends FileUploadHookFormProps {
  forwardedRef?: Ref<HTMLInputElement> | (() => void);
}

const FileUploadHookForm: FunctionComponent<
  React.PropsWithChildren<FileUploadHookFormPropsRef>
> = ({
  label,
  postLabel = null,
  dropzone = false,
  name,
  isRequired = false,
  isError = false,
  isWarning = false,
  isSuccess = false,
  disabled = false,
  isDisabled = false,
  allowedMimeTypes = [],
  file,
  forwardedRef = null,
  errors = {},
  warnings = {},
  successes = {},
  onSelect,
  onRemove = undefined,
  isLoading = false,
  hollow = false,
  isLiveUpload = true,
  dropZoneLabel,
  dataTestId,
  isFullWidth = false,
  onImportByQrCodeClick,
  withImagePreview,
}) => {
  const transitionDisabled = disabled || isDisabled;
  const [status, message] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  const fileRef = useRef<HTMLInputElement>(null);
  const combinedRefs = useCombinedRefs(fileRef, forwardedRef);

  const handleClickInput = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (fileRef.current) {
      fileRef.current.click();
    }
    event.preventDefault();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const newFiles = await Promise.all(
        acceptedFiles.map(async (file) => ImageConverter.heiToJpg(file)),
      );

      return onSelect(newFiles);
    },
  });

  return (
    <>
      {label && (
        <Label
          isRequired={isRequired}
          htmlFor={name}
          postLabel={postLabel}
          status={status}
        >
          {SafeFormattedMessage(label)}
        </Label>
      )}
      <$FileInput
        {...getInputProps()}
        id={name}
        name={name}
        ref={combinedRefs}
        disabled={transitionDisabled}
        type="file"
        accept={allowedMimeTypes.join(',')}
        onChange={async (e: ChangeEvent<HTMLFileInputElement>) => {
          const files = e.target.files ? Array.from(e.target.files) : undefined;

          if (!files) {
            onSelect(undefined);
            e.target.value = '';

            return undefined;
          }

          const newFiles = await Promise.all(
            files.map(async (file) => ImageConverter.heiToJpg(file)),
          );

          onSelect(newFiles);
          e.target.value = '';

          return undefined;
        }}
        data-testid={dataTestId}
      />
      <Row between="sm">
        <$Col $dropzone={dropzone} xs={12} sm={isFullWidth ? 12 : 6}>
          {dropzone && (
            <$IconContainer $isDragActive={isDragActive}>
              <AttachmentIcon />
              <$Dropzone
                data-testid="dropzone"
                {...(getRootProps() as any)}
                onClick={handleClickInput}
                type={BUTTON_TYPES.BUTTON}
                $isDragActive={isDragActive}
              >
                <p>
                  <SafeFormattedMessageWithoutSpread
                    message={dropZoneLabel || messages.dragAndDropFiles}
                  />
                </p>
              </$Dropzone>
            </$IconContainer>
          )}
          {!dropzone && (
            <>
              <ButtonGroup>
                <Button
                  disabled={transitionDisabled}
                  primary
                  hollow={hollow}
                  onClick={handleClickInput}
                  danger={status === FIELD_STATUS.ERROR}
                  type={BUTTON_TYPES.BUTTON}
                  icon={<PlusIcon />}
                  isLoading={isLoading}
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.fileInput}
                  />
                </Button>

                {onImportByQrCodeClick && (
                  <Button
                    disabled={transitionDisabled}
                    primary
                    onClick={onImportByQrCodeClick}
                    type={BUTTON_TYPES.BUTTON}
                    icon={<QrCodeIcon />}
                    isLoading={isLoading}
                    dataTestId={`qrCodeButton_${dataTestId}`}
                  />
                )}
              </ButtonGroup>
              {!file && (
                <FieldMessage
                  message={message}
                  status={status}
                  dataTestId={dataTestId}
                />
              )}
            </>
          )}
        </$Col>

        <$FileAttachmentCol xs={12} sm={isFullWidth ? 12 : 6}>
          {file && (
            <AttachmentItem
              file={file}
              onRemove={onRemove}
              name={name}
              isLiveUpload={isLiveUpload}
              errors={errors}
              withImagePreview={withImagePreview}
            />
          )}
        </$FileAttachmentCol>
      </Row>
    </>
  );
};

FileUploadHookForm.displayName = 'FileUploadHookForm';

export default React.forwardRef<HTMLInputElement, FileUploadHookFormProps>(
  (props, ref) => <FileUploadHookForm forwardedRef={ref} {...props} />,
);
