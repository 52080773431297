import { useEffect, useState } from 'react';

import { ANIMATION_DIRECTION } from '@savgroup-front-common/constants/src';

import { usePrevious } from '../../../hooks';
import calculateDirection from '../helpers/calculateDirection';
import { Tab } from '../Tabs.types';

const useTabBodyAnimation = (tabs: Tab[], currentTab: Tab) => {
  const [previous, setPrevious] = useState(ANIMATION_DIRECTION.NEXT);
  const previousTab = usePrevious(currentTab);
  const animation = calculateDirection(tabs, previousTab, currentTab);

  useEffect(() => {
    if (animation && previous !== animation) {
      setPrevious(animation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return animation || previous;
};

export default useTabBodyAnimation;
