import { defineMessages } from 'react-intl';

export default defineMessages({
  DEEE: {
    id: 'sparePart.returnProcess.DEEE',
    defaultMessage: 'DEEE',
  },
  Manufacturer: {
    id: 'sparePart.returnProcess.Manufacturer',
    defaultMessage: 'Manufacturer',
  },
  None: {
    id: 'sparePart.returnProcess.None',
    defaultMessage: 'None',
  },
});
