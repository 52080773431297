import get from 'lodash/get';
import head from 'lodash/head';
import React, { useRef, useState } from 'react';
import Select, { GroupBase } from 'react-select';
import SelectType from 'react-select/base';
import { useDeepCompareEffect } from 'react-use';
import { useTheme } from 'styled-components';

import {
  BaseAutocompleteOption,
  SUPPORTED_COMPONENTS,
} from '@savgroup-front-common/types';

import {
  safeFormattedIntlString,
  SafeFormattedMessage,
  SafeFormattedMessageWithoutSpread,
} from '../../../formatters';
import { useCombinedRefs } from '../../../hooks';
import useScrollIntoView from '../../../hooks/useScroll';
import { $Container, FieldMessage, Wrapper } from '../common';
import { getFinalFieldState } from '../common/helpers/getFinalFieldState';
import { Label } from '../common/Label/Label';

import { getSelectedOption, sortOptions } from './Autocomplete.helpers';
import { useGetAutocompleteStyle } from './Autocomplete.hooks';
import { $Description, $SelectWrapper } from './Autocomplete.styles';
import { AutocompleteBaseProps } from './Autocomplete.types';
import * as customAutocompleteComponents from './CustomAucompleteComponents';
import DefaultOptionFormatter from './Formatters/DefaultOptionFormatter';
import messages from './messages';

function Autocomplete<
  Option extends BaseAutocompleteOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: AutocompleteBaseProps<Option, IsMulti, Group>,
  ref: React.ForwardedRef<SelectType<Option, IsMulti, Group>>,
) {
  const {
    name,

    value = null,
    options = [],
    isLoading = undefined,

    noOptionsMessage = messages.noOptionsMessage,
    loadingMessage = messages.loadingMessage,
    placeholder = messages.placeholder,

    isDisabled = false,

    styles,

    defaultValue = null,

    isClearable,

    onFocus,
    onBlur,
    filterOption,
    formatOptionLabel = DefaultOptionFormatter,

    formatGroupLabel,
    onChange,

    label = null,
    postLabel,
    fullWidthLabel = false,
    isRequired = false,

    componentThemeName = SUPPORTED_COMPONENTS.DEFAULT_AUTOCOMPLETE,

    errors = {},
    warnings = {},
    successes = {},

    isError = false,
    isWarning = false,
    isSuccess = false,
    withoutOptionSort = false,
    isHorizontal = false,

    dataTestId,
    dataTestIdForCypress,

    onMouseEnter,
    onMouseLeave,
    customComponents = customAutocompleteComponents,
    prefix = '',
    suffix = '',
    suffixButton,
    backspaceRemovesValue = true,
    closeMenuOnSelect = true,
    captureMenuScroll = false,
    blurInputOnSelect = false,
    tabIndex,
    shouldAutoSelectFirstOptions = false,
    isScrollIntoView = false,
    description = undefined,
  } = props;

  const autoCompleteRef = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();

  const defaultStyles = useGetAutocompleteStyle();

  const { scrollIntoView } = useScrollIntoView({
    ref: autoCompleteRef,
    isScrollIntoView,
  });
  const combinedRefs = useCombinedRefs(ref, autoCompleteRef);
  const [status, message] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  const selectedValue = getSelectedOption(value, options);
  const defaultSelectedValue = getSelectedOption(defaultValue, options);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const firstOption = head(options);

  useDeepCompareEffect(() => {
    if (
      shouldAutoSelectFirstOptions &&
      !get(value, 'value') &&
      firstOption &&
      onChange
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange(firstOption as any);
    }
  }, [
    onChange,
    options,
    firstOption,
    options.length,
    shouldAutoSelectFirstOptions,
    value,
  ]);

  return (
    <$Container $isHorizontal={isHorizontal}>
      {label && (
        <Label
          status={status}
          isRequired={isRequired}
          htmlFor={name}
          postLabel={postLabel}
          fullWidth={fullWidthLabel}
          isHorizontal={isHorizontal}
        >
          {SafeFormattedMessage(label)}
        </Label>
      )}
      <Wrapper
        name={name}
        status={status}
        prefix={prefix}
        suffix={suffix}
        isHovered={isHovered}
        isFocused={isFocused}
        dataTestId={dataTestIdForCypress}
        suffixButton={suffixButton}
      >
        <$SelectWrapper
          data-testid={dataTestId}
          onMouseEnter={(e) => {
            if (onMouseEnter) {
              onMouseEnter(e);
            }
            setIsHovered(true);
          }}
          onMouseLeave={(e) => {
            if (onMouseLeave) {
              onMouseLeave(e);
            }
            setIsHovered(false);
          }}
          onFocus={() => {
            if (isScrollIntoView) {
              scrollIntoView();
            }
          }}
          ref={combinedRefs}
        >
          <Select
            {...props}
            tabIndex={tabIndex}
            blurInputOnSelect={blurInputOnSelect}
            captureMenuScroll={captureMenuScroll}
            backspaceRemovesValue={backspaceRemovesValue}
            formatGroupLabel={formatGroupLabel}
            onChange={onChange}
            isLoading={isLoading}
            closeMenuOnSelect={closeMenuOnSelect}
            isDisabled={isDisabled}
            options={
              options && options.length > 0 && !withoutOptionSort
                ? sortOptions(options)
                : options
            }
            onFocus={(e) => {
              if (onFocus) {
                onFocus(e);
              }
              setIsFocused(true);
            }}
            onBlur={(e) => {
              if (onBlur) {
                onBlur(e);
              }
              setIsFocused(false);
              setIsHovered(false);
            }}
            componentThemeName={componentThemeName}
            inputId={name}
            name={name}
            noOptionsMessage={() => safeFormattedIntlString(noOptionsMessage)}
            loadingMessage={() => safeFormattedIntlString(loadingMessage)}
            placeholder={SafeFormattedMessage(placeholder)}
            status={status}
            themeStyled={theme}
            styles={styles || defaultStyles}
            value={selectedValue}
            defaultValue={defaultSelectedValue}
            ref={ref}
            components={customComponents}
            filterOption={filterOption}
            isClearable={isClearable}
            formatOptionLabel={formatOptionLabel}
          />
        </$SelectWrapper>
      </Wrapper>
      {description && (
        <$Description>
          <SafeFormattedMessageWithoutSpread message={description} />
        </$Description>
      )}
      <FieldMessage message={message} status={status} dataTestId={dataTestId} />
    </$Container>
  );
}

Autocomplete.displayName = 'Autocomplete';

export default React.forwardRef(Autocomplete);
