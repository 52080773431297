import { useQuery } from 'react-query';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { CommonWorkflowService } from '@savgroup-front-common/core/src/api';
import { TARGET_ON_TYPE_VALUES } from '@savgroup-front-common/core/src/api/Workflow/getFileAdditionalInformationQuery';
import { customerState } from 'myaccount/domains/Customer/selectors';

import { useMyAccountTypedSelector } from '../../../../../hooks';
import adaptNeededFileAdditionalInformation from '../adapters/adaptNeededFileAdditionalInformation';

interface UseGetNeededAdditionalInformationsArgs {
  fileId: string;
  filterStateName?: string;
  filterModuleDefinitionId?: string;
  targetOnType: TARGET_ON_TYPE_VALUES;
  filterToStateName?: string;
}

export const useGetNeededAdditionalInformations = ({
  fileId,
  filterStateName,
  filterModuleDefinitionId,
  targetOnType,
  filterToStateName,
}: UseGetNeededAdditionalInformationsArgs) => {
  const customer = useMyAccountTypedSelector(customerState);

  const { data: additionalInformations } = useQuery(
    [
      'getFileAdditionalInformationQuery',
      {
        fileId,
        filterStateName,
        filterModuleDefinitionId,
        targetOnType,
        filterToStateName,
      },
    ],
    async () => {
      if (!(filterStateName && filterModuleDefinitionId && filterToStateName)) {
        return undefined;
      }

      const response =
        await CommonWorkflowService.getFileAdditionalInformationQuery({
          fileId,
          filterStateName,
          filterModuleDefinitionId,
          targetOnType,
          filterToStateName,
        });

      if (response.failure) {
        logCritical(response.errors);

        return undefined;
      }

      return adaptNeededFileAdditionalInformation({
        neededFileAdditionalInformation: response.value,
        fileId,
        concernedClaimIds: (customer?.file.fileProducts || []).map(
          (fileProduct) => {
            return fileProduct.claimId;
          },
        ),
      });
    },
    { suspense: true },
  );

  return {
    additionalInformations,
  };
};
