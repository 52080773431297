import { useEffect, useRef, useState } from 'react';

import { BUTTON_HUMAN_LOADING } from './Button.constants';

interface UseHumanLoadingButtonProps {
  isLoading: boolean;
  minimumDuration?: number;
}

export const useHumanLoadingButton = ({
  isLoading,
  minimumDuration = BUTTON_HUMAN_LOADING,
}: UseHumanLoadingButtonProps): boolean => {
  const isMounted = useRef(true);
  const [delayedLoading, setDelayedLoading] = useState(isLoading);

  useEffect(() => {
    if (isLoading) {
      setDelayedLoading(true);
      setTimeout(() => {
        if (isMounted.current) {
          setDelayedLoading(false);
        }
      }, minimumDuration);
    }
  }, [isLoading, minimumDuration]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isLoading || delayedLoading;
};
