import { Dictionary } from 'lodash';
import groupBy from 'lodash/groupBy';
import { useSelector } from 'react-redux';

import {
  HANDLING_GROUPS,
  HANDLING_TYPES,
} from '@savgroup-front-common/constants';
import { HandlingSummary } from '@savgroup-front-common/types';
import { currentGroupHandlingMethods } from 'myaccount/domains/Claim/claimGroupSelector';

import { Carrier } from '../../../ClaimGroupHandling.types';
import { getAllCarriers } from '../SelectCarrierType.helpers';

interface UseGetGroupedCarriersReturnValue {
  groupedCarriers: Dictionary<Carrier[]>;
  carriers: any[];
}

interface UseGetGroupedCarriersArgs {
  handlingInfo?: HandlingSummary;
}

const useGetGroupedCarriers = ({
  handlingInfo,
}: UseGetGroupedCarriersArgs): UseGetGroupedCarriersReturnValue => {
  const currentCarriers = useSelector(currentGroupHandlingMethods);
  const allCarriers = getAllCarriers({ handlingInfo, currentCarriers });
  const groupedCarriers: Dictionary<Carrier[]> = groupBy(
    allCarriers,
    (carrier) => {
      switch (carrier.productType) {
        case HANDLING_TYPES.HOME_DELIVERY:
          return HANDLING_GROUPS.HOME;
        case HANDLING_TYPES.PICKUP_POST_OFFICE:
        case HANDLING_TYPES.PICKUP:
        case HANDLING_TYPES.PICKUP_COUNTER:
          return HANDLING_GROUPS.PICKUP_POINT;
        case HANDLING_TYPES.DROP_AT_STORE:
          return HANDLING_GROUPS.PICKUP_STORE;
        case HANDLING_TYPES.PICKUP_STORE_DELIVERY:
          return HANDLING_GROUPS.PICKUP_STORE_DELIVERY;
        case HANDLING_TYPES.SELLER_PROVIDED_CARRIER:
          return HANDLING_GROUPS.EXTERNAL;
        case HANDLING_TYPES.HOME_INTERVENTION:
          return HANDLING_GROUPS.HOME_INTERVENTION;
        default:
          return carrier.productType;
      }
    },
  );

  return { groupedCarriers, carriers: allCarriers };
};

export default useGetGroupedCarriers;
