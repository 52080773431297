import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Textarea } from '@savgroup-front-common/core/src/atoms/Form';
import { H2 } from '@savgroup-front-common/core/src/atoms/headings';
import { Paragraph } from '@savgroup-front-common/core/src/atoms/Paragraph';

import { ModalContent, SubmitButton, SubmitRow } from './CommentModal.styles';
import messages from './messages';

const CommentModal = ({
  values,
  handleChange,
  onSubmit,
  isModal,
  closeModal,
}) => (
  <ModalContent>
    <H2>
      <FormattedMessage {...messages.title} />
    </H2>

    <Paragraph>
      <FormattedMessage {...messages.message} />
    </Paragraph>
    <Textarea name="comment" value={values.comment} onChange={handleChange} />

    <SubmitRow>
      <SubmitButton
        primary
        onClick={() => {
          onSubmit(values);
          if (isModal && closeModal) {
            closeModal();
          }
        }}
      >
        <FormattedMessage {...messages.submit} />
      </SubmitButton>
    </SubmitRow>
  </ModalContent>
);

CommentModal.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
  copy: PropTypes.bool,
  setCopy: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  isModal: PropTypes.bool,
  closeModal: PropTypes.func,
};

CommentModal.defaultProps = {
  copy: false,
  setCopy: () => {},
  isModal: false,
  closeModal: () => {},
};

export default CommentModal;
