import styled from 'styled-components';

import { H3 } from '../../atoms/headings';

export const ButtonRow = styled('div')`
  padding-top: 32px;
  display: flex;
  justify-content: space-between;
`;

export const InfoTitleH3 = styled(H3)`
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 16px;
`;

export const $ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row;

  & > * {
    margin-left: 0.25rem;
  }
`;
