import { useCallback, useReducer, useState } from 'react';
import { useQuery } from 'react-query';

import {
  NOTIFICATION_TYPES,
  STALE_TIME,
} from '@savgroup-front-common/constants';
import {
  SALESFORCE_INTEGRATION_TYPE,
  SellerConfiguration,
  Timeslot,
} from '@savgroup-front-common/types';

import adaptTimeslotToDetailedSchedule from './adapters/adaptTimeslotToDetailedSchedule';
import messages from './messages';
import {
  interventionAppointmentInit,
  takeInterventionAppointmentReducer,
} from './TakeInterventionAppointment.reducer';
import { INTERVENTION_APPOINTMENT_ACTION_TYPES } from './TakeInterventionAppointment.types';
import { useBanners } from '../../../../molecules/NotificationsProvider';
import useGetSellerConfiguration from '../../../../hooks/useGetSellerConfiguration';
import { InterventionAppointmentMockBuilder } from '../../__tests__/InterventionAppointmentMockBuilder.mock';
import { buildNotification } from '../../../../helpers/errorsMessage';
import { sortBy } from '../../../../helpers/sorter';
import { CommonWorkflowService } from '../../../../api';
import { DetailedSchedule } from '../../types/DetailedSchedule';

interface UseInterventionAppointmentProps {
  fileId: string;
  sellerId: string;
  onSubmit: () => void;
}

interface UseInterventionAppointmentReturnValue {
  detailedSchedules?: DetailedSchedule[];
  isCreateTechnicalInterventionLoading: boolean;
  handleValidateSchedule: () => Promise<void>;
  handleTimeslotSelect: (newTimeslot: Timeslot) => void;
  selectedTimeslot?: Timeslot;
  hasErrors: boolean;
  sellerConfiguration?: SellerConfiguration;
}

export const useInterventionAppointment = ({
  fileId,
  onSubmit,
  sellerId,
}: UseInterventionAppointmentProps): UseInterventionAppointmentReturnValue => {
  const { removeAllNotifications, pushError, pushNotification } = useBanners({
    extendedMessages: messages,
  });
  const [state, dispatch] = useReducer(
    takeInterventionAppointmentReducer,
    undefined,
    interventionAppointmentInit,
  );

  const { selectedTimeslot, hasErrors } = state;

  const [
    isCreateTechnicalInterventionLoading,
    setIsCreateTechnicalInterventionLoading,
  ] = useState(false);

  const { sellerConfiguration } = useGetSellerConfiguration({
    sellerId,
  });

  const { data: detailedSchedules } = useQuery(
    [
      'getTechnicalInterventionTimeSlots',
      {
        sellerId,
        integrationType:
          sellerConfiguration?.salesforceFieldServiceLightningIntegrationType,
      },
    ],
    async () => {
      removeAllNotifications();

      if (
        !sellerConfiguration ||
        sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
          SALESFORCE_INTEGRATION_TYPE.None ||
        !sellerConfiguration?.salesforceFieldServiceLightningIntegrationType
      ) {
        return undefined;
      }

      if (
        sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
        SALESFORCE_INTEGRATION_TYPE.Integration
      ) {
        const rawTimeslot =
          new InterventionAppointmentMockBuilder().addDemoSchedules().schedules;

        return adaptTimeslotToDetailedSchedule(
          rawTimeslot.sort(sortBy({ fieldName: 'start' })),
        );
      }

      const response =
        await CommonWorkflowService.getTechnicalInterventionTimeSlots({
          fileId,
        });

      if (response.failure) {
        pushNotification(
          buildNotification({
            message: messages.pleaseContactSupportToTakeAppointment,
            notificationType: NOTIFICATION_TYPES.ALERT,
            options: {
              hollow: true,
            },
          }),
        );

        return undefined;
      }

      return adaptTimeslotToDetailedSchedule(
        response.value.sort(sortBy({ fieldName: 'start' })),
      );
    },
    {
      suspense: true,
      staleTime: STALE_TIME.SECONDS_1,
    },
  );

  const handleValidateSchedule = useCallback(async () => {
    dispatch({
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.VALIDATE_TIMESLOT,
    });
    removeAllNotifications();

    if (!selectedTimeslot) {
      const error = {
        name: 'requiredTimeSlotSelection',
        message: '',
      };

      dispatch({
        type: INTERVENTION_APPOINTMENT_ACTION_TYPES.SET_ERRORS,
        payload: {
          errors: [error],
        },
      });

      pushError(error);

      return;
    }

    if (
      sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
      SALESFORCE_INTEGRATION_TYPE.Integration
    ) {
      setIsCreateTechnicalInterventionLoading(true);

      onSubmit();
    } else {
      try {
        setIsCreateTechnicalInterventionLoading(true);

        await CommonWorkflowService.createTechnicalIntervention({
          fileId,
          start: selectedTimeslot.start.toISOString(),
          end: selectedTimeslot.end.toISOString(),
        });

        onSubmit();
      } catch (e) {
        setIsCreateTechnicalInterventionLoading(false);

        pushNotification(
          buildNotification({
            message: messages.createTechnicalInterventionError,
            notificationType: NOTIFICATION_TYPES.ALERT,
            options: {
              hollow: true,
            },
          }),
        );
      }
    }
  }, [
    fileId,
    onSubmit,
    pushError,
    pushNotification,
    removeAllNotifications,
    selectedTimeslot,
    sellerConfiguration?.salesforceFieldServiceLightningIntegrationType,
  ]);

  const handleTimeslotSelect = useCallback((newTimeslot: Timeslot) => {
    dispatch({
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.SELECT_TIMESLOT,
      payload: {
        selectedTimeslot: newTimeslot,
      },
    });
  }, []);

  return {
    detailedSchedules,
    isCreateTechnicalInterventionLoading,
    handleValidateSchedule,
    selectedTimeslot,
    hasErrors,
    handleTimeslotSelect,
    sellerConfiguration,
  };
};
