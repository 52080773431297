import React from 'react';

interface SleeperChairIconProps {
  color?: string;
}

/* eslint-disable max-len */
const SleeperChairIcon: React.FC<SleeperChairIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.3335 30V35M8.00016 16.6667V8.33333C8.00016 7.44928 8.35135 6.60143 8.97647 5.97631C9.60159 5.35119 10.4494 5 11.3335 5H28.0002C28.8842 5 29.7321 5.35119 30.3572 5.97631C30.9823 6.60143 31.3335 7.44928 31.3335 8.33333V16.6667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 30V35M32.1667 16.6667C31.0616 16.6667 30.0018 17.1057 29.2204 17.8871C28.439 18.6685 28 19.7283 28 20.8333V23.3333H11.3333V20.8333C11.3332 20.045 11.1094 19.2728 10.6879 18.6066C10.2664 17.9404 9.66458 17.4074 8.95229 17.0695C8.23999 16.7317 7.44646 16.6028 6.66386 16.698C5.88127 16.7931 5.14172 17.1083 4.53111 17.607C3.9205 18.1056 3.46389 18.7673 3.21431 19.5151C2.96473 20.2629 2.93243 21.0662 3.12115 21.8316C3.30987 22.5971 3.71187 23.2932 4.28046 23.8393C4.84905 24.3854 5.5609 24.759 6.33333 24.9167V30H33V24.9167C34.0126 24.711 34.9127 24.1365 35.5256 23.3046C36.1384 22.4726 36.4203 21.4426 36.3164 20.4146C36.2126 19.3865 35.7304 18.4337 34.9636 17.7411C34.1968 17.0486 33.2 16.6656 32.1667 16.6667V16.6667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

SleeperChairIcon.displayName = 'SleeperChairIcon';

export default SleeperChairIcon;
