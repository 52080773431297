import { DetailedSchedule } from '../../types/DetailedSchedule';

export function getDetailedSchedulesByPage({
  detailedSchedules,
  pageSize,
  pageNumber,
}: {
  detailedSchedules?: DetailedSchedule[];
  pageSize: number;
  pageNumber: number;
}): DetailedSchedule[] {
  if (!detailedSchedules) {
    return [];
  }

  return detailedSchedules.slice(
    (pageNumber - 1) * pageSize,
    pageNumber * pageSize,
  );
}
