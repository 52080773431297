import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import { AuthConfiguration } from 'myaccount/configuration';
import { Selectors as CustomerSelectors } from 'myaccount/domains/Customer';

import {
  loadClosedFiles,
  loadOpenFiles,
} from '../../../domains/MyReturn/actionCreators';

import FileFollowups from './FileFollowups/FileFollowups';

const FileFollowupsPage: FunctionComponent = () => {
  const dispatch = useDispatch();

  const customerFileSummaryValue = useSelector(
    CustomerSelectors.selectCustomerFileSummary,
  );

  useEffect(() => {
    if (customerFileSummaryValue?.ownerId) {
      dispatch(
        loadOpenFiles({
          ownerId: customerFileSummaryValue.ownerId,
          forceRefresh: true,
        }),
      );

      dispatch(
        loadClosedFiles({
          ownerId: customerFileSummaryValue.ownerId,
          forceRefresh: true,
        }),
      );
    }
  }, [customerFileSummaryValue?.ownerId, dispatch]);

  return (
    <>
      <NeedHelp clientId={AuthConfiguration.clientId} />
      <FileFollowups />
    </>
  );
};

FileFollowupsPage.displayName = 'FileFollowupsPage';

export default FileFollowupsPage;
