import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import PaginatedView from '@savgroup-front-common/core/src/components/PaginatedView/PaginatedView';
import {
  currentClaims,
  fileProductsSelector,
  getCurrentIssue,
} from 'myaccount/domains/Claim/claimGroupSelector';

import adaptReasons from './adapters/adaptReasons';
import useAdditionalInformationSave from './hooks/useAdditionalInformationSave';
import useReasonSolutionSelection from './hooks/useReasonSolutionSelection';
import messages from './messages';
import NoSolutionForm from './NoSolutionForm/NoSolutionForm';
import ReasonSelectionForm from './ReasonSelectionForm/ReasonSelectionForm';
import { GridMarginBottom } from './ReasonSolutionSelection.styles';
import ReasonSolutionSelectionProductInfo from './ReasonSolutionSelectionProductInfo/ReasonSolutionSelectionProductInfo';
import ReasonSolutionSelectionSubmitButton from './ReasonSolutionSelectionSubmitButton/ReasonSolutionSelectionSubmitButton';
import SolutionSelection from './SolutionSelection/SolutionSelection';

const ReasonSolutionSelection = ({ claimAttachmentsByProductId }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onActiveProductChange = useCallback(
    (index) => setActiveIndex(index),
    [],
  );

  const products = useSelector(fileProductsSelector);
  const claims = useSelector(currentClaims);

  const issue = useSelector((state) =>
    getCurrentIssue(state, {
      claimId: head(Object.keys(claims || {})),
    }),
  );

  const adaptedProducts = products.map((product) => {
    return {
      ...product,
      reasons: adaptReasons({
        reasons: product.reasons,
        claimIds: product.claimIds,
        productClaimIds: product.claimIds,
      }),
    };
  });

  const firstProduct = head(adaptedProducts);
  const solutions = firstProduct?.solutions || [];

  const { activeProduct, formContext, handleReasonSolutionSubmit } =
    useReasonSolutionSelection({
      claims: Object.values(claims),
      products: adaptedProducts,
      activeIndex,
      onActiveProductChange,
      solutions,
      claimAttachmentsByProductId,
    });

  const { handleSubmit } = formContext;

  const handleAdditionalInformationSave = useAdditionalInformationSave({
    formContext,
    products: adaptedProducts,
  });

  return (
    <FormProvider {...formContext}>
      <form onSubmit={handleSubmit(handleReasonSolutionSubmit)}>
        <GridMarginBottom>
          <PaginatedView
            products={adaptedProducts}
            activeIndex={activeIndex}
            onActiveProductChange={onActiveProductChange}
            title={<FormattedMessage {...messages.products} />}
          />
        </GridMarginBottom>

        {activeProduct && (
          <ReasonSolutionSelectionProductInfo
            productInfo={{
              ...(activeProduct.productInfo || {}),
              fromProductWarranty: issue?.fromProductWarranty,
            }}
            orderDataLoaded={activeProduct.orderDataLoaded}
          />
        )}

        {adaptedProducts.map((product, index) => {
          return (
            <ReasonSelectionForm
              key={product.productId}
              isOpen={index === activeIndex}
              product={product}
              claimIds={product.claimIds}
              reasons={product.reasons}
              reasonsLoaded={product.reasonsLoaded}
              onAdditionalInformationSave={handleAdditionalInformationSave}
            />
          );
        })}

        <SolutionSelection
          solutions={solutions}
          solutionsLoaded={activeProduct.solutionsLoaded}
          activeProduct={activeProduct}
        />

        {!(activeProduct.solutionsLoaded && isEmpty(solutions)) && (
          <ReasonSolutionSelectionSubmitButton
            activeProduct={activeProduct}
            fileUploads={get(adaptedProducts, [
              activeIndex,
              'fileUploads',
              'value',
            ])}
            isLastProduct={activeIndex === adaptedProducts.length - 1}
            onNextButtonClick={() => onActiveProductChange(activeIndex + 1)}
          />
        )}
      </form>

      {activeProduct.solutionsLoaded && isEmpty(solutions) && (
        <Card>
          <NoSolutionForm activeProduct={activeProduct} />
        </Card>
      )}
    </FormProvider>
  );
};

ReasonSolutionSelection.displayName = 'ReasonSolutionSelection';

export default ReasonSolutionSelection;
