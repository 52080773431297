import React from 'react';
import styled from 'styled-components';

import { Card } from '@savgroup-front-common/core/src/atoms/Cards';

import OrderProductInfo from './OrderProductInfo';

export const $StyledCardContent = styled(Card)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  border-bottom: 1px solid #f8f9fb;
  border-radius: 0;
  padding: 2rem;
  margin-top: 0 !important;
  &:first-of-type {
    border-radius: 5px 5px 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 5px 5px;
    border-bottom: none;
  }
  & > div:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const OrderProductCard = (props) => (
  <$StyledCardContent>
    <OrderProductInfo {...props} />
  </$StyledCardContent>
);

OrderProductCard.displayName = 'OrderProductCard';

export default OrderProductCard;
