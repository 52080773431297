import { createAction } from '@reduxjs/toolkit';

import * as ActionTypes from './actionTypes';

export const startClaim = createAction(ActionTypes.START_CLAIM);
export const returnProducts = createAction(ActionTypes.RETURN_PRODUCTS.BASE);
export const finishClaimCreation = createAction(
  ActionTypes.FINISH_CLAIM_CREATION,
);

export const loadIssues = createAction(ActionTypes.LOAD_ISSUES.BASE);

export const chooseIssue = createAction(ActionTypes.CHOOSE_ISSUE.BASE);
export const chooseIssueEnd = createAction(ActionTypes.CHOOSE_ISSUE.END);
export const chooseIssueSuccess = createAction(
  ActionTypes.CHOOSE_ISSUE.SUCCEEDED,
);
