import { defineMessages } from 'react-intl';

export default defineMessages({
  deposit: {
    id: 'components.myaccount.common.deposit',
    defaultMessage: 'Handling (Outward)',
  },
  delivery: {
    id: 'components.myaccount.common.delivery',
    defaultMessage: 'Handling (Return)',
  },
});
