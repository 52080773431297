import React from 'react';

import Icon from './Icon';

interface DepositIconProps {
  color?: string;
  size?: string;
}

const DeliveryIcon: React.FC<DepositIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M14.2811 14.3717L22.6758 18.1026L22.7668 18.3072L22.8067 18.3967H22.7085H22.2411V18.6762L13.9961 15.0118L14.2811 14.3717ZM23.1759 30.3927V20.1146L23.4308 20.2279L23.5264 20.0131L23.622 20.2279L23.8769 20.1146V30.3927H23.1759ZM33.0567 15.0118L24.8117 18.6762V18.3967H24.3443H24.2461L24.286 18.3072L24.3771 18.1026L32.7717 14.3717L33.0567 15.0118Z"
        stroke={color}
        strokeOpacity="0.7"
        strokeWidth="0.934752"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5267 10.9065L14.4129 14.9579V18.1633C14.4129 18.5505 14.099 18.8644 13.7118 18.8644C13.3246 18.8644 13.0107 18.5505 13.0107 18.1633V14.9273C13.0108 14.6653 13.0868 14.4088 13.2295 14.1891C13.3723 13.9694 13.5756 13.7958 13.8149 13.6892L22.9755 9.61714C22.9756 9.61709 22.9754 9.61719 22.9755 9.61714C23.1489 9.54 23.3369 9.5 23.5267 9.5C23.7165 9.5 23.9042 9.53986 24.0776 9.61699C24.0777 9.61704 24.0775 9.61694 24.0776 9.61699L33.2381 13.6891C33.4775 13.7956 33.6811 13.9694 33.8239 14.1891C33.9666 14.4088 34.0426 14.6652 34.0427 14.9272V26.0727C34.0426 26.3347 33.9666 26.5912 33.8239 26.8109C33.6811 27.0306 33.4778 27.2042 33.2385 27.3108L24.0779 31.3829C24.0778 31.3829 24.078 31.3828 24.0779 31.3829C23.9045 31.46 23.7165 31.5 23.5267 31.5C23.3369 31.5 23.1492 31.4601 22.9758 31.383C22.9757 31.383 22.9759 31.3831 22.9758 31.383L13.8153 27.3109C13.5759 27.2044 13.3722 27.0306 13.2295 26.8109C13.0868 26.5912 13.0108 26.3348 13.0107 26.0729V24.7066C13.0107 24.3194 13.3246 24.0055 13.7118 24.0055C14.099 24.0055 14.4129 24.3194 14.4129 24.7066V26.0421L23.5267 30.0935L32.6405 26.0421V14.9579L23.5267 10.9065ZM23.5077 30.1019C23.5076 30.102 23.5078 30.1019 23.5077 30.1019Z"
        fill={color}
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9786 12.0366C18.1358 11.6828 18.5501 11.5235 18.904 11.6807L28.3306 15.8706C28.57 15.9771 28.7737 16.1509 28.9164 16.3706C29.0591 16.5903 29.1351 16.8467 29.1352 17.1087V21.0457C29.1352 21.4329 28.8213 21.7468 28.4341 21.7468C28.0469 21.7468 27.733 21.4329 27.733 21.0457V17.1394L18.3345 12.962C17.9807 12.8047 17.8213 12.3904 17.9786 12.0366Z"
        fill={color}
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.001 24.7351C9.72726 25.0089 9.28337 25.0089 9.00958 24.7351L6.20533 21.9308C5.93155 21.6571 5.93155 21.2132 6.20533 20.9394L9.00958 18.1351C9.28337 17.8613 9.72726 17.8613 10.001 18.1351C10.2748 18.4089 10.2748 18.8528 10.001 19.1266L8.39357 20.734L17.9181 20.734C18.3053 20.734 18.6191 21.0479 18.6191 21.4351C18.6191 21.8223 18.3053 22.1362 17.9181 22.1362L8.39357 22.1362L10.001 23.7436C10.2748 24.0174 10.2748 24.4613 10.001 24.7351Z"
        fill={color}
        fillOpacity="0.7"
      />
    </Icon>
  );
};

DeliveryIcon.displayName = 'DeliveryIcon';

export default DeliveryIcon;
