import { Map } from 'immutable';
import get from 'lodash/get';
import { createSelector } from 'reselect';

import { ERROR_FIELDS_MAP } from '../constants';

const carriersState = (state) => state.carriers;

export const verifiedAddress = createSelector([carriersState], (carriers) =>
  carriers.get('verifiedAddress'),
);
export const selectVerifiedAddress = createSelector(
  [carriersState],
  (carriers) => carriers.getIn(['verifiedAddress', 'value']),
);

export const verificationErrors = createSelector(
  [verifiedAddress],
  (address) => {
    const errors = address.get('errorFields') || [];

    return errors.reduce(
      (acc, item) => acc.set(ERROR_FIELDS_MAP[get(item, 'field')], '-'),
      new Map(),
    );
  },
);
