import styled from 'styled-components';

export const PageWrapper = styled('div')`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
`;

export const MainContent = styled('div')`
  width: 100%;
`;
