import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';

import { transitionRightOrLeft } from '../../animations/complexeStateAnimation';
import { simpleScaleInAnimation } from '../../animations/simpleAnimation';
import { rem } from '../../helpers';

interface ItemAppliedProps {
  $count: number;
}

const itemApplied = css<ItemAppliedProps>`
  &::before {
    content: '${({ $count }) => $count}';
    position: absolute;
    top: -${rem(7)};
    right: -${rem(7)};
    display: inline-block;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    font-size: ${rem(12)};
    ${simpleScaleInAnimation};
  }
`;

interface LiStyledProps {
  $isActive?: boolean;
  $hasErrors?: boolean;
  $count: number;
}

export const $TabButton = styled.button<LiStyledProps>`
  @media ${media.maxWidth.xs} {
    &&& {
      font-size: 1rem;
    }
  }

  display: flex;
  font-weight: ${({ theme, $isActive }) =>
    $isActive ? theme.fonts.weight.bold : theme.fonts.weight.normal};
    
  color: ${({ theme, $hasErrors }) => {
    if ($hasErrors) {
      return theme.colors.danger;
    }

    return theme.colors.mainTextColor;
  }};
  outline: 0;
  border: 0;
  background: none;

  font-size: ${rem(18)};
  line-height: ${rem(21)};
  margin: 0;
  padding: 0;
  position: relative;
  cursor: pointer;
  padding-bottom: 1.5rem;
  align-items: center;

  svg {
    width: auto;
    max-height: 16px;
    margin-right: 0.5rem;
    fill: ${({ theme, $isActive, $hasErrors }) => {
      if ($hasErrors) {
        return theme.colors.danger;
      }
      if ($isActive) {
        return theme.colors.primary;
      }

      return `${theme.colors.paragraphTextColor} !important`;
    }}

  &:hover,
  &:focus {
    svg {
      fill: ${({ theme, $hasErrors }) => {
        if ($hasErrors) {
          return theme.colors.danger;
        }

        return theme.colors.primary;
      }}!important;
    }
    outline: 0;
    color: ${({ theme, $hasErrors }) => {
      if ($hasErrors) {
        return theme.colors.danger;
      }

      return theme.colors.primary;
    }}!important;
    text-decoration: unset;
  }

  ${({ $count }) => ($count > 0 ? itemApplied : null)}
`;

export const $TabViewTransitionContainer = styled.div`
  position: relative;
`;

export const $TabViewAnimation = styled.div`
  ${transitionRightOrLeft()};
`;

export const $ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
