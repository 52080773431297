import { loadStripe } from '@stripe/stripe-js/pure';

import { StripeConfiguration } from '@savgroup-front-common/configuration';
import { useGetSellerConfiguration } from 'myaccount/view/app/hooks';

import useCreatePaymentIntent from './hooks/useCreatePaymentIntent';

interface UsePaymentStripeArgs {
  sellerId: string;
  invoiceId: string;
}

const usePaymentStripe = ({ sellerId, invoiceId }: UsePaymentStripeArgs) => {
  const { sellerConfiguration, isLoading: isGetSellerConfigurationLoading } =
    useGetSellerConfiguration({ sellerId });

  const stripePublicKey =
    sellerConfiguration?.stripePublishableKey || StripeConfiguration.publicKey;

  const stripePromise = loadStripe(stripePublicKey);

  const { paymentIntentClientSecret, isLoading: isCreatePaymentIntentLoading } =
    useCreatePaymentIntent({
      invoiceId,
    });

  const isLoading =
    isGetSellerConfigurationLoading || isCreatePaymentIntentLoading;

  return {
    stripePromise,
    paymentIntentClientSecret,
    isLoading,
  };
};

export default usePaymentStripe;
