import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import {
  FileSparePartRequestLine,
  RequestLineStatus,
} from '@savgroup-front-common/types';
import { SparePartService } from 'myaccount/api/SparePart';

interface UseGetRequestLinesReturnValue {
  isLoading: boolean;
  isOpenModal: boolean;
  handleCloseModal: () => void;
  requestLines?: FileSparePartRequestLine[];
  handleRequestLineStatusUpdate: () => void;
  handleSetCurrentRequestLine: (currentRequestLineId: string) => void;
  currentRequestLine?: FileSparePartRequestLine;
}

interface UseGetRequestLinesTypes {
  fileId: string;
}

export const GetRequestinesQuery = 'getRequestLinesQuery';

export const useGetRequestines = ({
  fileId,
}: UseGetRequestLinesTypes): UseGetRequestLinesReturnValue => {
  const [currentRequestLine, setCurrentRequestLine] =
    useState<FileSparePartRequestLine>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { pushErrors, removeAllNotifications } = useToasts();

  const {
    data: requestLines,
    isLoading,
    refetch,
  } = useQuery(
    [GetRequestinesQuery, { fileId }],
    async () => {
      if (!fileId) {
        return undefined;
      }

      removeAllNotifications();

      const response = await SparePartService.getRequestLines(fileId);

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      suspense: true,
    },
  );

  const handleCloseModal = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  const handleSetCurrentRequestLine = useCallback(
    async (currentRequestLineId: string) => {
      if (!currentRequestLineId || !requestLines) {
        return;
      }

      const currentRequestLineFilter = requestLines.find(
        (requestLine) => requestLine.requestLineId === currentRequestLineId,
      );

      if (!currentRequestLineFilter) {
        throw new Error(
          `Got an exception trying to find current requestLineId`,
        );
      }
      setCurrentRequestLine(currentRequestLineFilter);
      setIsOpenModal(true);
    },
    [requestLines],
  );

  const handleRequestLineStatusUpdate = useCallback(async () => {
    if (!currentRequestLine) {
      return;
    }
    removeAllNotifications();

    const response = await SparePartService.setRequestLineStatus(
      currentRequestLine.requestLineId,
      RequestLineStatus.ORDER_DELIVERED,
    );

    if (response.failure) {
      pushErrors(response.errors);
    }

    setIsOpenModal(false);
    refetch();
  }, [currentRequestLine, pushErrors, refetch, removeAllNotifications]);

  return {
    isLoading,
    isOpenModal,
    handleCloseModal,
    requestLines,
    handleSetCurrentRequestLine,
    handleRequestLineStatusUpdate,
    currentRequestLine,
  };
};

export default useGetRequestines;
