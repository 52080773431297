import size from 'lodash/size';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';

import IssueCard from './IssueCard/IssueCard';
import { IssuesContainer } from './Issues.styles';
import messages from './messages';

const Issues = ({ issues, onSelect, isLoaded, issueIsSelected }) => {
  return (
    <IssuesContainer>
      <Row center="xs">
        {sortBy(issues, ['displayOrder']).map(
          ({ key, id, name, didactic }, index) => (
            <Col key={id} xs={12} sm={6} md={4} lg={3}>
              <IssueCard
                index={index}
                keyIcon={key}
                onSelect={onSelect}
                name={name}
                didactic={didactic}
                id={id}
                isLoading={issueIsSelected}
              />
            </Col>
          ),
        )}
      </Row>
      {isLoaded && size(issues) === 0 && (
        <SafeFormattedMessage {...messages.noIssues} />
      )}
    </IssuesContainer>
  );
};

Issues.propTypes = {
  onSelect: PropTypes.func,
  isLoaded: PropTypes.bool,
  issues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      didactic: PropTypes.string,
      displayOrder: PropTypes.number.isRequired,
    }).isRequired,
  ),
};

Issues.defaultProps = {
  issues: [],
  onSelect: undefined,
  isLoaded: false,
};

Issues.displayName = 'Issues';

export default Issues;
