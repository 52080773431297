import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';

import { SafeFormattedMessage } from '../../../../formatters';
import { AttachmentIcon, CrossIcon } from '../../../../protons/icons';
import { Button } from '../../../button';
import { $Container, FieldMessage, Wrapper } from '../../common';
import {
  FIELD_STATUS,
  FieldMessages,
} from '../../common/helpers/getFinalFieldState.types';

import useAttachmentItem from './AttachmentItem.hooks';
import {
  $ImgPreview,
  AttachmentContent,
  AttachmentsListFileName,
  StyledProgressBar,
} from './AttachmentItem.styles';

interface AttachmentItemProps {
  file: { value: File; progress?: number };
  onRemove?: (item: { value: File; progress?: number }) => void;
  name: string;
  isLiveUpload?: boolean;
  errors?: FieldMessages;
  dataTestId?: string;
  withImagePreview?: boolean;
}

const AttachmentItem: FunctionComponent<
  React.PropsWithChildren<AttachmentItemProps>
> = ({
  file,
  onRemove = undefined,
  name,
  isLiveUpload = true,
  errors,
  dataTestId,
  withImagePreview,
}) => {
  const { status, message, progress, fileName } = useAttachmentItem({
    file,
    isLiveUpload,
    errors,
    name,
  });
  const theme = useTheme();

  const isImage = file?.value?.type?.includes('image') && withImagePreview;

  return (
    <$Container data-testid="attachmentItem">
      <Wrapper status={status} name={name} isFullWidth={!withImagePreview}>
        <StyledProgressBar
          percent={progress}
          isError={status === FIELD_STATUS.ERROR}
          isSuccess={status === FIELD_STATUS.SUCCESS}
          hideOnFinish
        >
          <AttachmentContent>
            {!isImage && (
              <AttachmentIcon size="18px" color={theme.colors.mainTextColor} />
            )}
            {isImage && <$ImgPreview src={URL.createObjectURL(file.value)} />}

            {fileName && !withImagePreview && (
              <AttachmentsListFileName>
                {SafeFormattedMessage(fileName)}
              </AttachmentsListFileName>
            )}
          </AttachmentContent>

          {onRemove && (
            <AttachmentContent $fixed>
              <Button
                onClick={() => onRemove(file)}
                type={BUTTON_TYPES.BUTTON}
                icon={<CrossIcon />}
                naked
                dataTestId="deleteAttachment"
              />
            </AttachmentContent>
          )}
        </StyledProgressBar>
      </Wrapper>
      <FieldMessage message={message} status={status} dataTestId={dataTestId} />
    </$Container>
  );
};

AttachmentItem.displayName = 'AttachmentItem';

export default AttachmentItem;
