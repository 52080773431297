import { APIConfiguration } from '@savgroup-front-common/configuration';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';
import {
  AdditionalInformation,
  AdditionalInformationExtended,
  Reason,
} from 'myaccount/types';

interface ReasonAdapterReturnValues extends Reason {
  neededInformation: AdditionalInformationExtended[];
}

interface ReasonAdapterArgs {
  reasonSelected: Reason;
  claimIds: string[];
  currentClaimId: string;
}

export const buildFileUploadEndpoints = ({
  claimId,
  id,
}: {
  claimId: string;
  id: string;
}): Record<string, { uploadEndpoint: string; deleteEndpoint: string }> => {
  return {
    [claimId]: {
      uploadEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
      deleteEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
    },
  };
};

export const reasonAdapter = ({
  reasonSelected,
  currentClaimId,
}: ReasonAdapterArgs): ReasonAdapterReturnValues => {
  const newNeededInformation: AdditionalInformationExtended[] | any[] =
    reasonSelected.neededInformation.reduce(
      (acc: AdditionalInformation[], curr: AdditionalInformation) => {
        const { id, type } = curr;

        const isFileType = type === ADDITIONAL_INFORMATION_TYPES.FILE;

        return [
          ...acc,
          {
            ...curr,
            internalId: `${id}_${currentClaimId}`,
            currentClaimId,
            fileUploadEndpoints: isFileType
              ? buildFileUploadEndpoints({
                  claimId: currentClaimId,
                  id,
                })
              : null,
          },
        ];
      },
      [],
    );

  return {
    ...reasonSelected,
    neededInformation: newNeededInformation,
  };
};

export const prepareReasonAdditionalInformation = (
  claimId: string,
  reasonAdditionalInformation?: Record<string, { value: string } | string>,
  neededInformations?: { id: string }[],
): { id: string; value: string }[] => {
  const neededInformationIds =
    neededInformations?.map((neededInformation) => neededInformation.id) ?? [];

  if (!reasonAdditionalInformation) {
    return [];
  }

  return Object.keys(reasonAdditionalInformation)
    .filter((key) => {
      const needInformationKey = key.endsWith(claimId)
        ? key.replace(`_${claimId}`, '')
        : key;

      return neededInformationIds.includes(needInformationKey);
    })
    .map((key) => {
      const needInformationKey = key.endsWith(claimId)
        ? key.replace(`_${claimId}`, '')
        : key;

      const value =
        typeof reasonAdditionalInformation[key] === 'object'
          ? reasonAdditionalInformation[key].value
          : reasonAdditionalInformation[key];

      return { id: needInformationKey, value };
    });
};
