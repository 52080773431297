import PropTypes from 'prop-types';
import React from 'react';

import { Checkbox } from '@savgroup-front-common/core/src/atoms/checkbox';

import { ChooseProductsHeaderContainer } from './ChooseProductsHeader.styles';
import messages from './messages';

function ChooseProductsHeader({ onChange, checked }) {
  return (
    <ChooseProductsHeaderContainer>
      <Checkbox
        label={messages.selectAll}
        checked={checked}
        onChange={onChange}
      />
    </ChooseProductsHeaderContainer>
  );
}

ChooseProductsHeader.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default React.memo(ChooseProductsHeader);
