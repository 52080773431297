import React, { FunctionComponent } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { getAnimationDirectionClassNames } from '../../animations/helper';

import useTabBodyAnimation from './hooks/useTabBodyAnimation';
import { $TabViewAnimation, $TabViewTransitionContainer } from './Tabs.styles';
import { Tab } from './Tabs.types';

interface TabsBodyProps {
  activeTab: Tab;
  tabs: Tab[];
  values?: Record<string, unknown>;
  withTransition: boolean;
}

const TabsBody: FunctionComponent<TabsBodyProps> = ({
  activeTab,
  tabs,
  values,
  withTransition,
}) => {
  const animationType = useTabBodyAnimation(tabs, activeTab);

  const classNames = getAnimationDirectionClassNames(animationType);

  if (!withTransition) {
    return (
      <>
        {tabs
          .filter((tab) => activeTab && activeTab.name === tab.name)
          .map(({ name, Component = undefined, children = undefined }) => {
            if (!children && !Component) {
              throw new Error(
                `[Tabs: ${name}] shoud have at a children or a component'`,
              );
            }

            return (
              <>{children || (Component && <Component values={values} />)}</>
            );
          })}
      </>
    );
  }

  return (
    <$TabViewTransitionContainer>
      <TransitionGroup
        component={null}
        childFactory={(child) => React.cloneElement(child, { classNames })}
      >
        {tabs
          .filter((tab) => activeTab && activeTab.name === tab.name)
          .map(({ name, Component = undefined, children = undefined }) => {
            if (!children && !Component) {
              throw new Error(
                `[Tabs: ${name}] shoud have at a children or a component'`,
              );
            }

            return (
              <CSSTransition
                key={name}
                timeout={400}
                unmountOnExit
                classNames={classNames}
              >
                {(animationState) => (
                  <$TabViewAnimation
                    animationDuration={400}
                    animationState={animationState}
                  >
                    {children || (Component && <Component values={values} />)}
                  </$TabViewAnimation>
                )}
              </CSSTransition>
            );
          })}
      </TransitionGroup>
    </$TabViewTransitionContainer>
  );
};

TabsBody.displayName = 'TabsBody';

export default TabsBody;
