import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import ProductCard from '@savgroup-front-common/core/src/components/common/productCard/ProductCard';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import OldPageHeader from '../OldPageHeader';

import messages from './messages';

const ClaimWrapper = styled('div')`
  padding: 0;
`;

const ClaimContainer = ({
  children,
  productInfo,
  productFullInfo,
  onContactUs,
  claimType,
  customSubTitle,
  values,
  myClaims,
  isRunning,
  centerTitle,
}) => {
  if (isRunning) {
    return (
      <Grid>
        <BaseLoader messageList={[messages.options]} />
      </Grid>
    );
  }

  return (
    <>
      {productInfo &&
        Object.keys(productInfo).length &&
        claimType === 'issues' && (
          <ProductCard
            {...productInfo}
            buyDate={get(productFullInfo, ['buyDate'])}
            asHeader
          />
        )}

      <ClaimWrapper>
        <OldPageHeader
          onContactUs={onContactUs}
          values={values}
          type={claimType}
          customSubtitle={customSubTitle}
          myClaims={myClaims}
          centerTitle={centerTitle}
        />
        {children}
      </ClaimWrapper>
    </>
  );
};

ClaimContainer.propTypes = {
  isRunning: PropTypes.bool,
  productInfo: PropTypes.shape({
    brandName: PropTypes.string,
    modelName: PropTypes.string,
    modelTypeId: PropTypes.string,
  }),
  children: PropTypes.element,
  onContactUs: PropTypes.func,
  claimType: PropTypes.string,
  customSubTitle: PropTypes.string,
  centerTitle: PropTypes.bool,
  productFullInfo: PropTypes.shape({}),
  values: PropTypes.shape({}),
  myClaims: PropTypes.bool,
};

ClaimContainer.defaultProps = {
  isRunning: true,
  productInfo: null,
  children: null,
  claimType: null,
  onContactUs: null,
  customSubTitle: null,
  centerTitle: false,
  productFullInfo: null,
  values: {},
  myClaims: false,
};

export default ClaimContainer;
