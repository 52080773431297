import { defineMessages } from 'react-intl';

export default defineMessages({
  back: {
    id: 'components.common.addressManagement.back',
    defaultMessage: 'Retour',
  },
  options: {
    id: 'components.common.loading.searchingOptions',
    defaultMessage:
      'We are searching for available options. Please wait a moment...',
  },
});
