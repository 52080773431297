import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';

import {
  constants as actorsConstants,
  reducer as actorsReducer,
} from '@savgroup-front-common/core/src/domains/actors';
import { reducer as attachmentsReducer } from '@savgroup-front-common/core/src/domains/attachments';
import { reducer as carriersReducer } from '@savgroup-front-common/core/src/domains/carriers';
import {
  constants as claimsConstants,
  reducer as claimsReducer,
} from '@savgroup-front-common/core/src/domains/claims';
import { reducer as fileUploadReducer } from '@savgroup-front-common/core/src/domains/fileUpload';
import {
  I18N_KEY,
  reducer as i18nReducer,
} from '@savgroup-front-common/core/src/domains/i18n';
import {
  SAGA_REQUEST_METADATA_KEY,
  reducer as sagaRequestMetadataReducer,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata';
import { reducer as sellerConfigurationReducer } from '@savgroup-front-common/core/src/domains/sellerConfiguration';
import {
  SELLER_DOMAIN_KEY,
  sellerReducer,
} from '@savgroup-front-common/core/src/domains/sellers';
import {
  STORE_DOMAIN_KEY,
  storeReducer,
} from '@savgroup-front-common/core/src/domains/store';
import {
  technicalInterventionsConstants,
  technicalInterventionsReducer,
} from '@savgroup-front-common/core/src/domains/technicalInterventions';
import { reducer as workflowReducer } from '@savgroup-front-common/core/src/domains/workflow';

import AuthReducer from './Auth/reducer';
import currentCarrierReducer from './Carriers/reducer';
import { reducer as claimReducer } from './Claim';
import { reducer as claimCreationReducer } from './Claim/ClaimCreation';
import { reducer as customerReducer } from './Customer';
import { reducer as errorReducer } from './errors';
import fileTrackingReducer from './FileTrackingPage/fileTrackingReducer';
import { reducer as historyReducer } from './history';
import loginFeatureReducer from './Login/loginReducer';
import { reducer as modalsReducer } from './Modals';
import { myReturnReducer } from './MyReturn';
import navigationReducer from './Navigation/reducer';
import myOrderFeatureReducer from './Orders/reducer';
import { reducer as ownerReducer } from './owner';
import { reducer as paymentsReducer } from './payments';
import profileManagementReducer from './ProfileManagement/reducer';

const rootReducer = (routerReducer) =>
  combineReducers({
    [actorsConstants.ACTORS_DOMAIN_KEY]: actorsReducer,
    attachments: attachmentsReducer,
    auth: AuthReducer,
    carriers: carriersReducer,
    claim: claimReducer,
    claimCreation: claimCreationReducer,
    [claimsConstants.CLAIMS_DOMAIN_KEY]: claimsReducer,
    currentCarrier: currentCarrierReducer,
    customer: customerReducer,
    errors: errorReducer,
    fileTrackingPage: fileTrackingReducer,
    fileUpload: fileUploadReducer,
    history: historyReducer,
    [I18N_KEY]: i18nReducer,
    login: loginFeatureReducer,
    modals: modalsReducer,
    myReturn: myReturnReducer,
    navigation: navigationReducer,
    oidc: oidcReducer,
    orders: myOrderFeatureReducer,
    owner: ownerReducer,
    payments: paymentsReducer,
    profileManagement: profileManagementReducer,
    router: routerReducer,
    [STORE_DOMAIN_KEY]: storeReducer,
    [SELLER_DOMAIN_KEY]: sellerReducer,
    [SAGA_REQUEST_METADATA_KEY]: sagaRequestMetadataReducer,
    sellerConfiguration: sellerConfigurationReducer,
    [technicalInterventionsConstants.TECHNICAL_INTERVENTIONS_DOMAIN_KEY]:
      technicalInterventionsReducer,
    workflow: workflowReducer,
  });

export default rootReducer;
