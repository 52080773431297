import React from 'react';
import OrderProductCard from '../OrderProductInfo/OrderProductCard';

const OtherProductCard = ({ product, fileDetailsRoute }) => (
  <OrderProductCard
    fileDetailsRoute={fileDetailsRoute}
    key={product.ownerProductId}
    {...product}
  />
);

OtherProductCard.displayName = 'OtherProductCard'

export default OtherProductCard;