import { defineMessages } from 'react-intl';

export default defineMessages({
  failedConsistencyChecks: {
    id: 'view.components.myaccount.claimGroups.failedConsistencyChecks',
    defaultMessage:
      'Unfortunatly, these products cannot be returned together. However, you can return them by starting a new claim for each one separately.',
  },
  inconsistantWarranty: {
    id: 'view.components.myaccount.claimGroups.inconsistantWarranty',
    defaultMessage:
      'Unfortunatly, your products have different warranty state. Your products cannot be sent in one go. However, you can return them by starting a new claim for each one separately.',
  },
  inconsistantTransportCost: {
    id: 'view.components.myaccount.claimGroups.inconsistantTransportCost',
    defaultMessage:
      'Unfortunatly, transport costs of your products are incompatible. Your products cannot be sent in one go. However, you can return them by starting a new claim for each one separately.',
  },
  weightError: {
    id: 'view.components.myaccount.claimGroups.weightError',
    defaultMessage:
      'Unfortunatly, the final package is too heavy. Your products cannot be sent in one go. However, you can return them by starting a new claim for each one separately.',
  },
  carriersError: {
    id: 'view.components.myaccount.claimGroups.carriersError',
    defaultMessage: 'Failed to get carrier info for this step.',
  },
  startNew: {
    id: 'components.common.claimHandling.startNew',
    defaultMessage: 'Start a new claim',
  },
  selectCarrierTypeWizard: {
    id: 'view.components.myaccount.handling.selectCarrierTypeWizard',
    defaultMessage: 'Select carrier type',
  },
  carrierFormWizard: {
    id: 'view.components.myaccount.handling.carrierFormWizard',
    defaultMessage: 'Carrier form',
  },
});
