import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import { Paragraph } from '@savgroup-front-common/core/src/atoms/Paragraph';
import { WarningIcon } from '@savgroup-front-common/core/src/protons/icons';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { getTextColorBasedOnProps } from '@savgroup-front-common/core/src/theme/helpers';

import useGetStripePromise from '../../../hooks/useGetStripePromise';
import messages from '../messages';

import {
  $CardElement,
  $ErrorMessage,
  $SingleErrorMessage,
  style,
} from './CardField.styles';
import { Label } from '@savgroup-front-common/core/src/atoms/Form/common/Label/Label';

const CardField = ({ additionalErrors, onChange, isError, ...rest }) => {
  const theme = useTheme();
  const stripePromise = useGetStripePromise();

  const [state, setState] = useState({ error: null });

  const { error } = state;

  const handleValueChange = (event) => {
    setState({
      error: event.error && event.error.message,
    });

    onChange(event);
  };

  return (
    <div>
      <Label isError={!!error || isError} isRequired>
        <SafeFormattedMessageWithoutSpread
          message={messages.creditCardDetails}
        />
      </Label>

      <$CardElement
        onChange={handleValueChange}
        stripe={stripePromise()}
        options={{
          style: style({ theme }),
        }}
        danger={isError || error}
      />

      {error ? (
        <$SingleErrorMessage>
          <Col xs={1}>
            <WarningIcon
              color={getTextColorBasedOnProps({
                theme,
                danger: true,
                isError,
                ...rest,
              })}
            />
          </Col>
          <Col xs>
            <Paragraph danger>{error}</Paragraph>
          </Col>
        </$SingleErrorMessage>
      ) : null}

      {additionalErrors.length > 0 ? (
        <$ErrorMessage>
          {additionalErrors.map((err) => (
            <React.Fragment key={err.message}>
              <Col xs={1}>
                <WarningIcon
                  color={getTextColorBasedOnProps({
                    theme,
                    danger: true,
                    isError,
                    ...rest,
                  })}
                />
              </Col>
              <Col xs>
                <Paragraph danger>{err.message}</Paragraph>
              </Col>
            </React.Fragment>
          ))}
        </$ErrorMessage>
      ) : null}
    </div>
  );
};

CardField.propTypes = {
  onChange: PropTypes.func.isRequired,
  additionalErrors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ).isRequired,
};

CardField.displayName = 'CardField';

export default CardField;
