import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

const ConnectionErrorIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color}>
    <path d="M0,0h24v24H0V0z" fill="none" />
    <path d="M12,4C7.31,4,3.07,5.9,0,8.98L12,21l5-5.01V8h5.92C19.97,5.51,16.16,4,12,4z" />
    <rect height="2" width="2" x="19" y="18" />
    <rect height="6" width="2" x="19" y="10" />
  </Icon>
);

ConnectionErrorIcon.displayName = 'ConnectionErrorIcon';

export default ConnectionErrorIcon;
