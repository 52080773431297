import React, { FunctionComponent, useMemo } from 'react';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';

import { InjectSvg } from '../../atoms/images';

import { $ProductIconContainer } from './ProductIcon.styles';

const DEFAULT_PRODUCT_ICON_URL = '/images/defaultProduct.svg';

interface ProductIconProps {
  modelTypeId?: string;
  onClick?: () => void;
  marginTop?: boolean;
}

const ProductIcon: FunctionComponent<ProductIconProps> = ({
  modelTypeId,
  onClick,
  marginTop = true,
}) => {
  const iconUrl = useMemo(() => {
    return `${APIConfiguration.catalogCDN}product-icons/${modelTypeId}.svg`;
  }, [modelTypeId]);

  return (
    <$ProductIconContainer
      key={modelTypeId || '-'}
      onClick={onClick}
      $marginTop={marginTop}
    >
      <InjectSvg iconUrl={iconUrl} iconUrlFallback={DEFAULT_PRODUCT_ICON_URL} />
    </$ProductIconContainer>
  );
};

ProductIcon.displayName = 'ProductIcon';

export default ProductIcon;
