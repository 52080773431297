import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import {
  BackResponseFailure,
  BaseBackResponse,
  FileId,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { extendedRequest } from '../../services';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/actionsAsynchronize`;

export interface SetUserActionAdditionalInformationsPayload {
  claimId: string;
  id: string;
  value?: string | File;
}

interface SetUserActionAsyncCommandPayload {
  fileId: FileId;
  additionalFileInformation?: SetUserActionAdditionalInformationsPayload[];
  module: {
    id: string;
    definitionId: string;
    wave: number;
    parameters: Record<string, unknown>;
  };
  fromState: string;
  toState: string;
  comment?: string;
  toCustomerComment?: string;
  sessionId?: string;
}

async function setUserActionAsyncCommand({
  fileId,
  additionalFileInformation,
  fromState,
  toCustomerComment,
  comment,
  toState,
  module,
  sessionId,
}: SetUserActionAsyncCommandPayload): Promise<
  BaseBackResponse | BackResponseFailure
> {
  const functionName = 'setUserActionAsyncCommand';

  if (!fileId) {
    const exception = new RequiredPayloadFieldIsNotProvided(
      'fileId',
      functionName,
    );

    return prepareResponseFailure(exception);
  }

  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const body = new FormData();
    const basePayload = {
      module,
      toState,
      comment,
      toCustomerComment,
      fromState,
      sessionId,
      fileId,
    };

    if (additionalFileInformation && additionalFileInformation.length) {
      const appendedAdditionalFileId = new Set();

      additionalFileInformation.forEach((item) => {
        if (
          item.value instanceof Blob &&
          !appendedAdditionalFileId.has(item.id)
        ) {
          appendedAdditionalFileId.add(item.id);

          body.append(item.id, item.value);
        }
      });

      body.append(
        'SetUserActionAsyncCommand',
        JSON.stringify({
          additionalFileInformation: additionalFileInformation.map((item) => ({
            id: item.id,
            claimId: item.claimId,
            value: item.value instanceof Blob ? '' : item.value,
          })),
          ...basePayload,
        }),
      );
    } else {
      body.append('SetUserActionAsyncCommand', JSON.stringify(basePayload));
    }

    return await extendedRequest(url, {
      method: SUPPORTED_METHODS.PUT,
      body,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default setUserActionAsyncCommand;
