import React, { FunctionComponent } from 'react';
import { Row } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { CheckIcon } from '@savgroup-front-common/core/src/protons/icons';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { Field } from '@savgroup-front-common/core/src/molecules/Field';
import {
  FileSparePartRequestLine,
  RequestLineStatus,
} from '@savgroup-front-common/types';

import { getAdressText, getStatusText } from './DeliveredSpareParts.helpers';
import {
  $DeliveredSparePartActionButton,
  $DeliveredSparePartActionButtonWrapper,
  $DeliveredSparePartColAlignCenter,
  $DeliveredSparePartColWithPaddingTopForXs,
  $DeliveredSparePartIconAndText,
  $DeliveredSparePartIconAndTextMessage,
} from './DeliveredSpareParts.styles';
import messages from './messages';

export interface DeliveredSparePartProps {
  onChange?: () => void;
  requestLine: FileSparePartRequestLine;
}

const DeliveredSparePart: FunctionComponent<
  React.PropsWithChildren<DeliveredSparePartProps>
> = ({ requestLine, onChange }) => {
  const isDelivered =
    requestLine.requestLineStatus === RequestLineStatus.ORDER_DELIVERED;

  const isDisabled =
    requestLine.requestLineStatus === RequestLineStatus.TO_PROCESS;

  const theme = useTheme();

  return (
    <Row between="xs" data-id={requestLine.requestLineId}>
      <$DeliveredSparePartColWithPaddingTopForXs xs={6} sm={2}>
        <Field label={messages.ref}>{requestLine.sparePartReference}</Field>
      </$DeliveredSparePartColWithPaddingTopForXs>
      <$DeliveredSparePartColWithPaddingTopForXs xs={6} sm={2}>
        <Field label={messages.supplier}>{requestLine.supplierName}</Field>
      </$DeliveredSparePartColWithPaddingTopForXs>
      <$DeliveredSparePartColWithPaddingTopForXs xs={6} sm={2}>
        <Field label={messages.statusKey}>
          <SafeFormattedMessageWithoutSpread
            message={getStatusText(requestLine.requestLineStatus)}
          />
        </Field>
      </$DeliveredSparePartColWithPaddingTopForXs>
      <$DeliveredSparePartColWithPaddingTopForXs xs={6} sm={2}>
        <Field label={messages.address}>
          {getAdressText(requestLine.deliveryAddress)}
        </Field>
      </$DeliveredSparePartColWithPaddingTopForXs>
      {!isDelivered && (
        <$DeliveredSparePartActionButtonWrapper xs={12} sm={2}>
          <$DeliveredSparePartActionButton
            theme={theme}
            disabled={isDisabled}
            type={BUTTON_TYPES.BUTTON}
            onClick={() => onChange && onChange()}
          >
            <SafeFormattedMessageWithoutSpread message={messages.confirm} />
          </$DeliveredSparePartActionButton>
        </$DeliveredSparePartActionButtonWrapper>
      )}
      {isDelivered && (
        <$DeliveredSparePartColAlignCenter theme={theme}>
          <$DeliveredSparePartIconAndText>
            <CheckIcon color={theme.colors.success} />
            <$DeliveredSparePartIconAndTextMessage>
              <SafeFormattedMessageWithoutSpread message={messages.submitted} />
            </$DeliveredSparePartIconAndTextMessage>
          </$DeliveredSparePartIconAndText>
        </$DeliveredSparePartColAlignCenter>
      )}
    </Row>
  );
};

DeliveredSparePart.displayName = 'DeliveredSparePart';

export default DeliveredSparePart;
