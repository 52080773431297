import React from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { OrderDetailsProduct } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import { Claim } from '../../../app/NewLayout/StepsProvider/StepsProvider.types';
import messages from '../messages';
import {
  $CardContentThumbnail,
  $CardContentThumbnailWrapper,
  $ResumeDescriptionProduct,
  $ResumeInfoProduct,
  $ResumeInfoProductSerialNumber,
  $ResumeInfoProductTag,
  $ResumeInfoProductTitle,
  $ResumePictureProduct,
  $ResumeSeparator,
} from '../ResumeCard.styles';

import useResumeCardHeader from './ResumeCardHeader.hooks';

interface ResumeCardHeaderProps {
  product: OrderDetailsProduct;
  currentClaim?: Claim;
}

const ResumeCardHeader: React.FC<ResumeCardHeaderProps> = ({
  product,
  currentClaim,
}) => {
  const { modelDetail, serialNumber } = useResumeCardHeader({
    product,
    currentClaim,
  });

  return (
    <>
      <$ResumeDescriptionProduct>
        <$ResumePictureProduct>
          <$CardContentThumbnailWrapper>
            <$CardContentThumbnail>
              {modelDetail?.pictureUrl && modelDetail.modelTypeId ? (
                <img src={modelDetail?.pictureUrl} alt="pictureUrl" />
              ) : (
                <MyAccountIcon icon={ICONS_TYPE.BOX_ICON} />
              )}
            </$CardContentThumbnail>
          </$CardContentThumbnailWrapper>
        </$ResumePictureProduct>
        <$ResumeInfoProduct>
          <$ResumeInfoProductTitle>
            {product?.modelDisplayName}
          </$ResumeInfoProductTitle>
          {serialNumber && (
            <$ResumeInfoProductSerialNumber>
              <SafeFormattedMessageWithoutSpread
                message={messages.serialNumber}
                values={{
                  serialNumber,
                }}
              />
            </$ResumeInfoProductSerialNumber>
          )}
          <$ResumeInfoProductTag />
        </$ResumeInfoProduct>
      </$ResumeDescriptionProduct>
      <$ResumeSeparator />
    </>
  );
};

ResumeCardHeader.displayName = 'ResumeCardHeader';

export default ResumeCardHeader;
