import { AutocompleteOptionForHookForm } from '@savgroup-front-common/types';

export enum ADDRESS_MANAGEMENT_FIELDS {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  CIVILITY = 'civility',
  COMPANY_NAME = 'companyName',
  PHONE = 'phone',
  COUNTRY_CODE = 'countryCode',
  ADDRESS = 'address',
  CITY = 'city',
  ADDITIONAL_INFORMATION = 'additionalInformation',
  ADDITIONAL_ADDRESS = 'additionalAddress',
  POSTAL_CODE = 'postalCode',
  KEEP_SUGGESTED_ADDRESS = 'keepSuggestedAddress',
  HAS_ELEVATOR = 'hasElevator',
  HAS_PARKING_SPACE = 'hasParkingSpace',
  HOUSING_TYPE = 'housingType',
  FLOOR = 'floor',
  DOOR_CODE = 'doorCode',
}

export interface AddressManagementFieldValues {
  [ADDRESS_MANAGEMENT_FIELDS.FIRST_NAME]: string;
  [ADDRESS_MANAGEMENT_FIELDS.LAST_NAME]: string;
  [ADDRESS_MANAGEMENT_FIELDS.CIVILITY]: string;
  [ADDRESS_MANAGEMENT_FIELDS.COMPANY_NAME]: string;
  [ADDRESS_MANAGEMENT_FIELDS.PHONE]: string;
  [ADDRESS_MANAGEMENT_FIELDS.COUNTRY_CODE]: string;
  [ADDRESS_MANAGEMENT_FIELDS.ADDRESS]: string;
  [ADDRESS_MANAGEMENT_FIELDS.CITY]: string;
  [ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_INFORMATION]: string;
  [ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_ADDRESS]: string;
  [ADDRESS_MANAGEMENT_FIELDS.POSTAL_CODE]: string;
  [ADDRESS_MANAGEMENT_FIELDS.KEEP_SUGGESTED_ADDRESS]: string;
  [ADDRESS_MANAGEMENT_FIELDS.HAS_ELEVATOR]: AutocompleteOptionForHookForm;
  [ADDRESS_MANAGEMENT_FIELDS.HAS_PARKING_SPACE]: AutocompleteOptionForHookForm;
  [ADDRESS_MANAGEMENT_FIELDS.HOUSING_TYPE]: AutocompleteOptionForHookForm;
  [ADDRESS_MANAGEMENT_FIELDS.FLOOR]: string;
  [ADDRESS_MANAGEMENT_FIELDS.DOOR_CODE]: string;
}

export interface AddressManagementValues {
  firstName: string;
  lastName: string;
  civility: string;
  companyName: string;
  phone: string;
  countryCode: string;
  address: string;
  city: string;
  additionalInformation: string;
  additionalAddress: string;
  postalCode: string;
  keepSuggestedAddress: string;
  hasElevator: boolean;
  hasParkingSpace: boolean;
  housingType: string;
  floor: string;
  doorCode: string;
}
