import React from 'react';

import Icon from './Icon';

interface ReceiveEurosIconProps {
  color?: string;
  size?: string;
}

const ReceiveEurosIcon: React.FC<ReceiveEurosIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M35 20H21.6667M21.6667 20L28.0667 13.3333M21.6667 20L28.0667 26.6667M18.3333 12.505C17.2315 11.9528 16.0158 11.6657 14.7833 11.6667C10.3 11.6667 6.66667 15.3983 6.66667 20C6.66667 24.6017 10.3 28.3333 14.7833 28.3333C16.0159 28.3353 17.2318 28.0481 18.3333 27.495M5 18.3333H15M5 21.6667H15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

ReceiveEurosIcon.displayName = 'ReceiveEurosIcon';

export default ReceiveEurosIcon;
