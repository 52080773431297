import React, { FunctionComponent, RefObject } from 'react';

import { MENU_POSITIONS, MENU_VARIANTS, MenuItem } from './Menu.types';
import MenuContent from './MenuContent';

interface MenuitemProps {
  menuItems: MenuItem[];
  isOpen: boolean;
  onClose?: () => void;
  position?: MENU_POSITIONS;
  dataTestId?: string;
  wrapperRef: RefObject<any>;
  componentThemeName?: string;
  variant?: MENU_VARIANTS;
}
const Menu: FunctionComponent<MenuitemProps> = ({
  menuItems = [],
  isOpen = false,
  onClose = () => undefined,
  position,
  dataTestId,
  wrapperRef,
  componentThemeName,
  variant = MENU_VARIANTS.DEFAULT,
}) => {
  if (menuItems.length === 0 || !isOpen) {
    return null;
  }

  return (
    <MenuContent
      menuItems={menuItems}
      onClose={onClose}
      position={position}
      dataTestId={dataTestId}
      wrapperRef={wrapperRef}
      componentThemeName={componentThemeName}
      variant={variant}
    />
  );
};

Menu.displayName = 'Menu';

export default Menu;
