import styled from 'styled-components';

import { rem } from '../../../helpers';

export const $Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.disabled};
  margin-bottom: 1rem;
`;

export const $AmountCell = styled.td`
  text-align: right;
`;

export const $AmountHeaderCel = styled.th`
  text-align: right;
  min-width: ${rem(110)};
`;
