import find from 'lodash/find';
import get from 'lodash/get';
import React from 'react';

import ProductIRSSummary from './ProductIRSSummary';
import { SummaryCardContainer } from './ProductIRSSummaryCardsGroup.styles';

const ProductIRSSummaryCard = ({
  multiProduct,
  flatMode,
  goToEditionPage,
  product,
  claimId,
  claimGroupId,
}) => {
  return (
    <SummaryCardContainer multiProduct={multiProduct} flatMode={flatMode}>
      <ProductIRSSummary
        goToEditionPage={goToEditionPage}
        product={product}
        reason={get(product, 'reason')}
        solution={get(product, 'solution')}
        comment={
          get(
            find(get(product, 'comments'), { type: 'ReasonComment' }),
            'content',
          ) || get(product, 'comment')
        }
        documents={get(product, 'documents')}
        price={get(product, 'price')}
        priceUnit={get(product, 'priceUnit')}
        solutionGroupId={get(product, 'solutionGroupId')}
        solutionTypeKey={get(product, 'solutionTypeKey')}
        claimId={claimId}
        claimGroupId={claimGroupId}
      />
    </SummaryCardContainer>
  );
};

ProductIRSSummaryCard.displayName = 'ProductIRSSummaryCard';

export default ProductIRSSummaryCard;
