function convertStyleStringToReactStyle(styleString: string) {
  const styleProperties = styleString.split(';');

  const reactStyle: Record<string, string> = {};

  styleProperties.forEach((prop) => {
    const [key, value] = prop.trim().split(':');
    const camelCaseKey = key.replace(/-([a-z])/g, (match, letter) =>
      letter.toUpperCase(),
    );

    reactStyle[camelCaseKey] = value.trim();
  });

  return reactStyle;
}

export const useFormatMdxContent = (
  input?: string | null | number | boolean,
) => {
  let content: string = input?.toString() || '';

  const styleAttributes = content.match(/style="([^"]+)"/g);

  styleAttributes?.forEach((styleAttr: string) => {
    const styleString = styleAttr.replace(/style="([^"]+)"/, '$1');
    const reactStyle = convertStyleStringToReactStyle(styleString);

    content = content.replaceAll(
      styleAttr,
      `style={${JSON.stringify(reactStyle)}}`,
    );
  });

  content = content.replaceAll('<hr>', '<Hr />');
  content = content
    .replaceAll('<h1>', '<H1>')
    .replaceAll('<h2>', '<H2>')
    .replaceAll('<h3>', '<H3>');
  content = content
    .replaceAll('</h1>', '</H1>')
    .replaceAll('</h2>', '</H2>')
    .replaceAll('</h3>', '</H3>');

  return content;
};
