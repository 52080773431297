import React, { FC, Suspense } from 'react';
import { useIntl } from 'react-intl';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { ClosedLockUnfilled } from '@savgroup-front-common/core/src/protons/icons';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { PAYMENT_STATUS } from '@savgroup-front-common/types';

import { $PaymentContainer } from '../PaymentStripe/components/CheckoutForm.styles';

import {
  $ButtonLink,
  $Heading,
  $PaymentLabel,
} from './CashRegisterPayment.styles';
import { useCashRegisterPayment } from './hooks';
import messages from './messages';

interface CashRegisterPaymentProps {
  paymentUrl: string;
  amount?: number;
  currency?: CURRENCIES;
  isFormCompleted: boolean;
}

const CashRegisterPayment: FC<CashRegisterPaymentProps> = ({
  paymentUrl,
  amount,
  currency = CURRENCIES.EUR,
  isFormCompleted,
}) => {
  const { paymentStatus } = useCashRegisterPayment({ paymentUrl });
  const intl = useIntl();

  return (
    <Suspense fallback={<BaseLoader />}>
      <$PaymentContainer>
        <$Heading>
          <SafeFormattedMessageWithoutSpread
            message={messages.paymentWithoutStripeTitle}
          />
        </$Heading>
        {(!paymentStatus ||
          paymentStatus.status !== PAYMENT_STATUS.CREATED) && (
          <$PaymentLabel>
            <SafeFormattedMessageWithoutSpread
              message={messages.noPaymentFound}
            />
          </$PaymentLabel>
        )}
        {paymentStatus?.status === PAYMENT_STATUS.CREATED && (
          <>
            <$PaymentLabel>
              <SafeFormattedMessageWithoutSpread
                message={messages.paymentWithoutStripeDidactic}
              />
            </$PaymentLabel>
            <$ButtonLink
              isExternal
              href={paymentStatus.paymentUrlWithExternalId}
              primary
              icon={<ClosedLockUnfilled />}
              disabled={!isFormCompleted}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.paymentWithoutStripeButton}
                values={{
                  amount: intl.formatNumber((amount || 0) / 100, {
                    style: 'currency',
                    currency,
                  }),
                }}
              />
            </$ButtonLink>
          </>
        )}
      </$PaymentContainer>
    </Suspense>
  );
};

CashRegisterPayment.displayName = 'CashRegisterPayment';

export default CashRegisterPayment;
