import { defineMessages } from 'react-intl';

import { INVOICE_ITEM_KIND } from '@savgroup-front-common/types';

export default defineMessages({
  title: {
    id: 'components.myaccount.claims.confirmation.orderSummary.title',
    defaultMessage: 'Summary',
  },
  totalPrice: {
    id: 'components.myaccount.claims.confirmation.orderSummary.totalCosts',
    defaultMessage: 'Total price',
  },
  Quote: {
    id: 'components.myaccount.claims.confirmation.orderSummary.quote',
    defaultMessage: "Quote's price",
  },
  Solution: {
    id: 'components.myaccount.claims.confirmation.orderSummary.solution',
    defaultMessage: "Solution's price",
  },
  Transport: {
    id: 'components.myaccount.claims.confirmation.orderSummary.transport',
    defaultMessage: 'Transport',
  },
  Insurance: {
    id: 'components.myaccount.claims.confirmation.orderSummary.insurance',
    defaultMessage: 'Insurance',
  },
  TransportDeposit: {
    id: 'components.myaccount.claims.confirmation.orderSummary.transportDeposit',
    defaultMessage: "Transport's price (Deposit)",
  },
  TransportDelivery: {
    id: 'components.myaccount.claims.confirmation.orderSummary.transportDelivery',
    defaultMessage: "Transport's price (Delivery)",
  },
  TransportHome: {
    id: 'components.myaccount.claims.confirmation.orderSummary.transportHome',
    defaultMessage: "Transport's price (Home)",
  },
  HomePickup: {
    id: 'view.card.handling.homePickup',
    defaultMessage: 'Home pickup',
  },
  TransportDepositInsurance: {
    id: 'components.myaccount.claims.confirmation.orderSummary.transportDepositInsurance',
    defaultMessage: 'Transport insurance (From the owner)',
  },
  TransportDeliveryInsurance: {
    id: 'components.myaccount.claims.confirmation.orderSummary.transportDeliveryInsurance',
    defaultMessage: 'Transport insurance (To the owner)',
  },
  PricesVatIncl: {
    id: 'components.myaccount.claims.confirmation.orderSummary.PricesVatIncl',
    defaultMessage: 'All prices are VAT included',
  },
  [INVOICE_ITEM_KIND.QUOTATION_ADMINISTRATIVE_FEES]: {
    id: 'components.myaccount.quoteInformation.administrativeFees',
    defaultMessage: 'Administrative Fees',
  },
  [INVOICE_ITEM_KIND.QUOTATION_UNKNOWN_SERVICE]: {
    id: 'components.myaccount.quoteInformation.unknownService',
    defaultMessage: 'Service',
  },
  [INVOICE_ITEM_KIND.QUOTATION_DISCOUNT]: {
    id: 'components.myaccount.quoteInformation.discount',
    defaultMessage: 'Discount',
  },
  [INVOICE_ITEM_KIND.QUOTATION_UNKNOWN_SPARE_PART]: {
    id: 'components.myaccount.quoteInformation.unknownSpareParts',
    defaultMessage: 'Spare parts',
  },
  [INVOICE_ITEM_KIND.QUOTATION_SPARE_PART]: {
    id: 'components.myaccount.quoteInformation.spareParts',
    defaultMessage: 'Spare parts',
  },
  [INVOICE_ITEM_KIND.QUOTATION_SERVICE]: {
    id: 'components.myaccount.quoteInformation.service',
    defaultMessage: 'Service',
  },
});
