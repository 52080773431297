import validator from 'validator';
import * as Yup from 'yup';

import { isValidPhoneNumber } from '@savgroup-front-common/core/src/helpers';

import { ADDRESS_MANAGEMENT_FIELDS } from './AddressManagement.types';
import messages from './messages';

const AddressManagementSchema = Yup.object().shape({
  [ADDRESS_MANAGEMENT_FIELDS.ADDRESS]: Yup.string().required(
    messages.requiredField,
  ),
  [ADDRESS_MANAGEMENT_FIELDS.PHONE]: Yup.string()
    .required(messages.requiredField)
    .test('validPhoneNumber', messages.phoneFormatError, (value, context) => {
      const { countryCode } = context.parent;

      return isValidPhoneNumber(value, countryCode);
    }),
  [ADDRESS_MANAGEMENT_FIELDS.COMPANY_NAME]: Yup.string(),
  [ADDRESS_MANAGEMENT_FIELDS.COUNTRY_CODE]: Yup.string().required(
    messages.requiredField,
  ),
  [ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_INFORMATION]: Yup.string().max(
    180,
    messages.maxLengthError,
  ),
  [ADDRESS_MANAGEMENT_FIELDS.LAST_NAME]: Yup.string().required(
    messages.requiredField,
  ),
  [ADDRESS_MANAGEMENT_FIELDS.FIRST_NAME]: Yup.string().required(
    messages.requiredField,
  ),
  [ADDRESS_MANAGEMENT_FIELDS.CIVILITY]: Yup.string(),
  [ADDRESS_MANAGEMENT_FIELDS.POSTAL_CODE]: Yup.string()
    .required(messages.requiredField)
    .test('validPostalCode', messages.badFormatError, (value) => {
      if (value) {
        return validator.isAlphanumeric(value.replace(/\s+/g, ''));
      }

      return false;
    }),
  [ADDRESS_MANAGEMENT_FIELDS.CITY]: Yup.string().required(
    messages.requiredField,
  ),
  [ADDRESS_MANAGEMENT_FIELDS.KEEP_SUGGESTED_ADDRESS]: Yup.string(),
});

export default AddressManagementSchema;
