import { defineMessages } from 'react-intl';

export default defineMessages({
  descriptionHomePickup: {
    id: 'components.myaccount.claims.handling.modal.description',
    defaultMessage:
      'You will be able to take an appointement once you finished the claim and after it was validated. To give you an idea of availabilities here are the approximative time slotss for next 3 days.',
  },
  continueButton: {
    id: 'components.myaccount.claims.handling.modal.continue',
    defaultMessage: 'Continue',
  },
  deposit_Home: {
    id: 'view.card.handling.homePickup',
    defaultMessage: 'Home pickup',
  },
});
