import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col } from 'react-styled-flexboxgrid';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { selectSellerConfigBySellerId } from '@savgroup-front-common/core/src/domains/sellerConfiguration/selectors';

import AgreementCheckbox from '../AgreementCheckbox';

import { $AgreementRow, RowWrapper } from './ConfirmationButton.styles';
import messages from './messages';

const ConfirmationButton = (props) => {
  const { isLoading, onValid, disabled, sellerId } = props;

  const [isSalesConditionsValidated, setIsSalesConditionsValidated] =
    useState(false);

  const handleValidateSalesConditions = () => {
    setIsSalesConditionsValidated(!isSalesConditionsValidated);
  };

  const handleSubmit = () => {
    if (!isLoading) {
      onValid();
    }
  };

  const sellerConfig = useSelector((state) =>
    selectSellerConfigBySellerId(state, { sellerId }),
  );

  return (
    <RowWrapper between="xs">
      {sellerConfig && (
        <Col xs={12} md={6}>
          <$AgreementRow data-term-checkbox>
            <AgreementCheckbox
              agreementHref={
                sellerConfig?.cgvUrl ||
                `${APIConfiguration.catalogCDN}miscellaneous/CGU%20Revers.io%20-%20Client%20final_fr.pdf`
              }
              isChecked={isSalesConditionsValidated}
              onChange={handleValidateSalesConditions}
            />
          </$AgreementRow>
        </Col>
      )}
      <Col xs={12} md={4}>
        <Button
          primary
          fluid
          type={BUTTON_TYPES.SUBMIT}
          disabled={disabled || !isSalesConditionsValidated}
          isLoading={isLoading}
          onClick={handleSubmit}
          data-submit-button
        >
          <FormattedMessage className="description" {...messages.validate} />
        </Button>
      </Col>
    </RowWrapper>
  );
};

ConfirmationButton.propTypes = {
  onValid: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  sellerConfig: PropTypes.shape({}).isRequired,
};

ConfirmationButton.defaultProps = {
  disabled: false,
  onValid: () => {},
};

export default ConfirmationButton;
