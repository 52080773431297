import React, { FunctionComponent } from 'react';
import { Col } from 'react-styled-flexboxgrid';
import 'react-tippy/dist/tippy.css';

import { Checkbox } from '@savgroup-front-common/core/src/atoms/checkbox';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { useGetFullOwnerProductQuery } from '@savgroup-front-common/core/src/hooks';
import {
  FileShortSummary,
  OutcomeHistory,
  PRODUCT_OUTCOME,
} from '@savgroup-front-common/types';
import { ERROR_TYPES } from 'myaccount/view/pages/ChooseProductsPage/ChooseProducts/ChooseProducts.types';

import messages from './messages';
import MinimalOrderProductInfo from './MinimalOrderProductInfo';
import { $CheckboxCol } from './OrderProductInfo.styles';
import {
  $ErrorMessage,
  $FlexRow,
  $StyledCard,
} from './SelectProductCard.styles';

interface ProductsError {
  ownerProductId: string;
  errorType: ERROR_TYPES;
}

interface SelectProductCardProps {
  disabled?: boolean;
  productsError: ProductsError[];
  onChangeProductToReturn: (ownerProductId: string) => void;
  ownerProductId: string;
  dataTestId?: string;
  modelTypeId: string;
  modelName: string;
  brandName: string;
  orderNumber: string;
  purchaseDate: string;
  claimId: string;
  modelId: string;
  isSelectedProduct?: boolean;
  outcomeHistory: OutcomeHistory[];
  previousFile?: FileShortSummary;
}

const SelectProductCard: FunctionComponent<
  React.PropsWithChildren<SelectProductCardProps>
> = ({
  disabled,
  productsError,
  onChangeProductToReturn,
  ownerProductId,
  dataTestId,
  modelTypeId,
  modelName,
  brandName,
  orderNumber,
  purchaseDate,
  claimId,
  isSelectedProduct,
  modelId,
  outcomeHistory,
  previousFile,
}) => {
  const previousFileOwnerId = previousFile?.ownerId;
  const productError = productsError.find(
    (p) => p.ownerProductId === ownerProductId,
  );
  const isOucomeHistoryRepaired = outcomeHistory.some(
    (history) => history.outcome === PRODUCT_OUTCOME.REPAIRED,
  );
  const isOucomeHistorySwapped = outcomeHistory.some(
    (history) => history.outcome === PRODUCT_OUTCOME.SWAPPED,
  );
  const isOucomeHistoryRefunded = outcomeHistory.some(
    (history) => history.outcome === PRODUCT_OUTCOME.REFUNDED,
  );
  const { ownerProduct } = useGetFullOwnerProductQuery({
    ownerProductId,
    ownerId: previousFileOwnerId,
  });

  return (
    <$StyledCard data-testid={dataTestId}>
      <$FlexRow middle="xs">
        <Col xs={12}>
          <MinimalOrderProductInfo
            modelTypeId={modelTypeId}
            modelName={modelName}
            brandName={brandName}
            orderNumber={orderNumber}
            purchaseDate={purchaseDate}
            disabled={
              disabled || isOucomeHistoryRefunded || isOucomeHistorySwapped
            }
            claimId={claimId}
            shouldRenderChildrenOnTop
            modelId={modelId}
            isOucomeHistoryRepaired={isOucomeHistoryRepaired}
            isOucomeHistoryRefunded={isOucomeHistoryRefunded}
            isOucomeHistorySwapped={isOucomeHistorySwapped}
            serialNumber={ownerProduct?.serialNumber}
          >
            <$CheckboxCol>
              <Checkbox
                name="selectProduct"
                disabled={
                  disabled || isOucomeHistoryRefunded || isOucomeHistorySwapped
                }
                checked={isSelectedProduct}
                onChange={() => onChangeProductToReturn(ownerProductId)}
              />
            </$CheckboxCol>
          </MinimalOrderProductInfo>
        </Col>
      </$FlexRow>
      {productError?.errorType === ERROR_TYPES.NO_SELECTED_PRODUCT && (
        <$ErrorMessage>
          <SafeFormattedMessageWithoutSpread
            message={messages.noProductSelected}
          />
        </$ErrorMessage>
      )}
      {productError?.errorType ===
        ERROR_TYPES.MORE_THAN_ONE_REPAIRED_PRODUCT && (
        <$ErrorMessage>
          <SafeFormattedMessageWithoutSpread
            message={messages.noMoreThanOneRepairedProductSelected}
          />
        </$ErrorMessage>
      )}
    </$StyledCard>
  );
};

SelectProductCard.displayName = 'SelectProductCard';

export default React.memo(SelectProductCard);
