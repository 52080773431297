import { DefaultStyledProps } from '@savgroup-front-common/types';

interface ColorBasedOnProps extends DefaultStyledProps {
  disabled?: boolean;
  danger?: boolean;
}
export const colorBasedOnProps = (props: ColorBasedOnProps) => {
  const { theme } = props;

  if (props.disabled) {
    return theme.colors.disabled;
  }
  if (props.danger) {
    return theme.colors.danger;
  }

  return theme.colors.default;
};

interface ColorHoverBasedOnProps extends DefaultStyledProps {
  $isNewBoEnabled?: boolean;
}

export const colorHoverBasedOnProps = ({
  theme,
  $isNewBoEnabled,
}: ColorHoverBasedOnProps) => {
  if ($isNewBoEnabled) {
    return theme.newUI.variationColors?.dark1;
  }

  return theme.colors.primary;
};
