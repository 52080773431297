import styled from 'styled-components';

export const $QuotationLineHead = styled.tr<{ $isActive: boolean }>`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.mainTextColor : theme.colors.paragraphTextColor};
  text-align: left;

  & th {
    padding: 1rem;
    background-color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.headerTitleColor : theme.colors.secondaryColor2};
  }
`;
