import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { ButtonLink } from '../../../atoms/button';
import { ChevronLeftIcon } from '../../../protons/icons';
import { SafeFormattedMessage } from '../../../formatters';

import { GridOverride } from './LinkHeader.styles';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

export default function LinkHeader({ href, message, isExternal }) {
  return (
    <GridOverride>
      <Row>
        <Col>
          <ButtonLink
            icon={<ChevronLeftIcon />}
            naked
            href={href}
            isExternal={isExternal}
            data-gohomepage-button
            componentThemeName={SUPPORTED_COMPONENTS.BACK_BUTTON}
          >
            <SafeFormattedMessage {...message} />
          </ButtonLink>
        </Col>
      </Row>
    </GridOverride>
  );
}

LinkHeader.propTypes = {
  href: PropTypes.string.isRequired,
  message: PropTypes.shape({}).isRequired,
  isExternal: PropTypes.bool,
};

LinkHeader.defaultProps = {
  isExternal: false,
};
