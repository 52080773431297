/* eslint-disable react-hooks/exhaustive-deps */
import includes from 'lodash/includes';
import { useEffect, useState } from 'react';

export default function useGetContent({ url, headers = {} }) {
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    if (url) {
      fetch(url, { headers })
        .then((response) => response.text())
        .then((svg) => {
          if (isMounted) {
            if (includes(svg, 'BlobNotFound')) {
              return Promise.reject();
            }
            setContent(svg);
          }

          return null;
        })
        .then(
          () => {
            if (isMounted) {
              setError(false);
            }
          },
          () => {
            if (isMounted) {
              setError(true);
            }
          },
        );
    }

    return () => {
      isMounted = false;
    };
  }, [url]);

  return [content, error];
}
