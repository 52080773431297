import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Modal,
  MODAL_SIZES,
} from '@savgroup-front-common/core/src/atoms/modal';

import SuggestedAddressModalContent from './SuggestedAddressModalContent';

interface SuggestedAddressModalProps {
  isOpen: boolean;
  onClose: () => void;
  addressErrors: any;
  suggestedAddress: any;
  onSuggestedAddressModalSubmit: () => void;
  isLoading: boolean;
  isVerificationPending?: boolean;
}

const SuggestedAddressModal: FunctionComponent<
  React.PropsWithChildren<SuggestedAddressModalProps>
> = ({
  isOpen,
  onClose,
  addressErrors,
  suggestedAddress,
  onSuggestedAddressModalSubmit,
  isLoading,
  isVerificationPending,
}) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size={MODAL_SIZES.TINY}
      shouldCloseOnDimmerClick={false}
    >
      <SuggestedAddressModalContent
        addressErrors={addressErrors}
        suggestedAddress={suggestedAddress}
        isLoading={isVerificationPending || isLoading || isSubmitting}
        onClose={onClose}
        onSuggestedAddressModalSubmit={onSuggestedAddressModalSubmit}
      />
    </Modal>
  );
};

SuggestedAddressModal.displayName = 'SuggestedAddressModal';

export default SuggestedAddressModal;
