import findTabIndex from '../helpers/findTabIndex';
import { Tab } from '../Tabs.types';

const useTabNavAnimation = ({
  tabs,
  activeTab,
}: {
  tabs: Tab[];
  activeTab: Tab;
}) => {
  const totalCount = tabs.length;

  const currentIndex = findTabIndex(tabs, activeTab);
  const currentName = tabs[currentIndex]?.name;

  return {
    currentIndex,
    currentName,
    totalCount,
  };
};

export default useTabNavAnimation;
