import React, { FunctionComponent } from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import {
  BUTTON_TYPES,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants';
import { Banner } from '@savgroup-front-common/core/src/atoms/Banners';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { FileSparePartRequestLine } from '@savgroup-front-common/types';

import messagesSpareParts from './DeliveredSparePart/messages';
import {
  $DeliveredSparePartActionButton,
  $DeliveredSparePartInfoModal,
  $DeliveredSparePartInfoModalKey,
  $DeliveredSparePartInfoModalValue,
  $DeliveredSparePartRowModal,
} from './DeliveredSparePartsList.styles';
import messages from './messages';
import {
  Modal,
  MODAL_SIZES,
} from '@savgroup-front-common/core/src/atoms/modal';

interface DeliveredSparePartsListModalTypeProps {
  isOpen?: boolean;
  onClose: () => void;
  onValidate: () => void;
  currentRequestLine: FileSparePartRequestLine | undefined;
}

const DeliveredSparePartsListModal: FunctionComponent<
  DeliveredSparePartsListModalTypeProps
> = ({ isOpen, onClose, currentRequestLine, onValidate }) => {
  const theme = useTheme();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={MODAL_SIZES.MEDIUM}>
      {currentRequestLine && (
        <>
          <Heading>
            <SafeFormattedMessageWithoutSpread message={messages.title} />
          </Heading>

          <Banner notificationType={NOTIFICATION_TYPES.ALERT} hollow>
            <SafeFormattedMessageWithoutSpread message={messages.warningMsg} />
          </Banner>

          <$DeliveredSparePartRowModal>
            <Col xs={12} sm={9}>
              <$DeliveredSparePartInfoModal>
                <$DeliveredSparePartInfoModalKey>
                  <SafeFormattedMessageWithoutSpread
                    message={messagesSpareParts.ref}
                  />
                </$DeliveredSparePartInfoModalKey>
                <$DeliveredSparePartInfoModalValue>
                  {currentRequestLine.sparePartReference}
                </$DeliveredSparePartInfoModalValue>
              </$DeliveredSparePartInfoModal>
              <$DeliveredSparePartInfoModal>
                <$DeliveredSparePartInfoModalKey>
                  <SafeFormattedMessageWithoutSpread
                    message={messagesSpareParts.supplier}
                  />
                </$DeliveredSparePartInfoModalKey>
                <$DeliveredSparePartInfoModalValue>
                  {currentRequestLine.supplierName}
                </$DeliveredSparePartInfoModalValue>
              </$DeliveredSparePartInfoModal>
            </Col>
            <Col>
              <$DeliveredSparePartActionButton
                theme={theme}
                type={BUTTON_TYPES.BUTTON}
                onClick={onValidate}
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.validateButton}
                />
              </$DeliveredSparePartActionButton>
            </Col>
          </$DeliveredSparePartRowModal>
        </>
      )}
    </Modal>
  );
};

DeliveredSparePartsListModal.displayName = 'DeliveredSparePartsListModal';

export default DeliveredSparePartsListModal;
