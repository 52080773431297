function deepMerge(...sources) {
  let acc = {};

  for (const source of sources) {
    if (Array.isArray(source)) {
      if (!Array.isArray(acc)) {
        acc = [];
      }
      acc = [...acc, ...source];
    } else if (source instanceof Object) {
      for (const [key, value] of Object.entries(source)) {
        if (value instanceof Object && key in acc) {
          acc = { ...acc, [key]: deepMerge(acc[key], value) };
        } else {
          acc = { ...acc, [key]: value };
        }
      }
    }
  }

  return acc;
}

export default deepMerge;
