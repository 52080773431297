import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const SubmitButton = styled(Button)`
  padding: 1rem;

  &[disabled] {
    opacity: 0.5;
  }
`;

export const ModalContent = styled('div')`
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;

  @media (min-width: 769px) {
    width: 56rem;
  }

  @media (min-width: 1024px) {
    width: 61rem;
  }

  @media (min-width: 1200px) {
    width: 76rem;
  }
`;

export const SubmitRow = styled('div')`
  padding: 12px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;
