import {
  LOCAL_STORAGE_KEYS,
  LocalStorageValues,
} from '@savgroup-front-common/types';

interface GetFromLocalStorageProps<T extends LOCAL_STORAGE_KEYS> {
  key: T;
  defaultValue?: LocalStorageValues[T];
}

export const getFromLocalStorage = <T extends LOCAL_STORAGE_KEYS>({
  key,
  defaultValue,
}: GetFromLocalStorageProps<T>): LocalStorageValues[T] => {
  let result;
  const value = window.localStorage.getItem(key);

  try {
    if (value) {
      result = JSON.parse(value);
    } else {
      result = defaultValue;
    }
  } catch (e) {
    if (value === 'true') {
      result = true;
    } else if (value === 'false') {
      result = false;
    } else {
      result = value;
    }
  }

  return result;
};

interface SetToLocalStorageProps<T extends LOCAL_STORAGE_KEYS> {
  key: T;
  value: LocalStorageValues[T];
}

export const setToLocalStorage = <T extends LOCAL_STORAGE_KEYS>({
  key,
  value,
}: SetToLocalStorageProps<T>): void => {
  window.localStorage.setItem(key, JSON.stringify(value));
};
