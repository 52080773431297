import { createSelector } from 'reselect';

import { CommonAppState } from '../CommonAppState';
import {
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '../sagaRequestMetadata/selectors';

import * as types from './actionTypes';
import { SELLER_DOMAIN, SELLER_DOMAIN_KEY } from './constants';
import { SellerId } from './types';

const selectSellerDomain = (state: CommonAppState) => state[SELLER_DOMAIN_KEY];

const selectSellerValue = createSelector([selectSellerDomain], (state) =>
  state ? state[SELLER_DOMAIN.VALUE] : {},
);

const selectByTenantId = createSelector(
  [selectSellerDomain],
  (state) => state[SELLER_DOMAIN.BY_TENANT_ID],
);

export const selectSellerIds = createSelector([selectSellerDomain], (state) =>
  state ? state[SELLER_DOMAIN.SELLER_IDS] : [],
);

export const selectTenantIds = createSelector(
  [selectSellerDomain],
  (state) => state[SELLER_DOMAIN.TENANT_IDS],
);

export const selectSellers = createSelector(
  [selectSellerValue, selectSellerIds],
  (sellers, sellerIds) => sellerIds.map((sellerId) => sellers[sellerId]),
);
export const selectTenants = createSelector(
  [selectSellerValue, selectTenantIds],
  (sellers, sellerIds) => sellerIds.map((sellerId) => sellers[sellerId]),
);
export const selectSellerAndTenants = createSelector(
  [selectSellerValue, selectTenantIds],
  (sellers) => Object.values(sellers),
);

export const selectSellerByIds = createSelector(
  [
    selectSellerValue,
    (_: CommonAppState, { sellerIds }: { sellerIds: SellerId[] }) => sellerIds,
  ],
  (sellers, sellerIds) =>
    sellerIds
      .map((sellerId) => sellers[sellerId] || null)
      .filter((seller) => seller !== null),
);
export const selectSellerById = createSelector(
  [
    selectSellerValue,
    (_: CommonAppState, { sellerId }: { sellerId?: SellerId }) => sellerId,
  ],
  (sellers, sellerId) => (sellerId ? sellers?.[sellerId] : undefined),
);

export const selectSellerByTenantId = createSelector(
  [
    selectByTenantId,
    (_: CommonAppState, { sellerId }: { sellerId: SellerId }) => sellerId,
  ],
  (tenants, sellerId: SellerId) => tenants[sellerId] || [],
);

export const selectGetSellerIsLoading = (state: CommonAppState): boolean =>
  isActionLoadingSelector(state, types.GET_SELLER);

export const selectGetSellerWasLoaded = (state: CommonAppState): boolean =>
  wasActionLoadedSelector(state, types.GET_SELLER);
