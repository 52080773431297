import { Col, Grid } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

export const ButtonActionWrapper = styled('div')`
  margin-bottom: 24px;
`;

export const GridMarginBottom = styled(Grid)`
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
`;

export const GoBackButtonCol = styled(Col)`
  padding-left: 0;
`;
