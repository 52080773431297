import { defineMessages } from 'react-intl';

export default defineMessages({
  products: {
    id: 'components.myaccount.multiProduct.products',
    defaultMessage: 'Product(s)',
  },
  claimGroupContinue: {
    id: 'components.myaccount.multiProduct.claimGroupContinue',
    defaultMessage: 'Continue',
  },
  claimGroupNextProduct: {
    id: 'components.myaccount.multiProduct.claimGroupNextProduct',
    defaultMessage: 'Next product',
  },
  reasonIsRequired: {
    id: 'view.createFile.selectReasonStep.reasonRequired',
    defaultMessage: 'Reason is required',
  },
  reasonCommentIsRequired: {
    id: 'view.createFile.selectReasonStep.reasonCommentIsRequired',
    defaultMessage: 'Comment is required',
  },
  thisFieldIsRequired: {
    id: 'components.myaccount.myReturn.quoteInformation.commentMandatory',
    defaultMessage: 'This field is required',
  },
  documentRequired: {
    id: 'components.myaccount.claims.confirmation.documentRequired',
    defaultMessage: 'This document is mandatory',
  },
});
