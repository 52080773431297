import React, { FunctionComponent } from 'react';

import { useGetFullOwnerProductQuery } from '@savgroup-front-common/core/src/hooks';
import {
  FileShortSummary,
  OutcomeHistory,
  PRODUCT_OUTCOME,
} from '@savgroup-front-common/types';
import MinimalOrderProductInfo from '../../../../components/OrderProductInfo/MinimalOrderProductInfo';

interface NoEligibleOrderProductInfoProps {
  ownerProductId: string;
  modelTypeId: string;
  modelName: string;
  brandName: string;
  orderNumber: string;
  purchaseDate: string;
  claimId: string;
  modelId: string;
  previousFile?: FileShortSummary;
  outcomeHistory?: OutcomeHistory[];
}

const NoEligibleOrderProductInfo: FunctionComponent<
  NoEligibleOrderProductInfoProps
> = ({
  ownerProductId,
  modelTypeId,
  modelName,
  brandName,
  orderNumber,
  purchaseDate,
  claimId,
  modelId,
  previousFile,
  outcomeHistory,
}) => {
  const previousFileOwnerId = previousFile?.ownerId;
  const { ownerProduct } = useGetFullOwnerProductQuery({
    ownerProductId,
    ownerId: previousFileOwnerId,
  });
  const isOucomeHistorySwapped = outcomeHistory?.some(
    (history) => history.outcome === PRODUCT_OUTCOME.SWAPPED,
  );
  const isOucomeHistoryRefunded = outcomeHistory?.some(
    (history) => history.outcome === PRODUCT_OUTCOME.REFUNDED,
  );
  const isOucomeHistoryRepaired = outcomeHistory?.some(
    (history) => history.outcome === PRODUCT_OUTCOME.REPAIRED,
  );

  return (
    <MinimalOrderProductInfo
      modelTypeId={modelTypeId}
      modelName={modelName}
      brandName={brandName}
      orderNumber={orderNumber}
      purchaseDate={purchaseDate}
      claimId={claimId}
      shouldRenderChildrenOnTop
      modelId={modelId}
      serialNumber={ownerProduct?.serialNumber}
      isOucomeHistoryRefunded={isOucomeHistoryRefunded}
      isOucomeHistorySwapped={isOucomeHistorySwapped}
      isOucomeHistoryRepaired={isOucomeHistoryRepaired}
    />
  );
};

NoEligibleOrderProductInfo.displayName = 'NoEligibleOrderProductInfo';

export default NoEligibleOrderProductInfo;
