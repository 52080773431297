import get from 'lodash/get';

import * as ActionTypes from './actionTypes';

export const initialState = {
  hasError: false,
  message: '',
};

function onServerErrorOccured(state, payload) {
  const message = get(payload, 'message');

  return {
    ...state,
    hasError: true,
    message,
  };
}

function onClearErrors(state) {
  return {
    ...state,
    hasError: false,
    message: '',
  };
}

export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SERVER_ERROR_OCCURES.BASE: {
      return onServerErrorOccured(state, action.payload);
    }

    case ActionTypes.CLEAR_ERRORS.BASE: {
      return onClearErrors(state);
    }
    default: {
      return state;
    }
  }
}
