import first from 'lodash/first';
import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import size from 'lodash/size';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';

import { H1, H2 } from '@savgroup-front-common/core/src/atoms/headings';
import LinkHeader from '@savgroup-front-common/core/src/components/common/linkHeader/LinkHeader';
import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';
import { getSellerUrl } from '@savgroup-front-common/core/src/domains/sellerConfiguration/selectors';
import { cancelClaim } from 'myaccount/domains/Claim/claimActions';

import { ROUTES } from '../../../app/routes/Routes';
import {
  FinalizeGroupProductCard,
  OtherProductCard,
  ViewGroupProductCard,
} from '../../../components/ClaimGroupProductCard';

import CreateClaim from './CreateClaim';
import messages from './messages';
import { getOrders, getSubOrders } from './OrderProductList.helpers';
import {
  OrderProductListPosition,
  SectionHeaderContainer,
  Separator,
} from './OrderProductList.styles';

export const GridSeparator = () => (
  <Row>
    <Col xs={12}>
      <Separator />
    </Col>
  </Row>
);

export const SectionTitle = ({ message }) => (
  <Row>
    <Col xs={12}>
      <H2 medium heading>
        <SafeFormattedMessage {...message} />
      </H2>
    </Col>
  </Row>
);

export const SectionHeader = ({ message }) => (
  <SectionHeaderContainer>
    <Grid>
      <Row>
        <Col xs={12}>
          <H1 medium heading>
            <SafeFormattedMessage {...message} />
          </H1>
        </Col>
      </Row>
    </Grid>
  </SectionHeaderContainer>
);

const OrderProductList = ({
  ownerId,
  orders,
  deleteClaimGroupById,
  startClaim,
  regroupProducts,
  regroupOtherProducts,
  sellerId,
}) => {
  const dispatch = useDispatch();

  const dispatchCancelClaim = useCallback(
    (payload) => {
      dispatch(cancelClaim(payload));
    },
    [dispatch],
  );
  const href = useSelector((state) => getSellerUrl(state, { sellerId }));

  const {
    ViewFile,
    FinalizeClaimGroup,
    FinalizeClaim,
    Others = [],
    productsCount = 0,
    NonEligibleForReturn = [],
  } = orders;

  if (productsCount === 0) {
    return (
      <>
        {href ? (
          <LinkHeader href={href} isExternal message={messages.back} />
        ) : null}
        <OrderProductListPosition>
          <Grid>
            <SectionTitle message={messages.noProduct} />
          </Grid>
        </OrderProductListPosition>
      </>
    );
  }

  const otherProductsCount = size(Others);
  const nonEligibleForReturnCount = size(NonEligibleForReturn);
  const productsWithFilesOrClaimsCount =
    productsCount - otherProductsCount - nonEligibleForReturnCount;

  const regroupFinalizeClaimGroupProducts = map(
    FinalizeClaimGroup,
    (claimGroup) => regroupProducts(claimGroup),
  );
  const regroupFinalizeClaimProducts = map(FinalizeClaim, (claim) =>
    regroupProducts(claim),
  );
  const regroupOthers = regroupOtherProducts(Others);
  const regroupedNonEligibleForReturn =
    regroupOtherProducts(NonEligibleForReturn);
  const regroupViewFile = map(ViewFile, (claim) => regroupProducts(claim));

  const otherOrderIds = getOrders(Others);
  const nonEligibleForReturnOrderIds = getOrders(NonEligibleForReturn);
  const finalizeClaimGroupOrderIds = getSubOrders(
    Object.values(FinalizeClaimGroup),
  );
  const finalizeClaimOrderIds = getSubOrders(FinalizeClaim);
  const viewFileOrderIds = getSubOrders(ViewFile);
  const ordersIds = uniqBy(
    [
      ...otherOrderIds,
      ...nonEligibleForReturnOrderIds,
      ...finalizeClaimGroupOrderIds,
      ...finalizeClaimOrderIds,
      ...viewFileOrderIds,
    ],
    'orderId',
  );
  const orderNumber = get(first(ordersIds), 'orderNumber', '');

  return (
    <>
      {href ? (
        <LinkHeader href={href} isExternal message={messages.back} />
      ) : null}
      <OrderProductListPosition>
        <SectionHeader
          message={
            size(ordersIds) > 1
              ? messages.products
              : { ...messages.oneProduct, values: { order: orderNumber } }
          }
        />
        <Grid>
          {productsWithFilesOrClaimsCount ? (
            <>
              {otherProductsCount > 0 && (
                <SectionTitle message={messages.productWithClaim} />
              )}
              {map(regroupViewFile, (products, index) => {
                const fileReference = get(
                  head(products),
                  'ongoingFile.fileReference',
                );

                return (
                  <ViewGroupProductCard
                    key={index}
                    products={products}
                    fileNumber={fileReference}
                    fileDetailsLink={ROUTES.FILE_FOLLOWUPS.replace(
                      ':fileId',
                      products[0].ongoingFile.fileId,
                    )}
                    fileDetailsRoute={ROUTES.FILE_FOLLOWUPS}
                  />
                );
              })}
              {map(regroupFinalizeClaimGroupProducts, (products, index) => {
                const claimGroupId = get(head(products), 'claimGroupId');

                return (
                  <FinalizeGroupProductCard
                    key={index}
                    products={products}
                    finalizeClaimLink={ROUTES.CLAIM_GROUP_DETAILS.replace(
                      ':claimGroupId',
                      claimGroupId,
                    )}
                    cancelClaim={() => deleteClaimGroupById(claimGroupId)}
                    fileDetailsRoute={ROUTES.FILE_FOLLOWUPS}
                  />
                );
              })}
              {map(regroupFinalizeClaimProducts, (product) => (
                <FinalizeGroupProductCard
                  key={product.claimContextId}
                  products={[product]}
                  finalizeClaimLink={ROUTES.CLAIM_DETAILS.replace(
                    ':claimId',
                    product.claimContextId,
                  )}
                  cancelClaim={() =>
                    dispatchCancelClaim({
                      ownerId,
                      productId: product.ownerProductId,
                      claimId: product.claimContextId,
                    })
                  }
                  fileDetailsRoute={ROUTES.FILE_FOLLOWUPS}
                />
              ))}
            </>
          ) : null}
          {productsWithFilesOrClaimsCount > 0 && otherProductsCount > 0 && (
            <GridSeparator />
          )}
          {otherProductsCount > 0 && (
            <>
              <CreateClaim createClaim={startClaim} />
              <GridSeparator />
              {productsWithFilesOrClaimsCount ? (
                <SectionTitle message={messages.otherProduct} />
              ) : null}
              {map(regroupOthers, (product) => (
                <OtherProductCard
                  fileDetailsRoute={ROUTES.FILE_FOLLOWUPS}
                  key={product.ownerProductId}
                  product={product}
                />
              ))}
            </>
          )}
          {NonEligibleForReturn.length > 0 && (
            <>
              <GridSeparator />
              <SectionTitle message={messages.titleNotEligible} />
              {map(regroupedNonEligibleForReturn, (product) => {
                return (
                  <OtherProductCard
                    fileDetailsRoute={ROUTES.FILE_FOLLOWUPS}
                    key={product.ownerProductId}
                    product={product}
                  />
                );
              })}
            </>
          )}
        </Grid>
      </OrderProductListPosition>
    </>
  );
};

OrderProductList.propTypes = {
  ownerId: PropTypes.string.isRequired,
  sellerId: PropTypes.string,
  orders: PropTypes.shape({
    ViewFile: PropTypes.shape({}).isRequired,
    FinalizeClaimGroup: PropTypes.shape({}).isRequired,
    FinalizeClaim: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    Others: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    productsCount: PropTypes.number.isRequired,
  }),
  assets: PropTypes.shape({
    imagePath: PropTypes.string,
    imageID: PropTypes.string,
    fileIconPath: PropTypes.string,
    endOfWarranty: PropTypes.string,
    spinnerPath: PropTypes.string,
  }),
  regroupProducts: PropTypes.func.isRequired,
  regroupOtherProducts: PropTypes.func.isRequired,
};

OrderProductList.defaultProps = {
  sellerId: undefined,
  assets: {
    imagePath: '/images/catalog/modelTypes/built/##ID##.svg',
    imageID: '##ID##',
    fileIconPath: '/images/icon_facture_jaune@2x.png',
    endOfWarranty: '/images/icon_findegarantie@1x.png',
    spinnerPath: '/images/spinner.gif',
  },
  orders: {
    NonEligibleForReturn: [],
  },
};

export default OrderProductList;
