import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { Modal } from '@savgroup-front-common/core/src/atoms/modal';
import SwipeableDrawer, {
  DRAWER_SIZE,
} from '@savgroup-front-common/core/src/atoms/SwipeableDrawer/SwipeableDrawer';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { PickupPoint } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import StepLayout from 'myaccount/view/app/NewLayout/StepLayout/StepLayout';
import { ResumeCard } from 'myaccount/view/components/ResumeCard';
import { ResumeCardButton } from 'myaccount/view/components/ResumeCardButton';

import useGetPickupPoints from '../../app/hooks/useGetPickupPoints';
import { AddressManagement } from '../../components/AddressManagement';
import HandlingCard from '../../components/HandlingCard';
import PickupPointSelector from '../../components/PickupPointSelector';
import { ResumeDeposit } from '../../components/ResumeDeposit';
import { ResumeSolution } from '../../components/ResumeSolution';

import useClaimGroupDeliveryPage from './ClaimGroupDeliveryPage.hooks';
import {
  $DeliveryItemWrapper,
  $DeliveryResultWrapper,
  $DeliveryWrapper,
  $Heading,
  $HeadingIcon,
  $LeftColumn,
} from './ClaimGroupDeliveryPage.styles';
import messages from './messages';

const ClaimGroupDeliveryPage: React.FC = () => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const {
    claimGroupConfirmation,
    groupedCarriers,
    claimGroupId,
    formContext,
    setChangedValue,
    changeSelection,
    onSubmit,
    isDeliveryHandlingLoading,
    isLoadingSubmit,
    chooseHandlingSelected,
    handlingSelected,
    homeSelectedAddressChanged,
    hasDeposit,
  } = useClaimGroupDeliveryPage();

  const { setValue } = formContext;

  const {
    sellerId,
    carriers,
    addressSelected,
    pickupPoints,
    noPickupPoints,
    addresses,
    handleSetAddressSelected,
    selectedPickupPoint,
    pickupPointsIsLoading,
  } = useGetPickupPoints({
    selectedCarrierType: chooseHandlingSelected,
    groupedCarriers,
    claimGroupId,
  });

  const handleSetSelectedPickupPoint = (pickupPointSelected: PickupPoint) => {
    Promise.resolve(setValue('pickupPointSelected', pickupPointSelected)).then(
      () => onSubmit(),
    );
  };

  const handleOpenModal = () => setOpenResumeModal((prev) => !prev);

  if (!claimGroupId) {
    throw new Error('Undefined claimGroupId');
  }

  return (
    <>
      <FormProvider {...formContext}>
        <form onSubmit={onSubmit}>
          <StepLayout
            hasFooter={!(handlingSelected || homeSelectedAddressChanged)}
            isLoading={isLoadingSubmit}
          >
            <$DeliveryWrapper>
              <$LeftColumn>
                <ResumeSolution
                  claimGroupId={claimGroupId}
                  claimGroupConfirmation={claimGroupConfirmation}
                />
                {hasDeposit && (
                  <ResumeDeposit
                    claimGroupConfirmation={claimGroupConfirmation}
                  />
                )}
                <$Heading>
                  <$HeadingIcon>
                    <MyAccountIcon icon={ICONS_TYPE.DELIVERY_ICON} />
                  </$HeadingIcon>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.handlingTitle}
                  />
                </$Heading>
                <$DeliveryItemWrapper>
                  {isDeliveryHandlingLoading ? (
                    <BaseLoader />
                  ) : (
                    <HandlingCard
                      groupedCarriers={groupedCarriers}
                      setChangedValue={setChangedValue}
                    />
                  )}
                </$DeliveryItemWrapper>
              </$LeftColumn>
              <$DeliveryResultWrapper>
                {isMobileView ? (
                  <ResumeCardButton
                    claimGroupConfirmation={claimGroupConfirmation}
                    handleOpenResumeModal={handleOpenModal}
                  />
                ) : (
                  <ResumeCard
                    claimGroupConfirmation={claimGroupConfirmation}
                    hasPrice
                    hasDepositPrice
                  />
                )}
              </$DeliveryResultWrapper>
            </$DeliveryWrapper>
          </StepLayout>
          <SwipeableDrawer
            isOpen={handlingSelected}
            onClose={changeSelection}
            hasBackdrop
            hasPadding={false}
            hasBackdropScroll={false}
            hasCloseButton={false}
          >
            {handlingSelected && (
              <PickupPointSelector
                maxDistance={200}
                pickupPoints={pickupPoints}
                selectedPickupPointId={
                  selectedPickupPoint ? selectedPickupPoint.id : undefined
                }
                onSelectPickupPoint={handleSetSelectedPickupPoint}
                searchAddress={addressSelected}
                userAddresses={addresses}
                onAddressSelected={handleSetAddressSelected}
                carriers={carriers}
                onClose={changeSelection}
                pickupPointsIsLoading={pickupPointsIsLoading}
                sellerId={sellerId}
                noPickupPoints={noPickupPoints}
              />
            )}
          </SwipeableDrawer>
          <SwipeableDrawer
            isOpen={homeSelectedAddressChanged}
            size={DRAWER_SIZE.SMALL}
            onClose={changeSelection}
            hasBackdrop
            hasBackdropScroll={false}
          >
            <AddressManagement />
          </SwipeableDrawer>
        </form>
      </FormProvider>
      <Modal
        isOpen={openResumeModal}
        onClose={handleOpenModal}
        shouldShowPadding={false}
      >
        <ResumeCard claimGroupConfirmation={claimGroupConfirmation} />
      </Modal>
    </>
  );
};

ClaimGroupDeliveryPage.displayName = 'ClaimGroupDeliveryPage';

export default ClaimGroupDeliveryPage;
