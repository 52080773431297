/* eslint-disable max-len */
import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

const RebaseIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '16px',
}) => (
  <Icon size={size} color={color} viewBox="0 96 960 960">
    <path d="m430 1011-42-42 83-83H316q-10 39-42 64.5T200 976q-50 0-85-35t-35-85q0-42 25.5-74t64.5-42V412q-39-10-64.5-42T80 296q0-50 35-85t85-35q42 0 74 25.5t42 64.5h155l-83-83 42-42 156 155-156 155-42-42 83-83H316q-8 32-31 55t-55 31v328q32 8 55 31t31 55h155l-83-83 42-42 156 155-156 155Zm330-35q-50 0-85-35t-35-85q0-41.98 25.5-73.99T730 740V412q-39-10-64.5-42.01T640 296q0-50 35-85t85-35q50 0 85 35t35 85q0 42-25.5 74T790 412v328q39 10 64.5 42t25.5 74q0 50-35 85t-85 35Zm-560-60q25.5 0 42.75-17.25T260 856q0-25.5-17.25-42.75T200 796q-25.5 0-42.75 17.25T140 856q0 25.5 17.25 42.75T200 916Zm560 0q25.5 0 42.75-17.25T820 856q0-25.5-17.25-42.75T760 796q-25.5 0-42.75 17.25T700 856q0 25.5 17.25 42.75T760 916ZM200 356q25.5 0 42.75-17.25T260 296q0-25.5-17.25-42.75T200 236q-25.5 0-42.75 17.25T140 296q0 25.5 17.25 42.75T200 356Zm560 0q25.5 0 42.75-17.25T820 296q0-25.5-17.25-42.75T760 236q-25.5 0-42.75 17.25T700 296q0 25.5 17.25 42.75T760 356ZM200 856Zm560 0ZM200 296Zm560 0Z" />
  </Icon>
);

RebaseIcon.displayName = 'RebaseIcon';

export default RebaseIcon;
