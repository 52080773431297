import styled from 'styled-components';

import Button from '../button/Button';

export const $ButtonGroupWrapper = styled.div`
  display: flex;
  margin-left: 1rem;

  & > button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid transparent;
  }

  & > button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const $ListPaginationButton = styled(Button)`
  &,
  &:focus,
  &:active,
  &:visited {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px;
    gap: 10px;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.default};
    height: auto;
    min-height: auto;
    width: auto;
    min-width: auto;

    & > span > svg {
      fill: ${({ theme }) => theme.colors.black};
      margin-right: 0;
    }

    &[disabled] {
      background-color: transparent;
      & > span > svg {
        fill: ${({ theme }) => theme.colors.default};
      }
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryColor2};
    border: 1px solid ${({ theme }) => theme.colors.default};
    &:not(:first-child) {
      border-left: 1px solid transparent;
    }
  }
`;

export const $ListPaginationInputText = styled.div`
  display: flex;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  text-align: right;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  white-space: nowrap;
  margin-left: 0.5rem;
`;

export const $ListPaginationInterval = styled.button<{ $isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.colors.default};
  border-right: none;
  border-radius: 0;
  cursor: pointer;
  background: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.secondaryColor2 : theme.colors.white};

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
    border-right: 1px solid ${({ theme }) => theme.colors.default};
    margin-right: 0.5rem;
  }
`;

export const $ListPaginationIntervalOnlyNumber = styled.span`
  margin-right: 4px;
`;

export const $ListPaginationIntervalWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const $ListPaginationPage = styled.div`
  display: flex;
  align-items: center;

  && input {
    width: 70px;
    text-align: center;
    height: auto;
    padding: 4px 8px;
  }
`;

export const $ListPaginationWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  width: 100%;
`;

export const $Spacer = styled.div`
  flex: 1;
`;
