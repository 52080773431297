import { tint, transparentize } from 'polished';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { H4 } from '@savgroup-front-common/core/src/atoms/headings';
import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';
import getTextColorBasedOnProps from '@savgroup-front-common/core/src/theme/helpers/getTextColorBasedOnProps';

export const PointItemContainer = styled(Col)`
  margin: 0;
  padding: 0 2px;

  &:first-child {
    padding-left: 12px;
  }
  &:nth-last-child(2) {
    padding-right: 18px;
  }

  ${mediaQuery.sm`
    padding: 0 6px;
    &:first-child { padding-left: 0; }
    &:nth-last-child(2) { padding-right: 0; }
  `};
`;

export const RowWrapper = styled(Row)`
  cursor: pointer;
  margin: 5px 0;
  background-color: #ffffff;
  padding: 5px;
  border-radius: ${({ theme }) => theme.borders.radius};
  border-width: ${({ theme }) => theme.borders.thickness};
  border-style: solid;
  border-color: ${(props) =>
    props.active
      ? getTextColorBasedOnProps(props)
      : transparentize(0.85, getTextColorBasedOnProps(props))};

  ${mediaQuery.sm`
    margin: 0 -0.5rem;
    padding: 16px 24px;
    border-radius: 0;
    border: 0;
    border-bottom: ${(props) =>
      ` ${props.theme.borders.thickness} solid 
      ${transparentize(0.85, getTextColorBasedOnProps(props))}
    `};
    background-color: ${(props) =>
      props.active ? tint(0.9, getTextColorBasedOnProps(props)) : ''};
  `} h3, p {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  h3 {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const PriceAndBrandContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BrandLogoContainer = styled('div')`
  text-align: right;

  img {
    max-width: 100%;
    margin-top: 8px;
    ${mediaQuery.sm`
      width: auto;
    `};
    object-fit: cover;
    ${mediaQuery.sm`max-height: 40px;`};
  }
`;

export const PriceH4 = styled(H4)`
  text-align: right;
`;

export const ButtonHours = styled(Button)`
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  &:after {
    height: 0;
  }
`;

export const DistanceContainer = styled('div')`
  color: ${(props) =>
    getTextColorBasedOnProps({ theme: props.theme, primary: true })};
  display: flex;
  align-items: center;
  margin: 6px 0;

  svg {
    position: relative;
    top: -2px;
    height: 12px;
    margin-right: 4px;
    path {
      fill: ${(props) =>
        getTextColorBasedOnProps({ theme: props.theme, primary: true })};
    }
  }
`;
