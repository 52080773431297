import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Row } from 'react-styled-flexboxgrid';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Img } from '@savgroup-front-common/core/src/atoms/Img';
import { Paragraph } from '@savgroup-front-common/core/src/atoms/Paragraph';
import { H3 } from '@savgroup-front-common/core/src/atoms/headings';
import {
  Modal,
  MODAL_SIZES,
} from '@savgroup-front-common/core/src/atoms/modal';
import PickupPointItemInfo from '@savgroup-front-common/core/src/components/PickupPointItemInfo';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { intl } from '@savgroup-front-common/core/src/helpers';

import messages from '../messages';

import {
  BrandLogoContainer,
  ButtonHours,
  DistanceContainer,
  PointItemContainer,
  PriceAndBrandContainer,
  PriceH4,
  RowWrapper,
} from './PickupPointItem.styles';
import { LocationIcon } from '@savgroup-front-common/core/src/protons/icons';

class PickupPointItem extends Component {
  constructor(props) {
    super(props);
    this.ensureVisible = this.ensureVisible.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isSelectedOnClick: false,
      moveToSelected: false,
      isHoursInfoModalOpen: false,
    };
  }

  componentDidMount() {
    this.ensureVisible();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isSelectedOnClick } = this.state;
    const { id } = this.props;

    if (nextProps.selectedPickupPointId === id && !isSelectedOnClick) {
      this.setState({
        moveToSelected: true,
      });
    } else {
      this.setState({
        moveToSelected: false,
        isSelectedOnClick: false,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isHoursInfoModalOpen } = this.state;
    const { id, selectedPickupPointId } = this.props;

    if (isHoursInfoModalOpen !== nextState.isHoursInfoModalOpen) {
      return true;
    }

    return (
      nextProps.id !== id ||
      nextProps.selectedPickupPointId !== selectedPickupPointId
    );
  }

  componentDidUpdate() {
    const { moveToSelected } = this.state;

    if (moveToSelected) {
      this.ensureVisible();
    }
  }

  handleClick() {
    const { pickupPoint, onSelectPickupPoint } = this.props;

    this.setState({ isSelectedOnClick: true }, () => {
      onSelectPickupPoint(pickupPoint);
    });
  }

  toggleModal() {
    this.setState((prevState) => ({
      isHoursInfoModalOpen: !prevState.isHoursInfoModalOpen,
    }));
  }

  ensureVisible() {
    const { id, selectedPickupPointId } = this.props;

    if (selectedPickupPointId === id) {
      const dom = this.node;
      const parent = dom.parentElement.parentElement;

      if (parent) {
        parent.scrollTop = dom.offsetTop - dom.clientHeight;
      }
    }
  }

  render() {
    const {
      selectedPickupPointId,
      pickupPoint,
      priceFormat,
      registerCard,
      sellerId,
    } = this.props;
    const { isHoursInfoModalOpen } = this.state;
    const {
      id,
      name,
      adress,
      city,
      postalCode,
      distanceInMeters,
      carrierName,
      chain,
      carrierBrand,
      openingHours,
      priceWithTax,
    } = pickupPoint || {};

    const normalizedDistance =
      distanceInMeters < 1000
        ? distanceInMeters
        : Math.round(distanceInMeters / 100) / 10;
    const distanceUnit = distanceInMeters < 1000 ? 'm' : 'km';

    return (
      <PointItemContainer
        xs={10}
        sm={12}
        ref={registerCard}
        data-testid={!priceWithTax && 'freeDropOff'}
      >
        <RowWrapper
          between="xs"
          primary={selectedPickupPointId === id}
          active={selectedPickupPointId === id}
          onClick={this.handleClick}
        >
          <Col xs={8} sm={7}>
            <Row>
              <H3 heading className="dropoffPointName">
                {name}
              </H3>
            </Row>
            <Row>
              <Paragraph>
                {adress}
                <br />
                {postalCode} {city}
              </Paragraph>
            </Row>
            <Row>
              <DistanceContainer>
                <LocationIcon />{' '}
                {`${safeFormattedIntlString(
                  messages.distance,
                )} ${intl.formatNumber(normalizedDistance)}${distanceUnit}`}
              </DistanceContainer>
            </Row>
            <Row between="xs">
              <ButtonHours
                underline
                main
                onClick={this.toggleModal}
                type={BUTTON_TYPES.BUTTON}
              >
                {safeFormattedIntlString(messages.openingHours)}
              </ButtonHours>
            </Row>
          </Col>
          <PriceAndBrandContainer xs>
            <BrandLogoContainer>
              <Img
                alt={carrierName}
                urls={[
                  `${APIConfiguration.imageCDN}sellers/${sellerId}/${(
                    chain ||
                    carrierBrand ||
                    'external'
                  ).toLowerCase()}.png`,
                  `${APIConfiguration.catalogCDN}carriers/${(
                    chain ||
                    carrierBrand ||
                    'external'
                  ).toLowerCase()}.png`,
                ]}
              />
            </BrandLogoContainer>
            <PriceH4 heading>
              {priceWithTax ? (
                <FormattedNumber value={priceWithTax} format={priceFormat} />
              ) : (
                safeFormattedIntlString(messages.free)
              )}
            </PriceH4>
          </PriceAndBrandContainer>
        </RowWrapper>
        <Modal
          size={MODAL_SIZES.SMALL}
          isOpen={isHoursInfoModalOpen}
          onClose={this.toggleModal}
        >
          <PickupPointItemInfo openingHours={openingHours} />
          <Button
            primary
            fluid
            hollow
            onClick={this.toggleModal}
            type={BUTTON_TYPES.BUTTON}
          >
            <FormattedMessage {...messages.close} />
          </Button>
        </Modal>
      </PointItemContainer>
    );
  }
}

PickupPointItem.propTypes = {
  onSelectPickupPoint: PropTypes.func.isRequired,
  selectedPickupPointId: PropTypes.string,
};

PickupPointItem.defaultProps = {
  selectedPickupPointId: null,
};

const mapStateToProps = (state) => {
  const { orders } = state;

  const { items } = orders;
  const sellerId = _.get(_.first(items), 'sellerId', null);

  return {
    sellerId,
  };
};

PickupPointItem.displayName = 'PickupPointItem';

export default connect(mapStateToProps)(PickupPointItem);
