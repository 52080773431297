import styled from 'styled-components';

import { media } from '../../../../../constants/src/shared/media';
import { decelerateTimingFunctionTransition } from '../../../animations/timingFunction';

interface DropzoneProps {
  $isDragActive: boolean;
}

export const $FileInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${media.minWidth.sm} {
    flex-direction: row;
  }
  justify-content: space-between;
  border: 1px solid #e1e4e8;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
`;

export const $FileInputLabelContainer = styled.div`
  flex: 1;
  padding-bottom: 24px;
  [class*='Labelstyles'] span {
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.systemColors.defaultTextColor};
    font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
    height: unset;
    margin: unset;
  }
  [class*='LabelWrap'] {
    height: unset;
  }
  label {
    height: unset;
  }
  @media ${media.minWidth.sm} {
    padding-right: 24px;
    padding-bottom: unset;
  }
`;

export const $FileInputDropZoneContainer = styled.div`
  border-top: 1px solid #e1e4e8;
  width: 100%;

  @media ${media.minWidth.sm} {
    border-top: unset;
    border-left: 1px solid #e1e4e8;
    width: 223px;
    height: 114px;
  }
`;

export const $Didactics = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 1rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 24px;
`;

export const $FileInput = styled.input`
  display: none;
`;

export const $Dropzone = styled.button<DropzoneProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background: transparent;
  border: none;
  padding-top: 24px;
  transition-duration: 0.3s;
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
  ${decelerateTimingFunctionTransition};
  svg {
    margin-bottom: 0.5rem;
  }

  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
  span:last-child {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    cursor: pointer;
  }

  @media ${media.minWidth.sm} {
    padding-top: unset;
    padding-left: 24px;
    padding-right: unset;
  }
`;
