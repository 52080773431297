import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const CheckIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} fill="none" viewBox="0 0 14 14">
    <g id="Check">
      <path
        id="vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.60733 7.27414C2.77819 7.10328 3.0552 7.10328 3.22605 7.27414L5.25003 9.29811L10.774 3.77414C10.9449 3.60328 11.2219 3.60328 11.3927 3.77414C11.5636 3.94499 11.5636 4.222 11.3927 4.39286L5.55938 10.2262C5.38853 10.397 5.11152 10.397 4.94067 10.2262L2.60733 7.89286C2.43648 7.722 2.43648 7.44499 2.60733 7.27414Z"
        fill={color}
      />
    </g>
  </Icon>
);

CheckIcon.displayName = 'CheckIcon';

export default CheckIcon;
