import React from 'react';

interface CrackedEggIconProps {
  color?: string;
}

/* eslint-disable max-len */
const CrackedEggIcon: React.FC<CrackedEggIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 36.6667C23.5362 36.6667 26.9276 35.2619 29.428 32.7614C31.9285 30.2609 33.3333 26.8696 33.3333 23.3333C33.3333 15.97 27.3633 3.33334 20 3.33334C12.6366 3.33334 6.66663 15.97 6.66663 23.3333C6.66663 26.8696 8.07138 30.2609 10.5719 32.7614C13.0724 35.2619 16.4637 36.6667 20 36.6667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8333 5.83334L19.9999 13.3333L15.8333 18.3333L19.9999 24.1667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

CrackedEggIcon.displayName = 'CrackedEggIcon';

export default CrackedEggIcon;
