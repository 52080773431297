import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const EmptyStateIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
}) => (
  <svg
    width="187"
    height="161"
    viewBox="0 0 187 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="92.0094" cy="80.5" r="80.5" fill={color} fillOpacity="0.05" />
    <g filter="url(#filter0_d_921_52065)">
      <path
        d="M145.595 19H13.4053C10.7014 19 8.5094 21.192 8.5094 23.8959V48.3754C8.5094 51.0793 10.7014 53.2713 13.4053 53.2713H145.595C148.299 53.2713 150.491 51.0793 150.491 48.3754V23.8959C150.491 21.192 148.299 19 145.595 19Z"
        fill="white"
      />
    </g>
    <path
      d="M83.9064 26.8325H58.4477C56.8253 26.8325 55.5101 28.1477 55.5101 29.7701C55.5101 31.3924 56.8253 32.7076 58.4477 32.7076H83.9064C85.5287 32.7076 86.8439 31.3924 86.8439 29.7701C86.8439 28.1477 85.5287 26.8325 83.9064 26.8325Z"
      fill={color}
      fillOpacity="0.2"
    />
    <path
      d="M101.532 39.562H58.4477C56.8253 39.562 55.5101 40.8772 55.5101 42.4996C55.5101 44.1219 56.8253 45.4371 58.4477 45.4371H101.532C103.154 45.4371 104.469 44.1219 104.469 42.4996C104.469 40.8772 103.154 39.562 101.532 39.562Z"
      fill={color}
      fillOpacity="0.1"
    />
    <path
      d="M38.3744 45.4955C43.5118 45.4955 47.6766 41.3308 47.6766 36.1933C47.6766 31.0559 43.5118 26.8911 38.3744 26.8911C33.2369 26.8911 29.0721 31.0559 29.0721 36.1933C29.0721 41.3308 33.2369 45.4955 38.3744 45.4955Z"
      fill={color}
      fillOpacity="0.1"
    />
    <g filter="url(#filter1_d_921_52065)">
      <path
        d="M175.595 63H43.4053C40.7014 63 38.5094 65.192 38.5094 67.8959V92.3754C38.5094 95.0793 40.7014 97.2713 43.4053 97.2713H175.595C178.299 97.2713 180.491 95.0793 180.491 92.3754V67.8959C180.491 65.192 178.299 63 175.595 63Z"
        fill="white"
      />
    </g>
    <path
      d="M113.906 70.8325H88.4477C86.8253 70.8325 85.5101 72.1477 85.5101 73.7701C85.5101 75.3924 86.8253 76.7076 88.4477 76.7076H113.906C115.529 76.7076 116.844 75.3924 116.844 73.7701C116.844 72.1477 115.529 70.8325 113.906 70.8325Z"
      fill={color}
      fillOpacity="0.2"
    />
    <path
      d="M131.532 83.562H88.4477C86.8253 83.562 85.5101 84.8772 85.5101 86.4996C85.5101 88.1219 86.8253 89.4371 88.4477 89.4371H131.532C133.154 89.4371 134.469 88.1219 134.469 86.4996C134.469 84.8772 133.154 83.562 131.532 83.562Z"
      fill={color}
      fillOpacity="0.1"
    />
    <path
      d="M68.3744 89.4955C73.5118 89.4955 77.6766 85.3308 77.6766 80.1933C77.6766 75.0559 73.5118 70.8911 68.3744 70.8911C63.2369 70.8911 59.0721 75.0559 59.0721 80.1933C59.0721 85.3308 63.2369 89.4955 68.3744 89.4955Z"
      fill={color}
      fillOpacity="0.1"
    />
    <g filter="url(#filter2_d_921_52065)">
      <path
        d="M143.595 107H11.4053C8.70137 107 6.5094 109.192 6.5094 111.896V136.375C6.5094 139.079 8.70137 141.271 11.4053 141.271H143.595C146.299 141.271 148.491 139.079 148.491 136.375V111.896C148.491 109.192 146.299 107 143.595 107Z"
        fill="white"
      />
    </g>
    <path
      d="M81.9064 114.833H56.4477C54.8253 114.833 53.5101 116.148 53.5101 117.77C53.5101 119.392 54.8253 120.708 56.4477 120.708H81.9064C83.5287 120.708 84.8439 119.392 84.8439 117.77C84.8439 116.148 83.5287 114.833 81.9064 114.833Z"
      fill={color}
      fillOpacity="0.2"
    />
    <path
      d="M99.5316 127.562H56.4477C54.8253 127.562 53.5101 128.877 53.5101 130.5C53.5101 132.122 54.8253 133.437 56.4477 133.437H99.5316C101.154 133.437 102.469 132.122 102.469 130.5C102.469 128.877 101.154 127.562 99.5316 127.562Z"
      fill={color}
      fillOpacity="0.1"
    />
    <path
      d="M36.3744 133.496C41.5118 133.496 45.6766 129.331 45.6766 124.193C45.6766 119.056 41.5118 114.891 36.3744 114.891C31.2369 114.891 27.0721 119.056 27.0721 124.193C27.0721 129.331 31.2369 133.496 36.3744 133.496Z"
      fill={color}
      fillOpacity="0.1"
    />
    <defs>
      <filter
        id="filter0_d_921_52065"
        x="2.5094"
        y="16"
        width="153.981"
        height="46.2715"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_921_52065"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_921_52065"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_921_52065"
        x="32.5094"
        y="60"
        width="153.981"
        height="46.2715"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_921_52065"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_921_52065"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_921_52065"
        x="0.509399"
        y="104"
        width="153.981"
        height="46.2715"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_921_52065"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_921_52065"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

EmptyStateIcon.displayName = 'EmptyStateIcon';

export default EmptyStateIcon;
