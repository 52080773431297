import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';
import { H1, H3 } from '@savgroup-front-common/core/src/atoms/headings';
import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const HeaderWrapperCol = styled(Col)`
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 1200px) {
    padding-left: ${(props) => (props.type === 'claim_group_irs' ? 0 : null)};
  }
  ${mediaQuery.sm`
    margin-top: ${rem('0px')};
  `};
`;

export const HeaderH1 = styled(H1)`
  padding-bottom: 0;
  letter-spacing: 0.5px;
  line-height: 40px;
  text-align: ${({ centerTitle }) => (centerTitle ? 'center' : 'left')};
  & {
    padding-top: ${rem('24px')};
  }
  @media ${media.maxWidth.xs} {
    font-size: 1.8rem;
  }
`;

export const SubHeaderH3 = styled(H3)`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  line-height: 20px;
  text-align: ${({ centerTitle }) => (centerTitle ? 'center' : 'left')};
`;

export const PageHeaderContainer = styled(Row)`
  justify-content: ${({ centerTitle }) => (centerTitle ? 'center' : 'start')};
`;
