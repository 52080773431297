import React, { FunctionComponent } from 'react';
import { IntlShape } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { MessageType } from '@savgroup-front-common/types';

import { Button } from '../../atoms/button';
import {
  safeFormattedIntlString,
  SafeFormattedMessage,
} from '../../formatters';

import messages from './messages';
import {
  $CardWrapper,
  $ImgContainer,
  $NameContainer,
  $SubtiltesContainer,
} from './OptionCard.styled';

interface OptionCardProps {
  title: MessageType | string;
  onClick: () => void;
  didactic?: IntlShape | MessageType | string;
  componentThemeNameButton?: string;
  dataTestId?: string;
  isLoading?: boolean;
  buttonType?: BUTTON_TYPES;
}

const OptionCard: FunctionComponent<
  React.PropsWithChildren<OptionCardProps>
> = ({
  title,
  onClick,
  didactic,
  componentThemeNameButton,
  dataTestId,
  children,
  isLoading = false,
  buttonType = BUTTON_TYPES.BUTTON,
}) => {
  const safeTitle = safeFormattedIntlString(title);

  return (
    <$CardWrapper title={safeTitle}>
      <Row>
        <Col xs={3} sm={12}>
          <$ImgContainer>{children}</$ImgContainer>
        </Col>
        <Col xs={9} sm={12}>
          <$NameContainer>{safeTitle}</$NameContainer>
          {didactic ? (
            <$SubtiltesContainer>
              {SafeFormattedMessage(didactic)}
            </$SubtiltesContainer>
          ) : null}
        </Col>
      </Row>
      <Button
        fluid
        primary
        type={buttonType}
        dataTestId={dataTestId}
        componentThemeName={componentThemeNameButton}
        onClick={onClick}
        isLoading={isLoading}
      >
        <SafeFormattedMessage {...messages.go} />
      </Button>
    </$CardWrapper>
  );
};

OptionCard.displayName = 'OptionCard';

export default OptionCard;
