import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { selectLocale } from '../../domains/i18n/selectors';
import useCommonTypedSelector from '../../domains/useCommonTypedSelector';

import { toast } from '../../atoms/Toast';
import {
  defaultLanguage,
  getConfigLanguage,
  IntlGlobalProvider,
} from '../../helpers/intl';
import { useIsSuperAdmin } from '../../hooks';
import { FullScreenLoader } from '../../molecules/FullScreenLoader';

import { fetchDynamicIntl, fetchIntl } from './IntlManager.helpers';

export const IntlManager: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const locale = useCommonTypedSelector(selectLocale);

  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState({});
  const [dynamicMessages, setDynamicMessages] = useState({});

  const config = useMemo(() => getConfigLanguage(locale), [locale]);

  useEffect(() => {
    setIsLoading(true);

    (async function () {
      const response = await fetchIntl(locale);

      if (!response.failure) {
        setMessages(response.value);
      } else {
        setMessages({});
        toast.warn(
          'We encountered a problem getting the required language, try to refresh your browser or contact an administrator.',
        );
      }
      setIsLoading(false);
    })();
  }, [locale]);

  useEffect(() => {
    setIsLoading(true);

    (async function () {
      const response = await fetchDynamicIntl(locale);

      if (!response.failure) {
        setDynamicMessages(response.value);
      } else {
        setDynamicMessages({});
        toast.warn(
          'We encountered a problem getting the required language, try to refresh your browser or contact an administrator.',
        );
      }
      setIsLoading(false);
    })();
  }, [locale]);

  const isSuperAdmin = useIsSuperAdmin();

  return (
    <>
      <FullScreenLoader
        isOpen={isLoading}
        size="55px"
        isIntlInitialized={false}
      />
      <IntlProvider
        key={locale}
        locale={locale}
        messages={{ ...messages, ...dynamicMessages }}
        formats={config.formats}
        textComponent="span"
        defaultLocale={defaultLanguage}
      >
        <IntlGlobalProvider isSuperAdmin={isSuperAdmin}>
          {children}
        </IntlGlobalProvider>
      </IntlProvider>
    </>
  );
};

IntlManager.displayName = 'IntlManager';

export default IntlManager;
