import size from 'lodash/size';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { Autocomplete } from '@savgroup-front-common/core/src/atoms/Form';
import {
  changeLocaleLanguage,
  setOwnerLanguagePreference,
} from '@savgroup-front-common/core/src/domains/i18n/actions';
import { selectLocale } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import { getAllAvailableLanguages } from '@savgroup-front-common/core/src/domains/languages/selectors';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { sortLanguageByPrefered } from '@savgroup-front-common/core/src/helpers';
import { useChangeHTMLLanguage } from '@savgroup-front-common/core/src/hooks';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { LanguageChooserContainer } from './LanguageChooser.styles';
import messages from './messages';

const LanguageChooser = () => {
  const intl = useIntl();
  const languages = useSelector(getAllAvailableLanguages);
  const dispatch = useDispatch();

  const { push } = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setOwnerLanguagePreference({ locale: intl.locale }));
  }, [dispatch, intl.locale]);

  const onUpdateLanguage = useCallback(
    (locale) => {
      dispatch(changeLocaleLanguage({ locale }));

      // reload every call :/
      push(pathname);
    },
    [dispatch, pathname, push],
  );

  const currentLanguage = useSelector(selectLocale);

  useChangeHTMLLanguage(currentLanguage);

  useDeepCompareEffect(() => {
    const [nextCurrentLanguage] = sortLanguageByPrefered(languages);

    if (
      nextCurrentLanguage !== undefined &&
      ((languages.length === 1 && currentLanguage !== nextCurrentLanguage) ||
        !languages.includes(currentLanguage))
    ) {
      onUpdateLanguage(nextCurrentLanguage);
    }
  }, [languages, currentLanguage, onUpdateLanguage]);

  const options = languages.map((languageID) => ({
    value: languageID,
    label: safeFormattedIntlString(messages[languageID]),
  }));

  if (size(languages) <= 1) {
    return null;
  }

  return (
    <LanguageChooserContainer>
      <Autocomplete
        componentThemeName={SUPPORTED_COMPONENTS.LANGUAGE_CHOOSER}
        onChange={({ value: languageID }) => onUpdateLanguage(languageID)}
        value={intl.locale}
        options={options}
        dataTestIdForCypress="language-chooser-dropdown"
      />
    </LanguageChooserContainer>
  );
};

LanguageChooser.displayName = 'LanguageChooser';

export default React.memo(LanguageChooser);
