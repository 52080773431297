import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

export const ModalContent = styled('div')`
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
  width: 100%;
`;

export const ModalRowWithMargin = styled(Row)`
  margin-bottom: 1rem;
`;
