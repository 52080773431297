import { darken } from 'polished';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

import { decelerateTimingFunctionTransition } from '../../animations/timingFunction';
import { getCustomComponentProperty, rem } from '../../helpers';

const BUTTON_SIZE = 30;
const BUTTON_MARGIN = 4;
const NEW_UI_BUTTON_SIZE = 34;

export const PADDING_BUTTONS = (BUTTON_SIZE + BUTTON_MARGIN) * 2;
const NEW_UI_PADDING_BUTTONS = NEW_UI_BUTTON_SIZE + BUTTON_MARGIN;

interface $TabViewNavProps {
  $hasOverflow: boolean;
  $fluid?: boolean;
  $componentThemeName?: string;
}

export const $TabViewNav = styled.nav<$TabViewNavProps>`
  user-select: none;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  padding-top: 0;
  overflow-x: ${({ $hasOverflow }) => ($hasOverflow ? 'auto' : null)};
  &::-webkit-scrollbar {
    height: 0 !important;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  scrollbar-width: none;
  height: ${({ $componentThemeName, theme }) =>
    $componentThemeName
      ? getCustomComponentProperty({
          theme,
          componentName: $componentThemeName,
          property: SUPPORTED_PROPERTIES.HEIGHT,
        })
      : null};

  @media ${media.minWidth.lg} {
    padding-top: ${({ $fluid }) => ($fluid ? 0 : rem(20))};
  }
`;

const margin = 32;

interface $TabViewSelectorProps {
  $position: number;
  $width: number;
  $totalWidth: number;
  $index: number;
  $componentThemeName?: string;
  $hasErrors?: boolean;
  $isNewBoEnabled?: boolean;
}
export const $TabViewSelector = styled.div<$TabViewSelectorProps>`
  height: ${rem(3)};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateX(
      ${({
        $position,
        $index,
        $componentThemeName,
        theme,
        $isNewBoEnabled,
      }) => {
        if ($isNewBoEnabled) {
          return rem($position);
        }

        if ($componentThemeName) {
          return rem(
            $position +
              $index *
                (getCustomComponentProperty({
                  theme,
                  componentName: $componentThemeName,
                  property: SUPPORTED_PROPERTIES.MARGIN,
                }) as any),
          );
        }

        return rem($position + $index * margin);
      }}
    )
    scaleX(
      ${({ $width, $totalWidth }) => {
        if ($totalWidth === 0) {
          return 0;
        }

        return $width / $totalWidth;
      }}
    );

  background-color: ${({ theme, $isNewBoEnabled, $hasErrors }) => {
    if ($hasErrors) {
      return theme.colors.danger;
    }
    if ($isNewBoEnabled) {
      return theme.newUI.variationColors?.light1;
    }

    return theme.colors.primary;
  }};
  transition-duration: 0.3s;
  transform-origin: left;
  ${decelerateTimingFunctionTransition};
`;

interface $TabViewListProps {
  $componentThemeName?: string;
  $fluid?: boolean;
  $isNewBoEnabled?: boolean;
}

export const $TabViewList = styled.ul<$TabViewListProps>`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  padding-right: ${({ $fluid }) => ($fluid ? 0 : rem(PADDING_BUTTONS))};
  li {
    margin: 0;
    &:not(:nth-last-child(-n + 2)) {
      margin-right: ${({ $componentThemeName, $isNewBoEnabled, theme }) => {
        if ($isNewBoEnabled) {
          return null;
        }

        if ($componentThemeName) {
          return rem(
            getCustomComponentProperty({
              theme,
              componentName: $componentThemeName,
              property: SUPPORTED_PROPERTIES.MARGIN,
            }) as any,
          );
        }

        return rem(margin);
      }};
    }
  }
`;
export const $TabContainer = styled.div<{
  $fluid?: boolean;
  $hasBottomBorder?: boolean;
  $isNewBoEnabled?: boolean;
  $isButtonScrollerVisible?: boolean;
}>`
  padding-left: ${({ $isButtonScrollerVisible, $isNewBoEnabled }) =>
    $isButtonScrollerVisible && $isNewBoEnabled
      ? rem(NEW_UI_PADDING_BUTTONS)
      : undefined};
  padding-right: ${({ $fluid, $isNewBoEnabled }) => {
    if ($fluid) {
      return 0;
    }
    if ($isNewBoEnabled) {
      return rem(NEW_UI_PADDING_BUTTONS);
    }

    return rem(PADDING_BUTTONS);
  }};
  position: relative;
  border-bottom: ${({ $hasBottomBorder, $isNewBoEnabled, theme }) => {
    if ($hasBottomBorder) {
      return `1px solid ${theme.colors.lightGrey}`;
    }

    if ($isNewBoEnabled) {
      return `1px solid ${theme.newUI.variationColors?.light4}`;
    }

    return 'none';
  }};
  width: 100%;
`;

export const $ButtonScroller = styled.button<{
  $componentThemeName?: string;
  $isNewBoEnabled?: boolean;
}>`
  width: ${({ $isNewBoEnabled }) =>
    $isNewBoEnabled ? null : rem(BUTTON_SIZE)};
  height: ${({ $isNewBoEnabled }) =>
    $isNewBoEnabled ? null : rem(BUTTON_SIZE)};
  position: absolute;
  bottom: ${({ $componentThemeName, theme, $isNewBoEnabled }) => {
    if ($componentThemeName) {
      return getCustomComponentProperty({
        theme,
        componentName: $componentThemeName,
        property: SUPPORTED_PROPERTIES.MARGIN_BOTTOM,
      });
    }

    if ($isNewBoEnabled) {
      return '7px';
    }

    return '1.2rem';
  }};
  &:not(:last-child) {
    right: ${({ $isNewBoEnabled }) =>
      $isNewBoEnabled ? null : rem(BUTTON_MARGIN + BUTTON_SIZE)};
    left: ${({ $isNewBoEnabled }) => ($isNewBoEnabled ? 0 : null)};
  }
  &:last-child {
    right: 0;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borders.radius};
  svg {
    fill: ${({ theme, $isNewBoEnabled }) =>
      $isNewBoEnabled
        ? theme.newUI.defaultColors.secondaryText
        : theme.colors.primary};
  }
  &:hover {
    background: ${({ theme, $isNewBoEnabled }) =>
      $isNewBoEnabled ? null : theme.colors.primary};
    svg {
      fill: ${({ theme, $isNewBoEnabled }) =>
        $isNewBoEnabled
          ? darken(0.2, theme.newUI.defaultColors.secondaryText)
          : theme.colors.white};
    }
  }
`;

export const $TabItemWrapper = styled.div<{ $fluid?: boolean }>`
  white-space: nowrap;
  width: ${({ $fluid }) => ($fluid ? '100%' : 'auto')};
`;

export const $ScrollDetector = styled.div`
  visibility: hidden;
  width: 0;
`;
