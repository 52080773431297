import React, { FC } from 'react';

import Card from '../../../atoms/Cards/Card';
import { SafeFormattedMessageWithoutSpread } from '../../../formatters/SafeFormattedMessageWithoutSpread';

import messages from './messages';
import AppointmentCalendar from '../components/TakeInterventionAppointment/AppointmentCalendarContainer/AppointmentCalendar/AppointmentCalendar';
import { $Title } from './TakeInterventionAppointmentContainer.styles';
import { InterventionAppointmentHeader } from '../components/TakeInterventionAppointment/AppointmentCalendarContainer/InterventionAppointmentHeader';
import {
  $AppointmentCalendarContainer,
  $CalendarCard,
} from '../components/TakeInterventionAppointment/AppointmentCalendarContainer/AppointmentCalendarContainer.styles';

const AppointmentCalendarContainerSkeleton: FC = () => {
  return (
    <Card colored>
      <$Title>
        <SafeFormattedMessageWithoutSpread message={messages.whatINeedToDo} />
      </$Title>

      <InterventionAppointmentHeader />

      <$Title>
        <SafeFormattedMessageWithoutSpread message={messages.title} />
      </$Title>

      <$CalendarCard>
        <$AppointmentCalendarContainer>
          <AppointmentCalendar
            isGetTimeslotsLoading
            isCreateTechnicalInterventionLoading={false}
            isNextPageDisabled={false}
            isLoading
            isActive
            onValidateSchedule={() => {
              return undefined;
            }}
          >
            <></>
          </AppointmentCalendar>
        </$AppointmentCalendarContainer>
      </$CalendarCard>
    </Card>
  );
};

AppointmentCalendarContainerSkeleton.displayName =
  'AppointmentCalendarContainerSkeleton';

export default AppointmentCalendarContainerSkeleton;
