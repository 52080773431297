export interface ExternalProvider {
  displayName: string | null;
  authenticationScheme: string;
}

export enum Portals {
  BTOB = 'BtoB',
}

export interface ClientInformation {
  clientId?: string;
  clientName?: string;
  enableLocalLogin?: boolean;
  enableServicePortal?: boolean;
  externalProviders: ExternalProvider[];
  id: string;
  hosts?: string[];
  tenantId: string;
  sellerId?: string;
  userType?: string;
  providers: [];
  phoneNumberLoginEnable: boolean;
  productReferenceLoginEnable: boolean;
  isWithTicketNumberLoginEnabled: boolean;
  onLogoutRedirectUrl?: string;
  reCaptchaIsEnabled?: boolean;
  reCaptchaPublicKey?: string;
  bannerUrl?: string;
  enablePortals?: Portals[];
}
