import { yupResolver } from '@hookform/resolvers/yup';
import find from 'lodash/find';
import get from 'lodash/get';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { COUNTRY_CODES } from '@savgroup-front-common/constants';
import { DataGouvService } from '@savgroup-front-common/core/src/api';
import { getSupportedCarrierCompany } from '@savgroup-front-common/core/src/helpers';
import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { Selectors as i18nSelectors } from '@savgroup-front-common/core/src/domains/i18n';
import { CarrierService } from 'myaccount/api';
import { currentGroupHandlingInfo } from 'myaccount/domains/Claim/claimGroupSelector';
import { formatAddress } from 'myaccount/formatters/carrier';

import {
  adaptCustomerAddress,
  adaptGeoCodeAddressFromDataGouv,
  adaptVerifiedAddress,
} from '../adapters';
import AddressManagementSchema from '../AddressManagement.schema';
import {
  ADDRESS_MANAGEMENT_FIELDS,
  AddressManagementFieldValues,
  AddressManagementValues,
} from '../AddressManagement.types';
import isAddressValid from '../helpers/isAddressValid';

import useGetCountriesOptions from './useGetCountriesOptions/useGetCountriesOptions';

const useAddressManagement = ({
  onAddressChange,
  updatedAddress,
  onClickNextButton,
  groupedCarriers,
}: any) => {
  const intl = useIntl();
  const { pushErrors } = useBanners();
  const handlingInfo = useSelector(currentGroupHandlingInfo) as any;
  const currentHandlingInfo = handlingInfo && handlingInfo.toJS();
  const hasHome = currentHandlingInfo?.value?.hasHome;
  const countryList = useSelector(i18nSelectors.countryList);

  const [isSuggestedModalOpen, setIsSuggestedModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState<
    AddressManagementValues | undefined
  >(undefined);

  const countryOptions = useGetCountriesOptions(intl.locale);

  const selectedCarrier = find(groupedCarriers.Home, {
    productType: 'HomeDelivery',
  });
  const carrierCompany = getSupportedCarrierCompany(
    get(selectedCarrier, 'carrierName'),
  );

  const verifyAddress = useCallback(
    async (
      customerInformation: AddressManagementFieldValues,
      keepSuggestedAddress: string,
    ) => {
      setIsLoading(true);

      const { countryCode, address, postalCode } = customerInformation;

      if (countryCode !== COUNTRY_CODES.FR) {
        const formattedCustomerInformation = formatAddress(
          customerInformation,
          countryList,
        );

        const response = await CarrierService.getVerifiedAddress({
          address: formattedCustomerInformation,
          carrierCompany,
        });

        setIsLoading(false);
        if (response.failure) {
          pushErrors(response.errors);

          return undefined;
        }

        return adaptVerifiedAddress({
          verifiedAddress: response.value,
          formattedCustomerInformation,
          keepSuggestedAddress,
        });
      }
      const result = await DataGouvService.geoCodeAddressFromDataGouv({
        query: [address, postalCode].join(' '),
      });

      setIsLoading(false);

      return adaptGeoCodeAddressFromDataGouv({
        customerInformation,
        keepSuggestedAddress,
        suggestedAddress: result,
      });
    },
    [carrierCompany, countryList, pushErrors],
  );

  const formContext = useForm<AddressManagementFieldValues>({
    defaultValues: {
      [ADDRESS_MANAGEMENT_FIELDS.ADDRESS]: updatedAddress?.address,
      [ADDRESS_MANAGEMENT_FIELDS.PHONE]: updatedAddress?.phone,
      [ADDRESS_MANAGEMENT_FIELDS.COMPANY_NAME]: updatedAddress?.companyName,
      [ADDRESS_MANAGEMENT_FIELDS.COUNTRY_CODE]: updatedAddress?.countryCode,
      [ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_ADDRESS]:
        updatedAddress?.additionalAddress,
      [ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_INFORMATION]:
        updatedAddress?.additionalInformation,
      [ADDRESS_MANAGEMENT_FIELDS.LAST_NAME]: updatedAddress?.lastName,
      [ADDRESS_MANAGEMENT_FIELDS.FIRST_NAME]: updatedAddress?.firstName,
      [ADDRESS_MANAGEMENT_FIELDS.CIVILITY]: updatedAddress?.civility,
      [ADDRESS_MANAGEMENT_FIELDS.POSTAL_CODE]: updatedAddress?.postalCode,
      [ADDRESS_MANAGEMENT_FIELDS.CITY]: updatedAddress?.city,
      [ADDRESS_MANAGEMENT_FIELDS.KEEP_SUGGESTED_ADDRESS]: suggestedAddress
        ? 'true'
        : 'false',
    },
    resolver: yupResolver(AddressManagementSchema as any),
  });
  const { handleSubmit } = formContext;

  const handleAddressManagementSubmit = handleSubmit(
    async (data: AddressManagementFieldValues) => {
      const { keepSuggestedAddress } = data;
      const verifiedAddress = await verifyAddress(data, keepSuggestedAddress);
      const adaptedCustomerAddress = adaptCustomerAddress(data);

      const addressIsValid = isAddressValid({
        suggestedAddress: verifiedAddress,
        customerAddress: data,
      });

      if (addressIsValid) {
        onClickNextButton(adaptedCustomerAddress);

        return;
      }
      if ((verifiedAddress || suggestedAddress) && !isSuggestedModalOpen) {
        setSuggestedAddress(verifiedAddress);
        setIsSuggestedModalOpen(true);
      }

      if (suggestedAddress && keepSuggestedAddress === 'true') {
        onAddressChange({
          ...updatedAddress,
          address: suggestedAddress?.address,
          city: suggestedAddress?.city,
          postalCode: suggestedAddress?.postalCode,
        });
      }

      if (isSuggestedModalOpen) {
        onClickNextButton(
          keepSuggestedAddress === 'true'
            ? suggestedAddress
            : adaptedCustomerAddress,
        );
        setIsSuggestedModalOpen(false);
      }
    },
  );

  return {
    handleAddressManagementSubmit,
    formContext,
    suggestedAddress,
    isSuggestedModalOpen,
    setIsSuggestedModalOpen,
    isLoading,
    countryOptions,
    hasHome,
  };
};

export default useAddressManagement;
