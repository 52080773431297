export enum DIAGNOSTIC_ACTION_TYPES {
  NEXT_STEP = 'NEXT_STEP',
  PREVIOUS_STEP = 'PREVIOUS_STEP',
  UPDATE_STEP = 'UPDATE_STEP',
  JUMP_TO_STEP = 'JUMP_TO_STEP',
  START = 'START',
}

export enum DIAGNOSTIC_STATE_TYPE {
  ACTION = 'Action',
  QUESTION = 'Question',
  FINAL_STATE = 'FinalState',
}

export enum DIAGNOSTIC_STATUS {
  REPAIRED = 'Repaired',
  NOT_REPAIRED = 'NotRepaired',
}

export interface ClaimGroupDiagnosticTreeValue {
  response?: {
    text: string;
    uniqueName: string;
  };
}
