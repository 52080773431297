import { defineMessages } from 'react-intl';

export default defineMessages({
  phoneNumber: {
    id: 'view.card.client.phone',
    defaultMessage: 'Phone number',
  },
  email: {
    id: 'view.component.block.client.email',
    defaultMessage: 'Email',
  },
});
