import { getAdditionalInformationByClaimGroupQuery } from './getAdditionalInformationByClaimGroupQuery';
import { getAdditionalInformationByClaimQuery } from './getAdditionalInformationByClaimQuery';
import getAdditionalInformationEntitiesSummaries from './getAdditionalInformationEntitiesSummaries';
import getClaimDocuments from './getClaimDocuments';
import getDefaultAddressForPickUpPointSearch from './getDefaultAddressForPickUpPointSearch';
import getFullClaimContextQuery from './getFullClaimContextQuery';
import { setClaimAdditionalInformationWithFilesCommand } from './setClaimAdditionalInformationWithFilesCommand';
import { setClaimGroupSolutionCommand } from './setClaimGroupSolutionCommand';
import uploadSharedFileCommand from './uploadSharedFileCommand';

export const CommonClaimService = {
  getAdditionalInformationEntitiesSummaries,
  getDefaultAddressForPickUpPointSearch,
  getClaimDocuments,
  uploadSharedFileCommand,
  setClaimGroupSolutionCommand,
  getFullClaimContextQuery,
  setClaimAdditionalInformationWithFilesCommand,
  getAdditionalInformationByClaimGroupQuery,
  getAdditionalInformationByClaimQuery,
};
