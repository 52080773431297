import React, { useState } from 'react';
import { $ImageCardStyled, $ProductImage } from './ProductImage.styles';
import { MyAccountIcon } from '../../protons/IconsNewDesign';
import { ICONS_TYPE } from '../../../../types';

interface ProductImageProps {
  title?: string;
  pictureUrl?: string;
}

const ProductImage: React.FunctionComponent<
  React.PropsWithChildren<ProductImageProps>
> = ({ title, pictureUrl, children }) => {
  const [imgErrorUrl, setImgErrorUrl] = useState(false);

  return (
    <$ImageCardStyled $imgErrorUrl={imgErrorUrl} title={title}>
      {pictureUrl && !imgErrorUrl ? (
        <$ProductImage
          src={pictureUrl}
          alt="pictureUrl"
          onError={() => setImgErrorUrl(true)}
        />
      ) : (
        <MyAccountIcon icon={ICONS_TYPE.BOX_ICON} />
      )}
      {children}
    </$ImageCardStyled>
  );
};

ProductImage.displayName = 'ProductImage';

export default ProductImage;
