import React from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { ChevronRightIcon } from '@savgroup-front-common/core/src/protons/icons';
import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';

import OrderProductCard from '../OrderProductInfo/OrderProductCard';

import CancelClaimButton from './CancelClaimButton/CancelClaimButton';
import {
  $CardGroupContainer,
  $ClaimButtonContainer,
  $StyledCardHeader,
} from './ClaimGroupProductCard.styles';
import messages from './messages';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

const FinalizeGroupProductCard = ({
  products,
  cancelClaim,
  finalizeClaimLink,
  fileDetailsRoute,
}) => {
  return (
    <$CardGroupContainer>
      <$StyledCardHeader>
        <$ClaimButtonContainer>
          <CancelClaimButton cancelClaim={cancelClaim} />
          <ButtonLink
            type={BUTTON_TYPES.BUTTON}
            href={finalizeClaimLink}
            icon={<ChevronRightIcon />}
            position={SUPPORTED_ICON_POSITIONS.RIGHT}
            alert
            componentThemeName={SUPPORTED_COMPONENTS.FINALIZE_CLAIM_BUTTON}
            dataTestId={SUPPORTED_COMPONENTS.FINALIZE_CLAIM_BUTTON}
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.continueClaim}
            />
          </ButtonLink>
        </$ClaimButtonContainer>
      </$StyledCardHeader>
      {products.map((product) => (
        <OrderProductCard
          fileDetailsRoute={fileDetailsRoute}
          key={product.ownerProductId}
          {...product}
        />
      ))}
    </$CardGroupContainer>
  );
};

FinalizeGroupProductCard.displayName = 'FinalizeGroupProductCard';

export default FinalizeGroupProductCard;
