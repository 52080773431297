import React, { FC, PropsWithChildren } from 'react';

import { $QuotationLineHead } from './QuotationLineHead.styles';

interface QuotationLineHeadProps {
  isActive?: boolean;
}

const QuotationLineHead: FC<PropsWithChildren<QuotationLineHeadProps>> = ({
  children,
  isActive = false,
}) => {
  return (
    <$QuotationLineHead $isActive={isActive}>{children}</$QuotationLineHead>
  );
};

QuotationLineHead.displayName = 'QuotationLineHead';

export default QuotationLineHead;
