import React from 'react';

interface CarpetIconProps {
  color?: string;
}

/* eslint-disable max-len */
const CarpetIcon: React.FC<CarpetIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.9029 23.7934L24.606 18.9934C24.2523 18.7913 23.7976 18.7913 23.4439 18.9934L14.1471 23.7934C13.7429 23.9955 13.4902 24.4502 13.4902 24.905C13.4902 25.3597 13.7429 25.8144 14.1471 26.0165L23.4439 30.8165C23.646 30.9176 23.8481 30.9681 23.9997 30.9681C24.1513 30.9681 24.4039 30.9176 24.5555 30.8165L33.8523 26.0165C34.2565 25.8144 34.5092 25.3597 34.5092 24.905C34.5092 24.4502 34.3071 23.9955 33.9029 23.7934ZM23.9997 28.2902L17.4313 24.905L23.9997 21.5197L30.5681 24.905L23.9997 28.2902Z"
        fill={color}
      />
      <path
        d="M23.7978 23.3387L21.4736 24.5513C21.322 24.6018 21.2715 24.7534 21.2715 24.905C21.2715 25.0566 21.3725 25.2081 21.4736 25.2587L23.7978 26.4713C23.8483 26.5218 23.9494 26.5218 23.9999 26.5218C24.0504 26.5218 24.1515 26.5218 24.202 26.4713L26.5262 25.2587C26.6778 25.2081 26.7283 25.0566 26.7283 24.905C26.7283 24.7534 26.6273 24.6018 26.5262 24.5513L24.202 23.3387C24.0504 23.2881 23.9494 23.2881 23.7978 23.3387Z"
        fill={color}
      />
      <path
        d="M46.7368 17.9832C47.4442 17.9832 48 17.4274 48 16.72C48 16.0126 47.4442 15.4568 46.7368 15.4568H43.6547V12.5263H46.7368C47.4442 12.5263 48 11.9705 48 11.2632C48 10.5558 47.4442 10 46.7368 10H1.26316C0.555789 10 0 10.5558 0 11.2632C0 11.9705 0.555789 12.5263 1.26316 12.5263H4.34526V15.4568H1.26316C0.555789 15.4568 0 16.0126 0 16.72C0 17.4274 0.555789 17.9832 1.26316 17.9832H4.34526V20.9137H1.26316C0.555789 20.9137 0 21.4695 0 22.1768C0 22.8842 0.555789 23.44 1.26316 23.44H4.34526V26.3705H1.26316C0.555789 26.3705 0 26.9263 0 27.6337C0 28.3411 0.555789 28.8968 1.26316 28.8968H4.34526V31.8274H1.26316C0.555789 31.8274 0 32.3832 0 33.0905C0 33.7979 0.555789 34.3537 1.26316 34.3537H4.34526V37.2842H1.26316C0.555789 37.2842 0 37.84 0 38.5474C0 39.2547 0.555789 39.8105 1.26316 39.8105H46.7368C47.4442 39.8105 48 39.2547 48 38.5474C48 37.84 47.4442 37.2842 46.7368 37.2842H43.6547V34.3537H46.7368C47.4442 34.3537 48 33.7979 48 33.0905C48 32.3832 47.4442 31.8274 46.7368 31.8274H43.6547V28.8968H46.7368C47.4442 28.8968 48 28.3411 48 27.6337C48 26.9263 47.4442 26.3705 46.7368 26.3705H43.6547V23.44H46.7368C47.4442 23.44 48 22.8842 48 22.1768C48 21.4695 47.4442 20.9137 46.7368 20.9137H43.6547V17.9832H46.7368ZM6.87158 37.2337V12.5768H41.1789V37.2842H6.87158V37.2337Z"
        fill={color}
      />
      <path
        d="M38.6527 18.3873C36.6822 18.6905 35.0149 17.0231 35.318 15.0526C35.3685 14.5978 35.0149 14.2441 34.6106 14.2441H13.3896C12.9349 14.2441 12.5812 14.6484 12.6822 15.0526C12.9854 17.0231 11.318 18.6905 9.34748 18.3873C8.89275 18.3368 8.53906 18.6905 8.53906 19.0947V30.6652C8.53906 31.1199 8.94327 31.4736 9.34748 31.3726C11.318 31.0694 12.9854 32.7368 12.6822 34.7073C12.6317 35.162 12.9854 35.5157 13.3896 35.5157H34.6106C35.0654 35.5157 35.4191 35.1115 35.318 34.7073C35.0149 32.7368 36.6822 31.0694 38.6527 31.3726C39.1075 31.4231 39.4612 31.0694 39.4612 30.6652V19.0947C39.4612 18.6905 39.1075 18.3368 38.6527 18.3873ZM36.9349 28.9978C34.9643 29.4526 33.3475 31.0189 32.8927 33.0399H15.057C14.6022 31.0694 13.0359 29.4526 11.0149 28.9978V20.762C12.9854 20.3073 14.6022 18.741 15.057 16.7199H32.8927C33.3475 18.6905 34.9138 20.3073 36.9349 20.762V28.9978Z"
        fill={color}
      />
    </svg>
  );
};

CarpetIcon.displayName = 'CarpetIcon';

export default CarpetIcon;
