import {
  ADDITIONAL_INFORMATION_TYPES,
  AdditionalInformationDto,
} from '@savgroup-front-common/types';

import { buildFileUploadEndpoints } from '../helpers/buildFileUploadEndpoints';

export const adaptFileTypeNeededInformation = ({
  fileId,
}: {
  fileId?: string;
}) => {
  return (
    acc: (AdditionalInformationDto & {
      claimIds?: string[];
      internalId: string;
      fileUploadEndpoints?: {
        uploadEndpoint: string;
        deleteEndpoint: string;
      };
    })[],
    neededInformation: AdditionalInformationDto & {
      claimIds?: string[];
      internalId: string;
    },
  ) => {
    const { type, id, claimIds } = neededInformation;

    const adaptedNeededInformation =
      type === ADDITIONAL_INFORMATION_TYPES.FILE
        ? {
            ...neededInformation,
            fileUploadEndpoints: buildFileUploadEndpoints({
              fileId,
              claimIds,
              id,
            }),
          }
        : neededInformation;

    return [...acc, adaptedNeededInformation];
  };
};
