import React, { FC } from 'react';

import { StepsOrchestrator } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import { useClaimGroupHandling } from './ClaimGroupHandling.hooks';
import {
  HANDLING_STEP_NAMES,
  HANDLING_STEPS_CONFIG,
} from './ClaimGroupHandling.steps';
import { HandlingStepsValue } from './ClaimGroupHandling.types';

interface ClaimGroupHandlingProps {
  claimGroupId: string;
}

const ClaimGroupHandling: FC<ClaimGroupHandlingProps> = ({ claimGroupId }) => {
  const { handleSubmit } = useClaimGroupHandling({ claimGroupId });

  return (
    <StepsOrchestrator<HandlingStepsValue>
      withWizard={false}
      config={HANDLING_STEPS_CONFIG}
      initialStep={HANDLING_STEP_NAMES.SELECT_CARRIER_TYPE}
      onSubmit={handleSubmit}
      initialValues={{
        claimGroupId,
      }}
    />
  );
};

ClaimGroupHandling.displayName = 'ClaimGroupHandling';

export default ClaimGroupHandling;
