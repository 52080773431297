import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const ArrowLeftIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color}>
    <path
      d="M21 12L3 12M3 12L11.5 3.5M3 12L11.5 20.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

ArrowLeftIcon.displayName = 'ArrowLeftIcon';

export default ArrowLeftIcon;
