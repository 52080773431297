import { useContext } from 'react';

import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import { PaymentStatus } from '../CashRegisterPayment.types';

import useGetPaymentUrl from './useGetPaymentUrl';

interface UseCashRegisterPaymentReturnValue {
  paymentStatus?: PaymentStatus;
}
interface UseCashRegisterPaymentArgs {
  paymentUrl: string;
}

const useCashRegisterPayment = ({
  paymentUrl,
}: UseCashRegisterPaymentArgs): UseCashRegisterPaymentReturnValue => {
  const { values } = useContext<StepContextValues>(StepContext);
  const { claimGroup } = values;
  const claimGroupId = claimGroup?.claimGroupId;
  const paymentStatus = useGetPaymentUrl({
    claimGroupId,
    paymentUrl,
  });

  return {
    paymentStatus,
  };
};

export default useCashRegisterPayment;
