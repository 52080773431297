import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

import { SafeFormattedMessageWithoutSpread } from '../../formatters';
import AddressConfirmation from '../addressConfirmation/AddressConfirmation';

import {
  AdditionalAddressCurrentActor,
  AdditionalInformationCurrentActor,
  ClaimAddInfo,
  CurrentActor,
  DoorCodeCurrentActor,
  EmailAddressesCurrentActor,
  FileAddInfo,
  FileDownloader,
  FloorCurrentActor,
  FullAddressCurrentActor,
  FullNameCurrentActor,
  NoteCurrentActor,
  WebsitesCurrentActor,
} from './components';
import { StoreActor } from './components/StoreActor';
import { ContentTemplateContext } from './MDXCustomComponents.context';
import {
  $Documents,
  $How,
  $HowStep,
  $HowStepIcon,
  $HowStepSubtitle,
  $HowStepText,
  $HowStepTitle,
  $HowSubtitle,
  $HowTitle,
  $InfoHandling,
  $InfoHandlingCard,
  $Line,
  ButtonContainer,
  FormattedTimeContainer,
  OpeningHourColumn,
  OpeningHourRow,
  TimeSlotContainer,
} from './MDXCustomComponents.styles';
import messages from './messages';

const SupplierPhone: React.FC = () => (
  <ContentTemplateContext.Consumer>
    {({ rmaSupplier }) => {
      const supplierPhone = get(rmaSupplier, 'phone');

      if (!supplierPhone) {
        return <></>;
      }

      return (
        <ButtonContainer>
          <span>{supplierPhone}</span>
        </ButtonContainer>
      );
    }}
  </ContentTemplateContext.Consumer>
);

SupplierPhone.displayName = 'SupplierPhone';

const SupplierName: React.FC = () => (
  <ContentTemplateContext.Consumer>
    {({ rmaSupplier }) => {
      const supplierName = get(rmaSupplier, 'name');

      if (!supplierName) {
        return <></>;
      }

      return (
        <ButtonContainer>
          <span>{supplierName}</span>
        </ButtonContainer>
      );
    }}
  </ContentTemplateContext.Consumer>
);

SupplierName.displayName = 'SupplierName';

const StoreName: React.FC = () => (
  <ContentTemplateContext.Consumer>
    {({ storeInfo }) => {
      const storeName = get(storeInfo, 'name');

      if (!storeName) {
        return <></>;
      }

      return <span>{storeName}</span>;
    }}
  </ContentTemplateContext.Consumer>
);

StoreName.displayName = 'StoreName';

const StoreAddress: React.FC = () => (
  <ContentTemplateContext.Consumer>
    {({ storeAddress, storeInfo }) => {
      const address = get(storeInfo, ['address', 'address']);
      const postalCode = get(storeInfo, ['address', 'postalCode']);
      const city = get(storeInfo, ['address', 'city']);

      return (
        <>
          {!storeAddress ? (
            <div>
              {address && <div>{address}</div>}
              <div>
                {postalCode && <div>{postalCode}</div>},{' '}
                {city && <div>{city}</div>}
              </div>
            </div>
          ) : (
            <AddressConfirmation
              openingHours={storeAddress.openingHours}
              address={storeAddress.address}
              city={storeAddress.city}
              lastname={storeAddress.lastname}
              firstname={storeAddress.firstname}
              header={storeAddress.header}
              label={storeAddress.label}
              imagePath={storeAddress.imagePath}
              postalCode={storeAddress.postalCode}
              civility={storeAddress.civility}
              price={storeAddress.price}
              priceUnit={storeAddress.priceUnit}
              distanceInMeters={storeAddress.distanceInMeters}
              additionalAddress={storeAddress.additionalAddress}
              showPrice={storeAddress.showPrice}
              transportMethod={storeAddress.transportMethod}
            />
          )}
        </>
      );
    }}
  </ContentTemplateContext.Consumer>
);

StoreAddress.displayName = 'StoreAddress';

const StoreHours: React.FC = () => (
  <ContentTemplateContext.Consumer>
    {({ storeInfo }) => {
      const storeHours = get(storeInfo, 'openingHours');

      return (
        <div>
          {storeHours?.map((storeHour) => {
            if (!isEmpty(storeHour.hours)) {
              return (
                <OpeningHourRow key={storeHour.day}>
                  <SafeFormattedMessageWithoutSpread
                    message={messages[storeHour.day]}
                  />{' '}
                  :
                  <OpeningHourColumn>
                    {storeHour.hours.map((hours) => {
                      return (
                        <div
                          key={storeHour.day}
                        >{` ${hours.start} - ${hours.end}`}</div>
                      );
                    })}
                  </OpeningHourColumn>
                </OpeningHourRow>
              );
            }

            return null;
          })}
        </div>
      );
    }}
  </ContentTemplateContext.Consumer>
);

StoreHours.displayName = 'StoreHours';

const SolutionName: React.FC = () => (
  <ContentTemplateContext.Consumer>
    {({ solutionName = '' }) => solutionName}
  </ContentTemplateContext.Consumer>
);

SolutionName.displayName = 'SolutionName';

const DepositCarrierName: React.FC = () => (
  <ContentTemplateContext.Consumer>
    {({ depositCarrierName }) => depositCarrierName || ''}
  </ContentTemplateContext.Consumer>
);

DepositCarrierName.displayName = 'DepositCarrierName';

const TimeSlotSelected: React.FC = () => (
  <ContentTemplateContext.Consumer>
    {({ homePickupInfos }) => {
      if (!homePickupInfos) {
        return <></>;
      }

      return (
        <TimeSlotContainer>
          {homePickupInfos.map((homePickupInfo) => {
            const startTimeInLocalRecipientTimezone =
              get(homePickupInfo, 'startTimeInLocalRecipientTimezone') ||
              undefined;
            const endTimeInLocalRecipientTimezone =
              get(homePickupInfo, 'endTimeInLocalRecipientTimezone') ||
              undefined;

            return (
              <React.Fragment
                key={`${get(
                  homePickupInfo,
                  'startTimeInLocalRecipientTimezone',
                )}-${get(homePickupInfo, 'endTimeInLocalRecipientTimezone')}`}
              >
                <FormattedDate
                  year="numeric"
                  month="long"
                  day="2-digit"
                  value={startTimeInLocalRecipientTimezone}
                />
                <FormattedTimeContainer>
                  <FormattedTime
                    value={startTimeInLocalRecipientTimezone}
                    hour="2-digit"
                    minute="2-digit"
                    timeZone="utc"
                  />
                  {' - '}
                  <FormattedTime
                    value={endTimeInLocalRecipientTimezone}
                    hour="2-digit"
                    minute="2-digit"
                    timeZone="utc"
                  />
                </FormattedTimeContainer>
              </React.Fragment>
            );
          })}
        </TimeSlotContainer>
      );
    }}
  </ContentTemplateContext.Consumer>
);

TimeSlotSelected.displayName = 'TimeSlotSelected';

interface PropsProps {
  name: string;
}

const Props: React.FC<PropsProps> = ({ name }) => {
  return (
    <ContentTemplateContext.Consumer>
      {(context) => {
        const key = get(context, name) || '';

        return key;
      }}
    </ContentTemplateContext.Consumer>
  );
};

Props.displayName = 'Props';

interface DownloadButtonProps {
  name: string;
  label?: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  name,
  label = null,
}) => {
  return (
    <ContentTemplateContext.Consumer>
      {({
        isLoading,
        fileId,
        getDocumentStatusByFileId,
        getDocumentStatusByFileIdValue,
        claimId,
        partnerTransportDocumentUrl,
        claimGroupId,
      }) => {
        if (
          isLoading === undefined ||
          !fileId ||
          !getDocumentStatusByFileId ||
          !claimId
        ) {
          return <></>;
        }

        return (
          <ButtonContainer>
            <FileDownloader
              isLoading={isLoading}
              documentName={label || ''}
              fileId={fileId}
              getDocumentStatusByFileId={getDocumentStatusByFileId}
              type={name}
              getDocumentStatusByFileIdValue={getDocumentStatusByFileIdValue}
              claimId={claimId}
              claimGroupId={claimGroupId}
              partnerTransportDocumentUrl={partnerTransportDocumentUrl}
            />
          </ButtonContainer>
        );
      }}
    </ContentTemplateContext.Consumer>
  );
};

DownloadButton.displayName = 'DownloadButton';

const FileReference: React.FC = () => {
  return (
    <ContentTemplateContext.Consumer>
      {(context) => context?.fileReference || ''}
    </ContentTemplateContext.Consumer>
  );
};

FileReference.displayName = 'FileReference';

const OrderReference: React.FC = () => {
  return (
    <ContentTemplateContext.Consumer>
      {(context) => context?.orderReference || ''}
    </ContentTemplateContext.Consumer>
  );
};

OrderReference.displayName = 'OrderReference';

const How: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ContentTemplateContext.Consumer>
      {() => <$How>{children}</$How>}
    </ContentTemplateContext.Consumer>
  );
};

How.displayName = 'How';

const HowTitle: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ContentTemplateContext.Consumer>
      {() => <$HowTitle>{children}</$HowTitle>}
    </ContentTemplateContext.Consumer>
  );
};

HowTitle.displayName = 'HowTitle';

const HowSubtitle: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ContentTemplateContext.Consumer>
      {() => <$HowSubtitle>{children}</$HowSubtitle>}
    </ContentTemplateContext.Consumer>
  );
};

HowSubtitle.displayName = 'HowSubtitle';

const HowStep: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ContentTemplateContext.Consumer>
      {() => <$HowStep>{children}</$HowStep>}
    </ContentTemplateContext.Consumer>
  );
};

HowStep.displayName = 'HowStep';

interface HowStepTextProps {
  number: number;
}

const HowStepText: React.FC<React.PropsWithChildren<HowStepTextProps>> = ({
  children,
  number,
}) => {
  return (
    <ContentTemplateContext.Consumer>
      {() => <$HowStepText $number={number}>{children}</$HowStepText>}
    </ContentTemplateContext.Consumer>
  );
};

HowStepText.displayName = 'HowStepText';

const HowStepTitle: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ContentTemplateContext.Consumer>
      {() => <$HowStepTitle>{children}</$HowStepTitle>}
    </ContentTemplateContext.Consumer>
  );
};

HowStepTitle.displayName = 'HowStepTitle';

const HowStepSubtitle: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ContentTemplateContext.Consumer>
      {() => <$HowStepSubtitle>{children}</$HowStepSubtitle>}
    </ContentTemplateContext.Consumer>
  );
};

HowStepSubtitle.displayName = 'HowStepSubtitle';

const Line: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ContentTemplateContext.Consumer>
      {() => <$Line>{children}</$Line>}
    </ContentTemplateContext.Consumer>
  );
};

Line.displayName = 'Line';

const Documents: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ContentTemplateContext.Consumer>
      {() => <$Documents>{children}</$Documents>}
    </ContentTemplateContext.Consumer>
  );
};

Documents.displayName = 'Documents';

const InfoHandling: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ContentTemplateContext.Consumer>
      {() => <$InfoHandling>{children}</$InfoHandling>}
    </ContentTemplateContext.Consumer>
  );
};

InfoHandling.displayName = 'InfoHandling';

const InfoHandlingCard: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ContentTemplateContext.Consumer>
      {() => {
        return <$InfoHandlingCard>{children}</$InfoHandlingCard>;
      }}
    </ContentTemplateContext.Consumer>
  );
};

InfoHandlingCard.displayName = 'InfoHandlingCard';

interface InfoHandlingCardIconProps {
  name: string;
}

const InfoHandlingCardIcon: React.FC<InfoHandlingCardIconProps> = ({
  name,
}) => {
  return (
    <ContentTemplateContext.Consumer>
      {(context) => {
        return context.picturesGrey && context.picturesGrey(name);
      }}
    </ContentTemplateContext.Consumer>
  );
};

InfoHandlingCardIcon.displayName = 'InfoHandlingCardIcon';

interface IconProps {
  name: string;
}

const Icon: React.FC<IconProps> = ({ name }) => {
  return (
    <ContentTemplateContext.Consumer>
      {(context) => {
        return (
          <$HowStepIcon>
            {context.pictures && context.pictures(name)}
          </$HowStepIcon>
        );
      }}
    </ContentTemplateContext.Consumer>
  );
};

Icon.displayName = 'Icon';

export const customComponents = {
  Props,
  DownloadButton,
  StoreAddress,
  SolutionName,
  DepositCarrierName,
  TimeSlotSelected,
  SupplierPhone,
  StoreName,
  StoreHours,
  SupplierName,
  FileReference,
  OrderReference,
  CurrentActor,
  AdditionalInformationCurrentActor,
  AdditionalAddressCurrentActor,
  EmailAddressesCurrentActor,
  FloorCurrentActor,
  FullAddressCurrentActor,
  DoorCodeCurrentActor,
  NoteCurrentActor,
  WebsitesCurrentActor,
  FullNameCurrentActor,
  FileAddInfo,
  ClaimAddInfo,
  StoreActor,
  How,
  HowTitle,
  HowSubtitle,
  HowStep,
  HowStepText,
  HowStepTitle,
  HowStepSubtitle,
  InfoHandling,
  InfoHandlingCard,
  InfoHandlingCardIcon,
  Line,
  Documents,
  Icon,
};
