import md5 from 'md5';
import React, { FC, Fragment } from 'react';

import {
  SparePartQuotationLine,
  SparePartSearchSummary,
} from '@savgroup-front-common/types';

import { CardSeparator } from '../../../../../atoms/Cards';
import { SafeFormattedMessageWithoutSpread } from '../../../../../formatters';
import { formatReverseMoneyToString } from '../../../../../formatters/intl';
import { getRecipientTypeMessage } from '../../../../../helpers/i18n';
import messages from '../../messages';
import {
  QuotationPendingCustomerValidationForm,
  QuotationPendingCustomerValidationNewModel,
} from '../../QuotationPendingCustomerValidation.types';
import {
  SparePartQuotationPendingCustomerValidationLine,
  SparePartQuotationPendingCustomerValidationLineSkeleton,
} from '../../SparePartQuotationPendingCustomerValidationLine';

import {
  $AmountCell,
  $QuotationLine,
  $QuotationLineHead,
} from './QuotationPendingCustomerValidationContentMobileView.styles';

interface QuotationPendingCustomerValidationContentMobileViewProps {
  adaptedQuotation: QuotationPendingCustomerValidationNewModel;
  requiredForRepaidSpareParts?: SparePartQuotationLine[];
  optionalSpareParts?: SparePartQuotationLine[];
  onSelectSparePartQuotationLineConditionChange: ({
    values,
    alternativeSpareParts,
  }: {
    values: QuotationPendingCustomerValidationForm;
    alternativeSpareParts: Record<
      string,
      Record<string, SparePartSearchSummary>
    >;
  }) => Promise<{ failure: boolean }>;
}

const QuotationPendingCustomerValidationContentMobileView: FC<
  QuotationPendingCustomerValidationContentMobileViewProps
> = ({
  adaptedQuotation,
  optionalSpareParts,
  onSelectSparePartQuotationLineConditionChange,
  requiredForRepaidSpareParts,
}) => {
  return (
    <div>
      <$QuotationLineHead $isActive>
        <SafeFormattedMessageWithoutSpread message={messages.service} />
      </$QuotationLineHead>
      {(adaptedQuotation.administrativeFees || []).map((fee) => {
        return (
          <Fragment key={fee.preTaxUnitPrice.amount.toString()}>
            <$QuotationLine $isActive>
              <div>
                <div>
                  <div>{fee.description}</div>
                  {fee.recipient && (
                    <div>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.recipient}
                      />
                      {': '}
                      <SafeFormattedMessageWithoutSpread
                        message={getRecipientTypeMessage(
                          fee.recipient?.recipientType,
                        )}
                      />
                    </div>
                  )}
                </div>
                <$AmountCell>
                  {formatReverseMoneyToString(fee.amountIncludedTax)}
                </$AmountCell>
              </div>
            </$QuotationLine>
            <CardSeparator withoutPaddingTop />
          </Fragment>
        );
      })}
      {(adaptedQuotation.services || []).map((service) => {
        return (
          <Fragment key={service.id}>
            <$QuotationLine $isActive>
              <div>
                <div>
                  <div>{service.description}</div>
                  {service.recipient && (
                    <div>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.recipient}
                      />
                      {': '}
                      <SafeFormattedMessageWithoutSpread
                        message={getRecipientTypeMessage(
                          service.recipient?.recipientType,
                        )}
                      />
                    </div>
                  )}
                </div>
                <$AmountCell>
                  {formatReverseMoneyToString(service.amountIncludedTax)}
                </$AmountCell>
              </div>
            </$QuotationLine>
            <CardSeparator withoutPaddingTop />
          </Fragment>
        );
      })}
      {(adaptedQuotation.unknownServices || []).map((service) => {
        return (
          <Fragment
            key={md5(formatReverseMoneyToString(service.amountIncludedTax))}
          >
            <$QuotationLine $isActive>
              <div>
                <div>
                  <div>{service.description}</div>
                  {service.recipient && (
                    <div>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.recipient}
                      />
                      {': '}
                      <SafeFormattedMessageWithoutSpread
                        message={getRecipientTypeMessage(
                          service.recipient?.recipientType,
                        )}
                      />
                    </div>
                  )}
                </div>

                <$AmountCell>
                  {formatReverseMoneyToString(service.amountIncludedTax)}
                </$AmountCell>
              </div>
            </$QuotationLine>
            <CardSeparator withoutPaddingTop />
          </Fragment>
        );
      })}
      {(adaptedQuotation.unknownSpareParts || []).map((sparePart) => {
        return (
          <Fragment
            key={md5(formatReverseMoneyToString(sparePart.amountIncludedTax))}
          >
            <$QuotationLine $isActive>
              <div>
                {sparePart.recipient && (
                  <div>
                    <div>{sparePart.description}</div>
                    <div>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.recipient}
                      />
                      {': '}
                      <SafeFormattedMessageWithoutSpread
                        message={getRecipientTypeMessage(
                          sparePart.recipient?.recipientType,
                        )}
                      />
                    </div>
                  </div>
                )}

                <$AmountCell>
                  {formatReverseMoneyToString(sparePart.amountIncludedTax)}
                </$AmountCell>
              </div>
            </$QuotationLine>
            <CardSeparator withoutPaddingTop />
          </Fragment>
        );
      })}
      {(adaptedQuotation.discounts || []).map((discount) => {
        return (
          <Fragment
            key={md5(formatReverseMoneyToString(discount.amountIncludedTax))}
          >
            <$QuotationLine $isActive>
              <div>
                <div>
                  <div>{discount.description}</div>
                  {discount.recipient && (
                    <div>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.recipient}
                      />
                      {': '}
                      <SafeFormattedMessageWithoutSpread
                        message={getRecipientTypeMessage(
                          discount.recipient?.recipientType,
                        )}
                      />
                    </div>
                  )}
                </div>

                <$AmountCell>
                  {formatReverseMoneyToString(discount.amountIncludedTax)}
                </$AmountCell>
              </div>
            </$QuotationLine>
            <CardSeparator withoutPaddingTop />
          </Fragment>
        );
      })}

      {!requiredForRepaidSpareParts && (
        <SparePartQuotationPendingCustomerValidationLineSkeleton />
      )}

      {!optionalSpareParts && (
        <SparePartQuotationPendingCustomerValidationLineSkeleton />
      )}

      {requiredForRepaidSpareParts && optionalSpareParts && (
        <>
          {requiredForRepaidSpareParts.length > 0 && (
            <>
              <$QuotationLineHead $isActive>
                <div>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.requiredSpareParts}
                  />
                </div>
              </$QuotationLineHead>

              {requiredForRepaidSpareParts.map(
                (sparePartQuotationLine, index) => {
                  return (
                    <SparePartQuotationPendingCustomerValidationLine
                      key={sparePartQuotationLine.id}
                      onSelectSparePartQuotationLineChange={
                        onSelectSparePartQuotationLineConditionChange
                      }
                      sparePartQuotationLine={sparePartQuotationLine}
                      index={index}
                    />
                  );
                },
              )}
            </>
          )}

          {optionalSpareParts.length > 0 && (
            <>
              <$QuotationLineHead>
                <div>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.optionalSpareParts}
                  />
                </div>
              </$QuotationLineHead>
              {optionalSpareParts.map((sparePartQuotationLine, index) => {
                return (
                  <SparePartQuotationPendingCustomerValidationLine
                    key={sparePartQuotationLine.id}
                    onSelectSparePartQuotationLineChange={
                      onSelectSparePartQuotationLineConditionChange
                    }
                    sparePartQuotationLine={sparePartQuotationLine}
                    index={index}
                    preDataTestId="optionalSpareParts"
                  />
                );
              })}
            </>
          )}
        </>
      )}
    </div>
  );
};

QuotationPendingCustomerValidationContentMobileView.displayName =
  'QuotationPendingCustomerValidationContentMobileView';

export default QuotationPendingCustomerValidationContentMobileView;
