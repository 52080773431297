function setAuthority() {
  return this;
}

const AuthConfiguration = {
  authority: 'http://localhost:5000',
  clientId: process.env.REACT_APP_CLIENTID
    ? process.env.REACT_APP_CLIENTID
    : 'local-revers.io',
  scope: 'openid email api',
  setAuthority,
};

export default AuthConfiguration;
