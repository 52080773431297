import { defineMessages } from 'react-intl';

export default defineMessages({
  returnTheProducts: {
    id: 'components.chooseProducts.returnTheProducts',
    defaultMessage:
      'Return the {selectedProducts, plural, =0 {selected products} one {selected product} other {# selected products}}',
  },
  description: {
    id: 'components.chooseProducts.description',
    defaultMessage:
      'To ease up your experience you can return the products above bundled',
  },
});
