import { createSelector } from 'reselect';

import { CommonAppState } from '@savgroup-front-common/core/src/domains/CommonAppState';

import { PaymentsState } from './types';

export interface State extends CommonAppState {
  payments: PaymentsState;
}

const paymentsState = (state: State) => state.payments;

export const openedInvoices = createSelector([paymentsState], (state) =>
  state.get('openedInvoices'),
);
