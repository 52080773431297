import { yupResolver } from '@hookform/resolvers/yup';
import get from 'lodash/get';
import head from 'lodash/head';
import last from 'lodash/last';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';

import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';
import { fullProductsValue } from 'myaccount/domains/owner/selectors';
import { useMyAccountTypedSelector } from 'myaccount/hooks';

import reasonSolutionSchema from '../ReasonSolutionSelection.schema';

import useAdditionalInformationSave from './useAdditionalInformationSave';
import useReasonSolutionSelectionStoreActions from './useReasonSolutionSelectionStoreActions';

const getInternalId = ({ claimId, additionalInformationId, products }) => {
  const product = products.find((product) =>
    product.claimIds.includes(claimId),
  );

  const currentReasonId = product?.currents?.currentReason;

  const currentReason = product.reasons.find(
    (reason) => reason.id === currentReasonId,
  );

  if (!currentReason) {
    return null;
  }

  const neededAdditionalInformation = currentReason.neededInformation;

  const concernedAddInfo = neededAdditionalInformation.find(
    (neededAdditionalInformationItem) =>
      neededAdditionalInformationItem.id === additionalInformationId &&
      neededAdditionalInformationItem.claimIds.includes(claimId),
  );

  return concernedAddInfo?.internalId;
};

const buildDefaultValues = ({
  claims,
  products,
  claimAttachmentsByProductId,
}) => {
  const firstProduct = head(products);
  const solutions = firstProduct?.solutions || [];

  const defaultSolutionId =
    solutions.length === 1
      ? head(solutions)?.id
      : firstProduct?.currents?.currentSolution;

  const defaultValues = products.reduce(
    (acc, product) => {
      const reasons = product?.reasons;
      const currentReasonId = product?.currents?.currentReason;

      const currentReason = reasons.find(
        (reason) => reason.id === currentReasonId,
      );

      return {
        ...acc,
        form: {
          ...acc.form,
          [product.productId]: {
            reason: currentReasonId
              ? {
                  value: currentReasonId,
                  label: currentReason?.name,
                  data: currentReason,
                }
              : null,
            reasonComment: product?.currents?.currentComment,
            reasonDocument: (
              claimAttachmentsByProductId[product.productId] || []
            ).map(({ value }) => value),
          },
        },
      };
    },
    {
      solutionId: defaultSolutionId,
      form: {},
    },
  );

  const defaultAdditionalInformationValues = products.reduce((acc, product) => {
    const reasons = product?.reasons;
    const productId = product?.productId;

    const fullProduct = useMyAccountTypedSelector((state) =>
      fullProductsValue(state, productId),
    );
    const imei = last((fullProduct?.value?.imei || []).filter((x) => x));
    const serialNumber = fullProduct?.value?.serialNumber;
    const claimId = claims[0]?.claimId;

    const neededAdditionalInformation = reasons?.reduce((acc, reason) => {
      return {
        ...acc,
        ...reason.neededInformation?.reduce((acc, info) => {
          const id = info.uniquenessByProduct
            ? `${info.id}_${claimId}`
            : `${info.id}`;

          if (info.type === ADDITIONAL_INFORMATION_TYPES.IMEI && imei) {
            return {
              ...acc,
              [id]: imei,
            };
          }
          if (
            info.type === ADDITIONAL_INFORMATION_TYPES.SERIAL_NUMBER &&
            serialNumber
          ) {
            return {
              ...acc,
              [id]: serialNumber,
            };
          }

          return acc;
        }, {}),
      };
    }, {});

    return {
      ...acc,
      [product.sellerProductId]: neededAdditionalInformation,
    };
  }, {});

  const defaultClaimAdditionalInformationValues = claims.reduce(
    (acc, claim) => {
      const { claimId, additionalClaimInformation } = claim;

      const concernedProduct = products.find((product) =>
        product.claimIds.includes(claimId),
      );

      const claimDefaultAddInfoValues = additionalClaimInformation.reduce(
        (claimDefaultAddInfoValuesAcc, additionalClaimInformationItem) => {
          const {
            additionalInformationId,
            additionalInformationEnumValue,
            additionalInformationStringValue,
            additionalInformationFileValue,
          } = additionalClaimInformationItem;

          const internalId = getInternalId({
            claimId,
            additionalInformationId,
            products,
          });

          if (!internalId) {
            return acc;
          }

          // TODO: Need to pre-fill with a blob
          if (additionalInformationFileValue) {
            return {
              ...claimDefaultAddInfoValuesAcc,
              [internalId]: {
                progress: 100,
                additionalInformationStringValue,
                value: {
                  additionalInformationStringValue,
                  name: additionalInformationFileValue.fileName,
                  ...additionalInformationFileValue,
                },
              },
            };
          }

          if (additionalInformationEnumValue) {
            return {
              ...claimDefaultAddInfoValuesAcc,
              [internalId]: {
                value: additionalInformationEnumValue,
              },
            };
          }

          return {
            ...claimDefaultAddInfoValuesAcc,
            [internalId]: additionalInformationStringValue,
          };
        },
        {},
      );

      return {
        ...acc,
        [concernedProduct.sellerProductId]: {
          ...get(acc, concernedProduct.sellerProductId, {}),
          ...claimDefaultAddInfoValues,
        },
      };
    },
    {},
  );

  return {
    ...defaultValues,
    additionalInformation: {
      ...defaultClaimAdditionalInformationValues,
      ...defaultAdditionalInformationValues,
    },
  };
};

const useReasonSolutionSelection = ({
  claims,
  activeIndex,
  products,
  onActiveProductChange,
  solutions,
  claimAttachmentsByProductId,
}) => {
  const { dispatchGoForwardOnClaimGroupEdition } =
    useReasonSolutionSelectionStoreActions();

  const activeProduct = products.find(
    (product, index) => index === activeIndex,
  );

  const [formValues, setFormValues] = useState({});

  const formContext = useForm({
    defaultValues: buildDefaultValues({
      claims,
      products,
      claimAttachmentsByProductId,
    }),
    shouldUnregister: true,

    resolver: yupResolver(
      reasonSolutionSchema({
        products,
        formValues,
      }),
    ),
  });

  const {
    watch,
    formState: { errors },
    setValue,
  } = formContext;
  const formWatchedValues = watch('form');

  useDeepCompareEffect(() => {
    if (solutions.length === 1) {
      const [solution] = solutions;

      setValue('solutionId', solution?.id);
    }
  }, [setValue, solutions]);

  useDeepCompareEffect(() => {
    setFormValues(formWatchedValues);
  }, [formWatchedValues]);

  useDeepCompareEffect(() => {
    const additionalInformationErrors = errors.additionalInformation || {};
    const formErrors = errors.form || {};

    const internalId = head(Object.keys(additionalInformationErrors));
    const ownerProductId = head(Object.keys(formErrors));

    const firstProductWithErrorIndex = products.findIndex((product) => {
      if (ownerProductId) {
        return product.productId === ownerProductId;
      }

      if (internalId) {
        const reasons = product?.reasons;
        const currentReasonId = product?.currents?.currentReason;

        const currentReason = reasons.find(
          (reason) => reason.id === currentReasonId,
        );

        return currentReason.neededInformation.some(
          (neededInformationItem) =>
            neededInformationItem.internalId === internalId,
        );
      }

      return false;
    });

    if (firstProductWithErrorIndex >= 0) {
      onActiveProductChange(firstProductWithErrorIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, onActiveProductChange]);

  const saveAdditionalInformation = useAdditionalInformationSave({
    formContext,
    products,
    reloadSolutions: false,
  });

  const handleReasonSolutionSubmit = useCallback(
    async (data) => {
      await saveAdditionalInformation();

      const solutionTypeId = data.solutionId;
      const solution =
        solutions.find((solution) => solution.id === data.solutionId) || {};
      const { priceWithoutTax, currency } = solution;

      await Promise.all(
        claims.map((claim) =>
          ClaimService.setClaimSolutionCommand({
            claimId: claim.claimId,
            solutionTypeId,
            solutionPrice: priceWithoutTax
              ? {
                  amount: priceWithoutTax.toString(),
                  currency,
                }
              : undefined,
          }),
        ),
      );

      dispatchGoForwardOnClaimGroupEdition();
    },
    [
      claims,
      dispatchGoForwardOnClaimGroupEdition,
      saveAdditionalInformation,
      solutions,
    ],
  );

  return {
    formContext,
    activeProduct,
    handleReasonSolutionSubmit,
  };
};

export default useReasonSolutionSelection;
