import { matches } from 'lodash';
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';
import * as ClaimActionTypes from '@savgroup-front-common/core/src/domains/claims/actionTypes';
import { selectUserId } from '@savgroup-front-common/core/src/domains/selectors';

import { CLAIM_CANCELLED } from '../Claim/actionTypes';
import { myReturnActionCreators } from '../MyReturn';
import {
  CLOSED_FILES_LOADED,
  OPEN_FILES_LOADED,
} from '../MyReturn/actionTypes';

import { loadOrders, ordersLoaded, ordersLoading } from './actionCreators';
import {
  LOAD_ORDERS,
  LOAD_ORDERS_DATA,
  MYORDERS_LOADED,
  MYORDERS_ON_ERRORS,
} from './actionTypes';
import {
  ordersSelectorItems,
  selectLoadOwnerOrdersWasLoaded,
} from './selectors';

export function* loadOrdersDataWorker({ payload } = { payload: {} }) {
  const forceRefresh = payload?.forceRefresh || false;
  const ownerId = yield select(selectUserId);

  yield all([
    put(LOAD_ORDERS_DATA.start({})),
    put(loadOrders({ forceRefresh })),
    race([
      take(MYORDERS_LOADED.BASE),
      take(MYORDERS_ON_ERRORS),
      take(LOAD_ORDERS.END),
    ]),
    put(myReturnActionCreators.loadOpenFiles({ ownerId })),
    race([
      take(
        matches({
          type: OPEN_FILES_LOADED.SUCCEEDED,
          meta: { indexer: ownerId },
        }),
      ),
      take(
        matches({
          type: OPEN_FILES_LOADED.END,
          meta: { indexer: ownerId },
        }),
      ),
    ]),
    put(myReturnActionCreators.loadClosedFiles({ ownerId })),
    race([
      take(
        matches({
          type: CLOSED_FILES_LOADED.SUCCEEDED,
          meta: { indexer: ownerId },
        }),
      ),
      take(
        matches({
          type: CLOSED_FILES_LOADED.END,
          meta: { indexer: ownerId },
        }),
      ),
    ]),
  ]);

  yield put(LOAD_ORDERS_DATA.success({}));
  yield put(LOAD_ORDERS_DATA.end({}));
}
export function* loadOrdersDataWatcher() {
  yield takeEvery([LOAD_ORDERS_DATA.BASE], loadOrdersDataWorker);
}

export function* loadOrdersForceRefreshWorker() {
  const ownerId = yield select(selectUserId);

  yield all([
    put(LOAD_ORDERS_DATA.start({})),
    put(loadOrders({ forceRefresh: true })),
    race([
      take(MYORDERS_LOADED.BASE),
      take(MYORDERS_ON_ERRORS),
      take(LOAD_ORDERS.END),
    ]),
    put(myReturnActionCreators.loadClosedFiles({ ownerId })),
    put(myReturnActionCreators.loadOpenFiles({ ownerId })),
  ]);

  yield put(LOAD_ORDERS_DATA.success({}));
  yield put(LOAD_ORDERS_DATA.end({}));
}
export function* loadOrdersForceRefreshWatcher() {
  yield takeEvery(
    [CLAIM_CANCELLED, ClaimActionTypes.DELETE_CLAIM_GROUP_BY_ID.END],
    loadOrdersForceRefreshWorker,
  );
}

export function* loadOrdersWorker({ payload }) {
  const forceRefresh = payload?.forceRefresh || false;
  const ownerId = yield select(selectUserId);
  const meta = { indexer: ownerId };

  const wasLoaded = yield select(selectLoadOwnerOrdersWasLoaded, { ownerId });

  if (!forceRefresh && wasLoaded) {
    const items = yield select(ordersSelectorItems);

    yield put(LOAD_ORDERS.end(meta));
    yield put(ordersLoaded({ items }));

    return;
  }

  yield put(ordersLoading());

  const response = yield call(
    callAndGetResponse,
    LOAD_ORDERS,
    `${APIConfiguration.owner}owners/${ownerId}/orders`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    meta,
  );

  yield put(ordersLoaded({ items: response?.payload?.value || [] }));

  yield put(LOAD_ORDERS.end(meta));
}
export function* loadOrdersWatcher() {
  yield takeEvery(LOAD_ORDERS.BASE, loadOrdersWorker);
}

export default function* ordersSaga() {
  try {
    yield all([
      loadOrdersDataWatcher(),
      loadOrdersWatcher(),
      loadOrdersForceRefreshWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
