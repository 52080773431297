import { createAction } from '@reduxjs/toolkit';

import {
  DELETE_ATTACHMENT_FROM_FILE,
  GET_ALL_DOCUMENTS_STATUS_BY_FILE_ID,
  GET_DOCUMENTS_BY_CLAIM_ID,
  GET_DOCUMENTS_STATUS_BY_FILE_ID,
  LOAD_DOCUMENT_BY_URL,
  LOAD_FILES_ATTACHMENT_LIST,
  RESET_UPLOAD_STATE,
  SET_FILE_ATTACHMENT_INFO,
  UPLOAD_FILE_ATTACHMENT_DATA,
} from './actionTypes';

export const loadDocumentByUrl = createAction<{
  url: string;
  fileName: string;
}>(LOAD_DOCUMENT_BY_URL.BASE);
export const loadFilesAttachmentList = createAction<{
  fileId: string;
  type: string;
}>(LOAD_FILES_ATTACHMENT_LIST.BASE);

export const setFileAttachmentInfo = createAction(
  SET_FILE_ATTACHMENT_INFO.BASE,
);
export const uploadFileAttachment = createAction<{
  file: Blob;
  fileId: string;
  service: string;
}>(UPLOAD_FILE_ATTACHMENT_DATA.BASE);
export const deleteAttachmentFromFile = createAction<{
  fileId: string;
  fileAttachmentId: string;
  internalId: string;
}>(DELETE_ATTACHMENT_FROM_FILE.BASE);
export const resetUploadState = createAction(RESET_UPLOAD_STATE);

export const getDocumentsStatusByFileId = createAction<{
  fileId: string;
  type: string;
}>(GET_DOCUMENTS_STATUS_BY_FILE_ID.BASE);
export const getAllDocumentsStatusByFileId = createAction(
  GET_ALL_DOCUMENTS_STATUS_BY_FILE_ID.BASE,
);

export const getDocumentsByClaimId = createAction<{ claimId: string }>(
  GET_DOCUMENTS_BY_CLAIM_ID.BASE,
);
