import React from 'react';

import Icon from './Icon';

interface BaselineEcoIconProps {
  color?: string;
  size?: string;
}

const BaselineEcoIcon: React.FC<BaselineEcoIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M2.03332 3.3665C1.16051 4.23946 0.669073 5.42264 0.666575 6.65708C0.664076 7.89153 1.15072 9.07669 2.01999 9.95317C2.99999 7.6865 4.74665 5.79317 6.92665 4.6665C5.04503 6.25862 3.77487 8.45489 3.33332 10.8798C5.06665 11.6998 7.19999 11.3998 8.63332 9.9665C10.9533 7.6465 11.3333 0.666504 11.3333 0.666504C11.3333 0.666504 4.35332 1.0465 2.03332 3.3665Z"
        fill={color}
      />
    </Icon>
  );
};

BaselineEcoIcon.displayName = 'BaselineEcoIcon';

export default BaselineEcoIcon;
