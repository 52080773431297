import React, { FC, Suspense } from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { Invoice } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import { CashRegisterPayment } from './CashRegisterPayment';
import { usePaymentCard } from './hooks';
import messages from './messages';
import { $Heading, $HeadingIcon } from './PaymentCard.styles';
import { PaymentStripe } from './PaymentStripe';

interface PaymentCardProps {
  invoice: Invoice;
  sellerId: string;
  claimGroupId: string;
  fileId: string;
  onAllSubmit?: () => void;
  isFormCompleted?: boolean;
}

const PaymentCard: FC<PaymentCardProps> = ({
  invoice,
  sellerId,
  claimGroupId,
  fileId,
  onAllSubmit = () => undefined,
  isFormCompleted = true,
}) => {
  const { paymentUrl } = usePaymentCard({ sellerId });

  return (
    <Suspense fallback={<BaseLoader />}>
      <$Heading>
        <$HeadingIcon>
          <MyAccountIcon icon={ICONS_TYPE.CART_ICON} />
        </$HeadingIcon>
        <SafeFormattedMessageWithoutSpread message={messages.paymentTitle} />
      </$Heading>
      {!paymentUrl && invoice && (
        <PaymentStripe
          sellerId={sellerId}
          onAllSubmit={onAllSubmit}
          isFormCompleted={isFormCompleted}
          invoice={invoice}
        />
      )}
      {paymentUrl && (
        <CashRegisterPayment
          fileId={fileId}
          claimGroupId={claimGroupId}
          paymentUrl={paymentUrl}
          isFormCompleted={isFormCompleted}
          invoice={invoice}
        />
      )}
    </Suspense>
  );
};

PaymentCard.displayName = 'PaymentCard';

export default PaymentCard;
