import max from 'lodash/max';
import min from 'lodash/min';
import slice from 'lodash/slice';
import React, { FC, useState } from 'react';
import { FormattedDate } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useDeepCompareEffect, useMedia } from 'react-use';

import { BUTTON_TYPES, media } from '@savgroup-front-common/constants';

import { BaseLoader } from '../../BaseLoader';
import { DetailedSchedule, Interval } from '../AppointmentCard.types';

import { byStartLocalTime } from './TimeSlotDisplay.helpers';
import {
  $AppointmentCalendarContainer,
  $AppointmentCalendarScreen,
  $Button,
  $CalendarDayBlock,
  $CalendarDayDate,
  $CalendarDayName,
  $LeftArrowButton,
  $RightArrowButton,
  $StyledCol,
  $TimeSlotSelectorContainer,
  $UnderlinedRow,
} from './TimeSlotDisplay.styles';
import TimeSlotSelector from './TimeSlotSelector';

interface TimeSlotDisplayProps {
  detailedSchedule: DetailedSchedule[];
  selectedInterval?: Interval;
  onIntervalSelect: (interval: Interval) => void;
  isActive?: boolean;
  timeZone?: string;
  isLoading?: boolean;
  hasErrors?: boolean;
  hasButtonPadding: boolean;
}

interface State {
  appointmentSchedule: DetailedSchedule[];
  daysMenuStart: number;
}

const TimeSlotDisplay: FC<TimeSlotDisplayProps> = ({
  detailedSchedule,
  selectedInterval,
  onIntervalSelect,
  isActive = true,
  timeZone,
  isLoading,
  hasErrors,
  hasButtonPadding,
}) => {
  const [state, setState] = useState<State>({
    daysMenuStart: 0,
    appointmentSchedule: [],
  });
  const { appointmentSchedule, daysMenuStart } = state;
  const isMobileView = useMedia(media.maxWidth.xs);

  const numberOfDaysByPage = isMobileView ? 2 : 3;

  useDeepCompareEffect(() => {
    const start =
      min([daysMenuStart, detailedSchedule.length - numberOfDaysByPage]) || 0;

    setState((prev: any) => ({
      ...prev,
      appointmentSchedule: slice(
        detailedSchedule,
        start,
        start + numberOfDaysByPage,
      ),
    }));
  }, [detailedSchedule, numberOfDaysByPage, daysMenuStart]);
  const onDayChange = (diff: any) => {
    const start = min([
      max([0, daysMenuStart + diff]),
      detailedSchedule.length - numberOfDaysByPage,
    ]);

    setState({
      daysMenuStart: start,
      appointmentSchedule: slice(
        detailedSchedule,
        start,
        start + numberOfDaysByPage,
      ),
    });
  };

  if (hasErrors) {
    return <></>;
  }

  return (
    <$AppointmentCalendarContainer $hasButtonPadding={hasButtonPadding}>
      {isLoading && (
        <div>
          <BaseLoader />
        </div>
      )}

      <$Button
        type={BUTTON_TYPES.BUTTON}
        naked
        secondary
        onClick={() => {
          onDayChange(-1);
        }}
        icon={<$LeftArrowButton />}
        disabled={daysMenuStart === 0}
      />
      <$AppointmentCalendarScreen>
        <$UnderlinedRow center="xs">
          <Col xs={12} sm={10}>
            <Row>
              {appointmentSchedule.map(({ date }) => (
                <$StyledCol xs={6} sm={4} key={date}>
                  <$CalendarDayBlock>
                    <$CalendarDayName>
                      <FormattedDate value={date} weekday="long" />
                    </$CalendarDayName>
                    <$CalendarDayDate>
                      <FormattedDate
                        value={date}
                        day="numeric"
                        month="numeric"
                      />
                    </$CalendarDayDate>
                  </$CalendarDayBlock>
                </$StyledCol>
              ))}
            </Row>
          </Col>
        </$UnderlinedRow>

        <Row center="xs">
          <Col xs={12}>
            <Row>
              {appointmentSchedule.map(({ date, intervals = [] }) => {
                const allDaySchedule = intervals.find(
                  (interval) => interval.allDaySchedule,
                );

                if (allDaySchedule) {
                  return (
                    <$StyledCol xs={6} sm={4} key={date}>
                      <TimeSlotSelector
                        isActive={isActive}
                        interval={allDaySchedule}
                        selectedInterval={selectedInterval}
                        onIntervalSelect={onIntervalSelect}
                        timeZone={timeZone}
                      />
                    </$StyledCol>
                  );
                }

                return (
                  <$StyledCol xs={6} sm={4} key={date}>
                    <$TimeSlotSelectorContainer>
                      {intervals.sort(byStartLocalTime).map((interval) => {
                        return (
                          <TimeSlotSelector
                            key={interval.internalId}
                            isActive={isActive}
                            interval={interval}
                            selectedInterval={selectedInterval}
                            onIntervalSelect={onIntervalSelect}
                            timeZone={timeZone}
                          />
                        );
                      })}
                    </$TimeSlotSelectorContainer>
                  </$StyledCol>
                );
              })}
            </Row>
          </Col>
        </Row>
      </$AppointmentCalendarScreen>
      <$Button
        type={BUTTON_TYPES.BUTTON}
        naked
        onClick={() => {
          onDayChange(1);
        }}
        icon={<$RightArrowButton />}
        secondary
        disabled={daysMenuStart >= detailedSchedule.length - numberOfDaysByPage}
      />
    </$AppointmentCalendarContainer>
  );
};

TimeSlotDisplay.displayName = 'TimeSlotDisplay';

export default TimeSlotDisplay;
