import { defineMessages } from 'react-intl';

export default defineMessages({
  oneProduct: {
    id: 'components.myaccount.order.oneProduct',
    defaultMessage: 'My order N° {order}',
  },
  back: {
    id: 'components.myaccount.order.goBackToSellerWebsite',
    defaultMessage: 'Return to website',
  },
  products: {
    id: 'components.myaccount.order.products',
    defaultMessage: 'My orders',
  },
  noProduct: {
    id: 'components.myaccount.order.noProduct',
    defaultMessage: "Vous n'avez actuellement aucune commande...",
  },
  errorOccured: {
    id: 'components.myaccount.order.errorOccured',
    defaultMessage: 'Oooops il y a eu un problème !',
  },
  add: {
    id: 'components.myaccount.order.add',
    defaultMessage: 'Ajouter un produit',
  },
  invoice: {
    id: 'components.myaccount.order.invoice',
    defaultMessage: 'Facture : ',
  },
  available: {
    id: 'components.myaccount.order.available',
    defaultMessage: 'Disponible',
  },
  reference: {
    id: 'components.myaccount.order.reference',
    defaultMessage: 'N° {reference} ',
  },
  buyDate: {
    id: 'components.myaccount.order.buyDate',
    defaultMessage: "Date d'achat",
  },
  purchaseChannel: {
    id: 'components.myaccount.order.purchaseChannel',
    defaultMessage: 'Canal de vente',
  },
  purchasedDate: {
    id: 'components.myaccount.order.foundOrder.buyDate',
    defaultMessage: "Date d'achat",
  },
  seller: {
    id: 'components.myaccount.order.seller',
    defaultMessage: 'Vendeur',
  },
  New: {
    id: 'components.myaccount.order.New',
    defaultMessage: 'Neuf',
  },
  Used: {
    id: 'components.myaccount.order.Used',
    defaultMessage: 'Occasion',
  },
  Reconditioned: {
    id: 'components.myaccount.order.Reconditioned',
    defaultMessage: 'Reconditionné',
  },
  Unknown: {
    id: 'components.myaccount.order.Unknown',
    defaultMessage: 'Unknown',
  },
  model: {
    id: 'components.myaccount.order.model',
    defaultMessage: 'Modèle',
  },
  brand: {
    id: 'components.myaccount.order.brand',
    defaultMessage: 'Marque',
  },
  state: {
    id: 'components.myaccount.order.state',
    defaultMessage: 'Etat',
  },
  endOfWarranty: {
    id: 'components.myaccount.order.endOfWarranty',
    defaultMessage: 'Fin de garantie',
  },
  until: {
    id: 'components.myaccount.order.until',
    defaultMessage: 'until',
  },
  followUp: {
    id: 'components.myaccount.order.followUp',
    defaultMessage: 'Continuer la déclaration',
  },
  declare: {
    id: 'components.myaccount.order.declare',
    defaultMessage: 'Déclarer un problème',
  },
  seeFileDetails: {
    id: 'components.myaccount.order.seeFileDetails',
    defaultMessage: 'Suivre le dossier ',
  },
  productWithClaim: {
    id: 'components.myaccount.order.productWithClaim',
    defaultMessage: 'Product with claim(s)',
  },
  otherProduct: {
    id: 'components.myaccount.order.otherProduct',
    defaultMessage: 'Other product(s)',
  },
  toggleMultiProductSelection: {
    id: 'components.myaccount.order.toggleMultiProductSelection',
    defaultMessage: 'Toggle multi-product selection',
  },
  orderNumber: {
    id: 'view.myaccount.order.orderNumber',
    defaultMessage: 'Order N°{orderReference}',
  },
  imei: {
    id: 'view.myaccount.order.imei',
    defaultMessage: 'IMEI',
  },
  quantity: {
    id: 'view.myaccount.order.quantity',
    defaultMessage: '{quantity} (out of {maxQuantity})',
  },
  quantityLabel: {
    id: 'view.myaccount.order.quantityLabel',
    defaultMessage: 'Quantity to return',
  },
  quantityCol: {
    id: 'view.card.claims.summaryClaimsProductQuantity',
    defaultMessage: 'Quantity',
  },
  requiredField: {
    id: 'components.myaccount.order.requiredQuantity',
    defaultMessage: 'Please specify quantify to return',
  },
  title_file_detail: {
    id: 'components.common.pageHeader.title_file_detail',
    defaultMessage: 'File N° {fileReference}',
  },
  withoutWarranty: {
    id: 'components.myaccount.order.withoutWarranty',
    defaultMessage: 'No warranty',
  },
  withWarranty: {
    id: 'components.myaccount.order.withWarranty',
    defaultMessage: 'Under warranty',
  },
  isRepaired: {
    id: 'components.myaccount.order.isRepaired',
    defaultMessage: 'Repaired',
  },
  serialNumber: {
    id: 'components.myaccount.order.serialNumber',
    defaultMessage: 'Serial number',
  },
  isRefunded: {
    id: 'components.myaccount.order.isRefunded',
    defaultMessage: 'Refunded',
  },
  isSwapped: {
    id: 'components.myaccount.order.isSwapped',
    defaultMessage: 'Swapped',
  },
});
