import React from 'react';

import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { DeleteIcon } from '@savgroup-front-common/core/src/protons/icons';

import {
  $CancelClaimButtonDesktop,
  $CancelClaimButtonMobile,
} from './CancelClaimButton.styles';
import messages from './messages';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

const CancelClaimButton = ({ cancelClaim }) => {
  return (
    <>
      <$CancelClaimButtonDesktop
        onClick={cancelClaim}
        data-testid="data-cancel-button-desktop"
      >
        <SafeFormattedMessageWithoutSpread message={messages.cancelClaim} />
      </$CancelClaimButtonDesktop>
      <$CancelClaimButtonMobile>
        <ButtonLink
          onClick={cancelClaim}
          dataTestId="data-cancel-button-mobile"
          componentThemeName={SUPPORTED_COMPONENTS.CANCEL_CLAIM_BUTTON}
          icon={<DeleteIcon />}
        />
      </$CancelClaimButtonMobile>
    </>
  );
};

CancelClaimButton.displayName = 'CancelClaimButton';

export default CancelClaimButton;
