import { createAction } from '@reduxjs/toolkit';

import {
  CLOSED_FILES_LOADED,
  MYRETURNS_RUNNING,
  OPEN_FILES_LOADED,
} from './actionTypes';

export const myReturnIsRunning = createAction(MYRETURNS_RUNNING.BASE);
export const myReturnIsRunningEnd = createAction(MYRETURNS_RUNNING.END);

export const loadClosedFiles = createAction<{
  ownerId: string;
  forceRefresh: boolean;
}>(CLOSED_FILES_LOADED.BASE);

export const loadOpenFiles = createAction<{
  ownerId: string;
  forceRefresh: boolean;
}>(OPEN_FILES_LOADED.BASE);
