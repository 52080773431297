import { media } from '@savgroup-front-common/constants';
import styled, { keyframes } from 'styled-components';

export const growUpKeyFrame = keyframes`
  0% { transform: scale(.8); }
  80% { transform: scale(1.25);}
  100% {transform: scale(1);}
`;

export const $CardContentHistoryWrapper = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;
  flex-flow: column;
  margin-top: 0.125rem;

  @media ${media.maxWidth.xs} {
    width: 70%;
    margin-left: -25px;
  }
`;

export const $CardContentHistory = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const $CardContentHistoryBlankSpace = styled.div`
  width: 3rem;
`;

export const $CardContentHistorySmallLine = styled.div`
  width: 2rem;
`;

export const $CardContentHistoryPoint = styled.div<{
  active?: boolean;
  disabled?: boolean;
}>`
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  animation: ${growUpKeyFrame} 0.5s linear;
  animation-delay: 0.5s;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.tertiary : theme.colors.primary};
  ${({ active, theme }) =>
    active
      ? `box-shadow: 0 0 0.5rem ${theme.colors.primary};`
      : 'animation: none;'}
`;

export const $CardContentHistoryLine = styled.div<{ disabled?: boolean }>`
  flex: 4;
`;

export const $CardContentHistoryDashedLine = styled.div<{ isFirst: boolean }>`
  position: absolute;
  top: 50%;
  left: ${({ isFirst }) => (isFirst ? '58%' : '50%')};
  transform: translate(-50%, -50%);
  width: 70%;
  height: 2px;
  background-image: ${({ theme }) => {
    const color = theme.colors.tertiary.replace('#', '');

    return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23${color}' stroke-width='4' stroke-dasharray='6%2c 16' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e")`;
  }};
`;

export const $CardContentHistoryPointContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

export const $CardContentHistoryLegendWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
`;

export const $CardContentHistoryLegend = styled.div<{
  disabled?: boolean;
  active?: boolean;
}>`
  width: 11rem;
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  flex-flow: column;
  align-items: center;
  text-align: center;
  padding: 0 0.25rem;
  font-size: 11px;
  font-weight: ${({ theme, disabled }) =>
    disabled ? theme.fonts.weight.normal : theme.fonts.weight.bold};
  line-height: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.tertiary : theme.colors.primary};
`;

export const $Divider = styled.div`
  width: calc(100% + 2rem);
  height: 1px;
  background: #f7f7f7;
  margin: 1rem -1rem 1.25rem;
`;
