import React from 'react';

interface HomeIconProps {
  color?: string;
}

/* eslint-disable max-len */
const HomeIcon: React.FC<HomeIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 15.8333L20 6.66667L35 15.8333M31.6667 21.6667V32.3333C31.6667 32.5985 31.5613 32.8529 31.3738 33.0404C31.1862 33.228 30.9319 33.3333 30.6667 33.3333H9.33333C9.06812 33.3333 8.81376 33.228 8.62623 33.0404C8.43869 32.8529 8.33333 32.5985 8.33333 32.3333V21.6667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

HomeIcon.displayName = 'HomeIcon';

export default HomeIcon;
