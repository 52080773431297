import { FileAction } from '../../../domains/Customer/Customer.types';

interface AdaptFirstActionArgs {
  fileId: string;
  action: FileAction;
  toCustomerComment?: string;
}

const adaptFirstAction = ({
  action,
  fileId,
  toCustomerComment,
}: AdaptFirstActionArgs) => {
  return {
    ...action,
    fileId,
    fileStatus: action?.currentState,
    fileStatusDate: action?.stateDate,
    toCustomerComment,
  };
};

export default adaptFirstAction;
