import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

import { extendedRequest } from '../../services';
import { prepareResponseFailure } from '../../helpers';

export interface UploadAttachmentArgs {
  file: File;
  endpoint: string;
  notify?: ({ progress }: { progress: number }) => void;
}

async function uploadAttachmentWithProgress({
  file,
  endpoint,
  notify,
}: UploadAttachmentArgs): Promise<BaseBackResponse | BackResponseFailure> {
  const body = new FormData();

  if (file) {
    body.append('fileBinary', file);
  }

  try {
    const response = await extendedRequest<BaseBackResponse>(endpoint, {
      method: SUPPORTED_METHODS.POST,
      body,
      notify,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default uploadAttachmentWithProgress;
