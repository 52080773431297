import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const LOAD_CUSTOMER_FILE = createRequest('LOAD_CUSTOMER_FILE');
export const LOAD_CUSTOMER_FILE_SUMMARY = createRequest(
  'LOAD_CUSTOMER_FILE_SUMMARY',
);
export const LOAD_MODEL_INFORMATIONS = createRequest('LOAD_MODEL_INFORMATIONS');
export const LOAD_IRSH_TRANSLATIONS = createRequest('LOAD_IRSH_TRANSLATIONS');
export const LOAD_FILE_STATE_HISTORY_LINES = createRequest(
  'LOAD_FILE_STATE_HISTORY_LINES',
);
export const LOAD_OPEN_FILES = createRequest('LOAD_OPEN_FILES');
export const LOAD_CLOSED_FILES = createRequest('LOAD_CLOSED_FILES');

export const LOAD_CLAIM_CONFIRMATION_SCREEN_DATA = createRequest(
  'LOAD_CLAIM_CONFIRMATION_SCREEN_DATA',
);
export const LOAD_QUOTES_DATA = createRequest('LOAD_QUOTES_DATA');
export const LOAD_INVOICE_DATA = createRequest('LOAD_INVOICE_DATA');
export const LOAD_QUOTE_DATA = createRequest('LOAD_QUOTE_DATA');
export const STORE_CLAIMID_FILEID = createRequest('STORE_CLAIMID_FILEID');
export const GET_SHIPPING_LABEL = createRequest('GET_SHIPPING_LABEL');

export const IS_LOADING = createRequest('IS_LOADING');
export const ACT_ON_QUOTE = createRequest('ACT_ON_QUOTE');
export const LOAD_WORKFLOW_ACTIONS = createRequest('LOAD_WORKFLOW_ACTIONS');
export const SUBMIT_WORKFLOW_ACTION = createRequest('SUBMIT_WORKFLOW_ACTION');

export const LOAD_CURRENT_FILE_ACTIONS_META = createRequest(
  'LOAD_CURRENT_FILE_ACTIONS_META',
);

export const LOAD_FILE_STATES = createRequest(
  '@@MYACCOUNT/FILES/LOAD_FILE_STATES',
);

export const STATE_UPDATE_SUCCEEDED = 'STATE_UPDATE_SUCCEEDED';
