import groupBy from 'lodash/groupBy';
import { createSelector } from 'reselect';

import { CommonAppState } from '../CommonAppState';
import { Selectors as requestSelectors } from '../sagaRequestMetadata';

import * as actionTypes from './actionTypes';

const carriersState = (state: CommonAppState) => state.carriers;

export const labels = createSelector([carriersState], (carriers) =>
  carriers.get('labels'),
);

export const labelsValue = createSelector(
  [
    carriersState,
    (_: CommonAppState, { fileId }: { fileId: string }) => fileId,
  ],
  (state, fileId) => {
    return {
      isLoaded: state.getIn(['labels', fileId, 'isLoaded'], null),
      hasErrors: state.getIn(['labels', fileId, 'hasErrors'], null),
      value: state.getIn(['labels', fileId, 'value'], null),
      labelsStatus: state.getIn(['labels', fileId, 'regenerateLabel'], []),
      rmaDocumentStatus: state.getIn(
        ['labels', fileId, 'rmaDocumentStatus'],
        null,
      ),
    };
  },
);
export const externalCarriers = createSelector([carriersState], (carriers) =>
  carriers.get('externalCarriers'),
);

export const carriersProducts = createSelector([carriersState], (carriers) =>
  carriers.get('carriersProducts'),
);
export const transportMethods = createSelector([carriersState], (carriers) =>
  carriers.get('transportMethods'),
);
export const carrierLabelUrls = createSelector([carriersState], (carriers) =>
  carriers.get('carrierLabelUrls'),
);
export const appointedHomePickup = createSelector([carriersState], (carriers) =>
  carriers.get('appointedHomePickup'),
);

export const homePickupSchedule = createSelector([carriersState], (carriers) =>
  carriers.get('homePickupSchedule'),
);
export const pickupSchedule = createSelector([homePickupSchedule], (carriers) =>
  carriers.get('value'),
);
export const scheduleByDay = createSelector([pickupSchedule], (schedule) =>
  groupBy(schedule, 'date'),
);

export const externalCarrierIsLoading = (state: CommonAppState) =>
  requestSelectors.isActionLoadingSelector(
    state,
    actionTypes.LOAD_EXTERNAL_CARRIERS,
  );
