export const BASE_ROUTES = {
  NEW_DESIGN: '/new_design',
  PROFESSIONAL: '/professional',
  HOME: '/home',
};

// Legacy sur le vieux IRSH
export const ROUTES = {
  HOME: '/',
  INDEX: '/index',

  OLD_FILE_DETAILS:
    '/myReturn/details/claimId=:claimId&fileId=:fileId&ownerId=:ownerId',
  CLAIM_DETAILS: '/claim/:claimId',
  PRODUCTS: '/products',
  ISSUES: '/issues',
  CHOOSE_PRODUCTS: '/chooseProducts/:issueId',
  CLAIM_GROUP_ISSUES: '/claimGroup/:claimGroupId/issues',

  CLAIM_GROUP_HANDLING_DELIVERY: '/claimGroup/:claimId/handling/delivery',
  CLAIM_GROUP_HANDLING_DEPOSIT: '/claimGroup/:claimId/handling/deposit',

  CLAIM_HANDLING_DEPOSIT: '/claim/:claimId/handling/deposit',
  CLAIM_HANDLING_DELIVERY: '/claim/:claimId/handling/delivery',

  FILE_FOLLOWUPS: '/files/:fileId',

  HOME_BTOB: '/home',
  ORDER_CREATE_CLAIM: '/order/:orderId/createClaim',

  CLAIM_GROUP_DETAILS: '/claimGroup/:claimGroupId',

  CLAIM_GROUP_HANDLING: '/claimGroup/:claimGroupId/handling/:handlingMode',
  CLAIM_GROUP_CONFIRMATION: '/claimGroup/:claimGroupId/confirmation',

  PAGE_NOT_FOUND: '/page_not_found',
  DEBUG: '/claimGroup/:claimGroupId/debug',
  CALLBACK: '/callback',
};

export const ROUTES_NEW_DESIGN = {
  HOME: '/',
  INDEX: '/index',
  PRODUCTS: '/products',
  ISSUES: '/issues',
  CHOOSE_PRODUCTS: '/chooseProducts/:issueId',
  CLAIM_GROUP_REASON: '/claimGroup/:claimGroupId/reason',
  CLAIM_GROUP_DIAGNOSTIC_TREE:
    '/claimGroup/:claimGroupId/diagnosticTree/:questionId/:step',
  CLAIM_GROUP_PRODUCT_INFO: '/claimGroup/:claimGroupId/productInformation',
  CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM:
    '/claimGroup/:claimGroupId/documentRelatedToClaim',
  CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT:
    '/claimGroup/:claimGroupId/documentRelatedToProduct',
  CLAIM_GROUP_SOLUTION: '/claimGroup/:claimGroupId/solution',
  CLAIM_GROUP_HANDLING_DEPOSIT: '/claimGroup/:claimGroupId/handling/deposit',
  CLAIM_GROUP_HANDLING_DELIVERY: '/claimGroup/:claimGroupId/handling/delivery',
  CLAIM_GROUP_CONFIRMATION: '/claimGroup/:claimGroupId/confirmation',
  CLAIM_GROUP_VALIDATION: '/claimGroup/:claimGroupId/validation',

  FILE_FOLLOWUPS: '/files/:fileId',

  ORDER_CREATE_CLAIM: '/order/:orderId/createClaim',
  CLAIM_GROUP_DETAILS: '/claimGroup/:claimGroupId',

  OLD_FILE_DETAILS:
    '/myReturn/details/claimId=:claimId&fileId=:fileId&ownerId=:ownerId',

  PAGE_NOT_FOUND: '/page_not_found',
  DEBUG: '/claimGroup/:claimGroupId/debug',
  CALLBACK: '/callback',
  VIDEO_CALL: '/visio/session/:sessionId',
};

export const HOME_ROUTES = {
  PRODUCTS: `${BASE_ROUTES.NEW_DESIGN}${BASE_ROUTES.HOME}/products`,
  REPAIRS: `${BASE_ROUTES.NEW_DESIGN}${BASE_ROUTES.HOME}/repairs`,
};
