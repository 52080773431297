export const CLAIM_STATES = {
  CREATED: 'Created',
  WAITING_FOR_ISSUE: 'Created',
  WAITING_FOR_REASON: 'WaitingForReason',
  WAITING_FOR_SOLUTION: 'WaitingForSolution',
  WAITING_FOR_DEPOSIT_INFO: 'WaitingForHandlingDepositInfo',
  WAITING_FOR_DELIVERY_INFO: 'WaitingForHandlingDeliveryInfo',
  WAITING_FOR_CONFIRMATION: 'Confirmation',
  CLOSED: 'Closed',
};

export const CLAIM_STATES_AVAILABLE_FOR_GET_ISSUES = [
  CLAIM_STATES.WAITING_FOR_ISSUE,
  CLAIM_STATES.WAITING_FOR_REASON,
  CLAIM_STATES.WAITING_FOR_SOLUTION,
  CLAIM_STATES.WAITING_FOR_CONFIRMATION,
  CLAIM_STATES.WAITING_FOR_DEPOSIT_INFO,
  CLAIM_STATES.WAITING_FOR_DELIVERY_INFO,
];

export const CLAIM_STATES_AVAILABLE_FOR_GET_REASONS = [
  CLAIM_STATES.WAITING_FOR_REASON,
  CLAIM_STATES.WAITING_FOR_SOLUTION,
  CLAIM_STATES.WAITING_FOR_CONFIRMATION,
  CLAIM_STATES.WAITING_FOR_DEPOSIT_INFO,
  CLAIM_STATES.WAITING_FOR_DELIVERY_INFO,
];

export const CLAIM_STATES_AVAILABLE_FOR_GET_SOLUTIONS = [
  CLAIM_STATES.WAITING_FOR_SOLUTION,
  CLAIM_STATES.WAITING_FOR_CONFIRMATION,
  CLAIM_STATES.WAITING_FOR_DEPOSIT_INFO,
  CLAIM_STATES.WAITING_FOR_DELIVERY_INFO,
];
