import * as ActionTypes from './actionTypes';

export const initialState = {
  modals: [],
};

const onModalOpen = (state, modal) => ({
  ...state,
  modals: [...state.modals, modal],
});

const onModalClose = (state) => ({
  ...state,
  modals: state.modals.slice(1),
});

export default function modalsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.OPEN_MODAL: {
      return onModalOpen(state, action.payload);
    }

    case ActionTypes.CLOSE_MODAL: {
      return onModalClose(state);
    }

    default: {
      return state;
    }
  }
}
