import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  BackResponse,
  BackResponseFailure,
  QuotationNewModelDto,
} from '@savgroup-front-common/types';
import { QuoteClientAnswer } from '@savgroup-front-common/types/src/Quotation/QuoteClientAnswer';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.repairer}quotes/answered`;

export const setQuoteClientAnswerCommand = async ({
  quoteId,
  clientAnswer,
}: {
  quoteId: string;
  clientAnswer: QuoteClientAnswer;
}): Promise<BackResponseFailure | BackResponse<QuotationNewModelDto[]>> => {
  try {
    return await apiCall<BackResponse<QuotationNewModelDto[]>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        quoteId,
        clientAnswer,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
