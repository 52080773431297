import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import size from 'lodash/size';
import { createSelector } from 'reselect';

import { regroupProducts } from '@savgroup-front-common/core/src/helpers';
import { Selectors as ClaimsSelectors } from '@savgroup-front-common/core/src/domains/claims';

import { customerState } from '../Customer/selectors';
import { productsInfoSelector } from '../Orders/selectors';

import { ordersSelector } from './groupedProduct';

export const summaryFileProductsSelector = createSelector(
  [
    customerState,
    productsInfoSelector,
    ordersSelector,
    ClaimsSelectors.claimGroupsConfirmation,
  ],
  (customer, productsInfo, orders, confirmation) => {
    const fileSummary = get(customer, 'customerFileSummary');
    const claimGroupId = get(fileSummary, ['claimInfoSummary', 'claimGroupId']);
    const claimGroupConfirmation = confirmation.getIn(
      [claimGroupId, 'value'],
      null,
    );
    const claimsConfirmation = get(claimGroupConfirmation, 'claims');
    const orderId = get(fileSummary, 'orderId');
    const fileProducts = map(
      get(fileSummary, 'fileProducts', []),
      (product) => ({
        ...product,
        sellerProductId: get(productsInfo, [
          product.ownerProductId,
          'sellerProductId',
        ]),
        orderNumber: get(productsInfo, [
          product.ownerProductId,
          'orderReference',
        ]),
        purchaseDate: get(productsInfo, [product.ownerProductId, 'buyDate']),
      }),
    );
    const orderProducts = get(find(orders, { orderId }), 'products', []);
    const regroupedOrderProducts = regroupProducts(orderProducts);
    const regroupedFileProducts = regroupProducts(fileProducts);

    return map(regroupedFileProducts, (product) => {
      const claim = find(
        claimsConfirmation,
        (claimConfirmation) => claimConfirmation.claimId === product.claimId,
      );

      return {
        ...product,
        ...get(customer, ['file', 'models', product.modelId]),
        maxQuantity: size(
          get(
            find(regroupedOrderProducts, {
              sellerProductId: get(product, 'sellerProductId'),
            }),
            'ownerProductId',
          ),
        ),
        issue: get(claim, 'issueName'),
        reason: get(claim, 'reasonName'),
        solution: get(claim, 'solutionTypeName'),
        solutionGroupId: get(claim, 'solutionGroupId'),
        price: get(claim, 'solutionPrice'),
        comment:
          get(claim, 'reasonComment') ||
          get(
            find(get(claim, 'comments'), { type: 'ReasonComment' }),
            'content',
          ),
        documents: get(claim, 'documents'),
        solutionTypeKey: get(claim, 'solutionTypeKey'),
      };
    });
  },
);
