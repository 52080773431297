import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.myaccount.interventionAppointment.title',
    defaultMessage: 'Take an appointment for intervention',
  },
  whatINeedToDo: {
    id: 'components.interventionAppointment.whatINeedToDo',
    defaultMessage: 'What i need to do ?',
  },
  validate: {
    id: 'components.myaccount.interventionAppointment.validate',
    defaultMessage: 'Validate',
  },
  requiredTimeSlotSelection: {
    id: 'components.myaccount.interventionAppointment.requiredTimeslot',
    defaultMessage: 'Timeslot selection is required',
  },
  noAvailableSchedule: {
    id: 'components.myaccount.interventionAppointment.noAvailableSchedule',
    defaultMessage: 'There are no available schedule please retry later.',
  },
  description: {
    id: 'components.myaccount.claims.appointmentCard.description',
    defaultMessage:
      'Please select one of the following time slots to confirm your home pick up.',
  },
  pleaseContactSupportToTakeAppointment: {
    id: 'components.myaccount.interventionAppointment.pleaseContactSupportToTakeAppointment',
    defaultMessage: 'Please contact support to take appointment',
  },

  integrationTypeIsNotConfigured: {
    id: 'components.interventionAppointment.integrationTypeIsNotConfigured',
    defaultMessage: 'FSL integration type is not configured.',
  },
  'Reverse.SalesforceAdapter.Errors.SalesforceAdapter.CoordinatesNotFoundOnTheServiceAppointment':
    {
      id: 'view.takeInterventionAppointmentLegacy.CoordinatesNotFoundOnTheServiceAppointment',
      defaultMessage: 'Please retry later.',
    },
});
