import { createSelector } from 'reselect';

import { OWNER_DOMAIN_KEY } from './constants';
import { MyAccountRootState } from '../MyAccountRootState';

export const ownerState = (state: MyAccountRootState) =>
  state[OWNER_DOMAIN_KEY];

export const fullProducts = createSelector([ownerState], (state) =>
  state.get('fullProducts'),
);
export const fullProductsValue = createSelector(
  [ownerState, (_: MyAccountRootState, productId: string) => productId],
  (state, productId) => {
    return {
      isLoaded: state.getIn(['fullProducts', productId, 'isLoaded'], null),
      value: state.getIn(['fullProducts', productId, 'value'], null),
    };
  },
);
