import React, { FunctionComponent } from 'react';

import { HANDLING_GROUPS } from '@savgroup-front-common/constants';
import {
  BUTTON_TYPES,
  CURRENCIES,
  HANDLING_MODES,
} from '@savgroup-front-common/constants/src/shared';
import { OptionCard } from '@savgroup-front-common/core/src/molecules/OptionCard';
import { formatAmountWithCurrency } from '@savgroup-front-common/core/src/formatters/intl';

import { getLabel } from './HandlingCard.helpers';
import HandlingIcon from './HandlingIcon';
import messages from './messages';

interface HandlingCardProps {
  handlingMode: HANDLING_MODES;
  onSelect: () => void;
  currency?: CURRENCIES;
  pickupMode: HANDLING_GROUPS;
  price?: number;
}

const HandlingCard: FunctionComponent<
  React.PropsWithChildren<HandlingCardProps>
> = ({
  pickupMode,
  onSelect,
  currency = CURRENCIES.EUR,
  handlingMode,
  price = 0,
}) => {
  const didactic =
    price > 0 ? formatAmountWithCurrency(price, currency) : messages.free;

  return (
    <OptionCard
      title={getLabel({ handlingMode, pickupMode })}
      didactic={didactic}
      onClick={() => {
        window.scroll(0, 0);
        onSelect();
      }}
      dataTestId={`${pickupMode}${handlingMode}`}
      buttonType={BUTTON_TYPES.SUBMIT}
    >
      <HandlingIcon pickupMode={pickupMode} />
    </OptionCard>
  );
};

HandlingCard.displayName = 'HandlingCard';

export default React.memo(HandlingCard);
