import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Checkbox } from '@savgroup-front-common/core/src/atoms/checkbox';

export const $AgreementRow = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

export const getAgreementColor = ({ theme }) => {
  return theme.colors.mainTextColor;
};

export const $AgreementText = styled.span`
  color: ${(props) => getAgreementColor(props)};
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 0;
  margin-right: 8px;
`;

export const StyledLink = styled.a`
  text-decoration: underline;
  color: ${(props) => getAgreementColor(props)};
`;

export const RowWrapper = styled(Row)`
  margin: 8px 0 32px;
`;
