import get from 'lodash/get';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { Selectors as ClaimSelectors } from '@savgroup-front-common/core/src/domains/claims';
import {
  getCarrierBrand,
  getFromDataByKey,
} from '@savgroup-front-common/core/src/helpers';
import { Selectors as ClaimSelector } from 'myaccount/domains/Claim';
import {
  allCurrentReasonLoaded,
  currentClaimGroup,
  currentClaimGroupConfirmation,
  currentClaimGroupIdSelector,
  currentClaimsAreLoaded,
  currentConfirmationErrors,
  currentConfirmationLoadingStatus,
  currentInvoice,
  currentSelectedIssueId,
  fileProductsLoadedSelector,
  fileProductsSelector,
  translatedCarrirerMethodNames,
} from 'myaccount/domains/Claim/claimGroupSelector';
import { Selectors as OwnerSelectors } from 'myaccount/domains/owner';
import { getClaimGroupHandlingLink } from 'myaccount/helpers/routing';
import { ROUTES } from 'myaccount/view/app';

import { loginState } from '../../../domains/Customer/selectors';

import ClaimGroupConfirmation from './ClaimGroupConfirmation';
import { ClaimGroupHandling } from './ClaimGroupHandling';
import ClaimGroupIRS from './ClaimGroupIRS';

const ClaimGroupPage = ({
  currentClaimGroupConfirmation: claimGroupConfirmation,
  currentClaimGroup,
  currentSelectedIssue,
  currentClaimGroupId,
  fileProducts,
  login,
  productsFullInfo,
  currentConfirmationErrors,
  currentConfirmationLoadingStatus,
  translatedCarrirerMethodNames,
  currentInvoice,
  productsIsLoaded,
  currentReasonsLoaded,
  currentClaimsIsLoaded,
  fileSummary,
}) => {
  const history = useHistory();
  const storeActorOptions = useSelector((state) =>
    ClaimSelectors.selectStoreActorOptions(state, {
      claimIds: currentClaimGroup,
    }),
  );

  const confirmation = getFromDataByKey(claimGroupConfirmation, 'value', null);

  const deliveryImagePath = confirmation
    ? `${APIConfiguration.catalogCDN}carriers/${(
        getCarrierBrand(confirmation.carrierDeliveryName) || 'external'
      ).toLowerCase()}.png`
    : null;

  const depositImagePath = confirmation
    ? `${APIConfiguration.catalogCDN}carriers/${(
        getCarrierBrand(confirmation.carrierDepositName) || 'external'
      ).toLowerCase()}.png`
    : null;

  const tooltipImage = '/images/blue-question-mark.svg';

  return (
    <Switch>
      <Route exact path={ROUTES.CLAIM_GROUP_ISSUES}>
        <ClaimGroupIRS
          currentSelectedIssue={currentSelectedIssue}
          history={history}
          currentClaimGroupId={currentClaimGroupId}
          productsIsLoaded={productsIsLoaded}
          currentReasonsLoaded={currentReasonsLoaded}
          currentClaimsIsLoaded={currentClaimsIsLoaded}
        />
      </Route>

      <Route exact path={ROUTES.CLAIM_GROUP_HANDLING}>
        <ClaimGroupHandling claimGroupId={currentClaimGroupId} />
      </Route>

      <Route exact path={ROUTES.CLAIM_GROUP_CONFIRMATION}>
        {confirmation && (
          <ClaimGroupConfirmation
            assets={{
              deliveryImagePath,
              depositImagePath,
              tooltipImage,
            }}
            confirmation={confirmation}
            confirmationIsLoaded={getFromDataByKey(
              claimGroupConfirmation,
              'isLoaded',
              false,
            )}
            currentInvoice={currentInvoice}
            confirmationLoadingStatus={currentConfirmationLoadingStatus}
            confirmationErrors={currentConfirmationErrors}
            getClaimHandlingLink={getClaimGroupHandlingLink}
            fileIsLoading={get(fileSummary, 'isLoading')}
            storeActorOptions={storeActorOptions}
            fileProducts={fileProducts}
            login={login}
            productsFullInfo={productsFullInfo}
            currentConfirmationErrors={currentConfirmationErrors}
            currentConfirmationLoadingStatus={currentConfirmationLoadingStatus}
            translatedCarrirerMethodNames={translatedCarrirerMethodNames}
          />
        )}
      </Route>
    </Switch>
  );
};

const mapStateToProps = (state) => {
  return {
    login: loginState(state),
    fileProducts: fileProductsSelector(state),
    productsIsLoaded: fileProductsLoadedSelector(state),
    currentClaimGroupId: currentClaimGroupIdSelector(state),
    currentClaimsIsLoaded: currentClaimsAreLoaded(state),
    currentReasonsLoaded: allCurrentReasonLoaded(state),
    currentClaimGroupConfirmation: currentClaimGroupConfirmation(state),
    translatedCarrirerMethodNames: translatedCarrirerMethodNames(state),
    productsFullInfo: (productId) =>
      OwnerSelectors.fullProductsValue(state, productId),
    currentInvoice: currentInvoice(state),
    currentConfirmationLoadingStatus: currentConfirmationLoadingStatus(state),
    currentConfirmationErrors: currentConfirmationErrors(state),
    currentClaimGroup: currentClaimGroup(state),
    currentSelectedIssue: currentSelectedIssueId(state),
    fileSummary: ClaimSelector.claimSummary(state),
  };
};

ClaimGroupPage.displayName = 'ClaimGroupPage';

export default connect(mapStateToProps)(ClaimGroupPage);
