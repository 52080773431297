import { Action } from 'redux-actions';

import { getInitialLanguage } from '../../helpers';

import { ChangeLocaleLanguagePayload, types } from './actions';
import { I18nAppState } from './types';

function onChangeLocalLanguage(
  state: I18nAppState,
  { payload }: Action<ChangeLocaleLanguagePayload>,
): I18nAppState {
  const { locale } = payload;

  return locale;
}

export default function actionsReducer(
  state: I18nAppState = getInitialLanguage(),
  action: Action<ChangeLocaleLanguagePayload>,
): I18nAppState {
  switch (action.type) {
    case types.CHANGE_LOCALE_LANGUAGE.BASE: {
      return onChangeLocalLanguage(state, action);
    }

    default: {
      return state;
    }
  }
}
