import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import { createSelector } from 'reselect';

import { verifiedAddress } from '@savgroup-front-common/core/src/domains/carriers/verifiedAddress/selectors';

import messages from '../../view/pages/ClaimGroupPage/ClaimGroupHandling/steps/CarrierForm/AddressManagement/messages';

const genericMessageCodes = [
  'E.COUNTRY.USUPPORTED',
  'E.ENGINE.UNAVAILABLE',
  'E.QUERY.UNANSWERABLE',
  'E.SECONDARY_INFORMATION.INVALID',
  'E.SECONDARY_INFORMATION.MISSING',
  'E.PO_BOX.INTERNATIONAL',
  'E.BOX_NUMBER.MISSING',
  'E.BOX_NUMBER.INVALID',
  'E.ZIP.PLUS4.NOT_FOUND',
  'E.TIMED_OUT',
  'E.TIME_ZONE.UNAVAILABLE',
  'E.STREET.MAGNET',
  'E.ADDRESS.DUAL',
];

const getMessageIdOrDefaultMessage = (error, messages) => {
  const code = get(error, 'code');

  if (genericMessageCodes.includes(code)) {
    return 'genericVerificationError';
  }
  if (messages[code]) {
    return code;
  }

  return get(error, 'message');
};

const verificationDictionary = {
  countryCode: 'countryCode',
  street1: 'address',
  street2: 'additionalAddress',
  zipCode: 'postalCode',
  city: 'city',
  phone: 'phone',
  contactLastName: 'lastName',
  contactFirstName: 'firstName',
  address: 'address',
};

export const verificationErrors = createSelector(
  [verifiedAddress],
  (verifiedAddressState) => {
    const errors = reduce(
      verifiedAddressState.get('errorFields'),
      (error, errorItem) =>
        verificationDictionary[get(errorItem, 'field')]
          ? {
              ...error,
              [verificationDictionary[get(errorItem, 'field')]]:
                getMessageIdOrDefaultMessage(errorItem, messages),
            }
          : error,
      {},
    );

    return isEmpty(errors) ? null : errors;
  },
);
