import { defineMessages } from 'react-intl';

export default defineMessages({
  deliveryLocationNoAddress: {
    id: 'view.createSparePartRequest.requestHandlingDelivery.deliveryLocationNoAddress',
    defaultMessage: ' no delivery adress setted',
  },
  deliveryLocationStore: {
    id: 'view.createSparePartRequest.requestHandlingDelivery.deliveryLocationStore',
    defaultMessage: ' in store delivery',
  },
  deliveryLocationRepairCenter: {
    id: 'view.createSparePartRequest.requestHandlingDelivery.deliveryLocationRepairCenter',
    defaultMessage: ' in repair center delivery',
  },
  deliveryLocationCustomer: {
    id: 'view.createSparePartRequest.requestHandlingDelivery.deliveryLocationCustomer',
    defaultMessage: ' at customer delivery',
  },
  deliveryLocationSpecificAddress: {
    id: 'view.createSparePartRequest.requestHandlingDelivery.deliveryLocationSpecificAddress',
    defaultMessage: ' at specific address delivery',
  },
  deliveryLocationDepotstore: {
    id: 'view.createSparePartRequest.requestHandlingDelivery.deliveryLocationDepotstore',
    defaultMessage: ' in depotstore delivery',
  },
  deliveryLocationWorkshop: {
    id: 'view.createSparePartRequest.requestHandlingDelivery.deliveryLocationWorkshop',
    defaultMessage: ' in workshop delivery',
  },
  deliveryLocationWarehouse: {
    id: 'view.createSparePartRequest.requestHandlingDelivery.deliveryLocationWarehouse',
    defaultMessage: ' in warehouse delivery',
  },
  deliveryLocationBox: {
    id: 'view.createSparePartRequest.requestHandlingDelivery.deliveryLocationBox',
    defaultMessage: ' in box delivery',
  },
  deliveryLocationHub: {
    id: 'view.createSparePartRequest.requestHandlingDelivery.deliveryLocationHub',
    defaultMessage: ' in hub delivery',
  },
  deliveryLocationVan: {
    id: 'view.createSparePartRequest.requestHandlingDelivery.deliveryLocationVan',
    defaultMessage: ' in van delivery',
  },
});
