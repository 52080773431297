import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { H3, H5 } from '../../../atoms/headings';
import { Card } from '../../../atoms/Cards';
import { Tag } from '../../../atoms/Tag';
import { mediaQuery } from '../../../grid/helpers';
import { rem } from '../../../helpers';
import { ProductIcon } from '../../ProductIcon';

import messages from './messages';

const ProductCardContainer = styled(Card)`
  border-radius: 0;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding: 24px 0;
  width: 100%;
  bottom: 0;
  left: 0;

  ${mediaQuery.sm`
    position: static;
  `};

  ${(props) => (props.asHeader ? 'display: none;' : null)};

  ${mediaQuery.sm`
    ${(props) => (props.asHeader ? 'display: block;' : null)};
  `};
`;

const InfoContainer = styled(Row)`
  padding-left: ${rem('25px')};
  padding-right: ${rem('25px')};

  ${mediaQuery.sm`align-items: flex-start;`};
`;

const ProductInfo = styled('div')`
  padding-left: 8px;

  &:first-child {
    padding-bottom: 0;
    ${mediaQuery.sm`padding-bottom: 12px;`};
  }
  h3 {
    line-height: 20px;
  }
  h4 {
    line-height: 20px;
  }
  h5 {
    font-size: 11px;
    line-height: 16px;
  }
`;

const ProductInfoWrapperFull = styled(Col)`
  ${mediaQuery.lg`margin-left: -32px;`};
`;

const ProductInfoFull = styled(ProductInfo)`
  ${mediaQuery.md`padding-left: 0;`};
`;

const ImageContainer = styled(Card)`
  margin: 0;
  padding: 12px 16px;
  border: 1px solid #e1e4e8;
  box-shadow: none;
  display: flex;
  justify-content: center;
  width: 104px;
  margin-right: 16px;

  ${mediaQuery.md`margin-right: 0;`} img {
    object-fit: contain;
    max-height: 74px;
    max-width: 74px;
  }
`;

const ImageContainerHeader = styled(Col)`
  width: auto;
  flex-basis: 0;

  div {
    padding: 3px 16px;
    max-width: 60px;
    max-height: 60px;
  }
`;

const ProductInfoCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ProductCard = (props) => {
  const {
    displayEndOfWarranty,
    endOfWarranty,
    brandName,
    modelName,
    modelTypeId,
    modelTypeName,
    purchaseDate,
    buyDate,
    full,
    asHeader,
    label,
  } = props;

  if (full) {
    return (
      <ProductCardContainer>
        <Row between="xs">
          <Col xs={12}>
            <InfoContainer>
              <Col xs={5} sm={2} lg={3}>
                <ImageContainer>
                  <ProductIcon
                    modelTypeId={modelTypeId}
                    modelTypeName={modelTypeName}
                  />
                </ImageContainer>
              </Col>
              <ProductInfoWrapperFull xs={7} sm={9}>
                <Row>
                  <ProductInfoCol xs={12} sm={5} lg={5}>
                    <ProductInfoFull>
                      <FormattedMessage {...messages.brand}>
                        {(message) => <H5> {message} </H5>}
                      </FormattedMessage>
                      <H3 heading> {brandName} </H3>
                    </ProductInfoFull>

                    <ProductInfoFull>
                      <FormattedMessage {...messages.purchaseDate}>
                        {(message) => <H5> {message} </H5>}
                      </FormattedMessage>
                      {/*
                        REVERS.IO
                        The purchased date needs to be passed
                        into ProductInfoFull props
                      */}
                      {purchaseDate || buyDate ? (
                        <FormattedDate
                          year="numeric"
                          month="long"
                          day="2-digit"
                          value={purchaseDate || buyDate}
                        >
                          {(message) => <H3 heading> {message} </H3>}
                        </FormattedDate>
                      ) : (
                        '-'
                      )}
                    </ProductInfoFull>
                  </ProductInfoCol>
                  <Col xs={11} sm={7}>
                    <ProductInfoFull>
                      <FormattedMessage {...messages.model}>
                        {(message) => <H5> {message} </H5>}
                      </FormattedMessage>
                      <H3 heading> {modelName} </H3>
                    </ProductInfoFull>

                    {displayEndOfWarranty ? (
                      <ProductInfoFull>
                        <FormattedMessage {...messages.endOfWarranty}>
                          {(message) => <H5> {message} </H5>}
                        </FormattedMessage>
                        {endOfWarranty ? (
                          <FormattedDate
                            year="numeric"
                            month="long"
                            day="2-digit"
                            value={endOfWarranty}
                          >
                            {(message) => <H3 heading> {message} </H3>}
                          </FormattedDate>
                        ) : (
                          '-'
                        )}
                      </ProductInfoFull>
                    ) : null}

                    {label && label.length > 0 ? (
                      <ProductInfoFull>
                        <Row>
                          <Tag style={{ maxWidth: '100%', marginBottom: 0 }}>
                            {label}
                          </Tag>
                        </Row>
                      </ProductInfoFull>
                    ) : null}
                  </Col>
                </Row>
              </ProductInfoWrapperFull>
            </InfoContainer>
          </Col>
        </Row>
      </ProductCardContainer>
    );
  }

  return (
    <ProductCardContainer asHeader={asHeader}>
      <Grid>
        <Row>
          {asHeader ? (
            <Col xs={false} sm={12} lgOffset={2} lg={8}>
              <Row>
                <ImageContainerHeader xs={false} sm={2} lg={2}>
                  <ImageContainer>
                    <ProductIcon
                      modelTypeId={modelTypeId}
                      modelTypeName={modelTypeName}
                    />
                  </ImageContainer>
                </ImageContainerHeader>
                <Col sm>
                  <Row>
                    <ProductInfoCol xs={false} sm={3}>
                      <ProductInfo>
                        <FormattedMessage {...messages.brand}>
                          {(message) => <H5> {message} </H5>}
                        </FormattedMessage>
                        <H3 heading> {brandName} </H3>
                      </ProductInfo>
                      <ProductInfo>
                        <FormattedMessage {...messages.purchaseDate}>
                          {(message) => <H5> {message} </H5>}
                        </FormattedMessage>

                        <H3 heading>
                          {purchaseDate || buyDate ? (
                            <FormattedDate
                              year="numeric"
                              month="long"
                              day="2-digit"
                              value={purchaseDate || buyDate}
                            />
                          ) : (
                            '-'
                          )}
                        </H3>
                      </ProductInfo>
                    </ProductInfoCol>
                    <Col sm md>
                      <ProductInfo>
                        <FormattedMessage {...messages.model}>
                          {(message) => <H5> {message} </H5>}
                        </FormattedMessage>
                        <H3 heading> {modelName} </H3>
                      </ProductInfo>
                      {label && label.length > 0 ? (
                        <ProductInfo>
                          <H5 />
                          <Tag>{label}</Tag>
                        </ProductInfo>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          ) : (
            <>
              <Col xs={false} sm={2} lg={2}>
                <ImageContainer>
                  <ProductIcon
                    modelTypeId={modelTypeId}
                    modelTypeName={modelTypeName}
                  />
                </ImageContainer>
              </Col>
              <Col sm>
                <Row>
                  <ProductInfoCol xs={false} sm={3}>
                    <ProductInfo>
                      <FormattedMessage {...messages.brand}>
                        {(message) => <H5> {message} </H5>}
                      </FormattedMessage>
                      <H3 heading> {brandName} </H3>
                    </ProductInfo>
                    <ProductInfo>
                      <FormattedMessage {...messages.purchaseDate}>
                        {(message) => <H5> {message} </H5>}
                      </FormattedMessage>
                      {/*
                        REVERS.IO
                        The purchased date needs to be passed
                        into productInfo props
                      */}
                      <H3 heading>
                        {purchaseDate ? (
                          <FormattedDate
                            year="numeric"
                            month="long"
                            day="2-digit"
                            value={purchaseDate}
                          >
                            {(message) => <H3 heading> {message} </H3>}
                          </FormattedDate>
                        ) : (
                          '-'
                        )}
                      </H3>
                    </ProductInfo>
                  </ProductInfoCol>
                  <Col sm md>
                    <ProductInfo>
                      <FormattedMessage {...messages.model}>
                        {(message) => <H5> {message} </H5>}
                      </FormattedMessage>
                      <H3 heading> {modelName} </H3>
                    </ProductInfo>
                    {label && label.length > 0 ? (
                      <ProductInfo>
                        <H5 />
                        <Tag>{label}</Tag>
                      </ProductInfo>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Grid>
    </ProductCardContainer>
  );
};

ProductCard.propTypes = {
  brandName: PropTypes.string,
  modelName: PropTypes.string,
  modelTypeId: PropTypes.string,
  children: PropTypes.element,
  label: PropTypes.string,
  full: PropTypes.bool,
  asHeader: PropTypes.bool,
  displayEndOfWarranty: PropTypes.bool,
};

ProductCard.defaultProps = {
  brandName: '',
  modelName: '',
  modelTypeId: '',
  children: null,
  label: '',
  full: false,
  asHeader: false,
  displayEndOfWarranty: true,
};

export default ProductCard;
