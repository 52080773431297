import React, { FunctionComponent } from 'react';

import { SafeFormattedMessageWithoutSpread } from '../../../../../../formatters';
import {
  $PlaceAutcompleteItem,
  $PlaceAutcompleteLoading,
} from '../../PlacesAutocomplete.styles';

import messages from './messages';

interface PlaceAutocompleteNoResultsProps {
  dataTestId?: string;
}

const PlaceAutocompleteNoResults: FunctionComponent<
  PlaceAutocompleteNoResultsProps
> = ({ dataTestId }) => {
  return (
    <$PlaceAutcompleteItem>
      <$PlaceAutcompleteLoading data-testid={dataTestId}>
        <SafeFormattedMessageWithoutSpread
          message={messages.noOptionsMessage}
        />
      </$PlaceAutcompleteLoading>
    </$PlaceAutcompleteItem>
  );
};

PlaceAutocompleteNoResults.displayName = 'PlaceAutocompleteNoResults';

export default PlaceAutocompleteNoResults;
