import React from 'react';

interface ChatLinesIconProps {
  color?: string;
}

/* eslint-disable max-len */
const ChatLinesIcon: React.FC<ChatLinesIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 16.6667H26.6666M13.3333 23.3333H19.9999M19.9999 36.6667C29.2049 36.6667 36.6666 29.205 36.6666 20C36.6666 10.795 29.2049 3.33333 19.9999 3.33333C10.7949 3.33333 3.33325 10.795 3.33325 20C3.33325 23.035 4.14492 25.8833 5.56325 28.3333L4.16659 35.8333L11.6666 34.4367C14.1992 35.9018 17.0741 36.6711 19.9999 36.6667V36.6667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ChatLinesIcon.displayName = 'ChatLinesIcon';

export default ChatLinesIcon;
