import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const AscIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} viewBox="0 0 500 400" color={color}>
    <path d="M4.702 331.314L84.686 411.316C90.934 417.563 101.069 417.561 107.313 411.316L187.294 331.314C197.364 321.2440000000001 190.193 304 175.98 304H128V-16C128 -24.837 120.837 -32 112 -32H80C71.163 -32 64 -24.837 64 -16V304H16.016C1.775 304 -5.347 321.264 4.702 331.314zM240 352H496C504.837 352 512 359.163 512 368V400C512 408.837 504.837 416 496 416H240C231.163 416 224 408.837 224 400V368C224 359.163 231.163 352 240 352zM224 240V272C224 280.837 231.163 288 240 288H432C440.837 288 448 280.837 448 272V240C448 231.163 440.837 224 432 224H240C231.163 224 224 231.163 224 240zM224 -16V16C224 24.837 231.163 32 240 32H304C312.837 32 320 24.837 320 16V-16C320 -24.837 312.837 -32 304 -32H240C231.163 -32 224 -24.837 224 -16zM224 112V144C224 152.837 231.163 160 240 160H368C376.837 160 384 152.837 384 144V112C384 103.163 376.837 96 368 96H240C231.163 96 224 103.163 224 112z" />
  </Icon>
);

AscIcon.displayName = 'AscIcon';

export default AscIcon;
