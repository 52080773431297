import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import { SafeFormattedMessageWithoutSpread } from '../../formatters';
import { BaseBackResponsePagination } from '@savgroup-front-common/types';

import messages from './messages';
import {
  $ButtonGroupWrapper,
  $ListPaginationButton,
  $ListPaginationInputText,
  $ListPaginationInterval,
  $ListPaginationIntervalOnlyNumber,
  $ListPaginationIntervalWrapper,
  $ListPaginationPage,
  $ListPaginationWrapper,
  $Spacer,
} from './Pagination.styles';
import { Input } from '../Form';
import { ChevronFirstIcon, ChevronLastIcon, ChevronLeftIcon, ChevronRightIcon } from '../../protons/icons';

interface PaginationProps {
  paginationInterval?: number[];
  onSelectInterval?: (interval: number) => void;
  onChangePage: (value: number) => void;
  pagination: BaseBackResponsePagination;
}

const Pagination: FC<PaginationProps> = ({
  onChangePage,
  onSelectInterval,
  pagination,
  paginationInterval,
}) => {
  const [value, setValue] = useState<number>(Number(pagination.page));
  const [pagesNumber, setPagesNumber] = useState<number>(
    Number(pagination.pageCount),
  );
  const formContext = useForm();
  const { control } = formContext;

  useEffect(() => {
    if (pagination.pageCount !== pagesNumber) {
      if (value > pagination.pageCount) {
        setValue(pagination.pageCount);
        onChangePage(pagination.pageCount);
      }
      setPagesNumber(pagination.pageCount);
    }
  }, [pagesNumber, onChangePage, pagination.page, pagination.pageCount, value]);

  const disablePrev = value <= 1;
  const disableNext = value >= pagesNumber;

  const handlePageChange = (page: number) => {
    setValue(page);
    onChangePage(page);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nbr = Number(event.target.value);

    if (nbr === 0) {
      return setValue(0);
    }

    if (value && (nbr < 1 || nbr > pagesNumber)) {
      return event.preventDefault();
    }

    setValue(nbr);

    return onChangePage(nbr);
  };

  return (
    <$ListPaginationWrapper>
      <$ListPaginationIntervalWrapper>
        {paginationInterval &&
          paginationInterval.map((interval) =>
            pagination.total > interval ? (
              <$ListPaginationInterval
                key={interval}
                onClick={() => {
                  if (onSelectInterval) {
                    onSelectInterval(interval);
                  }
                }}
                $isActive={interval === pagination.pageSize}
              >
                {interval}
              </$ListPaginationInterval>
            ) : (
              <></>
            ),
          )}
        {pagination.total <= pagination.pageSize && (
          <$ListPaginationIntervalOnlyNumber>
            {pagination.total}{' '}
          </$ListPaginationIntervalOnlyNumber>
        )}
      </$ListPaginationIntervalWrapper>

      {pagination.total > 1 && (
        <SafeFormattedMessageWithoutSpread
          message={messages.paginationPerPage}
        />
      )}

      {pagination.total <= 1 && (
        <SafeFormattedMessageWithoutSpread
          message={messages.paginationPerPageSingular}
        />
      )}

      <$Spacer />

      {pagesNumber > 1 && (
        <$ListPaginationPage>
          <Controller
            name="paginationValue"
            control={control}
            render={({ field }) => (
              <Input
                name={field.name}
                min={0}
                max={pagination.total}
                isDisabled={pagesNumber === 1}
                onChange={handleChange}
                value={value}
                selectAllOnFocus
              />
            )}
          />

          <$ListPaginationInputText>
            <SafeFormattedMessageWithoutSpread
              message={messages.totalPage}
              values={{ totalPage: pagesNumber }}
            />
          </$ListPaginationInputText>

          <$ButtonGroupWrapper>
            {!disablePrev && (
              <$ListPaginationButton
                type={BUTTON_TYPES.BUTTON}
                small
                hollow
                onClick={() => handlePageChange(1)}
              >
                <ChevronFirstIcon />
              </$ListPaginationButton>
            )}
            <$ListPaginationButton
              type={BUTTON_TYPES.BUTTON}
              small
              hollow
              disabled={disablePrev}
              onClick={() => {
                if (value >= 2) {
                  handlePageChange(value - 1);
                }
              }}
            >
              <ChevronLeftIcon size="20px" />
            </$ListPaginationButton>

            <$ListPaginationButton
              type={BUTTON_TYPES.BUTTON}
              small
              hollow
              disabled={disableNext}
              onClick={() => {
                if (value < pagesNumber) {
                  handlePageChange(value + 1);
                }
              }}
            >
              <ChevronRightIcon size="20px" />
            </$ListPaginationButton>
            {!disableNext && (
              <$ListPaginationButton
                type={BUTTON_TYPES.BUTTON}
                small
                hollow
                onClick={() => handlePageChange(pagesNumber)}
              >
                <ChevronLastIcon />
              </$ListPaginationButton>
            )}
          </$ButtonGroupWrapper>
        </$ListPaginationPage>
      )}
    </$ListPaginationWrapper>
  );
};

Pagination.displayName = 'Pagination';

export default Pagination;
