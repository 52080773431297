import head from 'lodash/head';
import { useDispatch, useSelector } from 'react-redux';

import {
  FILE_STATES,
  fileStatesMap,
  QUOTATION_CLIENT_ANSWER,
} from '@savgroup-front-common/constants';
import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { FileShortSummary, ModuleSummary } from '@savgroup-front-common/types';
import { fileTrackingPageHooks } from 'myaccount/domains/FileTrackingPage';

import { customerState } from '../../../../../domains/Customer/selectors';
import { approveOrRejectQuote } from '../../../../../domains/FileTrackingPage/actionCreators';
import messages from '../messages';
import { FileAction } from 'myaccount/types';

const useHandleValidateQuote = ({
  file,
  quoteId,
  currentModule,
  fromState,
}: {
  file: FileShortSummary;
  quoteId?: string;
  currentModule: ModuleSummary;
  fromState: string;
}) => {
  const dispatch = useDispatch();
  const isSubmitLoading =
    fileTrackingPageHooks.useApproveOrRejectQuoteIsLoading();
  const customer = useSelector(customerState);

  const { pushError } = useBanners({
    extendedMessages: messages,
  });

  const getCurrentUserAction = (): FileAction => {
    const currentActions = customer.file?.currentActions as FileAction[];
    const currentAction = head(currentActions);

    if (!currentAction) {
      throw new Error('components.myaccount.quote.noTransitionFound');
    }

    return currentAction;
  };

  const getTransition = (query = '') => {
    const userAction = getCurrentUserAction();

    return userAction.transitions.find((transition) =>
      transition.targetState.name.includes(query),
    );
  };

  const handleRejectQuotation = ({
    selectedTransition,
  }: {
    selectedTransition: any;
  }) => {
    try {
      const userAction = getTransition(FILE_STATES.QUOTE_VALIDATED);

      if (!userAction || !userAction.targetState) {
        throw new Error('components.myaccount.quote.noTransitionFound');
      }

      if (
        selectedTransition.targetState.name.includes(
          FILE_STATES.REQUESTED_RETURN_QUOTE_REJECTED,
        )
      ) {
        dispatch(
          approveOrRejectQuote({
            answer: QUOTATION_CLIENT_ANSWER.REJECTED_WITH_RETURN,
            targetStateName: selectedTransition.targetState.name,
            fileId: file.fileId,
            comment: undefined,
            quoteId,
            fromState,
            module: currentModule,
          }),
        );
      } else if (
        selectedTransition.targetState.name.includes(
          fileStatesMap.PRODUCT_ABANDON_QUOTE_REJECTED.legacy,
        ) ||
        selectedTransition.targetState.name.includes(
          fileStatesMap.PRODUCT_ABANDON_QUOTE_REJECTED.new,
        )
      ) {
        dispatch(
          approveOrRejectQuote({
            answer: QUOTATION_CLIENT_ANSWER.REJECTED_WITH_DESTRUCTION,
            targetStateName: selectedTransition.targetState.name,
            fileId: file.fileId,
            comment: undefined,
            quoteId,
            fromState,
            module: currentModule,
          }),
        );
      } else {
        dispatch(
          approveOrRejectQuote({
            answer: QUOTATION_CLIENT_ANSWER.REJECTED,
            targetStateName: selectedTransition.targetState.name,
            fileId: file.fileId,
            comment: undefined,
            quoteId,
            fromState,
            module: currentModule,
          }),
        );
      }
    } catch (error: any) {
      pushError(error);
    }
  };

  const handleValidateQuote = () => {
    try {
      const userAction = getTransition(FILE_STATES.QUOTE_VALIDATED);

      if (!userAction || !userAction.targetState) {
        throw new Error('components.myaccount.quote.noTransitionFound');
      }

      dispatch(
        approveOrRejectQuote({
          answer: QUOTATION_CLIENT_ANSWER.APPROVED,
          targetStateName: userAction.targetState.name,
          fileId: file.fileId,
          comment: undefined,
          quoteId,
          fromState,
          module: currentModule,
        }),
      );
    } catch (error: any) {
      pushError(error);
    }
  };

  return { handleValidateQuote, handleRejectQuotation, isSubmitLoading };
};

export default useHandleValidateQuote;
