import { format, parse } from 'date-fns';
import get from 'lodash/get';
import { v4 } from 'uuid';

import {
  STANDARD_DATE_FORMAT_DATE_FNS,
  STANDARD_DATE_TIME_FORMAT_DATE_FNS,
} from '@savgroup-front-common/constants';
import { Timeslot } from '@savgroup-front-common/types';

import { TimeslotFromBack } from '../../../../../api/Workflow/getTechnicalInterventionTimeSlots';
import { DetailedSchedule } from '../../../types/DetailedSchedule';

const adaptTimeslotToDetailedSchedule = (
  rawTimeslots: TimeslotFromBack[],
): DetailedSchedule[] => {
  const groupedTimeslots = rawTimeslots.reduce<{ [date: string]: Timeslot[] }>(
    (acc, rawTimeslot) => {
      const key = format(
        parse(
          rawTimeslot.start,
          STANDARD_DATE_TIME_FORMAT_DATE_FNS,
          new Date(),
        ),
        STANDARD_DATE_FORMAT_DATE_FNS,
      );

      const timeslot = {
        internalId: v4(),
        start: parse(
          rawTimeslot.start,
          STANDARD_DATE_TIME_FORMAT_DATE_FNS,
          new Date(),
        ),
        end: parse(
          rawTimeslot.finish,
          STANDARD_DATE_TIME_FORMAT_DATE_FNS,
          new Date(),
        ),
      };

      return {
        ...acc,
        [key]: [...get(acc, key, []), timeslot],
      };
    },
    {},
  );

  return Object.entries(groupedTimeslots).map(([date, timeslots]) => ({
    date,
    timeslots,
  }));
};

export default adaptTimeslotToDetailedSchedule;
