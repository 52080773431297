import { LOCATION_CHANGE } from 'redux-first-history';

const MAX_HISTORY_LENGTH = 5;
const initialState = [];

export const historyReducer = (state, action) => {
  const { pathname } = action.payload.location;

  return [
    pathname,
    ...state.filter((item, index) => index < MAX_HISTORY_LENGTH - 1),
  ];
};

const historyMainReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      // add history and location
      return historyReducer(state, action);

    default:
      // default state
      return state;
  }
};

export default historyMainReducer;
