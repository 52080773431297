import { createAction } from '@reduxjs/toolkit';

import {
  DOWNLOAD_SHIPPING_LABEL,
  GET_SHIPPING_LABEL,
  LOAD_CARRIERS_PRODUCTS,
  LOAD_EXTERNAL_CARRIERS,
  LOAD_LABELS,
  LOAD_PICKUP_SCHEDULE,
  LOAD_TRANSPORT_METHODS,
} from './actionTypes';

export const loadLabels = createAction<{ fileId: string }>(LOAD_LABELS.BASE);
export const loadExternalCarriers = createAction(LOAD_EXTERNAL_CARRIERS.BASE);
export const loadCarriersProducts = createAction(LOAD_CARRIERS_PRODUCTS.BASE);
export const loadTransportMethods = createAction(LOAD_TRANSPORT_METHODS.BASE);
export const getShippingLabel = createAction<{
  fileId: string;
  moduleDefinitionId: string;
  moduleInstanceId: string;
  trackingUrl: string;
}>(GET_SHIPPING_LABEL.BASE);
export const loadPickupSchedule = createAction<{
  countryCode: string;
  carrier: string;
  startTimeUtc: string;
}>(LOAD_PICKUP_SCHEDULE.BASE);
export const downloadShippingLabel = createAction<{
  fileId: string;
  label: string;
  downloadWindow: Window | null;
}>(DOWNLOAD_SHIPPING_LABEL.BASE);
