import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'view.commentModal.header',
    defaultMessage: 'No panic - we are here to help!',
  },
  message: {
    id: 'view.commentModal.message',
    defaultMessage:
      'We are sorry to hear that something wrong happened with your product. Please explain in a few words what happened and our Customer Service will get back to you within 48 working hours.',
  },
  cancel: {
    id: 'view.commentModal.cancel',
    defaultMessage: 'Cancel',
  },
  submit: {
    id: 'view.commentModal.submit',
    defaultMessage: 'Submit',
  },
  copy: {
    id: 'view.commentModal.copy',
    defaultMessage: 'Send me a copy',
  },
});
