import concat from 'lodash/concat';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';

import { regroupOtherProducts } from '@savgroup-front-common/core/src/helpers';
import { pathname } from '@savgroup-front-common/core/src/domains/selectors';

import { ROUTES } from '../../../view/app/routes/Routes';
import { groupedProductSelector } from '../../Views/groupedProduct';
import { mergedIssuesSelector } from '../../Views/issues';

const ELIGIBLE_PRODUCTS = 'eligibleProducts';
const NOT_ELIGIBLE_PRODUCTS = 'notEligibleProducts';

export const currentIssueIdIdSelector = createSelector([pathname], (path) => {
  const matchedPath = matchPath(path, {
    path: ROUTES.CHOOSE_PRODUCTS,
    exact: false,
    strict: false,
  });

  return get(matchedPath, 'params.issueId');
});

export const currentProductIdsSelector = createSelector(
  [currentIssueIdIdSelector, mergedIssuesSelector],
  (issueId, mergedIssues) => {
    const issue = find(mergedIssues, ({ id }) => id === issueId);

    return get(issue, 'ownerProductIds', []);
  },
);

export const selectCurrentIssue = createSelector(
  [currentIssueIdIdSelector, mergedIssuesSelector],
  (issueId, mergedIssues) => {
    return find(mergedIssues, ({ id }) => id === issueId);
  },
);

export const currentProductsSelector = createSelector(
  [currentProductIdsSelector, groupedProductSelector],
  (
    productIds,
    {
      ViewFile,
      FinalizeClaimGroup,
      FinalizeClaim,
      Others,
      NonEligibleForReturn,
    },
  ) => {
    const { eligibleProducts, notEligibleProducts } = groupBy(
      [...Others, ...NonEligibleForReturn],
      (product) =>
        includes(productIds, product.ownerProductId) &&
        !product.notOpenForClaims
          ? ELIGIBLE_PRODUCTS
          : NOT_ELIGIBLE_PRODUCTS,
    );
    const finalNotEligibleProducts = concat(
      flatMap(ViewFile),
      flatMap(FinalizeClaimGroup),
      FinalizeClaim || [],
      notEligibleProducts || [],
    );

    return {
      eligibleProducts: regroupOtherProducts(eligibleProducts),
      notEligibleProducts: regroupOtherProducts(finalNotEligibleProducts),
    };
  },
);

export const currentEligibleProductsSelector = createSelector(
  [currentProductIdsSelector, groupedProductSelector],
  (productIds, { Others, NonEligibleForReturn }) => {
    const { eligibleProducts } = groupBy(
      [...Others, ...NonEligibleForReturn],
      (product) =>
        productIds?.includes(product.ownerProductId) && product.notOpenForClaims
          ? NOT_ELIGIBLE_PRODUCTS
          : ELIGIBLE_PRODUCTS,
    );

    return eligibleProducts;
  },
);

export const currentNotEligibleProductsSelector = createSelector(
  [currentProductIdsSelector, groupedProductSelector],
  (
    productIds,
    {
      ViewFile,
      FinalizeClaimGroup,
      FinalizeClaim,
      Others,
      NonEligibleForReturn,
    },
  ) => {
    const { notEligibleProducts } = groupBy(
      [...Others, ...NonEligibleForReturn],
      (product) =>
        productIds?.includes(product.ownerProductId) && product.notOpenForClaims
          ? NOT_ELIGIBLE_PRODUCTS
          : ELIGIBLE_PRODUCTS,
    );
    const finalNotEligibleProducts = flatMap(ViewFile).concat(
      flatMap(FinalizeClaimGroup),
      FinalizeClaim || [],
      notEligibleProducts || [],
    );

    return finalNotEligibleProducts;
  },
);
