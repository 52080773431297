import PropTypes from 'prop-types';
import React from 'react';

import FullOrderProductInfo from './FullOrderProductInfo';
import MinimalOrderProductInfo from './MinimalOrderProductInfo';

const OrderProductInfo = ({ minimal, ...props }) => {
  return minimal ? (
    <MinimalOrderProductInfo {...props} />
  ) : (
    <FullOrderProductInfo {...props} />
  );
};

OrderProductInfo.propTypes = {
  modelName: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  fileDetailsRoute: PropTypes.string,
  endOfWarranty: PropTypes.string,
  purchaseDate: PropTypes.string,
  minimal: PropTypes.bool,
  assets: PropTypes.shape({
    imagePath: PropTypes.string,
    imageID: PropTypes.string,
    fileIconPath: PropTypes.string,
    endOfWarranty: PropTypes.string,
    spinnerPath: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

OrderProductInfo.defaultProps = {
  assets: {
    imagePath: '/images/catalog/modelTypes/built/##ID##.svg',
    imageID: '##ID##',
    fileIconPath: '/images/icon_facture_jaune@2x.png',
    endOfWarranty: '/images/icon_findegarantie@1x.png',
    spinnerPath: '/images/spinner.gif',
  },
  fileDetailsRoute: '',
  minimal: false,
  purchaseDate: '',
  endOfWarranty: '',
  onClick: () => {},
};

export default OrderProductInfo;
