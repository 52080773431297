import { defineMessages } from 'react-intl';

export default defineMessages({
  recaptchaUserFailure: {
    id: 'global.recaptcha.responseType.userFailure',
    defaultMessage: 'ReCAPTCHA error',
  },
  recaptchaNotActive: {
    id: 'global.recaptcha.responseType.notActive',
    defaultMessage: 'ReCAPTCHA is not active',
  },
  recaptchaSucess: {
    id: 'global.recaptcha.responseType.success',
    defaultMessage: 'ReCAPTCHA success',
  },
});
