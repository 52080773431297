import { defineMessages } from 'react-intl';

export default defineMessages({
  en: {
    id: 'components.myaccount.layout.header.languageChooser.en',
    defaultMessage: 'English',
  },
  fr: {
    id: 'components.myaccount.layout.header.languageChooser.fr',
    defaultMessage: 'Français',
  },
  es: {
    id: 'components.myaccount.layout.header.languageChooser.es',
    defaultMessage: 'Español',
  },
  de: {
    id: 'components.myaccount.layout.header.languageChooser.de',
    defaultMessage: 'Deutsch',
  },
  it: {
    id: 'components.myaccount.layout.header.languageChooser.it',
    defaultMessage: 'Italian',
  },
  pt: {
    id: 'components.myaccount.layout.header.languageChooser.pt',
    defaultMessage: 'Portuguese',
  },
  nl: {
    id: 'components.myaccount.layout.header.languageChooser.nl',
    defaultMessage: 'Dutch',
  },
  title: {
    id: 'components.myaccount.layout.header.languageChooser.title',
    defaultMessage: 'Change language',
  },
});
