import sha1 from 'js-sha1';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  SERVICES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { arrayBufferFromBlob, getFileType } from '../../helpers';
import { callAndGetResponse, reduxExtendedRequestSaga } from '../../services';
import { accessTokenSelector } from '../selectors';

import { selectLocale } from '../i18n/selectors';

import {
  DELETE_ATTACHMENT_FROM_FILE,
  GET_ALL_DOCUMENTS_STATUS_BY_FILE_ID,
  GET_DOCUMENTS_BY_CLAIM_ID,
  GET_DOCUMENTS_STATUS_BY_FILE_ID,
  LOAD_DOCUMENT_BY_URL,
  LOAD_FILES_ATTACHMENT_LIST,
  SET_FILE_ATTACHMENT_INFO,
  UPLOAD_FILE_ATTACHMENT_DATA,
} from './actionTypes';
import {
  attachmentsOrigins,
  attachmentsListOrigins as attachmentsProps,
} from './constants';
import { selectLoadDocumentByUrlWasLoaded } from './selectors';

const { EXTERNAL_CARRIER_LABEL_ATTACHMENT } = attachmentsOrigins;

function getLoadFilesAttachmentListApiUrl({ fileId, type }) {
  switch (type) {
    case attachmentsProps.CONTROL:
      return `${APIConfiguration.workflow}files/${fileId}/control-attachments`;
    case attachmentsProps.MYACCOUNT:
    default:
      return `${APIConfiguration.workflow}files/${fileId}/myaccount-attachments`;
  }
}

export function* loadDocumentByUrlWorker({ payload: { url, fileName } }) {
  if (!fileName || !url) {
    return;
  }

  const wasLoaded = yield select((state) =>
    selectLoadDocumentByUrlWasLoaded(state, { indexer: url }),
  );

  if (wasLoaded) {
    yield put(LOAD_DOCUMENT_BY_URL.end());

    return;
  }

  yield call(
    callAndGetResponse,
    LOAD_DOCUMENT_BY_URL,
    url,
    {
      responseType: 'blob',
      method: SUPPORTED_METHODS.GET,
    },
    {
      indexer: url,
      attachmentId: url,
      filename: fileName,
    },
  );
  yield put(LOAD_DOCUMENT_BY_URL.end({ indexer: url }));
}
function* loadDocumentByUrlWatcher() {
  yield takeEvery(LOAD_DOCUMENT_BY_URL.BASE, loadDocumentByUrlWorker);
}

export function* loadFilesAttachmentListWorker({ payload = {} }) {
  const { fileId, type } = payload;
  const apiUrl = getLoadFilesAttachmentListApiUrl({ fileId, type });

  yield call(
    callAndGetResponse,
    LOAD_FILES_ATTACHMENT_LIST,
    apiUrl,
    { method: SUPPORTED_METHODS.GET },
    { fileId, type },
  );

  yield put(LOAD_FILES_ATTACHMENT_LIST.end(fileId));
}
export function* loadFilesAttachmentListWatcher() {
  yield takeEvery(
    LOAD_FILES_ATTACHMENT_LIST.BASE,
    loadFilesAttachmentListWorker,
  );
}

function getUploadAttachmentApiUrl({ type, fileId, service }) {
  if (type === EXTERNAL_CARRIER_LABEL_ATTACHMENT) {
    return `${APIConfiguration.carrier}externalCarrierDepositLabels/data`;
  }

  return `${APIConfiguration.document}files/${fileId}/${service}-attachments`;
}
function* uploadFileAttachmentDataWorker({ payload }) {
  const { type, file, fileId, service, fileButtonOrigin } = payload;
  const accessToken = yield select(accessTokenSelector);
  const language = yield select(selectLocale);
  const fileArrayBuffer = yield call(arrayBufferFromBlob, file);
  const internalId = sha1(fileArrayBuffer);
  const uint8 = new Uint8Array(fileArrayBuffer);
  const fileType = getFileType(uint8);

  const fileToUpload = fileType
    ? new File(
        [fileArrayBuffer],
        fileType.ext
          ? file.name.replace(/(\.[^/.]*)?$/, `.${fileType.ext}`)
          : file.name,
        {
          type: fileType.mime || file.type,
        },
      )
    : file;

  const mimeType = fileToUpload.type;

  const body = new FormData();

  body.append('file', fileToUpload);
  const meta = {
    type,
    id: internalId,
    extension: mimeType.substring(mimeType.indexOf('/') + 1),
    fileName: fileToUpload.name,
    mimeType: fileToUpload.type,
    fileId,
    file,
    fileButtonOrigin,
  };

  yield call(
    reduxExtendedRequestSaga,
    UPLOAD_FILE_ATTACHMENT_DATA,
    getUploadAttachmentApiUrl({ type, fileId, service }),
    {
      method: SUPPORTED_METHODS.POST,
      body,
      accessToken,
      language,
    },
    meta,
  );

  yield put(UPLOAD_FILE_ATTACHMENT_DATA.end(meta));
}
function* uploadFileAttachmentDataWatcher() {
  yield takeEvery(
    UPLOAD_FILE_ATTACHMENT_DATA.BASE,
    uploadFileAttachmentDataWorker,
  );
}

function getDeleteAttachmentApiUrl({ type, fileId, fileAttachmentId, env }) {
  if (type === EXTERNAL_CARRIER_LABEL_ATTACHMENT) {
    return `${APIConfiguration.carrier}externalCarrierDepositLabels/${fileAttachmentId}`;
  }

  return `${APIConfiguration.document}files/${fileId}/${env}-attachments/${fileAttachmentId}`;
}
function* deleteFileAttachmentDataWorker({ payload }) {
  const {
    fileId,
    fileAttachmentId,
    internalId,
    service,
    type = null,
  } = payload;
  const env = service || SERVICES.MYACCOUNT;

  if (!fileAttachmentId) {
    yield put(DELETE_ATTACHMENT_FROM_FILE.end());

    return;
  }

  yield call(
    callAndGetResponse,
    DELETE_ATTACHMENT_FROM_FILE,
    getDeleteAttachmentApiUrl({ type, fileId, fileAttachmentId, env }),
    {
      method: SUPPORTED_METHODS.DELETE,
    },
    { fileId, internalId },
  );

  yield put(DELETE_ATTACHMENT_FROM_FILE.end());
}
function* deleteFileAttachmentDataWatcher() {
  yield takeEvery(
    DELETE_ATTACHMENT_FROM_FILE.BASE,
    deleteFileAttachmentDataWorker,
  );
}

function* setFileAttachmentInfoWorker({
  payload: {
    fileId,
    extension,
    name,
    fileAttachmentId,
    service,
    transitionInfo,
    type,
  },
}) {
  if (type === EXTERNAL_CARRIER_LABEL_ATTACHMENT) {
    yield callAndGetResponse(
      SET_FILE_ATTACHMENT_INFO,
      `${APIConfiguration.workflow}files/${fileId}/externalCarrierDepositLabels`,
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          moduleDefinitionId: transitionInfo.moduleDefinitionId,
          moduleInstanceId: transitionInfo.moduleId,
          wave: transitionInfo.wave,
          attachmentId: fileAttachmentId,
        },
      },
      {
        fileId,
        fileAttachmentId,
      },
    );
  } else {
    yield callAndGetResponse(
      SET_FILE_ATTACHMENT_INFO,
      `${APIConfiguration.workflow}files/${fileId}/${service}-attachments`,
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          fileAttachmentId,
          name,
          extension,
          transitionInfo,
        },
      },
      {
        fileId,
        fileAttachmentId,
      },
    );
  }

  yield put(SET_FILE_ATTACHMENT_INFO.end());
}
function* setFileAttachmentInfoWatcher() {
  yield takeEvery(SET_FILE_ATTACHMENT_INFO.BASE, setFileAttachmentInfoWorker);
}

function* getDocumentsStatusByFileIdWorker({ payload: { fileId, type } }) {
  yield call(
    callAndGetResponse,
    GET_DOCUMENTS_STATUS_BY_FILE_ID,
    `${APIConfiguration.document}files/${fileId}/documents/${type}/status`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      fileId,
      type,
      indexer: fileId,
    },
  );
  yield put(GET_DOCUMENTS_STATUS_BY_FILE_ID.end());
}
function* getDocumentsStatusByFileIdWatcher() {
  yield takeEvery(
    GET_DOCUMENTS_STATUS_BY_FILE_ID.BASE,
    getDocumentsStatusByFileIdWorker,
  );
}

function* getAllDocumentsStatusByFileIdWorker({ payload: { fileId, type } }) {
  yield call(
    callAndGetResponse,
    GET_ALL_DOCUMENTS_STATUS_BY_FILE_ID,
    `${APIConfiguration.document}files/${fileId}/documents/status`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      fileId,
      type,
      indexer: fileId,
    },
  );
  yield put(GET_ALL_DOCUMENTS_STATUS_BY_FILE_ID.end());
}
function* getAllDocumentsStatusByFileIdWatcher() {
  yield takeEvery(
    GET_ALL_DOCUMENTS_STATUS_BY_FILE_ID.BASE,
    getAllDocumentsStatusByFileIdWorker,
  );
}

function* getDocumentsByClaimIdWorker({ payload }) {
  const { claimId } = payload;
  const meta = { claimId, indexer: claimId };

  yield call(
    callAndGetResponse,
    GET_DOCUMENTS_BY_CLAIM_ID,
    `${APIConfiguration.claim}claims/${claimId}/documents`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    meta,
  );

  yield put(GET_DOCUMENTS_BY_CLAIM_ID.end(meta));
}
function* getDocumentsByClaimIdWatcher() {
  yield takeLatest(GET_DOCUMENTS_BY_CLAIM_ID.BASE, getDocumentsByClaimIdWorker);
}

export default function* mainSaga() {
  try {
    yield all([
      loadFilesAttachmentListWatcher(),
      setFileAttachmentInfoWatcher(),
      uploadFileAttachmentDataWatcher(),
      loadDocumentByUrlWatcher(),
      getDocumentsStatusByFileIdWatcher(),
      getAllDocumentsStatusByFileIdWatcher(),
      deleteFileAttachmentDataWatcher(),
      getDocumentsByClaimIdWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
