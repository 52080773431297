import { useContext } from 'react';

import { useGetSellerConfiguration } from 'myaccount/view/app/hooks';
import { useGetOpenedInvoiceWithClaimGroup } from 'myaccount/view/app/hooks/useGetOpenedInvoiceWithClaimGroup';
import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

interface UsePaymentCardArgs {
  sellerId: string;
}

const usePaymentCard = ({ sellerId }: UsePaymentCardArgs) => {
  const { values } = useContext<StepContextValues>(StepContext);
  const claimGroupId = values?.claimGroup?.claimGroupId;
  const { sellerConfiguration } = useGetSellerConfiguration({ sellerId });

  const { invoice } = useGetOpenedInvoiceWithClaimGroup({
    claimGroupId,
  });

  return {
    isCashRegisterConnected: sellerConfiguration?.isCashRegisterConnected,
    paymentUrl: sellerConfiguration?.paymentUrl,
    invoice,
  };
};

export default usePaymentCard;
