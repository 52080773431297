import React, { FunctionComponent } from 'react';

import { $Tag } from './Tag.styles';
import { FEATURE_FLAGS } from '@savgroup-front-common/constants/src/shared/featureFlags';
import useIsFeatureEnabled from '../../components/FeatureManager/hooks/useIsFeatureEnabled';

interface TagProps {
  noEllispis?: boolean;
  disabled?: boolean;
  danger?: boolean;
  neutral?: boolean;
  alert?: boolean;
  success?: boolean;
  primary?: boolean;
  secondary?: boolean;
  withoutMargin?: boolean;
  fluid?: boolean;
  dataTestId?: string;
  isNaked?: boolean;
  isSmall?: boolean;
}

const Tag: FunctionComponent<React.PropsWithChildren<TagProps>> = ({
  children,
  disabled = false,
  danger = false,
  alert = false,
  success = false,
  primary = false,
  secondary = false,
  neutral = false,
  withoutMargin = false,
  fluid = false,
  dataTestId = 'tag',
  noEllispis = false,
  isNaked = false,
  isSmall = false,
}) => {
  const isNewBoEnabled = useIsFeatureEnabled(FEATURE_FLAGS.BO_NEW_UI);

  return (
    <$Tag
      disabled={disabled}
      neutral={neutral}
      danger={danger}
      alert={alert}
      success={success}
      primary={primary}
      secondary={secondary}
      withoutMargin={withoutMargin}
      data-testid={dataTestId}
      $noEllispis={noEllispis}
      $fluid={fluid}
      $isNaked={isNaked}
      $isSmall={isSmall}
      $isNewBoEnabled={isNewBoEnabled}
    >
      {children}
    </$Tag>
  );
};

Tag.displayName = 'Tag';

export default Tag;
