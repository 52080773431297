import get from 'lodash/get';

export const formatVerifiedAddress = (verifiedAddress, deliveryAddress) => ({
  ...deliveryAddress,
  city: get(verifiedAddress, 'city', get(deliveryAddress, 'city')),
  postalCode: get(
    verifiedAddress,
    'zipCode',
    get(deliveryAddress, 'postalCode'),
  ),
  civility: get(verifiedAddress, 'civility', get(deliveryAddress, 'civility')),
  countryCode: get(
    verifiedAddress,
    'countryCode',
    get(deliveryAddress, 'countryCode'),
  ),
  firstName: get(
    verifiedAddress,
    'contactFirstName',
    get(deliveryAddress, 'firstName'),
  ),
  lastName: get(
    verifiedAddress,
    'contactLastName',
    get(deliveryAddress, 'lastName'),
  ),
  label: get(verifiedAddress, 'name1', get(deliveryAddress, 'label')),
  address: get(verifiedAddress, 'street1', get(deliveryAddress, 'address')),
  phone: get(verifiedAddress, 'phone', get(deliveryAddress, 'phone')),
  additionalAddress: get(
    verifiedAddress,
    'street2',
    get(deliveryAddress, 'additionalAddress'),
  ),
});

export const formatAddress = (address, countryList) => ({
  civility: get(address, 'civility'),
  name1: get(address, 'label'),
  name2: '',
  countryCode: get(address, 'countryCode'),
  street1: get(address, 'address'),
  street2: get(address, 'additionalAddress'),
  zipCode: get(address, 'postalCode'),
  state: '',
  city: get(address, 'city'),
  country: get(
    countryList.find((country) => country.key === get(address, 'countryCode')),
    'text',
  ),
  phone: get(address, 'phone'),
  contactLastName: get(address, 'lastName'),
  contactFirstName: get(address, 'firstName'),
  additionalInformation: get(address, 'additionalInformation'),
  companyName: get(address, 'companyName'),
  hasElevator: address?.hasElevator?.value === 'true',
  hasParkingSpace: address?.hasParkingSpace?.value === 'true',
  housingType: address?.housingType?.value,
  doorCode: address?.doorCode,
  floor: address?.floor,
  email: '',
});
