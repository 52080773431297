/* eslint-disable no-prototype-builtins */
import { yupResolver } from '@hookform/resolvers/yup';
import '@stripe/stripe-js';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SPECIFIC_ERROR_CODES } from '@savgroup-front-common/constants';
import { PAYMENT_BEHAVIOUR } from '@savgroup-front-common/constants/src/shared';
import AddressInfoShape from '@savgroup-front-common/core/src/components/common/propTypesShapes/addressInfoShape';
import { useSelectActionError } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata';
import { selectSellerConfigBySellerId } from '@savgroup-front-common/core/src/domains/sellerConfiguration/selectors';
import {
  formatPhoneNumber,
  SafeFormattedMessage,
} from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { createInvoiceForClaimGroup } from 'myaccount/domains/payments/actionCreators';

import InvoicePaymentForm from '../../../../components/InvoicePaymentForm/InvoicePaymentForm';
import InvoiceSummary from '../../../../components/InvoiceSummary';
import ProductIRSSummaryCardsGroup from '../../../../components/ProductIRSSummaryCardsGroup';
import TransportDeduction from '../../../../components/TransportDeduction/TransportDeduction';

import AgreementCheckbox from './AgreementCheckbox';
import {
  CONFIRMATION_FIELDS,
  useFormatStoreActorOptions,
} from './Confirmation.helpers';
import schema from './Confirmation.schema';
import { InfoTitle, SubHeader } from './Confirmation.styles';
import ConfirmationButton from './ConfirmationButton/ConfirmationButton';
import { $AgreementRow } from './ConfirmationButton/ConfirmationButton.styles';
import DeliveryAddress from './DeliveryAddress';
import DepositAddress from './DepositAddress';
import messages from './messages';
import OnSiteInterventionAddress from './OnSiteInterventionAddress';
import ConfirmationStoreActor from './StoreActor';
import ConfirmationUserFields from './UserFields';

const MY_ACCOUNT = 'MyAccount';

const Confirmation = ({
  confirmationElements,
  assets,
  loginInfo,
  isLoading,
  resetPaymentFormErrors,
  translatedCarrirerMethodNames,
  products,
  goToEditionPage,
  confirmationLoadingStatus,
  confirmationErrors,
  getClaimHandlingLink,
  fileIsLoading,
  sellerId,
  confirmationDatas,
  onValidate,
  storeActorOptions,
  claimGroupId,
}) => {
  const { email, countryCode } = loginInfo;
  const {
    deliveryAddress,
    depositAddress,
    onSiteInterventionAddress,
    solutionGroupType,
    carrierDepositPriceWithTax,
    carrierDeliveryPriceWithTax,
    invoice,
    invoiceLoading,
    solutionPriceCurrency,
    paymentBehavior,
  } = confirmationDatas;
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);

  const [options, defaultOption] = useFormatStoreActorOptions({
    storeActorOptions,
  });

  const formContext = useForm({
    shouldUnregister: true,

    defaultValues: {
      [CONFIRMATION_FIELDS.PHONE]:
        formatPhoneNumber(
          loginInfo.phone || get(loginInfo.addresses, [0, 'phone']),
          loginInfo.countryCode,
        ) || '',
      [CONFIRMATION_FIELDS.MAIL]: email || '',
    },
    resolver: yupResolver(
      schema({ countryCode, hasStoreActor: options.length > 0 }),
    ),
  });

  const sellerConfig = useSelector((state) =>
    selectSellerConfigBySellerId(state, { sellerId }),
  );

  const { getValues, trigger } = formContext;

  const handleValidation = async (sourceTokenId = undefined) => {
    const isValid = await trigger();
    const values = getValues();

    if (isValid) {
      onValidate({
        claimGroupId: confirmationDatas.claimGroupId,
        phoneNumber: formatPhoneNumber(
          values[CONFIRMATION_FIELDS.PHONE],
          loginInfo.countryCode,
        ),
        storeActorId: get(
          values,
          [CONFIRMATION_FIELDS.STORE_ACTOR, 'value'],
          undefined,
        ),
        swornStatement: undefined,
        comment: '',
        IMEI: '',
        mail: values[CONFIRMATION_FIELDS.MAIL],
        serialNumber: '',
        generalSalesConditions: true,
        invoiceId: invoice?.id,
        sourceTokenId,
        origin:
          (window.localStorage.getItem('LoggedInFrom') || '').replaceAll(
            '"',
            '',
          ) || MY_ACCOUNT,
        ticketId: undefined,
      });
    }
  };

  const errors = useSelectActionError({
    action: createInvoiceForClaimGroup.toString(),
    indexer: confirmationDatas.claimGroupId,
  });

  const isInvoiceAlreadyPaid = errors.some(
    (error) => error.code === SPECIFIC_ERROR_CODES.INVOICE_ALREADY_PAID,
  );

  if (isLoading) {
    return <BaseLoader isFluid />;
  }

  return (
    <>
      <ProductIRSSummaryCardsGroup
        products={products}
        goToEditionPage={goToEditionPage}
        claimGroupId={claimGroupId}
      />

      {solutionGroupType !== 'Refund' && Boolean(depositAddress) && (
        <DepositAddress
          depositAddress={depositAddress}
          assets={assets}
          getClaimHandlingLink={getClaimHandlingLink}
          carrierDepositPriceWithTax={carrierDepositPriceWithTax}
          translatedCarrirerMethodNames={translatedCarrirerMethodNames}
        />
      )}
      {solutionGroupType !== 'Refund' && Boolean(deliveryAddress) && (
        <DeliveryAddress
          deliveryAddress={deliveryAddress}
          assets={assets}
          getClaimHandlingLink={getClaimHandlingLink}
          carrierDeliveryPriceWithTax={carrierDeliveryPriceWithTax}
          translatedCarrirerMethodNames={translatedCarrirerMethodNames}
        />
      )}
      {solutionGroupType !== 'Refund' && Boolean(onSiteInterventionAddress) && (
        <OnSiteInterventionAddress
          onSiteInterventionAddress={onSiteInterventionAddress}
        />
      )}

      {options.length > 0 && (
        <ConfirmationStoreActor
          formContext={formContext}
          options={options}
          defaultValue={defaultOption}
        />
      )}

      <InfoTitle heading>
        <SafeFormattedMessage {...messages.contactInformation} />
      </InfoTitle>
      <SubHeader paragraph>
        <SafeFormattedMessage {...messages.FollowUpSubHeader} />
      </SubHeader>

      {confirmationElements.hasOwnProperty('followUp') ? (
        <ConfirmationUserFields
          formContext={formContext}
          countryCode={countryCode}
        />
      ) : null}

      {!isInvoiceAlreadyPaid &&
        paymentBehavior !== PAYMENT_BEHAVIOUR.NO_PAYMENT && (
          <>
            <InfoTitle heading>
              <SafeFormattedMessage {...messages.payment} />
            </InfoTitle>
            {invoiceLoading && <BaseLoader messageList={[messages.invoice]} />}
            {!invoiceLoading && (
              <>
                {paymentBehavior ===
                  PAYMENT_BEHAVIOUR.PAYMENT_DEDUCTED_FROM_REFUND && (
                  <>
                    <InvoiceSummary {...invoice} />
                    <TransportDeduction
                      transportDeduction={get(invoice, 'totalPriceWithTax')}
                      currency={solutionPriceCurrency}
                    />
                    <ConfirmationButton
                      isLoading={confirmationLoadingStatus || fileIsLoading}
                      onValid={handleValidation}
                      sellerId={sellerId}
                    />
                  </>
                )}

                {paymentBehavior === PAYMENT_BEHAVIOUR.PAYMENT_REQUIRED && (
                  <InvoicePaymentForm
                    invoice={invoice}
                    claimGroupId={confirmationDatas?.claimGroupId}
                    onSubmit={() => handleValidation()}
                    resetPaymentFormErrors={resetPaymentFormErrors}
                    additionalErrors={confirmationErrors}
                    isDisabled={!isAgreementChecked}
                  >
                    <$AgreementRow data-term-checkbox>
                      <AgreementCheckbox
                        agreementHref={
                          sellerConfig?.cgvUrl ||
                          `${APIConfiguration.catalogCDN}miscellaneous/CGU%20Revers.io%20-%20Client%20final_fr.pdf`
                        }
                        isChecked={isAgreementChecked}
                        onChange={() =>
                          setIsAgreementChecked(!isAgreementChecked)
                        }
                      />
                    </$AgreementRow>
                  </InvoicePaymentForm>
                )}
              </>
            )}
          </>
        )}

      {(paymentBehavior === PAYMENT_BEHAVIOUR.NO_PAYMENT ||
        isInvoiceAlreadyPaid) && (
        <ConfirmationButton
          isLoading={confirmationLoadingStatus || fileIsLoading}
          onValid={handleValidation}
          sellerId={sellerId}
        />
      )}
    </>
  );
};

Confirmation.propTypes = {
  confirmationElements: PropTypes.shape({}).isRequired,
  isRunning: PropTypes.bool,
  onValidate: PropTypes.func,
  confirmationDatas: PropTypes.shape({
    issueName: PropTypes.string,
    reasonName: PropTypes.string,
    deliveryAddress: PropTypes.shape(AddressInfoShape),
    depositAddress: PropTypes.shape(AddressInfoShape),
    onSiteInterventionAddress: PropTypes.shape(AddressInfoShape),
    carrierDepositPrice: PropTypes.number,
    carrierDeliveryPrice: PropTypes.number,
    solutionPrice: PropTypes.number,
    solutionTypeName: PropTypes.string,
    solutionGroupType: PropTypes.string,
    solutionGroupId: PropTypes.string,
  }).isRequired,
  loginInfo: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  assets: PropTypes.shape({
    depositImagePath: PropTypes.string,
    deliveryImagePath: PropTypes.string,
    tootipImage: PropTypes.string,
  }),
  resetPaymentFormErrors: PropTypes.func.isRequired,
  translatedCarrirerMethodNames: PropTypes.shape({}).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  goToEditionPage: PropTypes.func.isRequired,
  confirmationLoadingStatus: PropTypes.bool,
  confirmationErrors: PropTypes.arrayOf(PropTypes.shape({})),
  getClaimHandlingLink: PropTypes.func.isRequired,
  fileIsLoading: PropTypes.bool.isRequired,
  storeActorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      selectedStoreActorId: PropTypes.string.isRequired,
      selectedStoreActorName: PropTypes.string.isRequired,
      storesActor: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    }).isRequired,
  ),
};

Confirmation.defaultProps = {
  isRunning: false,
  onValidate: null,
  assets: {
    tooltipImage: '/images/blue-question-mark.svg',
  },
  confirmationLoadingStatus: null,
  confirmationErrors: null,
  storeActorOptions: [],
};

Confirmation.displayName = 'Confirmation';

export default Confirmation;
