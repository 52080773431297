import get from 'lodash/get';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  CARRIER_PRODUCT_TYPE,
  HANDLING_MODES,
} from '@savgroup-front-common/constants';
import { selectHandlingForClaimGroup } from '@savgroup-front-common/core/src/domains/claims/actionCreators';

import { HandlingStepsValue } from './ClaimGroupHandling.types';

export const useClaimGroupHandling = ({
  claimGroupId,
}: {
  claimGroupId: string;
}) => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    ({
      selectedCarrierType,
      handlingMode,
      selectedCarrier,
      selectedPickupPoint,
      address,
    }: HandlingStepsValue) => {
      const payload = {
        claimGroupId,
        handlingMode:
          selectedCarrierType === CARRIER_PRODUCT_TYPE.HOME_INTERVENTION
            ? HANDLING_MODES.HOME
            : handlingMode,
        pickupPointId: get(selectedPickupPoint, 'id'),
        pickupPointAddress: selectedPickupPoint
          ? {
              label: selectedPickupPoint.name,
              address: selectedPickupPoint.adress,
              additionalAddress: selectedPickupPoint.adress3,
              postalCode: selectedPickupPoint.postalCode,
              city: selectedPickupPoint.city,
              countryCode: selectedPickupPoint.countryCode,
            }
          : null,
        carrierPriceId: {
          carrierSellerPriceId: selectedCarrier.carrierSellerPriceId,
          carrierCustomerPriceId: selectedCarrier.carrierCustomerPriceId,
        },
        address,
      };

      logVerbose(
        new Error(
          `selectHandlingForClaimGroup: [${claimGroupId}], handlingMode: ${
            selectedCarrierType === CARRIER_PRODUCT_TYPE.HOME_INTERVENTION
              ? HANDLING_MODES.HOME
              : handlingMode
          }`,
        ),
        {
          payload,
        },
      );

      dispatch(selectHandlingForClaimGroup(payload));
    },
    [claimGroupId, dispatch],
  );

  return {
    handleSubmit,
  };
};
