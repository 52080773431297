import styled from 'styled-components';

import { H3, H4 } from '@savgroup-front-common/core/src/atoms/headings';

export const InfoTitle = styled(H3)`
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 16px;
  line-height: 24px;
`;

export const SubHeader = styled(H4)`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  line-height: 20px;
`;
