import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import {
  BUTTON_TYPES,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants/src/shared';

import { Button } from '../../atoms/button';
import { Banner } from '../../atoms/Banners';
import { FormGroup } from '../../atoms/Form';

import { SafeFormattedMessage } from '../../formatters';
import { formContextProps } from '../../helpers/proptypes';
import { TextareaHookForm } from '../../molecules/Form';

import useHandleDeadEndReportSubmit from './hooks/useHandleDeadEndReportSubmit';
import messages from './messages';
import { LabelNoSolutionReportStyled } from './NoSolutionReport.styles';

const FIELDS = {
  MESSAGE: 'message',
};

const NoSolutionReportForm = ({
  orderId,
  groupId,
  claimIds,
  formContext,
  onReportSubmit,
  isReportSent,
  isErrored,
  successMessage,
  errorMessage,
  isLoading,
  customSendNoSolutionReportLabel,
}) => {
  const { handleSubmit, register } = formContext;

  const defaultMessageSent =
    isReportSent && !successMessage ? messages.isMessageSentNotification : null;
  const defaultMessageErrored =
    isErrored && !errorMessage ? messages.isMessageErroredNotification : null;

  const handleNoSolutionReportFormSubmit = useHandleDeadEndReportSubmit({
    groupId,
    claimIds,
    orderId,
    onReportSubmit,
  });

  const successNotificationMessage = successMessage || defaultMessageSent;
  const errorNotificationMessage = errorMessage || defaultMessageErrored;

  return (
    <form onSubmit={handleSubmit(handleNoSolutionReportFormSubmit)}>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <LabelNoSolutionReportStyled heading>
              {SafeFormattedMessage(
                customSendNoSolutionReportLabel ||
                  messages.sendNoSolutionReportLabel,
              )}
            </LabelNoSolutionReportStyled>
          </FormGroup>
        </Col>
        <Col xs={12}>
          <Banner
            notificationType={NOTIFICATION_TYPES.SUCCESS}
            isOpen={successMessage || defaultMessageSent}
          >
            <SafeFormattedMessage {...successNotificationMessage} />
          </Banner>
          <Banner
            notificationType={NOTIFICATION_TYPES.ERROR}
            isOpen={errorMessage || defaultMessageErrored}
          >
            <SafeFormattedMessage {...errorNotificationMessage} />
          </Banner>
        </Col>
        {!isReportSent && (
          <Col xs={12}>
            <FormGroup>
              <TextareaHookForm
                dataTestId="messageInput"
                name={FIELDS.MESSAGE}
                placeholder={messages.messagePlaceholder}
                label={messages.messageLabel}
                register={register}
              />
            </FormGroup>
            <Row reverse>
              <Button
                primary
                dataTestId="noSolutionReportButton"
                type={BUTTON_TYPES.SUBMIT}
                isLoading={isLoading}
              >
                {SafeFormattedMessage(messages.sendReportButton)}
              </Button>
            </Row>
          </Col>
        )}
      </Row>
    </form>
  );
};

NoSolutionReportForm.displayName = 'NoSolutionReportForm';
NoSolutionReportForm.propTypes = {
  formContext: formContextProps.isRequired,
  onReportSubmit: PropTypes.func,
  isReportSent: PropTypes.bool,
  isErrored: PropTypes.bool,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  customSendNoSolutionReportLabel: PropTypes.shape({}),
  orderId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  claimIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};
NoSolutionReportForm.defaultProps = {
  isReportSent: false,
  onReportSubmit: undefined,
  isErrored: false,
  successMessage: null,
  errorMessage: null,
  isLoading: false,
  customSendNoSolutionReportLabel: null,
};

export default NoSolutionReportForm;
