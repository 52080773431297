import React, { FunctionComponent } from 'react';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { customerState } from 'myaccount/domains/Customer/selectors';
import { useMyAccountTypedSelector } from 'myaccount/hooks';

import { ClientAction } from '../../../../components/ClientAction';
import FileHistory from '../FileHistory';

const FileStatus: FunctionComponent = () => {
  const customer = useMyAccountTypedSelector(customerState);
  const hasAtLeastOneAction = (customer?.availableFileActions || []).length > 0;

  return hasAtLeastOneAction ? (
    <>
      <ClientAction />
      <FileHistory />
    </>
  ) : (
    <BaseLoader />
  );
};

FileStatus.displayName = 'FileStatus';

export default FileStatus;
