import { createSelector } from 'reselect';

const routerImmutableState = (state: any) => state.router;

export const immutableLocation = createSelector(
  [routerImmutableState],
  (router) => router.get('location'),
);

export const immutableHistory = createSelector(
  [routerImmutableState],
  (router) => router.get('history'),
);

export const immutablePathname = createSelector(
  [immutableLocation],
  (currentLocation) => currentLocation.get('pathname'),
);

export const immutableSearch = createSelector(
  [immutableLocation],
  (currentLocation) => currentLocation.get('search'),
);
