import { fromJS } from 'immutable';
import find from 'lodash/find';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { createSelector } from 'reselect';

import { Selectors as CarriersSelectors } from '@savgroup-front-common/core/src/domains/carriers';
import { Selectors as WorkflowSelectors } from '@savgroup-front-common/core/src/domains/workflow';

import { productsInfoSelector } from '../Orders/selectors';

import { currentFileIdSelector } from './currentFileId';

export const customerState = (state) => state.customer;

const claimConfirmationSelector = (state) =>
  get(state, ['claim', 'confirmation']);
const currentCarrierSelector = (state) => get(state, 'currentCarrier');
const currentCarrierNameSelector = createSelector(
  [currentCarrierSelector],
  (currentCarrier) => currentCarrier.get('carrier'),
);
const currentCarrierCountrySelector = createSelector(
  [currentCarrierSelector],
  (currentCarrier) => currentCarrier.get('countryCode'),
);

export const currentProductInfoSelector = createSelector(
  [customerState, productsInfoSelector, claimConfirmationSelector],
  (customer, productsInfo, claimConfirmation) =>
    map(get(customer, ['file', 'fileProducts']), (p) => ({
      ...p,
      ...get(customer, ['file', 'models', p.modelId]),
      ...get(productsInfo, p.ownerProductId),
      id: p.ownerProductId,
      issueName: get(customer, ['file', 'irshNames', p.issueId]),
      reasonName: get(customer, ['file', 'irshNames', p.reasonId]),
      solutionName: get(customer, ['file', 'irshNames', p.solutionTypeId]),
      warrantyTypeName: get(customer, ['file', 'irshNames', p.warrantyTypeId]),
      solutionGroupId: get(claimConfirmation, [p.claimId, 'solutionGroupId']),
      solutionPrice: get(claimConfirmation, [p.claimId, 'solutionPrice']),
      reasonComment: get(claimConfirmation, [p.claimId, 'reasonComment']),
      documents: get(claimConfirmation, [p.claimId, 'documents']),
    })),
);

export const currentFileHandlingSelector = createSelector(
  [currentFileIdSelector, WorkflowSelectors.handling],
  (fileId, handling) => handling.getIn([fileId]) || fromJS({}),
);

export const handlingSummary = createSelector(
  [customerState, currentFileHandlingSelector],
  (customer, fileHandling) => {
    const trackingNumbers = get(
      customer,
      'customerFileSummary.trackingNumbers',
      [],
    );
    const handlingData =
      fileHandling && fileHandling.get('isLoaded') && fileHandling.get('value');
    const depositAddress =
      get(handlingData, 'deposit.pickupPointAddress') ||
      get(handlingData, 'deposit.address');
    const deliveryAddress =
      get(handlingData, 'delivery.pickupPointAddress') ||
      get(handlingData, 'delivery.address');
    const depositCarrier = get(
      find(trackingNumbers, { handlingDirection: 'FromOwner' }),
      'carrier',
    );
    const deliveryCarrier = get(
      find(trackingNumbers, { handlingDirection: 'ToOwner' }),
      'carrier',
    );
    const trackingUrlDepositCarrier = get(
      find(trackingNumbers, { handlingDirection: 'FromOwner' }),
      'trackingUrl',
    );
    const trackingUrlDeliveryCarrier = get(
      find(trackingNumbers, { handlingDirection: 'ToOwner' }),
      'trackingUrl',
    );

    return {
      handlingStarted: Boolean(depositAddress),
      deposit: depositAddress && {
        ...depositAddress,
        address: `${
          depositAddress.address || depositAddress.additionalAddress
        }`,
        carrier: depositCarrier,
      },
      trackingUrlDepositCarrier,
      trackingUrlDeliveryCarrier,
      delivery: deliveryAddress && {
        ...deliveryAddress,
        address: `${
          deliveryAddress.address || deliveryAddress.additionalAddress
        }`,
        carrier: deliveryCarrier,
      },
    };
  },
);

const pickupSchedule = createSelector(
  [
    CarriersSelectors.homePickupSchedule,
    currentCarrierNameSelector,
    currentCarrierCountrySelector,
  ],
  (schedule, carrier, countryCode) =>
    schedule.getIn([countryCode, carrier], fromJS({})),
);

export const detailedSchedule = createSelector([pickupSchedule], (schedule) => {
  if (!schedule || !schedule.get('isLoaded')) {
    return null;
  }

  return map(groupBy(schedule.get('value'), 'date'), (intervals, date) => ({
    date,
    intervals,
  }));
});

export const isDetailedScheduleLoadingSelector = createSelector(
  [pickupSchedule],
  (schedule) => {
    if (schedule.get('isDirty', false)) {
      if (schedule.get('hasErrors', false)) {
        return false;
      }

      if (schedule.get('isLoaded', false)) {
        return false;
      }

      return true;
    }

    return false;
  },
);
