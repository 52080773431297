import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';

import NoSolutionReportForm from './NoSolutionReport.form';

const NoSolutionReport = ({
  onReportSubmit,
  isReportSent,
  errorMessage,
  successMessage,
  isLoading,
  isErrored,
  customSendNoSolutionReportLabel,
  orderId,
  groupId,
  claimIds,
}) => {
  const formContext = useForm({
    shouldUnregister: true,

    defaultValues: {
      message: '',
    },
  });

  return (
    <NoSolutionReportForm
      orderId={orderId}
      groupId={groupId}
      claimIds={claimIds}
      formContext={formContext}
      onReportSubmit={onReportSubmit}
      isReportSent={isReportSent}
      errorMessage={errorMessage}
      successMessage={successMessage}
      isLoading={isLoading}
      isErrored={isErrored}
      customSendNoSolutionReportLabel={customSendNoSolutionReportLabel}
    />
  );
};

NoSolutionReport.propTypes = {
  isReportSent: PropTypes.bool,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  onReportSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isErrored: PropTypes.bool,
  customSendNoSolutionReportLabel: PropTypes.shape({}),
  orderId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  claimIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};
NoSolutionReport.defaultProps = {
  isReportSent: false,
  successMessage: '',
  errorMessage: '',
  isLoading: false,
  isErrored: false,
  customSendNoSolutionReportLabel: null,
};

NoSolutionReport.displayName = 'NoSolutionReport';

export default NoSolutionReport;
