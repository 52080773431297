import { defineMessages } from 'react-intl';

export default defineMessages({
  sendReportButton: {
    id: 'view.noSolutionReport.sendReportButton',
    defaultMessage: 'Send report',
  },
  messageLabel: {
    id: 'view.noSolutionReport.messageLabel',
    defaultMessage: 'Message',
  },
  messagePlaceholder: {
    id: 'view.noSolutionReport.messagePlaceholder',
    defaultMessage: 'Type your message here',
  },
  sendNoSolutionReportLabel: {
    id: 'view.noSolutionReport.label',
    defaultMessage: `Sorry, something went wrong.`,
  },
  isMessageSentNotification: {
    id: 'view.noSolutionReport.isMessageSentNotification',
    defaultMessage: 'Votre message est envoyé avec succès.',
  },
  isMessageErroredNotification: {
    id: 'view.noSolutionReport.isMessageErroredNotification',
    defaultMessage:
      "Un problème est survenue, le rapport n'a pas pu être renvoyé, veuillez réessayer plus tard.",
  },
});
