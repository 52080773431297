import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

const StockIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color}>
    <rect fill="none" height="24" width="24" />
    <path d="M20,2H4C3,2,2,2.9,2,4v3.01C2,7.73,2.43,8.35,3,8.7V20c0,1.1,1.1,2,2,2h14c0.9,0,2-0.9,2-2V8.7c0.57-0.35,1-0.97,1-1.69V4 C22,2.9,21,2,20,2z M15,14H9v-2h6V14z M20,7H4V4h16V7z" />
  </Icon>
);

StockIcon.displayName = 'StockIcon';

export default StockIcon;
