import { useCallback } from 'react';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { CommonAttachmentService } from '@savgroup-front-common/core/src/api';

const useHandleDeleteAttachmentOnClaimGroup = ({ claimGroupId }) => {
  return useCallback(
    async ({ documentId }) => {
      return CommonAttachmentService.deleteAttachment({
        endpoint: `${APIConfiguration.claim}claimGroups/${claimGroupId}/uploads/${documentId}`,
      });
    },
    [claimGroupId],
  );
};

export default useHandleDeleteAttachmentOnClaimGroup;
