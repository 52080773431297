import { APIConfiguration } from '@savgroup-front-common/configuration';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';
import {
  AdditionalInformation,
  AdditionalInformationExtended,
  Reason,
  RELATED_TO,
} from 'myaccount/types';

export interface ReasonAdapterReturnValues extends Reason {
  neededInformation: AdditionalInformationExtended[];
}

interface ReasonAdapterArgs {
  reasonSelected: Reason;
  claimIds: string[];
}

export const buildFileUploadEndpoints = ({
  claimIds,
  id,
}: {
  claimIds: string[];
  id: string;
}): Record<string, { uploadEndpoint: string; deleteEndpoint: string }> => {
  return claimIds.reduce((acc, claimId) => {
    return {
      ...acc,
      [claimId]: {
        uploadEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
        deleteEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
      },
    };
  }, {});
};

export const reasonAdapter = ({
  reasonSelected,
  claimIds,
}: ReasonAdapterArgs): ReasonAdapterReturnValues => {
  const newNeededInformation: AdditionalInformationExtended[] | any[] =
    reasonSelected.neededInformation.reduce(
      (acc: AdditionalInformation[], curr: AdditionalInformation) => {
        const { uniquenessByProduct, id, type } = curr;

        const isFileType = type === ADDITIONAL_INFORMATION_TYPES.FILE;

        if (uniquenessByProduct) {
          return [
            ...acc,
            ...claimIds.map((claimId) => {
              return {
                ...curr,
                internalId: `${id}_${claimId}`,
                claimIds: [claimId],
                fileUploadEndpoints: isFileType
                  ? buildFileUploadEndpoints({
                      claimIds,
                      id,
                    })
                  : null,
              };
            }),
          ];
        }

        return [
          ...acc,
          {
            ...curr,
            claimIds,
            internalId: id,
            fileUploadEndpoints: isFileType
              ? buildFileUploadEndpoints({
                  claimIds,
                  id,
                })
              : null,
          },
        ];
      },
      [],
    );

  return {
    ...reasonSelected,
    neededInformation: newNeededInformation,
  };
};

export const prepareReasonAdditionalInformation = (
  claimId: string,
  reasonAdditionalInformation: Record<string, { value: string }>,
  neededInformations: { id: string }[],
): unknown => {
  const neededInformationIds = neededInformations.map(
    (neededInformation) => neededInformation.id,
  );

  return Object.keys(reasonAdditionalInformation || {})
    .filter((key) => {
      const needInformationKey = key.endsWith(claimId)
        ? key.replace(`_${claimId}`, '')
        : key;

      return neededInformationIds.includes(needInformationKey);
    })
    .map((key: any) => {
      const needInformationKey = key.endsWith(claimId)
        ? key.replace(`_${claimId}`, '')
        : key;

      if (
        reasonAdditionalInformation &&
        reasonAdditionalInformation[key] &&
        reasonAdditionalInformation[key]?.value
      ) {
        return {
          id: needInformationKey,
          value: reasonAdditionalInformation[key].value,
        };
      }

      return {
        id: needInformationKey,
        value: reasonAdditionalInformation[key],
      };
    });
};

export const neededInformationFiltered = (
  neededInformations?: AdditionalInformation[],
  isMultiProduct?: boolean,
): AdditionalInformation[] | undefined => {
  if (!neededInformations || !neededInformations?.length) {
    return undefined;
  }

  if (isMultiProduct) {
    return neededInformations
      .filter((item) => item.type !== ADDITIONAL_INFORMATION_TYPES.FILE)
      .filter((item) => item.relatedTo === RELATED_TO.CLAIM);
  }

  return neededInformations.filter(
    (item) => item.type !== ADDITIONAL_INFORMATION_TYPES.FILE,
  );
};
