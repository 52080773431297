import { Grid } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { mediaQuery } from '../../../grid/helpers';

export const GridOverride = styled(Grid)`
  margin-left: 0;
  ${mediaQuery.md`margin-left: auto;`};
  a {
    padding-left: 0;
    padding-right: 0;
  }
`;
