import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Constants, Selectors } from 'myaccount/domains/Modals';
import { closeModal } from 'myaccount/domains/Modals/actionCreators';

import { OtherIssueModal } from '../modals';

const modalsMap = {
  [Constants.MODAL_TYPES.OTHER_ISSUE_MODAL]: OtherIssueModal,
};

const ModalsPortal = () => {
  const dispatch = useDispatch();
  const modals = useSelector(Selectors.modalsSelector);

  const dispatchCloseModal = useCallback(
    (args) => {
      dispatch(closeModal(args));
    },
    [dispatch],
  );

  return (
    <div>
      {modals.map((modal) => {
        const Modal = modalsMap[modal.type];

        return (
          <Modal
            key={modal.type}
            data={modal.data}
            onClose={() => dispatchCloseModal({ type: modal.type })}
          />
        );
      })}
    </div>
  );
};

ModalsPortal.propTypes = {};

ModalsPortal.displayName = 'ModalsPortal';

export default ModalsPortal;
