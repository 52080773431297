import { useQuery } from 'react-query';

import { STALE_TIME } from '@savgroup-front-common/constants';

import { CommonCarrierService } from '../api';
import { useToasts } from '../molecules/NotificationsProvider';

const GetCarrierProductById = 'getCarrierProductById';

const useGetCarrierProductByIdQuery = ({
  carrierProductId,
}: {
  carrierProductId?: string;
}) => {
  const { pushErrors, removeAllNotifications } = useToasts();

  const { data: carrierProductSummary, isLoading } = useQuery(
    [GetCarrierProductById, { carrierProductId }],
    async () => {
      if (!carrierProductId) {
        return undefined;
      }
      removeAllNotifications();

      const response = await CommonCarrierService.getCarrierProductByIdQuery({
        carrierProductId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: STALE_TIME.MINUTES_10,
    },
  );

  return {
    carrierProductSummary,
    isLoading,
  };
};

export default useGetCarrierProductByIdQuery;
