import { AddressInfoDto } from './AddressInfo';

export enum RISK_CARRIER_TYPE {
  MANUFACTURER = 'Manufacturer',
  INSURER = 'Insurer',
  RESELLER = 'Reseller',
  SAV_GROUP = 'SavGroup',
  CARRIER = 'Carrier',
  OWNER = 'Owner',
  SUPPLIER = 'Supplier',
}

export interface RiskCarrier {
  address: AddressInfoDto;
  email: string;
  fullName: string;
  id: string;
  phone: string;
  type: RISK_CARRIER_TYPE;
}
