import React, { ChangeEvent, FunctionComponent, Ref } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { CURRENCIES } from '@savgroup-front-common/constants/src/shared';
import {
  Currency,
  MessageType,
  SUPPORTED_COMPONENTS,
} from '@savgroup-front-common/types';

import { InjectSvg } from '../../atoms/images';
import { getSolutionIconUrl, isPrice } from '../../helpers';
import { RadioCardHookForm } from '../../molecules/Form';

interface SolutionRadioProps {
  onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
  isChecked?: boolean | undefined;
  styleFormatter?: 'currency' | 'decimal' | 'percent' | 'unit';
  label: string | number | MessageType;
  icon?: string;
  name: string;
  currency?: Currency;
  price?: number;
  value?: string;
  dataTestId?: string;
  register: UseFormRegister<any>;
  didactic?: string;
}

interface SolutionRadioWithRefProps extends SolutionRadioProps {
  forwardedRef?: Ref<HTMLInputElement>;
}

export const SolutionRadio: FunctionComponent<SolutionRadioWithRefProps> = ({
  isChecked = undefined,
  styleFormatter = 'currency',
  onChange = undefined,
  forwardedRef = null,
  icon = undefined,
  label,
  price = null,
  currency = CURRENCIES.EUR,
  name,
  value,
  dataTestId,
  register,
  didactic,
}) => {
  const intl = useIntl();
  const { fallbackUrl, iconUrl } = getSolutionIconUrl(icon);

  return (
    <RadioCardHookForm
      title={label}
      image={
        <InjectSvg
          iconUrl={iconUrl}
          iconUrlFallback={fallbackUrl}
          componentThemeName={SUPPORTED_COMPONENTS.IRSH_ICONS}
        />
      }
      onChange={onChange}
      ref={forwardedRef}
      register={register}
      name={name}
      value={value}
      isChecked={isChecked}
      dataTestId={dataTestId}
      label={
        price !== null && isPrice(price) && price > 0
          ? intl.formatNumber(price, { style: styleFormatter, currency })
          : undefined
      }
    >
      {didactic}
    </RadioCardHookForm>
  );
};

SolutionRadio.displayName = 'SolutionRadio';

export default React.forwardRef<HTMLInputElement, SolutionRadioProps>(
  (props, ref) => <SolutionRadio forwardedRef={ref} {...props} />,
);
