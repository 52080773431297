import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { apiCall } from '@savgroup-front-common/core/src/services';

import { CLAIM_CANCELLED } from './actionTypes';

export function cancelClaim(claimInfo) {
  let workingClaimId;

  let workingProductId;

  return (dispatch, getState) => {
    const { navigation } = getState();

    if (claimInfo) {
      workingClaimId = claimInfo.claimId;
      workingProductId = claimInfo.productId;
    } else {
      workingClaimId = navigation.claimID;
      workingProductId = navigation.productId;
    }

    apiCall(`${APIConfiguration.claim}claims/${workingClaimId}`, {
      method: SUPPORTED_METHODS.DELETE,
    }).then(() => {
      dispatch({ type: CLAIM_CANCELLED, payload: workingProductId });
    });
  };
}
