import React, { FC, PropsWithChildren } from 'react';
import { FormattedDate } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import { Tag } from '@savgroup-front-common/core/src/atoms/Tag';
import { ProductIcon } from '@savgroup-front-common/core/src/components/ProductIcon';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { Field } from '@savgroup-front-common/core/src/molecules/Field';
import { Selectors as ClaimsSelectors } from '@savgroup-front-common/core/src/domains/claims';
import useGetModelById from 'myaccount/hooks/useGetModelById';

import messages from '../Order/messages';

import {
  $FullColl,
  $ImageContainer,
  $MiniColl,
  $RepairedCol,
  $StyledCol,
  $StyledH3,
} from './OrderProductInfo.styles';
import useMyAccountTypedSelector from '../../../hooks/useMyAccountTypedSelector';

interface MinimalOrderProductInfoProps {
  modelTypeId: string;
  modelName: string;
  brandName: string;
  orderNumber: string;
  purchaseDate: string;
  disabled?: boolean;
  claimId: string;
  shouldRenderChildrenOnTop?: boolean;
  isOucomeHistoryRepaired?: boolean;
  isOucomeHistoryRefunded?: boolean;
  isOucomeHistorySwapped?: boolean;
  serialNumber?: string;
  modelId: string;
}

const MinimalOrderProductInfo: FC<
  PropsWithChildren<MinimalOrderProductInfoProps>
> = ({
  modelTypeId,
  modelName,
  brandName,
  orderNumber,
  children,
  claimId,
  serialNumber,
  modelId,
  disabled = false,
  isOucomeHistoryRepaired = false,
  isOucomeHistoryRefunded = false,
  isOucomeHistorySwapped = false,
  shouldRenderChildrenOnTop = false,
  purchaseDate = '',
}) => {
  const fullClaim = useMyAccountTypedSelector((state) =>
    ClaimsSelectors.getFullClaimById(state, { claimId }),
  );
  const fromProductWarranty = fullClaim?.fromProductWarranty;
  const { model } = useGetModelById({ modelId });
  const pictureUrl = model?.pictureUrl;

  return (
    <Row>
      {shouldRenderChildrenOnTop && children}
      <$MiniColl>
        <$ImageContainer>
          {!pictureUrl && <ProductIcon modelTypeId={modelTypeId} />}
          {pictureUrl && (
            <div>
              <img src={pictureUrl} alt="pictureUrl" />
            </div>
          )}
        </$ImageContainer>
      </$MiniColl>
      <$FullColl>
        <Row>
          <$StyledCol>
            <Tag primary disabled={disabled} data-testid="orderReference">
              <SafeFormattedMessageWithoutSpread
                message={messages.orderNumber}
                values={{ orderReference: orderNumber }}
              />
            </Tag>
          </$StyledCol>
          {fromProductWarranty !== undefined && (
            <Col>
              <Tag primary={fromProductWarranty} alert={!fromProductWarranty}>
                {!fromProductWarranty && (
                  <SafeFormattedMessageWithoutSpread
                    message={messages.withoutWarranty}
                  />
                )}
                {fromProductWarranty && (
                  <SafeFormattedMessageWithoutSpread
                    message={messages.withWarranty}
                  />
                )}
              </Tag>
            </Col>
          )}
          {isOucomeHistoryRepaired && (
            <$RepairedCol>
              <Tag neutral>
                <SafeFormattedMessageWithoutSpread
                  message={messages.isRepaired}
                />
              </Tag>
            </$RepairedCol>
          )}
          {isOucomeHistoryRefunded && (
            <$RepairedCol>
              <Tag neutral>
                <SafeFormattedMessageWithoutSpread
                  message={messages.isRefunded}
                />
              </Tag>
            </$RepairedCol>
          )}
          {isOucomeHistorySwapped && (
            <$RepairedCol>
              <Tag neutral>
                <SafeFormattedMessageWithoutSpread
                  message={messages.isSwapped}
                />
              </Tag>
            </$RepairedCol>
          )}
        </Row>
        <Row>
          <$StyledCol xs={12} sm={2}>
            <Field label={messages.brand} dataTestId="brandName">
              {brandName}
            </Field>
          </$StyledCol>
          <$StyledCol xs={12} sm={3}>
            <Field label={messages.model} dataTestId="modelName">
              {modelName}
            </Field>
          </$StyledCol>
          <$StyledCol xs={12} sm={3}>
            <Field label={messages.purchasedDate} dataTestId="purchaseDate">
              {purchaseDate && (
                <$StyledH3>
                  <FormattedDate
                    year="numeric"
                    month="long"
                    day="2-digit"
                    value={purchaseDate}
                  />
                </$StyledH3>
              )}

              {!purchaseDate && '-'}
            </Field>
          </$StyledCol>
          {serialNumber && (
            <$StyledCol xs={12} sm={2}>
              <Field label={messages.serialNumber} dataTestId="brandName">
                {serialNumber}
              </Field>
            </$StyledCol>
          )}
          {!shouldRenderChildrenOnTop && children}
        </Row>
      </$FullColl>
    </Row>
  );
};

MinimalOrderProductInfo.displayName = 'MinimalOrderProductInfo';

export default MinimalOrderProductInfo;
