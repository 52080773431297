export enum MIME_TYPES {
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  SHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  EXCEL = 'application/vnd.ms-excel',
  DOC = 'application/msword',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  VIDEO_MP4 = 'video/mp4',
  VIDEO_3GPP = 'video/3gpp',
  VIDEO_3GPP2 = 'video/3gpp2',
  PLAINT_TEXT = 'text/plain',
  SVG = 'image/svg+xml',
  ICO = 'image/x-icon',
  OCTET_STREAM = 'application/octet-stream',
  HTML = 'text/html',
  TEXT_CSV = 'text/csv',
  GIF = 'image/gif',
  VIDEO_QUICKTIME = 'video/quicktime',
  IMAGE_HEIC = 'image/heic',
  IMAGE_HEIF = 'image/heif',
  IMAGE_BMP = 'image/bmp',
  JSON = 'application/json',
  MOVE = 'video/x-sgi-movie',
}

export const ALLOWED_MIME_TYPES = [
  MIME_TYPES.DOCX,
  MIME_TYPES.DOC,
  MIME_TYPES.JPEG,
  MIME_TYPES.PNG,
  MIME_TYPES.PDF,
  MIME_TYPES.VIDEO_MP4,
  MIME_TYPES.PLAINT_TEXT,
  MIME_TYPES.SHEET,
  MIME_TYPES.EXCEL,
  MIME_TYPES.VIDEO_QUICKTIME,
  MIME_TYPES.IMAGE_HEIF,
  MIME_TYPES.IMAGE_HEIC,
  MIME_TYPES.MOVE,
];

export const ALLOWED_MIME_TYPES_QR_CODE = [MIME_TYPES.JPEG, MIME_TYPES.PNG];

export const ALLOWED_MIME_TYPES_BULK_CHANGES = [
  MIME_TYPES.PLAINT_TEXT,
  MIME_TYPES.TEXT_CSV,
];
