import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  FEATURE_FAG_DESCRIPTIONS,
  FEATURE_FLAGS,
  FEATURE_FLAGS_APP_TYPE,
} from '@savgroup-front-common/constants';
import { LOCAL_STORAGE_KEYS } from '@savgroup-front-common/types';

import { getFromLocalStorage, setToLocalStorage } from '../../helpers';

import FeatureFlagContext from './FeatureFlagContext';

interface FeatureManagerProviderProps {
  app: FEATURE_FLAGS_APP_TYPE;
}

const FeatureManagerProvider: FunctionComponent<
  React.PropsWithChildren<FeatureManagerProviderProps>
> = ({ children, app }) => {
  const isFeatureFlagManagerInitialOpen = getFromLocalStorage({
    key: LOCAL_STORAGE_KEYS.IS_FEATURE_FLAG_MANAGER_OPEN,
    defaultValue: false,
  });

  const existingFeatureFlags = useMemo(
    () =>
      Object.values(FEATURE_FLAGS).filter((feature) => {
        const summary = FEATURE_FAG_DESCRIPTIONS[feature];

        return summary.app.includes(app);
      }),
    [app],
  );

  const [features, setFeatures] = useState(() => {
    const featuresFromLocalStorage = getFromLocalStorage({
      key: LOCAL_STORAGE_KEYS.FEATURE_FLAGS,
      defaultValue: [],
    });

    return featuresFromLocalStorage.filter((featureFlag) => {
      return existingFeatureFlags.includes(featureFlag);
    }, featuresFromLocalStorage);
  });

  useEffect(() => {
    setToLocalStorage({
      key: LOCAL_STORAGE_KEYS.FEATURE_FLAGS,
      value: features,
    });
  }, [features]);

  const [isFeatureFlagManagerOpen, setIsFeatureFlagManagerOpen] = useState(
    isFeatureFlagManagerInitialOpen,
  );

  useEffect(() => {
    setToLocalStorage({
      key: LOCAL_STORAGE_KEYS.IS_FEATURE_FLAG_MANAGER_OPEN,
      value: isFeatureFlagManagerOpen,
    });
  }, [isFeatureFlagManagerOpen]);

  const handleToggleFeature = useCallback((feature: FEATURE_FLAGS): void => {
    setFeatures((features) => {
      if (features.includes(feature)) {
        return features.filter((key) => key !== feature);
      }

      return [...features, feature];
    });
  }, []);

  const handleOpenModal = useCallback(
    () => setIsFeatureFlagManagerOpen(true),
    [],
  );
  const handleCloseModal = useCallback(
    () => setIsFeatureFlagManagerOpen(false),
    [],
  );

  const handleToggleModal = useCallback(
    () => setIsFeatureFlagManagerOpen((v) => !v),
    [],
  );

  return (
    <>
      <FeatureFlagContext.Provider
        value={{
          handleOpenFeatureFlagModal: handleOpenModal,
          handleCloseFeatureFlagModal: handleCloseModal,
          handleToggleFeature,
          features,
          handleToggleFeatureFlagModal: handleToggleModal,
          isFeatureFlagManagerOpen,
          existingFeatureFlags,
        }}
      >
        {children}
      </FeatureFlagContext.Provider>
    </>
  );
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    openFeatureManager: () => void;
    closeFeatureManager: () => void;
  }
}

FeatureManagerProvider.displayName = 'FeatureManagerProvider';

window.openFeatureManager = () => {
  setToLocalStorage({
    key: LOCAL_STORAGE_KEYS.IS_FEATURE_FLAG_MANAGER_OPEN,
    value: true,
  });
};
window.closeFeatureManager = () => {
  setToLocalStorage({
    key: LOCAL_STORAGE_KEYS.IS_FEATURE_FLAG_MANAGER_OPEN,
    value: false,
  });
};

export default FeatureManagerProvider;
