import get from 'lodash/get';
import React, { useEffect } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  availableNamedLanguages,
  LANGUAGES,
} from '@savgroup-front-common/constants';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { Autocomplete, FormGroup } from '../../atoms/Form';
import { safeFormattedIntlString } from '../../formatters';
import { useChangeHTMLLanguage } from '../../hooks';

import useLangageChooser from './hooks/useLangageChooser';
import { $LanguageChooserContainer } from './LanguageChooser.styles';
import messages from './messages';

interface LanguageChooserProps {
  sellerId?: string;
  onLangueChange?: (locale: LANGUAGES) => void;
  acceptedLanguages?: LANGUAGES[];
  withoutPaddingBottom?: boolean;
  withoutPaddingTop?: boolean;
}

const LanguageChooser = ({
  sellerId,
  onLangueChange,
  acceptedLanguages,
  withoutPaddingBottom,
  withoutPaddingTop,
}: LanguageChooserProps) => {
  const intl = useIntl();
  const {
    formContext,
    currentLanguage,
    acceptedLanguagesBySeller,
    handleChangeLanguage,
  } = useLangageChooser({ sellerId });

  useEffect(() => {
    if (onLangueChange) {
      onLangueChange(intl.locale as LANGUAGES);
    }
  }, [onLangueChange, intl.locale]);

  const { control } = formContext;

  const optionsLanguages = availableNamedLanguages
    .filter((option) =>
      acceptedLanguages
        ? acceptedLanguages.includes(option)
        : acceptedLanguagesBySeller.includes(option.toLocaleUpperCase()),
    )
    .map((option) => ({
      value: option.toLocaleUpperCase(),
      label: safeFormattedIntlString(get(messages, option)),
    }));

  useChangeHTMLLanguage(currentLanguage);

  if (!acceptedLanguagesBySeller.length && !acceptedLanguages?.length) {
    return <></>;
  }

  return (
    <$LanguageChooserContainer>
      <FormProvider {...formContext}>
        <FormGroup
          withoutPaddingBottom={withoutPaddingBottom}
          withoutPaddingTop={withoutPaddingTop}
        >
          <Controller
            control={control}
            name="language"
            render={({ field }) => (
              <Autocomplete
                name={field.name}
                componentThemeName={SUPPORTED_COMPONENTS.LANGUAGE_CHOOSER}
                options={optionsLanguages}
                onChange={handleChangeLanguage}
                value={field.value}
              />
            )}
          />
        </FormGroup>
      </FormProvider>
    </$LanguageChooserContainer>
  );
};

LanguageChooser.displayName = 'LanguageChooser';

export default React.memo(LanguageChooser);
