import { createAction } from '@reduxjs/toolkit';

import {
  CREATE_INVOICE_FOR_CLAIM_GROUP,
  GET_OPENED_INVOICES,
} from './actionTypes';
import {
  CreateInvoiceForClaimGroupPayload,
  GetOpenedInvoicesPayload,
} from './types';

export const getOpenedInvoices = createAction<GetOpenedInvoicesPayload>(
  GET_OPENED_INVOICES.BASE,
);

export const createInvoiceForClaimGroup =
  createAction<CreateInvoiceForClaimGroupPayload>(
    CREATE_INVOICE_FOR_CLAIM_GROUP.BASE,
  );
