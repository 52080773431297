import { ROUTES } from '../view/app/routes/Routes';

export const getClaimGroupHandlingLink = (id, mode) =>
  ROUTES.CLAIM_GROUP_HANDLING.replace(':claimGroupId', id).replace(
    ':handlingMode',
    mode,
  );

export const getClaimHandlingLink = (id, mode) =>
  ROUTES.CLAIM_HANDLING.replace(':claimID', id).replace(':handlingMode', mode);
