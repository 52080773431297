import { createSelector } from 'reselect';

import { CommonAppState } from '../CommonAppState';
import { wasActionLoadedSelector } from '../sagaRequestMetadata/selectors';

import { GET_ACTOR_SUMMARY_BY_ID } from './actionTypes';
import { ACTORS_DOMAIN, ACTORS_DOMAIN_KEY } from './constants';

export const actorsState = (state: CommonAppState) => state[ACTORS_DOMAIN_KEY];

export const selectActorSummaryById = createSelector(
  [
    actorsState,
    (state: CommonAppState, { actorId }: { actorId?: string }) => actorId,
  ],
  (actors, actorId) => {
    return actors.getIn([ACTORS_DOMAIN.VALUES, actorId], null);
  },
);

export const selectGetActorSummaryByIdWasLoaded = (
  state: CommonAppState,
  { actorId }: { actorId?: string },
) => {
  return wasActionLoadedSelector(state, GET_ACTOR_SUMMARY_BY_ID, actorId);
};
