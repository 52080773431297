import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import { Button } from '../../atoms/button';

import {
  $ButtonsContainer,
  ButtonRow,
  InfoTitleH3,
} from './PaginatedView.styles';

const PaginatedView = ({
  products,
  activeIndex,
  onActiveProductChange,
  title,
}) => {
  return (
    <ButtonRow>
      {title && <InfoTitleH3>{title}</InfoTitleH3>}
      <$ButtonsContainer>
        {products.length > 1
          ? map(products, (n, index) => (
              <Button
                type={BUTTON_TYPES.BUTTON}
                key={String(index)}
                isPressed={index === activeIndex}
                primary
                onClick={() => onActiveProductChange(index)}
                dataTestId={`paginated-view-${index}`}
              >
                {index + 1}
              </Button>
            ))
          : null}
      </$ButtonsContainer>
    </ButtonRow>
  );
};

PaginatedView.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onActiveProductChange: PropTypes.func.isRequired,
  activeIndex: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

PaginatedView.defaultProps = {
  activeIndex: null,
  title: null,
};

export default PaginatedView;
