import React from 'react';

import { ICONS_TYPE } from '../../../../types/src/Icon';

import AddCircleOutlineIcon from './AddCircleOutline.icon';
import AddDatabaseScriptIcon from './AddDatabaseScript.icon';
import BaselineEcoIcon from './BaselineEco.icon';
import BedOutlineIcon from './BedOutline.icon';
import BedSideIcon from './BedSide.icon';
import BoxIcon from './Box.icon';
import BreakdownIcon from './Breakdown.icon';
import CalendarIcon from './Calendar.icon';
import CardWalletIcon from './CardWallet.icon';
import CarpetIcon from './Carpet.icon';
import CartIcon from './Cart.icon';
import ChatLinesIcon from './ChatLines.icon';
import CheckCircleOutlineIcon from './CheckCircleOutline.icon';
import CluteryIcon from './Clutery.icon';
import ConsumableIcon from './Consumable.icon';
import CookingRobotIcon from './CookingRobot.icon';
import CrackedEggIcon from './CrackedEgg.icon';
import CreditCardIcon from './CreditCard.icon';
import CrossIcon from './Cross.icon';
import DeliveryIcon from './Delivery.icon';
import DeliveryBoxIcon from './DeliveryBox.icon';
import DeliveryTruckIcon from './DeliveryTruck.icon';
import DepositIcon from './Deposit.icon';
import FlowerIcon from './Flower.icon';
import FramePictureIcon from './FramePicture.icon';
import GamePadIcon from './GamePad.icon';
import GridRemoveIcon from './GridRemove.icon';
import HeadsetHelpIcon from './HeadsetHelp.icon';
import HighPriorityIcon from './HighPriority.icon';
import HomeIcon from './Home.icon';
import HooverIcon from './Hoover.icon';
import HotplateIcon from './Hotplate.icon';
import LaptopIcon from './Laptop.icon';
import LightBulbOnIcon from './LightBulbOn.icon';
import MediaImageIcon from './MediaImage.icon';
import ModernTvIcon from './ModernTv.icon';
import MultiwindowIcon from './Multiwindow.icon';
import NewspaperIcon from './Newspaper.icon';
import OpenBookIcon from './OpenBook.icon';
import PackagesIcon from './Packages.icon';
import PeopleGroupIcon from './PeopleGroup.icon';
import PhoneIcon from './Phone.icon';
import QuestionMarkIcon from './QuestionMark.icon';
import ReadingLampIcon from './ReadingLamp.icon';
import ReceiveEurosIcon from './ReceiveEuros.icon';
import RefreshDoubleIcon from './RefreshDouble.icon';
import SadFaceIcon from './SadFace.icon';
import ScanBarcodeIcon from './ScanBarcode.icon';
import SendMailIcon from './SendMail.icon';
import SleeperChairIcon from './SleeperChair.icon';
import SmallShopIcon from './SmallShop.icon';
import SoapIcon from './Soap.icon';
import SofaIcon from './Sofa.icon';
import StarsIcon from './Stars.icon';
import StrollerIcon from './Stroller.icon';
import ToolsIcon from './Tools.icon';
import UmbrellaIcon from './Umbrella.icon';
import VeganCircleIcon from './VeganCircle.icon';
import WashingMachineIcon from './WashingMachine.icon';
import WristwatchIcon from './Wristwatch.icon';
import HeartPulseIcon from './HeartPulse.icon';
import PortraitIcon from './Portrait.icon';
import FolderHeartIcon from './FolderHeart.icon';
import FolderIcon from './Folder.icon';
import SparksIcon from './Sparks.icon';

interface MyAccountIconProps {
  icon?: ICONS_TYPE;
  color?: string;
}

export const MyAccountIcon: React.FC<MyAccountIconProps> = ({
  icon,
  color = 'black',
}) => {
  if (!icon) {
    return <></>;
  }

  switch (icon) {
    case ICONS_TYPE.ADD_CIRCLE_OUTLINE_ICON:
      return <AddCircleOutlineIcon color={color} />;
    case ICONS_TYPE.ADD_DATABASE_SCRIPT_ICON:
      return <AddDatabaseScriptIcon color={color} />;
    case ICONS_TYPE.BASELINE_ECO:
      return <BaselineEcoIcon color={color} />;
    case ICONS_TYPE.BOX_ICON:
      return <BoxIcon color={color} />;
    case ICONS_TYPE.TOOLS_ICON:
      return <ToolsIcon color={color} />;
    case ICONS_TYPE.BREAKDOWN_ICON:
      return <BreakdownIcon color={color} />;
    case ICONS_TYPE.CALENDAR_ICON:
      return <CalendarIcon color={color} />;
    case ICONS_TYPE.CARD_WALLET_ICON:
      return <CardWalletIcon color={color} />;
    case ICONS_TYPE.CART_ICON:
      return <CartIcon color={color} />;
    case ICONS_TYPE.CHAT_LINES_ICON:
      return <ChatLinesIcon color={color} />;
    case ICONS_TYPE.CHECK_CIRCLE_OUTLINE_ICON:
      return <CheckCircleOutlineIcon color={color} />;
    case ICONS_TYPE.CONSUMABLE_ICON:
      return <ConsumableIcon color={color} />;
    case ICONS_TYPE.CRACKED_EGG_ICON:
      return <CrackedEggIcon color={color} />;
    case ICONS_TYPE.CREDIT_CARD_ICON:
      return <CreditCardIcon color={color} />;
    case ICONS_TYPE.CROSS_ICON:
      return <CrossIcon color={color} />;
    case ICONS_TYPE.DELIVERY_ICON:
      return <DeliveryIcon color={color} />;
    case ICONS_TYPE.DELIVERY_BOX_ICON:
      return <DeliveryBoxIcon color={color} />;
    case ICONS_TYPE.DELIVERY_TRUCK_ICON:
      return <DeliveryTruckIcon color={color} />;
    case ICONS_TYPE.GRID_REMOVE_ICON:
      return <GridRemoveIcon color={color} />;
    case ICONS_TYPE.HEADSET_HELP_ICON:
      return <HeadsetHelpIcon color={color} />;
    case ICONS_TYPE.HIGH_PRIORITY_ICON:
      return <HighPriorityIcon color={color} />;
    case ICONS_TYPE.HOME_ICON:
      return <HomeIcon color={color} />;
    case ICONS_TYPE.LAPTOP_ICON:
      return <LaptopIcon color={color} />;
    case ICONS_TYPE.LIGHT_BULB_ON_ICON:
      return <LightBulbOnIcon color={color} />;
    case ICONS_TYPE.MEDIA_IMAGE_ICON:
      return <MediaImageIcon color={color} />;
    case ICONS_TYPE.MULTIWINDOW_ICON:
      return <MultiwindowIcon color={color} />;
    case ICONS_TYPE.NEWSPAPER_ICON:
      return <NewspaperIcon color={color} />;
    case ICONS_TYPE.OPEN_BOOK_ICON:
      return <OpenBookIcon color={color} />;
    case ICONS_TYPE.PACKAGES_ICON:
      return <PackagesIcon color={color} />;
    case ICONS_TYPE.PEOPLE_GROUP_ICON:
      return <PeopleGroupIcon color={color} />;
    case ICONS_TYPE.PHONE_ICON:
      return <PhoneIcon color={color} />;
    case ICONS_TYPE.QUESTION_MARK_ICON:
      return <QuestionMarkIcon color={color} />;
    case ICONS_TYPE.RECEIVE_EUROS_ICON:
      return <ReceiveEurosIcon color={color} />;
    case ICONS_TYPE.REFRESH_DOUBLE_ICON:
      return <RefreshDoubleIcon color={color} />;
    case ICONS_TYPE.SAD_FACE_ICON:
      return <SadFaceIcon color={color} />;
    case ICONS_TYPE.SCAN_BARCODE_ICON:
      return <ScanBarcodeIcon color={color} />;
    case ICONS_TYPE.SEND_MAIL_ICON:
      return <SendMailIcon color={color} />;
    case ICONS_TYPE.SLEEPER_CHAIR_ICON:
      return <SleeperChairIcon color={color} />;
    case ICONS_TYPE.SMALL_SHOP_ICON:
      return <SmallShopIcon color={color} />;
    case ICONS_TYPE.STARS_ICON:
      return <StarsIcon color={color} />;
    case ICONS_TYPE.UMBRELLA_ICON:
      return <UmbrellaIcon color={color} />;
    case ICONS_TYPE.VEGAN_CIRCLE_ICON:
      return <VeganCircleIcon color={color} />;
    case ICONS_TYPE.DEPOSIT_ICON:
      return <DepositIcon color={color} />;
    case ICONS_TYPE.BED_OUTLINE_ICON:
      return <BedOutlineIcon color={color} />;
    case ICONS_TYPE.BED_SIDE_ICON:
      return <BedSideIcon color={color} />;
    case ICONS_TYPE.CARPET_ICON:
      return <CarpetIcon color={color} />;
    case ICONS_TYPE.CLUTERY_ICON:
      return <CluteryIcon color={color} />;
    case ICONS_TYPE.COOKINGROBOT_ICON:
      return <CookingRobotIcon color={color} />;
    case ICONS_TYPE.FLOWER_ICON:
      return <FlowerIcon color={color} />;
    case ICONS_TYPE.FRAME_PICTURE_ICON:
      return <FramePictureIcon color={color} />;
    case ICONS_TYPE.GAMEPAD_ICON:
      return <GamePadIcon color={color} />;
    case ICONS_TYPE.HOTPLATE_ICON:
      return <HotplateIcon color={color} />;
    case ICONS_TYPE.HOOVER_ICON:
      return <HooverIcon color={color} />;
    case ICONS_TYPE.MODERNTV_ICON:
      return <ModernTvIcon color={color} />;
    case ICONS_TYPE.READINGLAMP_ICON:
      return <ReadingLampIcon color={color} />;
    case ICONS_TYPE.SOAP_ICON:
      return <SoapIcon color={color} />;
    case ICONS_TYPE.SOFA_ICON:
      return <SofaIcon color={color} />;
    case ICONS_TYPE.STROLLER_ICON:
      return <StrollerIcon color={color} />;
    case ICONS_TYPE.WASHINGMACHINE_ICON:
      return <WashingMachineIcon color={color} />;
    case ICONS_TYPE.WRISTWATCH_ICON:
      return <WristwatchIcon color={color} />;
    case ICONS_TYPE.HEARTPULSE_ICON:
      return <HeartPulseIcon color={color} />;
    case ICONS_TYPE.PORTRAIT_ICON:
      return <PortraitIcon color={color} />;
    case ICONS_TYPE.FOLDERHEART_ICON:
      return <FolderHeartIcon color={color} />;
    case ICONS_TYPE.FOLDER_ICON:
      return <FolderIcon color={color} />;
    case ICONS_TYPE.SPARKS_ICON:
      return <SparksIcon color={color} />;
    default:
      return <QuestionMarkIcon color={color} />;
  }
};

MyAccountIcon.displayName = 'MyAccountIcon';
