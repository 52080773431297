import React, { FunctionComponent } from 'react';

import { NOTIFICATION_TYPES } from '@savgroup-front-common/constants';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';

import { Banner } from '../../../atoms/Banners';
import { sortBy } from '../../../helpers';
import { MDXContent } from '../../MDXContent/MDXContent';

type ClaimId = string;
interface NeededAdditionalInformationDidactic {
  claimIds: string[];
  displayOrder: number;
  id: string;
  internalId: string;
  isRequired: boolean;
  key: string;
  name: string;
  possibleValues: string[];
  type: 'NotificationInfo' | 'NotificationAlert';
  uniqueName: string;
  uniquenessByProduct: boolean;
}
type NeededAdditionalInformationDidacticsMap = Record<
  ClaimId,
  NeededAdditionalInformationDidactic[]
>;
interface AdditionalInformationDidacticsProps {
  additionalInformationDidactics: NeededAdditionalInformationDidacticsMap;
  isLoading: boolean;
}

export const AdditionalInformationDidactics: FunctionComponent<
  AdditionalInformationDidacticsProps
> = ({ additionalInformationDidactics, isLoading }) => {
  const allAdditionalInformationDidactic = Object.values(
    additionalInformationDidactics,
  )
    .flat()
    .sort(sortBy({ fieldName: 'displayOrder' }));

  if (isLoading) {
    return null;
  }

  return (
    <div>
      {!isLoading &&
        allAdditionalInformationDidactic.map(
          (additionalInformationDidactic) => {
            const isInfo =
              additionalInformationDidactic.type ===
              ADDITIONAL_INFORMATION_TYPES.NOTIFICATION_INFO;
            const isAlert =
              additionalInformationDidactic.type ===
              ADDITIONAL_INFORMATION_TYPES.NOTIFICATION_ALERT;
            const notificationType =
              (isInfo && NOTIFICATION_TYPES.INFO) ||
              (isAlert && NOTIFICATION_TYPES.ALERT) ||
              undefined;

            return (
              <Banner
                hollow={
                  additionalInformationDidactic.type ===
                  ADDITIONAL_INFORMATION_TYPES.NOTIFICATION_INFO
                }
                key={additionalInformationDidactic.internalId}
                notificationType={notificationType}
                isFluid
              >
                <MDXContent>{additionalInformationDidactic.name}</MDXContent>
              </Banner>
            );
          },
        )}
    </div>
  );
};

AdditionalInformationDidactics.displayName = 'AdditionalInformationDidactics';
