import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Paragraph } from '@savgroup-front-common/core/src/atoms/Paragraph';
import { H1 } from '@savgroup-front-common/core/src/atoms/headings';

export const $PaymentTitleH1 = styled(H1)`
  padding-top: 0;
  padding-bottom: 0;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 20px;
  line-height: 28px;
`;

export const $RowButtonContainer = styled(Row)`
  margin-top: 16px;
`;

export const $Description = styled(Paragraph)`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 16px;
`;

export const $PaymentWrapper = styled(Row)`
  margin-bottom: 16px;
  align-items: center;

  img {
    width: 24px;
  }
`;
