import { RefObject, useEffect } from 'react';

export default function useCloseWhenClickOutside({
  refs,
  onClose,
  shouldAttachListener = true,
}: {
  refs: RefObject<any>[];
  onClose: () => void;
  shouldAttachListener?: boolean;
}) {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (
        (event.target instanceof HTMLElement ||
          event.target instanceof SVGElement) &&
        refs.every((ref) => ref.current && !ref.current.contains(event.target))
      ) {
        onClose();
      }
    }
    if (shouldAttachListener) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, onClose, shouldAttachListener]);
}
