import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.myaccount.claims.confirmation.paymentForm.title',
    defaultMessage: 'Payment details',
  },
  description: {
    id: 'components.myaccount.claims.confirmation.paymentForm.description',
    defaultMessage:
      'Enter details regarding your payment card. Please note that we currently accept only Visa and Mastercard.',
  },
  refuseToPay: {
    id: 'components.myaccount.claims.confirmation.paymentForm.refuseToPay',
    defaultMessage: 'Refuse to pay',
  },
  pay: {
    id: 'components.myaccount.claims.confirmation.paymentForm.pay',
    defaultMessage: 'Pay {amount}',
  },
  creditCardHolder: {
    id: 'components.myaccount.claims.confirmation.paymentForm.creditCardHolder',
    defaultMessage: "Cardholder's name",
  },
  creditCardDetails: {
    id: 'components.myaccount.claims.confirmation.paymentForm.creditCardDetails',
    defaultMessage: "Card's details",
  },
  unknowError: {
    id: 'Reverse.Common.Errors.Unhandled',
    defaultMessage: 'An unknown error happened',
  },
});
