import get from 'lodash/get';
import * as Yup from 'yup';

import { getConstraint } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm/AdditionalInformation.schema';
import { getUniqErrorMessages } from '@savgroup-front-common/core/src/helpers';

import messages from './messages';
import { REASON_SELECTION_FORM_FIELDS } from './ReasonSelectionForm/ReasonSelectionForm.constants';

const buildAddInformationSchema = ({ neededInformationList = [] }) => {
  return neededInformationList.reduce((acc, neededInformationItem) => {
    const { internalId } = neededInformationItem;

    return acc.shape({
      [internalId]: getConstraint(neededInformationItem),
    });
  }, Yup.object());
};

export default ({ products, formValues }) => {
  const { neededInformationSchema, reasonSchema } = products.reduce(
    (acc, product) => {
      const reason = get(
        formValues,
        [product.productId, REASON_SELECTION_FORM_FIELDS.REASON, 'data'],
        [],
      );

      return {
        ...acc,
        neededInformationSchema: {
          ...get(acc, 'neededInformationSchema', {}),
          [product.sellerProductId]: buildAddInformationSchema({
            neededInformationList: reason.neededInformation || [],
          }),
        },
        reasonSchema: {
          ...acc.reasonSchema,
          [product.productId]: Yup.object().shape({
            reason: Yup.object()
              .nullable()
              .required(messages.thisFieldIsRequired),
            reasonComment: reason.mandatoryComment
              ? Yup.string().required(messages.thisFieldIsRequired)
              : Yup.string(),
            reasonDocument: Yup.array()
              .of(
                Yup.mixed()
                  .nullable()
                  .test('validFile', (value, { createError, path }) => {
                    if (value?.errors) {
                      return createError({
                        message: getUniqErrorMessages([value.errors[0]])[0],
                        path,
                      });
                    }

                    return true;
                  }),
              )
              .test(
                'required-file',
                messages.documentRequired,
                (value, context) => {
                  const { reason } = context.parent;

                  if (reason?.data?.mandatoryDocument) {
                    return value.some((document) => document);
                  }

                  return true;
                },
              ),
          }),
        },
      };
    },
    { neededInformationSchema: {}, reasonSchema: {} },
  );

  return Yup.object().shape({
    [REASON_SELECTION_FORM_FIELDS.ADDITIONAL_INFORMATION]: Yup.object().shape(
      neededInformationSchema,
    ),
    form: Yup.object().shape(reasonSchema), // ligne qui bug
    solutionId: Yup.string().required(messages.thisFieldIsRequired),
  });
};
