import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import md5 from 'md5';
import { Action } from 'redux-actions';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  COUNTRY_CODES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  AddressInfoDto,
  PickupPointAddress,
  ReverseMoney,
} from '@savgroup-front-common/types';

import { buildUrl, squashTakeLatest } from '../../helpers';
import { callAndGetResponse, reduxExtendedRequestSaga } from '../../services';
import { selectLocale } from '../i18n/selectors';
import { accessTokenSelector } from '../selectors';

import { setStoreActor } from './actionCreators';
import * as actionTypes from './actionTypes';
import { LOAD_FULL_CLAIMS_BY_GROUP_ID } from './actionTypes';
import { setDeadEndForClaimGroupWatcher } from './sagas/claimDeadEnd';
import {
  loadStoreActorOptionsWatcher,
  setStoreActorWatcher,
} from './sagas/storeActor';
import * as Selectors from './selectors';
import {
  selectGetFullClaimsByGroupIdWasLoaded,
  selectGetTranslationWasLoaded,
  selectLoadIssuesOptionsByOrderWasLoaded,
} from './selectors';

function* deleteClaimByIdWorker({
  payload: claimId,
}: {
  payload: string;
}): Generator {
  yield callAndGetResponse(
    actionTypes.DELETE_CLAIM_BY_ID,
    `${APIConfiguration.claim}claims/${claimId}`,
    {
      method: SUPPORTED_METHODS.DELETE,
    },
    {
      claimId,
    },
  );
  yield put(actionTypes.DELETE_CLAIM_BY_ID.end());
}
function* deleteClaimByIdWatcher() {
  yield takeEvery<Action<string>>(
    actionTypes.DELETE_CLAIM_BY_ID.BASE,
    deleteClaimByIdWorker,
  );
}

function* loadClaimsByGroupIdWorker({
  payload: claimGroupId,
}: {
  payload: string;
}): Generator {
  yield callAndGetResponse(
    actionTypes.LOAD_CLAIMS_BY_GROUP_ID,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}/claimsSummary`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      claimGroupId,
      indexer: claimGroupId,
    },
  );
  yield put(actionTypes.LOAD_CLAIMS_BY_GROUP_ID.end());
}

function* loadClaimsByGroupIdWatcher() {
  yield takeEvery<Action<string>>(
    actionTypes.LOAD_CLAIMS_BY_GROUP_ID.BASE,
    loadClaimsByGroupIdWorker,
  );
}

function* loadIssuesByClaimWorker({
  payload: claimId,
}: {
  payload: string;
}): Generator {
  yield callAndGetResponse(
    actionTypes.LOAD_ISSUES_OPTIONS_BY_CLAIM,
    `${APIConfiguration.claim}claims/${claimId}/issues`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      claimId,
    },
  );
  yield put(actionTypes.LOAD_ISSUES_OPTIONS_BY_CLAIM.end());
}

function* loadIssuesByClaimWatcher() {
  yield takeEvery<Action<string>>(
    actionTypes.LOAD_ISSUES_OPTIONS_BY_CLAIM.BASE,
    loadIssuesByClaimWorker,
  );
}

interface LoadIssuesByOrderPayload {
  orderId: string;
  ignoreDateRulesChecking: boolean;
}

function* loadIssuesByOrderWorker({
  payload: { orderId, ignoreDateRulesChecking },
}: {
  payload: LoadIssuesByOrderPayload;
}): any {
  const locale = yield select(selectLocale) as any;
  const wasLoaded = yield select((state) =>
    selectLoadIssuesOptionsByOrderWasLoaded(state, {
      indexer: md5(`${locale}_${orderId}`),
    }),
  );

  if (wasLoaded) {
    yield put(
      actionTypes.LOAD_ISSUES_OPTIONS_BY_ORDER.end({
        orderId,
        indexer: md5(`${locale}_${orderId}`),
      }),
    );

    return;
  }

  yield callAndGetResponse(
    actionTypes.LOAD_ISSUES_OPTIONS_BY_ORDER,
    buildUrl(
      `${APIConfiguration.claim}issues/forOrder/${orderId}`,
      { ignoreDateRulesChecking },
      { encode: false, arrayFormat: 'brackets' },
    ),
    {
      method: SUPPORTED_METHODS.GET,
    },
    { orderId, indexer: md5(`${locale}_${orderId}`) },
  );

  yield put(
    actionTypes.LOAD_ISSUES_OPTIONS_BY_ORDER.end({
      orderId,
      indexer: md5(`${locale}_${orderId}`),
    }),
  );
}
function* loadIssuesByOrderWatcher() {
  yield takeEvery<Action<LoadIssuesByOrderPayload>>(
    actionTypes.LOAD_ISSUES_OPTIONS_BY_ORDER.BASE,
    loadIssuesByOrderWorker,
  );
}

function* loadReasonsByClaimWorker({
  payload: claimId,
}: {
  payload: string;
}): Generator {
  yield callAndGetResponse(
    actionTypes.LOAD_REASONS_OPTIONS_BY_CLAIM,
    `${APIConfiguration.claim}claims/${claimId}/reasons`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      claimId,
      indexer: claimId,
    },
  );
  yield put(actionTypes.LOAD_REASONS_OPTIONS_BY_CLAIM.end());
}
function* loadReasonsByClaimWatcher() {
  yield takeEvery<Action<string>>(
    actionTypes.LOAD_REASONS_OPTIONS_BY_CLAIM.BASE,
    loadReasonsByClaimWorker,
  );
}

function* loadSolutionsByClaimWorker({
  payload: claimId,
}: {
  payload: string;
}) {
  yield callAndGetResponse(
    actionTypes.LOAD_SOLUTIONS_OPTIONS_BY_CLAIM,
    `${APIConfiguration.claim}claims/${claimId}/solutions`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      claimId,
    },
  );
  yield put(actionTypes.LOAD_SOLUTIONS_OPTIONS_BY_CLAIM.end());
}

function* loadSolutionsByClaimWatcher() {
  yield takeEvery<Action<string>>(
    actionTypes.LOAD_SOLUTIONS_OPTIONS_BY_CLAIM.BASE,
    loadSolutionsByClaimWorker,
  );
}

interface SelectIssueForClaimPayload {
  claimId: string;
  issueId: string;
}

function* selectIssueForClaimWorker({
  payload: { claimId, issueId },
}: {
  payload: SelectIssueForClaimPayload;
}): Generator {
  const meta = {
    claimId,
    issueId,
  };

  yield callAndGetResponse(
    actionTypes.SELECT_ISSUE_FOR_CLAIM,
    `${APIConfiguration.claim}claims/${claimId}/issue`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {
        issueId,
      },
    },
    meta,
  );
  yield put(actionTypes.SELECT_ISSUE_FOR_CLAIM.end(meta));
}

function* selectIssueForClaimWatcher() {
  yield takeEvery<Action<SelectIssueForClaimPayload>>(
    actionTypes.SELECT_ISSUE_FOR_CLAIM.BASE,
    selectIssueForClaimWorker,
  );
}

interface SelectReasonForClaimPayload {
  claimId: string;
  reasonId: string;
  reasonComment: string;
}

function* selectReasonForClaimWorker({
  payload: { claimId, reasonId, reasonComment },
}: {
  payload: SelectReasonForClaimPayload;
}): any {
  const claims: any = yield select(Selectors.claims);
  const meta = {
    claimId,
    reasonId,
  };

  yield callAndGetResponse(
    actionTypes.SELECT_REASON_FOR_CLAIM,
    `${APIConfiguration.claim}claims/${claimId}/reason`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {
        reasonId,
        reasonComment: reasonComment || '',
        diagnosticComment: '',
      },
    },
    meta,
  );

  const solutionId = get(claims.getIn([claimId, 'value']), 'solutionTypeId');
  const solutionPrice = get(claims.getIn([claimId, 'value']), 'solutionPrice');

  yield put(
    actionTypes.SELECT_REASON_FOR_CLAIM.end({
      claimId,
      reasonId,
      previousReasonId: get(claims.getIn([claimId, 'value']), 'reasonId'),
      solutionPrice,
      solutionId,
    }),
  );
}
function* selectReasonForClaimWatcher() {
  yield takeEvery<Action<SelectReasonForClaimPayload>>(
    actionTypes.SELECT_REASON_FOR_CLAIM.BASE,
    selectReasonForClaimWorker,
  );
}

interface SelectSolutionForClaimPayload {
  claimId: string;
  solutionTypeId: string;
  solutionPrice: ReverseMoney;
}

function* selectSolutionForClaimWorker({
  payload: { claimId, solutionTypeId, solutionPrice },
}: {
  payload: SelectSolutionForClaimPayload;
}) {
  yield callAndGetResponse(
    actionTypes.SELECT_SOLUTION_FOR_CLAIM,
    `${APIConfiguration.claim}claims/${claimId}/solution`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {
        solutionTypeId,
        solutionPrice,
      },
    },
    {
      claimId,
      solutionTypeId,
    },
  );
  yield put(actionTypes.SELECT_SOLUTION_FOR_CLAIM.end());
}
function* selectSolutionForClaimWatcher() {
  yield takeEvery<Action<SelectSolutionForClaimPayload>>(
    actionTypes.SELECT_SOLUTION_FOR_CLAIM.BASE,
    selectSolutionForClaimWorker,
  );
}

interface LoadHandlingByClaimGroupPayload {
  claimGroupId: string;
}

function* loadHandlingByClaimGroupWorker({
  payload: { claimGroupId },
}: {
  payload: LoadHandlingByClaimGroupPayload;
}): Generator {
  yield callAndGetResponse(
    actionTypes.LOAD_HANDLING_BY_CLAIM_GROUP,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}/handling`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      claimGroupId,
    },
  );
  yield put(actionTypes.LOAD_HANDLING_BY_CLAIM_GROUP.end());
}

function* loadHandlingByClaimGroupWatcher() {
  yield takeEvery<Action<LoadHandlingByClaimGroupPayload>>(
    actionTypes.LOAD_HANDLING_BY_CLAIM_GROUP.BASE,
    loadHandlingByClaimGroupWorker,
  );
}

interface LoadCarriersByClaimPayload {
  claimId: string;
  handlingMode: string;
}

function* loadCarriersByClaimWorker({
  payload: { claimId, handlingMode },
}: {
  payload: LoadCarriersByClaimPayload;
}): Generator {
  yield callAndGetResponse(
    actionTypes.LOAD_CARRIERS_OPTIONS_BY_CLAIM,
    `${APIConfiguration.claim}claims/${claimId}/carriers?mode=${handlingMode}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      claimId,
      handlingMode,
    },
  );
  yield put(actionTypes.LOAD_CARRIERS_OPTIONS_BY_CLAIM.end());
}

function* loadCarriersByClaimWatcher() {
  yield takeEvery<Action<LoadCarriersByClaimPayload>>(
    actionTypes.LOAD_CARRIERS_OPTIONS_BY_CLAIM.BASE,
    loadCarriersByClaimWorker,
  );
}

interface LoadCarriersByClaimGroupPayload {
  claimGroupId: string;
  handlingMode: string;
}

function* loadCarriersByClaimGroupWorker({
  payload: { claimGroupId, handlingMode },
}: {
  payload: LoadCarriersByClaimGroupPayload;
}): Generator {
  yield callAndGetResponse(
    actionTypes.LOAD_CARRIERS_OPTIONS_BY_CLAIM_GROUP,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}/carriers?mode=${handlingMode}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      claimGroupId,
      handlingMode,
    },
  );
  yield put(actionTypes.LOAD_CARRIERS_OPTIONS_BY_CLAIM_GROUP.end());
}

function* loadCarriersByClaimGroupWatcher() {
  yield takeEvery<Action<LoadCarriersByClaimGroupPayload>>(
    actionTypes.LOAD_CARRIERS_OPTIONS_BY_CLAIM_GROUP.BASE,
    loadCarriersByClaimGroupWorker,
  );
}

interface LoadCarrierSummaryByClaimGroupPayload {
  claimGroupId: string;
}

function* loadCarrierSummaryByClaimGroupWorker({
  payload: { claimGroupId },
}: {
  payload: LoadCarrierSummaryByClaimGroupPayload;
}): Generator {
  yield callAndGetResponse(
    actionTypes.LOAD_CARRIER_SUMMARY_BY_CLAIM_GROUP,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}/carrier`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      claimGroupId,
    },
  );
  yield put(actionTypes.LOAD_CARRIER_SUMMARY_BY_CLAIM_GROUP.end());
}

function* loadCarrierSummaryByClaimGroupWatcher() {
  yield takeEvery<Action<LoadCarrierSummaryByClaimGroupPayload>>(
    actionTypes.LOAD_CARRIER_SUMMARY_BY_CLAIM_GROUP.BASE,
    loadCarrierSummaryByClaimGroupWorker,
  );
}

interface LoadPickupPointsByCarrierPayload {
  countryCode: COUNTRY_CODES;
  address: AddressInfoDto;
  carrierCompany: string;
  productType: string;
  sellerId: string;
  maxDistance: number;
  claimId: string;
}

function* loadPickupPointsByCarrierWorker({
  payload: {
    countryCode,
    address,
    carrierCompany,
    productType,
    sellerId,
    maxDistance = 200,
    claimId,
  },
}: {
  payload: LoadPickupPointsByCarrierPayload;
}): Generator {
  const meta = {
    carrierCompany,
    countryCode,
    productType,
    sellerId,
  };

  const payload = {
    CountryCode: countryCode,
    carrierCompany: carrierCompany === 'chrono' ? 'Chronopost' : carrierCompany,
    distance: maxDistance,
    maxPickupPoint: 10,
    productType,
    sellerId,
    address,
    claimId,
  };

  const url = `${APIConfiguration.carrier}pickuppoints/coord`;
  const parameters = pickBy(payload, identity);
  const query = buildUrl(url, parameters as Record<string, string>);

  yield callAndGetResponse(
    actionTypes.LOAD_PICKUP_POINTS_OPTIONS_BY_CARRIER,
    query,
    { method: SUPPORTED_METHODS.GET },
    meta,
  );

  yield put(actionTypes.LOAD_PICKUP_POINTS_OPTIONS_BY_CARRIER.end());
}

function* loadPickupPointsByCarrierWatcher() {
  yield squashTakeLatest(
    actionTypes.LOAD_PICKUP_POINTS_OPTIONS_BY_CARRIER.BASE,
    loadPickupPointsByCarrierWorker,
    ({
      payload: { carrierCompany, countryCode, productType },
    }: {
      payload: LoadPickupPointsByCarrierPayload;
    }) => [carrierCompany, productType, countryCode].join(';'),
  );
}

function* loadConfirmationInfoByClaimWorker({
  payload: claimId,
}: {
  payload: string;
}): Generator {
  yield callAndGetResponse(
    actionTypes.LOAD_CONFIRMATION_INFO_BY_CLAIM,
    `${APIConfiguration.claim}claims/${claimId}/confirmation`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      claimId,
    },
  );
  yield put(actionTypes.LOAD_CONFIRMATION_INFO_BY_CLAIM.end());
}

function* loadConfirmationInfoByClaimWatcher() {
  yield takeEvery<Action<string>>(
    actionTypes.LOAD_CONFIRMATION_INFO_BY_CLAIM.BASE,
    loadConfirmationInfoByClaimWorker,
  );
}

function* loadConfirmationInfoByClaimGroupWorker({
  payload: claimGroupId,
}: {
  payload: string;
}): Generator {
  const meta = {
    claimGroupId,
  };

  if (!claimGroupId) {
    yield put(actionTypes.LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP.end(meta));

    return;
  }

  yield callAndGetResponse(
    actionTypes.LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}/confirmation`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    meta,
  );
  yield put(actionTypes.LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP.end(meta));
}

function* loadConfirmationInfoByClaimGroupWatcher() {
  yield takeEvery<Action<string>>(
    actionTypes.LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP.BASE,
    loadConfirmationInfoByClaimGroupWorker,
  );
}

function* loadFullClaimsByGroupIdWorker({
  payload: { claimGroupId, forceRefresh },
}: {
  payload: { claimGroupId: string; forceRefresh: boolean };
}): Generator {
  const wasLoaded = yield select((state) =>
    selectGetFullClaimsByGroupIdWasLoaded(state, {
      claimGroupId,
    }),
  );

  if (wasLoaded && !forceRefresh) {
    yield put(LOAD_FULL_CLAIMS_BY_GROUP_ID.end());

    return;
  }

  yield callAndGetResponse(
    LOAD_FULL_CLAIMS_BY_GROUP_ID,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}/full`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      claimGroupId,
      indexer: claimGroupId,
    },
  );

  yield put(LOAD_FULL_CLAIMS_BY_GROUP_ID.end());
}

function* loadFullClaimsByGroupIdWatcher() {
  yield takeEvery<Action<{ claimGroupId: string; forceRefresh: boolean }>>(
    LOAD_FULL_CLAIMS_BY_GROUP_ID.BASE,
    loadFullClaimsByGroupIdWorker,
  );
}

interface SelectHandlingForClaimGroupPayload {
  claimGroupId: string;
  handlingMode: string;
  pickupPointId: string;
  pickupPointAddress: PickupPointAddress;
  carrierPriceId: {
    carrierSellerPriceId: string;
    carrierCustomerPriceId: string;
  };
  address: AddressInfoDto;
}

function* selectHandlingForClaimGroupWorker({
  payload: {
    claimGroupId,
    handlingMode,
    pickupPointId,
    pickupPointAddress,
    carrierPriceId,
    address,
  },
}: {
  payload: SelectHandlingForClaimGroupPayload;
}): Generator {
  yield callAndGetResponse(
    actionTypes.SELECT_HANDLING_FOR_CLAIM_GROUP,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}/handling`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        handlingMode,
        ...carrierPriceId,
        address,
        pickupPointId,
        pickupPointAddress,
      },
    },
    {
      claimGroupId,
      handlingMode,
      pickupPointId,
    },
  );
  yield put(actionTypes.SELECT_HANDLING_FOR_CLAIM_GROUP.end());
}

function* selectHandlingForClaimGroupWatcher() {
  yield takeEvery<Action<SelectHandlingForClaimGroupPayload>>(
    actionTypes.SELECT_HANDLING_FOR_CLAIM_GROUP.BASE,
    selectHandlingForClaimGroupWorker,
  );
}

interface ConfirmClaimGroupPayload {
  claimGroupId: string;
  phone?: string;
  phoneNumber: string;
  email?: string;
  mail: string;
  swornStatement?: boolean;
  generalSalesCondition?: string;
  imei?: string;
  iMEI?: string;
  serialNumber?: string;
  devicePassword?: string;
  issueDate?: string;
  origin?: string;
  ticketId?: string;
  invoiceId?: string;
  sourceTokenId?: string;
  storeActorId?: string;
  claimIds: string[];
}

function* confirmClaimGroupWorker({
  payload,
}: {
  payload: ConfirmClaimGroupPayload;
}): Generator {
  const {
    claimGroupId,
    phone,
    phoneNumber,
    email,
    mail,
    swornStatement,
    generalSalesCondition,
    imei,
    iMEI,
    serialNumber,
    devicePassword,
    issueDate,
    origin,
    ticketId,
    invoiceId,
    sourceTokenId,
    storeActorId,
    claimIds = [],
  } = payload;

  yield callAndGetResponse(
    actionTypes.CONFIRM_CLAIM_GROUP,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}/confirmation`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {
        phoneNumber: phone || phoneNumber,
        mail: email || mail,
        swornStatement: swornStatement || undefined,
        generalSalesCondition: generalSalesCondition || undefined,
        iMEI: imei || iMEI || undefined,
        devicePassword: devicePassword || undefined,
        serialNumber: serialNumber || undefined,
        invoiceId: invoiceId || undefined,
        sourceTokenId: sourceTokenId || undefined,
        issueDate: issueDate || undefined,
        origin: origin || undefined,
        ticketId: ticketId || undefined,
      },
    },
    {
      claimGroupId,
    },
  );

  if (storeActorId) {
    yield all(
      claimIds.map((claimId) => put(setStoreActor({ claimId, storeActorId }))),
    );
  }

  yield put(actionTypes.CONFIRM_CLAIM_GROUP.end());
}

function* confirmClaimGroupWatcher() {
  yield takeEvery<Action<ConfirmClaimGroupPayload>>(
    actionTypes.CONFIRM_CLAIM_GROUP.BASE,
    confirmClaimGroupWorker,
  );
}

interface SetDocumentForClaimGroupPayload {
  file: any;
  claimGroupId: string;
  documentType: string;
  claimIds: string[];
  claimId: string;
}

function* setDocumentForClaimGroupWorker({
  payload: { file, claimGroupId, documentType, claimIds, claimId },
}: {
  payload: SetDocumentForClaimGroupPayload;
}): Generator {
  const { name, size, type } = file;
  const meta = { claimId, name, size, type };
  const accessToken = (yield select(accessTokenSelector)) as string;
  const language = (yield select(selectLocale)) as string;
  const body = new FormData();

  body.append('file', file);
  body.append('claimIds', String(claimIds));
  yield reduxExtendedRequestSaga(
    actionTypes.SET_DOCUMENT_FOR_CLAIM_GROUP,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}/${documentType}/upload`,
    {
      method: SUPPORTED_METHODS.POST,
      body,
      accessToken,
      language,
    },
    meta,
  );
  yield put(actionTypes.SET_DOCUMENT_FOR_CLAIM_GROUP.end(meta));
}

function* setDocumentForClaimGroupWatcher() {
  yield takeEvery<Action<SetDocumentForClaimGroupPayload>>(
    actionTypes.SET_DOCUMENT_FOR_CLAIM_GROUP.BASE,
    setDocumentForClaimGroupWorker,
  );
}

interface DeleteDocumentForClaimGroupPayload {
  claimGroupId: string;
  documentId: string;
  claimId: string;
}

function* deleteDocumentForClaimGroupWorker({
  payload,
}: {
  payload: DeleteDocumentForClaimGroupPayload;
}): Generator {
  const { claimGroupId, documentId, claimId } = payload;
  const meta = { claimId };

  yield callAndGetResponse(
    actionTypes.DELETE_DOCUMENT_FOR_CLAIM_GROUP,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}/uploads/${documentId}`,
    {
      method: SUPPORTED_METHODS.DELETE,
    },
    meta,
  );
  yield put(actionTypes.DELETE_DOCUMENT_FOR_CLAIM_GROUP.end(meta));
}

function* deleteDocumentForClaimGroupWatcher() {
  yield takeEvery<Action<DeleteDocumentForClaimGroupPayload>>(
    actionTypes.DELETE_DOCUMENT_FOR_CLAIM_GROUP.BASE,
    deleteDocumentForClaimGroupWorker,
  );
}

interface CreateClaimForOwnerPayload {
  ownerId: string;
  ownerProductId: string;
}

function* createClaimForOwnerWorker({
  payload: { ownerId, ownerProductId },
}: {
  payload: CreateClaimForOwnerPayload;
}): Generator {
  yield callAndGetResponse(
    actionTypes.CREATE_CLAIM_FOR_OWNER,
    `${APIConfiguration.claim}claims`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        ownerId,
        ownerProductId,
      },
    },
    {
      ownerId,
      ownerProductId,
    },
  );
  yield put(actionTypes.CREATE_CLAIM_FOR_OWNER.end());
}

function* createClaimForOwnerWatcher() {
  yield takeEvery<Action<CreateClaimForOwnerPayload>>(
    actionTypes.CREATE_CLAIM_FOR_OWNER.BASE,
    createClaimForOwnerWorker,
  );
}

interface CreateClaimGroupWithIssueForOwnerPayload {
  ownerId: string;
  ownerProductIds: string[];
  issueId: string;
  origin: string;
}

function* createClaimGroupWithIssueForOwnerWorker({
  payload: { ownerId, ownerProductIds, issueId, origin },
}: {
  payload: CreateClaimGroupWithIssueForOwnerPayload;
}): Generator {
  yield callAndGetResponse(
    actionTypes.CREATE_CLAIM_GROUP_WITH_ISSUE_FOR_OWNER,
    `${APIConfiguration.claim}claimGroups/withIssue`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        ownerId,
        ownerProductIds,
        issueId,
        origin,
      },
    },
    {
      ownerId,
      ownerProductIds,
    },
  );

  yield put(
    actionTypes.CREATE_CLAIM_GROUP_WITH_ISSUE_FOR_OWNER.end({
      ownerId,
      ownerProductIds,
    }),
  );
}

function* createClaimGroupWithIssueForOwnerWatcher() {
  yield takeEvery<Action<CreateClaimGroupWithIssueForOwnerPayload>>(
    actionTypes.CREATE_CLAIM_GROUP_WITH_ISSUE_FOR_OWNER.BASE,
    createClaimGroupWithIssueForOwnerWorker,
  );
}

function* deleteClaimGroupByIdWorker({
  payload: claimGroupId,
}: {
  payload: string;
}): Generator {
  yield callAndGetResponse(
    actionTypes.DELETE_CLAIM_GROUP_BY_ID,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}`,
    { method: SUPPORTED_METHODS.DELETE },
    {
      claimGroupId,
      indexer: claimGroupId,
    },
  );

  yield put(actionTypes.DELETE_CLAIM_GROUP_BY_ID.end());
}
function* deleteClaimGroupByIdWatcher() {
  yield takeEvery<Action<string>>(
    actionTypes.DELETE_CLAIM_GROUP_BY_ID.BASE,
    deleteClaimGroupByIdWorker,
  );
}

function* loadClaimGroupByIdWorker({
  payload: claimGroupId,
}: {
  payload: string;
}): Generator {
  yield callAndGetResponse(
    actionTypes.LOAD_CLAIM_GROUP_BY_ID,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      claimGroupId,
    },
  );
  yield put(actionTypes.LOAD_CLAIM_GROUP_BY_ID.end());
}

function* loadClaimGroupByIdWatcher() {
  yield takeEvery<Action<string>>(
    actionTypes.LOAD_CLAIM_GROUP_BY_ID.BASE,
    loadClaimGroupByIdWorker,
  );
}

function* loadClaimProductWorker({
  payload: claimId,
}: {
  payload: string;
}): Generator {
  yield callAndGetResponse(
    actionTypes.LOAD_CLAIM_PRODUCT,
    `${APIConfiguration.claim}claims/${claimId}/product`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      claimId,
    },
  );
  yield put(
    actionTypes.LOAD_CLAIM_PRODUCT.end({
      claimId,
    }),
  );
}

function* loadClaimProductWatcher() {
  yield takeEvery<Action<string>>(
    actionTypes.LOAD_CLAIM_PRODUCT.BASE,
    loadClaimProductWorker,
  );
}

function* getReasonCommentWorker({
  payload: claimId,
}: {
  payload: string;
}): Generator {
  yield callAndGetResponse(
    actionTypes.GET_REASON_COMMENT,
    `${APIConfiguration.claim}claims/${claimId}/reasonComment`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      claimId,
    },
  );
  yield put(actionTypes.GET_REASON_COMMENT.end());
}

function* getReasonCommentWatcher() {
  yield takeEvery<Action<string>>(
    actionTypes.GET_REASON_COMMENT.BASE,
    getReasonCommentWorker,
  );
}

interface SetReasonCommentForClaimGroupPayload {
  claimGroupId: string;
  ownerProductIds: string[];
  reasonComment: string;
}

function* setReasonCommentForClaimGroupWorker({
  payload: { claimGroupId, ownerProductIds, reasonComment },
}: {
  payload: SetReasonCommentForClaimGroupPayload;
}): Generator {
  const meta = { claimGroupId, ownerProductIds, reasonComment };

  yield callAndGetResponse(
    actionTypes.SET_REASON_COMMENT_FOR_CLAIM_GROUP,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}/reasonComment`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {
        ownerProductIds,
        reasonComment,
      },
    },
    {
      meta,
    },
  );
  yield put(actionTypes.SET_REASON_COMMENT_FOR_CLAIM_GROUP.end());
}

function* setReasonCommentForClaimGroupWatcher() {
  yield takeEvery<Action<SetReasonCommentForClaimGroupPayload>>(
    actionTypes.SET_REASON_COMMENT_FOR_CLAIM_GROUP.BASE,
    setReasonCommentForClaimGroupWorker,
  );
}

interface GetSellerInfoPayload {
  sellerId: string;
  sellerProductId: string;
}

function* getSellerInfoWorker({
  payload,
}: {
  payload: GetSellerInfoPayload;
}): Generator {
  const { sellerId, sellerProductId } = payload;

  if (!sellerId || !sellerProductId) {
    logCritical(
      `getSellerInfoWorker called with null payload ${JSON.stringify(
        {
          sellerId,
          getSellerInfoWorker,
        },
        null,
        2,
      )}`,
    );

    return;
  }

  yield callAndGetResponse(
    actionTypes.GET_SELLER_INFO,
    `${APIConfiguration.config}sellers/${payload.sellerId}/products/${payload.sellerProductId}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      sellerId: payload.sellerId,
      sellerProductId: payload.sellerProductId,
    },
  );
  yield put(actionTypes.GET_SELLER_INFO.end());
}

function* getSellerInfoWatcher() {
  yield takeEvery<Action<GetSellerInfoPayload>>(
    actionTypes.GET_SELLER_INFO.BASE,
    getSellerInfoWorker,
  );
}

function* getRmaSupplierInfosWorker({
  payload: claimId,
}: {
  payload: string;
}): Generator {
  const meta = { claimId };

  yield callAndGetResponse(
    actionTypes.GET_RMA_SUPPLIER_INFOS,
    `${APIConfiguration.claim}claims/${claimId}/rma/supplier`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    meta,
  );
  yield put(actionTypes.GET_RMA_SUPPLIER_INFOS.end(meta));
}

function* getRmaSupplierInfosWatcher() {
  yield takeEvery<Action<string>>(
    actionTypes.GET_RMA_SUPPLIER_INFOS.BASE,
    getRmaSupplierInfosWorker,
  );
}

function* getOrderIdByClaimGroupWorker({
  payload: claimGroupId,
}: {
  payload: string;
}): Generator {
  const meta = { claimGroupId };

  yield callAndGetResponse(
    actionTypes.GET_ORDER_BY_CLAIM_GROUP,
    `${APIConfiguration.claim}claimGroups/${claimGroupId}/orderId`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    meta,
  );
  yield put(actionTypes.GET_ORDER_BY_CLAIM_GROUP.end(meta));
}

function* getOrderIdByClaimGroupWatcher() {
  yield takeEvery<Action<string>>(
    actionTypes.GET_ORDER_BY_CLAIM_GROUP.BASE,
    getOrderIdByClaimGroupWorker,
  );
}

interface SetReasonAdditionalInformationPayload {
  claimId: string;
  informations: any;
}

function* setReasonAdditionalInformationWorker({
  payload,
}: {
  payload: SetReasonAdditionalInformationPayload;
}): Generator {
  const { claimId, informations } = payload;
  const meta = { claimId, informations };

  yield callAndGetResponse(
    actionTypes.SET_REASON_ADDITIONAL_INFORMATION,
    `${APIConfiguration.claim}claims/${claimId}/additionalInformation`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: { additionalClaimInformation: informations },
    },
    meta,
  );
  yield put(actionTypes.SET_REASON_ADDITIONAL_INFORMATION.end(meta));
}

function* setReasonAdditionalInformationWatcher() {
  yield takeEvery<Action<SetReasonAdditionalInformationPayload>>(
    actionTypes.SET_REASON_ADDITIONAL_INFORMATION.BASE,
    setReasonAdditionalInformationWorker,
  );
}

interface LoadTranslationPayload {
  ownerProductId: string;
  issueId?: string;
  reasonId?: string;
  warrantyTypeId?: string;
  solutionId?: string;
}

function* loadTranslationWorker({
  payload: { issueId, reasonId, solutionId, warrantyTypeId },
}: {
  payload: LoadTranslationPayload;
}) {
  const indexer = `${issueId}_${reasonId}_${solutionId}_${warrantyTypeId}`;

  const wasLoaded = (yield select(selectGetTranslationWasLoaded, {
    indexer,
  })) as boolean;

  if (wasLoaded) {
    yield put(actionTypes.LOAD_TRANSLATION.end());

    return;
  }

  yield callAndGetResponse(
    actionTypes.LOAD_TRANSLATION,
    `${APIConfiguration.claim}irsh/translate`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        issueId,
        reasonId,
        solutionId,
        warrantyTypeId,
      },
    },
    { issueId, reasonId, solutionId, warrantyTypeId, indexer },
  );
  yield put(actionTypes.LOAD_TRANSLATION.end());
}

function* loadTranslationWatcher() {
  yield takeEvery<Action<LoadTranslationPayload>>(
    actionTypes.LOAD_TRANSLATION.BASE,
    loadTranslationWorker,
  );
}

interface SetReasonAdditionalInformationFilePayload {
  claimId: string;
  file: any;
  additionalInformationId: string;
}

function* setReasonAdditionalInformationFileWorker({
  payload,
}: {
  payload: SetReasonAdditionalInformationFilePayload;
}): Generator {
  const { claimId, file, additionalInformationId } = payload;
  const meta = { claimId, file };
  const accessToken = (yield select(accessTokenSelector)) as string;
  const language = (yield select(selectLocale)) as string;
  const body = new FormData();

  if (file) {
    body.append('fileBinary', file);
  }
  yield reduxExtendedRequestSaga(
    actionTypes.SET_REASON_ADDITIONAL_INFORMATION_FILE,
    `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${additionalInformationId}/file`,
    {
      method: SUPPORTED_METHODS.POST,
      body,
      accessToken,
      language,
    },
    meta,
  );
  yield put(actionTypes.SET_REASON_ADDITIONAL_INFORMATION_FILE.end(meta));
}

function* setReasonAdditionalInformationFileWatcher() {
  yield takeEvery<Action<SetReasonAdditionalInformationFilePayload>>(
    actionTypes.SET_REASON_ADDITIONAL_INFORMATION_FILE.BASE,
    setReasonAdditionalInformationFileWorker,
  );
}

interface DeleteSetReasonAdditionalInformationFilePayload {
  claimId: string;
  file: any;
  additionalInformationId: string;
  blobValue: any;
}

function* deleteSetReasonAdditionalInformationFileWorker({
  payload,
}: {
  payload: DeleteSetReasonAdditionalInformationFilePayload;
}): Generator {
  const { claimId, file, additionalInformationId, blobValue } = payload;
  const meta = { claimId, file };

  yield callAndGetResponse(
    actionTypes.DELETE_REASON_ADDITIONAL_INFORMATION_FILE,
    `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${additionalInformationId}/file`,
    {
      method: SUPPORTED_METHODS.DELETE,
      json: {
        blobName: blobValue,
      },
    },
    meta,
  );
  yield put(actionTypes.DELETE_REASON_ADDITIONAL_INFORMATION_FILE.end(meta));
}

function* deleteSetReasonAdditionalInformationFileWatcher() {
  yield takeEvery<Action<DeleteSetReasonAdditionalInformationFilePayload>>(
    actionTypes.DELETE_REASON_ADDITIONAL_INFORMATION_FILE.BASE,
    deleteSetReasonAdditionalInformationFileWorker,
  );
}

export default function* mainSaga() {
  try {
    yield all([
      deleteClaimByIdWatcher(),
      loadClaimsByGroupIdWatcher(),
      loadIssuesByClaimWatcher(),
      loadReasonsByClaimWatcher(),
      loadSolutionsByClaimWatcher(),
      loadCarriersByClaimWatcher(),
      loadPickupPointsByCarrierWatcher(),
      loadConfirmationInfoByClaimWatcher(),
      loadConfirmationInfoByClaimGroupWatcher(),
      selectIssueForClaimWatcher(),
      selectReasonForClaimWatcher(),
      selectSolutionForClaimWatcher(),
      setDeadEndForClaimGroupWatcher(),
      confirmClaimGroupWatcher(),
      loadFullClaimsByGroupIdWatcher(),
      createClaimForOwnerWatcher(),
      createClaimGroupWithIssueForOwnerWatcher(),
      loadIssuesByOrderWatcher(),
      deleteClaimGroupByIdWatcher(),
      loadClaimGroupByIdWatcher(),
      loadClaimProductWatcher(),
      getReasonCommentWatcher(),
      loadHandlingByClaimGroupWatcher(),
      loadCarriersByClaimGroupWatcher(),
      loadCarrierSummaryByClaimGroupWatcher(),
      selectHandlingForClaimGroupWatcher(),
      getSellerInfoWatcher(),
      setReasonCommentForClaimGroupWatcher(),
      getRmaSupplierInfosWatcher(),
      getOrderIdByClaimGroupWatcher(),
      setDocumentForClaimGroupWatcher(),
      deleteDocumentForClaimGroupWatcher(),
      setReasonAdditionalInformationWatcher(),
      loadTranslationWatcher(),
      setReasonAdditionalInformationFileWatcher(),
      deleteSetReasonAdditionalInformationFileWatcher(),
      loadStoreActorOptionsWatcher(),
      setStoreActorWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
