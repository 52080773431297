import { defineMessages } from 'react-intl';

export default defineMessages({
  isRequired: {
    id: 'components.myaccount.claims.confirmation.requiredField',
    defaultMessage: 'Required field',
  },

  summary: {
    id: 'components.myaccount.claims.confirmation.summary',
    defaultMessage: 'File summary',
  },
  product: {
    id: 'components.myaccount.claims.confirmation.product',
    defaultMessage: 'Product',
  },
  payment: {
    id: 'components.myaccount.claims.confirmation.payment',
    defaultMessage: 'Payment',
  },
  issue: {
    id: 'components.myaccount.common.issue',
    defaultMessage: 'Issue',
  },
  reason: {
    id: 'view.card.claim.reasonName',
    defaultMessage: 'Reason',
  },
  solution: {
    id: 'components.myaccount.common.solution',
    defaultMessage: 'Solution',
  },
  solutionType: {
    id: 'components.myaccount.claims.confirmation.solutionType',
    defaultMessage: 'Type',
  },
  price: {
    id: 'components.myaccount.claims.confirmation.price',
    defaultMessage: 'Price',
  },
  deliveryHeader: {
    id: 'components.myaccount.claims.confirmation.deliveryHeader',
    defaultMessage: 'Handling (Return)',
  },
  depositHeader: {
    id: 'components.myaccount.claims.confirmation.depositHeader',
    defaultMessage: 'Handling (Outward)',
  },
  onSiteInterventionHeader: {
    id: 'components.myaccount.claims.confirmation.onSiteInterventionHeader',
    defaultMessage: 'Home intervention',
  },
  mustBeValidImei: {
    id: 'components.myaccount.claims.confirmation.mustBeValidImei',
    defaultMessage:
      'This field must be a valid IMEI number. It should be of the form: 123456789012347.',
  },
  documentRequired: {
    id: 'components.myaccount.claims.confirmation.documentRequired',
    defaultMessage: 'Required document',
  },
  FollowUpSubHeader: {
    id: 'components.myaccount.claims.confirmation.confirmationFollowUp.subheader',
    defaultMessage:
      'In some cases we might need to contact you by phone. Please make sure that the number below is correct',
  },
  mustAgree: {
    id: 'components.myaccount.claims.confirmation.mustAgree',
    defaultMessage: 'Veuillez cocher cette case',
  },
  phoneMustBeValid: {
    id: 'components.myaccount.claims.confirmation.phoneMustBeValid',
    defaultMessage: 'Please enter a valid phone number',
  },
  emailMustBeValid: {
    id: 'components.myaccount.claims.confirmation.emailMustBeValid',
    defaultMessage: 'Please enter a valid email',
  },
  emailRequired: {
    id: 'components.myaccount.claims.confirmation.emailRequired',
    defaultMessage: 'Required email',
  },
  reasonComment: {
    id: 'components.myaccount.claims.confirmation.reasonComment',
    defaultMessage: 'Comment',
  },
  model: {
    id: 'components.myaccount.order.model',
    defaultMessage: 'Model',
  },
  brand: {
    id: 'components.myaccount.order.brand',
    defaultMessage: 'Brand',
  },
  purchaseDate: {
    id: 'components.myaccount.order.buyDate',
    defaultMessage: 'Purchase date',
  },
  endOfWarranty: {
    id: 'components.myaccount.order.endOfWarranty',
    defaultMessage: 'End of warranty',
  },
  additionalInformation: {
    id: 'components.myaccount.claims.confirmation.ImeiSerial.header',
    defaultMessage: 'Additional information',
  },
  contactInformation: {
    id: 'components.myaccount.claims.confirmation.confirmationFollowUp.header',
    defaultMessage: 'Contact information',
  },
  phoneNumber: {
    id: 'view.card.client.phone',
    defaultMessage: 'Phone number',
  },
  email: {
    id: 'view.component.block.client.email',
    defaultMessage: 'Email',
  },
  edit: {
    id: 'components.myaccount.claims.confirmation.edit',
    defaultMessage: 'Edit',
  },
  documents: {
    id: 'components.myaccount.claims.confirmation.documents',
    defaultMessage: 'Document(s)',
  },
  free: {
    id: 'components.myaccount.claims.confirmation.free',
    defaultMessage: 'Free',
  },
  invoice: {
    id: 'components.common.loading.invoice',
    defaultMessage: 'Loading your invoice...',
  },
});
