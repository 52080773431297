import { css, keyframes } from 'styled-components';

import { getAnimationDelay, getAnimationDuration } from '../helper';
import { decelerateTimingFunctionAnimation } from '../timingFunction/animation';

import { SimpleAnimationProps } from './types';

const enter = css`
  opacity: 0;
  transform: translateY(1rem);
`;

const steady = css`
  opacity: 1;
  transform: translateY(0);
`;

export const slideFromBottomKeyframes = keyframes`
  from { ${enter} }
  to { ${steady} }
`;

export default css<SimpleAnimationProps>`
  ${enter};
  animation-name: ${slideFromBottomKeyframes};
  animation-duration: ${getAnimationDuration};
  animation-delay: ${getAnimationDelay};
  animation-fill-mode: forwards;
  ${decelerateTimingFunctionAnimation};
`;
