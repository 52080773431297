import { rgba } from 'polished';
import styled from 'styled-components';

import { DefaultStyledProps } from '@savgroup-front-common/types';

import { rem } from '../../helpers';
import { getThemeFromProps } from '../../theme/helpers';
import { standardTimingFunctionTransition } from '../../animations/timingFunction';

export const backgroundColorBasedOnProps = (props: any) => {
  const theme = getThemeFromProps(props);

  if (props.disabled) {
    if (props.$isNewBoEnabled) {
      return props.theme.newUI.tagsColors?.disabled.bgColor;
    }

    return rgba(theme.colors.disabled, 0.4);
  }
  if (props.neutral) {
    if (props.$isNewBoEnabled) {
      return props.theme.newUI.tagsColors?.neutral.bgColor;
    }

    return rgba(theme.colors.disabled, 0.8);
  }
  if (props.danger) {
    if (props.$isNewBoEnabled) {
      return props.theme.newUI.tagsColors?.danger.bgColor;
    }

    return rgba(theme.colors.danger, 0.1);
  }
  if (props.alert) {
    if (props.$isNewBoEnabled) {
      return props.theme.newUI.tagsColors?.alert.bgColor;
    }

    return rgba(theme.colors.alert, 0.1);
  }
  if (props.success) {
    if (props.$isNewBoEnabled) {
      return props.theme.newUI.tagsColors?.success.bgColor;
    }

    return rgba(theme.colors.success, 0.1);
  }
  if (props.primary) {
    if (props.$isNewBoEnabled) {
      return props.theme.newUI.tagsColors?.primary.bgColor;
    }

    return rgba(theme.colors.primary, 0.1);
  }
  if (props.secondary) {
    if (props.$isNewBoEnabled) {
      return props.theme.newUI.tagsColors?.secondary.bgColor;
    }

    return rgba(theme.colors.secondary, 0.1);
  }

  if (props.$isNewBoEnabled) {
    return theme.newUI.tagsColors?.alert.bgColor;
  }

  return rgba(theme.colors.alert, 0.2);
};
export const colorBasedOnProps = (props: any) => {
  const theme = getThemeFromProps(props);

  if (props.disabled) {
    if (props.$isNewBoEnabled) {
      return theme.newUI.tagsColors?.disabled.color;
    }

    return theme.colors.paragraphTextColor;
  }
  if (props.neutral) {
    if (props.$isNewBoEnabled) {
      return theme.newUI.tagsColors?.neutral.color;
    }

    return theme.colors.darkBackground;
  }
  if (props.danger) {
    if (props.$isNewBoEnabled) {
      return theme.newUI.tagsColors?.danger.color;
    }

    return theme.colors.danger;
  }
  if (props.alert) {
    if (props.$isNewBoEnabled) {
      return theme.newUI.tagsColors?.alert.color;
    }

    return theme.colors.alert;
  }
  if (props.success) {
    if (props.$isNewBoEnabled) {
      return theme.newUI.tagsColors?.success.color;
    }

    return theme.colors.success;
  }
  if (props.primary) {
    if (props.$isNewBoEnabled) {
      return theme.newUI.tagsColors?.primary.color;
    }

    return theme.colors.primary;
  }
  if (props.secondary) {
    if (props.$isNewBoEnabled) {
      return theme.newUI.tagsColors?.secondary.color;
    }

    return theme.colors.secondary;
  }

  if (props.$isNewBoEnabled) {
    return theme.newUI.tagsColors?.alert.color;
  }

  return theme.colors.alert;
};

interface $TagProps extends DefaultStyledProps {
  disabled?: boolean;
  danger?: boolean;
  alert?: boolean;
  success?: boolean;
  primary?: boolean;
  neutral?: boolean;
  secondary?: boolean;
  withoutMargin?: boolean;
  $noEllispis?: boolean;
  $fluid?: boolean;
  $isNaked: boolean;
  $isSmall?: boolean;
  $isNewBoEnabled?: boolean;
}

export const $Tag = styled.em.attrs(() => ({
  role: 'tag',
}))<$TagProps>`
  width: ${({ $fluid }) => ($fluid ? null : 'fit-content')};
  max-width: ${({ $fluid }) => ($fluid ? null : rem(352))};
  max-height: ${({ $noEllispis }) => ($noEllispis ? null : rem(32))};
  padding: ${({ $isNaked }) =>
    $isNaked ? `${rem(4)} 0` : `${rem(4)} ${rem(10)}`};
  margin: 0;
  margin-bottom: ${({ withoutMargin }) => (withoutMargin ? null : rem(8))};
  font-size: ${({ $isSmall }) => ($isSmall ? rem(10) : rem(12))};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  display: inline-block;
  white-space: ${({ $noEllispis }) => ($noEllispis ? null : 'nowrap')};
  overflow: ${({ $noEllispis }) => ($noEllispis ? null : 'hidden')};
  text-overflow: ${({ $noEllispis }) => ($noEllispis ? null : 'ellipsis')};
  font-style: normal;
  text-align: ${({ $noEllispis }) => ($noEllispis ? 'left' : 'center')};
  background-color: ${({ theme, $isNaked, $isNewBoEnabled, ...rest }) =>
    $isNaked
      ? 'transparent'
      : backgroundColorBasedOnProps({ theme, $isNewBoEnabled, ...rest })};
  border-radius: ${({ theme }) => theme.borders.radius};
  color: ${colorBasedOnProps};
  transition-duration: 0.3s;
  ${standardTimingFunctionTransition};
  text-transform: uppercase;
  line-height: ${({ $isSmall }) => ($isSmall ? rem(12) : '')};
  display: flex;
  align-items: center;
  position: relative;
`;
