import { ANIMATION_DIRECTION } from '@savgroup-front-common/constants/src';

import { Tab } from '../Tabs.types';

import findTabIndex from './findTabIndex';

const calculateDirection = (
  routes: Tab[],
  previousPath: Tab | undefined,
  nextPath: Tab,
): ANIMATION_DIRECTION | undefined => {
  const nextPathIndex = findTabIndex(routes, nextPath);
  const previousPathIndex = findTabIndex(routes, previousPath);

  let animation;

  if (previousPathIndex < nextPathIndex) {
    animation = ANIMATION_DIRECTION.NEXT;
  }

  if (previousPathIndex > nextPathIndex) {
    animation = ANIMATION_DIRECTION.PREVIOUS;
  }

  return animation;
};

export default calculateDirection;
