import styled from 'styled-components';

interface $ProductIconContainerProps {
  $marginTop: boolean;
}

export const $ProductIconContainer = styled.div<$ProductIconContainerProps>`
  position: relative;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  margin-top: ${({ $marginTop }) => ($marginTop ? '6px' : undefined)};

  svg {
    width: 50px;
    height: 50px;
  }
`;
