import { Dictionary } from 'lodash';
import head from 'lodash/head';
import { useCallback } from 'react';

import { HANDLING_GROUPS } from '@savgroup-front-common/constants';
import { StrictNextStep } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { AddressInfoDto } from '@savgroup-front-common/types';

import { Carrier } from '../../../ClaimGroupHandling.types';
import { CarrierFormValues } from '../CarrierForm.types';

interface UseHandleSubmitHomePickup {
  groupedCarriers: Dictionary<Carrier[]>;
  selectedCarrierType: HANDLING_GROUPS;
  homePickupAddress?: AddressInfoDto;
  nextStep: StrictNextStep<CarrierFormValues>;
}

const useHandleSubmitHomePickup = ({
  groupedCarriers,
  selectedCarrierType,
  homePickupAddress,
  nextStep,
}: UseHandleSubmitHomePickup) => {
  const selectedCarrier = head(groupedCarriers[selectedCarrierType]);

  return useCallback(
    (payload?: AddressInfoDto) => {
      nextStep({
        selectedCarrier,
        selectedPickupPoint: undefined,
        address: payload || homePickupAddress,
      });
    },
    [homePickupAddress, nextStep, selectedCarrier],
  );
};

export default useHandleSubmitHomePickup;
