export function byStartLocalTime(firstInterval, secondInterval) {
  if (firstInterval.startLocalTime < secondInterval.startLocalTime) {
    return -1;
  }

  if (firstInterval.startLocalTime > secondInterval.startLocalTime) {
    return 1;
  }

  return 0;
}
