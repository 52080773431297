import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { Selectors as sellerSelectors } from '@savgroup-front-common/core/src/domains/sellerConfiguration';
import { ActionCreators as ErrorActionCreators } from 'myaccount/domains/errors';
import { ActionCreators as ModalsActionCreators } from 'myaccount/domains/Modals';
import * as OrdersActions from 'myaccount/domains/Orders/actionCreators';
import { loadOrdersData } from 'myaccount/domains/Orders/actionCreators';

import * as MyReturnActions from '../../../domains/MyReturn/actionCreators';
import { ROUTES } from '../routes/Routes';

import useUpdateFavicon from './hooks/useUpdateFavicon';

export const useLayout = ({ userManager }) => {
  const dispatch = useDispatch();

  const { login, notifications, navigation, orders, errors, router } =
    useSelector((state) => {
      const { login, notifications, navigation, orders, errors, router } =
        state;

      return { login, notifications, navigation, orders, errors, router };
    });

  const match = matchPath(router.location.pathname, {
    path: ROUTES.OLD_FILE_DETAILS,
    exact: true,
    strict: false,
  });

  const fileId = _.get(match, ['params', 'fileId'], '');
  const { items } = orders;
  const sellerId = _.get(_.first(items), 'sellerId', null);

  const url = useSelector((state) =>
    sellerSelectors.getSellerUrl(state, { sellerId }),
  );

  const handleLogout = useCallback(
    () => userManager.current.signoutRedirect(),
    [userManager],
  );

  useEffect(() => {
    dispatch(loadOrdersData());
  }, [dispatch]);
  useUpdateFavicon({ sellerId });

  return {
    login,
    notifications,
    navigation,
    sellerId,
    errors,
    fileId,
    myReturnActions: bindActionCreators(MyReturnActions, dispatch),
    ordersActions: bindActionCreators(OrdersActions, dispatch),
    errorActions: bindActionCreators(ErrorActionCreators, dispatch),
    modalsActions: bindActionCreators(ModalsActionCreators, dispatch),
    handleLogout,
    url,
  };
};
