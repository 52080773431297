import { MutableRefObject, useLayoutEffect, useRef } from 'react';
import Select from 'react-select/dist/declarations/src/Select';

import { BaseAutocompleteOption } from '@savgroup-front-common/types';

export function useFocusRefOnMount<
  T extends HTMLElement | Select<BaseAutocompleteOption, false>,
>(initialValue = null): MutableRefObject<T | null> | undefined {
  const ref = useRef<T | null>(initialValue);

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    if (ref.current && ref.current.focus) {
      const id = setTimeout(() => {
        if (ref.current) {
          ref.current.focus();
        }
      });

      return () => clearTimeout(id);
    }
  }, []);

  return ref;
}

export function useFocusAutocompleteRefOnMount<Option>(
  initialValue = null,
): MutableRefObject<Select<Option, false> | null> {
  const ref = useRef<Select<Option, false> | null>(initialValue);

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    if (ref.current && ref.current.focus) {
      const id = setTimeout(() => {
        if (ref.current) {
          ref.current.focus();
        }
      });

      return () => clearTimeout(id);
    }
  }, []);

  return ref;
}
