import { Grid } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $ClaimButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-left: 0.5rem;
  }
`;

export const $StyledCardHeader = styled('div')`
  background-color: #f1f2f4;
  border-radius: ${rem(5)};
  padding: 1rem 2rem;
`;

export const $StyledButton = styled(Button)`
  margin-left: ${rem(15)};
  @media screen and (max-width: 576px) {
    margin-left: 0;
    margin: ${rem(5)} 0;
  }
`;

export const $GridCustom = styled(Grid)`
  padding-left: unset;
  padding-right: unset;
  width: 100%;
`;

export const $ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const $CardGroupContainer = styled('div')`
  margin-top: 1rem;
`;
