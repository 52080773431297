import React, {
  FunctionComponent,
  MouseEventHandler,
  ReactElement,
} from 'react';

import { BUTTON_TYPES, FEATURE_FLAGS } from '@savgroup-front-common/constants';
import { MessageType } from '@savgroup-front-common/types';

import { useIsFeatureEnabled } from '../../components/FeatureManager/hooks';
import { SafeFormattedMessage } from '../../formatters';

import {
  $FieldLabel,
  $FieldLabelNewUi,
  $LabelContainer,
  $SpannedButton,
} from './Field.styles';
import { FIELD_ALIGN } from './Field.types';

interface FieldLabelProps {
  label?: string | number | MessageType;
  labelIcon?: ReactElement | undefined;
  labelIconAlign?: FIELD_ALIGN;
  align?: FIELD_ALIGN;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const FieldLabel: FunctionComponent<FieldLabelProps> = ({
  label = undefined,
  labelIcon = undefined,
  labelIconAlign = FIELD_ALIGN.LEFT,
  align = FIELD_ALIGN.LEFT,
  onClick,
}) => {
  const isNewUi = useIsFeatureEnabled(FEATURE_FLAGS.BO_NEW_UI);

  if (!label) {
    return <></>;
  }

  if (isNewUi) {
    return (
      <$FieldLabelNewUi $align={align}>
        <$SpannedButton type={BUTTON_TYPES.BUTTON} onClick={onClick}>
          {labelIcon && labelIconAlign === FIELD_ALIGN.LEFT && (
            <$LabelContainer $align={labelIconAlign}>
              {labelIcon}
            </$LabelContainer>
          )}
          {SafeFormattedMessage(label)}
          {labelIcon && labelIconAlign === FIELD_ALIGN.RIGHT && (
            <$LabelContainer $align={labelIconAlign}>
              {labelIcon}
            </$LabelContainer>
          )}
        </$SpannedButton>
      </$FieldLabelNewUi>
    );
  }

  return (
    <$FieldLabel $align={align}>
      <$SpannedButton type={BUTTON_TYPES.BUTTON} onClick={onClick}>
        {labelIcon && labelIconAlign === FIELD_ALIGN.LEFT && (
          <$LabelContainer $align={labelIconAlign}>{labelIcon}</$LabelContainer>
        )}
        {SafeFormattedMessage(label)}
        {labelIcon && labelIconAlign === FIELD_ALIGN.RIGHT && (
          <$LabelContainer $align={labelIconAlign}>{labelIcon}</$LabelContainer>
        )}
      </$SpannedButton>
    </$FieldLabel>
  );
};

FieldLabel.displayName = 'FieldLabel';

export default FieldLabel;
