import { defineMessages } from 'react-intl';

export default defineMessages({
  issuedOn: {
    id: 'view.quotationTab.issuedOn',
    defaultMessage: 'Issued on',
  },
  by: {
    id: 'general.by',
    defaultMessage: 'By',
  },
  respondedOn: {
    id: 'view.quotationTab.respondedOn',
    defaultMessage: 'Responded on',
  },
  quotationTotal: {
    id: 'view.quotationTab.quotationTotal',
    defaultMessage: 'Quotation total',
  },
});
