import md5 from 'md5';
import React, { FC, Fragment } from 'react';

import { CardSeparator } from '../../../../atoms/Cards';
import { SafeFormattedMessageWithoutSpread } from '../../../../formatters';
import getSparePartConditionMessage from '../../../../formatters/getSparePartConditionMessage';
import { formatReverseMoneyToString } from '../../../../formatters/intl';
import { getRecipientTypeMessage } from '../../../../helpers/i18n';
import { AdaptedQuotationNewModel } from '../../types/AdaptedQuotationNewModel';
import messages from '../messages';

import {
  $AmountCell,
  $Container,
  $QuotationLine,
  $QuotationLineHead,
} from './QuotationLinesMobileView.styles';

interface QuotationLinesMobileViewProps {
  isActive?: boolean;
  quotation: AdaptedQuotationNewModel;
}

const QuotationLinesMobileView: FC<QuotationLinesMobileViewProps> = ({
  quotation,
  isActive = false,
}) => {
  const services = [
    ...(quotation.services || []),
    ...(quotation.unknownServices || []),
  ];

  const optionalSpareParts = (quotation.sparePartLines || []).filter(
    (sparePart) =>
      !sparePart.isRequiredForRepair && sparePart.isSelectedForRepair,
  );
  const requiredSpareParts = (quotation.sparePartLines || []).filter(
    (sparePart) => sparePart.isRequiredForRepair,
  );

  return (
    <$Container>
      {services.length > 0 && (
        <$QuotationLineHead $isActive={isActive}>
          <SafeFormattedMessageWithoutSpread message={messages.service} />
        </$QuotationLineHead>
      )}
      {(quotation.services || []).map((service) => {
        return (
          <Fragment key={service.id}>
            <$QuotationLine $isActive={isActive}>
              <div>
                <div>{service.description}</div>
                {service.recipient && (
                  <div>
                    <SafeFormattedMessageWithoutSpread
                      message={messages.recipient}
                    />
                    {': '}
                    <SafeFormattedMessageWithoutSpread
                      message={getRecipientTypeMessage(
                        service.recipient?.recipientType,
                      )}
                    />
                  </div>
                )}
              </div>
              <$AmountCell>
                {formatReverseMoneyToString(service.amountIncludedTax)}
              </$AmountCell>
            </$QuotationLine>
            <CardSeparator />
          </Fragment>
        );
      })}
      {(quotation.unknownServices || []).map((service) => {
        return (
          <Fragment
            key={md5(formatReverseMoneyToString(service.amountIncludedTax))}
          >
            <$QuotationLine $isActive={isActive}>
              <div>
                <div>
                  <div> {service.description}</div>
                  {service.recipient && (
                    <div>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.recipient}
                      />
                      {': '}
                      <SafeFormattedMessageWithoutSpread
                        message={getRecipientTypeMessage(
                          service.recipient?.recipientType,
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
              <$AmountCell>
                {formatReverseMoneyToString(service.amountIncludedTax)}
              </$AmountCell>
            </$QuotationLine>
            <CardSeparator />
          </Fragment>
        );
      })}
      {(quotation.unknownSpareParts || []).map((sparePart) => {
        return (
          <Fragment
            key={md5(formatReverseMoneyToString(sparePart.amountIncludedTax))}
          >
            <$QuotationLine $isActive={isActive}>
              <div>
                <div>
                  <div> {sparePart.description}</div>
                  {sparePart.recipient && (
                    <div>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.recipient}
                      />
                      {': '}
                      <SafeFormattedMessageWithoutSpread
                        message={getRecipientTypeMessage(
                          sparePart.recipient?.recipientType,
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
              <$AmountCell>
                {formatReverseMoneyToString(sparePart.amountIncludedTax)}
              </$AmountCell>
            </$QuotationLine>
            <CardSeparator />
          </Fragment>
        );
      })}
      {(quotation.discounts || []).map((discount) => {
        return (
          <Fragment
            key={md5(formatReverseMoneyToString(discount.amountIncludedTax))}
          >
            <$QuotationLine $isActive={isActive}>
              <div>
                <div>
                  <div> {discount.description}</div>
                  {discount.recipient && (
                    <div>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.recipient}
                      />
                      {': '}
                      <SafeFormattedMessageWithoutSpread
                        message={getRecipientTypeMessage(
                          discount.recipient?.recipientType,
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
              <$AmountCell>
                {formatReverseMoneyToString(discount.amountIncludedTax)}
              </$AmountCell>
            </$QuotationLine>
            <CardSeparator />
          </Fragment>
        );
      })}
      {requiredSpareParts.length > 0 && (
        <$QuotationLineHead $isActive={isActive}>
          <SafeFormattedMessageWithoutSpread
            message={messages.requiredSpareParts}
          />
        </$QuotationLineHead>
      )}
      {requiredSpareParts.map((sparePart) => {
        return (
          <Fragment key={sparePart.id}>
            <$QuotationLine $isActive={isActive}>
              <div>
                {sparePart.manufacturerLabel && (
                  <div>{sparePart.manufacturerLabel}</div>
                )}
                <div>
                  {sparePart.condition && (
                    <SafeFormattedMessageWithoutSpread
                      message={getSparePartConditionMessage(
                        sparePart.condition,
                      )}
                    />
                  )}
                </div>
                <div>
                  {sparePart.recipient?.data && (
                    <>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.recipient}
                      />
                      {': '}
                      <SafeFormattedMessageWithoutSpread
                        message={getRecipientTypeMessage(
                          sparePart.recipient?.data,
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
              <$AmountCell>
                {formatReverseMoneyToString(
                  sparePart.recommendedSalePriceIncludingTaxes,
                )}
              </$AmountCell>
            </$QuotationLine>
            <CardSeparator />
          </Fragment>
        );
      })}
      {optionalSpareParts.length > 0 && (
        <$QuotationLineHead>
          <SafeFormattedMessageWithoutSpread
            message={messages.optionalSpareParts}
          />
        </$QuotationLineHead>
      )}
      {optionalSpareParts.map((sparePart) => {
        return (
          <Fragment key={sparePart.id}>
            <$QuotationLine $isActive={isActive}>
              <div>
                {sparePart.manufacturerLabel && (
                  <div>{sparePart.manufacturerLabel}</div>
                )}
                <div>
                  <SafeFormattedMessageWithoutSpread
                    message={getSparePartConditionMessage(sparePart.condition)}
                  />
                </div>
                <div>
                  {sparePart.recipient?.data && (
                    <>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.recipient}
                      />
                      {': '}
                      <SafeFormattedMessageWithoutSpread
                        message={getRecipientTypeMessage(
                          sparePart.recipient?.data,
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
              <$AmountCell>
                {formatReverseMoneyToString(
                  sparePart.recommendedSalePriceIncludingTaxes,
                )}
              </$AmountCell>
            </$QuotationLine>
            <CardSeparator />
          </Fragment>
        );
      })}
    </$Container>
  );
};

QuotationLinesMobileView.displayName = 'QuotationLinesMobileView';

export default QuotationLinesMobileView;
