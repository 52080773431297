import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import min from 'lodash/min';
import React, { FunctionComponent } from 'react';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';

import {
  BUTTON_TYPES,
  CURRENCIES,
  HANDLING_GROUPS,
} from '@savgroup-front-common/constants';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { StepProps } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import {
  NEED_HELP_POSITION_TYPES,
  NeedHelp,
} from '@savgroup-front-common/core/src/components/NeedHelp';
import { ChevronLeftIcon } from '@savgroup-front-common/core/src/protons/icons';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';
import { AuthConfiguration } from 'myaccount/configuration';
import { TransportDeduction } from 'myaccount/view/components';

import OldPageHeader from '../../../../../components/OldPageHeader';
import { $BackButton, $ClaimWrapper } from '../../ClaimGroupHandling.styles';
import { HandlingStepsValue } from '../../ClaimGroupHandling.types';

import HandlingCard from './HandlingCard';
import messages from './messages';
import { useSelectCarrierType } from './SelectCarrierType.hooks';
import { $HandlingsContainer } from './SelectCarrierType.styles';

const SelectCarrierType: FunctionComponent<StepProps<HandlingStepsValue>> = ({
  nextStep,
}) => {
  const {
    handleSubmitSelectCarrierType,
    groupedCarriers,
    handlingInfo,
    shouldShowTransportDeduction,
    isGridFluidActive,
    handleGoToPreviousStep,
    hasHome,
    handlingMode,
  } = useSelectCarrierType({ nextStep });

  if (!handlingInfo && isEmpty(groupedCarriers)) {
    return (
      <>
        <NeedHelp clientId={AuthConfiguration.clientId} />
        <BaseLoader messageList={[messages.options]} />
      </>
    );
  }

  return (
    <$ClaimWrapper>
      <Grid fluid={isGridFluidActive}>
        <$BackButton
          onClick={handleGoToPreviousStep}
          naked
          icon={<ChevronLeftIcon />}
          componentThemeName={SUPPORTED_COMPONENTS.BACK_BUTTON}
          type={BUTTON_TYPES.BUTTON}
        >
          <SafeFormattedMessageWithoutSpread message={messages.back} />
        </$BackButton>
        <OldPageHeader
          type={`handling_${
            hasHome ? HANDLING_GROUPS.HOME_INTERVENTION : handlingMode
          }`}
          centerTitle
        />
        <$HandlingsContainer>
          <NeedHelp
            position={NEED_HELP_POSITION_TYPES.BOTTOM}
            clientId={AuthConfiguration.clientId}
          />
          <Row center="xs">
            {map(groupedCarriers, (carriers, productType: HANDLING_GROUPS) => (
              <Col key={productType} xs={12} sm={6} md={4} lg={3}>
                <HandlingCard
                  price={min(
                    map(carriers, (carrier) => carrier.priceWithTax || 0),
                  )}
                  currency={CURRENCIES.EUR}
                  pickupMode={productType}
                  handlingMode={handlingMode}
                  onSelect={() => {
                    handleSubmitSelectCarrierType(productType);
                  }}
                />
              </Col>
            ))}
          </Row>
          {shouldShowTransportDeduction && <TransportDeduction />}
        </$HandlingsContainer>
      </Grid>
    </$ClaimWrapper>
  );
};

SelectCarrierType.displayName = 'SelectCarrierType';

export default SelectCarrierType;
