import React from 'react';

import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { OrderDetailsProduct } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import {
  $CardContentThumbnail,
  $CardContentThumbnailWrapper,
  $ResumeDescriptionProduct,
  $ResumeInfoProduct,
  $ResumeInfoProductTag,
  $ResumeInfoProductTitle,
  $ResumePictureProduct,
} from '../ResumeCard.styles';

import useResumeCardHeader from './ResumeCardHeader.hooks';

interface ResumeCardHeaderProps {
  product: OrderDetailsProduct;
}

const ResumeCardHeader: React.FC<ResumeCardHeaderProps> = ({ product }) => {
  const { modelDetail } = useResumeCardHeader({
    product,
  });

  return (
    <$ResumeDescriptionProduct>
      <$ResumePictureProduct>
        <$CardContentThumbnailWrapper>
          <$CardContentThumbnail>
            {modelDetail?.pictureUrl && modelDetail.modelTypeId ? (
              <img src={modelDetail?.pictureUrl} alt="pictureUrl" />
            ) : (
              <MyAccountIcon icon={ICONS_TYPE.BOX_ICON} />
            )}
          </$CardContentThumbnail>
        </$CardContentThumbnailWrapper>
      </$ResumePictureProduct>
      <$ResumeInfoProduct>
        <$ResumeInfoProductTitle>
          {product.batchQuantity} x {modelDetail?.name}
        </$ResumeInfoProductTitle>
        <$ResumeInfoProductTag />
      </$ResumeInfoProduct>
    </$ResumeDescriptionProduct>
  );
};

ResumeCardHeader.displayName = 'ResumeCardHeader';

export default ResumeCardHeader;
