import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';

import { Loader } from '@savgroup-front-common/core/src/atoms/loader';
import { Theme } from '@savgroup-front-common/core/src/theme/Theme';
import { $InitialLoaderContainer } from 'myaccount/view/app/App.styles';

import { useGetClientInformation } from './hooks';
import useSellerTheme from './hooks/useSellerTheme';

const ThemeContainer: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { clientInformation, isLoading: isClientInformationLoading } =
    useGetClientInformation();

  const { theme, isLoading } = useSellerTheme({
    sellerId: clientInformation?.sellerId || clientInformation?.tenantId,
  });

  if (isClientInformationLoading || isLoading) {
    return (
      <$InitialLoaderContainer>
        <Loader color="#333" size="50px" />
      </$InitialLoaderContainer>
    );
  }

  return <ThemeProvider theme={Theme(theme)}>{children}</ThemeProvider>;
};

ThemeContainer.displayName = 'ThemeContainer';

export default ThemeContainer;
