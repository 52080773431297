import React from 'react';

interface WristwatchIconProps {
  color?: string;
}

/* eslint-disable max-len */
const WristwatchIcon: React.FC<WristwatchIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5.5C19.337 5.5 18.7011 5.76339 18.2322 6.23223C17.7634 6.70107 17.5 7.33696 17.5 8V15.056C17.5 15.8844 16.8284 16.556 16 16.556C15.1716 16.556 14.5 15.8844 14.5 15.056V8C14.5 6.54131 15.0795 5.14236 16.1109 4.11091C17.1424 3.07946 18.5413 2.5 20 2.5H28C29.4587 2.5 30.8576 3.07946 31.8891 4.11091C32.9205 5.14236 33.5 6.54131 33.5 8V15.056C33.5 15.8844 32.8284 16.556 32 16.556C31.1716 16.556 30.5 15.8844 30.5 15.056V8C30.5 7.33696 30.2366 6.70107 29.7678 6.23223C29.2989 5.76339 28.663 5.5 28 5.5H20ZM16 31.444C16.8284 31.444 17.5 32.1156 17.5 32.944V40C17.5 40.663 17.7634 41.2989 18.2322 41.7678C18.7011 42.2366 19.337 42.5 20 42.5H28C28.663 42.5 29.2989 42.2366 29.7678 41.7678C30.2366 41.2989 30.5 40.663 30.5 40V32.944C30.5 32.1156 31.1716 31.444 32 31.444C32.8284 31.444 33.5 32.1156 33.5 32.944V40C33.5 41.4587 32.9205 42.8576 31.8891 43.8891C30.8576 44.9205 29.4587 45.5 28 45.5H20C18.5413 45.5 17.1424 44.9205 16.1109 43.8891C15.0795 42.8576 14.5 41.4587 14.5 40V32.944C14.5 32.1156 15.1716 31.444 16 31.444Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4541 14.4541C16.9858 11.9223 20.4196 10.5 24 10.5C27.5804 10.5 31.0142 11.9223 33.5459 14.4541C36.0777 16.9858 37.5 20.4196 37.5 24C37.5 27.5804 36.0777 31.0142 33.5459 33.5459C31.0142 36.0777 27.5804 37.5 24 37.5C20.4196 37.5 16.9858 36.0777 14.4541 33.5459C11.9223 31.0142 10.5 27.5804 10.5 24C10.5 20.4196 11.9223 16.9858 14.4541 14.4541ZM24 13.5C21.2152 13.5 18.5445 14.6062 16.5754 16.5754C14.6062 18.5445 13.5 21.2152 13.5 24C13.5 26.7848 14.6062 29.4555 16.5754 31.4246C18.5445 33.3938 21.2152 34.5 24 34.5C26.7848 34.5 29.4555 33.3938 31.4246 31.4246C33.3938 29.4555 34.5 26.7848 34.5 24C34.5 21.2152 33.3938 18.5445 31.4246 16.5754C29.4555 14.6062 26.7848 13.5 24 13.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 18.5C24.8284 18.5 25.5 19.1716 25.5 20V22.5H28C28.8284 22.5 29.5 23.1716 29.5 24C29.5 24.8284 28.8284 25.5 28 25.5H24C23.1716 25.5 22.5 24.8284 22.5 24V20C22.5 19.1716 23.1716 18.5 24 18.5Z"
        fill={color}
      />
    </svg>
  );
};

WristwatchIcon.displayName = 'WristwatchIcon';

export default WristwatchIcon;
