import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  BUTTON_TYPES,
  CURRENCIES,
  INPUT_TYPES,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants/src/shared';
import { FormGroup, Input } from '@savgroup-front-common/core/src/atoms/Form';
import { Banner } from '@savgroup-front-common/core/src/atoms/Banners';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { childrenProps } from '@savgroup-front-common/core/src/helpers/proptypes';

import CardField from './CardField';
import messages from './messages';
import {
  $Description,
  $PaymentTitleH1,
  $PaymentWrapper,
  $RowButtonContainer,
} from './PaymentForm.styles';

const PaymentForm = ({
  currency,
  amount,
  additionalErrors,
  isDisabledFromParent,
  resetAdditionalErrors,
  onSubmit,
  isLoading,
  paymentIntentClientSecret,
  children,
}) => {
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    name: '',
  });

  const { name } = billingDetails;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (error && elements.getElement('card')) {
      elements.getElement('card').focus();

      return;
    }

    if (isCardComplete) {
      setIsProcessing(true);
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const response = await stripe.confirmCardPayment(
        paymentIntentClientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: billingDetails,
          },
        },
      );

      if (response.error) {
        setError(response.error.message);
        setIsProcessing(false);
        logError(response);
      } else if (response?.paymentIntent?.status === 'succeeded') {
        setError(null);
        onSubmit();
      }
    } catch (error) {
      logError(error);
      setError(safeFormattedIntlString(messages.unknowError));
      setIsProcessing(false);
    }
  };

  const handleCreditCardChange = (e) => {
    if (additionalErrors.length > 0) {
      resetAdditionalErrors();
    }

    setError(e?.error?.message || null);
    setIsCardComplete(e.complete);
  };

  const handleHolderNameChange = (event) => {
    setBillingDetails({
      ...billingDetails,
      name: event.target.value,
    });
  };

  const isDisabled = !isCardComplete || !name || isDisabledFromParent;

  return (
    <Card>
      <$PaymentWrapper>
        <Col xs={1}>
          <img
            alt="secure"
            src={`${process.env.REACT_APP_STATIC_HASH}/images/illu_locked.png`}
          />
        </Col>
        <Col xs={11}>
          <$PaymentTitleH1 heading>
            <SafeFormattedMessageWithoutSpread message={messages.title} />
          </$PaymentTitleH1>
        </Col>
      </$PaymentWrapper>

      <Banner
        isFluid
        notificationType={NOTIFICATION_TYPES.ERROR}
        isOpen={error}
      >
        {get(error, 'message', error)}
      </Banner>

      <Row between="md">
        <Col xs={12} md={6} style={{ paddingRight: '24px' }}>
          <$Description>
            <SafeFormattedMessageWithoutSpread message={messages.description} />
          </$Description>

          <Row>
            <Col>
              <img
                alt="mastercard"
                src={`${process.env.REACT_APP_STATIC_HASH}/images/illu_mastercard.svg`}
              />
            </Col>
            <Col>
              <img
                alt="visa"
                src={`${process.env.REACT_APP_STATIC_HASH}/images/illu_visa.svg`}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                label={messages.creditCardHolder}
                type={INPUT_TYPES.TEXT}
                value={name}
                isError={error}
                onChange={handleHolderNameChange}
                data-payment-input-form
                dataTestId="name"
                isRequired
              />
            </FormGroup>

            <FormGroup>
              <CardField
                additionalErrors={additionalErrors}
                onChange={handleCreditCardChange}
                isError={error}
              />
            </FormGroup>

            {children}

            <$RowButtonContainer end="md">
              <Col xs={12} md={6}>
                <Button
                  fluid
                  primary
                  disabled={Boolean(isDisabled)}
                  isLoading={isProcessing || isLoading}
                  type={BUTTON_TYPES.SUBMIT}
                  dataTestId="paymentFormSubmitButton"
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.pay}
                    values={{
                      amount: intl.formatNumber(amount, {
                        style: 'currency',
                        currency,
                      }),
                    }}
                  />
                </Button>
              </Col>
            </$RowButtonContainer>
          </form>
        </Col>
      </Row>
    </Card>
  );
};

PaymentForm.propTypes = {
  children: childrenProps,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  resetAdditionalErrors: PropTypes.func.isRequired,
  additionalErrors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  isDisabledFromParent: PropTypes.bool,
};

PaymentForm.defaultProps = {
  children: undefined,
  additionalErrors: [],
  isLoading: false,
  isDisabledFromParent: false,
  currency: CURRENCIES.EUR,
};

export default PaymentForm;
