import { defineMessages } from 'react-intl';

export default defineMessages({
  commentRequired: {
    id: 'components.common.breadCrumb.commentRequired',
    defaultMessage: 'This field is mandatory',
  },
  addFile: {
    id: 'components.common.reasonSolution.addFile',
    defaultMessage: 'Add file',
  },
  documentRequired: {
    id: 'components.myaccount.claims.confirmation.documentRequired',
    defaultMessage: 'This document is mandatory',
  },
  noIssues: {
    id: `components.myaccount.claims.issues.noIssues`,
    defaultMessage: `Sorry, the warranty has expired. You cannot create claims for this product. In case of error, please contact your seller on the original website`,
  },
  reason: {
    id: 'view.card.claim.reasonName',
    defaultMessage: 'Reason',
  },
  specifyReason: {
    id: 'components.common.breadCrumb.reasons',
    defaultMessage: 'Select reason',
  },
  commentPlaceholder: {
    id: 'components.myaccount.claims.reasons.choice.commentPlaceholder',
    defaultMessage: 'Merci de décrire précisément le problème rencontré',
  },
  comment: {
    id: 'components.myaccount.claims.reasons.choice.comment',
    defaultMessage: 'Comment',
  },
  document: {
    id: 'components.myaccount.claims.reasons.choice.document',
    defaultMessage: 'Document(s)',
  },
  miscUploadLabel: {
    id: 'components.myaccount.claims.reasons.choice.miscUploadLabel',
    defaultMessage:
      'Afin de traiter au mieux votre demande, vous pouvez joindre une vidéo ou une photo illustrant votre problème.',
  },
  solution: {
    id: 'components.myaccount.common.solution',
    defaultMessage: 'Solution',
  },
});
