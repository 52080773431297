import { datadogRum } from '@datadog/browser-rum';

import {
  APP_ENVS,
  currentAppEnvironment,
  getDatadogEnv,
} from '@savgroup-front-common/constants';

datadogRum.init({
  applicationId: 'e1b7b2b6-0b4a-4cb7-b5d3-fda3dcf1badd',
  clientToken: process.env.REACT_APP_DATA_DOG_PUBLIC_KEY,
  site: 'datadoghq.eu',
  service: 'revers-front-selfcare',
  env: getDatadogEnv(currentAppEnvironment),
  version: `${process.env.REACT_APP_BUILD_NUMBER}`,
  sessionSampleRate: 100,
  sessionReplaySampleRate: currentAppEnvironment === APP_ENVS.PROD ? 5 : 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    'https://dev-api.revers.io',
    'https://demo-api.revers.io',
    'https://preprod-api.revers.io',
    'https://api.revers.io',
  ],
});

datadogRum.startSessionReplayRecording();
