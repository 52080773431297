import React, { FunctionComponent } from 'react';

import { $Didactic } from './Didactic.styles';

interface DidacticProps {
  dataTestId?: string;
  withBottomMargin?: boolean;
}

const Didactic: FunctionComponent<React.PropsWithChildren<DidacticProps>> = ({
  dataTestId,
  children,
  withBottomMargin,
}) => {
  return (
    <$Didactic $withBottomMargin={withBottomMargin} data-testid={dataTestId}>
      {children}
    </$Didactic>
  );
};

Didactic.displayName = 'Didactic';

export default Didactic;
