import { lighten } from 'polished';
import { css } from 'styled-components';

import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

import {
  getCustomComponentProperty,
  getThemeProperty,
  isDefault,
  rem,
} from '../../../helpers';
import {
  getBackgroundColor,
  getColor,
  getOnHoverColor,
} from '../Button.helpers';
import { InnerButtonStyledProps } from '../Button.types';

const getNakedBackgroundColor = (props: InnerButtonStyledProps) => {
  const { componentThemeName, theme, highContrast, primary } = props;

  const customValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
  });

  if (customValue) {
    return customValue;
  }

  const defaultValue = getBackgroundColor(props);

  if (isDefault(props)) {
    return getThemeProperty({
      theme,
      highContrast,
      path: 'buttons.naked.default',
      property: SUPPORTED_PROPERTIES.COLOR,
      defaultValue,
    });
  }

  if (primary) {
    return getThemeProperty({
      theme,
      highContrast,
      path: 'buttons.naked.primary',
      property: SUPPORTED_PROPERTIES.COLOR,
      defaultValue,
    });
  }

  return defaultValue;
};
const getNakedHoverBackgroundColor = (props: InnerButtonStyledProps) => {
  const { theme, componentThemeName, primary, highContrast } = props;

  const color = getColor(props);

  const defaultValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR,
    fallback: color ? lighten(0.35, color) : undefined,
  });

  if (isDefault(props)) {
    return getThemeProperty({
      theme,
      highContrast,
      path: 'buttons.naked.default',
      property: SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR,
      defaultValue,
    });
  }

  if (primary) {
    return getThemeProperty({
      theme,
      highContrast,
      path: 'buttons.naked.primary',
      property: SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR,
      defaultValue,
    });
  }

  return defaultValue;
};

const getNakedColor = (props: InnerButtonStyledProps) => {
  const { componentThemeName, theme, highContrast, primary } = props;

  const customValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.COLOR,
  });

  if (customValue) {
    return customValue;
  }

  const defaultValue = getColor(props);

  if (isDefault(props)) {
    return getThemeProperty({
      theme,
      highContrast,
      path: 'buttons.naked.default',
      property: SUPPORTED_PROPERTIES.COLOR,
      defaultValue,
    });
  }

  if (primary) {
    return getThemeProperty({
      theme,
      highContrast,
      path: 'buttons.naked.primary',
      property: SUPPORTED_PROPERTIES.COLOR,
      defaultValue,
    });
  }

  return defaultValue;
};
const getNakedOnHoverColor = (props: InnerButtonStyledProps) => {
  const { componentThemeName, theme, highContrast, primary } = props;

  const defaultValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.ON_HOVER_COLOR,
    fallback: getOnHoverColor(props) || undefined,
  });

  if (isDefault(props)) {
    return getThemeProperty({
      theme,
      highContrast,
      path: 'buttons.naked.default',
      property: SUPPORTED_PROPERTIES.ON_HOVER_COLOR,
      defaultValue,
    });
  }

  if (primary) {
    return getThemeProperty({
      theme,
      highContrast,
      path: 'buttons.naked.primary',
      property: SUPPORTED_PROPERTIES.ON_HOVER_COLOR,
      defaultValue,
    });
  }

  return defaultValue;
};

const getNakedButtonStylesFromProps = (props: InnerButtonStyledProps) => {
  const { componentThemeName, naked, theme, hollow, disabled, $isPressed } =
    props;

  if (!naked) {
    return '';
  }

  const customBorder = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.BORDER,
  });
  const customOnHoverBorder = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.ON_HOVER_BORDER,
  });

  const color = getNakedColor(props);
  const hoverColor = getNakedOnHoverColor(props);

  const backgroundColor = getNakedBackgroundColor(props);
  const hoverBackgroundColor = getNakedHoverBackgroundColor(props);

  if (disabled && !hollow) {
    return css`
      color: ${backgroundColor}
      font-size: ${rem(12)};
      background-color: transparent;
      border: ${customBorder || '1px solid transparent'};

      &:hover,
      &:focus {
        background-color: ${hoverBackgroundColor};
      }
      
      & svg,
      & svg {
        fill: ${backgroundColor};
      }
    `;
  }

  return css`
    color: ${$isPressed ? undefined : color};
    font-size: ${rem(12)};
    background-color: ${$isPressed ? undefined : 'transparent'};
    border: ${customBorder || '1px solid transparent'};

    &&:hover,
    &&:focus {
      color: ${hoverColor};
      background-color: ${hoverBackgroundColor};
      border: ${customOnHoverBorder || '1px solid transparent'};
    }
    &:hover svg,
    &:focus svg {
      fill: ${hoverColor};
    }
    svg:not(:first-child):not(:last-child) {
      margin-right: ${rem('6px')};
    }
  `;
};

export default getNakedButtonStylesFromProps;
