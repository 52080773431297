enum CLAIM_TYPES {
  ISSUES = 'issues', // step 1
  REASONS = 'reasons', // step 2
  SOLUTIONS = 'solutions', // step 3
  DIAGNOSTIC_SYMPTOM_CHOICE = 'diagnostic_symptom_choice',
  DIAGNOSTIC = 'diagnostic',
  DIAGNOSTIC_CONFIRMATION = 'diagnostic_confirmation',
  HANDLING_DEPOSIT = 'handling_deposit', // step 4
  HANDLING_DELIVERY = 'handling_delivery', // step 4
  HANDLING_IAD = 'handling_iad', // step 4
  CONFIRMATION = 'confirmation', // step 5
  RMA = 'rma',
  FILE_DETAIL = 'file_detail',
  PRODUCTS = 'products',
}

export default CLAIM_TYPES;
