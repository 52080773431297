import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import { Link } from '@savgroup-front-common/core/src/atoms/link';
import { Tag } from '@savgroup-front-common/core/src/atoms/Tag';
import { ProductIcon } from '@savgroup-front-common/core/src/components/ProductIcon';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { Field } from '@savgroup-front-common/core/src/molecules/Field';
import useGetModelById from 'myaccount/hooks/useGetModelById';

import messages from './messages';
import {
  $GridCustom,
  $ImageCardStyled,
  $ImageContainer,
  $OutOfWarrantyTag,
  $RowInfo,
  $RowReference,
  $TextBold,
} from './OrderProductInfo.styles';

const FullOrderProductInfo = ({
  modelTypeId,
  modelName,
  brandName,
  orderNumber,
  purchaseDate,
  previousFile,
  minimal,
  fileDetailsRoute,
  quantity,
  noWarranty,
  fromProductWarranty,
  modelId,
}) => {
  const { model } = useGetModelById({ modelId });
  const pictureUrl = model?.pictureUrl;

  return (
    <>
      <$GridCustom>
        <Row>
          <Col xs={12} sm={12}>
            <$RowInfo>
              <Col xs={12} sm={2}>
                {!pictureUrl && (
                  <$ImageContainer>
                    <ProductIcon modelTypeId={modelTypeId} />
                  </$ImageContainer>
                )}
                {pictureUrl && (
                  <$ImageCardStyled>
                    <img src={pictureUrl} alt="pictureUrl" />
                  </$ImageCardStyled>
                )}
              </Col>
              <Col xs={12} sm={10}>
                <$RowReference start="xs">
                  <Col>
                    <Tag primary>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.orderNumber}
                        values={{
                          orderReference: orderNumber,
                        }}
                      />
                    </Tag>
                  </Col>
                  {fromProductWarranty !== undefined && (
                    <Col>
                      <Tag
                        primary={fromProductWarranty}
                        alert={!fromProductWarranty}
                      >
                        {fromProductWarranty && (
                          <SafeFormattedMessageWithoutSpread
                            message={messages.withWarranty}
                          />
                        )}
                        {!fromProductWarranty && (
                          <SafeFormattedMessageWithoutSpread
                            message={messages.withoutWarranty}
                          />
                        )}
                      </Tag>
                    </Col>
                  )}
                  <Col xs={12}>
                    {!minimal && (
                      <$GridCustom>
                        {noWarranty && (
                          <$OutOfWarrantyTag danger>
                            <SafeFormattedMessageWithoutSpread
                              message={messages.outOfWarranty}
                            />
                          </$OutOfWarrantyTag>
                        )}
                        {previousFile && (
                          <Link
                            title={previousFile.fileId}
                            to={fileDetailsRoute.replace(
                              ':fileId',
                              previousFile.fileId,
                            )}
                          >
                            <SafeFormattedMessageWithoutSpread
                              message={messages.viewHistory}
                            />
                          </Link>
                        )}
                      </$GridCustom>
                    )}
                  </Col>
                </$RowReference>
                <$RowInfo>
                  <Col xs={6} sm={3}>
                    <Field label={messages.purchasedDate}>
                      {purchaseDate ? (
                        <FormattedDate
                          year="numeric"
                          month="long"
                          day="2-digit"
                          value={purchaseDate}
                        >
                          {(message) => <$TextBold>{message}</$TextBold>}
                        </FormattedDate>
                      ) : (
                        '-'
                      )}
                    </Field>
                  </Col>
                  <Col xs={6} sm={3}>
                    <Field label={messages.brand}>{brandName}</Field>
                  </Col>
                  <Col xs={12} sm={4}>
                    <Field label={messages.model}>{modelName}</Field>
                  </Col>
                  <Col xs={12} sm={2}>
                    <Field
                      label={messages.quantityCol}
                      dataTestId="fullOrderProductInfoQuantity"
                    >
                      {quantity}
                    </Field>
                  </Col>
                </$RowInfo>
              </Col>
            </$RowInfo>
          </Col>
        </Row>
      </$GridCustom>
    </>
  );
};

FullOrderProductInfo.displayName = 'FullOrderProductInfo';

FullOrderProductInfo.propTypes = {
  modelTypeId: PropTypes.string.isRequired,
  modelId: PropTypes.string,
  modelName: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  purchaseDate: PropTypes.string,
  minimal: PropTypes.bool,
  previousFile: PropTypes.shape({}),
  fileDetailsRoute: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  noWarranty: PropTypes.bool,
};

FullOrderProductInfo.defaultProps = {
  purchaseDate: '',
  modelId: undefined,
  minimal: false,
  quantity: 0,
  previousFile: null,
  noWarranty: false,
};

export default FullOrderProductInfo;
