import _ from 'lodash';
import { createSelector } from 'reselect';

import { availableNamedLanguages } from '@savgroup-front-common/constants/src/shared';
import { Seller } from '@savgroup-front-common/types';

import { CommonAppState } from '../CommonAppState';
import { Selectors as SellerSelectors } from '../sellerConfiguration';
import { selectSellers } from '../sellers/selectors';

export const MAX_SELLER_NUMBER_TO_SUPPORT_LANGUAGE_RESTRICTION = 5;

const isThereAtLeastOneSellerConfigLoaded = (
  acceptedLanguages: Record<string, string>,
) => {
  return Object.keys(acceptedLanguages).length > 0;
};
const isThereToMuchSellerAttached = (sellersOptionsList: Seller[]) => {
  return (
    sellersOptionsList.length >
    MAX_SELLER_NUMBER_TO_SUPPORT_LANGUAGE_RESTRICTION
  );
};

const canReturnDefaultResult = ({
  oneSellerHasEmptyAcceptedLanguages,
  sellersOptionsList,
}: {
  oneSellerHasEmptyAcceptedLanguages: boolean;
  sellersOptionsList: Seller[];
}) => {
  return (
    oneSellerHasEmptyAcceptedLanguages ||
    isThereToMuchSellerAttached(sellersOptionsList)
  );
};
const canReturnEmptyResult = ({
  sellersOptionsList,
  acceptedLanguages,
  wasSellersConfigLoaded,
}: {
  sellersOptionsList: Seller[];
  acceptedLanguages: Record<string, string>;
  wasSellersConfigLoaded: boolean;
}) => {
  return (
    (!isThereAtLeastOneSellerConfigLoaded(acceptedLanguages) ||
      !wasSellersConfigLoaded) &&
    !isThereToMuchSellerAttached(sellersOptionsList)
  );
};

export const getAllAvailableLanguages = createSelector(
  [
    SellerSelectors.sellerConfig,
    selectSellers,
    SellerSelectors.getSellersConfigWasLoaded,
  ],
  (sellerConfigState, sellersOptionsList, wasSellersConfigLoaded) => {
    const acceptedLanguagesMap = sellerConfigState.mapEntries(
      ([sellerId, sellerConfigValue]: any) => [
        sellerId,
        sellerConfigValue.getIn(['value'], {}).acceptedLanguages || [],
      ],
    );

    const acceptedLanguages = acceptedLanguagesMap.toJS() as Record<
      string,
      string
    >;

    const oneSellerHasEmptyAcceptedLanguages = Object.values(
      acceptedLanguages,
    ).some((languages) => languages.length === 0);

    if (
      canReturnEmptyResult({
        sellersOptionsList,
        acceptedLanguages,
        wasSellersConfigLoaded,
      })
    ) {
      return [];
    }
    if (
      canReturnDefaultResult({
        oneSellerHasEmptyAcceptedLanguages,
        sellersOptionsList,
      })
    ) {
      return availableNamedLanguages;
    }

    let languagesList = acceptedLanguagesMap.reduce<string[]>(
      (acc: any, value: any) => [...acc, ...value],
      [],
    );

    languagesList = _.uniq(languagesList).map((language) =>
      language.toLowerCase(),
    );

    return languagesList.length ? languagesList : availableNamedLanguages;
  },
);

export const getAvailableLanguagesForSeller = createSelector(
  [
    (state: CommonAppState) => state,
    (state: CommonAppState, sellerId: string) => sellerId,
  ],
  (state, sellerId) => {
    const sellerConfig = SellerSelectors.selectSellerConfigBySellerId(state, {
      sellerId,
    });

    const { acceptedLanguages } = sellerConfig || {
      acceptedLanguages: [],
    };
    const languagesList = _.uniq(acceptedLanguages).map((language) =>
      language.toLowerCase(),
    );

    return languagesList.length ? languagesList : availableNamedLanguages;
  },
);
