import { rgba } from 'polished';
import { Col } from 'react-styled-flexboxgrid';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css } from 'styled-components';

import {
  media,
  Z_INDEX_MODAL,
} from '@savgroup-front-common/constants/src/shared';

import { getAnimationDuration } from '../../animations/helper';
import { scaleInToScaleDown } from '../../animations/stateAnimation';
import {
  decelerateTimingFunctionAnimation,
  decelerateTimingFunctionTransition,
} from '../../animations/timingFunction';
import { isAnimationExiting, rem } from '../../helpers';

import { getModalSize, MODAL_SIZES } from './Modal.helpers';
import { ModalPosition } from './Modal.types';

interface $ModalContainerProps {
  animationState: TransitionStatus;
}

export const $ModalContainer = styled.div<$ModalContainerProps>`
  z-index: ${Z_INDEX_MODAL};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;

  @media ${media.minWidth.sm} {
    padding: 3rem 1rem;
  }

  overflow-x: hidden;
  pointer-events: ${({ animationState }) =>
    isAnimationExiting(animationState) ? 'none' : null};
`;
export const $IconContainer = styled.div`
  position: absolute;
  user-select: none;
  pointer-events: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: ${({ theme }) => theme.newUI.borders.bigRadius};

  svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 600px;
    height: 600px;
    fill: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
    transform: translate(20%, -20%) rotate(15deg);
  }
`;

interface $ModalAnimationContainerProps {
  animationDuration: number;
  $size: MODAL_SIZES;
  $transformOrigin: ModalPosition;
  animationState: TransitionStatus;
  $isNewUi?: boolean;
}

const customPositionAnimation = css<$ModalAnimationContainerProps>`
  @keyframes testdfsafs {
    from {
      transform: ${({ $transformOrigin }) =>
        `translate(${rem($transformOrigin.x)}, ${rem(
          $transformOrigin.y + 100,
        )})`};
    }
    to {
      transform: translate(0, 0);
    }
  }
  animation-name: testdfsafs;
  animation-duration: ${getAnimationDuration};
  ${decelerateTimingFunctionAnimation};
`;

export const $ModalAnimationContainer = styled.div<$ModalAnimationContainerProps>`
  width: 100%;
  transform-origin: center center;
  transition-duration: ${getAnimationDuration};

  ${decelerateTimingFunctionTransition};
  ${customPositionAnimation}

  @media ${media.minWidth.sm} {
    max-width: ${getModalSize};
  }
`;

interface $ModalBlockProps {
  animationDuration: number;
  animationState: TransitionStatus;
  shouldShowPadding: boolean;
}
export const $ModalBlock = styled.div<$ModalBlockProps>`
  ${scaleInToScaleDown};
  background: ${({ theme }) => theme.colors.white};
  margin-top: ${rem(24)};
  border-radius: ${({ theme }) => theme.newUI.borders.bigRadius};
  width: 100%;
  z-index: 2;
  pointer-events: auto;
  position: relative;
  transition-duration: 0.3s;
  @media ${media.minWidth.sm} {
    padding: ${({ shouldShowPadding }) =>
      shouldShowPadding ? `${rem(40)} ${rem(64)}` : 0};
  }
  @media ${media.maxWidth.sm} {
    padding: ${({ shouldShowPadding }) =>
      shouldShowPadding ? '1.25rem !important' : 0};
    width: 100% !important;
  }
`;

export const $CloseIconWrapper = styled.button`
  position: absolute;
  top: -${rem(45)};
  height: ${rem(45)};
  right: -${rem(30)};
  padding: 0 ${rem(30)};
  cursor: pointer;
  background: transparent;
  outline: 0;
  border: none;
  color: white;

  > div {
    ${decelerateTimingFunctionTransition};
    transition-duration: 0.2s;
    padding: ${rem(5)};
    display: flex;
    align-items: center;
    border-radius: ${({ theme }) => theme.borders.radius};
  }
  &:hover > div,
  &:focus > div {
    background: rgba(255, 255, 255, 0.3);
  }
  &:active > div {
    transform: scale(${({ theme }) => theme.interactions.activeScale});
  }

  svg {
    fill: white;
  }
`;

export const ModalHeader = styled.div`
  margin-bottom: 1rem;
`;

export const ModalFooter = styled.footer`
  width: 100%;
  margin-top: 1rem;

  @media ${media.maxWidth.xs} {
    ${Col}${`:not(:last-child)`} {
      margin-bottom: 1rem;
    }
  }
  @media ${media.minWidth.sm} {
    margin-top: 3rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
`;
