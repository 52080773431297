import { defineMessages } from 'react-intl';

export default defineMessages({
  followUp: {
    id: 'view.page.orders.followUp',
    defaultMessage: 'Continuer la déclaration',
  },
  declare: {
    id: 'view.page.orders.declare',
    defaultMessage: 'Déclarer un problème',
  },
  seeFileDetails: {
    id: 'view.page.orders.seeFileDetails',
    defaultMessage: 'Suivre le dossier ',
  },
  seeHistoric: {
    id: 'view.page.orders.seeFileHistoric',
    defaultMessage: "Voir l'historique du dossier",
  },
  continueClaim: {
    id: 'view.page.orders.continueClaim',
    defaultMessage: 'Finalize claim',
  },
  followUpClaim: {
    id: 'view.page.orders.followUpClaim',
    defaultMessage: 'Follow Up claim',
  },
  titleFileDetail: {
    id: 'components.common.pageHeader.title_file_detail',
    defaultMessage: 'File N° {fileReference}',
  },
});
