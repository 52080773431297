import head from 'lodash/head';
import { useCallback, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PRODUCT_OUTCOME } from '@savgroup-front-common/types';
import {
  ActionCreators as ClaimCreationActionCreators,
  Selectors as ClaimCreationSelectors,
} from 'myaccount/domains/Claim/ClaimCreation';
import { currentClaimProducts } from 'myaccount/domains/Views/groupedProduct';

import {
  CHOOSE_PRODUCTS_PAGE_ACTION_TYPES,
  init,
  reducer,
} from './ChooseProducts.reducer';
import { ERROR_TYPES } from './ChooseProducts.types';

export default function useChooseProducts() {
  const storeDispatch = useDispatch();
  const [productsError, setProductsError] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const issueId = useSelector(ClaimCreationSelectors.currentIssueIdIdSelector);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const products =
    useSelector(ClaimCreationSelectors.currentEligibleProductsSelector) || {};

  const claimProducts = useSelector(currentClaimProducts);
  const [{ selectedProducts }, dispatch] = useReducer(
    reducer,
    { products, claimProducts },
    init,
  );

  const eligibleOwnerProductIds = products.reduce(
    (acc, { ownerProductId }) => [...acc, ownerProductId],
    [],
  );

  const selectedProductCount = selectedProducts.length;
  const allProductsSelected =
    selectedProductCount === eligibleOwnerProductIds.length;

  const handleError = useCallback(() => {
    const hasNoQuantitySelected = selectedProductCount === 0;

    if (hasNoQuantitySelected) {
      setProductsError(
        products.map(({ ownerProductId }) => {
          return {
            ownerProductId,
            errorType: ERROR_TYPES.NO_SELECTED_PRODUCT,
          };
        }),
      );

      return true;
    }

    const chosenProductsOutcomeWithRepaire = selectedProducts.reduce(
      (acc, ownerProductId) => {
        const product = products.find(
          (p) => p.ownerProductId === ownerProductId,
        );
        const firstOutcomeHistory = head(product?.outcomeHistory);
        const outcome = firstOutcomeHistory?.outcome;

        if (outcome === PRODUCT_OUTCOME.REPAIRED) {
          return [
            ...acc,
            {
              ownerProductId,
              errorType: ERROR_TYPES.MORE_THAN_ONE_REPAIRED_PRODUCT,
            },
          ];
        }

        return acc;
      },
      [],
    );

    const isProductWithRepairedOutcome = chosenProductsOutcomeWithRepaire.some(
      (p) => p.errorType,
    );

    if (isProductWithRepairedOutcome && selectedProductCount > 1) {
      setProductsError(chosenProductsOutcomeWithRepaire);

      return true;
    }

    return false;
  }, [products, selectedProductCount, selectedProducts]);

  const handleSelectAll = useCallback(() => {
    setProductsError([]);
    if (allProductsSelected) {
      return dispatch({ type: CHOOSE_PRODUCTS_PAGE_ACTION_TYPES.UNSELECT_ALL });
    }

    return dispatch({
      type: CHOOSE_PRODUCTS_PAGE_ACTION_TYPES.SELECT_ALL,
      payload: { products },
    });
  }, [allProductsSelected, products]);

  const handleChangeProductToReturn = useCallback((ownerProductId) => {
    setProductsError([]);

    const payload = {
      ownerProductId,
    };

    dispatch({
      type: CHOOSE_PRODUCTS_PAGE_ACTION_TYPES.CHANGE_PRODUCT_TO_RETURN,
      payload,
    });
  }, []);

  const handleSubmit = useCallback(() => {
    if (!handleError()) {
      setIsSubmitting(true);
      storeDispatch(
        ClaimCreationActionCreators.returnProducts({
          issueId,
          ownerProductIds: selectedProducts,
        }),
      );
    }
  }, [handleError, issueId, selectedProducts, storeDispatch]);

  return {
    selectedProductCount,
    productsError,
    eligibleProducts: products,
    isSubmitting,
    allProductsSelected,
    handleSelectAll,
    handleSubmit,
    handleChangeProductToReturn,
    selectedProducts,
    productCount: eligibleOwnerProductIds.length,
  };
}
