import { all, put, takeEvery } from 'redux-saga/effects';

import { ActionCreators as ClaimActionCreators } from '@savgroup-front-common/core/src/domains/claims';

import * as ActionTypes from './actionTypes';

function* selectQuantityReasonWorker({
  payload: { claimIds, reasonId, reasonComment },
}) {
  yield all(
    claimIds.map((claimId) =>
      put(
        ClaimActionCreators.selectReasonForClaim({
          claimId,
          reasonId,
          reasonComment,
        }),
      ),
    ),
  );
}
function* selectQuantityReasonWatcher() {
  yield takeEvery(
    ActionTypes.SELECT_QUANTITY_REASON,
    selectQuantityReasonWorker,
  );
}

export default function* mainSaga() {
  yield all([selectQuantityReasonWatcher()]);
}
