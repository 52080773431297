import { fromJS } from 'immutable';
import invoke from 'lodash/invoke';

import { ActionTypes as CarriersActionTypes } from '@savgroup-front-common/core/src/domains/carriers';

const initialState = fromJS({
  carrier: null,
  countryCode: null,
});

function onLoadCarrierSchedule(state, { meta }) {
  const carrier = invoke(meta, ['carrier', 'toLowerCase']);
  const countryCode = invoke(meta, ['countryCode', 'toLowerCase']);

  return state.setIn(['carrier'], carrier).setIn(['countryCode'], countryCode);
}

function currentCarrierReducer(state = initialState, action) {
  switch (action.type) {
    case CarriersActionTypes.LOAD_PICKUP_SCHEDULE.STARTED:
      return onLoadCarrierSchedule(state, action);
    default:
      return state;
  }
}

export default currentCarrierReducer;
