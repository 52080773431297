import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadIssuesFromAllOrder } from '@savgroup-front-common/core/src/domains/claims/actionCreators';
import {
  selectOrdersIsLoaded,
  selectOrdersIsRunning,
} from 'myaccount/domains/Orders/selectors';
import {
  mergedIssuesSelector,
  OrderIssuesLoadedSelector,
} from 'myaccount/domains/Views/issues';

const useChooseIssuePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadIssuesFromAllOrder());
  }, [dispatch]);

  const mergedIssues = useSelector(mergedIssuesSelector);
  const issuesIsLoaded = useSelector(OrderIssuesLoadedSelector);
  const isRunning = useSelector(selectOrdersIsRunning);
  const isLoaded = useSelector(selectOrdersIsLoaded);

  return {
    mergedIssues,
    issuesIsLoaded,
    isRunning,
    isLoaded,
  };
};

export default useChooseIssuePage;
