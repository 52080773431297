import React from 'react';

interface PackagesIconProps {
  color?: string;
}

/* eslint-disable max-len */
const PackagesIcon: React.FC<PackagesIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99992 26.6667V21.6667M19.9999 10V5M29.9999 26.6667V21.6667M16.6666 25V31.6667C16.6666 32.5507 16.3154 33.3986 15.6903 34.0237C15.0652 34.6488 14.2173 35 13.3333 35H6.66659C5.78253 35 4.93468 34.6488 4.30956 34.0237C3.68444 33.3986 3.33325 32.5507 3.33325 31.6667V25C3.33325 24.1159 3.68444 23.2681 4.30956 22.643C4.93468 22.0179 5.78253 21.6667 6.66659 21.6667H13.3333C14.2173 21.6667 15.0652 22.0179 15.6903 22.643C16.3154 23.2681 16.6666 24.1159 16.6666 25ZM26.6666 8.33333V15C26.6666 15.8841 26.3154 16.7319 25.6903 17.357C25.0652 17.9821 24.2173 18.3333 23.3333 18.3333H16.6666C15.7825 18.3333 14.9347 17.9821 14.3096 17.357C13.6844 16.7319 13.3333 15.8841 13.3333 15V8.33333C13.3333 7.44928 13.6844 6.60143 14.3096 5.97631C14.9347 5.35119 15.7825 5 16.6666 5H23.3333C24.2173 5 25.0652 5.35119 25.6903 5.97631C26.3154 6.60143 26.6666 7.44928 26.6666 8.33333ZM36.6666 25V31.6667C36.6666 32.5507 36.3154 33.3986 35.6903 34.0237C35.0652 34.6488 34.2173 35 33.3333 35H26.6666C25.7825 35 24.9347 34.6488 24.3096 34.0237C23.6844 33.3986 23.3333 32.5507 23.3333 31.6667V25C23.3333 24.1159 23.6844 23.2681 24.3096 22.643C24.9347 22.0179 25.7825 21.6667 26.6666 21.6667H33.3333C34.2173 21.6667 35.0652 22.0179 35.6903 22.643C36.3154 23.2681 36.6666 24.1159 36.6666 25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

PackagesIcon.displayName = 'PackagesIcon';

export default PackagesIcon;
