import styled from 'styled-components';

import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';

export const $CancelClaimButtonDesktop = styled('div')`
  display: none;
  align-items: center;
  font-size: 0.813rem;
  text-decoration: underline;
  margin-right: 1rem;
  font-weight: bold;
  cursor: pointer;
  ${mediaQuery.sm`{
    display: flex;
  `};
`;

export const $CancelClaimButtonMobile = styled('div')`
  display: flex;
  ${mediaQuery.sm`{
    display: none;
  `};
`;
