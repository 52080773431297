import { transparentize } from 'polished';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { H2, H3, H4 } from '@savgroup-front-common/core/src/atoms/headings';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $SummaryTitle = styled(H2)`
  padding-top: 0;
  padding-bottom: 11px;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  line-height: 28px;
  font-size: 20px;
`;

export const $SummaryItem = styled(Row)`
  display: flex;
  padding: 5px 0;
  align-items: center;
`;

export const $PriceCol = styled(Col)`
  text-align: right;
  justify-content: flex-end;
`;

export const $TotalPrice = styled(Row)`
  display: flex;
  margin-top: 16px;
  padding: 12px 16px;
  border-radius: ${({ theme }) => theme.borders.radius};
  background-color: ${({ theme }) =>
    transparentize(0.65, theme.colors.default)};
  align-items: center;
`;

export const $VatPriceNotice = styled(Row)`
  color: ${({ theme }) => theme.colors.danger};
`;

export const $TotalPriceBold = styled(H3)`
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 16px;
  line-height: 24px;
`;

export const $ItemH4 = styled(H4)`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 14px;
  line-height: 20px;
`;

export const $SubItemH4 = styled(H4)`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 14px;
  line-height: 20px;
  padding-left: ${(props) => (props.subItem > 1 ? '20px' : null)};
`;

export const $ItemCategoryH4 = styled(H4)`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
`;

export const $OrderSummary = styled.div`
  margin-top: ${rem('20px')};
`;
