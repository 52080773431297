import get from 'lodash/get';

type ValueType = string | Date | null | number | Record<string, unknown>;

function byField<T>(field: string, value: ValueType) {
  return (item: T): boolean => {
    return get(item, field) === value;
  };
}

export default byField;
