import { defineMessages } from 'react-intl';

export default defineMessages({
  store: {
    id: 'sparePartRequest.requestLine.handlingType.store',
    defaultMessage: 'Store',
  },
  repairCenter: {
    id: 'sparePartRequest.requestLine.handlingType.repairCenter',
    defaultMessage: 'Repair center',
  },
  customer: {
    id: 'sparePartRequest.requestLine.handlingType.customer',
    defaultMessage: 'Customer',
  },
  specific: {
    id: 'sparePartRequest.requestLine.handlingType.specific',
    defaultMessage: 'Specific location',
  },
});
