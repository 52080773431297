import md5 from 'md5';
import { useCallback, useRef, useState } from 'react';

import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { DOCUMENT_TYPES } from '@savgroup-front-common/constants';

interface UseLogoArgs {
  sellerId: string;
  clientId: string;
}

export const useLogo = ({ sellerId, clientId }: UseLogoArgs) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const logoUrl = `${
    APIConfiguration.imageCDN
  }sellers/${sellerId}/${DOCUMENT_TYPES.LOGO.toLocaleLowerCase()}`;

  const hashedClientId = md5(clientId);
  const logoFromUiStyles = `${APIConfiguration.uiStylesCDN}logos/${hashedClientId}`;
  const logoFallback = `${process.env.REACT_APP_STATIC_HASH}/images/logo-blank-rvl.svg`;

  const [listAlternatives, setListAlternatives] = useState(
    [logoUrl, logoFromUiStyles, logoFallback].filter((item) => item),
  );

  const [firstAlternative, ...rest] = listAlternatives;

  const onError = useCallback(() => {
    if (imgRef.current) {
      imgRef.current.onerror = rest.length > 0 ? onError : null;
      imgRef.current.src = firstAlternative;

      setListAlternatives(rest);
    }
  }, [firstAlternative, rest]);

  return {
    logoUrl: firstAlternative,
    imgRef,
    onError,
  };
};
