import { defineMessages } from 'react-intl';

export default defineMessages({
  compliant: {
    id: 'sparePartRequest.requestLine.stateUponReceipt.compliant',
    defaultMessage: 'Compliant',
  },
  broken: {
    id: 'sparePartRequest.requestLine.stateUponReceipt.broken',
    defaultMessage: 'Broken',
  },
  mismatch: {
    id: 'sparePartRequest.requestLine.stateUponReceipt.mismatch',
    defaultMessage: 'Mismatch',
  },
  mismatchWrongPart: {
    id: 'sparePartRequest.requestLine.stateUponReceipt.mismatchWrongPart',
    defaultMessage: 'Mismatch Wrong Part',
  },
});
