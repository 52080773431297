import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { Skeleton } from '@savgroup-front-common/core/src/atoms/Skeleton';
import LinkHeader from '@savgroup-front-common/core/src/components/common/linkHeader/LinkHeader';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { customerState } from 'myaccount/domains/Customer/selectors';

import { ROUTES } from '../../../app/routes/Routes';

import {
  $FileHeader,
  $LinkHeader,
  HeaderContainer,
} from './FileFollowups.styles';
import messages from './messages';

const FileFollowupsHeader = ({ children }) => {
  const customer = useSelector(customerState);
  const isGridFluidActive = useMedia(media.maxWidth.md);

  const fileReference = customer?.file?.fileReference;

  return (
    <>
      <$LinkHeader>
        <LinkHeader href={ROUTES.HOME} message={messages.back} />
      </$LinkHeader>
      <HeaderContainer>
        <Grid fluid={isGridFluidActive}>
          <Row>
            <Col xs={12}>
              <SafeFormattedMessageWithoutSpread
                message={messages.title_file_detail}
                values={{ fileReference: '' }}
              />
            </Col>
            <Col xs={12} sm={6}>
              <$FileHeader level={1} dataTestId="fileReference" color="black">
                {fileReference || <Skeleton isInline width="15rem" />}
              </$FileHeader>
            </Col>
          </Row>
          {children}
        </Grid>
      </HeaderContainer>
    </>
  );
};

FileFollowupsHeader.displayName = 'FileFollowupsHeader';

export default FileFollowupsHeader;
