import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  Store,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.actor}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/stores/${COMMON_QUERY_PARAMS.ID}`;

const getStoreByIdQuery = async ({
  sellerId,
  storeId,
}: {
  sellerId: string;
  storeId: string;
}): Promise<BackResponse<Store> | BackResponseFailure> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      sellerId,
    ).replace(COMMON_QUERY_PARAMS.ID, storeId);

    const response = await apiCall<BackResponse<Store>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getStoreByIdQuery;
