import React, { Ref } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import { INPUT_TYPES, media } from '@savgroup-front-common/constants';
import CheckboxNaked from '@savgroup-front-common/core/src/atoms/checkbox/CheckboxNaked';
import { Link } from '@savgroup-front-common/core/src/atoms/link';
import { ProductImage } from '@savgroup-front-common/core/src/atoms/ProductImage';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';
import CheckIcon from '@savgroup-front-common/core/src/protons/icons/Check/Check.icon';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import {
  FileShortSummary,
  OrderDetailsProduct,
} from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import { ChoiceProductValues } from '../../ChooseProductPage.types';
import ChooseProductTag from '../ChooseProductTag/ChooseProductTag';

import useChooseProductCard from './ChooseProductCard.hooks';
import {
  $ChooseProductCardCheckboxContainer,
  $ChooseProductCardContainer,
  $ChooseProductCardImageContainer,
  $ChooseProductCardInfoContainer,
  $ColonProductInfo,
  $OpenFile,
  $RowProductInfo,
} from './ChooseProductCard.styles';
import messages from './messages';

interface ChooseProductCardProps {
  product: OrderDetailsProduct;
  openedFiles: FileShortSummary[];
  formContext: UseFormReturn<ChoiceProductValues>;
  isDisabled?: boolean;
  isInProgress?: boolean;
  notEligible?: boolean;
}

interface RadioCardWithRefProps extends ChooseProductCardProps {
  forwardedRef?: Ref<HTMLInputElement>;
}

const ChooseProductCard: React.FC<RadioCardWithRefProps> = ({
  product,
  openedFiles,
  formContext,
  forwardedRef = null,
  isDisabled = false,
  isInProgress = false,
  notEligible = false,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const theme = useTheme();
  const { modelDetail } = useChooseProductCard({ product });
  const { control, watch } = formContext;

  const selectedOwnerProductIds = watch('selectedOwnerProductIds');

  const isChecked = selectedOwnerProductIds?.includes(product.ownerProductId);

  const isOpenedFile = openedFiles.some((file) => {
    return file.fileProducts.some(
      ({ ownerProductId }) => ownerProductId === product.ownerProductId,
    );
  });

  const fileOpenedId = openedFiles.find((file) => {
    return file.fileProducts.filter(
      ({ ownerProductId }) => ownerProductId === product.ownerProductId,
    );
  });

  return (
    <>
      {!isDisabled && (
        <Controller
          control={control}
          name="selectedOwnerProductIds"
          render={({ field }) => {
            return (
              <$ChooseProductCardContainer
                isDisabled={false}
                isChecked={isChecked}
              >
                <CheckboxNaked
                  checked={isChecked}
                  onChange={(event) => {
                    return field.onChange([
                      ...selectedOwnerProductIds,
                      event.target.value,
                    ]);
                  }}
                  onClick={() => {
                    if (isChecked) {
                      return field.onChange(
                        selectedOwnerProductIds.filter(
                          (item) => item !== product.ownerProductId,
                        ),
                      );
                    }

                    return undefined;
                  }}
                  name={field.name}
                  ref={forwardedRef}
                  value={product.ownerProductId}
                  type={INPUT_TYPES.RADIO}
                />
                <$RowProductInfo>
                  <$ChooseProductCardCheckboxContainer isChecked={isChecked}>
                    {isChecked && (
                      <CheckIcon color={theme.colors.white} size="16px" />
                    )}
                  </$ChooseProductCardCheckboxContainer>
                  <$ChooseProductCardImageContainer isDisabled={isDisabled}>
                    <ProductImage pictureUrl={modelDetail?.pictureUrl} />
                  </$ChooseProductCardImageContainer>
                  <$ChooseProductCardInfoContainer isDisabled={isDisabled}>
                    <>
                      {isMobileView && (
                        <ChooseProductTag
                          product={product}
                          openedFiles={openedFiles}
                          isSmall={isMobileView}
                        />
                      )}
                      <span>{product.modelDisplayName}</span>
                    </>
                  </$ChooseProductCardInfoContainer>
                  {!isMobileView && (
                    <ChooseProductTag
                      product={product}
                      openedFiles={openedFiles}
                    />
                  )}
                </$RowProductInfo>
              </$ChooseProductCardContainer>
            );
          }}
        />
      )}
      {isDisabled && (
        <$ChooseProductCardContainer isDisabled isInProgress={isInProgress}>
          <$ColonProductInfo>
            <$RowProductInfo>
              <$ChooseProductCardImageContainer isDisabled={isDisabled}>
                {modelDetail?.pictureUrl && modelDetail.modelTypeId ? (
                  <img src={modelDetail?.pictureUrl} alt="pictureUrl" />
                ) : (
                  <MyAccountIcon icon={ICONS_TYPE.BOX_ICON} />
                )}
              </$ChooseProductCardImageContainer>
              <$ChooseProductCardInfoContainer isDisabled>
                <>
                  {isMobileView && (
                    <ChooseProductTag
                      product={product}
                      openedFiles={openedFiles}
                      isInProgress={isInProgress}
                      notEligible={notEligible}
                      isSmall={isMobileView}
                    />
                  )}

                  <span>{product.modelDisplayName}</span>
                  {isMobileView && isOpenedFile && fileOpenedId?.fileId && (
                    <$OpenFile>
                      <Link
                        href={`/new_design/files/${fileOpenedId?.fileId}`}
                        icon={<ArrowNakedRightIcon color="#000" size="14px" />}
                      >
                        <SafeFormattedMessageWithoutSpread
                          message={messages.fileOpenedLink}
                        />
                      </Link>
                    </$OpenFile>
                  )}
                  {isMobileView && product.claimGroupId && (
                    <$OpenFile>
                      <Link
                        href={`/new_design/claimGroup/${product.claimGroupId}/reason`}
                        icon={<ArrowNakedRightIcon color="#000" size="14px" />}
                      >
                        <SafeFormattedMessageWithoutSpread
                          message={messages.followClaimGroupLink}
                        />
                      </Link>
                    </$OpenFile>
                  )}
                </>
              </$ChooseProductCardInfoContainer>
              {!isMobileView && (
                <div>
                  <ChooseProductTag
                    product={product}
                    openedFiles={openedFiles}
                    isInProgress={isInProgress}
                    notEligible={notEligible}
                  />

                  {isOpenedFile && fileOpenedId?.fileId && (
                    <$OpenFile>
                      <Link
                        href={`/new_design/files/${fileOpenedId?.fileId}`}
                        icon={<ArrowNakedRightIcon color="#000" size="14px" />}
                      >
                        <SafeFormattedMessageWithoutSpread
                          message={messages.fileOpenedLink}
                        />
                      </Link>
                    </$OpenFile>
                  )}
                  {product.claimGroupId && (
                    <$OpenFile>
                      <Link
                        href={`/new_design/claimGroup/${product.claimGroupId}/reason`}
                        icon={<ArrowNakedRightIcon color="#000" size="14px" />}
                      >
                        <SafeFormattedMessageWithoutSpread
                          message={messages.followClaimGroupLink}
                        />
                      </Link>
                    </$OpenFile>
                  )}
                </div>
              )}
            </$RowProductInfo>
          </$ColonProductInfo>
        </$ChooseProductCardContainer>
      )}
    </>
  );
};

ChooseProductCard.displayName = 'ChooseProductCard';

export default ChooseProductCard;
