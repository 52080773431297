import { createRequest } from '../../services/request';

export const types = {
  LOGIN_SIGNING_IN: 'LOGIN_SIGNING_IN',
  LOGIN_SIGNED_IN: 'LOGIN_SIGNED_IN',
  LOGIN_SIGNED_ENHANCED: createRequest('LOGIN_SIGNED_ENHANCED'),
  LOGIN_SIGNOUT: 'LOGIN_SIGNOUT',
  LOGIN_SIGNEDOUT: 'LOGIN_SIGNEDOUT',
};
export const creators = {};
