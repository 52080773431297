import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

const SearchIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color} viewBox="0 0 16 16">
    <path
      id="Vector (Stroke)"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.20854 3.20854C4.30251 2.11458 5.78624 1.5 7.33333 1.5C8.88043 1.5 10.3642 2.11458 11.4581 3.20854C12.5521 4.30251 13.1667 5.78624 13.1667 7.33333C13.1667 8.71288 12.678 10.042 11.7965 11.0894L14.3536 13.6464C14.5488 13.8417 14.5488 14.1583 14.3536 14.3536C14.1583 14.5488 13.8417 14.5488 13.6464 14.3536L11.0894 11.7965C10.042 12.678 8.71288 13.1667 7.33333 13.1667C5.78624 13.1667 4.30251 12.5521 3.20854 11.4581C2.11458 10.3642 1.5 8.88043 1.5 7.33333C1.5 5.78624 2.11458 4.30251 3.20854 3.20854ZM7.33333 2.5C6.05145 2.5 4.82208 3.00922 3.91565 3.91565C3.00922 4.82208 2.5 6.05145 2.5 7.33333C2.5 8.61521 3.00922 9.84459 3.91565 10.751C4.82208 11.6574 6.05145 12.1667 7.33333 12.1667C8.61521 12.1667 9.84459 11.6574 10.751 10.751C11.6574 9.84459 12.1667 8.61521 12.1667 7.33333C12.1667 6.05145 11.6574 4.82208 10.751 3.91565C9.84459 3.00922 8.61521 2.5 7.33333 2.5Z"
      fill={color}
    />
  </Icon>
);

SearchIcon.displayName = 'SearchIcon';

export default SearchIcon;
