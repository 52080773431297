import { COMMON_STEPS } from '@savgroup-front-common/constants/src/shared';

import messages from './messages';
import CarrierForm from './steps/CarrierForm';
import SelectCarrierType from './steps/SelectCarrierType';

export enum HANDLING_STEP_NAMES {
  SELECT_CARRIER_TYPE = 'SELECT_CARRIER_TYPE',
  CARRIER_FORM = 'CARRIER_FORM',
}

export const HANDLING_STEPS_CONFIG = [
  {
    name: HANDLING_STEP_NAMES.SELECT_CARRIER_TYPE,
    Component: SelectCarrierType,
    nextStep: HANDLING_STEP_NAMES.CARRIER_FORM,
    message: messages.selectCarrierTypeWizard,
  },
  {
    name: HANDLING_STEP_NAMES.CARRIER_FORM,
    Component: CarrierForm,
    nextStep: COMMON_STEPS.SUBMIT,
    message: messages.carrierFormWizard,
  },
];
