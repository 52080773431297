import { useQuery } from 'react-query';

import { CommonClaimService } from '@savgroup-front-common/core/src/api';
import { LocalizedAdditionalInformation } from '@savgroup-front-common/core/src/api/Claim/getAdditionalInformationEntitiesSummaries';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { AdditionalInformation } from '@savgroup-front-common/types';

import { getAdditionalInformationIdsFromFileSummary } from '../../helpers/getAdditionalInformationIdsFromFileSummary';

interface UseGetAdditionalInformationEntitiesArgs {
  fileSummary: {
    fileProducts: {
      additionalInformation?: AdditionalInformation[];
      fileAdditionalInformation?: AdditionalInformation[];
    }[];
  };
}

export const useGetAdditionalInformationEntities = ({
  fileSummary,
}: UseGetAdditionalInformationEntitiesArgs): {
  isLoading: boolean;
  additionalInformationEntitiesMap: Record<
    string,
    LocalizedAdditionalInformation
  >;
} => {
  const { pushErrors } = useToasts();

  const ids = getAdditionalInformationIdsFromFileSummary({ fileSummary });

  const { isLoading, data: additionalInformationEntitiesMap } = useQuery(
    ['getAdditionalInformationEntitiesSummaries', { ids }],
    async () => {
      if (ids.length === 0) {
        return undefined;
      }

      const response =
        await CommonClaimService.getAdditionalInformationEntitiesSummaries({
          ids,
        });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value.reduce<
        Record<string, LocalizedAdditionalInformation>
      >((acc, item) => {
        return { ...acc, [item.id]: item };
      }, {});
    },
  );

  return {
    isLoading,
    additionalInformationEntitiesMap: additionalInformationEntitiesMap || {},
  };
};
