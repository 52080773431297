import styled from 'styled-components';

import { Card } from '@savgroup-front-common/core/src/atoms/Cards';

export const ProductIRSSummaryCardsGroupContainer = styled('div')`
  margin-top: 2rem;
`;

export const SummaryCardContainer = styled(Card)`
  margin-top: ${({ flatMode, multiProduct }) => {
    if (flatMode) {
      return '2px';
    }
    if (multiProduct) {
      return '16px';
    }

    return null;
  }};

  &:not(:last-child) {
    margin-bottom: ${(props) => (props.flatMode ? '0' : null)};
  }
`;
