import React from 'react';

interface BoxIconProps {
  color?: string;
}

/* eslint-disable max-len */
const BoxIcon: React.FC<BoxIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.44531 12.4354L7.05452 11.0646L20.2499 16.9293L33.4453 11.0646L34.0545 12.4354L20.2499 18.5707L6.44531 12.4354Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 34.25V17.75H21V34.25H19.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5713 8.43065C13.7477 8.05587 14.1945 7.89502 14.5693 8.07139L27.7499 14.274V20.75C27.7499 21.1642 27.4141 21.5 26.9999 21.5C26.5857 21.5 26.2499 21.1642 26.2499 20.75V15.226L13.9306 9.42862C13.5558 9.25224 13.3949 8.80544 13.5713 8.43065Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9454 5.06464C20.1393 4.97845 20.3607 4.97845 20.5546 5.06464L34.0546 11.0646C34.3254 11.185 34.5 11.4536 34.5 11.75V28.25C34.5 28.5464 34.3254 28.815 34.0546 28.9354L20.5546 34.9354C20.3607 35.0215 20.1393 35.0215 19.9454 34.9354L6.4454 28.9354C6.17455 28.815 6 28.5464 6 28.25V11.75C6 11.4536 6.17455 11.185 6.4454 11.0646L19.9454 5.06464ZM7.5 12.2374V27.7626L20.25 33.4293L33 27.7626V12.2374L20.25 6.57074L7.5 12.2374Z"
        fill={color}
      />
    </svg>
  );
};

BoxIcon.displayName = 'BoxIcon';

export default BoxIcon;
