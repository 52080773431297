import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ActionCreators } from 'myaccount/domains/Modals';

import CommentModal from './CommentModal';
import { Modal } from '@savgroup-front-common/core/src/atoms/modal';

const OtherIssueModal = ({ data, onClose }) => {
  const dispatch = useDispatch();

  const handleOtherIssueModalSubmit = useCallback(
    (payload) => {
      dispatch(ActionCreators.submitOtherIssueModal(payload));
    },
    [dispatch],
  );

  return (
    <Modal onClose={onClose} isOpen>
      <CommentModal
        onSubmit={handleOtherIssueModalSubmit}
        actions={data.actions}
        closeModal={onClose}
        isModal
      />
    </Modal>
  );
};

export default OtherIssueModal;
