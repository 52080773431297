import React from 'react';
import { FormattedDate } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import { H4 } from '@savgroup-front-common/core/src/atoms/headings';

import {
  InfoCol,
  InfoLine,
  InfoTag,
  Separator,
} from '../../pages/FileFollowupsPage/FileFollowups/FileFollowups.styles';

const ActionLabel = ({ action, index }) => {
  if (!action) {
    return <></>;
  }

  const { fileStatus, fileStatusDate } = action;

  return (
    <Row>
      <Col xs={12}>
        <>
          {index !== 0 && <Separator />}
          <InfoLine between="xs">
            <Col>
              <H4 heading>
                <FormattedDate value={fileStatusDate} />
              </H4>
            </Col>
            <InfoCol>
              <InfoTag
                primary
                noEllispis
                fluid
                dataTestId={
                  index === 0 ? 'currentFileStatus' : 'olderFileStatus'
                }
              >
                {fileStatus.label}
              </InfoTag>
            </InfoCol>
          </InfoLine>
        </>
      </Col>
    </Row>
  );
};

ActionLabel.displayName = 'ActionLabel';

export default ActionLabel;
