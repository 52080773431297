/* eslint-disable @typescript-eslint/no-var-requires,global-require */
import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';
import DEMO_GOOGLE_CONFIG from './google.config.DEMO';
import DEV_GOOGLE_CONFIG from './google.config.DEV';
import LOCAL_GOOGLE_CONFIG from './google.config.LOCAL';
import PROD_GOOGLE_CONFIG from './google.config.PROD';
import DEFAULT_GOOGLE_CONFIG from './google.config.DEFAULT';

const getCurrentGoogleConfigs = () => {
  switch (currentAppEnvironment) {
    case APP_ENVS.DEMO:
      return DEMO_GOOGLE_CONFIG;
    case APP_ENVS.DEV:
      return DEV_GOOGLE_CONFIG;
    case APP_ENVS.LOCAL:
      return LOCAL_GOOGLE_CONFIG;
    case APP_ENVS.PROD:
      return PROD_GOOGLE_CONFIG;
    case APP_ENVS.DEFAULT:
    default:
      return DEFAULT_GOOGLE_CONFIG;
  }
};

export default getCurrentGoogleConfigs();
