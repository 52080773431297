import styled from 'styled-components';

export const $Description = styled.div`
  white-space: pre-wrap;
  margin-bottom: 1rem;
`;

export const $ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 1rem;
`;

export const $BonusLine = styled.div`
  display: flex;
  gap: 2rem;
  padding: 1rem;
  margin-bottom: 1rem;
  & a {
    padding-left: 10px;
  }
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
`;
