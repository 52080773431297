import { EVENT_CODE } from '../shared/keyCodes';

const SHORTCUT_LIST_CONTROL = {
  CREATE_MODAL_1: [EVENT_CODE.DIGIT_1],
  CREATE_MODAL_2: [EVENT_CODE.DIGIT_2],
  CREATE_MODAL_3: [EVENT_CODE.DIGIT_3],
  CREATE_MODAL_4: [EVENT_CODE.DIGIT_4],
  CREATE_MODAL_5: [EVENT_CODE.DIGIT_5],
  CREATE_MODAL_6: [EVENT_CODE.DIGIT_6],
  CREATE_MODAL_7: [EVENT_CODE.DIGIT_7],
  CREATE_MODAL_8: [EVENT_CODE.DIGIT_8],
  CREATE_MODAL_9: [EVENT_CODE.DIGIT_9],
  SHORTCUT_MODAL: [EVENT_CODE.QUOTE],
  FEATURE_FLAGS_MODAL: [EVENT_CODE.SEMICOLON],
  LIST_TOGGLE: [EVENT_CODE.PERIOD],
  FILTERS: [EVENT_CODE.COMMA],
  SEARCH_OPEN: [EVENT_CODE.CONTROL_LEFT, EVENT_CODE.SPACE],
  SEARCH_NAVIGATE_DOWN: [EVENT_CODE.ARROW_DOWN],
  SEARCH_NAVIGATE_UP: [EVENT_CODE.ARROW_UP],
  SEARCH_SELECT_ITEM: [EVENT_CODE.ENTER],
  SEARCH_CLOSE: [EVENT_CODE.ESCAPE],
};

export default SHORTCUT_LIST_CONTROL;
