import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $BackButton = styled(Button)`
  padding-left: 0;
`;

export const $ClaimWrapper = styled('div')`
  padding: 0;
`;

export const $TopButtonActionWrapper = styled(Row)`
  margin: ${rem('35px')} 0;
  padding-bottom: ${rem('57px')};
`;
