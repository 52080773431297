import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { BUTTON_TYPES, DOCUMENT_TYPES } from '@savgroup-front-common/constants';
import { Loader } from '@savgroup-front-common/core/src/atoms/loader';
import {
  AppErrorBoundary,
  BaseErrorBoundary,
} from '@savgroup-front-common/core/src/molecules/ErrorBoundary';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import ChooseIssuePage from 'myaccount/view/pages/ChooseIssuePage';
import ChooseProductsPage from 'myaccount/view/pages/ChooseProductsPage';
import ClaimGroupPage from 'myaccount/view/pages/ClaimGroupPage';
import FileDetailPage from 'myaccount/view/pages/FileFollowupsPage';
import OrdersPage from 'myaccount/view/pages/OrdersPage';
import PageNotFound from 'myaccount/view/pages/PageNotFound';

import { $InitialLoaderContainer } from '../App.styles';
import { ROUTES } from '../routes/Routes';

import AppHeader from './header/AppHeader';
import { useLayout } from './Layout.hooks';
import { PageWrapper } from './Layout.styles';
import messages from './messages';
import { useGetClientInformation } from '../hooks';
import { Portals } from '../../../types';
import { ROUTES_PROFESSIONAL } from '../routes/ProfessionalRoutes';

const steps = {
  1: ROUTES.ISSUES,
  2: ROUTES.CHOOSE_PRODUCTS,
  3: [ROUTES.CLAIM_GROUP_DETAILS, ROUTES.CLAIM_GROUP_ISSUES],
  4: ROUTES.CLAIM_GROUP_HANDLING_DEPOSIT,
  5: ROUTES.CLAIM_GROUP_HANDLING_DELIVERY,
  6: ROUTES.CLAIM_GROUP_CONFIRMATION,
};

const Layout = ({ userManager }) => {
  const location = useLocation();
  const { login, fileId, sellerId, errors, errorActions, handleLogout, url } =
    useLayout({ userManager });

  const { clientInformation } = useGetClientInformation();
  const isBtoBPortalEnabled = clientInformation?.enablePortals?.includes(
    Portals.BTOB,
  );

  if (errors.hasError) {
    return (
      <BaseErrorBoundary
        bodyMessage={errors.message}
        onClose={errorActions.clearErrors}
      >
        <Button type={BUTTON_TYPES.BUTTON} onClick={errorActions.clearErrors}>
          <SafeFormattedMessageWithoutSpread message={messages.close} />
        </Button>
      </BaseErrorBoundary>
    );
  }

  if (!sellerId) {
    return (
      <$InitialLoaderContainer>
        <Loader color="#333" size="50px" />
      </$InitialLoaderContainer>
    );
  }

  return (
    <AppErrorBoundary>
      <PageWrapper>
        <AppHeader
          logoUrl={`${
            APIConfiguration.imageCDN
          }sellers/${sellerId}/${DOCUMENT_TYPES.LOGO.toLocaleLowerCase()}`}
          loginInfos={login}
          onLogout={handleLogout}
          sellerId={sellerId}
          url={
            url
              ? { isExternal: true, href: url }
              : {
                  isExternal: false,
                  href: isBtoBPortalEnabled
                    ? ROUTES_PROFESSIONAL.HOME
                    : ROUTES.HOME,
                }
          }
          steps={steps}
        />
        <Switch location={location.location}>
          <Route
            exact
            path={ROUTES.ORDER_CREATE_CLAIM}
            component={OrdersPage}
          />
          <Route exact path={ROUTES.PRODUCTS} component={OrdersPage} />

          <Route path={ROUTES.CHOOSE_PRODUCTS} component={ChooseProductsPage} />
          <Route path={ROUTES.ISSUES} component={ChooseIssuePage} />

          <Route path={ROUTES.FILE_FOLLOWUPS} component={FileDetailPage} />

          <Route path={ROUTES.CLAIM_GROUP_DETAILS} component={ClaimGroupPage} />

          <Route exact path={ROUTES.PAGE_NOT_FOUND} component={PageNotFound} />

          <Redirect
            from={ROUTES.OLD_FILE_DETAILS}
            to={ROUTES.FILE_FOLLOWUPS.replace(':fileId', fileId)}
          />
          <Redirect from={ROUTES.HOME} to={ROUTES.PRODUCTS} push />
        </Switch>
      </PageWrapper>
    </AppErrorBoundary>
  );
};

Layout.propTypes = {
  userManager: PropTypes.shape({}).isRequired,
};

Layout.whyDidYouRender = true;
Layout.displayName = 'Layout';

export default Layout;
