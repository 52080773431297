import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'view.confirmation.storeActor.label',
    defaultMessage: 'Store actor',
  },
  title: {
    id: 'view.confirmation.storeActor.title',
    defaultMessage: 'Store actor',
  },
  didactic: {
    id: 'vview.confirmation.storeActor.didactic',
    defaultMessage: 'Selet the store actor',
  },
});
