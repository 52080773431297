import { PaymentStatus } from '../CashRegisterPayment.types';

import useGetPaymentUrl from './useGetPaymentUrl';

interface UseCashRegisterPaymentReturnValue {
  paymentStatus?: PaymentStatus;
}
interface UseCashRegisterPaymentArgs {
  fileId: string;
  claimGroupId: string;
  paymentUrl: string;
}

const useCashRegisterPayment = ({
  fileId,
  claimGroupId,
  paymentUrl,
}: UseCashRegisterPaymentArgs): UseCashRegisterPaymentReturnValue => {
  const paymentStatus = useGetPaymentUrl({
    fileId,
    claimGroupId,
    paymentUrl,
  });

  return {
    paymentStatus,
  };
};

export default useCashRegisterPayment;
