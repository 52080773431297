import React from 'react';

interface StarsIconProps {
  color?: string;
}

/* eslint-disable max-len */
const StarsIcon: React.FC<StarsIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.72509 24.025L9.45676 20.3533C9.5036 20.2484 9.57984 20.1592 9.67627 20.0967C9.77269 20.0341 9.88516 20.0009 10.0001 20.0009C10.115 20.0009 10.2275 20.0341 10.3239 20.0967C10.4203 20.1592 10.4966 20.2484 10.5434 20.3533L12.2768 24.025L16.1484 24.6183C16.6451 24.6933 16.8418 25.3333 16.4818 25.7L13.6818 28.555L14.3418 32.59C14.4268 33.1083 13.9084 33.5033 13.4634 33.2583L10.0001 31.3533L6.53676 33.2583C6.09176 33.5033 5.57343 33.1083 5.65843 32.5917L6.31843 28.555L3.51843 25.7C3.15676 25.3333 3.35509 24.6933 3.85176 24.6167L7.72509 24.025ZM27.7251 24.025L29.4568 20.3533C29.5036 20.2484 29.5798 20.1592 29.6763 20.0967C29.7727 20.0341 29.8852 20.0009 30.0001 20.0009C30.115 20.0009 30.2275 20.0341 30.3239 20.0967C30.4203 20.1592 30.4966 20.2484 30.5434 20.3533L32.2768 24.025L36.1484 24.6183C36.6451 24.6933 36.8418 25.3333 36.4818 25.7L33.6818 28.555L34.3418 32.59C34.4268 33.1083 33.9084 33.5033 33.4634 33.2583L30.0001 31.3533L26.5368 33.2583C26.0918 33.5033 25.5734 33.1083 25.6584 32.5917L26.3184 28.555L23.5184 25.7C23.1584 25.3333 23.3551 24.6933 23.8518 24.6167L27.7251 24.025ZM17.7251 9.025L19.4568 5.35333C19.5036 5.24838 19.5798 5.15924 19.6763 5.09669C19.7727 5.03414 19.8852 5.00085 20.0001 5.00085C20.115 5.00085 20.2275 5.03414 20.3239 5.09669C20.4203 5.15924 20.4966 5.24838 20.5434 5.35333L22.2768 9.025L26.1484 9.61833C26.6451 9.69333 26.8418 10.3333 26.4818 10.7L23.6818 13.555L24.3418 17.59C24.4268 18.1083 23.9084 18.5033 23.4634 18.2583L20.0001 16.3533L16.5368 18.2583C16.0918 18.5033 15.5734 18.1083 15.6584 17.5917L16.3184 13.555L13.5184 10.7C13.1568 10.3333 13.3551 9.69333 13.8518 9.61667L17.7251 9.025Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

StarsIcon.displayName = 'StarsIcon';

export default StarsIcon;
