import { Dictionary } from 'lodash';
import get from 'lodash/get';
import head from 'lodash/head';
import result from 'lodash/result';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import {
  HANDLING_GROUPS,
  HANDLING_MODES,
} from '@savgroup-front-common/constants';
import { StrictNextStep } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { verifiedAddress } from '@savgroup-front-common/core/src/domains/carriers/verifiedAddress/selectors';
import { AddressInfo, AddressInfoDto } from '@savgroup-front-common/types';
import { selectGroupHandlingInfo } from 'myaccount/domains/Claim/claimGroupSelector';
import { verificationErrors } from 'myaccount/domains/Views/verificationErrorsSelector';
import { formatVerifiedAddress } from 'myaccount/formatters/carrier';

import { loginState } from '../../../../../../../domains/Customer/selectors';
import { HANDLING_STEP_NAMES } from '../../../ClaimGroupHandling.steps';
import { Carrier } from '../../../ClaimGroupHandling.types';
import { useGetGroupedCarriers } from '../../SelectCarrierType/hooks';
import adaptAddressInfo from '../adapters/adaptAddressInfo';
import { CarrierFormValues } from '../CarrierForm.types';

import useHandleSubmitHomePickup from './useHandleSubmitHomePickup';

interface UseGetHomePickupArgs {
  handlingMode: HANDLING_MODES;
  groupedCarriers: Dictionary<Carrier[]>;
  selectedCarrierType: HANDLING_GROUPS;
  nextStep: StrictNextStep<CarrierFormValues>;
  jumpToStep: (step: string) => void;
}

interface UseGetHomePickupReturnValue {
  verifiedAddressIsLoaded: boolean;
  addressErrors: unknown;
  homePickupAddress?: AddressInfoDto;
  handleAddressChange: (payload: AddressInfoDto) => void;
  handleSubmitHomePickup: (payload: any) => void;
}

const useGetHomePickup = ({
  handlingMode,
  groupedCarriers,
  selectedCarrierType,
  nextStep,
  jumpToStep,
}: UseGetHomePickupArgs): UseGetHomePickupReturnValue => {
  const login = useSelector(loginState);
  const verifiedAddressValue = useSelector(verifiedAddress);
  const addressErrors = useSelector(verificationErrors);
  const verifiedAddressToJS = result(verifiedAddressValue.get('value'), 'toJS');
  const verifiedAddressIsLoaded = verifiedAddressValue.get('isLoaded');

  const handlingInfo = useSelector(selectGroupHandlingInfo) as any;
  const currentHandlingAddressDeposit = get(handlingInfo, [
    HANDLING_MODES.DEPOSIT,
    'address',
  ]);
  const currentHandlingAddressDelivery = get(handlingInfo, [
    HANDLING_MODES.DELIVERY,
    'address',
  ]);
  const handlingAddress = adaptAddressInfo({
    address: currentHandlingAddressDelivery || currentHandlingAddressDeposit,
  });

  const formattedAddress = formatVerifiedAddress(
    verifiedAddressToJS,
    handlingAddress,
  );
  const homePickupAddressDefault = formattedAddress?.address
    ? formattedAddress
    : handlingAddress;

  const [homePickupAddress, setHomePickupAddress] = useState<
    | (AddressInfoDto & {
        firstName?: AddressInfo['firstName'];
        lastName?: AddressInfo['lastName'];
      })
    | undefined
  >(undefined);

  const handleAddressChange = useCallback((payload: AddressInfoDto) => {
    setHomePickupAddress(payload);
  }, []);

  const handleSubmitHomePickup = useHandleSubmitHomePickup({
    groupedCarriers,
    selectedCarrierType,
    homePickupAddress,
    nextStep,
  });

  const { carriers: carriersToCheckStep } = useGetGroupedCarriers({
    handlingInfo,
  });

  useDeepCompareEffect(() => {
    const firstCarrier = head(carriersToCheckStep);

    if (firstCarrier?.handlingMode !== handlingMode) {
      jumpToStep(HANDLING_STEP_NAMES.SELECT_CARRIER_TYPE);
    }
  }, [carriersToCheckStep, handlingMode, jumpToStep]);

  useDeepCompareEffect(() => {
    if (homePickupAddressDefault?.address) {
      setHomePickupAddress(homePickupAddressDefault);
    } else {
      setHomePickupAddress(adaptAddressInfo({ address: login?.ownerAddress }));
    }
  }, [homePickupAddressDefault, login?.ownerAddress]);

  return {
    verifiedAddressIsLoaded,
    addressErrors,
    homePickupAddress,
    handleAddressChange,
    handleSubmitHomePickup,
  };
};

export default useGetHomePickup;
