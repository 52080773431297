import { Reducer } from 'react';

export type isMultiProductAction = {
  payload: boolean;
};

export const isMultiProductState = ({ state }: { state: boolean }): boolean => {
  return state;
};

const isMultiProductReducer: Reducer<boolean, isMultiProductAction> = (
  state,
  action,
) => action.payload;

export default isMultiProductReducer;
