import styled from 'styled-components';
import { media } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { transparentize } from 'polished';
import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { getCustomComponentProperty } from '@savgroup-front-common/core/src/helpers';

// export const $HomeProductsListWrapper = styled.div`
//   gap: 1rem;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   font-weight: bold;
// `;

export const $HomeContentWrapper = styled.div`
  background-color: white;
  border-radius: ${({ theme }) =>
    getCustomComponentProperty({
      theme,
      componentName: SUPPORTED_COMPONENTS.CARD,
      property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
      fallback: theme.borders.radius,
    })};
  min-height: 70vh;
  padding: 1.8rem 3rem;
  gap: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${media.maxWidth.xs} {
    padding: 1rem;
  }

  & [class*='TabContainer'] {
    padding: 0;
  }

  & [class*='RouteTabLink'] {
    padding: 1rem;
    padding-top: 0;
  }

  & [class*='TabViewList'] {
    width: fit-content;
    padding: 0;

    & a:-webkit-any-link {
      text-decoration: none;
    }

    & [class*='RouteTabLink'] {
      & [class*='MessageWithIcon'] {
        flex-direction: column;
        gap: 0.25rem;

        & > svg {
          width: 32px;
          height: 32px;
          margin: 0;
          fill: none;
        }
      }
      &:hover,
      &:focus {
        & [class*='MessageWithIcon'] > svg path[stroke] {
          stroke: ${({ theme }) => theme.colors.primary} !important;
        }
        & [class*='MessageWithIcon'] > svg path[fill] {
          fill: ${({ theme }) => theme.colors.primary} !important;
        }
      }
      &:focus {
        & [class*='MessageWithIcon'] > span {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }

  & [class*='TabViewNav'] {
    width: fit-content;
    padding: 0;
    margin: auto;
  }
`;

export const $HomeProductsListAddProduct = styled.div`
  display: flex;
  justify-content: flex-end;

  && button {
    border-radius: 8px;
  }
`;
export const $AddProductWrapper = styled.div`
  padding: 2rem;
`;

export const $CloseButton = styled(Button)`
  padding: 0;
  height: auto;
  width: auto;
  min-height: auto;
  min-width: auto;
  margin-bottom: 1rem;

  & > span > svg {
    margin-right: 0;
    width: 2rem;
    height: 2rem;
    max-width: 2rem;
    max-height: 2rem;
  }
`;

export const $HelperContainerTitle = styled('div')`
  margin: 2rem 0 1rem;
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumBold};
`;

export const $HomePageAddProductContent = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
  overflow: auto;
  max-height: calc(100vh - 225px);
  padding-left: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 3rem;
`;

export const $HomePageAddProductAction = styled.div<{ $isVisible?: boolean }>`
  position: absolute;
  bottom: ${({ $isVisible }) => ($isVisible ? '1rem' : '0')};
  left: 2rem;
  right: 2rem;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  transition: bottom 0.2s ease-in-out;
  z-index: 1;

  && button {
    border-radius: ${({ theme }) => theme.borders.radius};
  }
`;

export const $ProductItem = styled.div<{ $isChecked?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.borders.radius};
  border: 1px solid
    ${({ theme, $isChecked }) =>
      $isChecked ? theme.colors.primary : 'transparent'};
  box-shadow: ${({ theme, $isChecked }) =>
    $isChecked
      ? `0 0 0.5rem ${transparentize(0.7, theme.colors.primary)}`
      : '0 0 1rem rgba(0, 0, 0, 0.05)'};
  max-width: calc(33% - 0.75rem);
  flex: 1;
  flex-basis: 33.33%;
  position: relative;

  @media ${media.maxWidth.xs} {
    max-width: calc(50% - 0.75rem);
    flex-basis: 50%;
  }

  & [class*='Checkboxstyles__CheckboxContainer'] {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    margin: 0;
  }

  & input {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
  }

  & [class*='Checkboxstyles__StyledCheckbox'] {
    top: 1rem;
    left: 1rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background: white;
    border-width: 1px;
    padding: 5px;
    background: ${({ $isChecked, theme }) =>
      $isChecked ? theme.colors.primary : 'white'};
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0.5rem
      ${({ theme }) => transparentize(0.7, theme.colors.primary)};
  }
`;

export const $ProductItemPicture = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.25rem;
  overflow: hidden;

  & img {
    max-width: 100%;
  }
`;

export const $ProductItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBackground};
  font-size: ${({ theme }) => theme.fonts.size.large};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  margin-bottom: 4px;
  align-self: baseline;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
`;

export const $ProductItemDescription = styled.div`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  align-self: baseline;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
`;

export const $HomeProductsListWrapper = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & [class*='MonoProductInfostyles__Card'],
  &
    [class*='MonoProductInfostyles__Card']
    ~ [class*='MonoProductInfostyles__Card'] {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
