import flatten from 'lodash/flatten';
import * as Yup from 'yup';

import { ALLOWED_MIME_TYPES } from '@savgroup-front-common/constants';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';

import { safeFormattedIntlString } from '../../formatters';
import {
  getUniqErrorMessages,
  globalMessages,
  isValidIMEI,
} from '../../helpers';

import messages from './messages';

const typesToConstraintMap = {
  [ADDITIONAL_INFORMATION_TYPES.DATE]: () => Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.DECIMAL]: () =>
    Yup.number().transform((value) =>
      Number.isNaN(value) ? undefined : value,
    ),
  [ADDITIONAL_INFORMATION_TYPES.IRIS_CONDITION_CODE]: () =>
    Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.IRIS_DEFECT_CODE]: () =>
    Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.IRIS_REPAIR_CODE]: () =>
    Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.IRIS_SECTION_CODE]: () =>
    Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.IRIS_EXTENDED_CONDITION_CODE]: () =>
    Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.IRIS_SYMPTOM_CODE]: () =>
    Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.ENUM]: () => Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.IMEI]: () =>
    Yup.string()
      .nullable()
      .test('validIMEI', messages.mustBeValidImei, (value) => {
        return isValidIMEI(value);
      }),
  [ADDITIONAL_INFORMATION_TYPES.STRING]: () => Yup.string(),
  [ADDITIONAL_INFORMATION_TYPES.INTEGER]: () =>
    Yup.number().transform((value) =>
      Number.isNaN(value) ? undefined : value,
    ),
  [ADDITIONAL_INFORMATION_TYPES.FILE]: () =>
    Yup.object()
      .nullable()
      .test('validFile', (file, { createError, path }) => {
        if (file?.errors) {
          return createError({
            message: getUniqErrorMessages(file?.errors)[0],
            path,
          });
        }

        return true;
      }),
};

function getInputType(type) {
  const constraint = typesToConstraintMap[type];

  if (!constraint) {
    return Yup.string();
  }

  return constraint();
}

function applyIsRequiredToBase(base, isRequired, meta, validatingRegex = undefined) {
  const { type } = meta;
  const textType = [
    ADDITIONAL_INFORMATION_TYPES.STRING, 
    ADDITIONAL_INFORMATION_TYPES.SERIAL_NUMBER, 
    ADDITIONAL_INFORMATION_TYPES.EXCHANGE_ORDER_REFERENCE, 
    ADDITIONAL_INFORMATION_TYPES.RMA_AGREEMENT_NUMBER
  ]

  if(textType.includes(type) && !!validatingRegex) {
    return base.test(
      'regex-invalid',
      messages.mustBeValidRegex,
      (value) => {
        const regex = new RegExp(validatingRegex)

        return regex.test(value);
      },
    );
  }

  if (type === ADDITIONAL_INFORMATION_TYPES.DATE && isRequired) {
    return base.test(
      'required-moment',
      messages.thisFieldIsRequired,
      (value) => {
        if (!value) {
          return false;
        }

        return true;
      },
    );
  }

  if (type === ADDITIONAL_INFORMATION_TYPES.FILE && isRequired) {
    return base
      .test(
        'max-filesize',
        safeFormattedIntlString(globalMessages.form.maximumFileSize),
        (file) => {
          if (!file || !file?.value) {
            return true;
          }

          return (file?.value?.size || 0) <= 25_000_000;
        },
      )
      .test(
        'unsupported-filetype',
        safeFormattedIntlString(globalMessages.form.unsupportedFileType),
        (file) => {
          if (!file || !file?.value) {
            return true;
          }
          const adaptedMimeTypes = ALLOWED_MIME_TYPES.map((type) =>
            type.toString(),
          );

          if (!file?.value?.type) {
            return true;
          }

          return adaptedMimeTypes.includes(
            (file?.value?.type || '').toLowerCase(),
          );
        },
      )
      .test('required-file', messages.thisFieldIsRequired, (file) => {
        return Boolean(file);
      });
  }

  return isRequired ? base.required(messages.thisFieldIsRequired) : base;
}

export function getConstraint(neededInformationItem) {
  const { type, isRequired, validatingRegex } = neededInformationItem;
  const base = getInputType(type);

  return applyIsRequiredToBase(base, isRequired, { type }, validatingRegex);
}

const schema = ({ neededInformationList = {} }) => {
  const values = Object.values(neededInformationList);

  if(!values.length) {
    return undefined
  }

  return flatten(values)
    .filter(
      (neededInformationItem) =>
        ![
          ADDITIONAL_INFORMATION_TYPES.NOTIFICATION_ALERT,
          ADDITIONAL_INFORMATION_TYPES.NOTIFICATION_INFO,
        ].includes(neededInformationItem.type),
    )
    .reduce((acc, neededInformationItem) => {
      const { internalId } = neededInformationItem;

      return acc.shape({
        [internalId]: getConstraint(neededInformationItem),
      });
    }, Yup.object());
};

export default schema;
