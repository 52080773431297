import PropTypes from 'prop-types';

export default {
  addressId: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
};
