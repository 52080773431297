import React from 'react';
import { Grid } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { CLAIM_TYPES } from '@savgroup-front-common/constants/src/myaccount';
import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import { ChevronLeftIcon } from '@savgroup-front-common/core/src/protons/icons';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';
import { AuthConfiguration } from 'myaccount/configuration';

import ClaimContainer from '../../components/ClaimsContainer';

import ChooseProducts from './ChooseProducts';
import { BackButton } from './ChooseProductsPage.styles';
import useChooseProductsPage from './hooks/useChooseProductsPage';
import messages from './messages';

const ChooseProductsPage = () => {
  const { handleGoBack, isLoading } = useChooseProductsPage();
  const isGridFluidActive = useMedia(media.maxWidth.md);

  return (
    <Grid fluid={isGridFluidActive}>
      <BackButton
        type={BUTTON_TYPES.BUTTON}
        onClick={handleGoBack}
        naked
        icon={<ChevronLeftIcon />}
        componentThemeName={SUPPORTED_COMPONENTS.BACK_BUTTON}
      >
        <SafeFormattedMessageWithoutSpread message={messages.back} />
      </BackButton>

      <NeedHelp clientId={AuthConfiguration.clientId} />

      {isLoading && <BaseLoader messageList={[messages.loadingMessage]} />}
      {!isLoading && (
        <ClaimContainer claimType={CLAIM_TYPES.PRODUCTS} isRunning={false}>
          <ChooseProducts />
        </ClaimContainer>
      )}
    </Grid>
  );
};

ChooseProductsPage.displayName = 'ChooseProductsPage';

export default ChooseProductsPage;
