import difference from 'lodash/difference';
import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import matches from 'lodash/matches';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import { push } from 'redux-first-history';
import { all, put, race, select, take, takeEvery } from 'redux-saga/effects';

import { ActionCreators as ClaimActionCreators } from '@savgroup-front-common/core/src/domains/claims';
import {
  deleteClaimById,
  deleteClaimGroupById,
  loadClaimGroupById,
  loadClaimsByGroupId,
} from '@savgroup-front-common/core/src/domains/claims/actionCreators';
import * as ClaimActionTypes from '@savgroup-front-common/core/src/domains/claims/actionTypes';
import {
  DELETE_CLAIM_BY_ID,
  DELETE_CLAIM_GROUP_BY_ID,
  LOAD_CLAIM_GROUP_BY_ID,
  LOAD_CLAIMS_BY_GROUP_ID,
} from '@savgroup-front-common/core/src/domains/claims/actionTypes';
import {
  claimGroupsById,
  getClaimById,
} from '@savgroup-front-common/core/src/domains/claims/selectors';
import { selectUserId } from '@savgroup-front-common/core/src/domains/selectors';

import { ROUTES } from '../../view/app/routes/Routes';
import {
  ActionCreators as ClaimCreationActionCreators,
  ActionTypes as ClaimCreationActionTypes,
} from '../Claim/ClaimCreation';
import { selectors as historySelecors } from '../history';
import {
  selectLoadCloseFilesWasLoaded,
  selectLoadOpenFilesWasLoaded,
} from '../MyReturn/selectors';
import {
  loadedOrderViewAction,
  loadingOrderViewAction,
} from '../Navigation/actionCreators';
import { LOAD_ORDERS_DATA } from '../Orders/actionTypes';
import { ordersSelectorItems } from '../Orders/selectors';

import {
  closedFilesFromOrderId,
  currentOrderIdSelector,
  openedFilesFromOrderId,
  ordersSelector,
} from './groupedProduct';

function* startClaimWorker() {
  const orderIds = map(yield select(ordersSelector), (order) => order.orderId);

  yield put(ClaimActionCreators.loadIssuesFromAllOrderStarted());
  for (const orderId of orderIds) {
    // One at a time or we may spam backend
    yield all([
      put(ClaimActionCreators.loadIssuesByOrder({ orderId })),
      take(
        matches({
          type: ClaimActionTypes.LOAD_ISSUES_OPTIONS_BY_ORDER.END,
          meta: {
            orderId,
          },
        }),
      ),
    ]);
  }

  yield put(ClaimActionCreators.loadIssuesFromAllOrderSucceeded());
}

function* redirectSingleOrder() {
  const orders = yield select(ordersSelectorItems);
  const history = yield select(historySelecors.historySelector);
  const isInitialRoute =
    history.filter(
      (pathname) =>
        ![ROUTES.CALLBACK, ROUTES.HOME, ROUTES.INDEX].includes(pathname),
    ).length === 1;
  const ownerId = yield select(selectUserId);
  const isGetOpenFilesLoaded = yield select((state) =>
    selectLoadOpenFilesWasLoaded(state, { ownerId }),
  );
  const isGetClosedFilesLoaded = yield select((state) =>
    selectLoadCloseFilesWasLoaded(state, { ownerId }),
  );

  if (
    orders.length !== 1 ||
    !isInitialRoute ||
    !isGetOpenFilesLoaded ||
    !isGetClosedFilesLoaded
  ) {
    return;
  }

  const order = head(orders);
  const currentOrderId = get(order, 'orderId', null);
  const products = get(order, 'products', []);

  const openedFiles = yield select((state) =>
    openedFilesFromOrderId(state, currentOrderId),
  );
  const closedFiles = yield select((state) =>
    closedFilesFromOrderId(state, currentOrderId),
  );
  const files = [...openedFiles, ...closedFiles];

  const ownerProductIdNotEligible = uniq([
    ...openedFiles.reduce(
      (acc, file) => [
        ...acc,
        ...get(file, 'fileProducts', []).map((product) =>
          get(product, 'ownerProductId', null),
        ),
      ],
      [],
    ),
    ...closedFiles.reduce(
      (acc, file) => [
        ...acc,
        ...get(file, 'fileProducts', [])
          .filter((product) => get(product, 'notOpenForClaims', false))
          .map((product) => get(product, 'ownerProductId', null)),
      ],
      [],
    ),
  ]);

  const allProductsHaveClaim = products.every((product) =>
    get(product, 'claimGroupId', false),
  );
  const allProductsHaveNoClaim = products.every(
    (product) => !get(product, 'claimGroupId', false),
  );
  const allProductsHaveOpenFile = products.every((product) =>
    ownerProductIdNotEligible.includes(get(product, 'ownerProductId', null)),
  );

  if (allProductsHaveNoClaim && openedFiles.length === 0) {
    yield put(push(ROUTES.ISSUES));
  }

  if (
    (allProductsHaveNoClaim && files.length === 1) ||
    (allProductsHaveOpenFile && openedFiles.length === 1)
  ) {
    yield put(
      push(
        ROUTES.FILE_FOLLOWUPS.replace(
          ':fileId',
          get(head(files), 'fileId', ''),
        ),
      ),
    );
  }

  // redirect into claim
  if (allProductsHaveClaim && files.length === 0) {
    const claimGroupIds = uniq(
      products.map((product) => get(product, 'claimGroupId')),
    );

    if (size(claimGroupIds) === 1) {
      const claimGroupId = head(claimGroupIds);

      yield put(loadClaimGroupById(claimGroupId));
      yield race([
        take(LOAD_CLAIM_GROUP_BY_ID.SUCCEEDED),
        take(LOAD_CLAIM_GROUP_BY_ID.ERRORED),
      ]);

      const claimIds = yield select((state) =>
        claimGroupsById(state, { claimGroupId }),
      );

      const claimsIdsWithoutIssue = [];

      yield all([
        put(loadClaimsByGroupId(claimGroupId)),
        race([
          take(LOAD_CLAIMS_BY_GROUP_ID.SUCCEEDED),
          take(LOAD_CLAIMS_BY_GROUP_ID.ERRORED),
        ]),
      ]);

      // check if every claims has issues
      for (let index = 0; index < claimIds.length; index += 1) {
        const claimId = claimIds[index];

        const claim = yield select((state) =>
          getClaimById(state, { claimId }),
        ) || {};
        const { issueId = null } = claim;

        if (!issueId) {
          claimsIdsWithoutIssue.push(claimId);
        }
      }

      // if claims without issue are found, delete everything to restart from fresh claim
      if (claimsIdsWithoutIssue.length > 0) {
        for (let index = 0; index < claimsIdsWithoutIssue.length; index += 1) {
          const claimId = claimsIdsWithoutIssue[index];

          yield all([
            put(deleteClaimById(claimId)),
            race([
              take(DELETE_CLAIM_BY_ID.SUCCEEDED),
              take(DELETE_CLAIM_BY_ID.ERRORED),
            ]),
          ]);
        }

        if (difference(claimIds, claimsIdsWithoutIssue).length === 0) {
          yield all([
            put(deleteClaimGroupById(claimGroupId)),
            race([
              take(DELETE_CLAIM_GROUP_BY_ID.SUCCEEDED),
              take(DELETE_CLAIM_GROUP_BY_ID.ERRORED),
            ]),
          ]);
          yield put(push(ROUTES.ISSUES));
        }
      } else {
        yield put(
          push(
            ROUTES.CLAIM_GROUP_DETAILS.replace(':claimGroupId', claimGroupId),
          ),
        );
      }
    }
  }
}

export default function* loadOrderView() {
  yield put(loadingOrderViewAction());
  yield put(ClaimCreationActionCreators.finishClaimCreation());

  const currentOrderId = yield select(currentOrderIdSelector);

  yield takeEvery([LOAD_ORDERS_DATA.SUCCEEDED], redirectSingleOrder);
  yield takeEvery(ClaimCreationActionTypes.START_CLAIM, startClaimWorker);

  if (currentOrderId) {
    yield put(ClaimCreationActionCreators.startClaim());
  }

  yield put(loadedOrderViewAction());
}
