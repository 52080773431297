import classnames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedTime } from 'react-intl';

import { SafeFormattedMessage } from '../../../../formatters';
import { $CalendarTimeBlock } from '../TimeSlotDisplay.styles';

import message from './messages';
import { SingleSlotSelector } from './TimeSlotSelector.styles';

const TimeSlotSelector = ({
  interval,
  isActive,
  selectedInterval,
  onIntervalSelect,
  timeZone,
}) => {
  const allDaySchedule = get(interval, 'allDaySchedule', false);

  return (
    <$CalendarTimeBlock
      $fullHeight={allDaySchedule}
      key={`${get(interval, 'startLocalTime')}-${get(
        interval,
        'endLocalTime',
      )}`}
      className={classnames({
        clickable: isActive,
        active:
          selectedInterval && interval
            ? selectedInterval.internalId === interval.internalId
            : false,
      })}
      onClick={() => (isActive ? onIntervalSelect(interval) : undefined)}
      data-testid="schedule"
    >
      {allDaySchedule && (
        <SingleSlotSelector>
          <SafeFormattedMessage {...message.allDaySlotLabel} />
        </SingleSlotSelector>
      )}
      {!allDaySchedule && (
        <>
          <FormattedTime
            value={get(interval, 'startLocalTime')}
            hour="2-digit"
            minute="2-digit"
            timeZone={timeZone}
          />
          {' - '}
          <FormattedTime
            value={get(interval, 'endLocalTime')}
            hour="2-digit"
            minute="2-digit"
            timeZone={timeZone}
          />
        </>
      )}
    </$CalendarTimeBlock>
  );
};

TimeSlotSelector.propTypes = {
  interval: PropTypes.shape({}).isRequired,
  selectedInterval: PropTypes.shape({}),
  isActive: PropTypes.bool,
  onIntervalSelect: PropTypes.func,
};
TimeSlotSelector.defaultProps = {
  isActive: true,
  selectedInterval: null,
  onIntervalSelect: () => {},
};

TimeSlotSelector.displayName = 'TimeSlotSelector';

export default TimeSlotSelector;
