import React, { FunctionComponent, ReactElement } from 'react';
import { MessageDescriptor } from 'react-intl';

import { FEATURE_FLAGS } from '@savgroup-front-common/constants';
import { MessageType } from '@savgroup-front-common/types';

import { useIsFeatureEnabled } from '../../../../components/FeatureManager/hooks';
import { SafeFormattedMessage } from '../../../../formatters';
import Depiction from '../Depiction';
import { InputStatus } from '../helpers/getFinalFieldState.types';

import { $WrapElement, $WrapFix, $Wrapper } from './Wrapper.styles';

interface WrapperProps {
  status?: InputStatus;
  prefix?: string | MessageType | MessageDescriptor;
  suffix?: string | MessageType | MessageDescriptor;
  isHovered?: boolean;
  name?: string;
  isFocused?: boolean;
  isRounded?: boolean;
  dataTestId?: string;
  suffixButton?: ReactElement;
  isFullWidth?: boolean;
  isSmall?: boolean;
}

const Wrapper: FunctionComponent<React.PropsWithChildren<WrapperProps>> = ({
  status = null,
  prefix = '',
  suffix = '',
  isHovered = false,
  isFocused = false,
  isRounded = true,
  dataTestId,
  suffixButton,
  children,
  name,
  isFullWidth = true,
  isSmall = false,
}) => {
  const isNewBoEnabled = useIsFeatureEnabled(FEATURE_FLAGS.BO_NEW_UI);

  return (
    <$Wrapper>
      <$WrapElement
        $status={status}
        $isHovered={isHovered}
        $isFocused={isFocused}
        $isRounded={isRounded}
        data-testid={dataTestId}
        $isFullWidth={isFullWidth}
        $isNewBoEnabled={isNewBoEnabled}
      >
        {prefix && (
          <$WrapFix
            $status={status}
            $isHovered={isHovered}
            $isFocused={isFocused}
          >
            {SafeFormattedMessage(prefix)}
          </$WrapFix>
        )}
        {children}
        {/* do no test status in depiction, it will break the animation, thx */}
        {suffix && (
          <$WrapFix
            $status={status}
            $isHovered={isHovered}
            $isFocused={isFocused}
          >
            {SafeFormattedMessage(suffix)}
          </$WrapFix>
        )}
        <Depiction status={status} name={name} isSmall={isSmall} />
      </$WrapElement>
      {suffixButton && <div>{suffixButton}</div>}
    </$Wrapper>
  );
};

Wrapper.displayName = 'Wrapper';

export default Wrapper;
