export enum INVOICE_ITEM_KIND {
  QUOTE = 'Quote',
  SOLUTION = 'Solution',
  INSURANCE = 'Insurance',
  TRANSPORT_DEPOSIT = 'TransportDeposit',
  TRANSPORT_DELIVERY = 'TransportDelivery',
  TRANSPORT_HOME = 'TransportHome',
  HOME_PICKUP = 'HomePickup',
  TRANSPORT = 'Transport',
  TRANSPORT_DEPOSIT_INSURANCE = 'TransportDepositInsurance',
  TRANSPORT_DELIVERY_INSURANCE = 'TransportDeliveryInsurance',
  QUOTATION_SERVICE = 'QuotationService',
  QUOTATION_UNKNOWN_SERVICE = 'QuotationUnknownService',
  QUOTATION_SPARE_PART = 'QuotationSparePart',
  QUOTATION_UNKNOWN_SPARE_PART = 'QuotationUnknownSparePart',
  QUOTATION_DISCOUNT = 'QuotationDiscount',
  QUOTATION_ADMINISTRATIVE_FEES = 'QuotationAdministrativeFees',
  QUOTATION_BONUS = 'QuotationBonus',
}
