import { defineMessages } from 'react-intl';

export default defineMessages({
  descriptionLabel: {
    id: 'view.modal.quote.form.description.label',
    defaultMessage: 'Description',
  },
  quoteShouldBePositive: {
    id: 'view.modal.quote.error.netPriceShouldBePositive',
    defaultMessage: 'Net price should be positive',
  },
  quoteShouldHaveSpartPartOrService: {
    id: 'view.modal.quote.error.quoteShouldHaveSpartPartOrService',
    defaultMessage:
      'the quote must include at least one spare part or one service',
  },
  submitSuccess: {
    id: 'view.modal.quote.toast.submitSuccess',
    defaultMessage: 'the quote has been submitted',
  },
  saveSuccess: {
    id: 'view.modal.quote.toast.saveSuccess',
    defaultMessage: 'the quote has been saved',
  },

  lineTypeLabel: {
    id: 'view.modal.quote.form.type.label',
    defaultMessage: 'Type',
  },
  service: {
    id: 'view.modal.quote.form.lineType.service',
    defaultMessage: 'Service',
  },
  discount: {
    id: 'view.modal.quote.form.lineType.discount',
    defaultMessage: 'Discount',
  },
  spareSpart: {
    id: 'view.modal.quote.form.lineType.spareSpart',
    defaultMessage: 'Spare part',
  },
  administrativeFees: {
    id: 'view.modal.quote.form.lineType.administrativeFees',
    defaultMessage: 'Administrative fees',
  },
  stockItem: {
    id: 'view.modal.quote.form.lineType.stockItem',
    defaultMessage: 'Stock item',
  },
  serviceQuotationLines: {
    id: 'view.issueQuotation.serviceQuotationLines.title',
    defaultMessage: 'Services',
  },
  sparepartQuotationLines: {
    id: 'view.issuaQuotation.sparepartQuotationLines.title',
    defaultMessage: 'Spare parts',
  },
  sparePartConditionNoMoreAvailable: {
    id: 'view.issueAQuotation.sparepartQuotationLines.sparePartConditionNoMoreAvailable.errorMessage',
    defaultMessage: 'Selected spare part is not available',
  },
});
