import { useCallback } from 'react';

import * as SHARED_CONSTANTS from '@savgroup-front-common/constants/src/shared';
import { CommonClaimService } from '@savgroup-front-common/core/src/api';

const useHandleSetAttachmentOnClaimGroup = ({
  claimGroupId,
  claimId,
  claimIds,
}) => {
  return useCallback(
    async ({ file, notify }) => {
      return CommonClaimService.uploadSharedFileCommand({
        claimIds,
        file,
        documentType: SHARED_CONSTANTS.DOCUMENT_TYPES.IMAGE,
        claimGroupId,
        claimId,
        notify,
      });
    },
    [claimGroupId, claimId, claimIds],
  );
};

export default useHandleSetAttachmentOnClaimGroup;
