import React, { FC } from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { ChevronRightIcon } from '@savgroup-front-common/core/src/protons/icons';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import { ClaimGroupConfirmation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';

import messages from './messages';
import { useResumeCardButton } from './ResumeCardButton.hooks';
import {
  $CardContentThumbnail,
  $ResumeDescriptionProduct,
  $ResumeInfoProductSerialNumber,
  $ResumeInfoProductTag,
  $ResumeInfoProductTitle,
  $ResumeModalOpenButton,
  $ResumeModalOpenButtonContainer,
  $ResumeProduct,
} from './ResumeCardButton.styles';

interface ResumeCardButtonProps {
  claimGroupConfirmation?: ClaimGroupConfirmation;
  handleOpenResumeModal?: () => void;
}

const ResumeCardButton: FC<ResumeCardButtonProps> = ({
  claimGroupConfirmation,
  handleOpenResumeModal,
}) => {
  const { modelDetail, serialNumber } = useResumeCardButton({
    claimGroupConfirmation,
  });

  return (
    <$ResumeDescriptionProduct>
      <$ResumeProduct>
        <div>
          <$CardContentThumbnail>
            {modelDetail?.pictureUrl && modelDetail.modelTypeId ? (
              <img src={modelDetail?.pictureUrl} alt="pictureUrl" />
            ) : (
              <MyAccountIcon icon={ICONS_TYPE.BOX_ICON} />
            )}
          </$CardContentThumbnail>
        </div>
        <div>
          <$ResumeInfoProductTitle>{modelDetail?.name}</$ResumeInfoProductTitle>
          {serialNumber && (
            <$ResumeInfoProductSerialNumber>
              <SafeFormattedMessageWithoutSpread
                message={messages.serialNumber}
                values={{
                  serialNumber,
                }}
              />
            </$ResumeInfoProductSerialNumber>
          )}
          <$ResumeInfoProductTag />
        </div>
      </$ResumeProduct>
      <$ResumeModalOpenButtonContainer>
        <$ResumeModalOpenButton onClick={handleOpenResumeModal}>
          <SafeFormattedMessageWithoutSpread message={messages.resumeButton} />
          <ChevronRightIcon size="15px" />
        </$ResumeModalOpenButton>
      </$ResumeModalOpenButtonContainer>
    </$ResumeDescriptionProduct>
  );
};

ResumeCardButton.displayName = 'ResumeCardButton';

export default ResumeCardButton;
