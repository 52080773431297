import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const DescIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} viewBox="0 0 500 400" color={color}>
    <path d="M187.298 52.686L107.314 -27.316C101.066 -33.563 90.931 -33.561 84.687 -27.316L4.705 52.686C-5.365 62.756 1.807 80 16.019 80H64V400C64 408.837 71.163 416 80 416H112C120.837 416 128 408.837 128 400V80H175.984C190.225 80 197.347 62.736 187.298 52.686zM240 352H496C504.837 352 512 359.163 512 368V400C512 408.837 504.837 416 496 416H240C231.163 416 224 408.837 224 400V368C224 359.163 231.163 352 240 352zM224 240V272C224 280.837 231.163 288 240 288H432C440.837 288 448 280.837 448 272V240C448 231.163 440.837 224 432 224H240C231.163 224 224 231.163 224 240zM224 -16V16C224 24.837 231.163 32 240 32H304C312.837 32 320 24.837 320 16V-16C320 -24.837 312.837 -32 304 -32H240C231.163 -32 224 -24.837 224 -16zM224 112V144C224 152.837 231.163 160 240 160H368C376.837 160 384 152.837 384 144V112C384 103.163 376.837 96 368 96H240C231.163 96 224 103.163 224 112z" />
  </Icon>
);

DescIcon.displayName = 'DescIcon';

export default DescIcon;
