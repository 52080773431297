import head from 'lodash/head';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'react-styled-flexboxgrid';

import { Loader } from '@savgroup-front-common/core/src/atoms/loader';
import { SolutionRadio } from '@savgroup-front-common/core/src/components/SolutionRadio';
import { RadioButtonGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';

import messages from '../ReasonSelectionForm/messages';
import { REASON_SELECTION_FORM_FIELDS } from '../ReasonSelectionForm/ReasonSelectionForm.constants';
import { $LoaderWrapper } from '../ReasonSelectionForm/ReasonSelectionForm.styles';

const SolutionSelection = ({ solutions, solutionsLoaded, activeProduct }) => {
  const {
    watch,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const reason = watch(`form.${activeProduct.productId}.reason`)?.data;

  useEffect(() => {
    if (reason && solutions.length === 1) {
      setValue(REASON_SELECTION_FORM_FIELDS.SOLUTION_ID, head(solutions)?.id);
    }
  }, [reason, setValue, solutions]);

  if (!reason || (solutions.length === 0 && solutionsLoaded)) {
    return <></>;
  }

  return (
    <Card dataTestId="solutionSelection">
      <RadioButtonGroup
        name={REASON_SELECTION_FORM_FIELDS.SOLUTION_ID}
        errors={errors}
        label={messages.solution}
      >
        <Row>
          {!solutionsLoaded && (
            <$LoaderWrapper>
              <Loader />
            </$LoaderWrapper>
          )}

          {solutionsLoaded &&
            solutions.map((solution) => (
              <Col xs={12} lg={6} key={solution.id}>
                <SolutionRadio
                  name={REASON_SELECTION_FORM_FIELDS.SOLUTION_ID}
                  price={solution.price}
                  currency={solution.currency}
                  icon={solution.key}
                  label={solution.title}
                  dataTestId={solution.key}
                  value={solution.id}
                  register={register}
                  didactic={solution.didactic}
                />
              </Col>
            ))}
        </Row>
      </RadioButtonGroup>
    </Card>
  );
};

SolutionSelection.propTypes = {
  solutions: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      id: PropTypes.string,
      key: PropTypes.string,
      price: PropTypes.number,
      solutionGroupId: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  solutionsLoaded: PropTypes.bool,
  activeProduct: PropTypes.shape({ productId: PropTypes.string }).isRequired,
};

SolutionSelection.defaultProps = {
  solutions: [],
  solutionsLoaded: false,
};

SolutionSelection.displayName = 'SolutionSelection';

export default SolutionSelection;
