import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import { AddressConfirmation } from '@savgroup-front-common/core/src/components/addressConfirmation';

import { InfoTitle } from './Confirmation.styles';
import messages from './messages';

const DepositAddress = (props) => {
  const {
    depositAddress,
    assets,
    getClaimHandlingLink,
    carrierDepositPriceWithTax,
    translatedCarrirerMethodNames,
  } = props;

  return (
    <>
      <Row middle="xs">
        <Col>
          <InfoTitle heading>
            <FormattedMessage {...messages.depositHeader} />
          </InfoTitle>
        </Col>
        <Col>
          <ButtonLink href={getClaimHandlingLink('deposit')} underline primary>
            <FormattedMessage {...messages.edit} />
          </ButtonLink>
        </Col>
      </Row>
      <AddressConfirmation
        key={`delivery-${depositAddress.adressId}`}
        {...depositAddress}
        imagePath={assets.depositImagePath}
        price={carrierDepositPriceWithTax}
        transportMethod={translatedCarrirerMethodNames.get('deposit')}
        showPrice
      />
    </>
  );
};

DepositAddress.propTypes = {
  depositAddress: PropTypes.shape({}),
  assets: PropTypes.shape({}),
  getClaimHandlingLink: PropTypes.func.isRequired,
  carrierDepositPriceWithTax: PropTypes.number,
  translatedCarrirerMethodNames: PropTypes.shape({}).isRequired,
};
DepositAddress.defaultProps = {
  depositAddress: null,
  carrierDepositPriceWithTax: 0,
  assets: {},
};

export default DepositAddress;
