import { lighten } from 'polished';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';

import { Button } from '../../../atoms/button';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../protons/icons';
import { decelerateTimingFunctionTransition } from '../../../animations/timingFunction';

export const $AppointmentCalendarScreen = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
`;

export const $UnderlinedRow = styled(Row)`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.default}`};
  padding: 10px 0;
  margin-bottom: 1rem;
  position: relative;
`;

export const $StyledCol = styled(Col)`
  padding-right: 0.2rem;
  padding-left: 0.25rem;
`;

export const $LeftArrowButton = styled(ChevronLeftIcon)`
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  cursor: pointer;
  @media ${media.minWidth.md} {
    left: 10px;
  }
`;

export const $RightArrowButton = styled(ChevronRightIcon)`
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  cursor: pointer;
  @media ${media.minWidth.md} {
    right: 10px;
  }
`;

export const $CalendarDayBlock = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const $CalendarDayName = styled.span`
  color: ${({ theme }) => theme.colors.headingTextColor};
`;

export const $CalendarDayDate = styled('p')`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
`;

interface $CalendarTimeBlockProps {
  $fullHeight?: boolean;
}

export const $CalendarTimeBlock = styled.div<$CalendarTimeBlockProps>`
  border-radius: 0.25rem;

  transition-duration: 300ms;
  ${decelerateTimingFunctionTransition};
  background-color: ${({ theme }) => theme.colors.default};

  color: ${({ theme }) => theme.colors.headingTextColor};
  padding: 5px 10px;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 15px;
  text-align: center;

  height: ${({ $fullHeight }) => ($fullHeight ? '100%' : null)};

  margin-bottom: 0.5rem;

  &.clickable {
    cursor: pointer;
    &.active,
    &:hover.active {
      background-color: ${({ theme }) => lighten(0, theme.colors.primary)};
      color: ${({ theme }) => theme.colors.white};
    }

    &:hover {
      background-color: ${({ theme }) => lighten(0.05, theme.colors.default)};
    }
  }
`;

export const $TimeSlotSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 9rem;
`;

interface $AppointmentCalendarContainerArgs {
  $hasButtonPadding: boolean;
}

export const $AppointmentCalendarContainer = styled.div<$AppointmentCalendarContainerArgs>`
  display: flex;

  & > button {
    height: fit-content;
    padding: ${({ $hasButtonPadding }) => ($hasButtonPadding ? '4%' : null)};
  }
`;

export const $Button = styled(Button)`
  width: 30%;
`;
