import find from 'lodash/find';
import get from 'lodash/get';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import {
  HANDLING_GROUPS,
  HANDLING_MODES,
  media,
} from '@savgroup-front-common/constants';
import { NextStep } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import {
  currentClaimGroupHandlingModeSelector,
  selectGroupHandlingInfo,
} from 'myaccount/domains/Claim/claimGroupSelector';

import { useGoToPreviousStep } from './hooks';
import useGetGroupedCarriers from './hooks/useGetGroupedCarriers';
import { SubmitSelectCarrierTypeForm } from './SelectCarrierType.types';

interface UseSelectCarrierTypeReturnValue {
  isGridFluidActive: boolean;
  shouldShowTransportDeduction: boolean;
  hasHome: boolean;
  handlingInfo: any;
  groupedCarriers: any;
  handleSubmitSelectCarrierType: (productType: HANDLING_GROUPS) => void;
  handleGoToPreviousStep: () => void;
  handlingMode: HANDLING_MODES;
}

export const useSelectCarrierType = ({
  nextStep,
}: {
  nextStep: NextStep<SubmitSelectCarrierTypeForm>;
}): UseSelectCarrierTypeReturnValue => {
  const handlingInfo = useSelector(selectGroupHandlingInfo) as any;

  const { carriers, groupedCarriers } = useGetGroupedCarriers({
    handlingInfo,
  });

  const handlingMode = useSelector(
    currentClaimGroupHandlingModeSelector,
  ) as HANDLING_MODES;

  const handleSubmitSelectCarrierType = useCallback(
    (productType: HANDLING_GROUPS) => {
      nextStep({
        selectedCarrierType: productType,
        handlingMode,
        groupedCarriers,
      });
    },
    [nextStep, handlingMode, groupedCarriers],
  );

  const canBeTransportDeduction = Boolean(
    find(carriers, 'priceWithTax'),
  ) as boolean;
  const hasHome = handlingInfo?.hasHome;
  const canDeduceTransportFromRefund = get(
    handlingInfo,
    'canDeduceTransportFromRefund',
  );
  const shouldShowTransportDeduction = Boolean(
    handlingMode.toLowerCase() === HANDLING_MODES.DEPOSIT.toLowerCase() &&
      canBeTransportDeduction &&
      canDeduceTransportFromRefund,
  ) as boolean;
  const handleGoToPreviousStep = useGoToPreviousStep({ handlingMode });
  const isGridFluidActive = useMedia(media.maxWidth.md);

  return {
    isGridFluidActive,
    shouldShowTransportDeduction,
    hasHome,
    handlingInfo,
    groupedCarriers,
    handleSubmitSelectCarrierType,
    handleGoToPreviousStep,
    handlingMode,
  };
};
