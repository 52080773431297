import { createAction } from '@reduxjs/toolkit';

import * as ActionTypes from './actionTypes';

export const loadOwnerProductFiles = createAction<{
  ownerProductIds: string[];
}>(ActionTypes.LOAD_OWNER_PRODUCT_FILES.BASE);
export const saveExpects = createAction<any>(ActionTypes.SAVE_EXPECTS.BASE);
export const resetPaymentFormErrors = createAction(
  ActionTypes.RESET_PAYMENT_FORM_ERRORS,
);
export const loadFileHandling = createAction<{ fileId: string }>(
  ActionTypes.LOAD_FILE_HANDLING.BASE,
);
export const usingMyOwnCarrier = createAction(
  ActionTypes.USE_MY_OWN_CARRIER.BASE,
);
export const forceCloseFile = createAction(ActionTypes.FORCE_CLOSE_FILE.BASE);

export const setUserActionAndHydrateFileSummary = createAction(
  ActionTypes.SET_USER_ACTION_AND_HYDRATE_FILE_SUMMARY.BASE,
);
export const splitFile = createAction(ActionTypes.SPLIT_FILE.BASE);
export const explodeFile = createAction(ActionTypes.EXPLODE_FILE.BASE);
export const explodeFileAlongSolutions = createAction(
  ActionTypes.EXPLODE_FILE_ALONG_SOLUTIONS.BASE,
);
export const updateExplodeFileState = createAction(
  ActionTypes.UPDATE_EXPLODE_FILE_STATE,
);

export const clearSplitFileState = createAction<{ fileId: string }>(
  ActionTypes.CLEAR_SPLIT_FILE_STATE,
);

export const setCommercialSolution = createAction(
  ActionTypes.SET_COMMERCIAL_SOLUTION.BASE,
);

export const refreshFileHandler = createAction<string>(
  ActionTypes.REFRESH_FILE_HANDLER.BASE,
);

export const getFileActions = createAction<string>(
  ActionTypes.GET_FILE_ACTIONS.BASE,
);
export const setSyncUserAction = createAction(
  ActionTypes.SET_SYNC_USER_ACTION.BASE,
);
export const setSyncUserActionSuccess = createAction(
  ActionTypes.SET_SYNC_USER_ACTION.SUCCEEDED,
);

export const noop = createAction('NOOP');
