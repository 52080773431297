import { APIConfiguration } from '@savgroup-front-common/configuration';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';
import {
  AdditionalInformation,
  AdditionalInformationExtended,
  Reason,
} from 'myaccount/types';

interface ReasonAdapterArgs {
  reasonSelected?: Reason;
  claimIds: string[];
}

export const buildFileUploadEndpoints = ({
  claimIds,
  id,
}: {
  claimIds: string[];
  id: string;
}): Record<string, { uploadEndpoint: string; deleteEndpoint: string }> => {
  return claimIds.reduce((acc, claimId) => {
    return {
      ...acc,
      [claimId]: {
        uploadEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
        deleteEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
      },
    };
  }, {});
};

export const reasonAdapter = ({
  reasonSelected,
  claimIds,
}: ReasonAdapterArgs) => {
  const newNeededInformation: AdditionalInformationExtended[] | any[] =
    reasonSelected?.neededInformation.reduce(
      (acc: AdditionalInformation[], curr: AdditionalInformation) => {
        const { id, type } = curr;

        const isFileType = type === ADDITIONAL_INFORMATION_TYPES.FILE;

        return [
          ...acc,
          {
            ...curr,
            internalId: id,
            fileUploadEndpoints: isFileType
              ? buildFileUploadEndpoints({
                  claimIds,
                  id,
                })
              : null,
          },
        ];
      },
      [],
    ) || [];

  return {
    ...reasonSelected,
    neededInformation: newNeededInformation,
  };
};
