import get from 'lodash/get';

const { hostname } = window.location;

const clientIdByHostname = {
  'dev-etam-newui.revers.io': 'dev-etam.revers.io',
  'dev-myaccount.revers.io': 'dev-revers.io',
  'qualif-myaccount.revers.io': 'dev-revers.io',
  'dev-newui.revers.io': 'dev-revers.io',
  'sav.dev.auchan.fr': 'dev-auchan.revers.io',
  'qualif-ocp.revers.io': 'dev-ocp.revers.io',
};

const authorityByHostname = {
  'dev-auchan.revers.io': 'https://dev-auchan-login.revers.io',
  'sav.dev.auchan.fr': 'https://sav-login.dev.auchan.fr',
  'qualif-ocp.revers.io': 'https://dev-ocp-login.revers.io',
};

const authorityByClientId = {
  'dev-revers.io': 'https://dev-myaccount-login.revers.io',
  'dev-auchan.revers.io': 'https://sav-login.dev.auchan.fr',
  'dev-ocp.revers.io': 'https://dev-ocp-login.revers.io',
  'reversio.dev.revers.io': 'https://reversio-login.dev.revers.io',
};

const defaultClientId = 'dev-revers.io';

function computeClientId() {
  if (process.env.REACT_APP_CLIENTID) {
    return process.env.REACT_APP_CLIENTID;
  }

  if (hostname !== 'localhost') {
    return clientIdByHostname[hostname] || window.location.hostname;
  }

  return defaultClientId;
}

const clientId = computeClientId();

function setAuthority(authority = null) {
  const { clientId } = this;
  const defaultAuthority = authorityByClientId[clientId];

  const defaultAuthorityFromHostname = get(authorityByHostname, hostname);

  if (hostname !== 'localhost' && defaultAuthorityFromHostname) {
    this.authority = defaultAuthorityFromHostname;

    return this;
  }

  this.authority = authority || defaultAuthority;

  return this;
}

const AuthConfiguration = {
  clientId,
  authority: authorityByClientId[clientId],
  scope: 'openid email api',
  setAuthority,
};

export default AuthConfiguration;
