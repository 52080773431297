import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { AddressConfirmation } from '@savgroup-front-common/core/src/components/addressConfirmation';

import { InfoTitle } from './Confirmation.styles';
import messages from './messages';

const OnSiteInterventionAddress = (props) => {
  const { onSiteInterventionAddress } = props;

  return (
    <>
      <Row middle="xs">
        <Col>
          <InfoTitle heading>
            <FormattedMessage {...messages.onSiteInterventionHeader} />
          </InfoTitle>
        </Col>
        <Col>
          <Button underline primary>
            <FormattedMessage {...messages.edit} />
          </Button>
        </Col>
      </Row>

      <AddressConfirmation
        key={`deposit-${onSiteInterventionAddress.adressId}`}
        {...onSiteInterventionAddress}
      />
    </>
  );
};

OnSiteInterventionAddress.propTypes = {
  onSiteInterventionAddress: PropTypes.shape({}),
};
OnSiteInterventionAddress.defaultProps = {
  onSiteInterventionAddress: null,
};

export default OnSiteInterventionAddress;
