import React, { FunctionComponent } from 'react';

import { $ActionRow } from './ActionRow.styles';

interface ActionRowProps {
  withoutMarginBottom?: boolean;
}

const ActionRow: FunctionComponent<React.PropsWithChildren<ActionRowProps>> = ({
  children,
  withoutMarginBottom = false,
}) => {
  return (
    <$ActionRow $withoutMarginBottom={withoutMarginBottom}>
      {children}
    </$ActionRow>
  );
};

ActionRow.displayName = 'ActionRow';

export default ActionRow;
