import styled from 'styled-components';

export const $Didactic = styled.div<{ $withBottomMargin?: boolean }>`
  display: block;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  font-style: italic;
  margin-bottom: ${({ $withBottomMargin = true }) =>
    $withBottomMargin ? '1rem !important' : null};
  white-space: pre-wrap;
`;
