import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import { AddressConfirmation } from '@savgroup-front-common/core/src/components/addressConfirmation';

import { InfoTitle } from './Confirmation.styles';
import messages from './messages';

const DeliveryAddres = (props) => {
  const {
    deliveryAddress,
    assets,
    getClaimHandlingLink,
    carrierDeliveryPriceWithTax,
    translatedCarrirerMethodNames,
  } = props;

  return (
    <>
      <Row middle="xs">
        <Col>
          <InfoTitle heading>
            <FormattedMessage {...messages.deliveryHeader} />
          </InfoTitle>
        </Col>
        <ButtonLink underline primary href={getClaimHandlingLink('delivery')}>
          <FormattedMessage {...messages.edit} />
        </ButtonLink>
      </Row>
      <AddressConfirmation
        key={`deposit-${deliveryAddress.adressId}`}
        {...deliveryAddress}
        imagePath={assets.deliveryImagePath}
        price={carrierDeliveryPriceWithTax}
        transportMethod={translatedCarrirerMethodNames.get('delivery')}
        showPrice
      />
    </>
  );
};

DeliveryAddres.propTypes = {
  deliveryAddress: PropTypes.shape({}),
  assets: PropTypes.shape({}),
  getClaimHandlingLink: PropTypes.func.isRequired,
  carrierDeliveryPriceWithTax: PropTypes.number,
  translatedCarrirerMethodNames: PropTypes.shape({}).isRequired,
};
DeliveryAddres.defaultProps = {
  deliveryAddress: null,
  carrierDeliveryPriceWithTax: 0,
  assets: {},
};

export default DeliveryAddres;
