import React from 'react';

interface QrCodeIconProps {
  color?: string;
  size?: string;
}

export const QrCodeIcon: React.FC<QrCodeIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <svg viewBox="0 0 24 24" width={size} height={size} fill={color}>
      <path d="M3 11h8V3H3zm2-6h4v4H5zM3 21h8v-8H3zm2-6h4v4H5zm8-12v8h8V3zm6 6h-4V5h4zm0 10h2v2h-2zm-6-6h2v2h-2zm2 2h2v2h-2zm-2 2h2v2h-2zm2 2h2v2h-2zm2-2h2v2h-2zm0-4h2v2h-2zm2 2h2v2h-2z" />
    </svg>
  );
};

QrCodeIcon.displayName = 'QrCodeIcon';
