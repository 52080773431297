import {
  QuotationState,
  RawQuotationState,
} from '@savgroup-front-common/types';

import { globalMessages } from '../helpers';

export const formatQuotationState = (
  state: QuotationState | RawQuotationState,
) => {
  if (!state) {return '';}

  switch (state.toLowerCase()) {
    case QuotationState.CANCELLED.toLowerCase():
      return globalMessages.quotationStateMessages.cancelled;
    case QuotationState.APPROVED.toLowerCase():
      return globalMessages.quotationStateMessages.approved;
    case QuotationState.REJECTED.toLowerCase():
      return globalMessages.quotationStateMessages.rejected;
    case QuotationState.DRAFT.toLowerCase():
      return globalMessages.quotationStateMessages.draft;
    case QuotationState.PAID.toLowerCase():
      return globalMessages.quotationStateMessages.paid;
    case QuotationState.PENDING_CUSTOMER_APPROVAL.toLowerCase():
      return globalMessages.quotationStateMessages.pendingCustomerApproval;
    default:
      throw new Error(`Unknown quotation state: ${state}`);
  }
};
