import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const BarCodeIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color="none" viewBox="0 0 32 32">
    <g id="CodeBar">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.60792C0 7.08812 0.421375 6.66675 0.941168 6.66675H4.70584C5.22563 6.66675 5.64701 7.08812 5.64701 7.60792C5.64701 8.12771 5.22563 8.54908 4.70584 8.54908H1.88234V11.8432C1.88234 12.363 1.46096 12.7843 0.941168 12.7843C0.421375 12.7843 0 12.363 0 11.8432V7.60792ZM3.7649 10.4314H5.64724V22.6666H3.7649V10.4314ZM7.52957 10.4314H9.41191V18.902H7.52957V10.4314ZM24.4706 10.4314H22.5883V18.902H24.4706V10.4314ZM11.2942 10.4314H13.1766V18.902H11.2942V10.4314ZM26.3529 10.4314V18.902H28.2353V10.4314H26.3529ZM7.52957 20.7843H9.41191V22.6666H7.52957V20.7843ZM24.4706 20.7843H22.5883V22.6666H24.4706V20.7843ZM11.2942 20.7843H13.1766V22.6666H11.2942V20.7843ZM26.3529 20.7843V22.6666H28.2353V20.7843H26.3529ZM18.8236 10.4314H20.7059V22.6666H18.8236V10.4314ZM16.9413 10.4314H15.0589V22.6666H16.9413V10.4314ZM0.941398 26.4313C0.421605 26.4313 0.000229836 26.0099 0.000229836 25.4901V21.2549C0.000229836 20.7351 0.421605 20.3137 0.941398 20.3137C1.46119 20.3137 1.88257 20.7351 1.88257 21.2549V24.5489H4.70607C5.22586 24.5489 5.64724 24.9703 5.64724 25.4901C5.64724 26.0099 5.22586 26.4313 4.70607 26.4313H0.941398ZM31.9999 7.60792C31.9999 7.08812 31.5786 6.66675 31.0588 6.66675H27.2941C26.7743 6.66675 26.3529 7.08812 26.3529 7.60792C26.3529 8.12771 26.7743 8.54908 27.2941 8.54908H30.1176V11.8432C30.1176 12.363 30.539 12.7843 31.0588 12.7843C31.5786 12.7843 31.9999 12.363 31.9999 11.8432V7.60792ZM31.0588 26.4313C31.5786 26.4313 31.9999 26.0099 31.9999 25.4901V21.2549C31.9999 20.7351 31.5786 20.3137 31.0588 20.3137C30.539 20.3137 30.1176 20.7351 30.1176 21.2549V24.5489H27.2941C26.7743 24.5489 26.3529 24.9703 26.3529 25.4901C26.3529 26.0099 26.7743 26.4313 27.2941 26.4313H31.0588Z"
        fill={color}
      />
    </g>
  </Icon>
);

BarCodeIcon.displayName = 'BarCodeIcon';

export default BarCodeIcon;
