import React, { FunctionComponent } from 'react';
import styled, { useTheme } from 'styled-components';

import { rem } from '../../helpers';

interface $SvgProps {
  $width: string;
  $height: string;
  $color: string;
}

const $Svg = styled.svg<$SvgProps>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  transition: fill 100ms ease-out;

  & {
    fill: ${({ $color }) => $color};
  }
`;

interface IconProps {
  width?: string;
  height?: string;
  size?: string;
  viewBox?: string;
  color?: string;
  fill?: string;
}
const Icon: FunctionComponent<React.PropsWithChildren<IconProps>> = ({
  children,
  width = null,
  height = null,
  size = rem(12),
  viewBox = '0 0 24 24',
  color = null,
  fill,
}) => {
  const { colors = { primary: 'black' } } = useTheme() || {};
  const { primary = 'black' } = colors;
  const colorToApply = color || primary;

  return (
    <$Svg
      viewBox={viewBox}
      $width={width || size}
      $height={height || size}
      $color={colorToApply}
      fill={fill}
    >
      {children}
    </$Svg>
  );
};

Icon.displayName = 'Icon';

export default Icon;
