import PropTypes from 'prop-types';
import React from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';

import {
  ColDescription,
  ColSubmitButton,
  ReturnBottomRow,
} from '../ChooseProducts.styles';

import messages from './messages';

function ChooseProductsFooter({
  productCount,
  isSubmitting,
  handleSubmit,
  selectedProductCount,
}) {
  return (
    <ReturnBottomRow end="xs" middle="xs">
      {productCount > 1 && (
        <ColDescription sm={9} xs={12}>
          <SafeFormattedMessage {...messages.description} />
        </ColDescription>
      )}
      {productCount > 0 && (
        <ColSubmitButton sm={3} xs={12}>
          <Button
            isLoading={isSubmitting}
            type={BUTTON_TYPES.BUTTON}
            onClick={handleSubmit}
            primary
            fluid
            data-returnselectedproducts-button
          >
            <SafeFormattedMessage
              {...messages.returnTheProducts}
              values={{
                selectedProducts: selectedProductCount,
              }}
            />
          </Button>
        </ColSubmitButton>
      )}
    </ReturnBottomRow>
  );
}

ChooseProductsFooter.propTypes = {
  productCount: PropTypes.number.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedProductCount: PropTypes.number.isRequired,
};

export default React.memo(ChooseProductsFooter);
