import get from 'lodash/get';
import { createSelector } from 'reselect';

import { ErrorFromBack } from '../../helpers';

import { CommonAppState } from '../CommonAppState';

import {
  Action,
  ActionType,
  DATA_STATE,
  SAGA_REQUEST_METADATA_KEY,
  SuperAction,
} from './constants';
import { defaultIndexer } from './reducers';

const selectSagaRequestMetadata = (
  state: CommonAppState,
): Record<string, unknown> => {
  return state[SAGA_REQUEST_METADATA_KEY];
};

const getAction = (state: CommonAppState, action: Action | ActionType) =>
  action;

const getSuperActions = (state: CommonAppState, actions: SuperAction[]) =>
  actions;

const getIndexer = (state: CommonAppState, action: Action, indexer: string) =>
  indexer;

const getActionBase = (action: any) => get(action, 'BASE', action);

const memoizedEmptyErrors: ErrorFromBack[] = [];
const getErrors = (
  state: Record<string, unknown>,
  action: Action | Record<string, unknown> | string,
  indexer: string,
): ErrorFromBack[] => {
  return <ErrorFromBack[]>(
    get(
      state,
      [getActionBase(action), indexer, DATA_STATE.ERRORS],
      memoizedEmptyErrors,
    )
  );
};

interface IndexMapperArgs {
  action: Action | string;
  indexer?: string;
  indexers?: string[];
}

const indexMapperFactory =
  (state: Record<string, unknown>, dataStateName: DATA_STATE) =>
  ({ action, indexer, indexers = [] }: IndexMapperArgs) => {
    const checkIndexWasLoaded = (indexer: string): boolean => {
      const actionBase = getActionBase(action);

      return get(state, [actionBase, indexer, dataStateName]) || false;
    };

    if (!indexer && indexers.length) {
      return indexers.every(checkIndexWasLoaded);
    }

    if (indexer) {
      return checkIndexWasLoaded(indexer);
    }

    return checkIndexWasLoaded(defaultIndexer);
  };

export const isActionLoadingSelector = createSelector(
  [selectSagaRequestMetadata, getAction, getIndexer],
  (state, action, indexer = defaultIndexer): boolean => {
    return (
      get(state, [getActionBase(action), indexer, DATA_STATE.IS_LOADING]) ||
      false
    );
  },
);

export const wasActionLoadedSelector = createSelector(
  [selectSagaRequestMetadata, getAction, getIndexer],
  (state, action, indexer = defaultIndexer): boolean => {
    return (
      get(state, [getActionBase(action), indexer, DATA_STATE.WAS_LOADED]) ||
      false
    );
  },
);

export const getActionErrorsSelector = createSelector(
  [selectSagaRequestMetadata, getAction, getIndexer],
  (state, action, indexer = defaultIndexer) => {
    return getErrors(state, action, indexer);
  },
);

export const getActionsErrorsSelector = createSelector(
  [selectSagaRequestMetadata, getSuperActions],
  (state, actions) => {
    return actions
      .map(({ action, indexer = defaultIndexer }) =>
        getErrors(state, action, indexer),
      )
      .reduce((acc, errors) => {
        return [...acc, ...errors];
      }, []);
  },
);

export const wasEveryActionLoadedSelector = createSelector(
  [selectSagaRequestMetadata, getSuperActions],
  (state, actions: SuperAction[] = []) => {
    return actions
      .map(indexMapperFactory(state, DATA_STATE.WAS_LOADED))
      .every((value) => value === true);
  },
);

export const someActionIsLoadingSelector = createSelector(
  [selectSagaRequestMetadata, getSuperActions],
  (state, actions) => {
    return actions
      .map(indexMapperFactory(state, DATA_STATE.IS_LOADING))
      .some((value) => value === true);
  },
);

export const someActionsHasErrorSelector = createSelector(
  [selectSagaRequestMetadata, getSuperActions],
  (state, actions): boolean => {
    return actions
      .map(indexMapperFactory(state, DATA_STATE.HAS_ERRORS))
      .some((hasError) => hasError);
  },
);
