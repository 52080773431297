import useGetContent from './useGetContent';

export default function useGetSvg({
  url,
  headers = {
    Accept: 'image/svg+xml',
    'Access-Control-Max-Age': '600',
    'accept-language': 'fr',
  },
}) {
  return useGetContent({
    url,
    headers,
  });
}
