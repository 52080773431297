import { MessageType } from '@savgroup-front-common/types';

import messages from './messages';

const suggestedAddressErrorMessage = ({
  error,
}: {
  error: string | undefined;
}): MessageType => {
  switch (error) {
    case 'E.HOUSE_NUMBER.INVALID':
      return messages.houseNumberInvalid;

    case 'E.HOUSE_NUMBER.MISSING':
      return messages.missingHouseNumber;
    case 'E.STREET.MISSING':
      return messages.streetMissing;
    case 'E.STREET.INVALID':
      return messages.streetInvalid;
    case 'E.ADDRESS.INVALID':
      return messages.addressInvalid;
    case 'E.ZIP.NOT_FOUND':
      return messages.zipNotFound;
    case 'E.ZIP.INVALID':
      return messages.zipInvalid;
    case 'E.ADDRESS.MULTIPLE':
      return messages.addressMultiple;
    case 'E.ADDRESS.INSUFFICIENT':
      return messages.addressInsufficent;
    case 'E.CITY_STATE.INVALID':
      return messages.cityStateInvalid;
    case 'E.ADDRESS.DELIVERY.INVALID':
      return messages.addressDeliveryInvalid;
    case 'E.STATE.INVALID':
      return messages.stateInvalid;
    default:
      return messages.addressNotFound;
  }
};

export default suggestedAddressErrorMessage;
