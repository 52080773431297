import { Reducer } from 'react';

export type isValidatedStepAction = {
  payload: boolean;
};

export const isValidatedStepState = ({
  state,
}: {
  state: boolean;
}): boolean => {
  return state;
};

const isValidatedStepReducer: Reducer<boolean, isValidatedStepAction> = (
  state,
  action,
) => action.payload;

export default isValidatedStepReducer;
