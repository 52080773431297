import React, { FC } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Tag } from '@savgroup-front-common/core/src/atoms/Tag';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { intl } from '@savgroup-front-common/core/src/helpers';
import { FileSummary } from '@savgroup-front-common/types';

import messages from './messages';
import { ProductsInfoMinimal } from '../ProductsInfo.types';

import {
  $CardFileHeader,
  $CardFileHeaderDate,
  $CardHeader,
  $CardTagHeader,
  $CardTagWrapperHeader,
  $CardTitleContainer,
} from './ProductInfoHeader.styles';

const ProductInfoHeader: FC<{
  fileInfo?: FileSummary;
  productInfo?: ProductsInfoMinimal;
  hasCreatedDate?: boolean;
  hasOpenProductButton?: boolean;
  onClick?: () => void;
}> = ({
  fileInfo,
  productInfo,
  hasCreatedDate,
  hasOpenProductButton,
  onClick,
}) => {
  return (
    <$CardHeader>
      <$CardFileHeader>
        <$CardTitleContainer>
          <SafeFormattedMessageWithoutSpread
            message={messages.titleCard}
            values={{
              fileReference: productInfo?.fileReference,
            }}
          />
        </$CardTitleContainer>
        {hasCreatedDate && (
          <>
            {' '}
            <$CardFileHeaderDate>
              <SafeFormattedMessageWithoutSpread
                message={messages.titleCardDate}
                values={{
                  date: intl?.formatDate(fileInfo?.createdDate, {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  }),
                }}
              />
            </$CardFileHeaderDate>
          </>
        )}
      </$CardFileHeader>
      <$CardTagWrapperHeader>
        {productInfo?.solutionName && (
          <$CardTagHeader>
            <Tag isSmall neutral>
              {productInfo?.solutionName}
            </Tag>
          </$CardTagHeader>
        )}
        <$CardTagHeader>
          {productInfo?.isClosed ? (
            <Tag isSmall danger>
              <SafeFormattedMessageWithoutSpread message={messages.isClosed} />
            </Tag>
          ) : (
            <Tag isSmall success>
              <SafeFormattedMessageWithoutSpread message={messages.isOpen} />
            </Tag>
          )}
        </$CardTagHeader>
        {hasOpenProductButton && (
          <$CardTagHeader>
            <Button naked type={BUTTON_TYPES.BUTTON} onClick={onClick} small>
              <SafeFormattedMessageWithoutSpread message={messages.details} />
            </Button>
          </$CardTagHeader>
        )}
      </$CardTagWrapperHeader>
    </$CardHeader>
  );
};

ProductInfoHeader.displayName = 'ProductInfoHeader';
export default ProductInfoHeader;
