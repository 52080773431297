export enum APP_ENVS {
  DEFAULT = 'DEFAULT',
  DEMO = 'DEMO',
  PREPROD = 'PREPROD',
  PROD = 'PROD',
  DEV = 'DEV',
  LOCAL = 'LOCAL',
}

export const currentAppEnvironment =
  (process.env.REACT_APP_ENV as APP_ENVS) || APP_ENVS.DEFAULT;

export const getDatadogEnv = (env: APP_ENVS) => {
  switch (env) {
    case APP_ENVS.DEFAULT:
      return 'development';
    case APP_ENVS.DEMO:
      return 'demonstration';
    case APP_ENVS.PROD:
      return 'production';
    case APP_ENVS.DEV:
      return 'development';
    case APP_ENVS.LOCAL:
      return 'local';
    case APP_ENVS.PREPROD:
      return 'preproduction';
    default:
      throw new Error('Invalid state');
  }
};
