import React from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { Product } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import messages from './messages';
import useProductHeader from './ProductHeader.hooks';
import {
  $ProductCardImageContainer,
  $ProductContainer,
  $ProductInfoContainer,
} from './ProductHeader.styles';

interface ProductHeader {
  product: Product;
}

const ProductHeader: React.FC<ProductHeader> = ({ product }) => {
  const { model } = useProductHeader({ product });

  return (
    <$ProductContainer>
      <$ProductCardImageContainer>
        {model?.pictureUrl && model.modelTypeId ? (
          <img src={model?.pictureUrl} alt="pictureUrl" />
        ) : (
          <MyAccountIcon icon={ICONS_TYPE.BOX_ICON} />
        )}
      </$ProductCardImageContainer>
      <$ProductInfoContainer>
        <span>{product.modelName}</span>
        {model?.sku && (
          <SafeFormattedMessageWithoutSpread
            message={messages.reference}
            values={{
              value: model.sku,
            }}
          />
        )}
      </$ProductInfoContainer>
    </$ProductContainer>
  );
};

ProductHeader.displayName = 'ProductHeader';

export default ProductHeader;
