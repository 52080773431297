import {
  ADDRESS_MANAGEMENT_FIELDS,
  AddressManagementFieldValues,
  AddressManagementValues,
} from '../AddressManagement.types';

const adaptCustomerAddress = (
  customerAddress: AddressManagementFieldValues,
): AddressManagementValues => {
  return {
    ...customerAddress,
    [ADDRESS_MANAGEMENT_FIELDS.HAS_ELEVATOR]:
      customerAddress?.hasElevator?.value === 'true',
    [ADDRESS_MANAGEMENT_FIELDS.HAS_PARKING_SPACE]:
      customerAddress?.hasParkingSpace?.value === 'true',
    [ADDRESS_MANAGEMENT_FIELDS.HOUSING_TYPE]:
      customerAddress?.housingType?.value,
  };
};

export default adaptCustomerAddress;
