/* eslint-disable no-console */
import { fromJS } from 'immutable';
import get from 'lodash/get';
import { AnyAction } from 'redux';

import { GET_OPENED_INVOICES } from './actionTypes';
import { formatInvoice } from './helpers';
import { OnOpenedInvoicesResponse, PaymentsState } from './types';

const initialState = fromJS({
  allInvoices: {},
  openedInvoices: {},
  invoicesCreationForClaim: {},
  invoicesCreationForClaimGroup: {},
});

interface OnOpenedInvoicesAction extends AnyAction {
  meta: {
    id: string;
  };
  payload: OnOpenedInvoicesResponse;
}

function onOpenedInvoicesLoading(
  state: PaymentsState,
  { meta }: OnOpenedInvoicesAction,
) {
  const id = get(meta, ['id']);

  if (!id) {
    console.warn('[onOpenedInvoicesLoading] id is undefined');

    return state;
  }

  return state.setIn(
    ['openedInvoices', id],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}
function onOpenedInvoicesLoadingErrored(
  state: PaymentsState,
  { meta }: OnOpenedInvoicesAction,
) {
  const id = get(meta, ['id']);

  if (!id) {
    console.warn('[onOpenedInvoicesLoadingErrored] id is undefined');

    return state;
  }

  // TODO errors
  return state.updateIn(['openedInvoices', id], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}
function onOpenedInvoicesLoaded(
  state: PaymentsState,
  { payload, meta }: OnOpenedInvoicesAction,
) {
  const id = get(meta, ['id']);

  if (!id) {
    console.warn('[onOpenedInvoicesLoaded] id is undefined.');

    return state;
  }
  const invoice = formatInvoice(get(payload, ['value']));

  return state.setIn(
    ['openedInvoices', id],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], invoice),
  );
}

export default function mainReducer(
  state: PaymentsState = initialState,
  action: AnyAction,
) {
  switch (action.type) {
    case GET_OPENED_INVOICES.STARTED:
      return onOpenedInvoicesLoading(state, action as OnOpenedInvoicesAction);
    case GET_OPENED_INVOICES.ERRORED:
      return onOpenedInvoicesLoadingErrored(
        state,
        action as OnOpenedInvoicesAction,
      );
    case GET_OPENED_INVOICES.SUCCEEDED:
      return onOpenedInvoicesLoaded(state, action as OnOpenedInvoicesAction);

    default:
      return state;
  }
}
