import '@stripe/stripe-js';
import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { Selectors as CustomerSelectors } from 'myaccount/domains/Customer';

import { getInvoice } from '../../../domains/Customer/actionCreators';
import InvoicePaymentForm from '../InvoicePaymentForm';

import { useAwaitingPayment, useGetQuoteWithMargin } from './hooks';

const AwaitingPayment = ({ fileId, invoice, isLoading, onSubmit }) => {
  const invoiceToPay = useSelector(CustomerSelectors.invoiceToPay);
  const dispatch = useDispatch();

  const quoteId = get(
    find(get(invoice, 'items'), { kind: 'Quote' }),
    'referenceId',
  );

  useEffect(() => {
    dispatch(getInvoice({ fileId }));
  }, [dispatch, fileId]);

  const { resetPaymentFormErrors } = useAwaitingPayment();

  const quotesWithMargin = useGetQuoteWithMargin();

  const quote = find(quotesWithMargin, { id: quoteId });

  if (!invoice) {
    logError(`AwaitingPayment: there is no invoice for FileId => ${fileId}`, {
      fileId,
      invoiceToPay,
    });

    return null;
  }

  return (
    <InvoicePaymentForm
      invoice={invoice}
      quote={quote}
      isLoading={isLoading}
      resetPaymentFormErrors={resetPaymentFormErrors}
      onSubmit={onSubmit}
      fileId={fileId}
    />
  );
};

AwaitingPayment.propTypes = {
  invoice: PropTypes.shape({ id: PropTypes.string }),
  isLoading: PropTypes.bool.isRequired,
};
AwaitingPayment.defaultProps = {
  invoice: null,
};

export default AwaitingPayment;
