import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import union from 'lodash/union';
import unionWith from 'lodash/unionWith';
import values from 'lodash/values';
import { createSelector } from 'reselect';

import { Selectors } from '@savgroup-front-common/core/src/domains/claims';

export const orderIssuesValuesSelector = createSelector(
  [Selectors.orderIssues],
  (orderIssues) => orderIssues.get('values').toJS(),
);

export const OrderIssuesLoadedSelector = createSelector(
  [Selectors.orderIssues],
  (orderIssues) => orderIssues.get('loaded', false),
);

export const someOrderIssuesStillLoadingSelector = createSelector(
  [orderIssuesValuesSelector],
  (orderIssues) =>
    values(orderIssues).some((order) => order.isLoaded === false),
);

export const isOrderIssuesLoadingSelector = createSelector(
  [OrderIssuesLoadedSelector, someOrderIssuesStillLoadingSelector],
  (globalLoaded, someLoading) => !(globalLoaded && !someLoading),
);

export const mergedIssuesSelector = createSelector(
  [orderIssuesValuesSelector],
  (orderIssues) => {
    const orderIssuesJSArr = map(values(orderIssues), (order) => order.value);
    const unionArray = reduce(orderIssuesJSArr, (array, nextArray) =>
      unionWith(array, nextArray, (arrVal, othVal) => arrVal.id === othVal.id),
    );

    return map(unionArray, (issue) => ({
      ...issue,
      ownerProductIds: reduce(
        orderIssuesJSArr,
        (acc, array) =>
          union(
            acc,
            get(
              find(array, (item) => item.id === issue.id),
              'ownerProductIds',
            ),
          ),
        issue.ownerProductIds,
      ),
    }));
  },
);
