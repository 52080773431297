import { defineMessages } from 'react-intl';

export default defineMessages({
  free: {
    id: 'components.myaccount.claims.handling.cards.free',
    defaultMessage: 'Free',
  },
  depositPickupPoint: {
    id: 'components.myaccount.claims.handling.cards.Deposit_PickupPoint',
    defaultMessage: 'Drop off point',
  },
  deliveryPickupPoint: {
    id: 'components.myaccount.claims.handling.cards.Delivery_PickupPoint',
    defaultMessage: 'Drop off point',
  },
  depositPickupStore: {
    id: 'components.myaccount.claims.handling.cards.Deposit_PickupStore',
    defaultMessage: 'Drop off at store',
  },
  deliveryPickupStore: {
    id: 'components.myaccount.claims.handling.cards.Delivery_PickupStore',
    defaultMessage: 'Drop off at store',
  },
  deliveryHome: {
    id: 'components.myaccount.claims.handling.cards.Delivery_Home',
    defaultMessage: 'Home delivery',
  },
  depositHome: {
    id: 'view.card.handling.homePickup',
    defaultMessage: 'Home pickup',
  },
  depositExternal: {
    id: 'components.myaccount.claims.handling.cards.Deposit_External',
    defaultMessage: 'External transport',
  },
  depositHomeIntervention: {
    id: 'components.myaccount.claims.handling.cards.Deposit_HomeIntervention',
    defaultMessage: 'Home intervention',
  },
  deliveryExternal: {
    id: 'components.myaccount.claims.handling.cards.Delivery_External',
    defaultMessage: 'External transport',
  },
  depositImmediateDropAtStore: {
    id: 'components.myaccount.claims.handling.cards.depositImmediateDropAtStore',
    defaultMessage: 'Immediate drop at store',
  },
  deliveryPickupStoreDelivery: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStoreDelivery',
    defaultMessage: 'Delivery at store',
  },
});
