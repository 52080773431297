import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';

export const $Card = styled(Card)<{ isCliquable?: boolean }>`
  @media ${media.maxWidth.sm} {
    padding: 1rem;
  }

  transition: 0.15s transform ease-in-out;
  backface-visibility: hidden;
  margin-bottom: 24px;
  -webkit-font-smoothing: antialiased;

  ${({ isCliquable }) =>
    isCliquable
      ? `
    &:hover {
      transform: scale3d(1.015, 1.015, 1);
    }
  `
      : ''}
`;

export const $CardContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 calc(1rem - 2px);
  justify-content: space-between;
`;

export const $CardContentSolution = styled.div`
  flex: 1;
  display: flex;
  margin-left: 2rem;
`;

export const $CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: -1rem 0;
`;

export const $Divider = styled.div`
  width: calc(100% + 2rem);
  height: 1px;
  background: #f7f7f7;
  margin: 0.25rem -1rem 1.25rem;
`;
