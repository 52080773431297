import {
  FILE_STATES,
  REFERENCED_MODULE_TYPES,
} from '@savgroup-front-common/constants';

import { FileAction } from '../../../../types';
import { HolderType } from '@savgroup-front-common/types';

export const shouldDisplayConfirmedTechnicalInterventionSchedule = ({
  action,
  workflowInfos,
  holderType,
}: {
  action?: FileAction;
  workflowInfos?: { currentModule?: { referencedModuleType: string } };
  holderType?: HolderType;
}) => {
  const currentStatusName =
    action?.currentState?.name || action?.fileStatus?.name;
  const referencedModuleType =
    workflowInfos?.currentModule?.referencedModuleType;

  return (
    currentStatusName?.includes(FILE_STATES.InterventionDomicile) ||
    ([FILE_STATES.NEW, FILE_STATES.PLANNED].some((state) =>
      currentStatusName?.includes(state),
    ) &&
      referencedModuleType?.includes(
        REFERENCED_MODULE_TYPES.TECHNICAL_INTERVENTION_MODULE,
      ) &&
      (!holderType || holderType === HolderType.OWNER))
  );
};
