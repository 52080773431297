import React from 'react';
import { Controller } from 'react-hook-form';

import {
  Autocomplete,
  DescriptionOptionFormatter,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';

import { CONFIRMATION_FIELDS } from '../Confirmation.helpers';
import { InfoTitle, SubHeader } from '../Confirmation.styles';

import { ConfirmationStoreActorContainer } from './ConfirmationStoreActor.styles';
import messages from './messages';

function ConfirmationStoreActor({ options, formContext, defaultValue }) {
  const {
    control,
    formState: { errors },
  } = formContext;

  return (
    <ConfirmationStoreActorContainer $isHidden={options.length === 1}>
      <InfoTitle heading>
        <SafeFormattedMessage {...messages.title} />
      </InfoTitle>
      <SubHeader paragraph>
        <SafeFormattedMessage {...messages.didactic} />
      </SubHeader>
      <Card>
        <FormGroup>
          <Controller
            name={CONFIRMATION_FIELDS.STORE_ACTOR}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <Autocomplete
                isRequired
                label={messages.label}
                value={field.value}
                options={options}
                onChange={field.onChange}
                errors={errors}
                name={field.name}
                ref={field.ref}
                formatOptionLabel={DescriptionOptionFormatter}
              />
            )}
          />
        </FormGroup>
      </Card>
    </ConfirmationStoreActorContainer>
  );
}

export default ConfirmationStoreActor;
