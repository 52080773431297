import {
  currentAppEnvironment,
  FEATURE_FAG_DESCRIPTIONS,
  FEATURE_FLAGS,
} from '@savgroup-front-common/constants';

import useFeatureFlagContext from './useFeatureFlagContext';

const useIsFeatureEnabled = (feature: FEATURE_FLAGS): boolean => {
  const { features } = useFeatureFlagContext();

  const featureSummary = FEATURE_FAG_DESCRIPTIONS[feature];

  if (!featureSummary.excludedEnv) {
    return features.includes(feature);
  }

  const hasOnlyEnvsOption = featureSummary.excludedEnv.includes(
    currentAppEnvironment,
  );

  return hasOnlyEnvsOption ? features.includes(feature) : true;
};

export default useIsFeatureEnabled;
