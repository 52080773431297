import { yupResolver } from '@hookform/resolvers/yup';
import every from 'lodash/every';
import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col } from 'react-styled-flexboxgrid';

import { ALLOWED_MIME_TYPES } from '@savgroup-front-common/constants';
import {
  BUTTON_TYPES,
  FILE_BUTTON_ORIGIN,
} from '@savgroup-front-common/constants/src/shared';
import {
  FileUpload,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { Paragraph } from '@savgroup-front-common/core/src/atoms/Paragraph';
import { getUploadedAttachmentsByFileId } from '@savgroup-front-common/core/src/domains/attachments/selectors';
import { TextareaHookForm } from '@savgroup-front-common/core/src/molecules/Form';

import { ActionLabel } from '../../ActionLabel';
import { CustomerCommentDisplay } from '../../CustomerCommentDisplay';
import formMessages from '../../DevicePasswordForm/messages';

import schema from './AdditionalInfo.schema';
import {
  $Col,
  ButtonWrapper,
  StyledButton,
  StyledHeader,
  StyledRow,
} from './AdditionalInfo.styles';
import messages from './messages';

const AdditionalInfo = ({
  addAttachmentToFile,
  fileId,
  deleteAttachmentFromFile,
  onSubmit,
  isLoading,
  adaptedFirstAction,
}) => {
  const uploadedAttachments = useSelector((state) =>
    getUploadedAttachmentsByFileId(state, fileId),
  );
  const isAttachmentsLoaded = every(uploadedAttachments, { isLoaded: true });
  const currentFileAttachments = map(
    uploadedAttachments,
    (uploadedAttachment) => ({ ...uploadedAttachment.value }),
  );

  const formContext = useForm({
    resolver: yupResolver(schema()),
  });
  const { handleSubmit, formState, control, register } = formContext;
  const { errors, isSubmitted } = formState;

  return (
    <>
      <ActionLabel action={adaptedFirstAction} index={0} />

      <CustomerCommentDisplay
        title={messages.documentNeededCommentTitle}
        action={adaptedFirstAction}
      />
      <StyledRow>
        <$Col xs={12}>
          <StyledHeader>
            <FormattedMessage {...messages.header} />
          </StyledHeader>
          <Paragraph>
            <FormattedMessage {...messages.subHeader} />
          </Paragraph>
        </$Col>
        <Col xs={12}>
          <form
            onSubmit={handleSubmit((data) => {
              onSubmit(data.comment);
            })}
          >
            <FormGroup>
              <TextareaHookForm
                name="comment"
                errors={errors}
                label={messages.comment}
                autoHeight
                register={register}
              />
            </FormGroup>

            <FormGroup>
              <Controller
                control={control}
                name="file"
                render={({ field }) => {
                  return (
                    <FileUpload
                      name={field.name}
                      errors={errors}
                      hollow
                      allowedMimeTypes={ALLOWED_MIME_TYPES}
                      disabled={uploadedAttachments.length !== 0}
                      onSelect={(files = []) => {
                        field.onChange({ value: files[0] });

                        addAttachmentToFile({
                          file: files[0],
                          fileId,
                          service: 'myaccount',
                        });
                      }}
                      onRemove={(item) => {
                        const { fileAttachmentId, id } = item;

                        field.onChange(null);

                        deleteAttachmentFromFile({
                          fileId,
                          fileAttachmentId,
                          internalId: id,
                        });
                      }}
                      fileNames={filter(
                        currentFileAttachments,
                        (document) =>
                          get(document, 'fileButtonOrigin') !==
                          FILE_BUTTON_ORIGIN.DOCUMENTS_TAB,
                      )}
                    />
                  );
                }}
              />
            </FormGroup>

            <ButtonWrapper>
              <StyledButton
                primary
                disabled={!currentFileAttachments || !isAttachmentsLoaded}
                type={BUTTON_TYPES.SUBMIT}
                isError={isSubmitted && size(errors) > 0}
                isLoading={isLoading || (isSubmitted && size(errors) === 0)}
              >
                <FormattedMessage {...formMessages.submit} />
              </StyledButton>
            </ButtonWrapper>
          </form>
        </Col>
      </StyledRow>
    </>
  );
};

AdditionalInfo.propTypes = {
  addAttachmentToFile: PropTypes.func.isRequired,
  deleteAttachmentFromFile: PropTypes.func.isRequired,
  fileId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

AdditionalInfo.defaultProps = {};

AdditionalInfo.displayName = 'AdditionalInfo';

export default AdditionalInfo;
