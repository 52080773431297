import React, { FunctionComponent } from 'react';

import { SafeFormattedMessage } from '../../formatters';
import { TabScroller } from '../../protons/TabScroller';

import useTabNavAnimation from './hooks/useTabNavAnimation';
import TabButton from './TabButton';
import { Tab } from './Tabs.types';

interface TabsNavProps {
  activeTab: Tab;
  tabs: Tab[];
  errors?: string[];
  onTabChange: (tab: Tab) => void;
  dataTestId?: string;
  hasBottomBorder?: boolean;
}

const TabsNav: FunctionComponent<TabsNavProps> = ({
  activeTab,
  tabs,
  errors = [],
  onTabChange,
  dataTestId,
  hasBottomBorder,
}) => {
  const { currentIndex, currentName, totalCount } = useTabNavAnimation({
    tabs,
    activeTab,
  });

  const hasErrors = errors.includes(currentName);

  return (
    <TabScroller
      dataTestId={`${dataTestId}__selected__${currentName || ''}`}
      totalCount={totalCount}
      currentIndex={currentIndex}
      hasErrors={hasErrors}
      hasBottomBorder={hasBottomBorder}
    >
      {tabs.map((tab) => {
        const { name, message, count = 0, permissions = [], icon = null } = tab;

        const hasError = errors.includes(name);

        return (
          <TabButton
            permissions={permissions}
            key={name}
            isActive={activeTab && activeTab.name === name}
            count={count}
            dataTestId={`${dataTestId}__buttonTab__${name}`}
            hasError={hasError}
            onClick={() => onTabChange(tab)}
          >
            {icon}
            {SafeFormattedMessage(message)}
          </TabButton>
        );
      })}
    </TabScroller>
  );
};

TabsNav.displayName = 'TabsNav';

export default TabsNav;
