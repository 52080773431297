/* eslint-disable @typescript-eslint/no-var-requires,global-require */
import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';

interface GetHotjarConfigurationEnabledReturnType {
  enabled: true;
  hjid: number;
  hjsv: number;
}
interface GetHotjarConfigurationDisabledReturnType {
  enabled: false;
}

const HOTJAR_CONFIG = (():
  | GetHotjarConfigurationEnabledReturnType
  | GetHotjarConfigurationDisabledReturnType => {
  switch (currentAppEnvironment) {
    case APP_ENVS.DEMO:
      return require('./hotjar.config.DEMO').default;
    case APP_ENVS.DEV:
      return require('./hotjar.config.DEV').default;
    case APP_ENVS.LOCAL:
      return require('./hotjar.config.LOCAL').default;
    case APP_ENVS.PROD:
      return require('./hotjar.config.PROD').default;
    case APP_ENVS.DEFAULT:
    default:
      return require('./hotjar.config.DEFAULT').default;
  }
})();

export default HOTJAR_CONFIG;
