import get from 'lodash/get';
import size from 'lodash/size';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ActionCreators as ClaimActionCreators } from '@savgroup-front-common/core/src/domains/claims';
import { Selectors as ClaimCreationSelectors } from 'myaccount/domains/Claim/ClaimCreation';
import { selectCurrentIssue } from 'myaccount/domains/Claim/ClaimCreation/selector';
import { selectOrdersIsLoaded } from 'myaccount/domains/Orders/selectors';
import {
  currentClaimGroupIdSelector,
  ordersSelector,
} from 'myaccount/domains/Views/groupedProduct';
import { isOrderIssuesLoadingSelector } from 'myaccount/domains/Views/issues';
import { ROUTES } from 'myaccount/view/app';

function useChooseProductsPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(isOrderIssuesLoadingSelector);
  const issueId = useSelector(ClaimCreationSelectors.currentIssueIdIdSelector);
  const orderIsLoaded = useSelector(selectOrdersIsLoaded);
  const products =
    useSelector(ClaimCreationSelectors.currentProductsSelector) || {};

  const currentClaimGroupId = useSelector(currentClaimGroupIdSelector);

  const handleGoBack = () => {
    if (currentClaimGroupId) {
      return history.push(
        `${ROUTES.ISSUES}?claimGroupId=${currentClaimGroupId}`,
      );
    }

    return history.push(ROUTES.ISSUES);
  };

  const issue = useSelector(selectCurrentIssue);
  const orders = useSelector(ordersSelector);

  const ownerProductIds = get(issue, 'ownerProductIds');
  const orderIds = useMemo(
    () => (orders || []).map((order) => order.orderId),
    [orders],
  );
  const orderIdsHash = orderIds.join(';');
  const ownerProductIdsCount = size(ownerProductIds);

  useEffect(() => {
    if (ownerProductIdsCount === 0) {
      dispatch(ClaimActionCreators.loadIssuesFromAllOrderStarted);

      for (const orderId of orderIdsHash.split(';')) {
        dispatch(ClaimActionCreators.loadIssuesByOrder({ orderId }));
      }

      dispatch(ClaimActionCreators.loadIssuesFromAllOrderSucceeded());
    }
  }, [dispatch, ownerProductIdsCount, orderIdsHash]);

  return {
    handleGoBack,
    isLoading: !orderIsLoaded || isLoading || !issueId || !products,
  };
}

export default useChooseProductsPage;
