import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { ActionCreators as SellerActionCreators } from '@savgroup-front-common/core/src/domains/sellerConfiguration';

import {
  HeaderContainer,
  MenuContainer,
  RightSide,
  StyledGrid,
} from './AppHeader.styles';
import LanguageChooser from './languageChooser/LanguageChooser';
import Logo from './logo/Logo';
import StepProgress from './stepProgress/StepProgress';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

const AppHeader = ({
  logoUrl,
  sellerId,
  url,
  steps,
}) => {
  const dispatch = useDispatch();
  const isGridFluidActive = useMedia(media.maxWidth.md);

  useEffect(() => {
    dispatch(
      SellerActionCreators.getSellersConfiguration({ sellerIds: [sellerId] }),
    );
  }, [sellerId, dispatch]);

  return (
    <HeaderContainer componentThemeName={SUPPORTED_COMPONENTS.APP_HEADER}>
      <StyledGrid fluid={isGridFluidActive}>
        <MenuContainer>
          <Logo url={url} logoUrl={logoUrl} />
          <RightSide>
            <LanguageChooser />
          </RightSide>
        </MenuContainer>
      </StyledGrid>
      <StepProgress steps={steps} />
    </HeaderContainer>
  );
};

AppHeader.propTypes = {
  url: PropTypes.shape({
    isExternal: PropTypes.bool.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired,
  steps: PropTypes.shape({}).isRequired,
  loginInfos: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
  }),
  sellerId: PropTypes.string,
};

AppHeader.defaultProps = {
  loginInfos: {
    firstName: 'John',
    lastName: 'Doe',
    isLoggedIn: false,
  },
  sellerId: null,
};

AppHeader.displayName = 'AppHeader';

export default AppHeader;
