import { ReverseMoneyNumberAmount } from './ReverseMoney';
import { SparePartCondition } from './SparePartRequest';

export interface SparePartCatalogItemWithPrice {
  sparePartId: string;
  sellerId: string;
  supplierId: string;
  reference: string;
  preTaxBuyingPrice: number;
  preTaxSellingPrice: number;
  vatRate: number;
  sellingPriceIncludedTax: number;
}

export interface ModelDimension {
  lengthInCm: number;
  widthInCm: number;
  heightInCm: number;
}

export enum SparePartReturnProcess {
  DEEE = 'DEEE',
  MANUFACTURER = 'Manufacturer',
  NONE = 'None',
}
export enum SpareProductSize {
  XS = 'XS',
  XL = 'XL',
  NONE = 'None',
}
export enum SparePartStatus {
  ACTIVE = 'Active',
  DEPRECATED = 'Deprecated',
  OUTDATED = 'Outdated',
  NONE = 'None',
}
export enum SparePartType {
  FUNCTIONAL = 'Functional',
  AESTHETIC = 'Aesthetic',
  ACCESSORY = 'Accessory',
  CONSUMABLE = 'Consumable',
  KIT = 'Kit',
  NONE = 'None',
}

export interface SparePartDto {
  id: string;
  manufacturerReference: string;
  uniqueName: string;
  ean: string;
  manufacturerLabel: string;
  isOEM: boolean;
  sparePartType: SparePartType;
  status: SparePartStatus;
  size: SpareProductSize;
  dimensions?: ModelDimension;
  weight: number;
  isCustomerSaleAllowed: boolean;
  outOfWarrantyReturnProcess: SparePartReturnProcess;
  warrantyReturnProcess: SparePartReturnProcess;
  manufacturerWarranty: boolean;
  sparePartSupplierAssociations?: SparePartSupplierAssociationDto[];
  category: string;
  subCategory: string;
  manufacturer: string;
  manufacturerUniqueName: string;
  manufacturerName: string;
  comment?: string;
}

export interface SparePartSupplierAssociationDto {
  id: string;
  sparePartId: string;
  supplierId: string;
  supplierPartReference: string;
  supplierPartName: string;
  condition: SparePartCondition;
  lastPretaxPurchasePrice?: ReverseMoneyNumberAmount;
  negotiatedPretaxPurchasePrice?: ReverseMoneyNumberAmount;
  recommendedSalePriceIncludingTaxes?: ReverseMoneyNumberAmount;
  comment?: string;
}
