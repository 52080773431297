import React from 'react';

interface CartIconProps {
  color?: string;
}

/* eslint-disable max-len */
const CartIcon: React.FC<CartIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.4999 36.6667C33.163 36.6667 33.7988 36.4033 34.2677 35.9344C34.7365 35.4656 34.9999 34.8297 34.9999 34.1667C34.9999 33.5036 34.7365 32.8677 34.2677 32.3989C33.7988 31.9301 33.163 31.6667 32.4999 31.6667C31.8369 31.6667 31.201 31.9301 30.7322 32.3989C30.2633 32.8677 29.9999 33.5036 29.9999 34.1667C29.9999 34.8297 30.2633 35.4656 30.7322 35.9344C31.201 36.4033 31.8369 36.6667 32.4999 36.6667ZM15.8333 36.6667C16.4963 36.6667 17.1322 36.4033 17.601 35.9344C18.0699 35.4656 18.3333 34.8297 18.3333 34.1667C18.3333 33.5036 18.0699 32.8677 17.601 32.3989C17.1322 31.9301 16.4963 31.6667 15.8333 31.6667C15.1702 31.6667 14.5343 31.9301 14.0655 32.3989C13.5966 32.8677 13.3333 33.5036 13.3333 34.1667C13.3333 34.8297 13.5966 35.4656 14.0655 35.9344C14.5343 36.4033 15.1702 36.6667 15.8333 36.6667Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33325 6.66667C8.05492 5.555 6.66659 3.33333 3.33325 3.33333M33.3333 25H8.71659C5.74325 25 4.16659 26.3017 4.16659 28.3333C4.16659 30.365 5.74325 31.6667 8.71659 31.6667H32.4999M8.33325 6.66667H36.6666L33.3333 25H11.6666L8.33325 6.66667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

CartIcon.displayName = 'CartIcon';

export default CartIcon;
