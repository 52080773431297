import { darken } from 'polished';
import { DefaultTheme } from 'styled-components';

import { getFieldColorFromStatus } from '../common/helpers/getFieldColorFromStatus';
import { InputStatus } from '../common/helpers/getFinalFieldState.types';

export const getBorderColor = ({
  theme,
  status,
  disabled,
  readOnly,
}: {
  theme: DefaultTheme;
  disabled?: boolean;
  readOnly?: boolean;
  status: InputStatus;
}) => {
  const fieldColorFromStatus = getFieldColorFromStatus({
    status,
    theme,
  });

  if (fieldColorFromStatus) {
    return fieldColorFromStatus;
  }

  if (disabled || readOnly) {
    return theme.colors.default;
  }

  return getFieldColorFromStatus({ status, theme }) || theme.colors.default;
};

export const getOnHoverBorderColor = ({
  theme,
  status,
  disabled,
  readOnly,
  $isFocusAndHoverBehavioursHandled,
}: {
  theme: DefaultTheme;
  disabled?: boolean;
  readOnly?: boolean;
  $isFocusAndHoverBehavioursHandled?: boolean;
  status: InputStatus;
}) => {
  const fieldColorFromStatus = getFieldColorFromStatus({
    status,
    theme,
  });

  if (!$isFocusAndHoverBehavioursHandled) {
    return theme.colors.default;
  }

  if (fieldColorFromStatus) {
    return darken(0.1, fieldColorFromStatus);
  }

  if (disabled || readOnly) {
    return theme.colors.default;
  }

  return theme.colors.primary;
};
