import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { CLAIM_STATE, HANDLING_MODES } from '@savgroup-front-common/constants';
import { goBackOnClaimGroupEdition } from 'myaccount/domains/Claim/ClaimEdition/actionCreators';
import {
  goToHandling,
  goToSolutions,
} from 'myaccount/domains/Navigation/navigationActions';

interface UseGoToPreviousStepArgs {
  handlingMode?: HANDLING_MODES;
}

const useGoToPreviousStep = ({
  handlingMode,
}: UseGoToPreviousStepArgs): (() => void) => {
  const dispatch = useDispatch();

  const dispatchGoToSolutions = useCallback(() => {
    dispatch(goToSolutions());
  }, [dispatch]);

  const dispatchGoBackOnClaimGroupEdition = useCallback(() => {
    dispatch(goBackOnClaimGroupEdition());
  }, [dispatch]);

  const dispatchGoToHandling = useCallback(
    (payload: CLAIM_STATE) => {
      dispatch(goToHandling(payload));
    },
    [dispatch],
  );

  return useCallback(() => {
    const onGoBack = dispatchGoBackOnClaimGroupEdition;

    if (onGoBack) {
      onGoBack();
    } else if (handlingMode === HANDLING_MODES.DELIVERY) {
      dispatchGoToHandling(CLAIM_STATE.HANDLING_DEPOSIT);
    } else {
      dispatchGoToSolutions();
    }
  }, [
    dispatchGoBackOnClaimGroupEdition,
    dispatchGoToHandling,
    dispatchGoToSolutions,
    handlingMode,
  ]);
};

export default useGoToPreviousStep;
