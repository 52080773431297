import React, { FC } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { DocumentData } from '@savgroup-front-common/core/src/formatters/getDocumentNameTranslated';
import { DownloadIcon } from '@savgroup-front-common/core/src/protons/icons';

import getDocumentName from './helpers/getDocumentName';
import { $File, $FileContainer } from './ProductIRSSummaryDocuments.styles';

interface ProductIRSSummaryDocumentsArgs {
  document: DocumentData;
  onPrintDocument: (document: DocumentData) => void;
}

const ProductIRSSummaryDocuments: FC<ProductIRSSummaryDocumentsArgs> = ({
  document,
  onPrintDocument,
}) => {
  const documentName = getDocumentName({ document });

  return (
    <$File
      fluid
      secondary
      icon={<DownloadIcon />}
      rounded
      type={BUTTON_TYPES.BUTTON}
      onClick={() => onPrintDocument(document)}
    >
      <$FileContainer>
        <span title={documentName}>{documentName}</span>
      </$FileContainer>
    </$File>
  );
};

ProductIRSSummaryDocuments.displayName = 'ProductIRSSummaryDocuments';

export default ProductIRSSummaryDocuments;
