import get from 'lodash/get';

const clientIdByHostname = {
  'sav.demo.auchan.fr': 'demo-auchan.revers.io',
  'demo-auchan.revers.io': 'demo-auchan.revers.io',
  'mesretours.mdm-staging.com': 'demo-maisonsdumonde.revers.io',
};

const authorityByHostname = {
  'demo-auchan.revers.io': 'https://demo-auchan-login.revers.io',
  'sav.demo.auchan.fr': 'https://sav-login.demo.auchan.fr',

  'demo-maisonsdumonde.revers.io':
    'https://demo-maisonsdumonde-login.revers.io',
  'mesretours.mdm-staging.com': 'https://mesretours-login.mdm-staging.com',
};

const authorityByClientId = {
  'demo-revers.io': 'https://demo-myaccount-login.revers.io',
  'demo-retours.carrefour.fr': 'https://demo-retours-login.carrefour.fr',
  'demo-auchan.revers.io': 'https://demo-auchan-login.revers.io',
  'demo-maisonsdumonde.revers.io': 'https://mesretours-login.mdm-staging.com',
};

const { hostname } = window.location;

function setAuthority(authority = null) {
  const { clientId } = this;

  const defaultAuthority = authorityByClientId[clientId];
  const defaultAuthorityFromHostname = get(authorityByHostname, hostname);

  if (hostname !== 'localhost' && defaultAuthorityFromHostname) {
    this.authority = defaultAuthorityFromHostname;

    return this;
  }

  this.authority = authority || defaultAuthority;

  return this;
}

const defaultClientId = 'demo-revers.io';

function computeClientId() {
  if (process.env.REACT_APP_CLIENTID) {
    return process.env.REACT_APP_CLIENTID;
  }
  if (hostname !== 'localhost') {
    return clientIdByHostname[hostname] || window.location.hostname;
  }

  return defaultClientId;
}

const clientId = computeClientId();

const AuthConfiguration = {
  clientId,
  authority: authorityByClientId[clientId],
  scope: 'openid email api',
  setAuthority,
};

export default AuthConfiguration;
