import React, { FC, Suspense } from 'react';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { formatAmountWithCurrency } from '@savgroup-front-common/core/src/formatters/intl';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { Invoice, PAYMENT_STATUS } from '@savgroup-front-common/types';

import { $PaymentContainer } from '../PaymentStripe/components/CheckoutForm.styles';

import {
  $ButtonLink,
  $Heading,
  $PaymentLabel,
} from './CashRegisterPayment.styles';
import { useCashRegisterPayment } from './hooks';
import messages from './messages';
import { ClosedLockUnfilled } from '@savgroup-front-common/core/src/protons/icons';

interface CashRegisterPaymentProps {
  fileId: string;
  claimGroupId: string;
  paymentUrl: string;
  isFormCompleted: boolean;
  invoice: Invoice;
}

const CashRegisterPayment: FC<CashRegisterPaymentProps> = ({
  fileId,
  claimGroupId,
  paymentUrl,
  isFormCompleted,
  invoice,
}) => {
  const { paymentStatus } = useCashRegisterPayment({
    paymentUrl,
    claimGroupId,
    fileId,
  });

  return (
    <Suspense fallback={<BaseLoader />}>
      <$PaymentContainer>
        <$Heading>
          <SafeFormattedMessageWithoutSpread
            message={messages.paymentWithoutStripeTitle}
          />
        </$Heading>
        {(!paymentStatus ||
          paymentStatus.status !== PAYMENT_STATUS.CREATED) && (
          <$PaymentLabel>
            <SafeFormattedMessageWithoutSpread
              message={messages.noPaymentFound}
            />
          </$PaymentLabel>
        )}
        {paymentStatus?.status === PAYMENT_STATUS.CREATED && (
          <>
            <$PaymentLabel>
              <SafeFormattedMessageWithoutSpread
                message={messages.paymentWithoutStripeDidactic}
              />
            </$PaymentLabel>
            <$ButtonLink
              isExternal
              href={paymentStatus.paymentUrlWithExternalId}
              primary
              icon={<ClosedLockUnfilled />}
              disabled={!isFormCompleted}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.paymentWithoutStripeButton}
                values={{
                  amount: formatAmountWithCurrency(
                    invoice.totalPriceWithTax,
                    CURRENCIES.EUR,
                  ),
                }}
              />
            </$ButtonLink>
          </>
        )}
      </$PaymentContainer>
    </Suspense>
  );
};

CashRegisterPayment.displayName = 'CashRegisterPayment';

export default CashRegisterPayment;
