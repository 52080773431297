import React, { FunctionComponent, MouseEventHandler } from 'react';

import { $RouteTabLink, $RouteTabSize, $Counter } from './RouteTabs.styles';

interface RouteTabsLinkProps {
  url: string;
  isActive: boolean;
  count: number;
  dataTestId?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  level: 1 | 2;
  isHidden?: boolean;
  isNewBoEnabled?: boolean;
}

const RouteTabsLink: FunctionComponent<
  React.PropsWithChildren<RouteTabsLinkProps>
> = ({
  children,
  url,
  isActive,
  count,
  dataTestId,
  onClick,
  level,
  isHidden,
  isNewBoEnabled,
}) => {
  return (
    <$RouteTabSize $isNewBoEnabled={isNewBoEnabled}>
      <$RouteTabLink
        to={url}
        $isActive={isActive}
        $count={count}
        data-testid={`RouteTabs-${dataTestId}`}
        onClick={onClick}
        $level={level}
        $isHidden={isHidden}
        data-active={isActive}
        $isNewBoEnabled={isNewBoEnabled}
      >
        {children}
      </$RouteTabLink>
      {count > 0 && isNewBoEnabled && <$Counter>{count}</$Counter>}
    </$RouteTabSize>
  );
};

RouteTabsLink.displayName = 'RouteTabsLink';

export default RouteTabsLink;
