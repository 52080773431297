import React from 'react';

interface AddCircleOutlineIconProps {
  color?: string;
}

/* eslint-disable max-len */
const AddCircleOutlineIcon: React.FC<AddCircleOutlineIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 20H19.9999M26.6666 20H19.9999M19.9999 20V13.3333M19.9999 20V26.6667M19.9999 36.6667C29.2049 36.6667 36.6666 29.205 36.6666 20C36.6666 10.795 29.2049 3.33333 19.9999 3.33333C10.7949 3.33333 3.33325 10.795 3.33325 20C3.33325 29.205 10.7949 36.6667 19.9999 36.6667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

AddCircleOutlineIcon.displayName = 'AddCircleOutlineIcon';

export default AddCircleOutlineIcon;
