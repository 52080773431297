import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import {
  HolderType,
  SparePartQuotationLine,
  SparePartSearchSummary,
} from '@savgroup-front-common/types';

import { CommonRepairerService } from '../../../api/RepairerService';
import {
  UpdateQuotationOptionalLineUnavailableSparePart,
  UpdateQuotationSparePartSelectionCommandArgs,
} from '../../../api/RepairerService/updateQuotationSparePartSelectionCommand';
import { CommonSparePartsService } from '../../../api/SpareParts';
import { sortBy } from '../../../helpers';
import { useGetSellingPriceForSellerQuery } from '../../../hooks/useGetSellingPriceForSellerQuery';
import { useToasts } from '../../../molecules/NotificationsProvider';
import { useCancelQuotation } from '../hooks/useCancelQuotation';
import { AdaptedQuotationNewModel } from '../types/AdaptedQuotationNewModel';

import { useRejectQuotation } from './hooks/useRejectQuotation';
import { useValidateQuotation } from './hooks/useValidateQuotation';
import schema from './QuotationPendingCustomerValidation.schema';
import {
  QuotationPendingCustomerValidationForm,
  QuotationPendingCustomerValidationNewModel,
} from './QuotationPendingCustomerValidation.types';

export const usePrepareUpdateQuotationSparePartSelectionCommandPayload = ({
  sellerId,
}: {
  sellerId: string;
}) => {
  const { getSellingPrice } = useGetSellingPriceForSellerQuery({ sellerId });

  const prepareUpdateQuotationSparePartSelectionCommandPayload = async ({
    quotationId,
    values,
    sparePartQuotationLines,
    alternativeSpareParts,
  }: {
    quotationId: string;
    values: QuotationPendingCustomerValidationForm;
    sparePartQuotationLines?: SparePartQuotationLine[];
    alternativeSpareParts: Record<
      string,
      Record<string, SparePartSearchSummary>
    >;
  }): Promise<UpdateQuotationSparePartSelectionCommandArgs> => {
    return {
      quotationId,
      quotationSpareParts: await Promise.all(
        (sparePartQuotationLines || [])
          .filter((sparePartQuotationLine) => {
            return values.sparePartLines[sparePartQuotationLine.id];
          })
          .map(async (sparePartQuotationLine) => {
            const concernedAlternativeSpareParts = Object.values(
              alternativeSpareParts[sparePartQuotationLine.sparePartId],
            );

            const sparePartQuotationFormLine =
              values.sparePartLines[sparePartQuotationLine.id];

            const isOptionalLineUnselected =
              !sparePartQuotationFormLine.condition &&
              !sparePartQuotationFormLine.isSelectedForRepair &&
              !sparePartQuotationLine.isRequiredForRepair;

            if (isOptionalLineUnselected) {
              return {
                sparePartId: sparePartQuotationLine.sparePartId,
                id: sparePartQuotationLine.id,
                isRequiredForRepair: sparePartQuotationLine.isRequiredForRepair,
                isSelectedForRepair:
                  sparePartQuotationFormLine.isSelectedForRepair,
                recipient: {
                  recipientType: sparePartQuotationLine.recipient?.data,
                },
                supplierId: sparePartQuotationLine.sparePartSupplierId,
                sparePart: {
                  requestLineId: sparePartQuotationLine.requestLineId,
                },
              } as UpdateQuotationOptionalLineUnavailableSparePart;
            }

            const sparePart = concernedAlternativeSpareParts
              .sort(
                sortBy({
                  ascending: true,
                  fieldName: 'recommendedSalePriceIncludingTaxes.amount',
                }),
              )
              .find((part) => {
                return (
                  part.sparePartId === sparePartQuotationLine.sparePartId &&
                  part.condition === sparePartQuotationFormLine.condition
                );
              });

            if (
              !sparePart ||
              !sparePartQuotationLine.requestLineId ||
              sparePartQuotationLine.isRequiredForRepair === undefined ||
              !sparePartQuotationLine.recipient ||
              !sparePartQuotationFormLine.condition
            ) {
              throw new Error('Invalid state.');
            }

            const sellingPriceResponse = await getSellingPrice({
              preTaxBuyingPrice: sparePart.pretaxPurchasePrice.amount,
            });

            if (!sellingPriceResponse?.sellingPriceIncludedTax) {
              throw new Error(
                `Unable to get sellingPrice for ${sellerId} ${sparePart.pretaxPurchasePrice}`,
              );
            }

            return {
              id: sparePartQuotationLine.id,
              sparePartId: sparePartQuotationLine.sparePartId,
              isRequiredForRepair: sparePartQuotationLine.isRequiredForRepair,
              isSelectedForRepair:
                sparePartQuotationFormLine.isSelectedForRepair,
              recipient: {
                recipientType: sparePartQuotationLine.recipient.data,
              },
              supplierId: sparePart.sparePartSupplierId,
              sparePart: {
                sparePartSupplierAssociationId:
                  sparePart.sparePartSupplierAssociationId,
                supplierReference: sparePart.supplierPartReference,
                supplierId: sparePart.sparePartSupplierId,
                preTaxUnitBuyingPrice: sparePart.pretaxPurchasePrice,
                preTaxUnitPrice: {
                  amount: sellingPriceResponse.sellingPriceIncludedTax,
                  currency: sparePart.pretaxPurchasePrice.currency,
                },
                preTaxAmount: {
                  amount: sellingPriceResponse.sellingPriceIncludedTax,
                  currency: sparePart.pretaxPurchasePrice.currency,
                },
                amountIncludedTax: sparePart.recommendedSalePriceIncludingTaxes,
                quantity: 1,
                requestLineId: sparePartQuotationLine.requestLineId,
                condition: sparePartQuotationFormLine.condition,
                supplierName: sparePartQuotationLine.supplierName,
                manufacturerName: sparePartQuotationLine.manufacturerLabel,
                manufacturerReference:
                  sparePartQuotationLine.manufacturerReference,
                minDeliveryDays: sparePart.minDeliveryDays,
                maxDeliveryDays: sparePart.maxDeliveryDays,
                stockName: sparePart.stockName,
              },
            };
          }),
      ),
    };
  };

  return { prepareUpdateQuotationSparePartSelectionCommandPayload };
};

export const useSelectSparePartQuotationLineCondition = ({
  quotation,
  sellerId,
}: {
  quotation: QuotationPendingCustomerValidationNewModel;
  sellerId: string;
}) => {
  const { pushErrors } = useToasts();

  const { prepareUpdateQuotationSparePartSelectionCommandPayload } =
    usePrepareUpdateQuotationSparePartSelectionCommandPayload({
      sellerId,
    });

  const {
    isLoading: isSelectSparePartConditionLoading,
    mutateAsync: handleSelectSparePartQuotationLineConditionChange,
  } = useMutation(
    [],
    async ({
      values,
      alternativeSpareParts,
    }: {
      values: QuotationPendingCustomerValidationForm;
      alternativeSpareParts: Record<
        string,
        Record<string, SparePartSearchSummary>
      >;
    }) => {
      const payload =
        await prepareUpdateQuotationSparePartSelectionCommandPayload({
          values,
          quotationId: quotation.id,
          sparePartQuotationLines: quotation.sparePartLines,
          alternativeSpareParts,
        });

      const response =
        await CommonRepairerService.updateQuotationSparePartSelectionCommand(
          payload,
        );

      if (response.failure) {
        pushErrors(response.errors);

        return response;
      }

      return response;
    },
  );

  return {
    isSelectSparePartConditionLoading,
    handleSelectSparePartQuotationLineConditionChange,
  };
};

export const useQuotationPendingCustomerValidationSearchSparePartAvailability =
  ({
    quotation,
    sellerId,
  }: {
    quotation: AdaptedQuotationNewModel;
    sellerId: string;
  }) => {
    const { pushErrors } = useToasts();

    const { data: sparePartLines, isLoading } = useQuery(
      ['QuotationPendingCustomerValidationSearchSparePartAvailability'],
      async () => {
        const sparePartLines = await Promise.all(
          quotation.sparePartLines.map(async (sparePartLine) => {
            const response =
              await CommonSparePartsService.searchSparePartsByNames({
                manufacturerName: sparePartLine.manufacturerName,
                supplierName: sparePartLine.supplierName,
                supplierReference: sparePartLine.supplierReference,
                manufacturerReference: sparePartLine.manufacturerReference,
                sellerId,
                stockName: sparePartLine.stockName,
              });

            if (response.failure) {
              pushErrors(response.errors);

              throw new Error(`Unable to search spare parts status.`);
            }

            const selectedSparePart = response.value.find((sparePart) => {
              return (
                sparePart.sparePartId === sparePartLine.sparePartId &&
                sparePart.sparePartSupplierId ===
                  sparePartLine.sparePartSupplierId &&
                sparePart.condition === sparePartLine.condition &&
                sparePart.stockName === sparePartLine.stockName
              );
            });

            return {
              ...sparePartLine,
              condition: selectedSparePart
                ? sparePartLine.condition
                : undefined,
              supplierSparePartId: selectedSparePart?.sparePartSupplierId,
              alternativeSpareParts: response.value,
            };
          }),
        );

        const newLines = sparePartLines.map((line) => {
          return {
            id: line.id,
            excludingTaxPurchasePriceEur: line.preTaxUnitBuyingPrice,
            recommendedSalePriceIncludingTaxes:
              line.recommendedSalePriceIncludingTaxes,
            sparePartId: line.sparePartId,
            sparePartSupplierId: line.sparePartSupplierId,
            supplierName: line.supplierName,
            supplierReference: line.supplierReference,
            manufacturerReference: line.manufacturerReference,
            manufacturerId: line.manufacturerId,
            manufacturerLabel: line.manufacturerLabel,
            manufacturerName: line.manufacturerName,
            alternativeSpareParts: line.alternativeSpareParts,
            condition: line.condition,
            sparePartSupplierAssociationId: line.sparePartSupplierAssociationId,
            isRequiredForRepair: line.isRequiredForRepair,
            isSelectedForRepair: line.isSelectedForRepair,
            isUncertain: line.isRequiredForRepair,
            recipient: line.recipient,
            subCategory: line.subCategory,
            requestLineId: line.requestLineId,
            stockName: line.stockName,
          };
        });

        return newLines;
      },
    );

    return {
      sparePartLines,
      isLoading,
    };
  };

export const useQuotationPendingCustomerValidation = ({
  quotation,
  sellerId,
  onAnswerSuccess,
  fileId,
  holderType,
}: {
  quotation: AdaptedQuotationNewModel;
  sellerId: string;
  onAnswerSuccess?: () => void;
  fileId: string;
  holderType: HolderType;
}) => {
  const formContext = useForm<QuotationPendingCustomerValidationForm>({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    mode: REVALIDATE_MODES.ON_CHANGE,
    reValidateMode: REVALIDATE_MODES.ON_CHANGE,
  });

  const { sparePartLines } =
    useQuotationPendingCustomerValidationSearchSparePartAvailability({
      quotation,
      sellerId,
    });

  const adaptedQuotation = {
    ...quotation,
    sparePartLines,
  };

  const optionalSpareParts = adaptedQuotation.sparePartLines
    ? adaptedQuotation.sparePartLines.filter(
        (sparePart) => !sparePart.isRequiredForRepair,
      )
    : undefined;
  const requiredForRepaidSpareParts = adaptedQuotation.sparePartLines
    ? adaptedQuotation.sparePartLines.filter(
        (sparePart) => sparePart.isRequiredForRepair,
      )
    : undefined;

  const {
    isSelectSparePartConditionLoading,
    handleSelectSparePartQuotationLineConditionChange,
  } = useSelectSparePartQuotationLineCondition({
    quotation: adaptedQuotation,
    sellerId,
  });

  const {
    isConfirmRejectModalOpen,
    isRejectLoading,
    handleRejectButtonClick,
    handleRejectWithReturn,
    handleRejectWithDestruction,
    handleCancelConfirmReject,
  } = useRejectQuotation({
    quotationId: adaptedQuotation.id,
    fileId,
    onAnswerSuccess,
    holderType,
  });
  const { handleCancelQuotation, isCancelQuotationLoading } =
    useCancelQuotation({
      quotationId: adaptedQuotation.id,
      fileId,
      onAnswerSuccess,
    });
  const { handleValidate, isValidateLoading } = useValidateQuotation({
    quotationId: adaptedQuotation.id,
    fileId,
    onAnswerSuccess,
  });

  return {
    formContext,
    adaptedQuotation,
    handleValidate,
    isValidateLoading,
    optionalSpareParts,
    handleCancelQuotation,
    isCancelQuotationLoading,
    isConfirmRejectModalOpen,
    isRejectLoading,
    handleRejectButtonClick,
    handleRejectWithReturn,
    handleRejectWithDestruction,
    handleCancelConfirmReject,
    isSelectSparePartConditionLoading,
    handleSelectSparePartQuotationLineConditionChange,
    requiredForRepaidSpareParts,
  };
};
