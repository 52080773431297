import React, { FunctionComponent, useContext } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import { $ActionRow } from '../AppointmentCalendarContainer.styles';

import {
  $AppointmentCalendar,
  AppointmentCalendarContainer,
  LeftArrowButton,
  RightArrowButton,
} from './AppointmentCalendar.styles';
import { AppointmentCalendarScreen } from './AppointmentCalendarScreen';
import messages from './messages';
import { BannersProviderContext } from '../../../../../../molecules/NotificationsProvider';
import Button from '../../../../../../atoms/button/Button';
import { SafeFormattedMessageWithoutSpread } from '../../../../../../formatters/SafeFormattedMessageWithoutSpread';
import { AppointmentCalendarLoader } from './AppointmentCalendarLoader';
import { useTakeInterventionAppointmentContext } from '../../TakeInterventionAppointment.context';

interface AppointmentCalendarProps {
  isActive?: boolean;
  isGetTimeslotsLoading: boolean;
  isCreateTechnicalInterventionLoading: boolean;
  isLoading: boolean;
  isNextPageDisabled: boolean;
  onValidateSchedule: () => void;
}

const AppointmentCalendar: FunctionComponent<
  React.PropsWithChildren<AppointmentCalendarProps>
> = ({
  isActive = true,
  isGetTimeslotsLoading,
  children,
  isCreateTechnicalInterventionLoading,
  isLoading,
  isNextPageDisabled,
  onValidateSchedule,
}) => {
  const {
    selectedTimeslot,
    pageNumber,
    handlePageChange,
    detailedSchedules,
    hasErrors,
  } = useTakeInterventionAppointmentContext();
  const { notifications } = useContext(BannersProviderContext);

  const isCurrentWeekEmpty = (detailedSchedules || []).every(
    ({ timeslots = [] }) =>
      timeslots.filter((timeslot) => !!timeslot).length === 0,
  );
  const isValidateScheduleDisabled =
    !detailedSchedules ||
    detailedSchedules?.length === 0 ||
    isGetTimeslotsLoading;

  if (isGetTimeslotsLoading) {
    return <AppointmentCalendarLoader />;
  }

  return (
    <$AppointmentCalendar>
      <AppointmentCalendarContainer>
        <Button
          type={BUTTON_TYPES.BUTTON}
          onClick={() => {
            handlePageChange({ newPageNumber: pageNumber - 1 });
          }}
          icon={<LeftArrowButton />}
          disabled={pageNumber === 1}
          dataTestId="previousScreenButton"
          primary
          naked
          small
        />

        <AppointmentCalendarScreen isActive={isActive} />

        <Button
          type={BUTTON_TYPES.BUTTON}
          onClick={() => {
            handlePageChange({ newPageNumber: pageNumber + 1 });
          }}
          icon={<RightArrowButton />}
          disabled={isNextPageDisabled}
          dataTestId="nextScreenButton"
          primary
          naked
          small
        />
      </AppointmentCalendarContainer>

      <$ActionRow>
        {children}

        {!isCurrentWeekEmpty && notifications.length === 0 && (
          <Button
            disabled={isValidateScheduleDisabled || !selectedTimeslot}
            isLoading={isLoading || isCreateTechnicalInterventionLoading}
            primary
            type={BUTTON_TYPES.BUTTON}
            isError={hasErrors}
            onClick={onValidateSchedule}
            dataTestId="validateSchedule"
          >
            <SafeFormattedMessageWithoutSpread message={messages.validate} />
          </Button>
        )}

        {notifications.length > 0 && (
          <Button
            isLoading={isLoading || isCreateTechnicalInterventionLoading}
            primary
            type={BUTTON_TYPES.BUTTON}
            onClick={() => {
              window.location.reload();
            }}
          >
            <SafeFormattedMessageWithoutSpread message={messages.tryAgain} />
          </Button>
        )}
      </$ActionRow>
    </$AppointmentCalendar>
  );
};

AppointmentCalendar.displayName = 'AppointmentCalendar';

export default AppointmentCalendar;
