import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE, push } from 'redux-first-history';
import { put, select, take, takeEvery } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { ROUTES } from '../../view/app/routes/Routes';
import {
  ActionCreators as ClaimActionCreators,
  ActionTypes as ClaimActionTypes,
  Selectors as ClaimSelectors,
} from '../Claim';
import { Selectors as CustomerSelectors } from '../Customer';
import { LOGIN_SIGNEDIN } from '../Login/loginConst';

export function* checkClaimWorker({ payload }) {
  const { pathname } = payload;

  let userId = yield select(CustomerSelectors.ownerIdSelector);

  if (!userId) {
    yield take(LOGIN_SIGNEDIN);
    userId = yield select(CustomerSelectors.ownerIdSelector);
  }
  const checkParams = matchPath(pathname, {
    path: ROUTES.CLAIM_DETAILS,
    exact: false,
    strict: false,
  });

  if (checkParams) {
    const { claimId } = checkParams.params;

    yield put(ClaimActionCreators.getClaim(claimId));
    yield take(ClaimActionTypes.GET_CLAIM.END);
    const isChecked = yield select(ClaimSelectors.isChecked);
    const claimOwnerId = yield select(ClaimSelectors.claimOwnerId);

    if (!isChecked || claimOwnerId !== userId) {
      yield put(push(ROUTES.PAGE_NOT_FOUND));
    }
  }
}

export default function* locationChangeWatcher() {
  try {
    yield takeEvery(LOCATION_CHANGE, checkClaimWorker);
  } catch (error) {
    logCritical(error);
  }
}
