import React, { FC } from 'react';

import { StatelessQuotationTotal } from '../../StatelessQuotationTotal/StatelessQuotationTotal';
import { QuotationPendingCustomerValidationNewModel } from '../QuotationPendingCustomerValidation.types';

import { useCalculateQuotationPendingCustomerValidationTotals } from './hooks/useCalculateQuotationPendingCustomerValidationTotals';

interface QuotationTotalProps {
  quotation: QuotationPendingCustomerValidationNewModel;
  fileId: string;
}

const QuotationTotal: FC<QuotationTotalProps> = ({ quotation, fileId }) => {
  const {
    subtotalVat,
    subtotalPreVat,
    totalDiscountIncludedTax,
    alreadyPaid,
    totalAmountToBePaid,
    bonus,
  } = useCalculateQuotationPendingCustomerValidationTotals({
    quotation,
    fileId,
  });

  return (
    <StatelessQuotationTotal
      alreadyPaid={alreadyPaid}
      bonus={bonus}
      subtotalPreVat={subtotalPreVat}
      subtotalVat={subtotalVat}
      totalAmountToBePaid={totalAmountToBePaid}
      totalDiscountIncludedTax={totalDiscountIncludedTax}
    />
  );
};

QuotationTotal.displayName = 'QuotationTotal';

export default QuotationTotal;
