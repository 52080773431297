import { defineMessages } from 'react-intl';

export default defineMessages({
  titleNotEligible: {
    id: 'components.myaccount.claims.issues.titleNotEligible',
    defaultMessage: 'Product(s) not suited for withdrawal',
  },
  noProduct: {
    id: 'components.myaccount.order.noProduct',
    defaultMessage: "Vous n'avez actuellement aucune commande...",
  },
  back: {
    id: 'components.myaccount.order.goBackToSellerWebsite',
    defaultMessage: 'Return to website',
  },
  products: {
    id: 'components.myaccount.order.products',
    defaultMessage: 'My orders',
  },
  oneProduct: {
    id: 'components.myaccount.order.oneProduct',
    defaultMessage: 'My order N° {order}',
  },
  productWithClaim: {
    id: 'components.myaccount.order.productWithClaim',
    defaultMessage: 'Product with claim(s)',
  },
  otherProduct: {
    id: 'components.myaccount.order.otherProduct',
    defaultMessage: 'Other product(s)',
  },
});
