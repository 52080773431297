import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

import { getCustomComponentProperty } from '../../helpers';
import { useGetSvg } from '../../hooks';

const getIconColors = (props) => {
  const { componentThemeName, theme } = props;

  const customColor = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.COLOR,
  });

  if (customColor) {
    return customColor;
  }

  return theme.colors.iconColor;
};

const SvgContainer = styled('div')`
  svg {
    mask {
      fill: ${getIconColors};
    }
    use {
      stroke: ${getIconColors};
    }
    g {
      stroke: ${getIconColors};
    }
  }
`;

const InjectSvg = ({ iconUrl, componentThemeName, iconUrlFallback }) => {
  const [url, setUrl] = useState(iconUrl);
  const ref = useRef(null);
  const [svg, svgIsError] = useGetSvg({ url });

  // svg change
  useEffect(() => {
    setUrl(iconUrl);
  }, [iconUrl]);

  // svg fallback
  useEffect(() => {
    if (svgIsError && iconUrlFallback) {
      setUrl(iconUrlFallback);
    }
  }, [svgIsError, iconUrlFallback]);

  // svg inject in dom
  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = svg;
    }
  }, [url, svg]);

  return <SvgContainer componentThemeName={componentThemeName} ref={ref} />;
};

InjectSvg.propTypes = {
  iconUrl: PropTypes.string.isRequired,
  componentThemeName: PropTypes.string,
  iconUrlFallback: PropTypes.string,
};
InjectSvg.defaultProps = {
  componentThemeName: null,
  iconUrlFallback: null,
};

InjectSvg.displayName = 'InjectSvg';

export default InjectSvg;
