import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import React, { FunctionComponent, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { RadioButtonHookForm } from '@savgroup-front-common/core/src/molecules/Form';

import {
  ADDRESS_MANAGEMENT_FIELDS,
  AddressManagementFieldValues,
} from '../AddressManagement.types';

import messages from './messages';
import suggestedAddressErrorMessage from './SuggestedAddressModal.helpers';
import {
  $AddressRow,
  $ModalRowWithMargin,
  $NextButtonModal,
  $SuggestionTitle,
} from './SuggestedAddressModal.styles';

interface SuggestedAddressModalContentProps {
  suggestedAddress: AddressManagementFieldValues;
  addressErrors: {
    address?: string;
  };
  onClose: () => void;
  isLoading: boolean;
  onSuggestedAddressModalSubmit: () => void;
}

const SuggestedAddressModalContent: FunctionComponent<
  React.PropsWithChildren<SuggestedAddressModalContentProps>
> = ({
  suggestedAddress,
  addressErrors,
  onClose,
  isLoading,
  onSuggestedAddressModalSubmit,
}) => {
  const formContext = useFormContext<AddressManagementFieldValues>();
  const errorMessage = suggestedAddressErrorMessage({
    error: addressErrors?.address,
  });
  const { register, control, setValue } = formContext;
  const [firstName, lastName, address, additionalAddress, postalCode, city] =
    useWatch({
      control,
      name: [
        ADDRESS_MANAGEMENT_FIELDS.FIRST_NAME,
        ADDRESS_MANAGEMENT_FIELDS.LAST_NAME,
        ADDRESS_MANAGEMENT_FIELDS.ADDRESS,
        ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_ADDRESS,
        ADDRESS_MANAGEMENT_FIELDS.POSTAL_CODE,
        ADDRESS_MANAGEMENT_FIELDS.CITY,
      ],
    });

  useEffect(() => {
    setTimeout(() => {
      setValue(ADDRESS_MANAGEMENT_FIELDS.KEEP_SUGGESTED_ADDRESS, 'true');
    });
  }, [setValue]);

  const handleEditAddress = () => {
    if (!suggestedAddress) {
      onClose();

      return;
    }
    const { address, city, postalCode } = suggestedAddress;

    setValue(ADDRESS_MANAGEMENT_FIELDS.ADDRESS, address);
    setValue(ADDRESS_MANAGEMENT_FIELDS.POSTAL_CODE, postalCode);
    setValue(ADDRESS_MANAGEMENT_FIELDS.CITY, city);
    onClose();
  };

  return (
    <>
      <$ModalRowWithMargin>
        <Heading level={2}>
          <SafeFormattedMessageWithoutSpread
            message={messages.suggestionModalTitle}
          />
        </Heading>
      </$ModalRowWithMargin>
      {isEmpty(addressErrors) ? (
        <RadioButtonHookForm
          isFluid
          name={ADDRESS_MANAGEMENT_FIELDS.KEEP_SUGGESTED_ADDRESS}
          register={register}
          value="true"
          label={
            <>
              <$AddressRow>
                <$SuggestionTitle>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.suggestedAddress}
                  />
                </$SuggestionTitle>
                <div>
                  {suggestedAddress.firstName} {suggestedAddress.lastName}
                </div>
                {filter([
                  suggestedAddress.address,
                  suggestedAddress.additionalAddress,
                ]).join(', ')}
                <br />
                {suggestedAddress.postalCode} {suggestedAddress.city}
              </$AddressRow>
              <Button
                type={BUTTON_TYPES.BUTTON}
                underline
                primary
                onClick={handleEditAddress}
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.editAddress}
                />
              </Button>
            </>
          }
        />
      ) : (
        <div>
          <div>
            <SafeFormattedMessageWithoutSpread message={errorMessage} />
          </div>
          <div>
            <SafeFormattedMessageWithoutSpread
              message={messages.sureToContinue}
            />
          </div>
        </div>
      )}

      <RadioButtonHookForm
        isFluid
        value="false"
        name={ADDRESS_MANAGEMENT_FIELDS.KEEP_SUGGESTED_ADDRESS}
        label={
          <>
            <$AddressRow data-deliveryaddress-row>
              <$SuggestionTitle>
                <SafeFormattedMessageWithoutSpread
                  message={messages.deliveryAddress}
                />
              </$SuggestionTitle>
              <div>
                {firstName} {lastName}
              </div>
              {filter([address, additionalAddress]).join(', ')}
              <br />
              {postalCode} {city}
            </$AddressRow>
            <Button
              type={BUTTON_TYPES.BUTTON}
              underline
              primary
              onClick={onClose}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.editAddress}
              />
            </Button>
          </>
        }
        register={register}
      />

      <$NextButtonModal xs={4}>
        <Button
          type={BUTTON_TYPES.BUTTON}
          dataTestId="nextstepsuggestionmodal"
          isLoading={isLoading}
          fluid
          primary
          onClick={onSuggestedAddressModalSubmit}
        >
          <SafeFormattedMessageWithoutSpread message={messages.next} />
        </Button>
      </$NextButtonModal>
    </>
  );
};

SuggestedAddressModalContent.displayName = 'SuggestedAddressModalContent';

export default SuggestedAddressModalContent;
