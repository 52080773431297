import React from 'react';

interface PhoneIconProps {
  color?: string;
}

/* eslint-disable max-len */
const PhoneIcon: React.FC<PhoneIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.029 30.8881C25.642 31.4454 25.6872 32.3941 25.1299 33.007L25.1099 33.0291C24.5527 33.642 23.604 33.6872 22.991 33.13C22.378 32.5727 22.3328 31.6241 22.8901 31.0111L22.9101 30.9891C23.4673 30.3761 24.416 30.3309 25.029 30.8881Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 9.5V38.5H32.5V9.5H15.5ZM13.2908 7.29081C13.7972 6.78446 14.4839 6.5 15.2 6.5H32.8C33.1546 6.5 33.5057 6.56984 33.8332 6.70553C34.1608 6.84121 34.4585 7.04009 34.7092 7.29081C34.9599 7.54153 35.1588 7.83917 35.2945 8.16675C35.4302 8.49434 35.5 8.84544 35.5 9.2V38.8C35.5 39.1546 35.4302 39.5057 35.2945 39.8332C35.1588 40.1608 34.9599 40.4585 34.7092 40.7092C34.4585 40.9599 34.1608 41.1588 33.8332 41.2945C33.5057 41.4302 33.1546 41.5 32.8 41.5H15.2C14.4839 41.5 13.7972 41.2155 13.2908 40.7092C12.7845 40.2028 12.5 39.5161 12.5 38.8V9.2C12.5 8.48391 12.7845 7.79716 13.2908 7.29081Z"
        fill={color}
      />
    </svg>
  );
};

PhoneIcon.displayName = 'PhoneIcon';

export default PhoneIcon;
