import { DefaultTheme } from 'styled-components';

import { FIELD_STATUS, InputStatus } from './getFinalFieldState.types';

export function getFieldColorFromStatus({
  status,
  theme,
}: {
  status: InputStatus;
  theme: DefaultTheme;
}) {
  switch (status) {
    case FIELD_STATUS.ERROR:
      return theme.colors.danger;
    case FIELD_STATUS.WARNING:
      return theme.colors.alert;
    case FIELD_STATUS.SUCCESS:
      return theme.colors.success;
    default:
      return null;
  }
}
