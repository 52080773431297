/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import {
  SparePartQuotationLine,
  SparePartSearchSummary,
} from '@savgroup-front-common/types';

import { SafeFormattedMessageWithoutSpread } from '../../../../formatters';
import { formatReverseMoneyToString } from '../../../../formatters/intl';
import { getRecipientTypeMessage } from '../../../../helpers/i18n';
import { $AmountCell } from '../../QuotationLines';
import { QuotationLine } from '../../QuotationLines/QuotationLine';
import { useQuotationPendingCustomerValidationContext } from '../QuotationPendingCustomerValidation.context';
import { QuotationPendingCustomerValidationForm } from '../QuotationPendingCustomerValidation.types';

import { ConditionSelection } from './ConditionSelection';
import { OptionalLineSelection } from './OptionalLineSelection';
import { SparePartQuotationPendingCustomerValidationLineMobileView } from './SparePartQuotationPendingCustomerValidationLineMobileView';

interface SparePartQuotationPendingCustomerValidationLineProps {
  sparePartQuotationLine: SparePartQuotationLine;
  onSelectSparePartQuotationLineChange: ({
    values,
    alternativeSpareParts,
  }: {
    values: QuotationPendingCustomerValidationForm;
    alternativeSpareParts: Record<
      string,
      Record<string, SparePartSearchSummary>
    >;
  }) => Promise<{ failure: boolean }>;
  shouldShowRecipientColumn?: boolean;
  index: number;
  preDataTestId?: string;
}

const SparePartQuotationPendingCustomerValidationLine: FC<
  SparePartQuotationPendingCustomerValidationLineProps
> = ({
  sparePartQuotationLine,
  onSelectSparePartQuotationLineChange,
  shouldShowRecipientColumn = true,
  index,
  preDataTestId = 'sparePart',
}) => {
  const { alternativeSpareParts } =
    useQuotationPendingCustomerValidationContext();
  const isMobileView = useMedia(media.maxWidth.sm);

  const formContext = useFormContext<QuotationPendingCustomerValidationForm>();
  const { watch } = formContext;

  const sparePartQuotationFormLine =
    watch('sparePartLines')?.[sparePartQuotationLine.id];

  const concernedAlternativeSpareParts = Object.values(
    alternativeSpareParts[sparePartQuotationLine.sparePartId] || {},
  );

  const sparePart = concernedAlternativeSpareParts.find((part) => {
    return (
      part.sparePartId === sparePartQuotationLine.sparePartId &&
      part.sparePartSupplierId ===
        sparePartQuotationFormLine?.sparePartSupplierId &&
      part.condition === sparePartQuotationFormLine?.condition &&
      part.stockName === sparePartQuotationFormLine?.stockName
    );
  });

  const isSelected = watch(
    `sparePartLines.${sparePartQuotationLine.id}.isSelectedForRepair`,
  );

  if (isMobileView) {
    return (
      <SparePartQuotationPendingCustomerValidationLineMobileView
        sparePartQuotationLine={sparePartQuotationLine}
        isSelected={isSelected}
        sparePart={sparePart}
        shouldShowRecipientColumn={shouldShowRecipientColumn}
        onSelectSparePartQuotationLineChange={
          onSelectSparePartQuotationLineChange
        }
        preDataTestId={preDataTestId}
        index={index}
      />
    );
  }

  return (
    <QuotationLine
      key={sparePartQuotationLine.id}
      isSelected={isSelected}
      isActive
    >
      <td colSpan={shouldShowRecipientColumn ? 3 : 4}>
        <>
          {!sparePartQuotationLine.isRequiredForRepair && (
            <OptionalLineSelection
              sparePartQuotationLine={sparePartQuotationLine}
              onOptionalLineSelectionChange={
                onSelectSparePartQuotationLineChange
              }
              preDataTestId={preDataTestId}
              index={index}
            />
          )}
          {sparePartQuotationLine.isRequiredForRepair && (
            <span
              data-testid={`${preDataTestId}_subCategory_supplierReference_${index}`}
            >
              {' '}
              {sparePartQuotationLine.manufacturerLabel}
            </span>
          )}
        </>
      </td>
      <td>
        <ConditionSelection
          sparePartQuotationLine={sparePartQuotationLine}
          onConditionChange={onSelectSparePartQuotationLineChange}
          dataTestId={`${preDataTestId}_condition_${index}`}
        />
      </td>
      {shouldShowRecipientColumn && (
        <td data-testid={`${preDataTestId}_recipient_${index}`}>
          {sparePartQuotationLine.recipient?.data && (
            <SafeFormattedMessageWithoutSpread
              message={getRecipientTypeMessage(
                sparePartQuotationLine.recipient?.data,
              )}
            />
          )}
        </td>
      )}
      {sparePart && (
        <$AmountCell
          data-testid={`${preDataTestId}_recommendedSalePriceIncludingTaxes_${index}`}
        >
          {formatReverseMoneyToString(
            sparePart.recommendedSalePriceIncludingTaxes,
          )}
        </$AmountCell>
      )}
    </QuotationLine>
  );
};

SparePartQuotationPendingCustomerValidationLine.displayName =
  'SparePartQuotationPendingCustomerValidationLine';

export default SparePartQuotationPendingCustomerValidationLine;
