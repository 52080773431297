import React, { FunctionComponent, ReactEventHandler } from 'react';

import {
  BUTTON_TYPES,
  PERMISSIONS,
} from '@savgroup-front-common/constants/src';

import { useHasSomePermissions } from '../../hooks';

import { $TabButton } from './Tabs.styles';

interface BaseTabButtonProps {
  isActive: boolean;
  count: number;
  dataTestId?: string;
  permissions: PERMISSIONS[];
  hasError?: boolean;
  onClick: ReactEventHandler;
}

const TabButton: FunctionComponent<
  React.PropsWithChildren<BaseTabButtonProps>
> = ({
  children,
  permissions,
  isActive,
  count = 0,
  dataTestId,
  onClick,
  hasError = false,
}) => {
  const hasAtLeastOneRequiredPermissions = useHasSomePermissions(permissions);

  if (!hasAtLeastOneRequiredPermissions && permissions.length > 0) {
    return <></>;
  }

  return (
    <$TabButton
      $isActive={isActive}
      $count={count}
      data-testid={dataTestId}
      onClick={onClick}
      $hasErrors={hasError}
      type={BUTTON_TYPES.BUTTON}
    >
      {children}
    </$TabButton>
  );
};

TabButton.displayName = 'TabLink';

export default TabButton;
