export enum ADDRESS_MANAGEMENT_FIELDS {
  FIRST_NAME = 'addressSelected.firstName',
  LAST_NAME = 'addressSelected.lastName',
  CIVILITY = 'addressSelected.civility',
  COMPANY_NAME = 'addressSelected.companyName',
  PHONE = 'addressSelected.phone',
  MAIL = 'addressSelected.mail',
  COUNTRY_CODE = 'addressSelected.countryCode',
  ADDRESS = 'addressSelected.address',
  CITY = 'addressSelected.city',
  ADDITIONAL_ADDRESS = 'addressSelected.additionalAddress',
  POSTAL_CODE = 'addressSelected.postalCode',
  HAS_ELEVATOR = 'addressSelected.hasElevator',
  HAS_PARKING_SPACE = 'addressSelected.hasParkingSpace',
  HOUSING_TYPE = 'addressSelected.housingType',
  FLOOR = 'addressSelected.floor',
  DOOR_CODE = 'addressSelected.doorCode',
  ADDITIONAL_INFORMATION = 'addressSelected.additionalInformation',
}

export interface AddressManagementFieldValues {
  [ADDRESS_MANAGEMENT_FIELDS.FIRST_NAME]: string;
  [ADDRESS_MANAGEMENT_FIELDS.LAST_NAME]: string;
  [ADDRESS_MANAGEMENT_FIELDS.CIVILITY]: string;
  [ADDRESS_MANAGEMENT_FIELDS.COMPANY_NAME]: string;
  [ADDRESS_MANAGEMENT_FIELDS.PHONE]: string;
  [ADDRESS_MANAGEMENT_FIELDS.COUNTRY_CODE]: string;
  [ADDRESS_MANAGEMENT_FIELDS.ADDRESS]: string;
  [ADDRESS_MANAGEMENT_FIELDS.CITY]: string;
  [ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_ADDRESS]: string;
  [ADDRESS_MANAGEMENT_FIELDS.POSTAL_CODE]: string;
  [ADDRESS_MANAGEMENT_FIELDS.HAS_ELEVATOR]: boolean;
  [ADDRESS_MANAGEMENT_FIELDS.HAS_PARKING_SPACE]: boolean;
  [ADDRESS_MANAGEMENT_FIELDS.HOUSING_TYPE]: string;
  [ADDRESS_MANAGEMENT_FIELDS.FLOOR]: string;
  [ADDRESS_MANAGEMENT_FIELDS.DOOR_CODE]: string;
  [ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_INFORMATION]: string;
}

export interface AddressManagementValues {
  firstName: string;
  lastName: string;
  civility: string;
  companyName: string;
  phone: string;
  countryCode: string;
  address: string;
  city: string;
  additionalAddress: string;
  postalCode: string;
  hasElevator: boolean;
  hasParkingSpace: boolean;
  housingType: string;
  floor: string;
  doorCode: string;
  additionalInformation: string;
}
