import React, { FC, PropsWithChildren } from 'react';

import { ConfirmModal } from '../../molecules/ConfirmModal';
import { MODAL_SIZES } from '../../atoms/modal';
import messages from '../Quotation/QuotationPendingCustomerValidation/messages';
import { useConfirmationContext } from './ConfirmationContextProvider';

interface ConfirmationProps {
  onClick: (status: boolean) => void;
}

export const Confirmation: FC<PropsWithChildren<ConfirmationProps>> =
  React.memo(({ children, onClick }) => {
    const { title, didactic, isOpen } = useConfirmationContext();

    return (
      <ConfirmModal
        isOpen={isOpen}
        size={MODAL_SIZES.SMALL}
        title={title}
        didactic={didactic}
        cancelMessage={messages.cancel}
        confirmMessage={messages.confirmActionButton}
        onCancel={() => onClick(false)}
        onConfirm={() => onClick(true)}
      >
        {children}
      </ConfirmModal>
    );
  });

Confirmation.displayName = 'Confirmation';
