import React, { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  CURRENCIES,
  HANDLING_GROUPS,
  HANDLING_MODES,
} from '@savgroup-front-common/constants';
import { StepProps } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import {
  NEED_HELP_POSITION_TYPES,
  NeedHelp,
} from '@savgroup-front-common/core/src/components/NeedHelp';
import { AuthConfiguration } from 'myaccount/configuration';

import { SubmitSelectCarrierTypeForm } from '../SelectCarrierType/SelectCarrierType.types';

import { AddressManagement, TimerSlotModal } from './AddressManagement';
import { CarrierFormValues } from './CarrierForm.types';
import { useGetHomePickup } from './hooks';
import useGetPickupPoints from './hooks/useGetPickupPoints';
import useHandleSubmitPickupPoints from './hooks/useHandleSubmitPickupPoints';
import PickupPointSelector from './PickupPointSelector';

type CarrierFormProps = React.PropsWithChildren<
  StepProps<
    SubmitSelectCarrierTypeForm,
    Record<string, unknown>,
    CarrierFormValues
  >
>;

const CarrierForm: FunctionComponent<CarrierFormProps> = ({
  values,
  previousStep,
  nextStep,
  jumpToStep,
}) => {
  const { selectedCarrierType, groupedCarriers, handlingMode, claimGroupId } =
    values;
  const {
    carriers,
    addressSelected,
    pickupPoints,
    addresses,
    handleSetAddressSelected,
    selectedPickupPoint,
    handleSetSelectedPickupPoint,
    pickupPointsIsLoading,
  } = useGetPickupPoints({
    selectedCarrierType,
    groupedCarriers,
    claimGroupId,
  });

  const {
    verifiedAddressIsLoaded,
    addressErrors,
    homePickupAddress,
    handleAddressChange,
    handleSubmitHomePickup,
  } = useGetHomePickup({
    handlingMode,
    groupedCarriers,
    selectedCarrierType,
    nextStep,
    jumpToStep,
  });

  const formContext = useForm();

  const isHomePickup =
    selectedCarrierType === HANDLING_GROUPS.HOME &&
    handlingMode.toLowerCase() === HANDLING_MODES.DEPOSIT.toLowerCase();

  const handleSubmitPickupPoints = useHandleSubmitPickupPoints({
    selectedCarrierType,
    groupedCarriers,
    selectedPickupPoint,
    addressSelected,
    nextStep,
    formContext,
  });

  return (
    <>
      {(selectedCarrierType === HANDLING_GROUPS.PICKUP_POINT ||
        selectedCarrierType === HANDLING_GROUPS.PICKUP_STORE ||
        selectedCarrierType === HANDLING_GROUPS.PICKUP_STORE_DELIVERY) &&
        addressSelected && (
          <FormProvider {...formContext}>
            <form onSubmit={handleSubmitPickupPoints}>
              <NeedHelp
                position={NEED_HELP_POSITION_TYPES.TOP}
                clientId={AuthConfiguration.clientId}
              />
              <PickupPointSelector
                maxDistance={200}
                pickupPoints={pickupPoints}
                selectedCarrierGroup={selectedCarrierType}
                selectedPickupPointId={
                  selectedPickupPoint ? selectedPickupPoint.id : undefined
                }
                onSelectPickupPoint={handleSetSelectedPickupPoint}
                searchAddress={addressSelected}
                userAddresses={addresses}
                onAddressSelected={handleSetAddressSelected}
                carriers={carriers}
                priceFormat={CURRENCIES.EUR}
                onClickBackButton={previousStep}
                pickupPointsIsLoading={pickupPointsIsLoading}
              />
            </form>
          </FormProvider>
        )}
      {(selectedCarrierType === HANDLING_GROUPS.HOME ||
        selectedCarrierType === HANDLING_GROUPS.HOME_INTERVENTION ||
        selectedCarrierType === HANDLING_GROUPS.EXTERNAL) &&
        homePickupAddress && (
          <>
            <NeedHelp clientId={AuthConfiguration.clientId} />
            {isHomePickup && <TimerSlotModal previousStep={previousStep} />}
            <AddressManagement
              groupedCarriers={groupedCarriers}
              isHomePickup={isHomePickup}
              updatedAddress={homePickupAddress}
              onAddressChange={handleAddressChange}
              onClickNextButton={handleSubmitHomePickup}
              isVerificationPending={!verifiedAddressIsLoaded}
              addressErrors={addressErrors}
              goToPreviousStep={previousStep}
              handlingMode={handlingMode}
            />
          </>
        )}
    </>
  );
};

CarrierForm.displayName = 'CarrierForm';

export default CarrierForm;
