import get from 'lodash/get';
import size from 'lodash/size';
import xor from 'lodash/xor';
import { replace } from 'redux-first-history';
import {
  all,
  cancel,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { ActionCreators as ClaimActionCreators } from '@savgroup-front-common/core/src/domains/claims';
import * as ClaimActionTypes from '@savgroup-front-common/core/src/domains/claims/actionTypes';
import { selectUserId } from '@savgroup-front-common/core/src/domains/selectors';

import { ROUTES } from '../../view/app/routes/Routes';
import { ActionTypes as ClaimCreationActionTypes } from '../Claim/ClaimCreation';

import {
  currentClaimGroupIdSelector,
  currentClaimProducts,
} from './groupedProduct';

function* returnProductsWorker({ payload: { issueId, ownerProductIds } }) {
  const currentClaimGroupId = yield select(currentClaimGroupIdSelector);
  const claimProducts = yield select(currentClaimProducts) || [];
  const productsWasChanged = size(xor(ownerProductIds, claimProducts)) !== 0;

  if (currentClaimGroupId) {
    if (
      currentClaimGroupId &&
      !productsWasChanged &&
      ownerProductIds.length > 1
    ) {
      yield put(
        replace(
          ROUTES.CLAIM_GROUP_ISSUES.replace(
            ':claimGroupId',
            currentClaimGroupId,
          ),
        ),
      );
      yield cancel();
    }
    yield put(ClaimActionCreators.deleteClaimGroupById(currentClaimGroupId));
    yield take(ClaimActionTypes.DELETE_CLAIM_GROUP_BY_ID.SUCCEEDED);
  }

  const ownerId = yield select(selectUserId);

  yield put(
    ClaimActionCreators.createClaimGroupWithIssueForOwner({
      issueId,
      ownerId,
      ownerProductIds,
    }),
  );
  const [success, failure] = yield race([
    take(ClaimActionTypes.CREATE_CLAIM_GROUP_WITH_ISSUE_FOR_OWNER.SUCCEEDED),
    take(ClaimActionTypes.CREATE_CLAIM_GROUP_WITH_ISSUE_FOR_OWNER.ERRORED),
  ]);

  if (success) {
    const claimGroupId = get(success, ['payload', 'value']);

    yield put(
      replace(
        ROUTES.CLAIM_GROUP_DETAILS.replace(':claimGroupId', claimGroupId),
      ),
    );
  }

  if (failure) {
    logCritical(new Error('Failed to create claim group with issue'), {
      issueId,
      ownerId,
      ownerProductIds,
      failure,
    });
  }
}
function* returnProductsWatcher() {
  yield takeEvery(
    ClaimCreationActionTypes.RETURN_PRODUCTS.BASE,
    returnProductsWorker,
  );
}

export default function* mainSaga() {
  yield all([returnProductsWatcher()]);
}
