import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import { Row } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import {
  BUTTON_TYPES,
  media,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants';
import {
  Banner,
} from '@savgroup-front-common/core/src/atoms/Banners';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { AddressInfoDto, PickupPoint } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import { Carrier } from 'myaccount/api/Claim/getClaimGroupCarrierQuery';

import Address from './Address';
import PickupPointList from './components/PickupPointList';
import PickupPointMap from './components/PickupPointMap';
import messages from './messages';
import {
  $AddressContainer,
  $BackButtonContainer,
  $CloseButton,
  $ColListContainer,
  $ColMapContainer,
  $NoPickupPoint,
  $SelectContainerGrid,
  $SelectContainerRow,
} from './PickupPointSelector.styles';
import { AddressSelected, PickupPoints } from './PickupPointSelector.types';

interface PickupPointSelectorProps {
  onClose: () => void;
  pickupPoints: PickupPoints;
  maxDistance: number;
  onAddressSelected: ({ address, countryCode }: AddressSelected) => void;
  searchAddress?: AddressSelected;
  userAddresses: AddressInfoDto[];
  pickupPointsIsLoading: boolean;
  carriers: Carrier[];
  onSelectPickupPoint: (payload: PickupPoint) => void;
  selectedPickupPointId?: string;
  sellerId?: string;
  noPickupPoints?: boolean;
}

const PickupPointSelector: FunctionComponent<
  React.PropsWithChildren<PickupPointSelectorProps>
> = ({
  onClose,
  pickupPoints,
  maxDistance,
  onAddressSelected,
  searchAddress,
  userAddresses,
  pickupPointsIsLoading,
  carriers,
  onSelectPickupPoint,
  selectedPickupPointId,
  sellerId,
  noPickupPoints = false,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);

  const cardRefs: any = {};

  const selectPickupPointOnMap = (selectedPickupPointOnMap: PickupPoint) => {
    const cardElement = cardRefs[selectedPickupPointOnMap.id];

    if (cardElement) {
      cardElement.scrollIntoView();
    }
    onSelectPickupPoint(selectedPickupPointOnMap);
  };

  const filteredCarrierBrands = carriers.map((carrier) => carrier.carrierBrand);

  const filteredProductTypes = carriers.map((carrier) => carrier.productType);

  const filteredPickupPoints = _.orderBy(
    _.uniqBy(
      _.filter(
        _.flatMap(filteredProductTypes, (type) =>
          _.flatMap(filteredCarrierBrands, (carrier = '') =>
            _.map(_.get(pickupPoints, [type, carrier, 'points']), (p) => ({
              ...p,
              ..._.find(carriers, {
                carrierBrand: carrier,
                productType: type,
              }),
            })),
          ),
        ),
        (pickupPoint) => _.get(pickupPoint, 'adress'),
      ),
      'id',
    ),
    'distanceInMeters',
    'asc',
  );

  const handleSetSelectedPickupPoint = (pickupPointsId: string) => {
    const pickupPointChoice = filteredPickupPoints?.find(
      (pickupPoint: any) => pickupPoint.id === pickupPointsId,
    );

    onSelectPickupPoint(pickupPointChoice as PickupPoint);
  };

  return (
    <$SelectContainerGrid fluid>
      <$SelectContainerRow>
        <$ColListContainer sm={4}>
          <Row>
            <$BackButtonContainer xs={12}>
              <$CloseButton onClick={onClose} naked type={BUTTON_TYPES.BUTTON}>
                <MyAccountIcon icon={ICONS_TYPE.CROSS_ICON} />
              </$CloseButton>
            </$BackButtonContainer>
          </Row>

          <$AddressContainer>
            <Address
              onAddressSelected={onAddressSelected}
              searchAddress={searchAddress}
              maxDistance={maxDistance}
              userAddresses={userAddresses}
            />
          </$AddressContainer>

          {pickupPointsIsLoading ? (
            <BaseLoader />
          ) : (
            <Row>
              {!noPickupPoints ? (
                <PickupPointList
                  handleSetSelectedPickupPoint={handleSetSelectedPickupPoint}
                  pickupPoints={filteredPickupPoints as PickupPoint[]}
                  sellerId={sellerId}
                />
              ) : (
                <$NoPickupPoint>
                  <Banner
                    isOpen
                    hollow
                    notificationType={NOTIFICATION_TYPES.ALERT}
                  >
                    <SafeFormattedMessageWithoutSpread
                      message={messages.noPickupPoints}
                    />
                  </Banner>
                </$NoPickupPoint>
              )}
            </Row>
          )}
        </$ColListContainer>
        {pickupPointsIsLoading && !isMobileView ? (
          <BaseLoader />
        ) : (
          <$ColMapContainer sm={8}>
            <PickupPointMap
              searchAddress={searchAddress}
              selectedPickupPointId={selectedPickupPointId}
              onSelectPickupPoint={selectPickupPointOnMap}
              pickupPoints={filteredPickupPoints}
              noPickupPoints={noPickupPoints}
            />
          </$ColMapContainer>
        )}
      </$SelectContainerRow>
    </$SelectContainerGrid>
  );
};

PickupPointSelector.displayName = 'PickupPointSelector';

export default PickupPointSelector;
