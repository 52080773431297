import { add, format } from 'date-fns';
import moment from 'moment';
import React, { FunctionComponent, useContext } from 'react';
import { FormattedDate } from 'react-intl';

import { STANDARD_DATE_FORMAT_DATE_FNS } from '@savgroup-front-common/constants';

import { byStartLocalTime } from '../AppointmentCalendar.helpers';
import {
  CalendarDayBlock,
  CalendarDayDate,
  CalendarDayName,
  TimeSlotSelectorContainer,
} from '../AppointmentCalendar.styles';

import {
  $AppointmentCalendarScreen,
  $EmptyDay,
  $TimeSlotSelectorContainer,
  $UnderlinedRow,
} from './AppointmentCalendarScreen.styles';
import TimeSlotSelector from './TimeSlotSelector';
import { useGetPageSize } from '../../../../../hooks/useGetPageSize';
import {
  BannersConsumer,
  BannersProviderContext,
} from '../../../../../../../molecules/NotificationsProvider';
import { isEmpty } from '../../../../../../../helpers/isEmpty';

import { PAGINATION_START_FROM_DAY } from '../../../TakeInterventionAppointment.types';
import { useTakeInterventionAppointmentContext } from '../../../TakeInterventionAppointment.context';

interface AppointmentCalendarScreenProps {
  isActive: boolean;
}

const AppointmentCalendarScreen: FunctionComponent<
  AppointmentCalendarScreenProps
> = ({ isActive }) => {
  const pageSize = useGetPageSize();
  const { detailedSchedules, pageNumber, handleSelectTimeSlot } =
    useTakeInterventionAppointmentContext();
  const { notifications } = useContext(BannersProviderContext);

  const isAllDetailedScheduleEmpty = (detailedSchedules || []).every(
    ({ timeslots = [] }) =>
      timeslots.filter((timeslot) => !!timeslot).length === 0,
  );

  const detailedSchedulesToDisplay = isEmpty(detailedSchedules)
    ? new Array(pageSize).fill(null).map((_, index) => {
        const date = format(
          add(new Date(), {
            days:
              (pageNumber - 1) * pageSize + PAGINATION_START_FROM_DAY + index,
          }),
          STANDARD_DATE_FORMAT_DATE_FNS,
        );

        return {
          date,
          timeslots: [],
        };
      })
    : detailedSchedules || [];

  return (
    <$AppointmentCalendarScreen>
      <$UnderlinedRow $columnCount={detailedSchedulesToDisplay.length}>
        {detailedSchedulesToDisplay.map(({ date }) => (
          <div key={date}>
            <CalendarDayBlock>
              <CalendarDayName>
                <FormattedDate value={date} weekday="long" />
              </CalendarDayName>
              <CalendarDayDate>{moment(date).format('DD/MM')}</CalendarDayDate>
            </CalendarDayBlock>
          </div>
        ))}
      </$UnderlinedRow>

      <BannersConsumer />

      {!isAllDetailedScheduleEmpty && notifications.length === 0 && (
        <$TimeSlotSelectorContainer
          $columnCount={detailedSchedulesToDisplay.length}
        >
          {detailedSchedulesToDisplay.map(({ date, timeslots = [] }) => {
            if (timeslots.length === 0) {
              return <$EmptyDay key={date}>-</$EmptyDay>;
            }

            const allDaySchedule = timeslots.find(
              (timeslot) => timeslot.allDaySchedule,
            );

            if (allDaySchedule) {
              return (
                <div key={date}>
                  <TimeSlotSelector
                    isActive={isActive}
                    timeslot={allDaySchedule}
                    onTimeslotSelect={handleSelectTimeSlot}
                  />
                </div>
              );
            }

            return (
              <div key={date}>
                <TimeSlotSelectorContainer>
                  {timeslots.sort(byStartLocalTime).map((timeslot) => {
                    return (
                      <TimeSlotSelector
                        key={timeslot.internalId}
                        isActive={isActive}
                        timeslot={timeslot}
                        onTimeslotSelect={handleSelectTimeSlot}
                      />
                    );
                  })}
                </TimeSlotSelectorContainer>
              </div>
            );
          })}
        </$TimeSlotSelectorContainer>
      )}
    </$AppointmentCalendarScreen>
  );
};

AppointmentCalendarScreen.displayName = 'AppointmentCalendarScreen';

export default AppointmentCalendarScreen;
