import React from 'react';

import Icon from './Icon';

interface CheckCircleOutlineIconProps {
  color?: string;
  size?: string;
}

const CheckCircleOutlineIcon: React.FC<CheckCircleOutlineIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M11.6665 20.8333L16.6665 25.8333L28.3332 14.1667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9999 36.6667C29.2049 36.6667 36.6666 29.205 36.6666 20C36.6666 10.795 29.2049 3.33333 19.9999 3.33333C10.7949 3.33333 3.33325 10.795 3.33325 20C3.33325 29.205 10.7949 36.6667 19.9999 36.6667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

CheckCircleOutlineIcon.displayName = 'CheckCircleOutlineIcon';

export default CheckCircleOutlineIcon;
