import React from 'react';

interface ToolsProps {
  color?: string;
}

/* eslint-disable max-len */
const ToolsIcon: React.FC<ToolsProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.75 17.6783L4.96664 29.4617C4.65671 29.7712 4.41085 30.1389 4.2431 30.5435C4.07536 30.9482 3.98901 31.3819 3.98901 31.82C3.98901 32.2581 4.07536 32.6918 4.2431 33.0965C4.41085 33.5011 4.65671 33.8688 4.96664 34.1783V34.1783C5.59173 34.8032 6.43942 35.1543 7.3233 35.1543C8.20718 35.1543 9.05488 34.8032 9.67997 34.1783L21.4633 22.3917M28.655 23L35.1183 29.4633C35.7432 30.0884 36.0943 30.9361 36.0943 31.82C36.0943 32.7039 35.7432 33.5516 35.1183 34.1767V34.1767C34.4932 34.8016 33.6455 35.1526 32.7616 35.1526C31.8778 35.1526 31.0301 34.8016 30.405 34.1767L20.0566 23.83M11.2216 9.84L15.9383 14.5567M11.2216 9.84L7.6833 11.0167L4.14997 5.125L6.50664 2.76833L12.4 6.30333L11.2216 9.84V9.84Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7499 17.6783C15.3432 14.09 15.6182 9.38167 18.5182 6.48167C21.4182 3.58167 26.7682 2.94667 29.7132 4.715L24.6465 9.78167L24.1749 14.9667L29.3599 14.4967L34.4265 9.42833C36.1965 12.375 35.5599 17.725 32.6599 20.6233C29.7599 23.5233 25.0532 23.7983 21.4649 22.3917"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ToolsIcon.displayName = 'ToolsIcon';

export default ToolsIcon;
