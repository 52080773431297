import { DOCUMENT_TYPES } from '@savgroup-front-common/constants';
import { rmaSupplierInfosValue } from '@savgroup-front-common/core/src/domains/claims/selectors';
import { storeSelectors } from '@savgroup-front-common/core/src/domains/store';
import { FileSummary } from '@savgroup-front-common/types';
import { useMyAccountTypedSelector } from 'myaccount/hooks';

interface UseActiveStatusTemplateContainerArgs {
  confirmationClaimGroupValue?: {
    actorId: string;
    storeActorId: string;
  };
  fileSummary?: FileSummary;
  claimConfirmationData?: {
    content1: string;
    content2: string;
    contentTitle: string;
    solutionName: string;
  };
  handlingSummary?: { deposit: Record<string, unknown> };
}

export const useActiveStatusTemplateContainer = ({
  confirmationClaimGroupValue,
  fileSummary,
  claimConfirmationData,
  handlingSummary,
}: UseActiveStatusTemplateContainerArgs) => {
  const attachedDocuments = fileSummary?.attachedDocuments;
  const partnerTransportDocument = attachedDocuments?.find(
    (document) => document.attachedDocumentType === DOCUMENT_TYPES.TRANSPORT,
  );

  const storeId = confirmationClaimGroupValue?.actorId;

  const content1 = claimConfirmationData?.content1 as string;
  const content2 = claimConfirmationData?.content2;

  const contentTitle = claimConfirmationData?.contentTitle;
  const partnerTransportDocumentUrl = partnerTransportDocument?.documentUrl;
  const trackingNumbers = fileSummary?.trackingNumbers;
  const solutionName = claimConfirmationData?.solutionName;
  const deposit = handlingSummary?.deposit;
  const homePickupInfos = fileSummary?.homePickupInfos;
  const claimId = fileSummary?.claimInfoSummary?.claimId;
  const claimGroupId = fileSummary?.claimInfoSummary?.claimGroupId;

  const rmaSupplierInfos = useMyAccountTypedSelector((state) =>
    rmaSupplierInfosValue(state, claimId),
  );
  const rmaSupplier = rmaSupplierInfos?.value;

  const storeInfo = useMyAccountTypedSelector((state) =>
    storeSelectors.selectStoreById(state, { storeId }),
  );

  const storeActor = useMyAccountTypedSelector((state) =>
    storeSelectors.selectStoreById(state, {
      storeId: confirmationClaimGroupValue?.storeActorId,
    }),
  );

  return {
    content1,
    content2,
    contentTitle,
    deposit,
    homePickupInfos,
    rmaSupplier,
    storeInfo,
    storeActor,
    solutionName,
    trackingNumbers,
    claimId,
    claimGroupId,
    partnerTransportDocumentUrl,
  };
};
