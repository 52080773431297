export enum DOCUMENT_STATUS {
  UNKNOWN = 'Unknown',
  IN_PROGRESS = 'InProgress',
  CREATED = 'Created',
  FAILURE = 'Failure',
  REGENERATE = 'Regenerate',
}

export const FILE_BUTTON_ORIGIN = {
  STATUS_UPDATE: 'statusUpdate',
  DOCUMENTS_TAB: 'documentsTab',
};

export enum DOCUMENT_TYPES {
  IMAGE = 'Image',
  LOGO = 'Logo',
  FAVICON = 'Favicon',
  VIDEO = 'Video',
  RETURN_VOUCHER = 'ReturnVoucher',
  SHIPPING_LABEL = 'ShippingLabel',
  QUOTE = 'Quote',
  TRANSPORT = 'Transport',
  THEME = 'Theme',
  VOUCHER_LOGO = 'VoucherLogo',
  STORE_LOGO = 'Storelogo',
  INTERVENTION_REPORT = 'InterventionReport',
  ADDITIONAL_INFORMATION_DOCUMENTS = 'ADDITIONAL_INFORMATION_DOCUMENTS',
  BILLING = 'Billing',
}

export const RMA_DOCUMENT_TYPES = {
  BILL_RMA: 'bill_rma',
};
