import get from 'lodash/get';
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { MDXContent } from '@savgroup-front-common/core/src/components/MDXContent/MDXContent';
import {
  ContentTemplateContext,
  customComponents,
} from '@savgroup-front-common/core/src/components/MDXCustomComponents';
import { ActorsHooks } from '@savgroup-front-common/core/src/domains/actors';
import {
  ActionCreators as fileAttachmentsActionCreators,
  Selectors as fileAttachmentsSelectors,
} from '@savgroup-front-common/core/src/domains/attachments';
import { selectTechnicalInterventionsByFileId } from '@savgroup-front-common/core/src/domains/technicalInterventions/selectors';
import useCommonTypedSelector from '@savgroup-front-common/core/src/domains/useCommonTypedSelector';
import { Store } from '@savgroup-front-common/types';
import { Selectors as CustomerSelectors } from 'myaccount/domains/Customer';
import { useMyAccountTypedSelector } from 'myaccount/hooks';

import { useGetAdditionalInformationEntities } from './hooks/useGetAdditionalInformationEntities';

interface ClaimsSummaryContentProps {
  fileId: string;
  claimId?: string;
  claimGroupId?: string;
  content: string;
  solutionName?: string;
  storeAddress?: Record<string, unknown>;
  isLoading: boolean;
  homePickupInfos?: Record<string, unknown>[];
  rmaSupplier?: Record<string, unknown>;
  depositTrackingInfo?: Record<string, unknown>;
  storeInfo?: Store;
  storeActor?: Store;
  partnerTransportDocumentUrl?: string;
}

const ClaimsSummaryContent: FunctionComponent<
  React.PropsWithChildren<ClaimsSummaryContentProps>
> = ({
  content,
  depositTrackingInfo,
  solutionName = '',
  storeAddress = {},
  isLoading,
  homePickupInfos = [],
  rmaSupplier,
  storeInfo,
  storeActor,
  fileId,
  claimId,
  claimGroupId,
  partnerTransportDocumentUrl,
}) => {
  const dispatch = useDispatch();

  const depositCarrierName =
    get(depositTrackingInfo, 'unknownCarrierName') ||
    get(depositTrackingInfo, 'externalCarrier') ||
    '-';
  const customerFileSummaryValue = useMyAccountTypedSelector(
    CustomerSelectors.selectCustomerFileSummary,
  );
  const documentStatusByFileIdValue = useCommonTypedSelector((state) =>
    fileAttachmentsSelectors.getDocumentsStatusByFileIdValue(state, fileId),
  );
  const latestTechnicalIntervention = useCommonTypedSelector((store) =>
    selectTechnicalInterventionsByFileId(store, { fileId }),
  );

  const getDocumentStatusByFileId = useCallback(
    (data: { fileId: string; type: string }) => {
      dispatch(fileAttachmentsActionCreators.getDocumentsStatusByFileId(data));
    },
    [dispatch],
  );

  const { actorSummary } = ActorsHooks.useGetActorSummary({
    actorId: customerFileSummaryValue.actorId,
    actorType: customerFileSummaryValue.actorType,
    ownerId: customerFileSummaryValue.ownerId,
    sellerId: customerFileSummaryValue.sellerId,
  });

  const {
    isLoading: getAdditionalInformationEntitiesIsLoading,
    additionalInformationEntitiesMap,
  } = useGetAdditionalInformationEntities({
    fileSummary: customerFileSummaryValue,
  });

  return (
    <ContentTemplateContext.Provider
      value={{
        solutionName,
        storeAddress,
        depositCarrierName,
        isLoading: isLoading || getAdditionalInformationEntitiesIsLoading,
        homePickupInfos,
        rmaSupplier,
        storeInfo,
        storeActor,
        fileId,
        getDocumentStatusByFileId,
        getDocumentStatusByFileIdValue: documentStatusByFileIdValue,
        claimId,
        claimGroupId,
        latestTechnicalIntervention,
        orderReference: customerFileSummaryValue?.orderReference,
        fileReference: customerFileSummaryValue?.fileReference,
        customerFile: customerFileSummaryValue,
        actorSummary,
        partnerTransportDocumentUrl,
        additionalInformationEntitiesMap,
      }}
    >
      <MDXContent customComponents={customComponents}>{content}</MDXContent>
    </ContentTemplateContext.Provider>
  );
};

ClaimsSummaryContent.displayName = 'ClaimsSummary';

export default ClaimsSummaryContent;
