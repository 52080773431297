import { APIConfiguration } from '@savgroup-front-common/configuration';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';

export const buildFileUploadEndpoints = ({ claimIds, id }) => {
  return claimIds.reduce((acc, claimId) => {
    return {
      ...acc,
      [claimId]: {
        uploadEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
        deleteEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
      },
    };
  }, {});
};

export const deduplicateNeededInformation =
  ({ claimIds, productClaimIds }) =>
  (acc, neededInformationItem) => {
    const { uniquenessByProduct, id, type } = neededInformationItem;

    let newNeededInformationItems = [];

    const isFileType = type === ADDITIONAL_INFORMATION_TYPES.FILE;

    if (uniquenessByProduct) {
      newNeededInformationItems = [
        ...newNeededInformationItems,
        ...productClaimIds.map((claimId) => {
          return {
            ...neededInformationItem,
            internalId: `${id}_${claimId}`,
            claimIds: [claimId],
            fileUploadEndpoints: isFileType
              ? buildFileUploadEndpoints({
                  claimIds: [claimId],
                  id,
                })
              : null,
          };
        }),
      ];
    } else {
      newNeededInformationItems = [
        ...newNeededInformationItems,
        {
          ...neededInformationItem,
          internalId: id,
          claimIds,
          fileUploadEndpoints: isFileType
            ? buildFileUploadEndpoints({
                claimIds,
                id,
              })
            : null,
        },
      ];
    }

    return [...acc, ...newNeededInformationItems];
  };

const adaptReasons = ({ reasons, claimIds, productClaimIds }) => {
  return reasons.map((reason) => {
    const neededInformation = reason.neededInformation.reduce(
      deduplicateNeededInformation({ claimIds, productClaimIds }),
      [],
    );

    return {
      ...reason,
      neededInformation,
    };
  });
};

export default adaptReasons;
