import { head } from 'lodash';
import get from 'lodash/get';
import moment from 'moment';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { intl } from '@savgroup-front-common/core/src/helpers';
import { loadSolutionsByClaim } from '@savgroup-front-common/core/src/domains/claims/actionCreators';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';

import { REASON_SELECTION_FORM_FIELDS } from '../ReasonSelectionForm/ReasonSelectionForm.constants';

const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DDT00:00:00.000ZZ';

const formatDate = (date) =>
  intl.formatDate(date, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

export const reduceClaimIds =
  ({ id, value }) =>
  (acc, claimId) => {
    const arr = acc[claimId] || [];

    return {
      ...acc,
      [claimId]: [...arr, { id, value }],
    };
  };

export const reduceNeededInformation =
  ({ watchedAdditionalInformationValues, sellerProductId }) =>
  (acc, neededInformationItem) => {
    const { internalId, id, type } = neededInformationItem;

    const value = get(watchedAdditionalInformationValues, [
      sellerProductId,
      internalId,
    ]);

    let transformedValue = value;

    if (type === ADDITIONAL_INFORMATION_TYPES.FILE) {
      return acc;
    }

    if (type === ADDITIONAL_INFORMATION_TYPES.DATE) {
      transformedValue = formatDate(
        moment(value, DEFAULT_DATETIME_FORMAT).toDate(),
      );
    }

    if (type === ADDITIONAL_INFORMATION_TYPES.ENUM) {
      transformedValue = value?.value;
    }

    return neededInformationItem.claimIds.reduce(
      reduceClaimIds({ id, value: transformedValue }),
      acc,
    );
  };

const useAdditionalInformationSave = ({
  formContext,
  products,
  reloadSolutions = true,
}) => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    const { watch } = formContext;

    const watchedAdditionalInformationValues = watch(
      REASON_SELECTION_FORM_FIELDS.ADDITIONAL_INFORMATION,
    );
    const watchedFormValues = watch('form');

    const additionalInformationByClaimId = products.reduce((acc, product) => {
      const reason = get(watchedFormValues, [
        product.productId,
        REASON_SELECTION_FORM_FIELDS.REASON,
      ]);

      if (!reason) {
        return acc;
      }

      const { neededInformation = [] } = reason.data;

      return neededInformation.reduce(
        reduceNeededInformation({
          watchedAdditionalInformationValues,
          sellerProductId: product.sellerProductId,
        }),
        acc,
      );
    }, {});

    const claimsAdditionalInformationList = Object.entries(
      additionalInformationByClaimId,
    ).reduce((acc, [claimId, informations]) => {
      return [
        ...acc,
        {
          claimId,
          informations: informations.filter(
            (information) =>
              information.value !== undefined && information.value !== null,
          ),
        },
      ];
    }, []);

    await Promise.all(
      claimsAdditionalInformationList.map(({ claimId, informations }) => {
        return ClaimService.setClaimAdditionalInformation({
          claimId,
          additionalClaimInformationValues: informations,
        });
      }),
    );

    const firstProduct = head(products);
    const claimId = firstProduct?.claimId;

    if (reloadSolutions) {
      dispatch(loadSolutionsByClaim(claimId));
    }
  }, [dispatch, formContext, products, reloadSolutions]);
};

export default useAdditionalInformationSave;
