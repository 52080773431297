import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import { myReturnIsRunning, myReturnIsRunningEnd } from './actionCreators';
import { CLOSED_FILES_LOADED, OPEN_FILES_LOADED } from './actionTypes';
import {
  selectLoadCloseFilesWasLoaded,
  selectLoadOpenFilesWasLoaded,
} from './selectors';

export function* loadOpenFilesWorker({
  payload: { ownerId, forceRefresh = false },
}) {
  yield put(myReturnIsRunning());
  const meta = { indexer: ownerId };

  const wasLoaded = yield select((state) =>
    selectLoadOpenFilesWasLoaded(state, { ownerId }),
  );

  if (!forceRefresh && wasLoaded) {
    yield put(myReturnIsRunningEnd());
    yield put(OPEN_FILES_LOADED.end(meta));

    return;
  }

  yield call(
    callAndGetResponse,
    OPEN_FILES_LOADED,
    `${APIConfiguration.workflow}owners/${ownerId}/files/opened`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    meta,
  );

  yield put(OPEN_FILES_LOADED.end(meta));
}
export function* loadOpenFilesWatcher() {
  yield takeEvery(OPEN_FILES_LOADED.BASE, loadOpenFilesWorker);
}

export function* loadClosedFilesWorker({
  payload: { ownerId, forceRefresh = false },
}) {
  yield put(myReturnIsRunning());
  const meta = { indexer: ownerId };
  const wasLoaded = yield select((state) =>
    selectLoadCloseFilesWasLoaded(state, { ownerId }),
  );

  if (!forceRefresh && wasLoaded) {
    yield put(myReturnIsRunningEnd());
    yield put(CLOSED_FILES_LOADED.end(meta));

    return;
  }

  yield call(
    callAndGetResponse,
    CLOSED_FILES_LOADED,
    `${APIConfiguration.workflow}owners/${ownerId}/files/closed`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    meta,
  );

  yield put(CLOSED_FILES_LOADED.end(meta));
}
export function* loadClosedFilesWatcher() {
  yield takeEvery(CLOSED_FILES_LOADED.BASE, loadClosedFilesWorker);
}

export default function* mainSaga() {
  yield all([loadClosedFilesWatcher(), loadOpenFilesWatcher()]);
}
