import React from 'react';

interface SendMailIconProps {
  color?: string;
}

/* eslint-disable max-len */
const SendMailIcon: React.FC<SendMailIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0001 15L22.5001 20L30.0001 15M5.00008 22.5H8.33342M1.66675 17.5H8.33342"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3335 12.5V11.6667C8.3335 10.7826 8.68469 9.93476 9.30981 9.30964C9.93493 8.68452 10.7828 8.33333 11.6668 8.33333H33.3335C34.2176 8.33333 35.0654 8.68452 35.6905 9.30964C36.3156 9.93476 36.6668 10.7826 36.6668 11.6667V28.3333C36.6668 29.2174 36.3156 30.0652 35.6905 30.6904C35.0654 31.3155 34.2176 31.6667 33.3335 31.6667H11.6668C10.7828 31.6667 9.93493 31.3155 9.30981 30.6904C8.68469 30.0652 8.3335 29.2174 8.3335 28.3333V27.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

SendMailIcon.displayName = 'SendMailIcon';

export default SendMailIcon;
