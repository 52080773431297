/* eslint-disable jsx-a11y/control-has-associated-label */
import md5 from 'md5';
import React, { FC, PropsWithChildren } from 'react';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';

import { SafeFormattedMessageWithoutSpread } from '../../../formatters';
import getSparePartConditionMessage from '../../../formatters/getSparePartConditionMessage';
import { formatReverseMoneyToString } from '../../../formatters/intl';
import { getRecipientTypeMessage } from '../../../helpers/i18n';
import { AdaptedQuotationNewModel } from '../types/AdaptedQuotationNewModel';

import messages from './messages';
import { QuotationLine } from './QuotationLine';
import { QuotationLineHead } from './QuotationLineHead';
import { $AmountCell, $AmountHeaderCel, $Table } from './QuotationLines.styles';
import { QuotationLinesMobileView } from './QuotationLinesMobileView';

interface QuotationLineHeadProps {
  isActive?: boolean;
  quotation: AdaptedQuotationNewModel;
}

const QuotationLines: FC<PropsWithChildren<QuotationLineHeadProps>> = ({
  isActive = false,
  quotation,
}) => {
  const isMobileView = useMedia(media.maxWidth.sm);

  const services = [
    ...(quotation.services || []),
    ...(quotation.unknownServices || []),
    ...(quotation.administrativeFees || []),
  ];

  const optionalSpareParts = (quotation.sparePartLines || []).filter(
    (sparePart) =>
      !sparePart.isRequiredForRepair && sparePart.isSelectedForRepair,
  );
  const requiredSpareParts = (quotation.sparePartLines || []).filter(
    (sparePart) => sparePart.isRequiredForRepair,
  );

  if (isMobileView) {
    return (
      <QuotationLinesMobileView quotation={quotation} isActive={isActive} />
    );
  }

  return (
    <$Table>
      <tbody>
        {services.length > 0 && (
          <QuotationLineHead isActive={isActive}>
            <th colSpan={4}>
              <SafeFormattedMessageWithoutSpread message={messages.service} />
            </th>
            <th>
              <SafeFormattedMessageWithoutSpread message={messages.recipient} />
            </th>
            <$AmountHeaderCel>
              <SafeFormattedMessageWithoutSpread message={messages.total} />
            </$AmountHeaderCel>
          </QuotationLineHead>
        )}

        {(quotation.administrativeFees || []).map((service, index) => {
          return (
            <QuotationLine
              key={md5(formatReverseMoneyToString(service.amountIncludedTax))}
              isActive={isActive}
            >
              <td
                colSpan={4}
                data-testid={`administrativeFees_description_${index}`}
              >
                {service.description}
              </td>
              <td data-testid={`services_recipient_${index}`}>
                {service?.recipient && (
                  <SafeFormattedMessageWithoutSpread
                    message={getRecipientTypeMessage(
                      service.recipient?.recipientType,
                    )}
                  />
                )}
              </td>
              <$AmountCell
                data-testid={`administrativeFees_amountIncludedTax_${index}`}
              >
                {formatReverseMoneyToString(service.amountIncludedTax)}
              </$AmountCell>
            </QuotationLine>
          );
        })}

        {(quotation.services || []).map((service, index) => {
          return (
            <QuotationLine key={service.id} isActive={isActive}>
              <td colSpan={4} data-testid={`services_description_${index}`}>
                {service.description}
              </td>
              <td data-testid={`services_recipient_${index}`}>
                {service.recipient && (
                  <SafeFormattedMessageWithoutSpread
                    message={getRecipientTypeMessage(
                      service.recipient?.recipientType,
                    )}
                  />
                )}
              </td>
              <$AmountCell data-testid={`services_amountIncludedTax_${index}`}>
                {formatReverseMoneyToString(service.amountIncludedTax)}
              </$AmountCell>
            </QuotationLine>
          );
        })}

        {(quotation.unknownServices || []).map((service, index) => {
          return (
            <QuotationLine
              key={md5(formatReverseMoneyToString(service.amountIncludedTax))}
              isActive={isActive}
            >
              <td
                colSpan={4}
                data-testid={`unknownServices_description_${index}`}
              >
                {service.description}
              </td>
              <td data-testid={`services_recipient_${index}`}>
                {service.recipient && (
                  <SafeFormattedMessageWithoutSpread
                    message={getRecipientTypeMessage(
                      service.recipient?.recipientType,
                    )}
                  />
                )}
              </td>
              <$AmountCell data-testid={`services_amountIncludedTax_${index}`}>
                {formatReverseMoneyToString(service.amountIncludedTax)}
              </$AmountCell>
            </QuotationLine>
          );
        })}
        {(quotation.unknownSpareParts || []).map((sparePart, index) => {
          return (
            <QuotationLine
              key={md5(formatReverseMoneyToString(sparePart.amountIncludedTax))}
              isActive={isActive}
            >
              <td
                colSpan={4}
                data-testid={`unknownSpareParts_description_${index}`}
              >
                {sparePart.description}
              </td>
              <td data-testid={`services_recipient_${index}`}>
                {sparePart.recipient && (
                  <SafeFormattedMessageWithoutSpread
                    message={getRecipientTypeMessage(
                      sparePart.recipient?.recipientType,
                    )}
                  />
                )}
              </td>
              <$AmountCell data-testid={`services_amountIncludedTax_${index}`}>
                {formatReverseMoneyToString(sparePart.amountIncludedTax)}
              </$AmountCell>
            </QuotationLine>
          );
        })}

        {(quotation.discounts || []).map((discount, index) => {
          return (
            <QuotationLine
              key={md5(formatReverseMoneyToString(discount.amountIncludedTax))}
              isActive={isActive}
            >
              <td colSpan={4} data-testid={`discounts_description_${index}`}>
                {discount.description}
              </td>
              <td data-testid={`services_recipient_${index}`}>
                {discount.recipient && (
                  <SafeFormattedMessageWithoutSpread
                    message={getRecipientTypeMessage(
                      discount.recipient?.recipientType,
                    )}
                  />
                )}
              </td>
              <$AmountCell data-testid={`services_amountIncludedTax_${index}`}>
                {formatReverseMoneyToString(discount.amountIncludedTax)}
              </$AmountCell>
            </QuotationLine>
          );
        })}

        {requiredSpareParts.length > 0 && (
          <QuotationLineHead isActive={isActive}>
            <th colSpan={3}>
              <SafeFormattedMessageWithoutSpread
                message={messages.requiredSpareParts}
              />
            </th>
            <th>
              <SafeFormattedMessageWithoutSpread
                message={messages.statusSpareParts}
              />
            </th>
            <th>
              <SafeFormattedMessageWithoutSpread message={messages.recipient} />
            </th>
            <$AmountHeaderCel>
              <SafeFormattedMessageWithoutSpread message={messages.total} />
            </$AmountHeaderCel>
          </QuotationLineHead>
        )}
        {requiredSpareParts.map((sparePart, index) => {
          return (
            <QuotationLine key={sparePart.id} isActive={isActive}>
              <td
                colSpan={3}
                data-testid={`requiredSpareParts_subCategory_supplierReference_${index}`}
              >
                {sparePart.manufacturerLabel}
              </td>
              <td data-testid={`requiredSpareParts_condition_${index}`}>
                {sparePart.condition && (
                  <SafeFormattedMessageWithoutSpread
                    message={getSparePartConditionMessage(sparePart.condition)}
                  />
                )}
              </td>
              <td data-testid={`requiredSpareParts_recipient_${index}`}>
                {sparePart.recipient?.data && (
                  <SafeFormattedMessageWithoutSpread
                    message={getRecipientTypeMessage(sparePart.recipient?.data)}
                  />
                )}
              </td>
              <$AmountCell
                data-testid={`requiredSpareParts_recommendedSalePriceIncludingTaxes_${index}`}
              >
                {formatReverseMoneyToString(
                  sparePart.recommendedSalePriceIncludingTaxes,
                )}
              </$AmountCell>
            </QuotationLine>
          );
        })}

        {optionalSpareParts.length > 0 && (
          <QuotationLineHead>
            <th colSpan={3}>
              <SafeFormattedMessageWithoutSpread
                message={messages.optionalSpareParts}
              />
            </th>
            <th>
              <SafeFormattedMessageWithoutSpread
                message={messages.statusSpareParts}
              />
            </th>
            <th>
              <SafeFormattedMessageWithoutSpread message={messages.recipient} />
            </th>
            <$AmountHeaderCel>
              <SafeFormattedMessageWithoutSpread message={messages.total} />
            </$AmountHeaderCel>
          </QuotationLineHead>
        )}
        {optionalSpareParts.map((sparePart, index) => {
          return (
            <QuotationLine key={sparePart.id} isActive={isActive}>
              <td
                colSpan={3}
                data-testid={`optionalSpareParts_subCategory_supplierReference_${index}`}
              >
                {sparePart.manufacturerLabel}
              </td>
              <td data-testid={`optionalSpareParts_condition_${index}`}>
                <SafeFormattedMessageWithoutSpread
                  message={getSparePartConditionMessage(sparePart.condition)}
                />
              </td>
              <td data-testid={`optionalSpareParts_recipient_${index}`}>
                {sparePart.recipient?.data && (
                  <SafeFormattedMessageWithoutSpread
                    message={getRecipientTypeMessage(sparePart.recipient?.data)}
                  />
                )}
              </td>
              <$AmountCell
                data-testid={`optionalSpareParts_recommendedSalePriceIncludingTaxes_${index}`}
              >
                {formatReverseMoneyToString(
                  sparePart.recommendedSalePriceIncludingTaxes,
                )}
              </$AmountCell>
            </QuotationLine>
          );
        })}
      </tbody>
    </$Table>
  );
};

QuotationLines.displayName = 'QuotationLineHead';

export default QuotationLines;
