import PropTypes from 'prop-types';
import React from 'react';

import { $LinkText } from './CustomComponents.styles';

export const CustomComponents = {
  a: ({ children, ...props }) => (
    <$LinkText {...props} target="_blank" rel="noopener noreferrer">
      {children}
    </$LinkText>
  ),
};

CustomComponents.a.displayName = 'a';

CustomComponents.a.propTypes = {
  children: PropTypes.node.isRequired,
};
