import React from 'react';

import { NOTIFICATION_TYPES } from '@savgroup-front-common/constants';
import { Banner } from '@savgroup-front-common/core/src/atoms/Banners';
import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';

import SelectProductCard from '../../../components/OrderProductInfo/SelectProductCard';

import useChooseProducts from './ChooseProducts.hooks';
import {
  ChooseProducsContainer,
  ProductsContainer,
} from './ChooseProducts.styles';
import ChooseProductsFooter from './Footer';
import ChooseProductsHeader from './Header';
import messages from './messages';
import NotEligibleProduct from './NotEligibleProduct';

const ChooseProducts = () => {
  const {
    productsError,
    eligibleProducts,
    isSubmitting,
    allProductsSelected,
    handleSelectAll,
    handleSubmit,
    handleChangeProductToReturn,
    selectedProductCount,
    selectedProducts,
    productCount,
  } = useChooseProducts();

  return (
    <ChooseProducsContainer>
      {productCount > 1 && (
        <ChooseProductsHeader
          onChange={handleSelectAll}
          checked={allProductsSelected}
        />
      )}
      <ProductsContainer>
        {eligibleProducts.map((product) => (
          <SelectProductCard
            {...product}
            key={`${product.sellerProductId}`}
            dataTestId="selectProductCardEligible"
            isSelectedProduct={selectedProducts.some(
              (ownerProductId) => ownerProductId === product.ownerProductId,
            )}
            onChangeProductToReturn={handleChangeProductToReturn}
            productsError={productsError}
          />
        ))}
      </ProductsContainer>
      <Banner
        notificationType={NOTIFICATION_TYPES.ALERT}
        hollow
        isOpen={productCount === 0}
      >
        <SafeFormattedMessage {...messages.noProductAvailable} />
      </Banner>
      <ChooseProductsFooter
        productCount={productCount}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        selectedProductCount={selectedProductCount}
      />
      <NotEligibleProduct />
    </ChooseProducsContainer>
  );
};

ChooseProducts.displayName = 'ChooseProducts';

export default ChooseProducts;
