import { createSelector } from 'reselect';

import { CLAIMS_DOMAIN_KEY } from '../constants';

const claimsState = (state) => state[CLAIMS_DOMAIN_KEY];

export const claimGroupsDeadEnd = createSelector(
  [claimsState, (state, orderId) => orderId],
  (state, orderId) => state.getIn(['deadEnd', orderId], {}),
);
