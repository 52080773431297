import entries from 'lodash/entries';
import get from 'lodash/get';
import moment from 'moment';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  API_COMMON_ERROR,
  EXPECTS,
  STANDARD_DATE_TIME_FORMAT,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';

import callAndGetResponse from '../../services/callAndGetResponse';
import { UPDATE_PICKUP_SCHEDULE } from '../carriers/actionTypes';

import { noop } from './actionCreators';
import * as ActionTypes from './actionTypes';

export function* saveDevicePasswordWorker({ payload = {} }) {
  const { fileId, devicePassword } = payload;
  const response = yield callAndGetResponse(
    ActionTypes.SAVE_DEVICE_PASSWORD,
    `${APIConfiguration.workflow}files/customerfile/${fileId}/devicepassword`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: { devicePassword },
    },
  );

  yield put(ActionTypes.SAVE_DEVICE_PASSWORD.end());

  return yield get(response, 'type') ===
    ActionTypes.SAVE_DEVICE_PASSWORD.SUCCEEDED;
}

export function* SetCommercialSolutionCommand({ payload = {} }) {
  const fileId = get(payload, 'fileId');

  yield call(
    callAndGetResponse,
    ActionTypes.SET_COMMERCIAL_SOLUTION,
    `${APIConfiguration.workflow}files/customerfile/${fileId}/commercialsolution`,
    { method: SUPPORTED_METHODS.PUT, json: payload },
    { fileId },
  );

  yield call(get(payload, 'callback', () => {}));

  yield put(ActionTypes.SET_COMMERCIAL_SOLUTION.end());
}

export function* loadFileHandlingWorker({ payload: { fileId } }) {
  const meta = { fileId, indexer: fileId };

  if (!fileId) {
    yield put(ActionTypes.LOAD_FILE_HANDLING.end());

    return;
  }

  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_FILE_HANDLING,
    `${APIConfiguration.workflow}files/customerfile/${fileId}/handling`,
    { method: SUPPORTED_METHODS.GET },
    meta,
  );
  yield put(ActionTypes.LOAD_FILE_HANDLING.end());
}
function* loadFileHandlingWatcher() {
  yield takeLatest(ActionTypes.LOAD_FILE_HANDLING.BASE, loadFileHandlingWorker);
}

function haveScheduleProblem(error = {}) {
  const { code = '' } = error;

  return [
    API_COMMON_ERROR.PROBLEM_WITH_SCHEDULE,
    API_COMMON_ERROR.READY_TIME_LATER_THAN_ALLOWABLE,
  ].includes(code);
}
export function* setAppointmentWorker({ payload = {} }) {
  const { appointmentTime, fileId } = payload;
  const meta = { fileId, indexer: appointmentTime.internalId };

  let response;

  const { hostname } = window.location;

  if (
    appointmentTime.startLocalTime.indexOf('T10:00:00Z') > 0 &&
    (hostname.includes('dev') ||
      hostname.includes('localhost') ||
      hostname.includes('qualif'))
  ) {
    const mockResponseForDev = {
      type: ActionTypes.SET_PICKUP_APPOINTMENT.ERRORED,
      errors: [{ code: API_COMMON_ERROR.PROBLEM_WITH_SCHEDULE }],
    };

    response = mockResponseForDev;
    yield put(ActionTypes.SET_PICKUP_APPOINTMENT.start(meta));
    yield put(
      ActionTypes.SET_PICKUP_APPOINTMENT.error(
        { errors: [{ code: API_COMMON_ERROR.PROBLEM_WITH_SCHEDULE }] },
        meta,
      ),
    );
  } else {
    response = yield callAndGetResponse(
      ActionTypes.SET_PICKUP_APPOINTMENT,
      `${APIConfiguration.carrier}homePickup/create/${fileId}`,
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          startTime: get(appointmentTime, 'startLocalTime'),
          endTime: get(appointmentTime, 'endLocalTime'),
          startTimeInLocalRecipientTimezone: get(
            appointmentTime,
            'startLocalTime',
          ),
          endTimeInLocalRecipientTimezone: get(appointmentTime, 'endLocalTime'),
        },
      },
      meta,
    );
  }

  if (get(response, 'type') === ActionTypes.SET_PICKUP_APPOINTMENT.ERRORED) {
    const { errors = [] } = response;

    if (errors.some(haveScheduleProblem)) {
      yield put(
        UPDATE_PICKUP_SCHEDULE.base({
          ...appointmentTime,
          startLocalTime: moment
            .utc(appointmentTime.startLocalTime, STANDARD_DATE_TIME_FORMAT)
            .set({
              hour: 9,
            })
            .format(STANDARD_DATE_TIME_FORMAT),
          endLocalTime: moment
            .utc(appointmentTime.endLocalTime, STANDARD_DATE_TIME_FORMAT)
            .set({
              hour: 23,
              minute: 1,
            })
            .format(STANDARD_DATE_TIME_FORMAT),
          allDaySchedule: true,
        }),
      );
    }
  }

  yield put(ActionTypes.SET_PICKUP_APPOINTMENT.end(meta));

  return yield get(response, 'type') ===
    ActionTypes.SET_PICKUP_APPOINTMENT.SUCCEEDED;
}

export function* saveExpectsWorker({ payload = {} }) {
  const { fileId, expects } = payload;

  let errored = false;

  yield entries(expects).reduce(function* iterate(prev, [key, expect]) {
    yield prev;
    if (errored) {
      return;
    }

    switch (key) {
      case EXPECTS.EXPECT_UNLOCK_CODE_REQUESTED:
        errored = !(yield saveDevicePasswordWorker({
          payload: {
            fileId,
            devicePassword: expect,
          },
        }));
        break;
      case EXPECTS.EXPECT_TAKE_APPOINTMENT:
        errored = !(yield setAppointmentWorker({
          payload: expect,
        }));
        break;
      default:
        break;
    }
  }, call(noop));

  if (errored) {
    yield put(ActionTypes.SAVE_EXPECTS.error());
  } else {
    yield put(ActionTypes.SAVE_EXPECTS.success());
  }

  yield put(ActionTypes.SAVE_EXPECTS.end());
}
function* saveExpectsWatcher() {
  yield takeEvery(ActionTypes.SAVE_EXPECTS.BASE, saveExpectsWorker);
}

function* usingMyOwnCarrierWorker({
  payload: {
    fileId,
    moduleDefinitionId,
    moduleInstanceId,
    carrierName,
    trackingNumber,
    unknownCarrier,
    handlingDirection,
    trackingUrl,
    comment,
  },
}) {
  const data = unknownCarrier
    ? {
        moduleDefinitionId,
        moduleInstanceId,
        carrier: carrierName,
        trackingNumber,
        trackingUrl,
        handlingDirection,
        unknownCarrierName: unknownCarrier,
      }
    : {
        moduleDefinitionId,
        moduleInstanceId,
        carrier: carrierName,
        trackingNumber,
        handlingDirection,
      };
  const meta = {
    fileId,
    comment,
  };

  yield call(
    callAndGetResponse,
    ActionTypes.USE_MY_OWN_CARRIER,
    `${APIConfiguration.workflow}files/${fileId}/trackingReferences`,
    {
      method: SUPPORTED_METHODS.POST,
      json: data,
    },
    meta,
  );
  yield put(ActionTypes.USE_MY_OWN_CARRIER.end(meta));
}
function* usingMyOwnCarrierWatcher() {
  yield takeEvery(ActionTypes.USE_MY_OWN_CARRIER.BASE, usingMyOwnCarrierWorker);
}

export function* forceCloseFileWorker({ payload = {} }) {
  const { fileId, userId, closingType, comment, toCustomerComment } = payload;

  if (closingType) {
    yield call(
      callAndGetResponse,
      ActionTypes.FORCE_CLOSE_FILE,
      `${APIConfiguration.workflow}files/customerfile/${fileId}/outcome`,
      {
        method: SUPPORTED_METHODS.PUT,
        json: {
          closing: closingType,
        },
      },
    );
  }

  yield call(
    callAndGetResponse,
    ActionTypes.FORCE_CLOSE_FILE,
    `${APIConfiguration.workflow}files/${fileId}/closed`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {
        responsibleUserId: userId,
        comment,
        toCustomerComment,
      },
    },
  );
  yield put(ActionTypes.FORCE_CLOSE_FILE.end({ fileId }));
}

function* getUserActionsWorker({ payload: fileId }) {
  const meta = { fileId };

  if (!fileId) {
    yield put(ActionTypes.GET_FILE_ACTIONS.end(meta));

    return;
  }

  yield call(
    callAndGetResponse,
    ActionTypes.GET_FILE_ACTIONS,
    `${APIConfiguration.workflow}files/${fileId}/actions`,
    { method: SUPPORTED_METHODS.GET },
    meta,
  );
  yield put(ActionTypes.GET_FILE_ACTIONS.end(meta));
}
function* getUserActionsWatcher() {
  yield takeEvery(ActionTypes.GET_FILE_ACTIONS.BASE, getUserActionsWorker);
}

function* setSyncUserActionWorker({ payload: { fileId } }) {
  yield put(ActionTypes.SET_SYNC_USER_ACTION.success(fileId));
  yield put(ActionTypes.SET_SYNC_USER_ACTION.end(fileId));
}
function* setSyncUserActionWatcher() {
  yield takeEvery(
    ActionTypes.SET_SYNC_USER_ACTION.BASE,
    setSyncUserActionWorker,
  );
}

function* splitFileWorker({ payload: { fileId, ownerProductIds } }) {
  const meta = { fileId };

  yield call(
    callAndGetResponse,
    ActionTypes.SPLIT_FILE,
    `${APIConfiguration.workflow}files/${fileId}/split`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {
        ownerProductIds,
      },
    },
    meta,
  );
  yield put(ActionTypes.SPLIT_FILE.end(meta));
}

function* explodeFileWorker({ payload: fileId }) {
  const meta = { fileId };

  yield call(
    callAndGetResponse,
    ActionTypes.EXPLODE_FILE,
    `${APIConfiguration.workflow}files/${fileId}/explode`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {},
    },
    meta,
  );
  yield put(ActionTypes.EXPLODE_FILE.end(meta));
}

function* explodeFileAlongSolutionsWorker({ payload: fileId }) {
  const meta = { fileId };

  yield call(
    callAndGetResponse,
    ActionTypes.EXPLODE_FILE_ALONG_SOLUTIONS,
    `${APIConfiguration.workflow}files/${fileId}/solutions/explode`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {},
    },
    meta,
  );
  yield put(ActionTypes.EXPLODE_FILE_ALONG_SOLUTIONS.end(meta));
}

function* forceCloseFileWatcher() {
  yield takeEvery(ActionTypes.FORCE_CLOSE_FILE.BASE, forceCloseFileWorker);
}

function* setCommercialSolutionWatcher() {
  yield takeEvery(
    ActionTypes.SET_COMMERCIAL_SOLUTION.BASE,
    SetCommercialSolutionCommand,
  );
}

function* splitFileWatcher() {
  yield takeEvery(ActionTypes.SPLIT_FILE.BASE, splitFileWorker);
}

function* explodeFileWatcher() {
  yield takeEvery(ActionTypes.EXPLODE_FILE.BASE, explodeFileWorker);
}

function* explodeFileAlongSolutionsWatcher() {
  yield takeEvery(
    ActionTypes.EXPLODE_FILE_ALONG_SOLUTIONS.BASE,
    explodeFileAlongSolutionsWorker,
  );
}

function* loadOwnerProductFilesWorker({ payload: { ownerProductIds } }) {
  const meta = { ownerProductIds };

  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_OWNER_PRODUCT_FILES,
    `${APIConfiguration.workflow}ownerProducts/files`,
    { method: SUPPORTED_METHODS.POST, json: { ownerProductIds } },
    meta,
  );
  yield put(ActionTypes.LOAD_OWNER_PRODUCT_FILES.end(meta));
}

function* loadOwnerProductFilesWatcher() {
  yield takeEvery(
    ActionTypes.LOAD_OWNER_PRODUCT_FILES.BASE,
    loadOwnerProductFilesWorker,
  );
}

function* refreshFileHandlerWorker({ payload: fileId }) {
  const meta = { fileId };

  yield call(
    callAndGetResponse,
    ActionTypes.REFRESH_FILE_HANDLER,
    `${APIConfiguration.workflow}files/${fileId}/status`,
    { method: SUPPORTED_METHODS.PUT },
    meta,
  );
  yield put(ActionTypes.REFRESH_FILE_HANDLER.end(meta));
}

function* refreshFileHandlerWatcher() {
  yield takeEvery(
    ActionTypes.REFRESH_FILE_HANDLER.BASE,
    refreshFileHandlerWorker,
  );
}

export default function* workflowSaga() {
  try {
    yield all([
      saveExpectsWatcher(),
      loadFileHandlingWatcher(),
      usingMyOwnCarrierWatcher(),
      forceCloseFileWatcher(),

      getUserActionsWatcher(),
      setSyncUserActionWatcher(),

      splitFileWatcher(),
      explodeFileWatcher(),
      explodeFileAlongSolutionsWatcher(),
      setCommercialSolutionWatcher(),
      loadOwnerProductFilesWatcher(),
      refreshFileHandlerWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
