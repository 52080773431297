import { GeocodeAddressFromDataGouvResponse } from '@savgroup-front-common/core/src/api/DataGouv';

import {
  ADDRESS_MANAGEMENT_FIELDS,
  AddressManagementFieldValues,
  AddressManagementValues,
} from '../AddressManagement.types';

interface AdaptGeoCodeAddressFromDataGouvArgs {
  suggestedAddress: GeocodeAddressFromDataGouvResponse;
  keepSuggestedAddress: string;
  customerInformation: AddressManagementFieldValues;
}

const adaptGeoCodeAddressFromDataGouv = ({
  suggestedAddress,
  keepSuggestedAddress,
  customerInformation,
}: AdaptGeoCodeAddressFromDataGouvArgs):
  | AddressManagementValues
  | undefined => {
  const addresses = suggestedAddress?.features;

  if (addresses.length === 0) {
    return undefined;
  }

  const addressToSuggest = addresses[0];

  return {
    [ADDRESS_MANAGEMENT_FIELDS.ADDRESS]: addressToSuggest.properties.name,
    [ADDRESS_MANAGEMENT_FIELDS.POSTAL_CODE]:
      addressToSuggest.properties.postcode,
    [ADDRESS_MANAGEMENT_FIELDS.CITY]: addressToSuggest.properties.city,
    [ADDRESS_MANAGEMENT_FIELDS.FIRST_NAME]: customerInformation.firstName,
    [ADDRESS_MANAGEMENT_FIELDS.LAST_NAME]: customerInformation.lastName,
    [ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_INFORMATION]:
      customerInformation.additionalInformation,
    [ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_ADDRESS]:
      customerInformation?.additionalAddress,
    [ADDRESS_MANAGEMENT_FIELDS.CIVILITY]: customerInformation?.civility,
    [ADDRESS_MANAGEMENT_FIELDS.COUNTRY_CODE]: customerInformation.countryCode,
    [ADDRESS_MANAGEMENT_FIELDS.COMPANY_NAME]: customerInformation?.companyName,
    [ADDRESS_MANAGEMENT_FIELDS.PHONE]: customerInformation?.phone,
    [ADDRESS_MANAGEMENT_FIELDS.KEEP_SUGGESTED_ADDRESS]: keepSuggestedAddress,
    [ADDRESS_MANAGEMENT_FIELDS.HAS_ELEVATOR]:
      customerInformation?.hasElevator?.value === 'true',
    [ADDRESS_MANAGEMENT_FIELDS.HAS_PARKING_SPACE]:
      customerInformation?.hasParkingSpace?.value === 'true',
    [ADDRESS_MANAGEMENT_FIELDS.HOUSING_TYPE]:
      customerInformation?.housingType?.value,
    [ADDRESS_MANAGEMENT_FIELDS.DOOR_CODE]: customerInformation?.doorCode,
    [ADDRESS_MANAGEMENT_FIELDS.FLOOR]: customerInformation?.floor,
  };
};

export default adaptGeoCodeAddressFromDataGouv;
