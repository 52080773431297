import styled from 'styled-components';

export const $LanguageChooserContainer = styled.div`
  min-width: 120px;

  [class*='-ValueContainer'] {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  [class*='-singleValue'] {
    color: #8a94a6;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fonts.weight.normal};
  }
  [class*='-indicatorContainer'] {
    svg {
      fill: #8a94a6;
    }
  }
  [class*='-control'] {
    border: none;
  }
`;
