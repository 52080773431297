import { CardElement } from '@stripe/react-stripe-js';
import { darken } from 'polished';
import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';

const colorBasedOnProps = ({ theme, danger, alert, success }) => {
  if (danger) {return theme.colors.danger;}
  if (alert) {return theme.colors.alert;}
  if (success) {return theme.colors.success;}

  return theme.colors.default;
};

export const $CardElement = styled(CardElement)`
  height: ${rem('56px')};
  padding: ${rem('18px')} ${rem('20px')};
  border-style: solid;
  border-radius: ${({ theme }) => theme.borders.radius};
  border-width: ${({ theme }) => theme.borders.thickness};
  border-color: ${colorBasedOnProps};
  overflow: hidden;
  cursor: text;

  &:focus {
    border-color: ${({ disabled, danger, theme }) =>
      disabled || danger ? null : theme.colors.primary};
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const $SingleErrorMessage = styled(Row)`
  margin: 16px 0;
  svg {
    transform: scale(0.8);
  }
`;

export const $ErrorMessage = styled(Row)`
  margin: 4px 0;
  svg {
    transform: scale(0.8);
  }
`;

export const style = ({ theme }) => ({
  base: {
    color: '#000',
    fontSize: rem(14),
    '::placeholder': {
      color: darken(0.3, theme.colors.disabled),
    },
  },
});
