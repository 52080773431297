import slice from 'lodash/slice';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-styled-flexboxgrid';

import { Loader } from '@savgroup-front-common/core/src/atoms/loader';
import { TimeSlotDisplay } from '@savgroup-front-common/core/src/molecules/AppointmentCard';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import {
  Modal,
  MODAL_SIZES,
} from '@savgroup-front-common/core/src/atoms/modal';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import {
  detailedSchedule,
  isDetailedScheduleLoadingSelector,
} from 'myaccount/domains';

import messages from './messages';
import { ModalContent, ModalRowWithMargin } from './TimerSlotModal.styles';

const TimerSlotModal = ({ previousStep }) => {
  const detailedScheduleValue = useSelector(detailedSchedule);
  const isDetailedScheduleLoading = useSelector(
    isDetailedScheduleLoadingSelector,
  );
  const [isModalOpen, setIsModalOpen] = useState(true);
  const handleClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  if (!isDetailedScheduleLoading && !detailedScheduleValue) {
    return <></>;
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={previousStep}
      size={MODAL_SIZES.MEDIUM}
    >
      <ModalContent>
        {!isDetailedScheduleLoading ? (
          <>
            <Heading level={1}>
              <SafeFormattedMessageWithoutSpread
                message={messages.deposit_Home}
              />
            </Heading>

            <ModalRowWithMargin>
              <Col xs={12}>
                <SafeFormattedMessageWithoutSpread
                  message={messages.descriptionHomePickup}
                />
              </Col>
            </ModalRowWithMargin>

            <TimeSlotDisplay
              detailedSchedule={slice(detailedScheduleValue, 0, 3)}
              isActive={false}
              timeZone="utc"
            />

            <Row end="xs">
              <Col xs={12} sm={3}>
                <Button
                  onClick={handleClose}
                  fluid
                  primary
                  data-timerslotcontinue-button
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.continueButton}
                  />
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <Loader />
        )}
      </ModalContent>
    </Modal>
  );
};

TimerSlotModal.displayName = 'TimerSlotModal';

export default TimerSlotModal;
