import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-styled-flexboxgrid';
import { usePrevious } from 'react-use';

import {
  API_COMMON_ERROR,
  BUTTON_TYPES,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants/src/shared';
import { getActionsErrorsSelector } from '../../domains/sagaRequestMetadata/selectors';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { Banner } from '../../atoms/Banners';
import { ActionRow } from '../../atoms/ActionRow';
import { Button } from '../../atoms/button';
import { Didactic } from '../../atoms/Didactic';
import { Heading } from '../../atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '../../formatters';
import { ChevronLeftIcon, DateIcon } from '../../protons/icons';

import { DetailedSchedule, Interval } from './AppointmentCard.types';
import ConfirmCancelModal from './ConfirmCancelModal';
import messages from './messages';
import TimeSlotDisplay from './TimeSlotDisplay';
import { SET_PICKUP_APPOINTMENT } from '../../domains/workflow/actionTypes';

const haveScheduleProblem = (error = {}) => {
  const { code = '' } = error as any;

  return [
    API_COMMON_ERROR.PROBLEM_WITH_SCHEDULE,
    API_COMMON_ERROR.READY_TIME_LATER_THAN_ALLOWABLE,
  ].includes(code);
};

interface AppointmentCardProps {
  detailedSchedule: DetailedSchedule[];
  appointmentStatus?: any;
  onCancel?: () => void;
  onSubmit: ({ appointmentTime }: { appointmentTime?: Interval }) => void;
  hasButtonPadding?: boolean;
  isLoading?: boolean;
  previousStep?: () => void;
}

const AppointmentCard: FC<AppointmentCardProps> = ({
  detailedSchedule,
  appointmentStatus,
  onCancel,
  onSubmit,
  hasButtonPadding = true,
  isLoading,
  previousStep,
}) => {
  const isAppointmentUpdating = appointmentStatus
    ? !appointmentStatus.get('isLoaded')
    : false;

  const [selectedInterval, setSelectedInterval] = useState<
    Interval | undefined
  >(undefined);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isConfirmCancelOpen, setIsConfirmCancelOpen] = useState(false);

  const setConfirmModalOpen = (isOpen: boolean) => {
    setIsConfirmCancelOpen(isOpen);
  };

  const handleIntervalSelect = (interval: Interval) => {
    if (isEqual(selectedInterval, interval)) {
      return setSelectedInterval(undefined);
    }

    return setSelectedInterval(interval);
  };

  const actions = detailedSchedule.reduce((acc: any, detailedScheduleDay) => {
    const intervals = detailedScheduleDay?.intervals || [];

    return [
      ...acc,
      ...intervals.map((interval: any) => {
        return {
          action: SET_PICKUP_APPOINTMENT,
          indexer: get(interval, 'internalId', undefined),
        };
      }),
    ];
  }, []);

  const selectedAppointmentErrors = useSelector((store: any) =>
    getActionsErrorsSelector(store, actions),
  );
  const haveScheduleError = selectedAppointmentErrors.some(haveScheduleProblem);

  const isAppointmentErrored =
    appointmentStatus && !haveScheduleError
      ? appointmentStatus.getIn(['hasErrors'], false)
      : false;

  const handleSetAppointment = () => {
    setIsLoadingSubmit(true);
    onSubmit({ appointmentTime: selectedInterval });
  };

  const previousIsAppointmentErrored = usePrevious(isAppointmentErrored);

  useEffect(() => {
    if (
      selectedInterval &&
      isAppointmentErrored &&
      !previousIsAppointmentErrored
    ) {
      setSelectedInterval(undefined);
    }
  }, [selectedInterval, isAppointmentErrored, previousIsAppointmentErrored]);

  const handleCloseModal = useCallback(() => setConfirmModalOpen(false), []);

  return (
    <>
      <Heading
        level={2}
        icon={<DateIcon />}
        color="black"
        dataTestId="appointmentCardTitle"
      >
        <SafeFormattedMessageWithoutSpread message={messages.title} />
      </Heading>
      <Didactic>
        <SafeFormattedMessageWithoutSpread message={messages.description} />
      </Didactic>
      <Banner
        isOpen={haveScheduleError}
        notificationType={NOTIFICATION_TYPES.INFO}
      >
        <SafeFormattedMessageWithoutSpread
          message={messages.needToSelectAnotherSchedule}
        />
      </Banner>
      <Row between="md">
        <Col xs={12} md={8} smOffset={0} mdOffset={2}>
          <TimeSlotDisplay
            detailedSchedule={detailedSchedule}
            selectedInterval={selectedInterval}
            onIntervalSelect={handleIntervalSelect}
            timeZone="utc"
            hasButtonPadding={hasButtonPadding}
          />
        </Col>
        <Col xs={12}>
          <Banner
            notificationType={NOTIFICATION_TYPES.ERROR}
            isOpen={isAppointmentErrored}
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.contactSupport}
            />
          </Banner>
        </Col>
        <Col xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }} />
      </Row>
      <ActionRow>
        {previousStep && (
          <Button
            type={BUTTON_TYPES.BUTTON}
            componentThemeName={SUPPORTED_COMPONENTS.BACK_BUTTON}
            naked
            onClick={() => {
              previousStep();
            }}
            icon={<ChevronLeftIcon />}
            isLoading={isLoading}
          >
            <SafeFormattedMessageWithoutSpread message={messages.previous} />
          </Button>
        )}
        <Button
          primary
          disabled={
            !selectedInterval || (isAppointmentErrored && !haveScheduleError)
          }
          onClick={handleSetAppointment}
          isLoading={
            (isAppointmentUpdating && !isAppointmentErrored) ||
            isLoading ||
            isLoadingSubmit
          }
          type={BUTTON_TYPES.BUTTON}
          dataTestId="submitButton"
        >
          <SafeFormattedMessageWithoutSpread message={messages.submitButton} />
        </Button>
      </ActionRow>
      {onCancel && (
        <ConfirmCancelModal
          isConfirmCancelOpen={isConfirmCancelOpen}
          onCloseModal={handleCloseModal}
          onSubmit={onCancel}
        />
      )}
    </>
  );
};

AppointmentCard.displayName = 'AppointmentCard';

export default AppointmentCard;
