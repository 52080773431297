import React, { FunctionComponent, ReactElement } from 'react';

import { FLEX_ALIGN, MessageType } from '@savgroup-front-common/types';

import { safeFormattedIntlString } from '../../formatters';
import { CopyIcon } from '../../protons/icons';
import { CopyField } from '../CopyField';

import {
  $CopyStyle,
  $H1,
  $H2,
  $H3,
  $H4,
  $H5,
  $H6,
  $HeadingContainer,
} from './Heading.styles';

interface HeadingProps {
  level?: number;
  color?: string;
  align?: FLEX_ALIGN;
  dataTestId?: string;
  copy?: MessageType | string;
  icon?: ReactElement;
  isNewBoEnabled?: boolean;
}

const Heading: FunctionComponent<React.PropsWithChildren<HeadingProps>> = ({
  level = 2,
  children,
  copy,
  align = FLEX_ALIGN.FLEX_START,
  color,
  icon,
  dataTestId,
  isNewBoEnabled,
}) => {
  let title;

  const hasCopy =
    Boolean(copy) &&
    (typeof copy === 'string' || Boolean(copy?.id && copy?.defaultMessage));

  switch (level) {
    case 3:
      title = (
        <$H3
          $color={color}
          data-testid={dataTestId}
          $isNewBoEnabled={isNewBoEnabled}
        >
          {icon}
          {children}
        </$H3>
      );
      break;
    case 4:
      title = (
        <$H4
          $color={color}
          data-testid={dataTestId}
          $isNewBoEnabled={isNewBoEnabled}
        >
          {icon}
          {children}
        </$H4>
      );
      break;
    case 5:
      title = (
        <$H5
          $color={color}
          data-testid={dataTestId}
          $isNewBoEnabled={isNewBoEnabled}
        >
          {icon}
          {children}
        </$H5>
      );
      break;
    case 6:
      title = (
        <$H6
          $color={color}
          data-testid={dataTestId}
          $isNewBoEnabled={isNewBoEnabled}
        >
          {icon}
          {children}
        </$H6>
      );
      break;
    case 1:
      title = (
        <$H1
          $color={color}
          data-testid={dataTestId}
          $isNewBoEnabled={isNewBoEnabled}
        >
          {icon}
          {children}
        </$H1>
      );
      break;
    case 2:
    default:
      title = (
        <$H2
          $color={color}
          data-testid={dataTestId}
          $isNewBoEnabled={isNewBoEnabled}
        >
          {icon}
          {children}
        </$H2>
      );
      break;
  }

  return (
    <$HeadingContainer $align={align}>
      <CopyField copy={hasCopy ? safeFormattedIntlString(copy) : undefined}>
        <$CopyStyle $hasCopy={hasCopy}>
          {title}
          {hasCopy ? <CopyIcon size="15px" /> : null}
        </$CopyStyle>
      </CopyField>
    </$HeadingContainer>
  );
};

Heading.displayName = 'Heading';

export default Heading;
