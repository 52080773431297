import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { Grid } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import {
  BUTTON_TYPES,
  HANDLING_GROUPS,
  media,
} from '@savgroup-front-common/constants';
import { ChevronLeftIcon } from '@savgroup-front-common/core/src/protons/icons';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import OldPageHeader from 'myaccount/view/components/OldPageHeader';

import { $BackButton, $ClaimWrapper } from '../../../ClaimGroupHandling.styles';

import AddressForm from './addressForm/AddressForm';
import useAddressManagement from './hooks/useAddressManagement';
import messages from './messages';
import SuggestedAddressModal from './SuggestedAddressModal';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

const AddressManagement = ({
  groupedCarriers,
  isHomePickup,
  updatedAddress,
  onAddressChange,
  onClickNextButton,
  isVerificationPending,
  addressErrors,
  handlingMode,
  goToPreviousStep,
}) => {
  const {
    handleAddressManagementSubmit,
    formContext,
    suggestedAddress,
    isSuggestedModalOpen,
    setIsSuggestedModalOpen,
    isLoading,
    countryOptions,
    hasHome,
  } = useAddressManagement({
    groupedCarriers,
    onAddressChange,
    updatedAddress,
    onClickNextButton,
  });
  const isGridFluidActive = useMedia(media.maxWidth.md);

  return (
    <$ClaimWrapper>
      <Grid fluid={isGridFluidActive}>
        <$BackButton
          onClick={goToPreviousStep}
          naked
          icon={<ChevronLeftIcon />}
          componentThemeName={SUPPORTED_COMPONENTS.BACK_BUTTON}
          type={BUTTON_TYPES.BUTTON}
        >
          <SafeFormattedMessageWithoutSpread message={messages.back} />
        </$BackButton>
        <OldPageHeader
          type={`handling_${
            hasHome
              ? HANDLING_GROUPS.HOME_INTERVENTION
              : handlingMode.toLowerCase()
          }`}
          centerTitle
        />
        <FormProvider {...formContext}>
          <form onSubmit={handleAddressManagementSubmit}>
            <SuggestedAddressModal
              isOpen={
                suggestedAddress &&
                !isVerificationPending &&
                isSuggestedModalOpen
              }
              addressErrors={addressErrors}
              suggestedAddress={suggestedAddress}
              onClose={useCallback(() => {
                setIsSuggestedModalOpen(false);
              }, [setIsSuggestedModalOpen])}
              onSuggestedAddressModalSubmit={() => {
                return handleAddressManagementSubmit();
              }}
              isLoading={isLoading}
              isVerificationPending={isVerificationPending}
            />

            <AddressForm
              isHomePickup={isHomePickup}
              isVerificationPending={isVerificationPending}
              countriesOptions={countryOptions}
              isLoading={isLoading}
              hasHome={hasHome}
            />
          </form>
        </FormProvider>
      </Grid>
    </$ClaimWrapper>
  );
};

AddressManagement.propTypes = {
  onAddressChange: PropTypes.func,
  updatedAddress: PropTypes.shape({}),
};

AddressManagement.defaultProps = {
  updatedAddress: null,
  onAddressChange: () => {},
};

AddressManagement.displayName = 'AddressManagement';

export default AddressManagement;
