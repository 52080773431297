import { defineMessages } from 'react-intl';

export default defineMessages({
  distance: {
    id: 'components.myaccount.claims.handling.pickup.distance',
    defaultMessage: 'at',
  },
  next: {
    id: 'components.myaccount.claims.handling.pickup.next',
    defaultMessage: 'Next',
  },
  dropOffPoint: {
    id: 'components.myaccount.claims.handling.pickup.dropOffPoint',
    defaultMessage: 'Drop off point',
  },
  carrier: {
    id: 'components.myaccount.claims.handling.pickup.carrier',
    defaultMessage: 'Carriers',
  },
  openingHours: {
    id: 'components.myaccount.claims.handling.pickup.openingHours',
    defaultMessage: 'Opening hours',
  },
  free: {
    id: 'components.myaccount.claims.handling.pickup.free',
    defaultMessage: 'Free',
  },
  close: {
    id: 'components.myaccount.claims.handling.pickup.close',
    defaultMessage: 'Close',
  },
  Deposit_Pickup: {
    id: 'components.myaccount.claims.handling.carrier.Deposit_Pickup',
    defaultMessage: 'Drop off point',
  },
  Deposit_PickupPostOffice: {
    id: 'components.myaccount.claims.handling.carrier.Deposit_PickupPostOffice',
    defaultMessage: 'Drop off at post-office',
  },
  Deposit_DropAtStore: {
    id: 'components.myaccount.claims.handling.carrier.Deposit_DropAtStore',
    defaultMessage: 'Drop off at store',
  },
  Delivery_Pickup: {
    id: 'components.myaccount.claims.handling.carrier.Delivery_Pickup',
    defaultMessage: 'Pickup point',
  },
  Delivery_PickupPostOffice: {
    id: 'components.myaccount.claims.handling.carrier.Delivery_PickupPostOffice',
    defaultMessage: 'Pickup at post-office',
  },
  Delivery_DropAtStore: {
    id: 'components.myaccount.claims.handling.carrier.Delivery_DropAtStore',
    defaultMessage: 'Pick up at store',
  },
  maxDistance: {
    id: 'general.maxDistance.label',
    defaultMessage: 'Maximum distance (Km)',
  },
  back: {
    id: 'components.common.addressManagement.back',
    defaultMessage: 'Retour',
  },
});
