import React from 'react';

import Icon from './Icon';

interface CalendarIconProps {
  color?: string;
  size?: string;
}

const CalendarIcon: React.FC<CalendarIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M25 6.66667V3.33333M25 6.66667V10M25 6.66667H17.5M5 16.6667V10C5 9.11594 5.35119 8.2681 5.97631 7.64298C6.60143 7.01785 7.44928 6.66667 8.33333 6.66667H11.6667M11.6667 3.33333V10M35 16.6667V10C35 9.11594 34.6488 8.2681 34.0237 7.64298C33.3986 7.01785 32.5507 6.66667 31.6667 6.66667H30.8333M5 16.6667V31.6667C5 32.5507 5.35119 33.3986 5.97631 34.0237C6.60143 34.6488 7.44928 35 8.33333 35H31.6667C32.5507 35 33.3986 34.6488 34.0237 34.0237C34.6488 33.3986 35 32.5507 35 31.6667V16.6667H5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

CalendarIcon.displayName = 'CalendarIcon';

export default CalendarIcon;
