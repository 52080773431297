import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import map from 'lodash/map';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Col } from 'react-styled-flexboxgrid';

import { CURRENCIES } from '@savgroup-front-common/constants/src/shared';
import { Paragraph } from '@savgroup-front-common/core/src/atoms/Paragraph';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { INVOICE_ITEM_KIND } from '@savgroup-front-common/types';

import {
  $ItemCategoryH4,
  $ItemH4,
  $OrderSummary,
  $PriceCol,
  $SubItemH4,
  $SummaryItem,
  $SummaryTitle,
  $TotalPrice,
  $TotalPriceBold,
  $VatPriceNotice,
} from './InvoiceSummary.styles';
import messages from './messages';

const InvoiceSummary = ({
  items,
  currency,
  totalPriceWithTax,
  quote,
  styleFormatter,
}) => {
  const quoteItemSummaries = get(quote, 'quoteItemSummaries', []);

  return (
    <$OrderSummary>
      <Card>
        <$SummaryTitle heading>
          <FormattedMessage {...messages.title} />
        </$SummaryTitle>

        {flatMap(items, (item) => [
          <$SummaryItem between="xs" key={item.kind}>
            {size(get(item, 'subItems')) !== 1 && (
              <>
                <Col xs={8} sm={10}>
                  <$ItemCategoryH4 heading>
                    <FormattedMessage {...messages[item.kind]} />
                  </$ItemCategoryH4>
                </Col>
                <$PriceCol xs={4} sm={2}>
                  <$ItemCategoryH4 heading>
                    <FormattedNumber
                      value={item.priceWithTax}
                      style={styleFormatter}
                      currency={currency}
                    />
                  </$ItemCategoryH4>
                </$PriceCol>
              </>
            )}

            {item.kind === INVOICE_ITEM_KIND.QUOTE && quoteItemSummaries.length
              ? quoteItemSummaries.map((quoteItem) => (
                  <React.Fragment key={quoteItem.id}>
                    <Col xs={8} sm={10}>
                      <$ItemH4 paragraph>
                        <FormattedMessage
                          id={item.referenceId}
                          defaultMessage={quoteItem.name}
                        />
                      </$ItemH4>
                    </Col>
                    <$PriceCol xs={4} sm={2}>
                      <$ItemH4 paragraph>
                        <FormattedNumber
                          value={quoteItem.bundlePrice}
                          style={styleFormatter}
                          currency={currency}
                        />
                      </$ItemH4>
                    </$PriceCol>
                  </React.Fragment>
                ))
              : null}

            {map(item.subItems, (subItem, itemIndex) => (
              <React.Fragment key={itemIndex}>
                <Col xs={8} sm={10}>
                  <$SubItemH4 paragraph subItem={size(get(item, 'subItems'))}>
                    <FormattedMessage {...messages[subItem.kind]} />
                  </$SubItemH4>
                </Col>
                <$PriceCol xs={4} sm={2}>
                  <$ItemH4 paragraph>
                    <FormattedNumber
                      value={subItem.priceWithTax}
                      style={styleFormatter}
                      currency={currency}
                    />
                  </$ItemH4>
                </$PriceCol>
              </React.Fragment>
            ))}
          </$SummaryItem>,
        ])}

        <$TotalPrice>
          <Col xs={8}>
            <$TotalPriceBold heading>
              <FormattedMessage {...messages.totalPrice} />
            </$TotalPriceBold>
          </Col>
          <$PriceCol xs={4}>
            <$TotalPriceBold heading>
              <FormattedNumber
                value={totalPriceWithTax}
                style={styleFormatter}
                currency={currency}
              />
            </$TotalPriceBold>
          </$PriceCol>
        </$TotalPrice>

        <$VatPriceNotice reverse>
          <FormattedMessage {...messages.PricesVatIncl}>
            {(txt) => <Paragraph> {txt} </Paragraph>}
          </FormattedMessage>
        </$VatPriceNotice>
      </Card>
    </$OrderSummary>
  );
};

InvoiceSummary.propTypes = {
  currency: PropTypes.string,
  styleFormatter: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      priceWithTax: PropTypes.number.isRequired,
    }),
  ).isRequired,
  totalPriceWithTax: PropTypes.number.isRequired,
  quote: PropTypes.shape({
    quotesMap: PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      comments: PropTypes.string.isRequired,
      createdDate: PropTypes.string.isRequired,
      editedDate: PropTypes.string.isRequired,
      amountVAT: PropTypes.number.isRequired,
      amountWithoutVAT: PropTypes.number.isRequired,
      quoteItemSummaries: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          amountForParts: PropTypes.number,
          amountForLabor: PropTypes.number,
          amountPackage: PropTypes.number,
          valueAddedTax: PropTypes.number,
        }),
      ),
    }),
  }),
};

InvoiceSummary.defaultProps = {
  currency: CURRENCIES.EUR,
  styleFormatter: 'currency',
  quote: {
    quotesMap: undefined,
  },
};

InvoiceSummary.displayName = 'InvoiceSummary';

export default InvoiceSummary;
