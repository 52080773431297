import React from 'react';

import Icon from './Icon';

interface MediaImageIconProps {
  color?: string;
  size?: string;
}

const MediaImageIcon: React.FC<MediaImageIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M35 6V34C35 34.2652 34.8946 34.5196 34.7071 34.7071C34.5196 34.8946 34.2652 35 34 35H6C5.73478 35 5.48043 34.8946 5.29289 34.7071C5.10536 34.5196 5 34.2652 5 34V6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H34C34.2652 5 34.5196 5.10536 34.7071 5.29289C34.8946 5.48043 35 5.73478 35 6V6Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 26.6667L16.6667 21.6667L35 30M26.6667 16.6667C25.7826 16.6667 24.9348 16.3155 24.3096 15.6904C23.6845 15.0652 23.3333 14.2174 23.3333 13.3333C23.3333 12.4493 23.6845 11.6014 24.3096 10.9763C24.9348 10.3512 25.7826 10 26.6667 10C27.5507 10 28.3986 10.3512 29.0237 10.9763C29.6488 11.6014 30 12.4493 30 13.3333C30 14.2174 29.6488 15.0652 29.0237 15.6904C28.3986 16.3155 27.5507 16.6667 26.6667 16.6667V16.6667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

MediaImageIcon.displayName = 'MediaImageIcon';

export default MediaImageIcon;
