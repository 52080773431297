import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';

import messages from '../messages';

import PickupPointItem from './PickupPointItem';
import {
  Container,
  ListContainer,
  NextButtonContainer,
} from './PickupPointList.styles';

const { number, string, shape, arrayOf, array } = PropTypes;

export const pickupPointShape = shape({
  id: string,
  name: string,
  maxWeight: number,
  type: string,
  adress: string,
  distance: number,
  latitude: number,
  longitude: number,
  countryCode: string,
  postalCode: string,
  city: string,
  openingHours: arrayOf(
    shape({
      day: string,
      hours: array,
    }),
  ),
  periodClosings: array,
});

const PickupPointList = ({
  pickupPoints,
  registerCard,
  selectedPickupPointId,
  onSelectPickupPoint,
  priceFormat,
}) => {
  const formContext = useFormContext();
  const {
    formState: { isSubmitSuccessful },
  } = formContext;

  return (
    <Container>
      <ListContainer
        selectionDone={!!selectedPickupPointId}
        data-pickuppoints-list
      >
        {pickupPoints.map((pickupPoint) => (
          <PickupPointItem
            className="pickupPoint"
            key={`pickupPoint-${pickupPoint.id}`}
            registerCard={registerCard(pickupPoint.id)}
            pickupPoint={pickupPoint}
            priceFormat={priceFormat}
            onSelectPickupPoint={onSelectPickupPoint}
            selectedPickupPointId={selectedPickupPointId}
          />
        ))}
      </ListContainer>
      <NextButtonContainer disabled={!selectedPickupPointId}>
        <Col xs={12}>
          <Button
            fluid
            primary
            disabled={!selectedPickupPointId}
            data-pickupnextstep-button
            type={BUTTON_TYPES.SUBMIT}
            isLoading={isSubmitSuccessful}
          >
            <FormattedMessage {...messages.next} />
          </Button>
        </Col>
      </NextButtonContainer>
    </Container>
  );
};

PickupPointList.propTypes = {
  onSelectPickupPoint: PropTypes.func.isRequired,
  pickupPoints: PropTypes.arrayOf(pickupPointShape).isRequired,
  selectedPickupPointId: PropTypes.string,
};

PickupPointList.defaultProps = {
  selectedPickupPointId: null,
};

PickupPointList.displayName = 'PickupPointList';

export default PickupPointList;
