import React from 'react';

import Icon from './Icon';

interface SmallShopIconProps {
  color?: string;
  size?: string;
}

const SmallShopIcon: React.FC<SmallShopIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M5 16.6667V31.6667C5 32.5507 5.35119 33.3986 5.97631 34.0237C6.60143 34.6488 7.44928 35 8.33333 35H31.6667C32.5507 35 33.3986 34.6488 34.0237 34.0237C34.6488 33.3986 35 32.5507 35 31.6667V16.6667"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M24.7217 35V25C24.7217 24.1159 24.3705 23.2681 23.7454 22.643C23.1202 22.0179 22.2724 21.6667 21.3883 21.6667H18.055C17.171 21.6667 16.3231 22.0179 15.698 22.643C15.0729 23.2681 14.7217 24.1159 14.7217 25V35"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="16"
      />
      <path
        d="M36.3635 15.6067L33.5402 5.725C33.4804 5.51608 33.3542 5.33231 33.1807 5.2015C33.0072 5.07069 32.7958 4.99995 32.5785 5H25.8335L26.6252 14.5067C26.6371 14.6595 26.6854 14.8073 26.766 14.9378C26.8466 15.0682 26.9572 15.1776 27.0885 15.2567C27.7385 15.645 29.0085 16.3617 30.0002 16.6667C31.6935 17.1883 34.1668 17 35.5768 16.8267C35.7139 16.8089 35.8455 16.7615 35.9623 16.6877C36.0792 16.6139 36.1785 16.5155 36.2534 16.3993C36.3283 16.2832 36.377 16.1521 36.396 16.0152C36.415 15.8782 36.4039 15.7388 36.3635 15.6067Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M23.3335 16.6667C24.2802 16.375 25.4802 15.71 26.1502 15.3133C26.3063 15.2204 26.433 15.0851 26.5156 14.9232C26.5982 14.7613 26.6333 14.5793 26.6168 14.3983L25.8335 5H14.1668L13.3835 14.3983C13.3667 14.5796 13.4016 14.7619 13.4842 14.9242C13.5668 15.0864 13.6937 15.2219 13.8502 15.315C14.5202 15.71 15.7202 16.375 16.6668 16.6667C19.1552 17.4333 20.8452 17.4333 23.3335 16.6667V16.6667Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M6.46009 5.725L3.63675 15.6083C3.5969 15.7403 3.58625 15.8794 3.60553 16.0159C3.62482 16.1524 3.67357 16.283 3.74842 16.3988C3.82327 16.5145 3.92242 16.6126 4.03898 16.6862C4.15554 16.7598 4.28672 16.8072 4.42342 16.825C5.83175 17 8.30675 17.1867 10.0001 16.6667C10.9918 16.3617 12.2634 15.645 12.9118 15.2583C13.0433 15.1791 13.154 15.0695 13.2346 14.9387C13.3152 14.808 13.3634 14.6598 13.3751 14.5067L14.1668 5H7.42175C7.20446 4.99995 6.99305 5.07069 6.81954 5.2015C6.64603 5.33231 6.51984 5.51608 6.46009 5.725V5.725Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </Icon>
  );
};

SmallShopIcon.displayName = 'SmallShopIcon';

export default SmallShopIcon;
