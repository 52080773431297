import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import {
  BUTTON_TYPES,
  CURRENCIES,
  HANDLING_GROUPS,
  HANDLING_TYPES,
  media,
} from '@savgroup-front-common/constants';
import { ChevronLeftIcon } from '@savgroup-front-common/core/src/protons/icons';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import {
  AddressInfoDto,
  PickupPoint,
  SUPPORTED_COMPONENTS,
} from '@savgroup-front-common/types';

import { Carrier } from '../../../ClaimGroupHandling.types';
import { AddressSelected, PickupPoints } from '../CarrierForm.types';

import Address from './Address';
import PickupPointList from './components/PickupPointList';
import PickupPointMap from './components/PickupPointMap';
import messages from './messages';
import {
  $AddressContainer,
  $BackButtonContainer,
  $ColWithoutGutter,
  $SelectContainerRow,
} from './PickupPointSelector.styles';

interface PickupPointSelectorProps {
  onClickBackButton: () => void;
  pickupPoints: PickupPoints;
  maxDistance: number;
  onAddressSelected: ({ address, countryCode }: AddressSelected) => void;
  searchAddress: AddressSelected;
  userAddresses: AddressInfoDto[];
  pickupPointsIsLoading: boolean;
  selectedCarrierGroup: HANDLING_GROUPS;
  carriers: Carrier[];
  onSelectPickupPoint: (payload: PickupPoint) => void;
  selectedPickupPointId?: string;
  priceFormat: CURRENCIES;
}

const PickupPointSelector: FunctionComponent<
  React.PropsWithChildren<PickupPointSelectorProps>
> = ({
  onClickBackButton,
  pickupPoints,
  maxDistance,
  onAddressSelected,
  searchAddress,
  userAddresses,
  pickupPointsIsLoading,
  selectedCarrierGroup,
  carriers,
  onSelectPickupPoint,
  selectedPickupPointId,
  priceFormat,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);

  const cardRefs: any = {};

  const getPlacesValues = () => {
    const placesValue = _.uniqBy(carriers, 'productType').map(
      ({ productType }) => {
        return {
          value: productType,
        };
      },
    );

    return placesValue;
  };

  const getDefaultPlace = (): any => {
    const placesValue = getPlacesValues();

    if (selectedCarrierGroup === HANDLING_GROUPS.PICKUP_STORE) {
      return _.filter(placesValue, { value: HANDLING_TYPES.DROP_AT_STORE });
    }

    return _.filter(
      placesValue,
      ({ value }) => value && value !== HANDLING_TYPES.DROP_AT_STORE,
    );
  };

  const getCarriersFiltered = (): Carrier[] => {
    const filterPlace = getDefaultPlace();

    return _.filter(carriers, (carrier) =>
      _.find(filterPlace, { value: carrier.productType }),
    ) as Carrier[];
  };

  const registerCard = (id: string) => (ref: any) => {
    cardRefs[id] = ref;

    const currentPickuppointsIds = _.flatMap(
      _.flatMap(_.flatMap(_.values(pickupPoints), _.values), (p) => p.points),
      (p) => p.id,
    );

    const pickupPointRefsToRemove = _.difference(
      _.keys(cardRefs),
      currentPickuppointsIds,
    );

    pickupPointRefsToRemove.forEach((oldId) => {
      delete cardRefs[oldId];
    });
  };

  const selectPickupPointOnMap = (selectedPickupPointOnMap: PickupPoint) => {
    const id = _.get(selectedPickupPointOnMap, 'id');
    const cardElement = cardRefs[id];

    if (cardElement) {
      cardElement.scrollIntoView();
    }
    onSelectPickupPoint(selectedPickupPointOnMap);
  };

  const filteredCarriers = getCarriersFiltered();
  const filteredCarrierBrands = _.uniq(_.map(filteredCarriers, 'carrierBrand'));
  const filteredProductTypes = _.uniq(_.map(filteredCarriers, 'productType'));

  const filteredPickupPoints = _.orderBy(
    _.uniqBy(
      _.filter(
        _.flatMap(filteredProductTypes, (type) =>
          _.flatMap(filteredCarrierBrands, (carrier) =>
            _.map(_.get(pickupPoints, [type, carrier, 'points']), (p) => ({
              ...p,
              ..._.find(filteredCarriers, {
                carrierBrand: carrier,
                productType: type,
              }),
            })),
          ),
        ),
        (pickupPoint) => _.get(pickupPoint, 'adress'),
      ),
      'id',
    ),
    'distanceInMeters',
    'asc',
  );

  return (
    <$SelectContainerRow reverse>
      <$ColWithoutGutter xs={12} sm={4} lg={3}>
        <Row>
          <$BackButtonContainer xs={12}>
            <Button
              onClick={onClickBackButton}
              icon={<ChevronLeftIcon />}
              naked
              componentThemeName={SUPPORTED_COMPONENTS.BACK_BUTTON}
              type={BUTTON_TYPES.BUTTON}
            >
              <SafeFormattedMessageWithoutSpread message={messages.back} />
            </Button>
          </$BackButtonContainer>
        </Row>

        <$AddressContainer>
          <Address
            onAddressSelected={onAddressSelected}
            searchAddress={searchAddress}
            maxDistance={maxDistance}
            userAddresses={userAddresses}
          />
        </$AddressContainer>

        {pickupPointsIsLoading ? (
          <BaseLoader />
        ) : (
          <>
            {isMobileView && (
              <Row>
                <PickupPointMap
                  onSelectPickupPoint={selectPickupPointOnMap}
                  pickupPoints={filteredPickupPoints}
                  searchAddress={searchAddress}
                  selectedPickupPointId={selectedPickupPointId}
                />
              </Row>
            )}
            <Row>
              <PickupPointList
                selectedPickupPointId={selectedPickupPointId}
                onSelectPickupPoint={onSelectPickupPoint}
                priceFormat={priceFormat}
                registerCard={registerCard}
                pickupPoints={filteredPickupPoints}
              />
            </Row>
          </>
        )}
      </$ColWithoutGutter>

      {pickupPointsIsLoading && !isMobileView ? (
        <BaseLoader />
      ) : (
        <Col sm>
          <PickupPointMap
            searchAddress={searchAddress}
            selectedPickupPointId={selectedPickupPointId}
            onSelectPickupPoint={selectPickupPointOnMap}
            pickupPoints={filteredPickupPoints}
          />
        </Col>
      )}
    </$SelectContainerRow>
  );
};

PickupPointSelector.displayName = 'PickupPointSelector';

export default PickupPointSelector;
