import * as Yup from 'yup';

import {
  globalMessages,
  isEmail,
  isValidPhoneNumber,
} from '@savgroup-front-common/core/src/helpers';

import { CONFIRMATION_FIELDS } from './Confirmation.helpers';
import messages from './messages';

const profileSchema = ({ countryCode, hasStoreActor }) => {
  const validationField = {
    [CONFIRMATION_FIELDS.PHONE]: Yup.string()
      .required(globalMessages.form.required)
      .test('test-phone', messages.phoneMustBeValid, function test(value) {
        if (value === undefined) {
          return false;
        }

        return isValidPhoneNumber(value, countryCode);
      }),
    [CONFIRMATION_FIELDS.MAIL]: Yup.string()
      .required(globalMessages.form.required)
      .test('test-email', messages.emailMustBeValid, function test(value) {
        if (value === undefined) {
          return false;
        }

        return isEmail(value);
      }),
  };

  if (hasStoreActor) {
    validationField[CONFIRMATION_FIELDS.STORE_ACTOR] = Yup.object().required(
      globalMessages.form.required,
    );
  }

  return Yup.object().shape(validationField);
};

export default profileSchema;
