import { Dictionary } from 'lodash';
import find from 'lodash/find';
import get from 'lodash/get';
import { UseFormReturn } from 'react-hook-form';

import { HANDLING_GROUPS } from '@savgroup-front-common/constants';
import { StrictNextStep } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { PickupPoint } from '@savgroup-front-common/types';

import { Carrier } from '../../../ClaimGroupHandling.types';
import { AddressSelected, CarrierFormValues } from '../CarrierForm.types';

interface UseHandleSubmitPickupPointsArgs {
  nextStep: StrictNextStep<CarrierFormValues>;
  groupedCarriers: Dictionary<Carrier[]>;
  selectedCarrierType: HANDLING_GROUPS;
  selectedPickupPoint?: PickupPoint;
  addressSelected?: AddressSelected;
  formContext: UseFormReturn;
}

const useHandleSubmitPickupPoints = ({
  nextStep,
  groupedCarriers,
  selectedCarrierType,
  selectedPickupPoint,
  addressSelected,
  formContext,
}: UseHandleSubmitPickupPointsArgs): (() => void) => {
  const { handleSubmit } = formContext;

  return handleSubmit(() => {
    const address = addressSelected?.defaultAddress;
    const selectedCarrier = find(
      groupedCarriers[selectedCarrierType],
      (carrier) =>
        get(carrier, 'carrierId') === get(selectedPickupPoint, 'carrierId'),
    );

    nextStep({ selectedCarrier, selectedPickupPoint, address });
  });
};

export default useHandleSubmitPickupPoints;
