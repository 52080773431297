import { CommonAttachmentService } from '@savgroup-front-common/core/src/api';
import { HTMLFileInputElement } from '@savgroup-front-common/types';

export const uploadAdditionalInformationFilesToClaim = async ({
  neededInformations,
  reasonAdditionalInformationDocument,
  claimId,
}: {
  neededInformations?: ({
    internalId: string;
    fileUploadEndpoints: Record<string, { uploadEndpoint: string }>;
  } & any)[];
  reasonAdditionalInformationDocument?: Record<string, unknown>;
  claimId: string;
}) => {
  if (!neededInformations) {
    return undefined;
  }

  const neededInformationParams = neededInformations
    .filter(
      (neededInformation) =>
        reasonAdditionalInformationDocument &&
        reasonAdditionalInformationDocument[neededInformation.internalId] &&
        (
          reasonAdditionalInformationDocument[
            neededInformation.internalId
          ] as HTMLFileInputElement
        ).value,
    )
    .map((neededInformation) => {
      if (
        !reasonAdditionalInformationDocument ||
        !neededInformation.fileUploadEndpoints
      ) {
        return undefined;
      }

      return {
        id: neededInformation.id,
        file: (
          reasonAdditionalInformationDocument[
            neededInformation.internalId
          ] as HTMLFileInputElement
        ).value,
        fileUploadUrl: neededInformation.fileUploadEndpoints[claimId],
      };
    });

  const responses = [];

  for (const neededInformationParam of neededInformationParams) {
    if (neededInformationParam) {
      // eslint-disable-next-line no-await-in-loop
      const response = await CommonAttachmentService.uploadAttachment<string>({
        file: neededInformationParam.file as unknown as File,
        endpoint: neededInformationParam.fileUploadUrl.uploadEndpoint,
      });

      responses.push(response);
    }
  }

  return responses;
};
