import { tint } from 'polished';
import styled from 'styled-components';

import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Tag } from '@savgroup-front-common/core/src/atoms/Tag';
import { rem } from '@savgroup-front-common/core/src/helpers';
import { Col, Row } from 'react-styled-flexboxgrid';

export const InfoLine = styled(Row)`
  display: flex;
  align-items: center;
  padding: 24px 0;

  div {
    &:last-child {
      text-align: right;
    }
  }
`;

export const CardSpacer = styled(Card)`
  margin-top: 1rem !important;
`;

export const InfoCol = styled(Col)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const InfoTag = styled(Tag)`
  align-self: flex-end;
`;

export const Separator = styled('div')`
  background-color: ${({ theme }) => tint(0.6, theme.colors.default)};
  height: 1px;
  margin-bottom: ${rem('6px')};
`;

export const HeaderContainer = styled.div`
  background-color: #ffffff;
  position: relative;
  margin-bottom: 2rem;
  padding-top: 2.5rem;
`;

export const Tab = styled(Col)`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-basis: auto;
  padding-right: 16px;
  margin: 0 0.5rem;
  cursor: pointer;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const $FileHeader = styled(Heading)`
  padding-top: 0;
  @media (max-width: 767px) {
    padding-bottom: 10px;
  }
`;

export const $LinkHeader = styled.div`
  margin-bottom: 1rem;
`;
