import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const $File = styled(Button)`
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.default};
  border-width: ${({ theme }) => theme.borders.thickness};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const $FileContainer = styled('div')`
  margin-left: 8px;
  margin-right: 8px;
  width: calc(100% - 16px);
  display: flex;
  flex-direction: row;
  align-items: center;
  > span {
    margin: 0 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.headingTextColor};
    display: flex;
  }
`;
