import { createAction } from '@reduxjs/toolkit';

import * as ActionTypes from './actionTypes';
import { MODAL_TYPES } from './constants';

export const openModal = createAction(ActionTypes.OPEN_MODAL);
export const closeModal = createAction(ActionTypes.CLOSE_MODAL);

export const openOtherIssueModal = (data) =>
  openModal({ type: MODAL_TYPES.OTHER_ISSUE_MODAL, data });

export const submitOtherIssueModal = createAction(
  ActionTypes.SUBMIT_OTHER_ISSUE_MODAL,
);
