import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'view.quotation.refuseQuotation.title',
    defaultMessage: 'Refuse quotation ?',
  },
  destructionOption: {
    id: 'view.quotation.destructionOption',
    defaultMessage: 'Destruct product',
  },
  returnOption: {
    id: 'view.quotation.returnOption',
    defaultMessage: 'Return product',
  },

  buttonCancel: {
    id: 'view.component.confirmModal.cancelButton',
    defaultMessage: 'Cancel',
  },
  buttonReject: {
    id: 'view.quotation.buttonReject',
    defaultMessage: 'Reject',
  },
});
