import get from 'lodash/get';

import { ExtendedFile } from '@savgroup-front-common/types';

import { getFinalFieldState } from '../../../common/helpers/getFinalFieldState';
import { FieldMessages } from '../../../common/helpers/getFinalFieldState.types';
import messages from '../messages';

interface UseAttachmentItemArgs {
  isLiveUpload?: boolean;
  file: ExtendedFile;
  errors: FieldMessages;
  name: string;
}

const useAttachmentItem = ({
  file,
  isLiveUpload = true,
  errors,
  name,
}: UseAttachmentItemArgs) => {
  const fileName = get(file, 'name', '');
  const formError = get(errors, name);
  const progress = get(file, 'progress', 0);

  if (isLiveUpload) {
    const isSuccess = progress === 100 && !formError;

    const [status, message] = getFinalFieldState({
      name,
      errors: { isStatus: false, messages: errors },
      successes: isSuccess
        ? {
            isStatus: false,
            messages: { [name]: messages.uploadSucceeded },
          }
        : undefined,
    });

    return {
      progress,
      fileName,
      message,
      status,
    };
  }

  const [status, message] = getFinalFieldState({
    name,
    errors: { isStatus: false, messages: errors },
  });

  return {
    progress,
    fileName,
    message,
    status,
  };
};

export default useAttachmentItem;
