import React from 'react';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { BaseErrorBoundary } from '@savgroup-front-common/core/src/molecules/ErrorBoundary';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import useLoadOrder from './hooks/useLoadOrder';
import messages from './messages';
import { useLayout } from './NewLayout.hooks';
import { useIsBTobEnabled } from '../hooks/useIsBTobEnabled';
import ClassiqueLayout from './ClassiqueLayout';
import ProfessionalLayout from './ProfessionalLayout';
import InitProvider from './InitProvider/InitProvider.context';

const NewLayout: React.FC<unknown> = () => {
  const {
    sellerId,
    ownerId,
    login,
    enableServicePortal,
    errors,
    errorActions,
  } = useLayout();
  const isBtoBPortalEnabled = useIsBTobEnabled();
  const { orders, openedFiles, closedFiles, isLoading } = useLoadOrder({
    ownerId,
  });

  if (errors.hasError) {
    return (
      <BaseErrorBoundary bodyMessage={errors.message}>
        <Button
          type={BUTTON_TYPES.BUTTON}
          onClick={() => errorActions.clearErrors}
        >
          <SafeFormattedMessageWithoutSpread message={messages.close} />
        </Button>
      </BaseErrorBoundary>
    );
  }

  if (!sellerId || isLoading) {
    return <BaseLoader />;
  }

  if (isBtoBPortalEnabled) {
    return (
      <InitProvider
        ownerId={ownerId}
        login={login}
        orders={orders}
        openedFiles={openedFiles}
        closedFiles={closedFiles}
        enableServicePortal={enableServicePortal}
      >
        <ProfessionalLayout sellerId={sellerId} />
      </InitProvider>
    );
  }

  return (
    <InitProvider
      ownerId={ownerId}
      login={login}
      orders={orders}
      openedFiles={openedFiles}
      closedFiles={closedFiles}
      enableServicePortal={enableServicePortal}
    >
      <ClassiqueLayout sellerId={sellerId} />
    </InitProvider>
  );
};

NewLayout.whyDidYouRender = true;
NewLayout.displayName = 'NewLayout';

export default NewLayout;
