import { APIConfiguration } from '@savgroup-front-common/configuration/src';

export const printDocumentVoucher = ({
  returnVoucherUrl,
}: {
  returnVoucherUrl: string;
}) => {
  const downloadWindow = window.open();

  if (!downloadWindow) {
    return;
  }

  if (returnVoucherUrl) {
    const docUrl = APIConfiguration.base + returnVoucherUrl;

    downloadWindow.location.href = docUrl;
  } else {
    downloadWindow.close();
  }
};
