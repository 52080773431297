import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

import {
  BUTTON_TYPES,
  REVALIDATE_MODES,
} from '@savgroup-front-common/constants';
import { QuoteClientAnswer } from '@savgroup-front-common/types/src/Quotation/QuoteClientAnswer';

import { Button } from '../../../../atoms/button';
import { Heading } from '../../../../atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '../../../../formatters';
import { RadioButtonHookForm } from '../../../../molecules/Form';

import { schema } from './ConfirmQuotationRejectModalContent.schema';
import { $ActionRow } from './ConfirmQuotationRejectModalContent.styles';
import messages from './messages';
import { ModalHeader } from '../../../../atoms/modal';
import { FormGroup } from '../../../../atoms/Form';

interface ConfirmQuotationRejectModalContentProps {
  onRejectWithReturn: () => void;
  onRejectWithDestruction: () => void;
  onClose: () => void;
}

const ConfirmQuotationRejectModalContent: FC<
  ConfirmQuotationRejectModalContentProps
> = ({ onRejectWithReturn, onRejectWithDestruction, onClose }) => {
  const { register, formState, handleSubmit } = useForm<{
    rejectOption:
      | QuoteClientAnswer.REJECTED_WITH_DESTRUCTION
      | QuoteClientAnswer.REJECTED_WITH_RETURN;
  }>({
    resolver: yupResolver(schema),
    mode: REVALIDATE_MODES.ALL,
  });

  return (
    <form
      onSubmit={handleSubmit(({ rejectOption }) => {
        if (rejectOption === QuoteClientAnswer.REJECTED_WITH_DESTRUCTION) {
          onRejectWithDestruction();

          return undefined;
        }

        if (rejectOption === QuoteClientAnswer.REJECTED_WITH_RETURN) {
          onRejectWithReturn();

          return undefined;
        }

        return undefined;
      })}
    >
      <ModalHeader>
        <Heading level={3}>
          <SafeFormattedMessageWithoutSpread message={messages.title} />
        </Heading>
      </ModalHeader>

      <FormGroup>
        <RadioButtonHookForm
          name="rejectOption"
          value={QuoteClientAnswer.REJECTED_WITH_RETURN}
          label={messages.returnOption}
          register={register}
        />
      </FormGroup>
      <FormGroup>
        <RadioButtonHookForm
          name="rejectOption"
          value={QuoteClientAnswer.REJECTED_WITH_DESTRUCTION}
          label={messages.destructionOption}
          register={register}
        />
      </FormGroup>

      <$ActionRow>
        <Button tertiary type={BUTTON_TYPES.BUTTON} onClick={() => onClose()}>
          <SafeFormattedMessageWithoutSpread message={messages.buttonCancel} />
        </Button>
        <Button type={BUTTON_TYPES.SUBMIT} danger disabled={!formState.isValid}>
          <SafeFormattedMessageWithoutSpread message={messages.buttonReject} />
        </Button>
      </$ActionRow>
    </form>
  );
};

ConfirmQuotationRejectModalContent.displayName =
  'ConfirmQuotationRejectModalContent';

export default ConfirmQuotationRejectModalContent;
