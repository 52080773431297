import { AddressInfo } from './AddressInfo';
import { Document } from './Document';
import { Product } from './Product';

export type ClaimId = string;

export interface AdditionalClaimInformation {
  additionalInformationId: string;
  additionalInformationEnumValue?: string;
  additionalInformationStringValue?: string;
  additionalInformationLabel?: string;
  additionalInformationFileValue?: {
    fileName: string;
    url: string;
    customerVisibility: boolean;
  };
}

interface Comment {
  contents: string;
  type: string;
}

interface MatchingActors {
  hasValidAddress: boolean;
  identity: {
    id: string;
    type: string;
  };
  priority: number;
  score: number;
}

export interface Claim {
  additionalClaimInformation?: AdditionalClaimInformation[];
  claimId?: string;
  groupId?: string;
  products?: Product;
  orderLineReference?: string;
  ownerProductId?: string;
  ignoreDateRulesChecking?: boolean;
  issueId?: string;
  ownerId?: string;
  reasonId?: string;
  sellerId?: string;
  solutionTypeId?: string;
  state: string;
  claimCreationDateUtc: string;
}

export interface FullClaim {
  actorId: string;
  actorType: string;
  additionalInformation: AdditionalClaimInformation[];
  additionalClaimInformation: AdditionalClaimInformation[];
  claimId: string;
  comments: Comment[];
  currencyCode: string;
  declarationDate: string;
  diagnosticHistorics: [];
  documents: Document[];
  issueId: string;
  issueName: string;
  matchingActors: MatchingActors[];
  price: number;
  reasonId: string;
  reasonName: string;
  reasonUniqueName: string;
  sellerId: string;
  solutionId: string;
  solutionName: string;
  solutionUniqueName: string;
  warrantyTypeId: string;
  warrantyTypeName: string;
  ownerProductId: string;
}

export enum ClaimContextState {
  CREATED = 'Created',
  WAITING_FOR_REASON = 'WaitingForReason',
  CLOSED_FOR_RMA = 'ClosedForRma',
  WAITING_FOR_DIAGNOSTIC_CONFIRMATION = 'WaitingForDiagnosticConfirmation',
  WAITING_FOR_SOLUTION = 'WaitingForSolution',
  WAITING_FOR_HANDLING_MODE = 'WaitingForHandlingMode',
  WAITING_FOR_HANDLING_DEPOSIT_INFO = 'WaitingForHandlingDepositInfo',
  WAITING_FOR_HANDLING_DELIVERY_INFO = 'WaitingForHandlingDeliveryInfo',
  WAITING_FOR_ONSITE_INTERVENTION_INFO = 'WaitingForOnSiteInterventionInfo',
  CONFIRMATION = 'Confirmation',
  SUMMARY = 'Summary',
  CLOSED = 'Closed',
}

export enum ClaimOrigin {
  ORDER = 'Order',
  PRODUCT_IN_CATATLOG = 'ProductInCatalog',
  PRODUCT_NOT_IN_CATALOG = 'ProductNotInCatalog',
}

export interface ClaimContext {
  state: ClaimContextState;
  claimId: string;
  sellerId: string;
  ownerId: string;
  warrantyTypeId?: string;
  issueId?: string;
  reasonId?: string;
  solutionTypeId?: string;
  ignoreDateRulesChecking: boolean;
  groupId?: string;
  additionalClaimInformation: AdditionalClaimInformation;
  fileId?: string;
  externalCaseId?: string;
  safetyRiskDeclared: boolean;
  originPathway: ClaimOrigin;
  reasonComment?: string;
  ownerProductId: string;
  depositAddress: AddressInfo;
  ownerAddress: AddressInfo;
  deliveryAddress: AddressInfo;
  carrierDepositProductSellerPriceId?: string;
  carrierDepositProductCustomerPriceId?: string;
  notificationEmail?: string;
}

export interface ProductAdditionalInformation {
  additionalInformationId: string;
  additionalInformationEnumValue: string;
  additionalInformationStringValue: string;
  additionalInformationFileValue: ProductFileAdditionalInformation;
}
interface ProductFileAdditionalInformation {
  fileName: string;
  url: string;
}

export interface ClaimContextSummary {
  state: ClaimContextState;
  claimId: string;
  sellerId: string;
  ownerId: string;
  issueId: string;
  symptomId: string;
  questionId: string;
  reasonId: string;
  solutionTypeId: string;
  ignoreDateRulesChecking: boolean;
  groupId: string;
  additionalClaimInformation: ProductAdditionalInformation[];
  fileId: string;
}
