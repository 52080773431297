import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import {
  BUTTON_TYPES,
  HOUSING_TYPES,
  INPUT_TYPES,
} from '@savgroup-front-common/constants/src/shared';
import {
  Autocomplete,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { ActionRow } from '@savgroup-front-common/core/src/atoms/ActionRow';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { globalMessages } from '@savgroup-front-common/core/src/helpers';
import {
  InputHookForm,
  TextareaHookForm,
  RadioButtonHookForm,
} from '@savgroup-front-common/core/src/molecules/Form';

import { ADDRESS_MANAGEMENT_FIELDS } from '../AddressManagement.types';

import { $CardContainer } from './AddressForm.styles';
import messages from './messages';

const AddressForm = ({
  isVerificationPending,
  countriesOptions,
  isHomePickup,
  isLoading,
  hasHome,
}) => {
  const theme = useTheme();
  const formContext = useFormContext();
  const {
    register,
    control,
    formState: { errors, isValid, isSubmitSuccessful },
  } = formContext;

  const civilityOptions = [
    { key: 'Mr', text: safeFormattedIntlString(messages.mister), value: 'Mr' },
    { key: 'Mrs', text: safeFormattedIntlString(messages.madam), value: 'Mrs' },
  ];

  const yesOrNoOptions = [
    { value: 'true', label: safeFormattedIntlString(globalMessages.form.yes) },
    { value: 'false', label: safeFormattedIntlString(globalMessages.form.no) },
  ];
  const habitationOptions = [
    {
      value: HOUSING_TYPES.HOUSE,
      label: safeFormattedIntlString(messages.house),
    },
    {
      value: HOUSING_TYPES.APARTMENT,
      label: safeFormattedIntlString(messages.flat),
    },
    {
      value: HOUSING_TYPES.OTHER,
      label: safeFormattedIntlString(messages.other),
    },
  ];

  const additionalInfoMaxLength = isHomePickup ? 50 : 180;

  return (
    <div>
      <Heading level={3} color={theme.colors.mainTextColor}>
        <FormattedMessage {...messages.information} />
      </Heading>
      <$CardContainer>
        <Row>
          <Col xs={12}>
            <Row>
              {civilityOptions.map((item) => (
                <Col key={`civility-${item.key}`}>
                  <RadioButtonHookForm
                    name={ADDRESS_MANAGEMENT_FIELDS.CIVILITY}
                    disabled={isVerificationPending}
                    key={item.key}
                    value={item.key}
                    label={item.text}
                    register={register}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={6} data-firstname-addressform>
            <FormGroup>
              <InputHookForm
                errors={errors}
                name={ADDRESS_MANAGEMENT_FIELDS.FIRST_NAME}
                placeholder={messages.firstName}
                label={messages.firstName}
                register={register}
                type={INPUT_TYPES.TEXT}
                isRequired
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} data-lastname-addressform>
            <FormGroup>
              <InputHookForm
                errors={errors}
                name={ADDRESS_MANAGEMENT_FIELDS.LAST_NAME}
                placeholder={messages.lastName}
                label={messages.lastName}
                register={register}
                type={INPUT_TYPES.TEXT}
                isRequired
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={6} data-company-addressform>
            <FormGroup>
              <InputHookForm
                errors={errors}
                name={ADDRESS_MANAGEMENT_FIELDS.COMPANY_NAME}
                placeholder={messages.companyName}
                label={messages.companyName}
                register={register}
                type={INPUT_TYPES.TEXT}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} data-phonenumber-addressform>
            <FormGroup>
              <InputHookForm
                label={messages.phone}
                name={ADDRESS_MANAGEMENT_FIELDS.PHONE}
                maxLength={25}
                register={register}
                errors={errors}
                type={INPUT_TYPES.TEXT}
                isRequired
              />
            </FormGroup>
          </Col>
        </Row>
      </$CardContainer>
      <Heading level={3} color={theme.colors.mainTextColor}>
        <FormattedMessage {...messages.address} />
      </Heading>
      <$CardContainer>
        <Row>
          <Col xs={12} sm={6} data-location-addressform>
            <FormGroup>
              <InputHookForm
                label={messages.address}
                name={ADDRESS_MANAGEMENT_FIELDS.ADDRESS}
                register={register}
                errors={errors}
                type={INPUT_TYPES.TEXT}
                isRequired
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <InputHookForm
                label={messages.additionalAddress}
                name={ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_ADDRESS}
                placeholder={messages.additionalInformationsPlaceHolder}
                register={register}
                errors={errors}
                type={INPUT_TYPES.TEXT}
                dataTestId="additionalAddressInput"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={4} data-postal-addressform>
            <FormGroup>
              <InputHookForm
                label={messages.postalCode}
                name={ADDRESS_MANAGEMENT_FIELDS.POSTAL_CODE}
                register={register}
                errors={errors}
                type={INPUT_TYPES.TEXT}
                isRequired
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} data-city-addressform>
            <FormGroup>
              <InputHookForm
                label={messages.city}
                name={ADDRESS_MANAGEMENT_FIELDS.CITY}
                register={register}
                errors={errors}
                type={INPUT_TYPES.TEXT}
                isRequired
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} data-countrycode-addressform>
            <FormGroup>
              <Controller
                control={control}
                name={ADDRESS_MANAGEMENT_FIELDS.COUNTRY_CODE}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      name={field.name}
                      isLoading={isVerificationPending}
                      tabSelectsValue={false}
                      isClearable={false}
                      backspaceRemovesValue={false}
                      hideSelectedOptions={false}
                      label={messages.country}
                      placeholder={messages.chooseOption}
                      options={countriesOptions}
                      errors={errors}
                      value={field.value}
                      onChange={(option) => field.onChange(option.value)}
                      ref={field.ref}
                      isRequired
                    />
                  );
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </$CardContainer>
      <Heading level={3} color={theme.colors.mainTextColor}>
        <FormattedMessage {...messages.complementary} />
      </Heading>
      <$CardContainer>
        {hasHome && (
          <>
            <Row>
              <Col xs={12} sm={4}>
                <FormGroup>
                  <Controller
                    control={control}
                    name={ADDRESS_MANAGEMENT_FIELDS.HAS_ELEVATOR}
                    render={({ field }) => (
                      <Autocomplete
                        name={field.name}
                        isLoading={isVerificationPending}
                        errors={errors}
                        label={messages.elevator}
                        options={yesOrNoOptions}
                        onChange={field.onChange}
                        value={field.value}
                        ref={field.ref}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={4}>
                <FormGroup>
                  <Controller
                    control={control}
                    name={ADDRESS_MANAGEMENT_FIELDS.HAS_PARKING_SPACE}
                    render={({ field }) => (
                      <Autocomplete
                        name={field.name}
                        isLoading={isVerificationPending}
                        errors={errors}
                        label={messages.parking}
                        options={yesOrNoOptions}
                        onChange={field.onChange}
                        value={field.value}
                        ref={field.ref}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={4}>
                <FormGroup>
                  <Controller
                    control={control}
                    name={ADDRESS_MANAGEMENT_FIELDS.HOUSING_TYPE}
                    render={({ field }) => (
                      <Autocomplete
                        name={field.name}
                        isLoading={isVerificationPending}
                        errors={errors}
                        label={messages.habitation}
                        options={habitationOptions}
                        onChange={field.onChange}
                        value={field.value}
                        ref={field.ref}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <InputHookForm
                    label={messages.floor}
                    name={ADDRESS_MANAGEMENT_FIELDS.FLOOR}
                    maxLength={25}
                    register={register}
                    errors={errors}
                    type={INPUT_TYPES.TEXT}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <InputHookForm
                    label={messages.intercom}
                    name={ADDRESS_MANAGEMENT_FIELDS.DOOR_CODE}
                    maxLength={25}
                    register={register}
                    errors={errors}
                    type={INPUT_TYPES.TEXT}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <Row style={{ marginBottom: 0 }}>
          <Col xs={12}>
            <FormGroup>
              <TextareaHookForm
                style={{ minHeight: '128px' }}
                isDisabled={isVerificationPending}
                name={ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_INFORMATION}
                label={messages.additionalInformation}
                placeholder={messages.additionalInformationsPlaceHolder}
                maxLength={additionalInfoMaxLength}
                errors={errors}
                register={register}
              />
            </FormGroup>
          </Col>
        </Row>
      </$CardContainer>
      <ActionRow>
        <Col xs={12} sm={3}>
          <Button
            type={BUTTON_TYPES.SUBMIT}
            data-addressnextstep-button
            fluid
            primary
            isLoading={isVerificationPending || isLoading || isSubmitSuccessful}
            isError={!isValid}
            dataTestId="submitButton"
          >
            <SafeFormattedMessageWithoutSpread message={messages.next} />
          </Button>
        </Col>
      </ActionRow>
    </div>
  );
};

AddressForm.propTypes = {
  isHomePickup: PropTypes.bool,
  onChange: PropTypes.func,
  countriesOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

AddressForm.defaultProps = {
  isHomePickup: false,
  onChange: () => {},
};

AddressForm.displayName = 'AddressForm';

export default AddressForm;
