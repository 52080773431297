import PropTypes from 'prop-types';
import React from 'react';

import { OptionCard } from '@savgroup-front-common/core/src/molecules/OptionCard';
import { InjectSvg } from '@savgroup-front-common/core/src/atoms/images';
import { getIssueIconUrl } from '@savgroup-front-common/core/src/helpers';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

const IssueCard = ({
  name,
  onSelect,
  didactic,
  keyIcon,
  index,
  id,
  isLoading,
}) => {
  const { fallbackUrl, iconUrl } = getIssueIconUrl(keyIcon);

  return (
    <OptionCard
      isLoading={isLoading}
      title={name}
      didactic={didactic}
      dataTestId={`issueCardButton${index}`}
      componentThemeNameButton={SUPPORTED_COMPONENTS.ISSUE_CARD_BUTTON}
      onClick={() => {
        window.scroll(0, 0);
        onSelect(id);
      }}
    >
      <InjectSvg
        iconUrl={iconUrl}
        iconUrlFallback={fallbackUrl}
        componentThemeName={SUPPORTED_COMPONENTS.IRSH_ICONS}
      />
    </OptionCard>
  );
};

IssueCard.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  didactic: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  keyIcon: PropTypes.string.isRequired,
  index: PropTypes.number,
};

IssueCard.defaultProps = {
  didactic: '',
  index: 0,
};

IssueCard.displayName = 'IssueCard';

export default React.memo(IssueCard);
