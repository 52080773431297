import React from 'react';

interface FolderIconProps {
  color?: string;
  size?: string;
}

/* eslint-disable max-len */
const FolderIcon: React.FC<FolderIconProps> = ({
  color = 'black',
  size = '32',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 11V4.6C2 4.44087 2.06321 4.28826 2.17574 4.17574C2.28826 4.06321 2.44087 4 2.6 4H8.778C8.92101 3.99999 9.05932 4.05106 9.168 4.144L12.332 6.856C12.4407 6.94894 12.579 7.00001 12.722 7H21.4C21.4788 7 21.5568 7.01552 21.6296 7.04567C21.7024 7.07583 21.7685 7.12002 21.8243 7.17574C21.88 7.23145 21.9242 7.29759 21.9543 7.37039C21.9845 7.44319 22 7.52121 22 7.6V11M2 11V19.4C2 19.5591 2.06321 19.7117 2.17574 19.8243C2.28826 19.9368 2.44087 20 2.6 20H21.4C21.5591 20 21.7117 19.9368 21.8243 19.8243C21.9368 19.7117 22 19.5591 22 19.4V11M2 11H22"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

FolderIcon.displayName = 'FolderIcon';

export default FolderIcon;
