import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { apiCall } from '../../services';
import {
  BackResponse,
  BackResponseFailure,
  SellerConfiguration,
} from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../helpers';

export const getSellerConfigurationQuery = async ({
  sellerId,
}: {
  sellerId: string;
}): Promise<BackResponseFailure | BackResponse<SellerConfiguration>> => {
  try {
    return await apiCall<BackResponse<SellerConfiguration>>(
      buildUrl(`${APIConfiguration.seller}sellers/${sellerId}/configuration`),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
