import * as clipboardy from 'clipboardy';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

function timeout(ms = 2000) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

interface UseCopyReturnValue<Reference = any> {
  childRef: RefObject<Reference>;
  copied: boolean;
  handleClick: (
    event: React.MouseEvent<Reference, MouseEvent>,
    textToCopy: any,
  ) => Promise<void>;
}

const useCopy = <Reference = any>(): UseCopyReturnValue<Reference> => {
  const mounted = useRef(false);
  const childRef = useRef<Reference>(null);
  const [copied, setCopied] = useState(false);

  const handleClick = useCallback(
    async (
      event: React.MouseEvent<Reference, MouseEvent>,
      textToCopy: string,
    ) => {
      const domRef = childRef.current || event.currentTarget || event.target;

      if (domRef) {
        await clipboardy.write(String(textToCopy));
        setCopied(true);
        mounted.current = true;
        await timeout();
        if (mounted.current) {setCopied(false);}
      }
    },
    [mounted],
  );

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return { copied, handleClick, childRef };
};

export default useCopy;
