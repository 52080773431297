import { defineMessages } from 'react-intl';

export default defineMessages({
  Active: {
    id: 'sparePart.status.Active',
    defaultMessage: 'Active',
  },
  Deprecated: {
    id: 'sparePart.status.Deprecated',
    defaultMessage: 'Deprecated',
  },
  Outdated: {
    id: 'sparePart.status.Outdated',
    defaultMessage: 'Outdated',
  },
  None: {
    id: 'sparePart.status.None',
    defaultMessage: 'None',
  },
});
