import { AdditionalInformation } from '@savgroup-front-common/types';

export const getAdditionalInformationIdsFromFileSummary = ({
  fileSummary,
}: {
  fileSummary: {
    fileProducts: {
      additionalInformation?: AdditionalInformation[];
      fileAdditionalInformation?: AdditionalInformation[];
    }[];
  };
}): string[] => {
  const { fileProducts } = fileSummary;

  const result = fileProducts.reduce<string[]>((acc, fileProduct) => {
    const claimAdditionalInformationIds =
      fileProduct.additionalInformation?.map((item) => {
        return item.additionalInformationId;
      }) || [];

    const claimAdditionalInformationEnumIds =
      fileProduct.additionalInformation?.map((item) => {
        return item.additionalInformationEnumValue || '';
      }) || [];

    const fileAdditionalInformationIds =
      fileProduct.fileAdditionalInformation?.map((item) => {
        return item.additionalInformationId;
      }) || [];
    const fileAdditionalInformationEnumIds =
      fileProduct.fileAdditionalInformation?.map((item) => {
        return item.additionalInformationEnumValue || '';
      }) || [];

    return [
      ...acc,
      ...claimAdditionalInformationEnumIds,
      ...claimAdditionalInformationIds,
      ...fileAdditionalInformationEnumIds,
      ...fileAdditionalInformationIds,
    ];
  }, []);

  return result;
};
