import endsWith from 'lodash/endsWith';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';

interface GetIconUrl {
  iconUrl: string;
  fallbackUrl: string;
}

export const getSolutionIconUrl = (keyIcon?: string): GetIconUrl => {
  const iconUrl = `${APIConfiguration.catalogCDN}miscellaneous/solutions/${keyIcon}.svg`;
  const fallbackUrl = `${APIConfiguration.catalogCDN}miscellaneous/solutions/noSolutionIconFallback.svg`;

  return {
    iconUrl,
    fallbackUrl,
  };
};

export const getIssueIconUrl = (keyIcon?: string): GetIconUrl => {
  const iconUrl = `${APIConfiguration.catalogCDN}issue-icons/${keyIcon}.svg`;
  const fallbackUrl = `${APIConfiguration.catalogCDN}issue-icons/noIssueIconFallback.svg`;

  return {
    iconUrl,
    fallbackUrl,
  };
};

export const getProductIconUrl = (
  modelTypeId: string,
  productsQuantity?: number,
  sameModel?: boolean,
): string => {
  if (productsQuantity && productsQuantity > 1 && !sameModel) {
    return `${APIConfiguration.catalogCDN}product-icons/multiproduct.svg`;
  }
  if (endsWith(modelTypeId, '.svg')) {
    return modelTypeId;
  }

  return `${APIConfiguration.catalogCDN}product-icons/${modelTypeId}.svg`;
};

export const getProcedureReceiptUrl = (): string =>
  `${APIConfiguration.catalogCDN}procedure-images/Carrefour/Specific/procedure_receipt.pdf`;
