import isEqual from 'lodash/isEqual';

import {
  ADDRESS_MANAGEMENT_FIELDS,
  AddressManagementFieldValues,
  AddressManagementValues,
} from '../AddressManagement.types';

interface IsAddressValidArgs {
  suggestedAddress?: AddressManagementValues;
  customerAddress: AddressManagementFieldValues;
}

const isAddressValid = ({
  suggestedAddress,
  customerAddress,
}: IsAddressValidArgs): boolean => {
  if (!suggestedAddress) {
    return false;
  }

  const addressToCheck = {
    address: customerAddress[ADDRESS_MANAGEMENT_FIELDS.ADDRESS].toLowerCase(),
    postalCode:
      customerAddress[ADDRESS_MANAGEMENT_FIELDS.POSTAL_CODE].toLowerCase(),
    city: customerAddress[ADDRESS_MANAGEMENT_FIELDS.CITY].toLowerCase(),
  };

  const suggestedAddressToCheck = {
    address: suggestedAddress[ADDRESS_MANAGEMENT_FIELDS.ADDRESS].toLowerCase(),
    postalCode:
      suggestedAddress[ADDRESS_MANAGEMENT_FIELDS.POSTAL_CODE].toLowerCase(),
    city: suggestedAddress[ADDRESS_MANAGEMENT_FIELDS.CITY].toLowerCase(),
  };

  return isEqual(addressToCheck, suggestedAddressToCheck);
};

export default isAddressValid;
