import { createContext } from 'react';

import { FEATURE_FLAGS } from '@savgroup-front-common/constants';

const FeatureFlagContext = createContext<{
  features: FEATURE_FLAGS[];
  handleOpenFeatureFlagModal: () => void;
  handleCloseFeatureFlagModal: () => void;
  handleToggleFeatureFlagModal: () => void;
  handleToggleFeature: (feature: FEATURE_FLAGS) => void;
  isFeatureFlagManagerOpen: boolean;
  existingFeatureFlags: FEATURE_FLAGS[];
}>({
  features: [],
  handleOpenFeatureFlagModal: () => undefined,
  handleCloseFeatureFlagModal: () => undefined,
  handleToggleFeatureFlagModal: () => undefined,
  handleToggleFeature: () => undefined,
  isFeatureFlagManagerOpen: false,
  existingFeatureFlags: [],
});

export default FeatureFlagContext;
