import { createAction } from '@reduxjs/toolkit';

import {
  GO_BACK_CLAIM_GROUP_EDITION,
  GO_FORWARD_CLAIM_GROUP_EDITION,
  SELECT_QUANTITY_REASON,
} from './actionTypes';

export const goForwardOnClaimGroupEdition = createAction(
  GO_FORWARD_CLAIM_GROUP_EDITION,
);
export const goBackOnClaimGroupEdition = createAction(
  GO_BACK_CLAIM_GROUP_EDITION,
);
export const selectQuantityReason = createAction(SELECT_QUANTITY_REASON);
