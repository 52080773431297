import { format } from 'date-fns';

import { SetUserActionAdditionalInformationsPayload } from '@savgroup-front-common/core/src/api/Workflow/setUserActionAsyncCommand';

import { AdaptedNeededInformation } from './AdditionalInformationExpect.types';

const DEFAULT_DATETIME_FORMAT = 'yyyy-MM-ddT00:00:00.000Z';

interface PrepareSetUserActionAsyncCommandAdditionalInformationsArgs {
  neededFileAdditionalInformation: Record<string, AdaptedNeededInformation[]>;
  fileAdditionalInformation: Record<string, any>;
}

export const prepareSetUserActionAsyncCommandAdditionalInformations = ({
  neededFileAdditionalInformation,
  fileAdditionalInformation,
}: PrepareSetUserActionAsyncCommandAdditionalInformationsArgs): SetUserActionAdditionalInformationsPayload[] => {
  const adaptedNeededInformations = Object.values(
    neededFileAdditionalInformation,
  ).flat();

  return adaptedNeededInformations.reduce<
    {
      claimId: string;
      id: string;
      value?: string | File;
    }[]
  >((acc, item) => {
    const { claimIds, internalId, id, fileUploadEndpoints } = item;

    return [
      ...acc,
      ...(claimIds || []).reduce<
        {
          claimId: string;
          id: string;
          value?: string | File;
        }[]
      >((accClaimIds, claimId) => {
        const value = fileAdditionalInformation[internalId];

        const isFile =
          value && typeof value === 'object' && value.value instanceof File;
        const isDate =
          value && typeof value === 'object' && value instanceof Date;

        if (isFile) {
          return [
            ...accClaimIds,
            {
              claimId,
              id,
              value: value.value,
              fileUploadEndpoints,
            },
          ];
        }

        if (isDate) {
          return [
            ...accClaimIds,
            {
              claimId,
              id,
              value: format(value, DEFAULT_DATETIME_FORMAT),
            },
          ];
        }

        if (typeof value === 'object') {
          return [
            ...accClaimIds,
            {
              claimId,
              id,
              value: value?.value || '',
            },
          ];
        }

        return [
          ...accClaimIds,
          {
            claimId,
            id,
            value,
          },
        ];
      }, []),
    ];
  }, []);
};
