import flatMap from 'lodash/flatMap';

import {
  HANDLING_GROUPS,
  HANDLING_MODES,
} from '@savgroup-front-common/constants';
import { HandlingSummary } from '@savgroup-front-common/types';

import { Carrier } from '../../ClaimGroupHandling.types';

export const getHomeInterventionCarrier = (hasHome?: boolean) => {
  if (!hasHome) {
    return [];
  }

  return [
    {
      handlingMode: HANDLING_MODES.DEPOSIT,
      carrierBrand: HANDLING_GROUPS.HOME_INTERVENTION,
      productType: HANDLING_GROUPS.HOME_INTERVENTION,
    },
  ];
};

interface GetAllCarriersArgs {
  handlingInfo?: HandlingSummary;
  currentCarriers: Map<
    string,
    {
      [key: string]: Carrier[];
    }
  >;
}

export const getAllCarriers = ({
  currentCarriers,
  handlingInfo,
}: GetAllCarriersArgs): Carrier[] => {
  const hasHome = handlingInfo?.hasHome;
  const homeInterventionCarrier = getHomeInterventionCarrier(hasHome);

  const carriers =
    currentCarriers && currentCarriers.get('value')
      ? flatMap(currentCarriers.get('value'), (v) => v)
      : [];

  const allCarriers = carriers.concat(homeInterventionCarrier);

  const allCarriersWithoutImmediateDropAtStore = allCarriers.filter(
    (carrier) =>
      carrier?.productType !== HANDLING_GROUPS.IMMEDIATE_DROP_AT_STORE,
  );

  return allCarriersWithoutImmediateDropAtStore;
};
