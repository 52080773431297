import find from 'lodash/find';
import get from 'lodash/get';
import last from 'lodash/last';
import { AnyAction, Dispatch } from 'redux';
import { push } from 'redux-first-history';

import { CLAIM_STATE, HANDLING_MODES } from '@savgroup-front-common/constants';

import { ROUTES } from '../../view/app/routes/Routes';

export function goToHome() {
  return (dispatch: Dispatch<AnyAction>, getState: () => any) => {
    const state = getState();
    const url = '/products';

    if (state.router.location.pathname !== url) {
      dispatch(push(url));
    }
  };
}

export function goToRMA() {
  return (dispatch: Dispatch<AnyAction>, getState: () => any) => {
    const state = getState();
    const { claimID } = state.navigation;
    const url = `/claim/${claimID}/rma`;

    if (state.router.location.pathname !== url) {
      dispatch(push(url));
    }
  };
}

export function goToIssues() {
  return (dispatch: Dispatch<AnyAction>, getState: () => any) => {
    const state = getState();
    const { claimID } = state.navigation;
    const url = `/claim/${claimID}/issues`;

    if (state.router.location.pathname !== url) {
      dispatch(push(url));
    }
  };
}

export function goToReasons() {
  return (dispatch: Dispatch<AnyAction>, getState: () => any) => {
    const state = getState();
    const { claimID } = state.navigation;
    const url = `/claim/${claimID}/reasons`;

    if (state.router.location.pathname !== url) {
      dispatch(push(url));
    }
  };
}

export function goToSolutions(): any {
  return (dispatch: Dispatch<AnyAction>, getState: () => any) => {
    const state = getState();
    const { claimID } = state.navigation;
    const url = `/claim/${claimID}/solutions`;

    if (state.router.location.pathname !== url) {
      dispatch(push(url));
    }
  };
}

export function goToSymptoms() {
  return (dispatch: Dispatch<AnyAction>, getState: () => any) => {
    const state = getState();
    const { claimID } = state.navigation;
    const url = `/claim/${claimID}/symptoms`;

    if (state.router.location.pathname !== url) {
      dispatch(push(url));
    }
  };
}

export function goToDiagnostic() {
  return (dispatch: Dispatch<AnyAction>, getState: () => any) => {
    const state = getState();
    const { claimID, diagnosticQuestions } = state.navigation;
    const lastAnswerID = last(diagnosticQuestions) || '';
    const url = `/claim/${claimID}/diagnostic/${lastAnswerID}`;

    if (state.router.location.pathname !== url) {
      dispatch(push(url));
    }
  };
}

export function goToDiagnosticConfirmation() {
  return (dispatch: Dispatch<AnyAction>, getState: () => any) => {
    const state = getState();
    const { claimID } = state.navigation;
    const url = `/claim/${claimID}/reason`;

    if (state.router.location.pathname !== url) {
      dispatch(push(url));
    }
  };
}

export function goToHandling(defaultClaimState: CLAIM_STATE): any {
  return (dispatch: Dispatch<AnyAction>, getState: () => any) => {
    const state = getState();
    const { claimID, claimState } = state.navigation;

    let handlingMode;

    switch (defaultClaimState || claimState) {
      case CLAIM_STATE.HANDLING_DEPOSIT:
      case CLAIM_STATE.HANDLING_ROUNDTRIP_DEPOSIT:
        handlingMode = HANDLING_MODES.DEPOSIT;
        break;
      case CLAIM_STATE.HANDLING_DELIVERY:
      case CLAIM_STATE.HANDLING_ROUNDTRIP_DELIVERY:
        handlingMode = HANDLING_MODES.DELIVERY;
        break;
      default:
        handlingMode = HANDLING_MODES.DEPOSIT;
    }

    if (handlingMode) {
      const url = `/claim/${claimID}/handling/${handlingMode.toLowerCase()}`;

      if (state.router.location.pathname !== url) {
        dispatch(push(url));
      }
    }
  };
}

export function goToClaimGroup(claimGroupId: string) {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(
      push(ROUTES.CLAIM_GROUP_DETAILS.replace(':claimGroupId', claimGroupId)),
    );
  };
}

export function goToClaim(claimId: string) {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(push(ROUTES.CLAIM_DETAILS.replace(':claimId', claimId)));
  };
}

export function goToFileDetails({
  fileId,
  productId,
}: {
  fileId: string;
  productId: string;
}) {
  return (dispatch: Dispatch<AnyAction>, getState: () => any) => {
    const state = getState();

    let file = {};

    if (productId) {
      file = find(
        get(state, 'myReturn.historic.closedFiles'),
        (item) => item.productId === productId,
      );
    }
    const workingFileId = fileId || get(file, 'fileId');

    if (
      state.router.location.pathname !== ROUTES.FILE_FOLLOWUPS &&
      workingFileId
    ) {
      dispatch(push(ROUTES.FILE_FOLLOWUPS.replace(':fileId', workingFileId)));
    }
  };
}
