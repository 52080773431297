import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { ProgressBar } from '@savgroup-front-common/core/src/atoms/ProgressBar';

export default function StepProgress({ steps }) {
  const location = useLocation();
  const { pathname } = location;
  const stepArray = Object.entries(steps);

  const current = stepArray.find(([, path]) => {
    if (Array.isArray(path)) {
      return path.find((p) =>
        matchPath(pathname, {
          path: p,
          exact: true,
          strict: true,
        }),
      );
    }

    return matchPath(pathname, {
      path,
      exact: true,
      strict: true,
    });
  });

  if ([undefined, null].includes(current)) {
    return null;
  }

  const [value] = current;

  return (
    <ProgressBar
      percent={(Number(value) / Object.values(steps).length) * 100}
      isAnimated={false}
      isRounded={false}
    />
  );
}

StepProgress.propTypes = {
  steps(props, propName, componentName) {
    for (const [key, value] of Object.entries(props[propName])) {
      if (typeof key !== 'string') {
        return new Error(
          `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed. Provide a string as property`,
        );
      }

      if (typeof value !== 'string' && !Array.isArray(value)) {
        return new Error(
          `Invalid prop \`${propName}\` supplied to` +
            ` \`${componentName}\`. Validation failed. Provide a string or an array as value`,
        );
      }
    }

    return null;
  },
};

StepProgress.defaultProps = {
  steps: {},
};
