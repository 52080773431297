import React from 'react';

interface CluteryIconProps {
  color?: string;
}

/* eslint-disable max-len */
const CluteryIcon: React.FC<CluteryIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.9998 41.6004C33.1998 41.6004 32.3998 41.0004 32.3998 40.0004V24.0004C32.3998 23.4004 32.7998 22.8004 33.3998 22.6004C33.3998 22.6004 37.3998 21.0004 37.3998 18.0004V9.00039C37.3998 8.20039 37.9998 7.60039 38.9998 7.60039C39.9998 7.60039 40.5998 8.20039 40.5998 9.00039V18.0004C40.5998 21.8004 37.1998 24.0004 35.5998 25.0004V40.0004C35.5998 40.8004 34.7998 41.6004 33.9998 41.6004ZM23.9998 41.6004H11.9998C11.1998 41.6004 10.3998 41.0004 10.3998 40.0004C10.3998 39.0004 10.9998 38.4004 11.9998 38.4004H16.3998V30.8004C14.3998 29.6004 9.39982 26.4004 7.59982 22.6004C5.19982 17.6004 7.39982 9.00039 7.59982 8.60039C7.79982 8.00039 8.39982 7.40039 8.99982 7.40039H26.9998C27.5998 7.40039 28.1998 7.80039 28.3998 8.60039C28.3998 9.00039 30.5998 17.6004 28.3998 22.6004C26.5998 26.4004 21.5998 29.6004 19.5998 30.8004V38.4004H23.9998C24.7998 38.4004 25.5998 39.0004 25.5998 40.0004C25.5998 41.0004 24.7998 41.6004 23.9998 41.6004ZM10.1998 10.4004C9.59982 13.2004 8.99982 18.4004 10.3998 21.2004C11.7998 24.0004 15.7998 26.8004 17.9998 28.2004C20.1998 27.0004 24.3998 24.0004 25.5998 21.2004C26.9998 18.2004 26.3998 13.2004 25.7998 10.4004H10.1998ZM33.9998 18.4004C33.1998 18.4004 32.3998 17.8004 32.3998 16.8004V8.80039C32.3998 8.00039 32.9998 7.40039 33.9998 7.40039C34.9998 7.40039 35.5998 8.00039 35.5998 8.80039V16.8004C35.5998 17.8004 34.7998 18.4004 33.9998 18.4004Z"
        fill={color}
      />
    </svg>
  );
};

CluteryIcon.displayName = 'CluteryIcon';

export default CluteryIcon;
