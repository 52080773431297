import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';

enum REASON_KIND {
  NORMAL = 'Normal',
  OTHER_PROBLEM = 'OtherProblem',
}

export enum RELATED_TO {
  CLAIM = 'Claim',
  PRODUCT = 'Product',
}

export interface AdditionalInformationExtended extends AdditionalInformation {
  internalId: string;
  claimIds: string[];
  currentClaimId: string;
  fileUploadEndpoints?: {
    [key: string]: {
      uploadEndpoint: string;
      deleteEndpoint: string;
    };
  };
}

export interface AdditionalInformation {
  id: string;
  key: string;
  name: string;
  isRequired: boolean;
  uniqueName: string;
  relatedTo: RELATED_TO;
  type: ADDITIONAL_INFORMATION_TYPES;
  uniquenessByProduct: boolean;
  description: string;
  displayOrder: number;
  possibleValues: AdditionalInformationPossibleValue[];
  importByQrCode: boolean;
}

interface AdditionalInformationPossibleValue {
  id: string;
  name: string;
  key: string;
}

export interface Reason {
  id: string;
  name: string;
  key: string;
  mandatoryComment: boolean;
  mandatoryDocument: boolean;
  kind: REASON_KIND;
  displayOrder: number;
  didactic: string;
  diagnosticTreeUniqueName?: string;
  isDiagnosticTreeAvailable?: boolean;
  neededInformation: AdditionalInformation[];
}
