import { get, head } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createSelector } from 'reselect';

import { COUNTRY_CODES } from '@savgroup-front-common/constants';
import {
  useGetCarrierProductByIdQuery,
  useGetHomePickupSchedule,
} from '@savgroup-front-common/core/src/hooks';
import { Selectors as ClaimsSelectors } from '@savgroup-front-common/core/src/domains/claims';
import { Selectors as WorkflowSelectors } from '@savgroup-front-common/core/src/domains/workflow';
import { Selectors as CustomerSelectors } from 'myaccount/domains/Customer';
import { handlingSummary as handlingSummarySelector } from 'myaccount/domains/Views/handlingSummary';
import { useMyAccountTypedSelector } from 'myaccount/hooks';

import { customerState } from '../../../../domains/Customer/selectors';

const useGetClientData = () => {
  const currentCarrierSelector = (state: any) => get(state, 'currentCarrier');
  const currentCarrierNameSelector = createSelector(
    [currentCarrierSelector],
    (currentCarrier: any) => currentCarrier.get('carrier'),
  );

  const customer = useMyAccountTypedSelector(customerState);
  const { fileStates } = customer;
  const isLoading = useMyAccountTypedSelector(
    (state) => state.fileTrackingPage.isLoading,
  );
  const invoiceToPay = useMyAccountTypedSelector(
    CustomerSelectors.invoiceToPay,
  );
  const handlingSummary = useMyAccountTypedSelector(handlingSummarySelector);
  const appointment = useMyAccountTypedSelector(
    WorkflowSelectors.appointmentsSelector,
  );

  const latestState = head(fileStates);

  const { fileId } = useParams<{ fileId: string }>();

  const claimGroupId = get(customer, [
    'customerFileSummary',
    'claimInfoSummary',
    'claimGroupId',
  ]);
  const confirmationClaimGroupValue = useMyAccountTypedSelector((state) =>
    ClaimsSelectors.getClaimGroupsConfirmationValue(state, claimGroupId),
  );

  const firstAction = head(customer?.availableFileActions);

  const loadingStatuses = {
    fileDownloadInProgress: customer.fileDownloadInProgress,
    isLoading,
  };
  const fileActions = customer.availableFileActions;
  const workflowInfos = {
    currentStatus: latestState?.state,
    currentModule: latestState?.module,
    fileId,
    file: customer.file,
  };
  const fileSummary = customer.customerFileSummary;
  const invoice = invoiceToPay;
  const { claimConfirmationData } = customer;
  const appointmentStatus = appointment.getIn([fileId]);
  const { isStateUpdatePending } = customer;

  const transitions = firstAction?.transitions;
  const module = firstAction?.module;
  const fileStatusName = firstAction?.fileStatus?.name;
  const invoiceId = invoice?.id;

  const startTime = new Date();

  startTime.setMinutes(0, 0, 0);

  const endTime = new Date();

  endTime.setMonth(endTime.getMonth() + 1);
  endTime.setMinutes(0, 0, 0);

  const { carrierProductSummary } = useGetCarrierProductByIdQuery({
    carrierProductId: fileSummary?.transportDepositSummary?.carrierProductId,
  });

  const carrierName: string = useSelector(currentCarrierNameSelector);

  const { detailedSchedule } = useGetHomePickupSchedule({
    carrier: carrierProductSummary?.carrierOperator || carrierName,
    startTimeInLocalRecipientTimezone: startTime.toISOString(),
    countryCode: fileSummary.productLocationCountryCode as COUNTRY_CODES,
    postalCode: handlingSummary.deposit?.postalCode,
    fileId,
  });

  return {
    action: firstAction,
    confirmationClaimGroupValue: confirmationClaimGroupValue?.value,
    loadingStatuses,
    fileActions,
    workflowInfos,
    fileSummary,
    handlingSummary,
    invoice,
    claimConfirmationData,
    detailedSchedule,
    appointmentStatus,
    isStateUpdatePending,
    fileId,
    module,
    transitions,
    fileStatusName,
    invoiceId,
  };
};

export default useGetClientData;
