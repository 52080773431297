import { defineMessages } from 'react-intl';

export default defineMessages({
  quantityCol: {
    id: 'view.card.claims.summaryClaimsProductQuantity',
    defaultMessage: 'Quantity',
  },
  orderNumber: {
    id: 'view.myaccount.order.orderNumber',
    defaultMessage: 'Order N°{orderReference}',
  },
  outOfWarranty: {
    id: 'components.myaccount.order.foundOrder.outOfWarranty',
    defaultMessage: 'Out of Warranty',
  },
  viewHistory: {
    id: 'myaccount.order.orderButton.viewHistory',
    defaultMessage: 'View History',
  },
  purchasedDate: {
    id: 'components.myaccount.order.foundOrder.buyDate',
    defaultMessage: "Date d'achat",
  },
  model: {
    id: 'components.myaccount.order.model',
    defaultMessage: 'Modèle',
  },
  brand: {
    id: 'components.myaccount.order.brand',
    defaultMessage: 'Marque',
  },
  withoutWarranty: {
    id: 'components.myaccount.order.withoutWarranty',
    defaultMessage: 'No warranty',
  },
  withWarranty: {
    id: 'components.myaccount.order.withWarranty',
    defaultMessage: 'Under warranty',
  },
  noProductSelected: {
    id: 'components.myaccount.order.noProductSelected',
    defaultMessage: 'Select at least one product',
  },
  noMoreThanOneRepairedProductSelected: {
    id: 'components.myaccount.order.noMoreThanOneRepairedProductSelected',
    defaultMessage: "You can't select more than on repaired product",
  },
});
