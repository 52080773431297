import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { useLoadNextScreenForClaimGroupIsLoading } from 'myaccount/domains/ClaimGroupEdition/selector';

import messages from '../messages';
import { ButtonActionWrapper } from '../ReasonSolutionSelection.styles';

const ReasonSolutionSelectionSubmitButton = ({
  fileUploads,
  isLastProduct,
  onNextButtonClick,
}) => {
  const formContext = useFormContext();
  const {
    formState: { errors, isSubmitSuccessful, isSubmitted, isSubmitting },
  } = formContext;

  const isLoadNextScreenForClaimGroupLoading =
    useLoadNextScreenForClaimGroupIsLoading();

  return (
    <ButtonActionWrapper between="xs">
      <Row>
        <Col xs={12} smOffset={9} sm={3}>
          {!isLastProduct && (
            <Button
              type={BUTTON_TYPES.BUTTON}
              fluid
              primary
              dataTestId="reason-solution-next-step-button"
              onClick={onNextButtonClick}
            >
              <FormattedMessage {...messages.claimGroupNextProduct} />
            </Button>
          )}

          {isLastProduct && (
            <Button
              type={BUTTON_TYPES.SUBMIT}
              fluid
              primary
              disabled={
                !isEmpty(fileUploads)
                  ? get(fileUploads, 'progress') !== 100
                  : null
              }
              className="nextStepButton"
              dataTestId="reason-solution-next-step-button"
              isError={isSubmitted && size(errors) > 0}
              isLoading={
                isSubmitting ||
                isSubmitSuccessful ||
                isLoadNextScreenForClaimGroupLoading
              }
            >
              <FormattedMessage {...messages.claimGroupContinue} />
            </Button>
          )}
        </Col>
      </Row>
    </ButtonActionWrapper>
  );
};

ReasonSolutionSelectionSubmitButton.propTypes = {
  solution: PropTypes.string,
  fileUploads: PropTypes.shape({}),
  isLastProduct: PropTypes.bool.isRequired,
  addInfoValid: PropTypes.bool,
};

ReasonSolutionSelectionSubmitButton.defaultProps = {
  fileUploads: null,
  addInfoValid: false,
  solution: null,
};

ReasonSolutionSelectionSubmitButton.displayName =
  'ReasonSolutionSelectionSubmitButton';

export default ReasonSolutionSelectionSubmitButton;
