import { Store } from '@savgroup-front-common/types';

function transformField(field: string) {
  return field.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
}

function storeSelectionFilterOption(
  { data }: { data: { label: string; data: Store } },
  value?: string,
) {
  const {
    label,
    data: { externalId = '', externalId3 = '' },
  } = data;

  if (!value) {
    return true;
  }

  const transformedValue = transformField(value);
  const transformedExternalId = externalId ? transformField(externalId) : '';
  const transformedExternalId3 = externalId3 ? transformField(externalId3) : '';
  const transformedLabel = transformField(label);

  return (
    transformedValue.length === 0 ||
    transformedExternalId.includes(transformedValue) ||
    transformedExternalId3.includes(transformedValue) ||
    transformedLabel.includes(transformedValue)
  );
}

export default storeSelectionFilterOption;
