import get from 'lodash/get';

const clientIdByHostname = {
  'sav.auchan.fr': 'auchan.revers.io',
  'mesretours.maisonsdumonde.com': 'maisonsdumonde.revers.io',
};

const authorityByHostname = {
  'auchan.revers.io': 'https://auchan-login.revers.io',
  'sav.auchan.fr': 'https://sav-login.auchan.fr',
  'maisonsdumonde.revers.io': 'https://maisonsdumonde-login.revers.io',
  'mesretours.maisonsdumonde.com':
    'https://mesretours-login.maisonsdumonde.com',
};

const authorityByClientId = {
  'revers.io': 'https://myaccount-login.revers.io',
  'retours.carrefour.fr': 'https://retours-login.carrefour.fr',
  'auchan.revers.io': 'https://sav-login.auchan.fr',
  'maisonsdumonde.revers.io': 'https://mesretours-login.maisonsdumonde.com',
};

const { hostname } = window.location;

function setAuthority(authority = null) {
  const { clientId } = this;

  const defaultAuthority = authorityByClientId[clientId];
  const defaultAuthorityFromHostname = get(authorityByHostname, hostname);

  if (hostname !== 'localhost' && defaultAuthorityFromHostname) {
    this.authority = defaultAuthorityFromHostname;

    return this;
  }

  this.authority = authority || defaultAuthority;

  return this;
}

const defaultClientId = 'myaccount.revers.io';

function computeClientId() {
  if (process.env.REACT_APP_CLIENTID) {
    return process.env.REACT_APP_CLIENTID;
  }
  if (hostname !== 'localhost') {
    return clientIdByHostname[hostname] || window.location.hostname;
  }

  return defaultClientId;
}

const clientId = computeClientId();

const AuthConfiguration = {
  clientId,
  authority: authorityByClientId[clientId],
  scope: 'openid email api',
  setAuthority,
};

export default AuthConfiguration;
