import React from 'react';

interface OpenBookIconProps {
  color?: string;
}

/* eslint-disable max-len */
const OpenBookIcon: React.FC<OpenBookIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9999 35V11.6667C19.9999 10.7826 20.3511 9.93476 20.9762 9.30964C21.6014 8.68452 22.4492 8.33333 23.3333 8.33333H35.6666C35.7979 8.33333 35.9279 8.3592 36.0493 8.40945C36.1706 8.45971 36.2808 8.53337 36.3737 8.62623C36.4666 8.71908 36.5402 8.82932 36.5905 8.95065C36.6407 9.07197 36.6666 9.20201 36.6666 9.33333V31.19M19.9999 35V11.6667C19.9999 10.7826 19.6487 9.93476 19.0236 9.30964C18.3985 8.68452 17.5506 8.33333 16.6666 8.33333H4.33325C4.06804 8.33333 3.81368 8.43869 3.62615 8.62623C3.43861 8.81376 3.33325 9.06812 3.33325 9.33333V31.19M23.3333 31.6667H36.6666M16.6666 31.6667H3.33325"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.9998 35C19.9998 34.1159 20.351 33.2681 20.9761 32.643C21.6013 32.0179 22.4491 31.6667 23.3332 31.6667M19.9998 35C19.9998 34.1159 19.6486 33.2681 19.0235 32.643C18.3984 32.0179 17.5506 31.6667 16.6665 31.6667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

OpenBookIcon.displayName = 'OpenBookIcon';

export default OpenBookIcon;
