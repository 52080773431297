import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $StyledCard = styled(Card)`
  margin-bottom: 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: ${rem('32px')};
  }

  padding: 1rem 2rem 1rem 0.5rem;
  ${mediaQuery.md`
    padding: 2rem;
  `};
`;

export const $FlexRow = styled(Row)`
  display: flex;
  align-items: stretch;
`;

export const $ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.danger};
`;
