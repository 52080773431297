import { defineMessages } from 'react-intl';

export default defineMessages({
  products: {
    id: 'components.myaccount.order.products',
    defaultMessage: 'Mes produits',
  },
  noProduct: {
    id: 'components.myaccount.order.noProduct',
    defaultMessage: "Vous n'avez actuellement aucune commande...",
  },
  errorOccured: {
    id: 'components.myaccount.order.errorOccured',
    defaultMessage: 'Oooops il y a eu un problème !',
  },
  add: {
    id: 'components.myaccount.order.add',
    defaultMessage: 'Ajouter un produit',
  },
  invoice: {
    id: 'components.myaccount.order.invoice',
    defaultMessage: 'Facture : ',
  },
  available: {
    id: 'components.myaccount.order.available',
    defaultMessage: 'Disponible',
  },
  reference: {
    id: 'components.myaccount.order.reference',
    defaultMessage: 'N° {reference} ',
  },
  buyDate: {
    id: 'components.myaccount.order.buyDate',
    defaultMessage: "Date d'achat",
  },
  purchaseChannel: {
    id: 'components.myaccount.order.purchaseChannel',
    defaultMessage: 'Canal de vente',
  },
  seller: {
    id: 'components.myaccount.order.seller',
    defaultMessage: 'Vendeur',
  },
  New: {
    id: 'components.myaccount.order.New',
    defaultMessage: 'Neuf',
  },
  Used: {
    id: 'components.myaccount.order.Used',
    defaultMessage: 'Occasion',
  },
  Reconditioned: {
    id: 'components.myaccount.order.Reconditioned',
    defaultMessage: 'Reconditionné',
  },
  Unknown: {
    id: 'components.myaccount.order.Unknown',
    defaultMessage: 'Unknown',
  },
  model: {
    id: 'components.myaccount.order.model',
    defaultMessage: 'Modèle',
  },
  brand: {
    id: 'components.myaccount.order.brand',
    defaultMessage: 'Marque',
  },
  state: {
    id: 'components.myaccount.order.state',
    defaultMessage: 'Etat',
  },
  purchaseDate: {
    id: 'components.myaccount.order.buyDate',
    defaultMessage: "Date d'achat",
  },
  endOfWarranty: {
    id: 'components.myaccount.order.endOfWarranty',
    defaultMessage: 'Fin de garantie',
  },
  until: {
    id: 'components.myaccount.order.until',
    defaultMessage: "jusqu'au",
  },
  followUp: {
    id: 'components.myaccount.order.followUp',
    defaultMessage: 'Continuer la déclaration',
  },
  declare: {
    id: 'components.myaccount.order.declare',
    defaultMessage: 'Déclarer un problème',
  },
  seeFileDetails: {
    id: 'components.myaccount.order.seeFileDetails',
    defaultMessage: 'Suivre le dossier ',
  },
  deposit: {
    id: 'components.myaccount.common.deposit',
    defaultMessage: 'Handling (Outward)',
  },
  delivery: {
    id: 'components.myaccount.common.delivery',
    defaultMessage: 'Handling (Return)',
  },
  phoneNumber: {
    id: 'view.card.client.phone',
    defaultMessage: 'Phone number',
  },
  issue: {
    id: 'components.myaccount.common.issue',
    defaultMessage: 'Issue',
  },
  solution: {
    id: 'components.myaccount.common.solution',
    defaultMessage: 'Solution',
  },
});
