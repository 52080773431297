import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const MenuHamburgerOpenIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M3 18h13v-2H3v2zm0-5h10v-2H3v2zm0-7v2h13V6H3zm18 9.59L17.42 12 21 8.41 19.59 7l-5 5 5 5L21 15.59z" />
  </Icon>
);

MenuHamburgerOpenIcon.displayName = 'MenuHamburgerOpenIcon';

export default MenuHamburgerOpenIcon;
