import { defineMessages } from 'react-intl';

export default defineMessages({
  Functional: {
    id: 'sparePart.type.Functional',
    defaultMessage: 'Functional',
  },
  Aesthetic: {
    id: 'sparePart.type.Aesthetic',
    defaultMessage: 'Aesthetic',
  },
  Accessory: {
    id: 'sparePart.type.Accessory',
    defaultMessage: 'Accessory',
  },
  Consumable: {
    id: 'sparePart.type.Consumable',
    defaultMessage: 'Consumable',
  },
  Kit: {
    id: 'sparePart.type.Kit',
    defaultMessage: 'Kit',
  },
  none: {
    id: 'sparePart.type.None',
    defaultMessage: 'None',
  },
});
