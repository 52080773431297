import { defineMessages } from 'react-intl';

export default defineMessages({
  salesConditions: {
    id: 'components.myaccount.claims.confirmation.confirmationButton.salesConditions',
    defaultMessage: 'J’accepte les conditions générales de service',
  },
  readSalesConditions: {
    id: 'components.myaccount.claims.confirmation.confirmationButton.readSalesConditions',
    defaultMessage: 'Lire les conditions générales de service',
  },
  termsAndConditions: {
    id: 'components.myaccount.claims.confirmation.confirmationButton.termsAndConditions',
    defaultMessage:
      'I have read and accept the terms and Conditions of the use,terms and sale conditions.',
  },
  goToConditions: {
    id: 'components.myaccount.claims.confirmation.confirmationButton.goToConditions',
    defaultMessage: 'Read the Terms of services',
  },
  salesConditionsMustBeReadError: {
    id: 'components.myaccount.claims.confirmation.confirmationButton.salesConditionsMustBeReadError',
    defaultMessage: 'Veuilez valider les conditions générales de vente',
  },
  validate: {
    id: 'components.myaccount.claims.confirmation.confirmationButton.validate',
    defaultMessage: 'Valider',
  },
});
