import styled from 'styled-components';

import { mediaQuery } from '../../grid/helpers';
import { rem } from '../../helpers';

export const $ProductImage = styled.img`
  padding: 10px;
`;

export const $ImageCardStyled = styled('div')<{ $imgErrorUrl: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: ${({ $imgErrorUrl }) => ($imgErrorUrl ? 'unset' : '2rem')};
  margin: 0;
  border: 0;
  overflow: hidden;
  box-shadow: unset;
  ${mediaQuery.md`
    border: ${rem('1px')} solid #e1e4e8;
  `};

  height: 50px;
  max-width: 50px;

  img {
    object-fit: contain;
    max-height: ${rem('74px')};
    max-width: ${rem('74px')};
  }

  &:hover > * {
    opacity: 0.8;
  }
`;
