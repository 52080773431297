import find from 'lodash/find';
import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE } from 'redux-first-history';
import { call, cancel, fork, take, takeEvery } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { BASE_ROUTES, ROUTES, ROUTES_NEW_DESIGN } from '../../view/app/routes/Routes';
import { ROUTES_PROFESSIONAL } from '../../view/app/routes/ProfessionalRoutes'
import claimGroupEditionView from '../ClaimGroupEdition/saga';
import { loadFileTrackingPage } from '../FileTrackingPage/fileTrackingView';

import orderView from './orderView';

const viewSagas = {
  [ROUTES.FILE_FOLLOWUPS]: loadFileTrackingPage,
  [`${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.FILE_FOLLOWUPS}`]: loadFileTrackingPage,
  [`${ROUTES_PROFESSIONAL.FILE_FOLLOWUPS}`]: loadFileTrackingPage,
  [ROUTES.PRODUCTS]: orderView,
  [ROUTES.ORDER_CREATE_CLAIM]: orderView,
  [ROUTES.CLAIM_GROUP_DETAILS]: claimGroupEditionView,
};

const checkPageName = [
  ROUTES.HOME,
  ROUTES.PRODUCTS,
  ROUTES.CLAIM_GROUP_ISSUES,
  ROUTES.FILE_FOLLOWUPS,
  ROUTES.CLAIM_GROUP_DETAILS,
  ROUTES.ORDER_CREATE_CLAIM,
  ROUTES.CLAIM_HANDLING_DELIVERY,
  ROUTES.CLAIM_HANDLING_DEPOSIT,
  ROUTES.CLAIM_GROUP_HANDLING,
  ROUTES.CLAIM_GROUP_CONFIRMATION,
  ROUTES.PAGE_NOT_FOUND,
];

let selectedView = null;
let task = null;
let signedIn = false;
let lastKnownPath = null;

function* locationChangeWorker({ payload }) {
  const { pathname } = payload.location;

  lastKnownPath = pathname;
  if (signedIn) {
    const viewSagaKey = Object.keys(viewSagas).find(
      (route) =>
        !!matchPath(pathname, {
          path: route,
          exact: false,
          strict: false,
        }),
    );

    if (selectedView === viewSagaKey) {
      // no need to reload
      // return;
    }

    selectedView = viewSagaKey;
    const saga = viewSagas[viewSagaKey];

    if (task) {
      yield cancel(task);
    }

    if (saga) {
      task = yield fork(saga);
    }
  }
}

function findPagename(pathname) {
  return find(
    checkPageName,
    (route) =>
      !!matchPath(pathname, {
        path: route,
        exact: true,
        strict: true,
      }),
  );
}

export default function* locationChangeWatcher() {
  let currentPage = {};

  function* trackPageAndChangeLocation({ payload }) {
    const { pathname } = payload.location;
    const pageName = findPagename(pathname);

    if (currentPage.pathname !== pathname && signedIn && pageName) {
      currentPage = {
        pathname,
        pagename: pageName,
      };
    }
    yield call(locationChangeWorker, { payload });
  }

  try {
    yield fork(function* ensureSignedIn() {
      yield take('LOGIN_SIGNEDIN');
      signedIn = true;
      // reload last known path
      if (lastKnownPath) {
        yield call(trackPageAndChangeLocation, {
          payload: { location: { pathname: lastKnownPath } },
        });
      }
    });

    yield takeEvery(LOCATION_CHANGE, trackPageAndChangeLocation);
  } catch (error) {
    logCritical(error);
  }
}
