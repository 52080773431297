import styled from 'styled-components';

export const $AdditionalInformationContainer = styled.div`
  margin-top: 1.5rem;
`;
export const $LoaderWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const $UploadLegend = styled.legend`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
`;
