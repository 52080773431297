import { VerifiedAddress } from 'myaccount/api/Carrier/getVerifiedAddress';

import {
  ADDRESS_MANAGEMENT_FIELDS,
  AddressManagementValues,
} from '../AddressManagement.types';

interface AdaptVerifiedAddressArgs {
  verifiedAddress: VerifiedAddress;
  formattedCustomerInformation: VerifiedAddress;
  keepSuggestedAddress: string;
}

const adaptVerifiedAddress = ({
  verifiedAddress,
  formattedCustomerInformation,
  keepSuggestedAddress,
}: AdaptVerifiedAddressArgs): AddressManagementValues => {
  return {
    [ADDRESS_MANAGEMENT_FIELDS.ADDRESS]: verifiedAddress?.street1,
    [ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_ADDRESS]: verifiedAddress?.street2,
    [ADDRESS_MANAGEMENT_FIELDS.PHONE]: verifiedAddress?.phone,
    [ADDRESS_MANAGEMENT_FIELDS.COMPANY_NAME]: verifiedAddress?.companyName,
    [ADDRESS_MANAGEMENT_FIELDS.COUNTRY_CODE]: verifiedAddress?.countryCode,
    [ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_INFORMATION]:
      verifiedAddress?.additionalInformation,
    [ADDRESS_MANAGEMENT_FIELDS.LAST_NAME]: verifiedAddress?.contactLastName,
    [ADDRESS_MANAGEMENT_FIELDS.FIRST_NAME]: verifiedAddress?.contactFirstName,
    [ADDRESS_MANAGEMENT_FIELDS.CIVILITY]: verifiedAddress?.civility,
    [ADDRESS_MANAGEMENT_FIELDS.POSTAL_CODE]: verifiedAddress?.zipCode,
    [ADDRESS_MANAGEMENT_FIELDS.CITY]: verifiedAddress?.city,
    [ADDRESS_MANAGEMENT_FIELDS.KEEP_SUGGESTED_ADDRESS]: keepSuggestedAddress,
    [ADDRESS_MANAGEMENT_FIELDS.HAS_ELEVATOR]:
      formattedCustomerInformation?.hasElevator,
    [ADDRESS_MANAGEMENT_FIELDS.HAS_PARKING_SPACE]:
      formattedCustomerInformation?.hasParkingSpace,
    [ADDRESS_MANAGEMENT_FIELDS.HOUSING_TYPE]:
      formattedCustomerInformation?.housingType,
    [ADDRESS_MANAGEMENT_FIELDS.DOOR_CODE]:
      formattedCustomerInformation?.doorCode,
    [ADDRESS_MANAGEMENT_FIELDS.FLOOR]: formattedCustomerInformation?.floor,
  };
};

export default adaptVerifiedAddress;
