import { PaymentElement } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Col } from 'react-styled-flexboxgrid';

import {
  BUTTON_TYPES,
  CURRENCIES,
  INPUT_TYPES,
} from '@savgroup-front-common/constants/src/shared';
import { ClosedLockUnfilled } from '@savgroup-front-common/core/src/protons/icons';
import { Input } from '@savgroup-front-common/core/src/atoms/Form';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { formatStripeAmount } from '@savgroup-front-common/core/src/formatters/intl';

import messages from '../../messages';

import useCheckoutForm from './CheckoutForm.hooks';
import {
  $PaymentButtonContainer,
  $PaymentContainer,
  $PaymentInputContainer,
} from './CheckoutForm.styles';

interface CheckoutFormProps {
  onAllSubmit: () => void;
  isFormCompleted: boolean;
  invoice: { id: string; priceWithTax: number };
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  onAllSubmit,
  isFormCompleted,
  invoice,
}) => {
  const [paymentIsComplete, setPaymentIsComplete] = useState(false);
  const { formContext, onSubmit, paymentLoading } = useCheckoutForm({
    onAllSubmit,
    invoiceId: invoice.id,
  });

  const {
    control,
    formState: { errors, isValid },
  } = formContext;

  return (
    <$PaymentContainer>
      <FormProvider {...formContext}>
        <form onSubmit={onSubmit}>
          <$PaymentInputContainer>
            <Col sm={12} xs={12}>
              <Controller
                control={control}
                name="name"
                render={({ field }) => {
                  return (
                    <Input
                      label={messages.creditCardHolder}
                      type={INPUT_TYPES.TEXT}
                      value={field.value}
                      errors={errors}
                      onChange={field.onChange}
                      isRequired
                    />
                  );
                }}
              />
            </Col>
          </$PaymentInputContainer>
          <PaymentElement
            onChange={(event) => {
              if (event.complete) {
                setPaymentIsComplete(true);
              }
            }}
          />
          <$PaymentButtonContainer>
            <Button
              fluid
              primary
              isLoading={paymentLoading}
              disabled={!isValid || !paymentIsComplete || !isFormCompleted}
              type={BUTTON_TYPES.SUBMIT}
              icon={<ClosedLockUnfilled />}
              dataTestId="paymentFormSubmitButton"
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.pay}
                values={{
                  amount: formatStripeAmount(
                    invoice.priceWithTax,
                    CURRENCIES.EUR,
                  ),
                }}
              />
            </Button>
          </$PaymentButtonContainer>
        </form>
      </FormProvider>
    </$PaymentContainer>
  );
};

CheckoutForm.displayName = 'CheckoutForm';

export default CheckoutForm;
