import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.myaccount.interventionAppointment.title',
    defaultMessage: 'Take an appointment for intervention',
  },
  whatINeedToDo: {
    id: 'components.interventionAppointment.whatINeedToDo',
    defaultMessage: 'What i need to do ?',
  },
  validate: {
    id: 'components.myaccount.interventionAppointment.validate',
    defaultMessage: 'Validate',
  },

  takeAppointment: {
    id: 'components.interventionAppointment.takeAppointment',
    defaultMessage: 'Take appointment',
  },
  takeAppointmentDidactic: {
    id: 'components.interventionAppointment.takeAppointmentDidactic',
    defaultMessage: 'with a technician for a home intervention',
  },
  intervention: {
    id: 'components.interventionAppointment.intervention',
    defaultMessage: 'Home intervention',
  },
  interventionDidactic: {
    id: 'components.interventionAppointment.interventionDidactic',
    defaultMessage:
      'by the technician (repair and/or ordering of parts according to diagnosis)',
  },
  takeAppointmentDone: {
    id: 'components.interventionAppointment.takeAppointmentDone',
    defaultMessage: "It's done!",
  },
  takeAppointmentDoneDidactic: {
    id: 'components.interventionAppointment.takeAppointmentDoneDidactic',
    defaultMessage: 'your product has, if possible, been repaired',
  },
});
