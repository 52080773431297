import styled from 'styled-components';

import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { H3, H5 } from '@savgroup-front-common/core/src/atoms/headings';
import { LinkButton } from '@savgroup-front-common/core/src/atoms/link';

export const TitleH2 = styled(H3)`
  color: ${({ theme }) => theme.colors.headingTextColor};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
`;

export const TitleRow = styled(H5)`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  margin-bottom: 5px;
`;
export const DocumentsMessageColumn = styled(H3)`
  color: ${({ theme }) => theme.colors.headingTextColor};
  display: flex;
  flex-direction: column;
`;

export const ReasonColumn = styled('div')`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const Column = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const ReasonAndSolutionRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SolutionColumn = styled('div')`
  margin-right: auto;
  margin-left: 10%;
`;

export const ImageContainer = styled(Card)`
  margin-top: 5px;
  border: 1px solid #e1e4e8;
  box-shadow: none;
  display: flex;
  justify-content: center;
  margin-right: 16px;
  width: 38px;
  height: 38px;
  padding: 0;

  svg {
    width: 38px;
    padding: 8px;
  }
`;

export const SolutionText = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const SolutionContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const SolutionTitle = styled(H3)`
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 14px;
`;

export const SolutionPrice = styled(H3)`
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  color: ${({ theme }) => theme.colors.paragraphTextColor};
`;

export const Divider = styled('div')`
  background-color: #e1e4e8;
  height: 1px;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 10px;
`;

export const $LinkButton = styled(LinkButton)`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;
