import React, { FunctionComponent } from 'react';

import Card from '../../../../../atoms/Cards/Card';
import { SafeFormattedMessageWithoutSpread } from '../../../../../formatters/SafeFormattedMessageWithoutSpread';

import AppointmentCalendar from './AppointmentCalendar/AppointmentCalendar';
import {
  $AppointmentCalendarContainer,
  $CalendarCard,
  $Title,
} from './AppointmentCalendarContainer.styles';
import InterventionAppointmentHeader from './InterventionAppointmentHeader/InterventionAppointmentHeader';
import messages from './messages';
import { useInterventionAppointment } from '../TakeInterventionAppointment.hooks';

interface AppointmentCalendarContainerProps {
  fileId: string;
  sellerId: string;
  onSubmit: () => void;
  isLoading?: boolean;
  isActive?: boolean;
}

const AppointmentCalendarContainer: FunctionComponent<
  React.PropsWithChildren<AppointmentCalendarContainerProps>
> = ({
  children,
  fileId,
  sellerId,
  onSubmit,
  isLoading = false,
  isActive = true,
}) => {
  const { handleValidateSchedule, isCreateTechnicalInterventionLoading } =
    useInterventionAppointment({ fileId, onSubmit, sellerId });

  return (
    <Card colored>
      <$Title>
        <SafeFormattedMessageWithoutSpread message={messages.whatINeedToDo} />
      </$Title>

      <InterventionAppointmentHeader />

      <$Title>
        <SafeFormattedMessageWithoutSpread message={messages.title} />
      </$Title>

      <$CalendarCard>
        <$AppointmentCalendarContainer>
          <AppointmentCalendar
            isGetTimeslotsLoading={isLoading}
            isCreateTechnicalInterventionLoading={
              isCreateTechnicalInterventionLoading
            }
            isLoading={isLoading}
            isNextPageDisabled={false}
            isActive={isActive}
            onValidateSchedule={handleValidateSchedule}
          >
            {children}
          </AppointmentCalendar>
        </$AppointmentCalendarContainer>
      </$CalendarCard>
    </Card>
  );
};

AppointmentCalendarContainer.displayName = 'AppointmentCalendarContainer';

export default AppointmentCalendarContainer;
