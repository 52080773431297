import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '../../helpers';
import { callAndGetResponse } from '../../services';

import {
  DOWNLOAD_SHIPPING_LABEL,
  GET_SHIPPING_LABEL,
  LOAD_CARRIERS_PRODUCTS,
  LOAD_EXTERNAL_CARRIERS,
  LOAD_LABELS,
  LOAD_PICKUP_SCHEDULE,
  LOAD_TRANSPORT_METHODS,
} from './actionTypes';
import { verifyAddressWatcher } from './verifiedAddress/saga';

function* loadLabelsWorker({
  payload: { fileId },
}: {
  payload: { fileId: string };
}) {
  const meta = { fileId };

  if (!fileId) {
    yield put(LOAD_LABELS.end());

    return;
  }

  yield call(
    callAndGetResponse,
    LOAD_LABELS,
    `${APIConfiguration.carrier}carriers/files/${fileId}/labels`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    meta,
  );
  yield put(LOAD_LABELS.end());
}

function* loadLabelsWatcher() {
  yield takeEvery<Action<{ fileId: string }>>(
    LOAD_LABELS.BASE,
    loadLabelsWorker,
  );
}

function* loadExternalCarriersWorker() {
  yield call(
    callAndGetResponse,
    LOAD_EXTERNAL_CARRIERS,
    `${APIConfiguration.carrier}externalcarriercompanies`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );
  yield put(LOAD_EXTERNAL_CARRIERS.end());
}

function* loadExternalCarriersWatcher() {
  yield takeEvery(LOAD_EXTERNAL_CARRIERS.BASE, loadExternalCarriersWorker);
}

type LoadPickupScheduleWorkerPayload =
  | {
      countryCode: string;
      carrier: string;
      startTimeUtc: string;
    }
  | Record<string, never>;

function* loadPickupScheduleWorker({
  payload = {},
}: {
  payload: LoadPickupScheduleWorkerPayload;
}) {
  const { countryCode, carrier, startTimeUtc } = payload;

  yield call(
    callAndGetResponse,
    LOAD_PICKUP_SCHEDULE,
    buildUrl(`${APIConfiguration.carrier}homePickup/schedule/${countryCode}`, {
      carrier,
      startTimeUtc,
    }),
    { method: SUPPORTED_METHODS.GET },
    {
      countryCode,
      carrier,
    },
  );
  yield put(LOAD_PICKUP_SCHEDULE.end());
}

function* loadPickupScheduleWatcher() {
  yield takeEvery<Action<LoadPickupScheduleWorkerPayload>>(
    LOAD_PICKUP_SCHEDULE.BASE,
    loadPickupScheduleWorker,
  );
}

interface GetShippingLabelWorkerPayload {
  fileId: string;
  moduleDefinitionId: string;
  moduleInstanceId: string;
}

function* getShippingLabelWorker({
  payload: { fileId, moduleDefinitionId, moduleInstanceId },
}: {
  payload: GetShippingLabelWorkerPayload;
}) {
  yield call(
    callAndGetResponse,
    GET_SHIPPING_LABEL,
    `${APIConfiguration.carrier}carriers/files/${fileId}/urls/${moduleDefinitionId}/${moduleInstanceId}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      fileId,
      internalId: `${fileId}-${moduleDefinitionId}-${moduleInstanceId}`,
    },
  );

  yield put(GET_SHIPPING_LABEL.end());
}

function* getShippingLabelWatcher() {
  yield takeEvery<Action<GetShippingLabelWorkerPayload>>(
    GET_SHIPPING_LABEL.BASE,
    getShippingLabelWorker,
  );
}

function* loadTransportMethodsWorker() {
  yield call(
    callAndGetResponse,
    LOAD_TRANSPORT_METHODS,
    `${APIConfiguration.carrier}transportmethods`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );
  yield put(LOAD_TRANSPORT_METHODS.end());
}

function* loadTransportMethodsWatcher() {
  yield takeEvery(LOAD_TRANSPORT_METHODS.BASE, loadTransportMethodsWorker);
}

function* loadCarriersProductsWorker() {
  yield call(
    callAndGetResponse,
    LOAD_CARRIERS_PRODUCTS,
    `${APIConfiguration.carrier}carriers/products`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );
  yield put(LOAD_CARRIERS_PRODUCTS.end());
}
function* loadCarriersProductsWatcher() {
  yield takeEvery(LOAD_CARRIERS_PRODUCTS.BASE, loadCarriersProductsWorker);
}

interface DownloadShippingLabelWorkerPayload {
  label: { moduleDefinitionId: string; moduleInstanceId: string };
  fileId: string;
  downloadWindow: any;
}

function* downloadShippingLabelWorker({
  payload,
}: {
  payload: DownloadShippingLabelWorkerPayload;
}): Generator {
  const { label, fileId, downloadWindow } = payload;
  const response: any = yield callAndGetResponse(
    DOWNLOAD_SHIPPING_LABEL,
    `${APIConfiguration.carrier}carriers/files/${fileId}/urls/${label.moduleDefinitionId}/${label.moduleInstanceId}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  yield put(DOWNLOAD_SHIPPING_LABEL.end());

  const shippingLabelUrl = response?.payload?.value;

  if (shippingLabelUrl) {
    const shippingLabel =
      APIConfiguration.carrier +
      shippingLabelUrl.replace('v1/', '').replace('{fileId}', fileId);

    downloadWindow.location.href = shippingLabel;
  } else {
    downloadWindow.close();
  }
}
function* downloadShippingLabelUrlWatcher() {
  yield takeEvery<Action<DownloadShippingLabelWorkerPayload>>(
    DOWNLOAD_SHIPPING_LABEL.BASE,
    downloadShippingLabelWorker,
  );
}

export default function* mainSaga() {
  try {
    yield all([
      loadLabelsWatcher(),
      loadExternalCarriersWatcher(),
      verifyAddressWatcher(),
      loadTransportMethodsWatcher(),
      loadCarriersProductsWatcher(),
      getShippingLabelWatcher(),
      loadPickupScheduleWatcher(),
      downloadShippingLabelUrlWatcher(),
    ]);
  } catch (error) {
    logCritical(error as any);
  }
}
