import React, {
  FunctionComponent,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import { v4 } from 'uuid';

import { BUTTON_TYPES, FEATURE_FLAGS } from '@savgroup-front-common/constants';
import { MessageType } from '@savgroup-front-common/types';

import { CopyField } from '../../atoms/CopyField';
import messages from '../../atoms/CopyField/messages';
import { notice } from '../../atoms/Toast';
import { useIsFeatureEnabled } from '../../components/FeatureManager/hooks';
import { safeFormattedIntlString } from '../../formatters';
import useCopy from '../../protons/CopyOnClick/useCopy';

import {
  $FieldContainer,
  $FieldValue,
  $FieldValueContainer,
  $MaxWidthDiv,
  $SpannedButton,
} from './Field.styles';
import { FIELD_ALIGN } from './Field.types';
import FieldLabel from './FieldLabel';
import IconCopy from './IconCopy';

interface FieldProps {
  label?: string | number | MessageType;
  copy?: number | string | MessageType;
  copyOnlyOnIconClick?: boolean;
  dataTestId?: string;
  labelIcon?: ReactElement;
  labelIconAlign?: FIELD_ALIGN;
  actions?: ReactElement[];
  align?: FIELD_ALIGN;
  hasMaxWidth?: boolean;
  onChildrenClick?: MouseEventHandler<HTMLButtonElement>;
  onLabelClick?: MouseEventHandler<HTMLButtonElement>;
  noMargin?: boolean;
  isEllipsisEnabled?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  isNewUi?: boolean;
  isFluid?: boolean;
}

const Field: FunctionComponent<React.PropsWithChildren<FieldProps>> = ({
  children,
  copy,
  copyOnlyOnIconClick = true,
  label,
  labelIcon,
  labelIconAlign = FIELD_ALIGN.LEFT,
  dataTestId,
  actions = [],
  align = FIELD_ALIGN.LEFT,
  onChildrenClick,
  onLabelClick,
  hasMaxWidth = false,
  noMargin = false,
  isEllipsisEnabled = true,
  isError = false,
  isSuccess = false,
  isNewUi = false,
  isFluid = false,
}) => {
  const hasCopy =
    Boolean(copy) &&
    (typeof copy === 'string' ||
      typeof copy === 'number' ||
      Boolean(copy?.id && copy?.defaultMessage));

  const [toastId, setToastId] = useState(() => v4());
  const { handleClick } = useCopy();

  const handleIconClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      if (copyOnlyOnIconClick) {
        handleClick(e, safeFormattedIntlString(copy));
        notice.info(messages.textCopied, {
          toastId,
          onClose: () => setToastId(v4()),
        });
      }
    },
    [copy, copyOnlyOnIconClick, handleClick, toastId],
  );

  const isNewUiEnabled =
    useIsFeatureEnabled(FEATURE_FLAGS.BO_NEW_UI) || isNewUi;

  return (
    <$FieldContainer
      $noMargin={noMargin}
      $isNewUi={isNewUiEnabled}
      $isFluid={isFluid}
    >
      <FieldLabel
        label={label}
        labelIcon={labelIcon}
        labelIconAlign={labelIconAlign}
        align={align}
        onClick={onLabelClick}
      />
      <$FieldValueContainer
        $align={align}
        $isError={isError}
        $isSuccess={isSuccess}
        $isFluid={isFluid}
      >
        <CopyField
          copy={
            hasCopy && !copyOnlyOnIconClick
              ? safeFormattedIntlString(copy)
              : undefined
          }
        >
          <$FieldValue
            $hasCopy={hasCopy || actions.length > 0}
            data-testid={dataTestId}
            hasMaxWidth={hasMaxWidth}
            $noMargin={noMargin}
            $isEllipsisEnabled={isEllipsisEnabled}
            $isError={isError}
            $isSuccess={isSuccess}
            $isNewUi={isNewUiEnabled}
            $isFluid={isFluid}
          >
            {align === FIELD_ALIGN.RIGHT && hasCopy ? (
              <IconCopy handleIconClick={handleIconClick} />
            ) : null}

            {align === FIELD_ALIGN.RIGHT ? actions : null}

            {onChildrenClick ? (
              <$SpannedButton
                type={BUTTON_TYPES.BUTTON}
                onClick={onChildrenClick}
              >
                {children}
              </$SpannedButton>
            ) : (
              <$MaxWidthDiv hasMaxWidth={hasMaxWidth} $isFluid={isFluid}>
                {children}
              </$MaxWidthDiv>
            )}

            {align === FIELD_ALIGN.LEFT && hasCopy ? (
              <IconCopy handleIconClick={handleIconClick} />
            ) : null}

            {align === FIELD_ALIGN.LEFT ? actions : null}
          </$FieldValue>
        </CopyField>
      </$FieldValueContainer>
    </$FieldContainer>
  );
};

Field.displayName = 'Field';

export default Field;
