import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { H2 } from '@savgroup-front-common/core/src/atoms/headings';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const ChooseProductsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const ColDescription = styled(Col)`
  padding-bottom: 1rem;
`;

export const ColSubmitButton = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Divider = styled.div`
  height: 1px;
  background: #ccc;
  margin: 3rem 0 2rem;
`;

export const HeaderNotEligible = styled(H2)`
  color: #333;
  margin: 0 0 0;
  padding: 1rem 0 0;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
`;

export const ReturnBottomRow = styled(Row)`
  margin-bottom: 27px;
`;

export const StyledCard = styled(Card)`
  margin-bottom: 0;

  &:last-child {
    margin-bottom: ${rem('32px')};
  }
`;

export const ChooseProducsContainer = styled.div`
  padding-top: 1rem;
`;
