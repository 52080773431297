import { darken, transparentize } from 'polished';
import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { H3, H5 } from '@savgroup-front-common/core/src/atoms/headings';
import { Tag } from '@savgroup-front-common/core/src/atoms/Tag';
import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';
import { rem } from '@savgroup-front-common/core/src/helpers';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';

export const $ImageContainer = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: 0;

  box-shadow: unset;
  border: ${rem(1)} solid #e1e4e8;

  height: ${rem(55)};
  width: ${rem(55)};
  margin-bottom: 1rem;

  img {
    object-fit: contain;
    max-height: ${rem(74)};
    max-width: ${rem(74)};
  }
  ${mediaQuery.md`
    height: ${rem(96)};
    width: ${rem(96)};
    margin-bottom: unset;
  `}
`;

export const $OutOfWarrantyTag = styled(Tag)`
  max-width: inherit;
  background-color: ${({ theme, disabled = false }) =>
    disabled ? theme.colors.default : transparentize(0.9, theme.colors.alert)};
  color: ${({ theme, disabled = false }) =>
    disabled ? theme.colors.default : theme.colors.alert};
  margin: 0;
`;

export const $StyledH3 = styled(H3)`
  line-height: ${rem(20)};
  padding-top: 0;
`;

export const $ViewHistoryButton = styled(Button)`
  justify-content: flex-start;
  color: ${({ theme }) => darken(0.05, theme.colors.default)};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  margin: ${rem(12)} 0;
  margin-top: 0;
  font-size: ${rem(13)};
  padding-top: ${rem(4)};
  padding-left: ${rem(14)};

  ${mediaQuery.md`
    margin: 0;
    justify-content: flex-start
  `};

  .children {
    padding-top: ${rem(2)};
    margin-left: 0;
  }

  svg {
    transform-origin: ${rem(50)} ${rem(50)};
    transform: scale(0.75);

    path {
      fill: #bbc1cb;
    }
  }
`;

export const $StyledH5 = styled(H5)`
  padding-top: 0;
`;

export const $StyledCol = styled(Col)`
  padding-bottom: ${rem(10)};
`;
export const $FullColl = styled(Col)`
  ${mediaQuery.sm`
    flex: 1;
  `};
`;
export const $MiniColl = styled(Col)`
  ${mediaQuery.sm`
    flex: 0;
  `};
`;

export const $RightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  ${mediaQuery.md`
    justify-content: flex-start;
  `};
`;

export const FlexColumn = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const $FullOrderProductInfoHeader = styled(Row)`
  width: 100%;
  margin-bottom: 0.25rem;
`;

export const $GridCustom = styled(Grid)`
  padding-left: unset;
  padding-right: unset;
  width: 100%;
`;

export const $RowInfo = styled(Row)`
  & > div:not(:last-child) {
    margin-bottom: 1rem;
    ${mediaQuery.md`
      margin-bottom: unset;
    `}
  }
`;

export const $RowReference = styled(Row)`
  align-items: baseline;
  ${mediaQuery.xs`
    margin-bottom: 1rem;
  `}
`;
export const $TextBold = styled.div`
  font-weight: bold;
`;

export const $CheckboxCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const $RepairedCol = styled(Col)`
  margin-left: auto;
`;

export const $ImageCardStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;

  box-shadow: unset;

  height: ${rem(55)};
  width: ${rem(55)};

  margin-bottom: 1rem;
  border: ${rem(1)} solid #e1e4e8;

  img {
    object-fit: contain;
    max-height: ${rem(74)};
    max-width: ${rem(74)};
  }
  ${mediaQuery.md`
    height: ${rem(96)};
    width: ${rem(96)};
    margin-bottom: unset;
  `}
`;
