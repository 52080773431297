import { createReducer } from 'myaccount/helpers/reducerUtils';

import * as actionTypes from './actionTypes';

const initialState = {
  myReturns: [],
  historic: {
    openFiles: [],
    closedFiles: [],
  },
  errors: [],
  isRunning: false,
};

function onIsRunning(state) {
  return {
    ...state,
    isRunning: true,
  };
}

function onIsRunningEnd(state) {
  return {
    ...state,
    isRunning: false,
  };
}

function openFilesLoaded(state, { value }) {
  const openFiles = value
    .filter((file) => !file.isClosed)
    .sort(
      (left, right) => new Date(right.createdDate) - new Date(left.createdDate),
    );

  const historic = {
    ...state.historic,
    openFiles,
  };

  return {
    ...state,
    historic,
    isRunning: false,
  };
}

function closedFilesLoaded(state, { value }) {
  const historic = {
    ...state.historic,
    closedFiles: value.sort(
      (left, right) => new Date(right.createdDate) - new Date(left.createdDate),
    ),
  };

  return {
    ...state,
    historic,
    isRunning: false,
  };
}

const reducer = createReducer(initialState, {
  [actionTypes.MYRETURNS_RUNNING.BASE]: onIsRunning,
  [actionTypes.MYRETURNS_RUNNING.END]: onIsRunningEnd,
  [actionTypes.OPEN_FILES_LOADED.SUCCEEDED]: openFilesLoaded,
  [actionTypes.CLOSED_FILES_LOADED.SUCCEEDED]: closedFilesLoaded,
});

export default reducer;
