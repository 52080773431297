import PropTypes from 'prop-types';
import React from 'react';
import { Transition } from 'react-transition-group';

import { BackgroundDimmerNaked } from './BackgroundDimmer.styles';

export default function BackgroundDimmer({
  isOpen,
  animationDuration,
  onClick,
  zIndex,
  opacity,
}) {
  return (
    <Transition in={isOpen} timeout={animationDuration} unmountOnExit>
      {(animationState) => (
        <BackgroundDimmerNaked
          animationDuration={animationDuration}
          animationState={animationState}
          onClick={onClick}
          $zIndex={zIndex}
          $opacity={opacity}
        />
      )}
    </Transition>
  );
}
BackgroundDimmer.displayName = 'BackgroundDimmer';
BackgroundDimmer.propTypes = {
  isOpen: PropTypes.bool,
  animationDuration: PropTypes.number,
  onClick: PropTypes.func,
  zIndex: PropTypes.number,
  opacity: PropTypes.number,
};

BackgroundDimmer.defaultProps = {
  isOpen: false,
  animationDuration: 400,
  onClick: undefined,
  zIndex: 3,
  opacity: 0.8,
};
