import { defineMessages } from 'react-intl';

export default defineMessages({
  loadingMessage: {
    id: 'components.chooseProducts.loadingProducts',
    defaultMessage: 'Products are loading, please wait',
  },
  back: {
    id: 'components.common.addressManagement.back',
    defaultMessage: 'Retour',
  },
});
