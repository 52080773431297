import React, { FC } from 'react';
import { FormattedDate } from 'react-intl';

import { SafeFormattedMessageWithoutSpread } from '../../../../formatters';
import { formatReverseMoneyToString } from '../../../../formatters/intl';
import { QuotationPendingCustomerValidationNewModel } from '../QuotationPendingCustomerValidation.types';
import { useCalculateQuotationPendingCustomerValidationTotals } from '../QuotationTotal';

import messages from './messages';
import { $LightSpan, $QuotationTitle } from './QuotationTitle.styles';

interface QuotationTitleProps {
  quotation: QuotationPendingCustomerValidationNewModel;
  fileId: string;
}

const QuotationTitle: FC<QuotationTitleProps> = ({ fileId, quotation }) => {
  const { totalAmountToBePaid } =
    useCalculateQuotationPendingCustomerValidationTotals({
      quotation,
      fileId,
    });

  return (
    <$QuotationTitle>
      <div>
        <span>
          <SafeFormattedMessageWithoutSpread message={messages.issuedOn} />
        </span>
        <$LightSpan>
          <FormattedDate
            year="numeric"
            month="2-digit"
            day="2-digit"
            value={quotation.createdDate}
          />
        </$LightSpan>
        <span>
          <SafeFormattedMessageWithoutSpread message={messages.by} />
        </span>
        <$LightSpan>{quotation.author?.fullname}</$LightSpan>
      </div>
      <div>
        <span>
          <SafeFormattedMessageWithoutSpread
            message={messages.quotationTotal}
          />{' '}
          :{' '}
        </span>
        <$LightSpan data-testid="quotationTitle_totalAmountIncludedTax">
          {formatReverseMoneyToString(totalAmountToBePaid)}
        </$LightSpan>
      </div>
    </$QuotationTitle>
  );
};

QuotationTitle.displayName = 'QuotationTitle';

export default QuotationTitle;
