import { transparentize } from 'polished';
import styled from 'styled-components';

import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const OrderProductListPosition = styled('div')`
  margin-top: 2rem;
`;
export const Separator = styled('div')`
  background-color: ${({ theme }) => transparentize(0.6, theme.colors.default)};
  height: 1px;
  margin-top: ${rem('32px')};
  margin-bottom: ${rem('32px')};
`;
export const SectionHeaderContainer = styled('div')`
  background: #fff;
  margin-top: -2rem;
  margin-bottom: 2rem;
  display: none;

  ${mediaQuery.md`display: block;`};
`;
