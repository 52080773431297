import styled from 'styled-components';

import { getTextColorBasedOnProps } from '../../theme/helpers';

export const LabelNoSolutionReportStyled = styled.p`
  color: ${getTextColorBasedOnProps}
  font-weight: bold;
  margin-bottom: 1rem;
  `;

export const Form = styled.form`
  flex: 1 100%;
  padding: 0 1rem 0 1rem;
`;
