import { defineMessages } from 'react-intl';

export default defineMessages({
  go: {
    id: 'components.myaccount.claims.issues.go',
    defaultMessage: 'Sélectionner',
  },
  noIssues: {
    id: `components.myaccount.claims.issues.noIssues`,
    defaultMessage: `Sorry, the warranty has expired. You cannot create claims for this product. In case of error, please contact your seller on the original website`,
  },
});
