import { useQuery } from 'react-query';

import { STALE_TIME } from '@savgroup-front-common/constants';
import { CommonClaimService } from '@savgroup-front-common/core/src/api';

import { AddressSelected } from '../CarrierForm.types';

interface UseGetDefaultAddressArgs {
  claimGroupId: string;
}

const useGetDefaultAddress = ({
  claimGroupId,
}: UseGetDefaultAddressArgs): AddressSelected | undefined => {
  const { data: defaultAddress } = useQuery(
    [
      CommonClaimService.getDefaultAddressForPickUpPointSearch.name,
      { claimGroupId },
    ],
    async () => {
      const response =
        await CommonClaimService.getDefaultAddressForPickUpPointSearch({
          claimGroupId,
        });

      if (response.failure) {
        return {};
      }

      return {
        defaultAddress: response.value,
        address: [
          response.value.address,
          response.value.postalCode,
          response.value.city,
          response.value.countryCode,
        ]
          .filter((value) => value && value.length > 0)
          .join(' '),
        countryCode: response.value.countryCode,
      };
    },
    {
      staleTime: STALE_TIME.HOURS_1,
    },
  );

  return defaultAddress;
};

export default useGetDefaultAddress;
