import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Card } from '@savgroup-front-common/core/src/atoms/Cards';

import messages from '../../../pages/FileFollowupsPage/FileFollowups/messages';
import { ActionLabel } from '../../ActionLabel';
import { CustomerCommentDisplay } from '../../CustomerCommentDisplay';

interface StatusWarningDisplayProps {
  adaptedFirstAction?: { toCustomerComment?: string };
}

const StatusWarningDisplay: FunctionComponent<StatusWarningDisplayProps> = ({
  adaptedFirstAction,
}) => {
  return (
    <>
      <ActionLabel action={adaptedFirstAction} index={0} />

      <CustomerCommentDisplay action={adaptedFirstAction} />

      <Card>
        <FormattedMessage {...messages.statusWarning} />
      </Card>
    </>
  );
};

StatusWarningDisplay.displayName = 'StatusWarningDisplay';

export default StatusWarningDisplay;
