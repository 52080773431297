import React from 'react';
import { useSelector } from 'react-redux';

import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';
import { Selectors as ClaimCreationSelectors } from 'myaccount/domains/Claim/ClaimCreation';

import {
  Divider,
  HeaderNotEligible,
  ProductsContainer,
  StyledCard,
} from '../ChooseProducts.styles';

import messages from './messages';
import NoEligibleOrderProductInfo from './NoEligibleOrderProductInfo';

export const NotEligibleProduct = () => {
  const notEligibleProducts =
    useSelector(ClaimCreationSelectors.currentNotEligibleProductsSelector) ||
    {};

  if (notEligibleProducts.length === 0) {
    return null;
  }

  return (
    <>
      <Divider />
      <HeaderNotEligible>
        <SafeFormattedMessage {...messages.titleNotEligible} />
      </HeaderNotEligible>

      <ProductsContainer>
        {notEligibleProducts.map((product) => (
          <StyledCard key={`${product.sellerProductId}${product.orderId}`}>
            <NoEligibleOrderProductInfo
              ownerProductId={product.ownerProductId}
              modelTypeId={product.modelTypeId}
              modelName={product.modelName}
              brandName={product.brandName}
              orderNumber={product.orderNumber}
              purchaseDate={product.purchaseDate}
              claimId={product.claimId}
              modelId={product.modelId}
              previousFile={product.previousFile}
              outcomeHistory={product?.outcomeHistory}
            />
          </StyledCard>
        ))}
      </ProductsContainer>
    </>
  );
};

NotEligibleProduct.displayName = 'NotEligibleProduct';

export default NotEligibleProduct;
