import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ActionCreators as claimDeadEndActionCreators } from '../../../domains/claims';

const useHandleDeadEndReportSubmit = ({
  groupId,
  claimIds,
  orderId,
  onReportSubmit,
}) => {
  const dispatch = useDispatch();

  return useCallback(
    ({ message }) => {
      dispatch(
        claimDeadEndActionCreators.setDeadEndForClaimGroup({
          message,
          groupId,
          claimIds,
          orderId,
        }),
      );
      if (onReportSubmit) {
        onReportSubmit({ message });
      }
    },
    [claimIds, dispatch, groupId, onReportSubmit, orderId],
  );
};

export default useHandleDeadEndReportSubmit;
