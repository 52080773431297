import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import { H3, H5 } from '@savgroup-front-common/core/src/atoms/headings';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import OrderProductCard from '../OrderProductInfo/OrderProductCard';

import {
  $ButtonContainer,
  $GridCustom,
  $StyledCardHeader,
} from './ClaimGroupProductCard.styles';
import messages from './messages';

const ViewGroupProductCard = ({
  products,
  fileNumber,
  fileDetailsLink,
  fileDetailsRoute,
}) => {
  return (
    <>
      <$StyledCardHeader>
        <$GridCustom>
          <Row middle="xs">
            <Col xs={6}>
              <H5>
                <SafeFormattedMessageWithoutSpread
                  message={messages.titleFileDetail}
                  values={{ fileReference: '' }}
                />
              </H5>
              <H3 heading>{fileNumber}</H3>
            </Col>
            <Col xs={6}>
              <$ButtonContainer>
                <ButtonLink
                  href={fileDetailsLink}
                  type={BUTTON_TYPES.BUTTON}
                  success
                  componentThemeName={
                    SUPPORTED_COMPONENTS.VIEW_FILE_DETAILS_BUTTON
                  }
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.seeFileDetails}
                  />
                </ButtonLink>
              </$ButtonContainer>
            </Col>
          </Row>
        </$GridCustom>
      </$StyledCardHeader>
      {products.map((product) => (
        <OrderProductCard
          fileDetailsRoute={fileDetailsRoute}
          key={product.ownerProductId}
          {...product}
        />
      ))}
    </>
  );
};

ViewGroupProductCard.displayName = 'ViewGroupProductCard';

export default ViewGroupProductCard;
