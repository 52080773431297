import { useQuery } from 'react-query';

import { CommonOwnerService } from '../api';

interface UseGetFullOwnerProductQueryArgs {
  ownerId?: string;
  ownerProductId?: string;
}

const useGetFullOwnerProductQuery = ({
  ownerId,
  ownerProductId,
}: UseGetFullOwnerProductQueryArgs) => {
  const { data: ownerProduct } = useQuery(
    ['GetFullOwnerProduct', { ownerId, ownerProductId }],
    async () => {
      if (!ownerId || !ownerProductId) {
        return undefined;
      }

      const response = await CommonOwnerService.getFullOwnerProductQuery({
        ownerId,
        ownerProductId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    { staleTime: Infinity },
  );

  return { ownerProduct };
};

export default useGetFullOwnerProductQuery;
