import React, { FunctionComponent, ReactElement, Ref } from 'react';

import { MessageType } from '@savgroup-front-common/types';

import { FEATURE_FLAGS } from '../../../../constants';
import { useIsFeatureEnabled } from '../../components/FeatureManager/hooks';
import { SafeFormattedMessage } from '../../formatters';
import { FieldMessage } from '../Form/common';
import { getFinalFieldState } from '../Form/common/helpers/getFinalFieldState';
import {
  FIELD_STATUS,
  FieldMessages,
} from '../Form/common/helpers/getFinalFieldState.types';

import {
  $CheckboxContainer,
  $CheckboxDescription,
  $CheckboxLabel,
} from './Checkbox.styles';
import CheckboxNaked, { CheckboxNakedProps } from './CheckboxNaked';

export interface CheckboxProps extends CheckboxNakedProps {
  label?: ReactElement | MessageType | number | string;
  errors?: FieldMessages;
  warnings?: FieldMessages;
  successes?: FieldMessages;
  isError?: boolean;
  isSuccess?: boolean;
  isWarning?: boolean;
  shouldHighlightWhenChecked?: boolean;
  description?: string;
  isFlexAlign?: boolean;
  isNewUi?: boolean;
  withoutMargin?: boolean;
}
interface CheckboxPropsWithRef extends CheckboxProps {
  forwardedRef: Ref<HTMLInputElement>;
}

const Checkbox: FunctionComponent<
  React.PropsWithChildren<CheckboxPropsWithRef>
> = ({
  forwardedRef,
  disabled = false,
  name = '',
  label = undefined,
  type = 'checkbox',
  value = undefined,
  dataTestId,
  errors = {},
  warnings = {},
  successes = {},
  isError = false,
  isWarning = false,
  isSuccess = false,
  shouldHighlightWhenChecked = false,
  checked,
  isIndeterminate,
  description,
  isFlexAlign = false,
  isNewUi = false,
  withoutMargin = false,
  ...props
}) => {
  const [status, message] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  const isNewBoEnabled =
    useIsFeatureEnabled(FEATURE_FLAGS.BO_NEW_UI) || isNewUi;

  const inputRender = (
    <CheckboxNaked
      {...props}
      isIndeterminate={isIndeterminate}
      checked={checked}
      disabled={disabled}
      danger={status === FIELD_STATUS.ERROR}
      ref={forwardedRef}
      name={name}
      type={type}
      value={value}
      dataTestId={dataTestId}
      hasDescription={!!description}
      isFlexAlign={isFlexAlign}
      isNewUi={isNewBoEnabled}
    />
  );

  const FieldOfError = (
    <FieldMessage message={message} status={status} dataTestId={dataTestId} />
  );

  if (!label) {
    return (
      <div>
        <$CheckboxContainer
          disabled={disabled}
          danger={status === FIELD_STATUS.ERROR}
          $isNewBoEnabled={isNewBoEnabled}
          $withoutMargin={withoutMargin}
        >
          {inputRender}
        </$CheckboxContainer>
        {FieldOfError}
      </div>
    );
  }

  return (
    <div style={{ flex: 1 }}>
      <$CheckboxLabel
        $isFlexAlign={isFlexAlign}
        disabled={disabled}
        danger={status === FIELD_STATUS.ERROR}
        data-testid={`${dataTestId}-label`}
        $checked={checked}
        $shouldHighlightWhenChecked={shouldHighlightWhenChecked}
        $isNewBoEnabled={isNewBoEnabled}
      >
        {inputRender}
        <div>
          {SafeFormattedMessage(label)}
          {description && (
            <$CheckboxDescription $isChecked={checked}>
              {description}
            </$CheckboxDescription>
          )}
        </div>
      </$CheckboxLabel>
      {FieldOfError}
    </div>
  );
};

Checkbox.displayName = 'Checkbox';

export default React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => <Checkbox forwardedRef={ref} {...props} />,
);
