import { Dictionary } from 'lodash';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import get from 'lodash/get';
import head from 'lodash/head';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import values from 'lodash/values';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { HANDLING_GROUPS } from '@savgroup-front-common/constants';
import { loadPickupPointsForCarrier } from '@savgroup-front-common/core/src/domains/claims/actionCreators';
import { AddressInfoDto, PickupPoint } from '@savgroup-front-common/types';
import {
  currentClaims,
  currentPickupPoints,
} from 'myaccount/domains/Claim/claimGroupSelector';
import { profileManagementSelector } from 'myaccount/domains/ProfileManagement/selectors';

import { Carrier } from '../../../ClaimGroupHandling.types';
import { AddressSelected, PickupPoints } from '../CarrierForm.types';

import useGetDefaultAddress from './useGetDefaultAddress';

interface UseGetPickupPointsArgs {
  selectedCarrierType: HANDLING_GROUPS;
  groupedCarriers: Dictionary<Carrier[]>;
  claimGroupId: string;
}

interface UseGetPickupPointsReturnValue {
  selectedPickupPoint?: PickupPoint;
  handleSetSelectedPickupPoint: (payload: PickupPoint) => void;
  carriers: Carrier[];
  addressSelected?: AddressSelected;
  addresses: AddressInfoDto[];
  handleSetAddressSelected: ({ address, countryCode }: AddressSelected) => void;
  pickupPoints: PickupPoints;
  pickupPointsIsLoading: boolean;
}

const useGetPickupPoints = ({
  groupedCarriers,
  selectedCarrierType,
  claimGroupId,
}: UseGetPickupPointsArgs): UseGetPickupPointsReturnValue => {
  const dispatch = useDispatch();
  const { pickupPoints, numberOfPickupPointsLoaded } =
    useSelector(currentPickupPoints);

  const claims = useSelector(currentClaims);
  const profilAddress = useSelector(profileManagementSelector);
  const { addresses } = profilAddress;
  const [selectedPickupPoint, setSelectedPickupPoint] = useState<
    PickupPoint | undefined
  >(undefined);
  const handleSetSelectedPickupPoint = useCallback((payload: PickupPoint) => {
    setSelectedPickupPoint(payload);
  }, []);

  const [addressSelected, setAddressSelected] = useState<
    AddressSelected | undefined
  >(undefined);

  const defaultAddress = useGetDefaultAddress({
    claimGroupId,
  });

  useDeepCompareEffect(() => {
    setAddressSelected(defaultAddress);
  }, [defaultAddress]);
  const carriers = includes(HANDLING_GROUPS, selectedCarrierType)
    ? filter(
        concat(
          groupedCarriers[HANDLING_GROUPS.PICKUP_POINT],
          groupedCarriers[HANDLING_GROUPS.PICKUP_STORE],
          groupedCarriers[HANDLING_GROUPS.PICKUP_STORE_DELIVERY],
        ),
        (value) => !isNil(value),
      )
    : groupedCarriers[selectedCarrierType];

  const isLoading = numberOfPickupPointsLoaded !== (carriers || []).length;

  const handleSetAddressSelected = useCallback(
    (payload: AddressSelected) => {
      const { address, countryCode } = payload;

      carriers.forEach(({ productType, carrierBrand }) => {
        dispatch(
          loadPickupPointsForCarrier({
            sellerId: get(head(values(claims)), 'sellerId'),
            claimId: get(head(values(claims)), 'claimId'),
            address,
            countryCode,
            maxDistance: 200,
            carrierCompany:
              productType === 'DropAtStore' ||
              productType === 'PickUpStoreDelivery'
                ? 'ExternalCarrier'
                : carrierBrand,
            productType,
          }),
        );
      });
      setAddressSelected(payload);
    },
    [carriers, claims, dispatch],
  );

  return {
    carriers,
    pickupPoints,
    addresses,
    addressSelected,
    handleSetAddressSelected,
    handleSetSelectedPickupPoint,
    selectedPickupPoint,
    pickupPointsIsLoading: isLoading,
  };
};

export default useGetPickupPoints;
