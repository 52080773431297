import React, { FC } from 'react';
import { $QualiReparLogo } from './QualireparLogo.styles';

export const QualireparLogo: FC = () => {
  return (
    <$QualiReparLogo
      data-testid="logoBonus"
      src={`${process.env.REACT_APP_STATIC_HASH}/images/quali-repar.png`}
      alt=""
    />
  );
};

QualireparLogo.displayName = 'QualireparLogo';
