import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ActionCreators as WorkflowActionCreators } from '@savgroup-front-common/core/src/domains/workflow';

const useAwaitingPayment = () => {
  const dispatch = useDispatch();

  return {
    resetPaymentFormErrors: useCallback(() => {
      return dispatch(WorkflowActionCreators.resetPaymentFormErrors);
    }, [dispatch]),
  };
};

export default useAwaitingPayment;
