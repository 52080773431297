import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import TabsBody from '@savgroup-front-common/core/src/atoms/Tabs/TabsBody';
import TabsNav from '@savgroup-front-common/core/src/atoms/Tabs/TabsNav';

import { customerState } from '../../../../domains/Customer/selectors';

import FileFollowupsContext from './FileFollowups.context';
import FileFollowupsHeader from './FileFollowupsHeader';
import FileStatus from './FileStatus';
import messages from './messages';
import Summary from './Summary';
import {
  MenuHamburgerCloseIcon,
  OrderIcon,
} from '@savgroup-front-common/core/src/protons/icons';

const tabs = [
  {
    name: 'status',
    message: messages.status,
    icon: <MenuHamburgerCloseIcon />,
    Component: FileStatus,
  },
  {
    name: 'summary',
    icon: <OrderIcon />,
    message: messages.summary,
    Component: Summary,
  },
];

const FileFollowups = () => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const isGridFluidActive = useMedia(media.maxWidth.md);

  const customer = useSelector(customerState);

  return (
    <FileFollowupsContext.Provider
      value={{ fileSummary: customer?.customerFileSummary }}
    >
      <FileFollowupsHeader>
        <TabsNav
          dataTestId="fileFollowUp"
          activeTab={activeTab}
          tabs={tabs}
          onTabChange={(value) => setActiveTab(value)}
        />
      </FileFollowupsHeader>
      <Grid fluid={isGridFluidActive}>
        <TabsBody tabs={tabs} activeTab={activeTab} />
      </Grid>
    </FileFollowupsContext.Provider>
  );
};

FileFollowups.displayName = 'FileFollowups';

export default FileFollowups;
