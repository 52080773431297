import React from 'react';

interface DepositIconProps {
  color?: string;
}

/* eslint-disable max-len */
const DeliveryIcon: React.FC<DepositIconProps> = ({ color }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.4688 12.1721L25.3273 18.4572L24.3689 16.3046L38.5103 10.0195L39.4688 12.1721ZM23.6696 34.6648V17.3809H26.0265V34.6648H23.6696ZM24.3689 18.4572L10.2274 12.1721L11.1859 10.0195L25.3273 16.3046L24.3689 18.4572Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8485 5.91544L37.9798 11.7526V27.723L24.8485 33.5602L11.7172 27.723V25.7987C11.7172 25.2409 11.2649 24.7886 10.7071 24.7886C10.1492 24.7886 9.69697 25.2409 9.69697 25.7987V27.767C9.69706 28.1445 9.80655 28.5141 10.0122 28.8306C10.2178 29.1472 10.5108 29.3973 10.8557 29.5509L24.0543 35.418C24.0545 35.418 24.0542 35.4179 24.0543 35.418C24.3042 35.5291 24.575 35.5867 24.8485 35.5867C25.1219 35.5867 25.3923 35.5293 25.6422 35.4182C25.6423 35.4181 25.642 35.4183 25.6422 35.4182L38.8408 29.5511C39.1857 29.3976 39.4791 29.1472 39.6848 28.8306C39.8904 28.5141 39.9999 28.1447 40 27.7672V11.7086C39.9999 11.3312 39.8904 10.9615 39.6848 10.645C39.4791 10.3284 39.1862 10.0783 38.8413 9.92477L25.6427 4.05766C25.6425 4.05759 25.6428 4.05773 25.6427 4.05766C25.3928 3.94652 25.1219 3.88889 24.8485 3.88889C24.575 3.88889 24.3046 3.94631 24.0548 4.05745C24.0546 4.05752 24.055 4.05738 24.0548 4.05745L10.8561 9.92456C10.5113 10.0781 10.2178 10.3284 10.0122 10.645C9.80655 10.9615 9.69706 11.3309 9.69697 11.7084V17.0445C9.69697 17.6024 10.1492 18.0546 10.7071 18.0546C11.2649 18.0546 11.7172 17.6024 11.7172 17.0445V11.7526L24.8485 5.91544ZM24.8759 33.5724C24.876 33.5724 24.8757 33.5723 24.8759 33.5724Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.8424 7.54302C32.6158 7.03325 32.0189 6.80367 31.5091 7.03025L17.927 13.0671C17.5822 13.2206 17.2887 13.471 17.083 13.7875C16.8774 14.1041 16.7679 14.4734 16.7678 14.8509V20.5234C16.7678 21.0813 17.2201 21.5335 17.7779 21.5335C18.3358 21.5335 18.788 21.0813 18.788 20.5234V14.8951L32.3296 8.87632C32.8394 8.64974 33.0689 8.0528 32.8424 7.54302Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.7649 16.33C5.37043 15.9355 4.73087 15.9355 4.3364 16.33L0.295999 20.3704C-0.0984706 20.7648 -0.0984706 21.4044 0.295999 21.7989L4.3364 25.8393C4.73087 26.2337 5.37043 26.2337 5.7649 25.8393C6.15937 25.4448 6.15937 24.8052 5.7649 24.4108L3.44885 22.0947H13.8385C14.3964 22.0947 14.8486 21.6425 14.8486 21.0846C14.8486 20.5267 14.3964 20.0745 13.8385 20.0745H3.44885L5.7649 17.7585C6.15937 17.364 6.15937 16.7244 5.7649 16.33Z"
        fill={color}
      />
    </svg>
  );
};

DeliveryIcon.displayName = 'DeliveryIcon';

export default DeliveryIcon;
