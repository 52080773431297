import React, { FC, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMedia } from 'react-use';

import {
  BUTTON_TYPES,
  CIVILITY,
  CURRENCIES,
  media,
  POSITIONS,
} from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Checkbox } from '@savgroup-front-common/core/src/atoms/checkbox';
import { Input } from '@savgroup-front-common/core/src/atoms/Form';
import { SwipeableDrawer } from '@savgroup-front-common/core/src/atoms/SwipeableDrawer';
import {
  DIRECTION,
  DRAWER_SIZE,
} from '@savgroup-front-common/core/src/atoms/SwipeableDrawer/SwipeableDrawer';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { SearchIcon } from '@savgroup-front-common/core/src/protons/icons';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types';
import { OwnerService } from 'myaccount/api';
import { useGetClientInformation } from 'myaccount/view/app/hooks';
import { useGetModelsBySearchModelQuery } from 'myaccount/view/app/hooks/useGetModelsBySearchModelQuery';
import { useLayout } from 'myaccount/view/app/NewLayout/NewLayout.hooks';

import {
  $AddProductWrapper,
  $CloseButton,
  $HelperContainerTitle,
  $HomePageAddProductAction,
  $HomePageAddProductContent,
  $ProductItem,
  $ProductItemDescription,
  $ProductItemPicture,
  $ProductItemTitle,
} from '../../HomePage.styles';
import messages from '../messages';

const NULL_AMOUNT = 0.01;
const MAX_RESULT = 50;

const AddProduct: FC<{
  isOpen: boolean;
  setOpen: (state: boolean) => void;
  onAddProduct: () => void;
}> = ({ isOpen, setOpen, onAddProduct }) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const { sellerId, login } = useLayout();
  const formContext = useForm<{
    chooseProduct: string;
    searchTerms: string;
  }>();
  const { control, watch, handleSubmit } = formContext;
  const [isChecked, setIsChecked] = useState<string | undefined>();
  const { clientInformation } = useGetClientInformation();
  const { removeAllNotifications, pushErrors } = useToasts();

  const searchTerms = watch('searchTerms');

  const { models } = useGetModelsBySearchModelQuery({
    maxResult: MAX_RESULT,
    searchTerms,
    sellerId,
  });

  const handleSubmitAddProduct = async ({
    chooseProduct,
  }: {
    chooseProduct: string;
  }) => {
    const payload = {
      civility: CIVILITY.NOT_SET,
      customerLastName: login.lastName,
      customerFirstName: login.firstName,
      address: login.ownerAddress,
      phoneNumber: login.phone,
      customerMail: login.mail || login.email,
      product: {
        id: chooseProduct,
        sku: models?.find((model) => model.id === chooseProduct)?.sku,
        price: {
          amount: NULL_AMOUNT,
          currency: CURRENCIES.EUR,
        },
      },
      isOutOfWarranty: false,
      sellerId,
      tenantId: clientInformation?.tenantId,
    };

    removeAllNotifications();
    const response = await OwnerService.createOrderByProductReferenceCommand(
      payload,
    );

    if (response.failure) {
      pushErrors(response.errors);

      return undefined;
    }

    onAddProduct();
    setOpen(false);

    return response;
  };

  return (
    <SwipeableDrawer
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      direction={isMobileView ? DIRECTION.TOP : DIRECTION.LEFT}
      size={DRAWER_SIZE.SMALL}
      hasBackdrop
      hasPadding={false}
      hasCloseButton={false}
    >
      <$AddProductWrapper>
        <$CloseButton
          onClick={() => setOpen(false)}
          naked
          type={BUTTON_TYPES.BUTTON}
        >
          <MyAccountIcon icon={ICONS_TYPE.CROSS_ICON} />
        </$CloseButton>
        <$HelperContainerTitle>
          <SafeFormattedMessageWithoutSpread message={messages.addProduct} />
        </$HelperContainerTitle>
        <FormProvider {...formContext}>
          <form onSubmit={handleSubmit(handleSubmitAddProduct)}>
            <Controller
              control={control}
              name="searchTerms"
              render={({ field }) => (
                <Input
                  name={field.name}
                  icon={<SearchIcon />}
                  iconPosition={POSITIONS.RIGHT}
                  placeholder={messages.searchProductPlaceholder}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  value={field.value}
                />
              )}
            />
            <$HomePageAddProductContent>
              {models?.map((model) => (
                <Controller
                  key={model.id}
                  control={control}
                  name="chooseProduct"
                  render={({ field }) => (
                    <$ProductItem $isChecked={isChecked === model.id}>
                      <Checkbox
                        checked={isChecked === model.id}
                        onChange={(event) => {
                          if (isChecked === model.id) {
                            setIsChecked(undefined);

                            return field.onChange(undefined);
                          }

                          setIsChecked(event.target.value);

                          return field.onChange(event.target.value);
                        }}
                        name={field.name}
                        value={model.id}
                      />
                      <$ProductItemPicture>
                        {!model?.pictureUrl && (
                          <MyAccountIcon icon={ICONS_TYPE.BOX_ICON} />
                        )}
                        {model?.pictureUrl && (
                          <img src={model?.pictureUrl} alt="pictureUrl" />
                        )}
                      </$ProductItemPicture>
                      <$ProductItemTitle>
                        {model.locales ? model.locales[0].label : model.name}
                      </$ProductItemTitle>
                      <$ProductItemDescription>
                        {model.modelTypeName}
                      </$ProductItemDescription>
                    </$ProductItem>
                  )}
                />
              ))}
            </$HomePageAddProductContent>
            <$HomePageAddProductAction $isVisible={isChecked !== undefined}>
              <Button primary type={BUTTON_TYPES.SUBMIT} fluid>
                <SafeFormattedMessageWithoutSpread
                  message={messages.addProductSubmit}
                />
              </Button>
            </$HomePageAddProductAction>
          </form>
        </FormProvider>
      </$AddProductWrapper>
    </SwipeableDrawer>
  );
};

AddProduct.displayName = 'AddProduct';
export default AddProduct;
