import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { InformationPlainIcon } from '@savgroup-front-common/core/src/protons/icons';
import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';

import messages from './messages';

const TransportDeductionContainer = styled('div')`
  border-radius: 0.25rem;
  background-color: #c7d6fc;
  padding: 1.125rem;
  color: #205df3;
  margin-bottom: 2rem;
  display: flex;
`;

const MarginIconInfo = styled(InformationPlainIcon)`
  width: 75px;
  ${mediaQuery.sm`
    width: 50px;
  `}
  ${mediaQuery.md`
    width: 30px;
  `}
`;

const TransportDeduction = () => (
  <>
    <TransportDeductionContainer>
      <MarginIconInfo color="#205DF3" />
      <FormattedMessage {...messages.deductedFee} />
    </TransportDeductionContainer>
  </>
);

export default TransportDeduction;
