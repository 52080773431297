import React, { FC, PropsWithChildren, ReactElement, useState } from 'react';

import { AccordionProps } from '../Accordion';
import { ACCORDION_ROLE } from '../Accordion.types';
import AccordionContent from '../Content';
import AccordionHeader from '../Header';

import { $Accordion } from './AccordionWithLabel.styles';

interface AccordionWithLabelProps extends Omit<AccordionProps, 'title'> {
  label: ReactElement;
  title: ReactElement;
}

const AccordionWithLabel: FC<PropsWithChildren<AccordionWithLabelProps>> = ({
  label,
  title = '',
  isOpen = undefined,
  initialIsOpen = false,
  isDisabled = false,
  isErrored = false,
  onClick = undefined,
  children = undefined,
  headerRightChildren = undefined,
  naked = false,
  unmountOnExit = true,
  dataTestId = undefined,
  role = ACCORDION_ROLE.PRIMARY,
}) => {
  const [internalIsOpen, setInternalIsOpen] = useState(initialIsOpen);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }

    if (isOpen === undefined) {
      setInternalIsOpen(!internalIsOpen);
    }
  };

  return (
    <$Accordion $naked={naked} data-testid={dataTestId}>
      <AccordionHeader
        isOpen={isOpen !== undefined ? isOpen : internalIsOpen}
        isDisabled={isDisabled}
        isErrored={isErrored}
        onClick={handleClick}
        headerRightChildren={headerRightChildren}
        label={label}
        role={role}
      >
        {title}
      </AccordionHeader>

      <AccordionContent
        naked={naked}
        unmountOnExit={unmountOnExit}
        isOpen={isOpen !== undefined ? isOpen : internalIsOpen}
      >
        {children}
      </AccordionContent>
    </$Accordion>
  );
};

AccordionWithLabel.displayName = 'AccordionWithLabel';

export default AccordionWithLabel;
