export const ERROR_FIELDS_MAP = {
  contactLastName: 'lastname',
  contactFirstName: 'firstname',
  phone: 'phone',
  street1: 'address',
  zipCode: 'postalcode',
  city: 'city',
  countryCode: 'countrycode',
  address: 'address',
};
