import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { CommonWorkflowService } from '../../../../api';
import { useToasts } from '../../../../molecules/NotificationsProvider';

export const useQrCodeImportModal = ({
  additionalInformationId,
  claimId,
}: {
  additionalInformationId: string;
  claimId: string;
}) => {
  const { pushErrors } = useToasts();
  const { fileId } = useParams<{ fileId?: string }>();

  const { data: uploadQrCode, isLoading } = useQuery(
    [
      'getFileAttachmentUploadLinkFromControlCommand',
      { additionalInformationId },
    ],
    async () => {
      const response =
        await CommonWorkflowService.getFileAttachmentUploadLinkFromControlCommand(
          {
            fileId,
            additionalInformationId,
            claimId,
          },
        );

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value.uploadQrCode;
    },
    {},
  );

  return {
    uploadQrCode,
    isLoading,
  };
};
