import get from 'lodash/get';
import startsWith from 'lodash/startsWith';

import { APIConfiguration } from '@savgroup-front-common/configuration';

export const adaptAttachments = ({ uploadedAttachments }) => {
  return uploadedAttachments.map(({ documents, productId }) => {
    return {
      documents: documents.map((document) => {
        const url = get(document, 'url');
        const isUrlReady =
          startsWith(url, 'https://') || startsWith(url, '//localhost/');
        const formattedUrl = isUrlReady
          ? url
          : `${APIConfiguration.base}${url}`;

        const blob = document.value;

        blob.name = get(document, 'originalFileName') || get(document, 'name');
        blob.id = get(document, 'id');
        blob.progress = get(document, 'progress') || 100;

        return {
          progress: get(document, 'progress') || 100,
          name: get(document, 'originalFileName') || get(document, 'name'),
          hasErrors: get(document, 'hasErrors') || false,
          id: get(document, 'id'),
          internalId: get(document, 'internalId', get(document, 'id')),
          url,
          formattedUrl,
          value: blob,
        };
      }),
      productId,
    };
  });
};
