import React from 'react';

interface DeliveryIconProps {
  color?: string;
}

/* eslint-disable max-len */
const DeliveryBoxIcon: React.FC<DeliveryIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33325 6.66666H29.9999C30.884 6.66666 31.7318 7.01785 32.3569 7.64297C32.9821 8.2681 33.3333 9.11594 33.3333 10V30C33.3333 30.8841 32.9821 31.7319 32.3569 32.357C31.7318 32.9821 30.884 33.3333 29.9999 33.3333H9.99992C9.11586 33.3333 8.26802 32.9821 7.6429 32.357C7.01777 31.7319 6.66659 30.8841 6.66659 30V20M19.9999 15V6.66666M13.3333 13.3333H4.99992"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

DeliveryBoxIcon.displayName = 'DeliveryBoxIcon';

export default DeliveryBoxIcon;
