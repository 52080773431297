import { fromJS } from 'immutable';
import capitalize from 'lodash/capitalize';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import includes from 'lodash/includes';
import invoke from 'lodash/invoke';
import keys from 'lodash/keys';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import size from 'lodash/size';
import some from 'lodash/some';
import uniqBy from 'lodash/uniqBy';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';

import { HANDLING_TYPES } from '@savgroup-front-common/constants';
import {
  findTranslatedTransportMethod,
  regroupProducts,
} from '@savgroup-front-common/core/src/helpers';
import { Selectors as CarriersSelectors } from '@savgroup-front-common/core/src/domains/carriers';
import { Selectors as ClaimSelectors } from '@savgroup-front-common/core/src/domains/claims';
import { pathname } from '@savgroup-front-common/core/src/domains/selectors';

import { ROUTES } from '../../view/app/routes/Routes';
import { Selectors as OwnerSelectors } from '../owner';
import { Selectors as PaymentsSelectors } from '../payments';
import {
  ordersIsLoadedSelector,
  ordersSelector,
} from '../Views/groupedProduct';

export const currentClaimGroupIdSelector = createSelector(
  [pathname],
  (path) => {
    const options = matchPath(path, {
      path: ROUTES.CLAIM_GROUP_DETAILS,
      exact: false,
      strict: false,
    });
    const claimGroupId = get(options, 'params.claimGroupId');

    return claimGroupId;
  },
);

export const currentClaimGroupHandlingModeSelector = createSelector(
  [pathname],
  (path) => {
    const options = matchPath(path, {
      path: ROUTES.CLAIM_GROUP_HANDLING,
      exact: true,
      strict: false,
    });
    const handlingMode = get(options, 'params.handlingMode') || '';

    return capitalize(handlingMode);
  },
);

export const CLAIM_GROUP_STATES = {
  CLAIM_GROUP_ISSUES: 'CLAIM_GROUP_ISSUES',
  CLAIM_GROUP_CONFIRMATION: 'CLAIM_GROUP_CONFIRMATION',
  CLAIM_GROUP_HANDLING_DEPOSIT: 'CLAIM_GROUP_HANDLING_DEPOSIT',
  CLAIM_GROUP_HANDLING_DELIVERY: 'CLAIM_GROUP_HANDLING_DELIVERY',
};

export const currentClaimGroupEditionStateSelector = createSelector(
  [pathname],
  (path) => {
    if (
      matchPath(path, {
        path: ROUTES.CLAIM_GROUP_ISSUES,
        exact: true,
        strict: false,
      })
    ) {
      return CLAIM_GROUP_STATES.CLAIM_GROUP_ISSUES;
    }
    if (
      matchPath(path, {
        path: ROUTES.CLAIM_GROUP_CONFIRMATION,
        exact: true,
        strict: false,
      })
    ) {
      return CLAIM_GROUP_STATES.CLAIM_GROUP_CONFIRMATION;
    }

    const handlingOptions = matchPath(path, {
      path: ROUTES.CLAIM_GROUP_HANDLING,
      exact: true,
      strict: false,
    });

    if (handlingOptions) {
      const handlingMode = get(handlingOptions, 'params.handlingMode') || '';

      return CLAIM_GROUP_STATES[
        `CLAIM_GROUP_HANDLING_${handlingMode.toUpperCase()}`
      ];
    }

    return undefined;
  },
);

export const currentClaimGroup = createSelector(
  [currentClaimGroupIdSelector, ClaimSelectors.claimGroups],
  (claimGroupId, claimGroups) =>
    claimGroups.getIn([claimGroupId, 'value']) || [],
);

export const currentClaims = createSelector(
  [currentClaimGroup, ClaimSelectors.claims],
  (claimGroup, claims) =>
    fromPairs(
      claimGroup.map((claimId) => [claimId, claims.getIn([claimId, 'value'])]),
    ),
);

export const currentClaimsAreLoaded = createSelector(
  [currentClaimGroup, ClaimSelectors.claims],
  (claimGroup, claims) =>
    claimGroup.every(
      (claimId) =>
        claims.getIn([claimId, 'isLoaded'], false) &&
        claims.getIn([claimId, 'value'], null),
    ),
);

export const currentSelectedIssueId = createSelector(
  [currentClaimGroup, currentClaims],
  (claimGroup, claims) => get(claims, [head(claimGroup), 'issueId']),
);

export const currentIssues = createSelector(
  [currentClaimGroup, ClaimSelectors.issues],
  (claimGroup, issues) =>
    fromPairs(claimGroup.map((claimId) => [claimId, issues.getIn([claimId])])),
);
export const getCurrentIssue = createSelector(
  [currentIssues, currentSelectedIssueId, (_, { claimId }) => claimId],
  (issues, issueId, claimId) => {
    const allIssues = issues[claimId]?.get('value');
    const currentIssue = allIssues?.find((issue) => issue.id === issueId);

    return currentIssue;
  },
);

export const currentReasons = createSelector(
  [currentClaimGroup, ClaimSelectors.reasons],
  (claimGroup, reasons) =>
    fromPairs(claimGroup.map((claimId) => [claimId, reasons.getIn([claimId])])),
);

export const allCurrentReasonLoaded = createSelector(
  [currentReasons],
  (currentReasonsState) =>
    Object.values(currentReasonsState).every((state) => {
      if (!state) {
        return false;
      }

      return state.get('isLoaded', false);
    }),
);

export const currentSolutions = createSelector(
  [currentClaimGroup, ClaimSelectors.solutions],
  (claimGroup, solutions) =>
    fromPairs(
      claimGroup.map((claimId) => [claimId, solutions.getIn([claimId])]),
    ),
);

export const currentProducts = createSelector(
  [currentClaimGroup, ClaimSelectors.products],
  (claimGroup, products) =>
    fromPairs(
      claimGroup.map((claimId) => [
        claimId,
        products.getIn([claimId, 'value']),
      ]),
    ),
);

export const currentComments = createSelector(
  [currentClaimGroup, ClaimSelectors.comments],
  (claimGroup, comments) =>
    fromPairs(claimGroup.map((claimId) => [claimId, comments.get(claimId)])),
);

export const currentClaimUploads = createSelector(
  [currentClaimGroup, ClaimSelectors.claimUploads],
  (claimGroup, claimUploads) =>
    fromPairs(
      claimGroup.map((claimId) => [claimId, claimUploads.get(claimId, {})]),
    ),
);

export const currentClaimGroupStateSelector = createSelector(
  [currentClaims],
  (claims) => {
    const includesStates = (...states) =>
      some(claims, (claim) => includes(states, get(claim, 'state')));

    if (includesStates('Created', 'WaitingForReason', 'WaitingForSolution')) {
      return CLAIM_GROUP_STATES.CLAIM_GROUP_ISSUES;
    }
    if (includesStates('WaitingForHandlingDepositInfo')) {
      return CLAIM_GROUP_STATES.CLAIM_GROUP_HANDLING_DEPOSIT;
    }
    if (includesStates('WaitingForHandlingDeliveryInfo')) {
      return CLAIM_GROUP_STATES.CLAIM_GROUP_HANDLING_DELIVERY;
    }

    return CLAIM_GROUP_STATES.CLAIM_GROUP_CONFIRMATION;
  },
);

export const currentGroupHandlingInfo = createSelector(
  [currentClaimGroupIdSelector, ClaimSelectors.groupHandling],
  (claimGroupId, groupHandling) => groupHandling.get(claimGroupId),
);

export const selectGroupHandlingInfo = createSelector(
  [currentGroupHandlingInfo],
  (groupHandlingInfo) =>
    groupHandlingInfo ? groupHandlingInfo.toJS()?.value : undefined,
);

export const currentGroupHandlingMethods = createSelector(
  [
    currentClaimGroupIdSelector,
    ClaimSelectors.groupCarriers,
    currentClaimGroupHandlingModeSelector,
  ],
  (claimGroupId, groupCarriers, handlingMode) => {
    return groupCarriers.getIn([claimGroupId, handlingMode]);
  },
);

export const carrierSummary = createSelector(
  [currentClaimGroupIdSelector, ClaimSelectors.groupCarrier],
  (claimGroupId, groupCarrier) => groupCarrier.get(claimGroupId),
);

export const currentPickupPoints = createSelector(
  [
    currentClaimGroupIdSelector,
    currentClaimGroupHandlingModeSelector,
    ClaimSelectors.groupCarriers,
    ClaimSelectors.pickupPoints,
  ],
  (claimGroupId, handlingMode, groupCarriers, pickupPoints) => {
    const groupCarriersValue =
      groupCarriers.getIn([claimGroupId, handlingMode, 'value']) || {};

    const formattedPickupPoints = Object.entries(groupCarriersValue)
      .filter(([type]) => type !== 'Home')
      .reduce((acc, [, carriers]) => [...acc, ...carriers], [])
      .reduce((acc, carrier) => {
        const pickupPointList =
          pickupPoints.getIn([
            carrier.carrierBrand,
            carrier.productType,
            'value',
          ]) || [];
        const hasPickupPointErrors = pickupPoints.getIn([
          carrier.carrierBrand,
          carrier.productType,
          'hasErrors',
        ]);

        if (
          pickupPointList.length === 0 &&
          carrier.productType !== HANDLING_TYPES.SELLER_PROVIDED_CARRIER &&
          carrier.productType !== HANDLING_TYPES.HOME_DELIVERY &&
          hasPickupPointErrors
        ) {
          return [...acc, { ...carrier }];
        }

        return [
          ...acc,
          ...pickupPointList.map((pickup) => ({ ...pickup, ...carrier })),
        ];
      }, []);

    return {
      pickupPoints: mapValues(
        groupBy(formattedPickupPoints, 'productType'),
        (group) =>
          mapValues(groupBy(group, 'carrierBrand'), (points) => ({
            isLoaded: size(points) > 0,
            points,
          })),
      ),
      numberOfPickupPointsLoaded: uniqBy(
        formattedPickupPoints,
        'carrierProductId',
      ).length,
    };
  },
);

export const currentClaimGroupConfirmation = createSelector(
  [currentClaimGroupIdSelector, ClaimSelectors.claimGroupsConfirmation],
  (claimGroupId, claimGroupsConfirmation) =>
    claimGroupsConfirmation.get(claimGroupId),
);

export const translatedCarrirerMethodNames = createSelector(
  [
    currentClaimGroupConfirmation,
    CarriersSelectors.carriersProducts,
    CarriersSelectors.transportMethods,
  ],
  (confirmation, carriersProducts, transportMethods) => {
    const depositName = get(
      confirmation && confirmation.get('value'),
      'carrierDepositName',
    );
    const deliveryName = get(
      confirmation && confirmation.get('value'),
      'carrierDeliveryName',
    );

    if ((depositName || deliveryName) && carriersProducts && transportMethods) {
      return fromJS({
        deposit: findTranslatedTransportMethod(
          depositName,
          carriersProducts,
          transportMethods,
        ),
        delivery: findTranslatedTransportMethod(
          deliveryName,
          carriersProducts,
          transportMethods,
        ),
      });
    }

    return fromJS({});
  },
);

export const isHomePickupSelector = createSelector(
  [
    currentGroupHandlingMethods,
    CarriersSelectors.carriersProducts,
    CarriersSelectors.transportMethods,
  ],
  (handlingMethods, carriersProducts, transportMethods) => {
    const carriers = handlingMethods ? handlingMethods.get('value') : null;

    return some(
      get(carriers, ['Deposit_HomeDelivery']),
      (potentialHomePickup) => {
        const carrier = find(carriersProducts, {
          id: get(potentialHomePickup, 'carrierProductId'),
        });
        const transportMethod = find(transportMethods, {
          id: get(carrier, 'transportMethodId'),
        });
        const isHomePickup =
          get(transportMethod, 'uniqueName') === 'HomePickup';

        return isHomePickup;
      },
    );
  },
);

export const fileProductsLoadedSelector = createSelector(
  [OwnerSelectors.fullProducts, currentProducts, ordersIsLoadedSelector],
  (productsFullData, products, ordersIsLoaded) =>
    productsFullData.getIn([
      get(products, [head(keys(products)), 'productId']),
      'isLoaded',
    ]) && ordersIsLoaded,
);

export const fileProductsSelector = createSelector(
  [
    currentProducts,
    ClaimSelectors.reasons,
    ClaimSelectors.solutions,
    ClaimSelectors.sellerInfo,
    currentClaims,
    currentClaimUploads,
    OwnerSelectors.fullProducts,
    currentComments,
    ordersSelector,
    currentClaimGroupIdSelector,
  ],
  (
    products,
    reasons,
    solutions,
    sellerInfo,
    claims,
    claimUploads,
    productsFullData,
    comments,
    orders,
    currentClaimGroupId,
  ) => {
    const orderId = get(
      productsFullData.getIn([
        get(products, [head(keys(products)), 'productId']),
        'value',
      ]),
      'orderId',
    );
    const concernedOrder = find(orders, { orderId });
    const orderProducts = concernedOrder?.products.filter(
      (product) => !product.notOpenForClaims,
    );

    const claimProducts = filter(orderProducts, {
      claimGroupId: currentClaimGroupId,
    });
    const regroupedClaimProducts = regroupProducts(claimProducts);
    const regroupedProducts = regroupProducts(orderProducts);

    return regroupedClaimProducts.map((product) => {
      const claimId = get(product, ['claimContextId']);
      const productIds = get(product, ['ownerProductId']);
      const productId = head(productIds);
      const sellerId = get(productsFullData.toJS(), [
        productId,
        'value',
        'sellerId',
      ]);
      const sellerProductId = get(productsFullData.toJS(), [
        productId,
        'value',
        'sellerProductId',
      ]);
      const purchaseDate = get(productsFullData.toJS(), [
        productId,
        'value',
        'buyDate',
      ]);
      const claimIds = map(productIds, (id) =>
        get(find(orderProducts, { ownerProductId: id }), 'claimContextId'),
      );

      return {
        orderId,
        claimId,
        productId,
        productIds,
        claimIds,
        sellerProductId,
        claimGroupId: currentClaimGroupId,
        reasons: reasons.getIn([claimId, 'value']),
        reasonsLoaded: reasons.getIn([claimId, 'isLoaded']),
        solutions: map(solutions.getIn([claimId, 'value']), (solution) => ({
          id: get(solution, 'id'),
          title: get(solution, 'name'),
          price: get(solution, 'priceWithTax'),
          priceWithoutTax: get(solution, 'price'),
          currency: get(solution, 'priceCurrencyCode'),
          solutionGroupId: get(solution, 'solutionGroupId'),
          key: get(solution, 'key'),
          didactic: get(solution, 'didactic'),
        })),
        solutionsLoaded: solutions.getIn([claimId, 'isLoaded']),
        outOfWarranty:
          solutions.getIn([claimId, 'selectedWarrantyId']) ===
          '00000000-0000-0000-0000-000000000012',
        fileUploads: get(claimUploads, claimId),
        currents: {
          currentReason: get(claims, [claimId, 'reasonId']),
          currentSolution: get(claims, [claimId, 'solutionTypeId']),
          currentComment: get(invoke(comments, [claimId, 'toJS']), 'value'),
        },
        orderDataLoaded: productsFullData.getIn([productId, 'isLoaded']),
        productInfo: {
          modelTypeId: get(product, 'modelTypeId'),
          modelId: get(product, 'modelId'),
          modelName: get(product, 'modelName'),
          brandName: get(product, 'brandName'),
          orderNumber: get(
            productsFullData.getIn([productId, 'value']),
            'orderReference',
          ),
          endOfWarranty: get(
            productsFullData.getIn([productId, 'value']),
            'warrantyEndDateUtc',
          ),
          state: get(sellerInfo.toJS(), [
            sellerId,
            sellerProductId,
            'value',
            'state',
          ]),
          quantity: get(product, 'quantity'),
          maxQuantity: size(
            get(
              find(regroupedProducts, {
                sellerProductId: get(product, 'sellerProductId'),
              }),
              'ownerProductId',
            ),
          ),
          purchaseDate,
        },
      };
    });
  },
);

export const currentInvoice = createSelector(
  [currentClaimGroupIdSelector, PaymentsSelectors.openedInvoices],
  (claimGroupId, openedInvoices) => openedInvoices.getIn([claimGroupId]),
);

export const currentConfirmationLoadingStatus = createSelector(
  [currentClaimGroupIdSelector, ClaimSelectors.claimConfirmationStatus],
  (claimGroupId, claimConfirmationStatus) => {
    const status = claimConfirmationStatus.get(claimGroupId);
    const isLoading = status && !status.get('isLoaded');

    return isLoading;
  },
);

export const currentConfirmationErrors = createSelector(
  [currentClaimGroupIdSelector, ClaimSelectors.claimConfirmationStatus],
  (claimGroupId, claimConfirmationStatus) => {
    const status = claimConfirmationStatus.get(claimGroupId);

    return status ? status.get('errors') : [];
  },
);

const currentOrderSelector = createSelector(
  [ordersSelector, currentClaimGroupIdSelector],
  (orders, claimGroupId) =>
    find(
      orders,
      (order) => get(order, ['products', 0, 'claimGroupId']) === claimGroupId,
    ),
);

export const sourceTypeSelector = createSelector(
  [currentOrderSelector],
  (order) => invoke(get(order, ['orderSourceType']), 'toLowerCase'),
);

const ordersProductsSelector = createSelector([ordersSelector], (orders) =>
  flatMap(get(orders, 'items'), (order) => get(order, 'products')),
);

export const startedClaims = createSelector(
  [ordersProductsSelector],
  (orders) =>
    filter(
      orders,
      (order) => !!get(order, 'claimContextId') || !!get(order, 'claimGroupId'),
    ),
);
