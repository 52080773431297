import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.createClaim.title',
    defaultMessage: 'Having a problem?',
  },
  message: {
    id: 'components.createClaim.message',
    defaultMessage:
      'Create a claim for one of the product below, click on the button and begin the process →',
  },
  action: {
    id: 'components.createClaim.action',
    defaultMessage: 'Start a claim',
  },
});
