import React, { FunctionComponent } from 'react';

import { HANDLING_GROUPS } from '@savgroup-front-common/constants';
import {
  HomeOutlineIcon,
  LocationIcon,
  ShopIcon,
} from '@savgroup-front-common/core/src/protons/icons';

interface HandlingIconProps {
  pickupMode: HANDLING_GROUPS;
}
const HandlingIcon: FunctionComponent<
  React.PropsWithChildren<HandlingIconProps>
> = ({ pickupMode }) => {
  switch (pickupMode) {
    case HANDLING_GROUPS.HOME:
    case HANDLING_GROUPS.HOME_INTERVENTION:
      return <HomeOutlineIcon />;
    case HANDLING_GROUPS.PICKUP_STORE:
    case HANDLING_GROUPS.PICKUP_STORE_DELIVERY:
      return <ShopIcon />;

    default:
      return <LocationIcon />;
  }
};

HandlingIcon.displayName = 'HandlingIcon';

export default HandlingIcon;
