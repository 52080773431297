import { createAction } from '@reduxjs/toolkit';

import { ReverseMoney } from '@savgroup-front-common/types';

import * as actionTypes from './actionTypes';

export const getSellerConfiguration = createAction<string>(
  actionTypes.GET_SELLER_CONFIGURATION.BASE,
);

export const getSellersConfiguration = createAction<{ sellerIds: string[] }>(
  actionTypes.GET_SELLERS_CONFIGURATION.BASE,
);

interface SetSellerConfigurationArgs {
  sellerId: string;
  timestamp: string;

  acceptedLanguages?: string[];
  accountManagerConfiguration?: {
    accountStatus: string;
    customerService: boolean;
  };
  automatedMessagesEnabled?: boolean;
  carriersOptIn?: { ups: boolean; chronopost: boolean; colissimo: boolean };
  cgvUrl?: string;
  deductTransportFromRefund?: boolean;
  defaultOwnerOrderUrl?: string;
  insuranceThreshold?: ReverseMoney;
  isClaimValidationNeeded?: boolean;
  lowValueThreshold?: ReverseMoney;
  ownerNotificationSenderMail?: string;
  faqUrl?: string;
  isCashRegisterConnected?: boolean;
  paymentUrl?: string;
}

export const setSellerConfiguration = createAction<SetSellerConfigurationArgs>(
  actionTypes.SET_SELLER_CONFIGURATION.BASE,
);
export const setSellerConfigurationOther = createAction<{
  sellerId: string;
  timestamp: string;
}>(actionTypes.SET_SELLER_CONFIGURATION_OTHER.BASE);
export const setSellerConfigurationTransport = createAction<{
  sellerId: string;
  timestamp: string;
}>(actionTypes.SET_SELLER_CONFIGURATION_TRANSPORT.BASE);
export const setSellerConfigurationAccountManager = createAction<{
  sellerId: string;
  timestamp: string;
}>(actionTypes.SET_SELLER_CONFIGURATION_ACCOUNT_MANAGER.BASE);
export const setSellerConfigurationAcceptedLanguages = createAction<{
  sellerId: string;
  timestamp: string;
  acceptedLanguages: string[];
}>(actionTypes.SET_SELLER_CONFIGURATION_ACCEPTED_LANGUAGES.BASE);
