import styled from 'styled-components';

import { rem } from '../../helpers';

interface $AccordionContainerProps {
  $naked: boolean;
  $isList?: boolean;
}

export const $AccordionContainer = styled.div<$AccordionContainerProps>`
  margin-bottom: ${({ $isList }) => ($isList ? 'unset' : '1.25rem')};
  background-color: ${({ theme, $naked }) =>
    $naked ? null : theme.colors.neutralBackground};
  border-radius: ${rem(5)};
`;
