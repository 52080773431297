import React, { FC } from 'react';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { useGetQuotationsByFileIdQuery } from '@savgroup-front-common/core/src/components/Quotation/hooks/useGetQuotationsByFileIdQuery';
import { RawQuotationState } from '@savgroup-front-common/types';

import { CustomerFile } from '../../../../../types';

import AnswerQuotationContent from './AnswerQuotationContent';

interface AnswerQuotationProps {
  file: CustomerFile;
  isLoading: boolean;
}

const AnswerQuotation: FC<AnswerQuotationProps> = ({ file, isLoading }) => {
  const { quotations } = useGetQuotationsByFileIdQuery({
    fileId: file.fileId,
  });

  const currentQuotation = (quotations || []).find(
    (quotation) =>
      quotation.state === RawQuotationState.PENDING_CUSTOMER_APPROVAL,
  );

  if (!currentQuotation || isLoading) {
    return <BaseLoader />;
  }

  return (
    <AnswerQuotationContent
      quotation={currentQuotation}
      sellerId={file.sellerId}
      fileId={file.fileId}
      isOpen
    />
  );
};

AnswerQuotation.displayName = 'AnswerQuotation';

export default AnswerQuotation;
