import head from 'lodash/head';
import map from 'lodash/map';
import size from 'lodash/size';

import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';

export const CHOOSE_PRODUCTS_PAGE_ACTION_TYPES = {
  CHANGE_PRODUCT_TO_RETURN: 'CHANGE_PRODUCT_TO_RETURN',
  UNSELECT_ALL: 'UNSELECT_ALL',
  SELECT_ALL: 'SELECT_ALL',
};

export function init({ products, claimProducts }) {
  const eligibleProducts = products;

  if (eligibleProducts.length === 1) {
    const { ownerProductId } = head(eligibleProducts);

    if (size(ownerProductId) === 1) {
      return {
        selectedProducts: [ownerProductId],
      };
    }
  }

  return {
    selectedProducts: eligibleProducts.reduce((acc, { ownerProductId }) => {
      if (claimProducts.includes(ownerProductId)) {
        return [...acc, ownerProductId];
      }

      return acc;
    }, []),
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CHOOSE_PRODUCTS_PAGE_ACTION_TYPES.CHANGE_PRODUCT_TO_RETURN: {
      const { ownerProductId } = action.payload;
      const { selectedProducts } = state;
      const isProductAlreadySelected = selectedProducts.some(
        (selectedOwnerProductId) => selectedOwnerProductId === ownerProductId,
      );

      if (isProductAlreadySelected) {
        return {
          ...state,
          selectedProducts: selectedProducts.filter(
            (selectedOwnerProductId) =>
              selectedOwnerProductId !== ownerProductId,
          ),
        };
      }

      return {
        ...state,
        selectedProducts: selectedProducts.concat(ownerProductId),
      };
    }
    case CHOOSE_PRODUCTS_PAGE_ACTION_TYPES.UNSELECT_ALL: {
      return {
        ...state,
        selectedProducts: [],
      };
    }
    case CHOOSE_PRODUCTS_PAGE_ACTION_TYPES.SELECT_ALL: {
      const { products } = action.payload;

      return {
        ...state,
        selectedProducts: map(products, ({ ownerProductId }) => ownerProductId),
      };
    }
    default:
      throw new ActionTypeIsNotSupportedError(action.type);
  }
}
