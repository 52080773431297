import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import * as ActionTypes from './actionTypes';
import { invoicesKind } from './constants';
import {
  CreateInvoiceForClaimGroupPayload,
  GetOpenedInvoicesPayload,
} from './types';

interface GetInvoiceKindFromIdsArgs {
  claimGroupId: string;
  claimId: string;
}

const getInvoiceKindFromIds = ({
  claimGroupId,
  claimId,
}: GetInvoiceKindFromIdsArgs) =>
  // eslint-disable-next-line no-nested-ternary
  claimGroupId
    ? invoicesKind.CLAIM_GROUP
    : claimId
    ? invoicesKind.CLAIM
    : invoicesKind.FILE;

export function* getOpenedInvoicesWorker({
  payload: { claimGroupId, claimId, fileId },
}: {
  payload: GetOpenedInvoicesPayload;
}): Generator {
  const id = claimGroupId || claimId || fileId;
  const kind = getInvoiceKindFromIds({ claimGroupId, claimId });
  const meta = { id };

  yield call(
    callAndGetResponse,
    ActionTypes.GET_OPENED_INVOICES,
    `${APIConfiguration.payment}invoices/${kind}/${id}/opened`,
    { method: SUPPORTED_METHODS.GET },
    meta,
  );
  yield put(ActionTypes.GET_OPENED_INVOICES.end(meta));
}

function* getOpenedInvoicesWatcher() {
  yield takeEvery<Action<GetOpenedInvoicesPayload>>(
    ActionTypes.GET_OPENED_INVOICES.BASE,
    getOpenedInvoicesWorker,
  );
}

export function* createInvoiceForClaimGroupWorker({
  payload: { claimGroupId },
}: {
  payload: CreateInvoiceForClaimGroupPayload;
}): Generator {
  const meta = { claimGroupId, indexer: claimGroupId };

  yield call(
    callAndGetResponse,
    ActionTypes.CREATE_INVOICE_FOR_CLAIM_GROUP,
    `${APIConfiguration.claim}claimsGroups/${claimGroupId}/invoices`,
    { method: SUPPORTED_METHODS.PUT },
    meta,
  );
  yield put(ActionTypes.CREATE_INVOICE_FOR_CLAIM_GROUP.end(meta));
}
function* createInvoiceForClaimGroupWatcher() {
  yield takeEvery<Action<{ claimGroupId: string }>>(
    ActionTypes.CREATE_INVOICE_FOR_CLAIM_GROUP.BASE,
    createInvoiceForClaimGroupWorker,
  );
}

export default function* quoteSaga() {
  try {
    yield all([
      getOpenedInvoicesWatcher(),
      createInvoiceForClaimGroupWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
