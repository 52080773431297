import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ActionCreators as ClaimActionCreators } from '@savgroup-front-common/core/src/domains/claims';
import {
  goForwardOnClaimGroupEdition,
  selectQuantityReason,
} from 'myaccount/domains/Claim/ClaimEdition/actionCreators';

const useReasonSolutionSelectionStoreActions = () => {
  const dispatch = useDispatch();

  const dispatchGoForwardOnClaimGroupEdition = useCallback(
    (payload) => {
      dispatch(goForwardOnClaimGroupEdition(payload));
    },
    [dispatch],
  );

  const dispatchSelectQuantityReason = useCallback(
    (payload) => {
      dispatch(selectQuantityReason(payload));
    },
    [dispatch],
  );

  const dispatchSetReasonCommentForClaimGroup = useCallback(
    (payload) => {
      dispatch(ClaimActionCreators.setReasonCommentForClaimGroup(payload));
    },
    [dispatch],
  );

  return {
    dispatchGoForwardOnClaimGroupEdition,
    dispatchSelectQuantityReason,
    dispatchSetReasonCommentForClaimGroup,
  };
};

export default useReasonSolutionSelectionStoreActions;
