import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { H3 } from '@savgroup-front-common/core/src/atoms/headings';

export const StyledRow = styled(Row)`
  margin-bottom: 2rem;
`;
export const StyledImg = styled('img')`
  height: 54px;
`;
export const StyledTitle = styled(H3)`
  font-size: 20px;
`;
export const StyledCol = styled(Col)`
  flex-basis: auto !important;
`;
