import { rgba } from 'polished';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { rem, styledGetCustomComponentProperty } from '../../../helpers';
import { InputStatus } from '../common/helpers/getFinalFieldState.types';

import { getBorderColor, getOnHoverBorderColor } from './Input.helpers';
import { InputProps } from './Input.types';

interface StyledInputProps extends InputProps {
  $isRounded?: boolean;
  $isCentered?: boolean;
  $isFocusAndHoverBehavioursHandled?: boolean;
  $hasIcon?: boolean;
  $hasIconRight?: boolean;
  status: InputStatus;
  autoComplete?: string;
  $suffixButton?: ReactElement;
  $shouldFitContent?: boolean;
  autofocus?: boolean;
  $isSmall?: boolean;
}

export const $StyledInput = styled.input<StyledInputProps>`
  && {
    background-color: ${({ theme, disabled, readOnly }) =>
      disabled || readOnly ? theme.colors.disabled : null};
    width: ${({ $shouldFitContent }) =>
      $shouldFitContent ? 'fit-content' : '100%'};
    min-width: 20px;
    color: ${({ isError, theme }) =>
      isError ? theme.colors.danger : theme.colors.mainTextColor};
    border: ${rem(1)} solid;
    border-color: ${({ theme, status, disabled, readOnly }) =>
      getBorderColor({ theme, status, disabled, readOnly })};
    border-radius: ${({ theme, $isRounded }) => {
      return $isRounded
        ? theme.borders.radius
        : styledGetCustomComponentProperty({
            componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
            property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
            fallback: 0,
          });
    }};
    border-top-right-radius: ${({ $suffixButton }) =>
      $suffixButton ? 0 : null};
    border-bottom-right-radius: ${({ $suffixButton }) =>
      $suffixButton ? 0 : null};
    flex: auto;
    outline: none;
    transition-duration: ${({ theme }) => theme.interactions.basicTiming};
    height: ${({ $isSmall }) => {
      if ($isSmall) {
        return '24px';
      }

      return styledGetCustomComponentProperty({
        componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
        property: SUPPORTED_PROPERTIES.HEIGHT,
      });
    }};
    padding: ${styledGetCustomComponentProperty({
      componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
      property: SUPPORTED_PROPERTIES.PADDING,
    })};
    padding-left: ${({ $hasIcon, $hasIconRight }) =>
      $hasIcon && !$hasIconRight ? rem(35) : null};
    padding-right: ${({ isLoading, $hasIconRight }) =>
      rem((isLoading ? 35 : 0) + ($hasIconRight ? 35 : 0))};
    cursor: ${({ readOnly, disabled }) =>
      readOnly || disabled ? 'not-allowed' : null};
    text-align: ${({ $isCentered }) => ($isCentered ? 'center' : null)};
  }
  &:focus,
  &:hover {
    border: ${rem(1)} solid;
    border-color: ${({
      theme,
      status,
      disabled,
      readOnly,
      $isFocusAndHoverBehavioursHandled,
    }) =>
      getOnHoverBorderColor({
        theme,
        status,
        disabled,
        readOnly,
        $isFocusAndHoverBehavioursHandled,
      })};
    box-shadow: ${({ theme }) =>
      `0px 0px 4px ${rgba(theme.colors.primary, 0.4)}`};
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.default};
  }
`;

export const $InputContainer = styled('div')`
  position: relative;
  width: 100%;
`;

export const $CustomIcon = styled(({ icon, ...props }) =>
  React.cloneElement(icon, props),
)``;

export const $IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: ${rem(10)};
  transform: translateY(-50%);
  height: ${rem(20)};
`;

export const $IconContainerRight = styled.div`
  position: absolute;
  top: 50%;
  right: ${rem(10)};
  transform: translateY(-50%);
  height: ${rem(20)};
`;

export const $InputSuffix = styled.div<StyledInputProps>`
  display: flex;
  align-items: center;
  position: absolute;
  top: 1px;
  padding: 1px;
  right: 1px;
  background: ${({ theme }) => theme.colors.secondaryColor2};
  border-top-right-radius: ${({ theme }) => theme.borders.radius};
  border-bottom-right-radius: ${({ theme }) => theme.borders.radius};
  height: calc(100% - 2px);
  border-left: 1px solid
    ${({ theme, status, isDisabled, readOnly }) =>
      getBorderColor({ theme, status, disabled: isDisabled, readOnly })};

  & > span {
    color: ${({ theme }) => theme.colors.paragraphTextColor};
    font-size: ${({ theme }) => theme.fonts.size.medium};
    font-weight: ${({ theme }) => theme.fonts.weight.mediumBold};
    padding: 0 1.25rem;
  }
`;
