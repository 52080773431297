import React, { FunctionComponent } from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import messages from '../../messages';
import { ProductsInfoMinimal } from '../../ProductsInfo.types';

import {
  $CardContentDescription,
  $CardContentProductName,
  $CardContentQuantity,
  $CardContentSmallThumbnail,
  $CardContentSmallThumbnailWrapper,
  $CardContentThumbnail,
  $CardContentThumbnailWrapper,
} from './MultiProductInfoDescription.styles';

const MultiProductInfoDescription: FunctionComponent<{
  productsInfo?: ProductsInfoMinimal[];
}> = ({ productsInfo }) => {
  const hasPicture = productsInfo?.some(
    (productInfo) => productInfo.modelPictureUrl,
  );
  const pictures = hasPicture
    ? productsInfo
        ?.map((productInfo) => productInfo.modelPictureUrl)
        .slice(0, 4)
    : [];

  const sameProduct = !productsInfo?.some(
    (productInfo) => productsInfo[0].modelName !== productInfo.modelName,
  );

  return (
    <>
      {!sameProduct && (
        <>
          {hasPicture &&
            pictures?.map((picture) => (
              <$CardContentSmallThumbnailWrapper key={picture}>
                <$CardContentSmallThumbnail>
                  <>
                    {!picture && <MyAccountIcon icon={ICONS_TYPE.BOX_ICON} />}
                    {picture && <img src={picture} alt="pictureUrl" />}
                  </>
                </$CardContentSmallThumbnail>
              </$CardContentSmallThumbnailWrapper>
            ))}

          <$CardContentDescription>
            <$CardContentProductName>
              <SafeFormattedMessageWithoutSpread
                message={messages.multiproductLength}
                values={{ itemsLength: productsInfo?.length }}
              />
            </$CardContentProductName>
          </$CardContentDescription>
        </>
      )}
      {sameProduct && productsInfo && (
        <>
          {((!productsInfo[0]?.modelPictureUrl &&
            productsInfo[0]?.modelTypeId) ||
            productsInfo[0]?.modelPictureUrl) && (
            <$CardContentThumbnailWrapper>
              <$CardContentThumbnail>
                {!productsInfo[0]?.modelPictureUrl && (
                  <MyAccountIcon icon={ICONS_TYPE.BOX_ICON} />
                )}
                {productsInfo[0]?.modelPictureUrl && (
                  <img
                    src={productsInfo[0]?.modelPictureUrl}
                    alt="pictureUrl"
                  />
                )}
              </$CardContentThumbnail>
            </$CardContentThumbnailWrapper>
          )}
          <$CardContentDescription>
            <$CardContentProductName>
              {productsInfo[0]?.displayName}
            </$CardContentProductName>

            <$CardContentQuantity>
              <SafeFormattedMessageWithoutSpread
                message={messages.quantity}
                values={{
                  quantity: productsInfo?.length,
                }}
              />
            </$CardContentQuantity>
          </$CardContentDescription>
        </>
      )}
    </>
  );
};

MultiProductInfoDescription.displayName = 'MultiProductInfoDescription';
export default MultiProductInfoDescription;
