import { defineMessages } from 'react-intl';

export default defineMessages({
  contactUs: {
    id: 'components.common.pageHeader.contactUs',
    defaultMessage: 'Nous contacter',
  },
  title_rma: {
    id: 'components.common.pageHeader.title_rma',
    defaultMessage: 'Le constructeur de votre produit peut vous aider',
  },
  subtitle_rma: {
    id: 'components.common.pageHeader.subtitle_rma',
    defaultMessage: 'Votre produit est couvert par la garantie constructeur',
  },
  description_rma: {
    id: 'components.common.pageHeader.description_rma',
    defaultMessage:
      ' Veuillez prendre connaissance de la procédure à suivre pour la prise en charge de votre produit',
  },
  title_confirmation: {
    id: 'components.common.pageHeader.title_confirmation',
    defaultMessage: 'Récapitulatif',
  },
  subtitle_confirmation: {
    id: 'components.common.pageHeader.subtitle_confirmation',
    defaultMessage: 'Merci de vérifier les éléments de votre dossier ',
  },
  title_diagnostic_confirmation: {
    id: 'components.common.pageHeader.title_diagnostic_confirmation',
    defaultMessage: 'Dites nous en plus...',
  },
  subtitle_diagnostic_confirmation: {
    id: 'components.common.pageHeader.subtitle_diagnostic_confirmation',
    defaultMessage:
      'Merci de nous décrire le ou les problèmes que vous rencontrez sur votre appareil …',
  },
  title_diagnostic: {
    id: 'components.common.pageHeader.title_diagnostic',
    defaultMessage: 'Diagnostiquez votre problème',
  },
  subtitle_diagnostic: {
    id: 'components.common.pageHeader.subtitle_diagnostic',
    defaultMessage:
      'Utilisez notre outil de diagnostic pour trouver rapidement une solution',
  },
  title_diagnostic_symptom_choice: {
    id: 'components.common.pageHeader.title_diagnostic_symptom_choice',
    defaultMessage: 'Dites nous en plus...',
  },
  subtitle_diagnostic_symptom_choice: {
    id: 'components.common.pageHeader.subtitle_diagnostic_symptom_choice',
    defaultMessage:
      'Merci de sélectionner le problème que vous rencontrez sur votre appareil ...',
  },
  title_handling_deposit: {
    id: 'components.common.pageHeader.title_handling_deposit',
    defaultMessage: "Votre prise en charge pour l'envoi",
  },
  subtitle_handling_deposit: {
    id: 'components.common.pageHeader.subtitle_handling_deposit',
    defaultMessage:
      "Merci de sélectionner votre mode de transport pour l'envoi de votre produit",
  },
  title_handling_HomeIntervention: {
    id: 'components.common.pageHeader.title_handling_HomeIntervention',
    defaultMessage: 'Home intervention',
  },
  title_handling_iad: {
    id: 'components.common.pageHeader.title_handling_iad',
    defaultMessage: 'Votre prise en charge',
  },
  subtitle_handling_iad: {
    id: 'components.common.pageHeader.subtitle_handling_iad',
    defaultMessage: 'Intervention à domicile',
  },
  title_handling_delivery: {
    id: 'components.common.pageHeader.title_handling_delivery',
    defaultMessage: 'Votre prise en charge pour le retour',
  },
  subtitle_handling_delivery: {
    id: 'components.common.pageHeader.subtitle_handling_delivery',
    defaultMessage:
      'Merci de sélectionner votre mode de transport pour le retour de votre produit',
  },
  title_issues: {
    id: 'components.common.pageHeader.title_issues',
    defaultMessage: 'Quel problème rencontrez-vous ?',
  },
  subtitle_issues: {
    id: 'components.common.pageHeader.subtitle_issues',
    defaultMessage: 'Vous rencontrez un problème avec ce produit ?',
  },
  title_reasons: {
    id: 'components.common.pageHeader.title_reasons',
    defaultMessage: 'Dites nous en plus...',
  },
  description_reasons: {
    id: 'components.common.pageHeader.description_reasons',
    defaultMessage: 'Vous rencontrez un problème avec ce produit ?',
  },
  title_solutions: {
    id: 'components.common.pageHeader.title_solutions',
    defaultMessage: 'Nous avons trouvé la solution !',
  },
  subtitle_solutions: {
    id: 'components.common.pageHeader.subtitle_solutions',
    defaultMessage: 'Voici la ou les solution(s) que nous vous proposons.',
  },
  description_solutions: {
    id: 'components.common.pageHeader.description_solutions',
    defaultMessage: 'Séléctionnez en une pour continuer.',
  },
  title_tracking: {
    id: 'components.common.pageHeader.title_tracking',
    defaultMessage: 'Suivi de dossier N°{claimId}',
  },
  description_tracking: {
    id: 'components.common.pageHeader.description_tracking',
    defaultMessage: 'Statut actuel : {status}',
  },
  title_file_detail: {
    id: 'components.common.pageHeader.title_file_detail',
    defaultMessage: 'File N° {fileReference}',
  },
  title_claim_group_irs: {
    id: 'components.common.pageHeader.title_claim_group_irs',
    defaultMessage: '1/4 Choose reason and solution',
  },
  description_claim_group_irs: {
    id: 'components.common.pageHeader.description_claim_group_irs',
    defaultMessage: 'Please select the relevant reason for each product',
  },
  title_products: {
    id: 'components.common.pageHeader.title_products',
    defaultMessage: '2/5 Choose product(s)',
  },
  subtitle_products: {
    id: 'components.common.pageHeader.subtitle_products',
    defaultMessage:
      'Please select the product(s) that which you want to create a claim for.',
  },
});
