/* eslint-disable @typescript-eslint/no-var-requires,global-require */
import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';
import { AuthConfiguration } from '@savgroup-front-common/types';

const getCurrentAuthConfigs = (): AuthConfiguration => {
  switch (currentAppEnvironment) {
    case APP_ENVS.DEMO:
      return require('./Auth.config.DEMO').default;
    case APP_ENVS.DEV:
      return require('./Auth.config.DEV').default;
    case APP_ENVS.LOCAL:
      return require('./Auth.config.LOCAL').default;
    case APP_ENVS.PROD:
      return require('./Auth.config.PROD').default;
    case APP_ENVS.PREPROD:
      return require('./Auth.config.PREPROD').default;
    case APP_ENVS.DEFAULT:
    default:
      throw new Error(`${currentAppEnvironment} is not supported as APP_ENVS.`);
  }
};

export default getCurrentAuthConfigs();
