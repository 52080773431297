import { defineMessages } from 'react-intl';

export default defineMessages({
  descriptionLabel: {
    id: 'view.modal.quote.form.description.label',
    defaultMessage: 'Description',
  },
  service: {
    id: 'components.myaccount.quoteInformation.service',
    defaultMessage: 'Service',
  },
  recipient: {
    id: 'view.quotation.recipient.label',
    defaultMessage: 'Recipient',
  },
  optionalSpareParts: {
    id: 'view.quotation.optionalSpareParts.label',
    defaultMessage: 'Optional spare parts',
  },
  requiredSpareParts: {
    id: 'view.quotation.requiredSpareParts.label',
    defaultMessage: 'Required spare parts',
  },
  total: {
    id: 'view.quotation.total.label',
    defaultMessage: 'Total TTC',
  },
  statusSpareParts: {
    id: 'view.quotation.status.label',
    defaultMessage: 'Status',
  },
});
