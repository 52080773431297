import { useGetSellerConfiguration } from 'myaccount/view/app/hooks';

const usePaymentCard = ({ sellerId }: { sellerId: string}) => {
  const { sellerConfiguration } = useGetSellerConfiguration({ sellerId });

  return {
    isCashRegisterConnected: sellerConfiguration?.isCashRegisterConnected,
    paymentUrl: sellerConfiguration?.paymentUrl,
  };
};

export default usePaymentCard;
