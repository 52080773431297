import React, { FC, useEffect, useState } from 'react';
import { StrichSDK } from '@pixelverse/strichjs-sdk';

import { CartManager } from './CartManager';
import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

const ProductsToReturnPage: FC = () => {
  const [isStrichLoading, setIsStrichLoading] = useState(true);

  useEffect(() => {
    StrichSDK.initialize(process.env.REACT_APP_STRICH_LICENCE_KEY).then(() => {
      logVerbose('STRICH SDK initialized');
      setIsStrichLoading(false);
    });
  }, []);

  if (isStrichLoading) {
    return <BaseLoader />;
  }

  return <CartManager />;
};

ProductsToReturnPage.displayName = 'ProductsToReturnPage';

export default React.memo(ProductsToReturnPage);
