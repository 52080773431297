import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';

export const Container = styled('div')`
  position: relative;
  width: 100%;
`;

export const ListContainer = styled('div')`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  box-sizing: content-box;
  background-color: #ffffff;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  ${mediaQuery.sm`
    display: block;
    flex-direction: unset;A
    flex-wrap: unset;
    overflow-x: hidden;

    padding: 0;
    max-height: calc(100vh - 208px);
    min-height: calc(100vh - 208px);
    overflow-y: auto;

    padding-bottom: 80px;
    max-height: calc(100vh - 290px);
    min-height: calc(100vh - 290px);
  `};
`;

export const NextButtonContainer = styled(Row)`
  width: 100%;
  bottom: 0;
  margin: 0;
  padding: 5px;
  ${mediaQuery.sm`
    padding: 16px;
    position: absolute;
    left: 0.5rem;
    margin: 0 -0.5rem;
    background-color: #FFF;
    box-shadow: 0 0 1px 0 rgba(10,31,68,0.08), 
                0 -3px 4px 0 rgba(10,31,68,0.10);
  `};
`;
