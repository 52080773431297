import { DOCUMENT_STATUS, SERVICES } from '@savgroup-front-common/constants';
import {
  DOCUMENT_UPLOADER_TYPE,
  MessageType,
} from '@savgroup-front-common/types';

import { globalMessages } from '../helpers';

import { safeFormattedIntlString } from './safeFormattedIntlString';

export interface DocumentData {
  documentName: string;
  fileId?: string;
  moduleDefinitionId?: string;
  moduleInstanceId?: string;
  fromActor?: string;
  toActor?: string;
  type?: string;
  inProgress?: boolean;
  uniqueName?: string;
  url?: string;
  disabled?: boolean;
  uploaderFullName?: string;
  uploadDateUtc?: string;
  uploaderType?: DOCUMENT_UPLOADER_TYPE;
  extension?: string;
  attachmentId?: string;
  customerVisibility?: boolean;
  originalFileName?: string;
  service?: SERVICES;
  counter?: number;
}

const getDocumentNameTranslated = ({
  documentData,
}: {
  documentData: DocumentData;
}): string => {
  const {
    documentName,
    fileId,
    moduleDefinitionId,
    moduleInstanceId,
    fromActor,
    toActor,
    inProgress,
    uniqueName,
  } = documentData;
  const messages = globalMessages.documentMessages as Record<
    string,
    MessageType
  >;

  if (
    (fileId && moduleDefinitionId && moduleInstanceId) ||
    (documentName === DOCUMENT_STATUS.REGENERATE && inProgress)
  ) {
    if (fromActor && toActor && messages[fromActor] && messages[toActor]) {
      return safeFormattedIntlString(messages.ShippingLabel, {
        sourceAndDestination: `${safeFormattedIntlString(
          messages[fromActor],
        )} → ${safeFormattedIntlString(messages[toActor])}`,
      });
    }

    return safeFormattedIntlString(messages.ShippingLabel, {
      sourceAndDestination: null,
    });
  }

  if (documentName && uniqueName && messages[uniqueName]) {
    const documentNameForAddInfo = messages[documentName]
      ? safeFormattedIntlString(messages[documentName])
      : documentName;

    return safeFormattedIntlString(messages[uniqueName], {
      documentName: documentNameForAddInfo,
    });
  }

  if (documentName && messages[documentName]) {
    return safeFormattedIntlString(messages[documentName]);
  }

  return documentName || '';
};

export default getDocumentNameTranslated;
