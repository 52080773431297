import React, { FunctionComponent, ReactElement } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

import {
  BUTTON_TYPES,
  Z_INDEX_MODAL,
} from '@savgroup-front-common/constants/src/shared';

import { SafeFormattedMessageWithoutSpread } from '../../formatters';
import { useFunctionOnEscape } from '../../hooks';
import { CrossIcon } from '../../protons/icons';
import { BackgroundDimmerNaked } from '../BackgroundDimmer';

import messages from './messages';
import { MODAL_SIZES } from './Modal.helpers';
import { useAriaHidden, useInitialPosition } from './Modal.hooks';
import {
  $CloseIconWrapper,
  $IconContainer,
  $ModalAnimationContainer,
  $ModalBlock,
  $ModalContainer,
} from './Modal.styles';

interface SubModalProps {
  shouldCloseOnDimmerClick: boolean;
  animationState: TransitionStatus;
  animationDuration: number;
  showCrossButton: boolean;
  shouldShowPadding: boolean;
  onClose?: () => void;
  icon?: ReactElement;
  dataTestId: string;
  size: MODAL_SIZES;
  isNewUi?: boolean;
}

const SubModal: FunctionComponent<React.PropsWithChildren<SubModalProps>> = ({
  shouldCloseOnDimmerClick,
  animationDuration,
  showCrossButton,
  shouldShowPadding,
  animationState,
  dataTestId,
  children,
  onClose,
  size,
  icon,
  isNewUi,
}) => {
  useFunctionOnEscape(onClose);
  useAriaHidden();
  const transformOrigin = useInitialPosition();

  return (
    <>
      <BackgroundDimmerNaked
        animationDuration={animationDuration}
        animationState={animationState}
        $zIndex={Z_INDEX_MODAL}
        $opacity={0.8}
        onClick={
          shouldCloseOnDimmerClick && onClose ? () => onClose() : undefined
        }
      />
      <$ModalContainer
        animationState={animationState}
        onClick={
          shouldCloseOnDimmerClick && onClose ? () => onClose() : undefined
        }
      >
        <$ModalAnimationContainer
          $transformOrigin={transformOrigin}
          animationDuration={animationDuration}
          animationState={animationState}
          $size={size}
          onClick={
            shouldCloseOnDimmerClick && onClose ? () => onClose() : undefined
          }
          $isNewUi={isNewUi}
        >
          <$ModalBlock
            animationState={animationState}
            shouldShowPadding={shouldShowPadding}
            animationDuration={animationDuration}
            data-testid={dataTestId}
            onClick={(event) => event.stopPropagation()}
          >
            {icon && <$IconContainer>{icon}</$IconContainer>}
            {children}
            {showCrossButton && onClose && (
              <$CloseIconWrapper
                onClick={() => onClose()}
                type={BUTTON_TYPES.BUTTON}
                data-testid="modalClose"
              >
                <div>
                  <SafeFormattedMessageWithoutSpread message={messages.close} />
                  <CrossIcon size="18px" />
                </div>
              </$CloseIconWrapper>
            )}
          </$ModalBlock>
        </$ModalAnimationContainer>
      </$ModalContainer>
    </>
  );
};

SubModal.displayName = 'SubModal';

export default SubModal;
