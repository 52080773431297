import {
  COUNTRY_CODES,
  LANGUAGES,
} from '@savgroup-front-common/constants/src/shared';

const COUNTRY_CODES_LANGUAGE = {
  [COUNTRY_CODES.FR]: LANGUAGES.FR,
  [COUNTRY_CODES.MA]: LANGUAGES.FR,
  [COUNTRY_CODES.CN]: LANGUAGES.EN,
  [COUNTRY_CODES.HK]: LANGUAGES.EN,
  [COUNTRY_CODES.US]: LANGUAGES.EN,
  [COUNTRY_CODES.UK]: LANGUAGES.EN,
  [COUNTRY_CODES.DE]: LANGUAGES.DE,
  [COUNTRY_CODES.ES]: LANGUAGES.ES,
  [COUNTRY_CODES.MY]: LANGUAGES.EN,
  [COUNTRY_CODES.IN]: LANGUAGES.EN,
  [COUNTRY_CODES.AE]: LANGUAGES.EN,
  [COUNTRY_CODES.SG]: LANGUAGES.EN,
  [COUNTRY_CODES.MG]: LANGUAGES.FR,
  [COUNTRY_CODES.NL]: LANGUAGES.EN,
  [COUNTRY_CODES.IT]: LANGUAGES.IT,
  [COUNTRY_CODES.PT]: LANGUAGES.PT,
  [COUNTRY_CODES.NL]: LANGUAGES.NL,
};

const guessLanguageFromCountryCode = (
  countryCode?: string,
): LANGUAGES | undefined => {
  if (!countryCode) {return undefined;}

  const upperCountryCode = countryCode.toUpperCase() as COUNTRY_CODES;

  return COUNTRY_CODES_LANGUAGE[upperCountryCode] || LANGUAGES.EN;
};

export default guessLanguageFromCountryCode;
