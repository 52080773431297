import { transparentize } from 'polished';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';
import {
  getCustomComponentProperty,
  rem,
} from '@savgroup-front-common/core/src/helpers';
import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 72px;
  background-color: ${({ theme, componentThemeName }) => {
    return getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
      fallback: theme.colors.white,
    });
  }};
  border-bottom: ${({ theme }) =>
    `${theme.borders.thickness} solid ${transparentize(
      0.75,
      theme.colors.default,
    )}`};
  margin-bottom: 1rem;
`;

export const StyledGrid = styled(Grid)`
  flex: 1;

  ${mediaQuery.md`flex: none;`};
`;

export const ActionRow = styled(Row)`
  align-items: flex-end;
`;

export const MenuContainer = styled.div`
  align-items: center;
  height: ${rem(72)};
  display: flex;
  justify-content: space-between;
`;

export const LogoContainer = styled('div')`
  width: 100%;
  height: 72px;
  padding: ${({ componentThemeName, theme }) => {
    const themeValue = getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.PADDING,
    });

    return themeValue || '21px 0';
  }};
  cursor: pointer;
`;

export const LogoImg = styled('img')`
  height: 100%;
  max-height: 100%;
  max-width: 100%;
`;

export const LogoutButton = styled(Button)`
  @media (max-width: 670px) {
    display: none;
  }
`;

export const SmallLogoutButton = styled(Button)`
  display: none;
  @media (max-width: 670px) {
    display: block;
    width: initial;
    height: initial;
    line-height: 0;

    & > span {
      height: 100%;
    }
  }
`;

export const ButtonCol = styled(Col)`
  @media (min-width: 670px) {
    display: flex !important;
    align-items: center !important;
  }
  @media (max-width: 670px) {
    padding: 0;
  }
`;

export const HeaderCol = styled(Col)`
  @media (max-width: 670px) {
    max-width: 40%;
    flex-basis: 40%;
  }
`;

export const LogoCol = styled(Col)`
  @media (max-width: 670px) {
    max-width: 60%;
    flex-basis: 60%;
  }
`;

export const RightSide = styled.div`
  display: flex;
`;
