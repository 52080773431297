import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { QuoteClientAnswer } from '@savgroup-front-common/types/src/Quotation/QuoteClientAnswer';

import { CommonRepairerService } from '../../../../api/RepairerService';
import { useToasts } from '../../../../molecules/NotificationsProvider';
import { GetQuotationsByFileIdQuery } from '../../hooks/useGetQuotationsByFileIdQuery';
import { HolderType } from '@savgroup-front-common/types';

export const useRejectQuotation = ({
  quotationId,
  fileId,
  onAnswerSuccess,
  holderType,
}: {
  quotationId: string;
  fileId: string;
  onAnswerSuccess?: () => void;
  holderType: HolderType;
}) => {
  const queryClient = useQueryClient();
  const [isConfirmRejectModalOpen, setIsConfirmRejectModalOpen] =
    useState(false);
  const { removeAllNotifications, pushErrors } = useToasts();

  const { mutateAsync: handleReject, isLoading: isRejectLoading } = useMutation(
    [],
    async () => {
      removeAllNotifications();

      const response = await CommonRepairerService.setQuoteClientAnswerCommand({
        quoteId: quotationId,
        clientAnswer: QuoteClientAnswer.REJECTED,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      if (onAnswerSuccess) {
        onAnswerSuccess();
      }

      await queryClient.invalidateQueries({
        queryKey: [GetQuotationsByFileIdQuery, { fileId }],
      });

      setIsConfirmRejectModalOpen(false);

      return undefined;
    },
  );

  const handleRejectButtonClick = useCallback(async () => {
    if (holderType === HolderType.OWNER) {
      await handleReject();

      return;
    }
    setIsConfirmRejectModalOpen(true);
  }, [handleReject, holderType]);
  const handleCancelConfirmReject = useCallback(() => {
    setIsConfirmRejectModalOpen(false);
  }, []);

  const {
    mutateAsync: handleRejectWithDestruction,
    isLoading: isRejectWithDestructionLoading,
  } = useMutation([], async () => {
    removeAllNotifications();

    const response = await CommonRepairerService.setQuoteClientAnswerCommand({
      quoteId: quotationId,
      clientAnswer: QuoteClientAnswer.REJECTED_WITH_DESTRUCTION,
    });

    if (response.failure) {
      pushErrors(response.errors);

      return undefined;
    }

    if (onAnswerSuccess) {
      onAnswerSuccess();
    }

    await queryClient.invalidateQueries({
      queryKey: [GetQuotationsByFileIdQuery, { fileId }],
    });

    setIsConfirmRejectModalOpen(false);

    return undefined;
  });

  const {
    mutateAsync: handleRejectWithReturn,
    isLoading: isRejectWithReturnLoading,
  } = useMutation([], async () => {
    removeAllNotifications();

    const response = await CommonRepairerService.setQuoteClientAnswerCommand({
      quoteId: quotationId,
      clientAnswer: QuoteClientAnswer.REJECTED_WITH_RETURN,
    });

    if (response.failure) {
      pushErrors(response.errors);

      return undefined;
    }

    if (onAnswerSuccess) {
      onAnswerSuccess();
    }

    await queryClient.invalidateQueries({
      queryKey: [GetQuotationsByFileIdQuery, { fileId }],
    });

    setIsConfirmRejectModalOpen(false);

    return undefined;
  });

  return {
    handleRejectButtonClick,
    handleCancelConfirmReject,
    isConfirmRejectModalOpen,
    handleRejectWithReturn,
    handleRejectWithDestruction,
    isRejectLoading:
      isRejectWithReturnLoading ||
      isRejectWithDestructionLoading ||
      isRejectLoading,
  };
};
